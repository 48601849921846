

import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { IMaskModule } from 'angular-imask';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CurrencyMaskDirective, NgxCurrencyModule } from 'ngx-currency';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BirimfiyatAddComponent } from '../components/apas-components/birimfiyat/birimfiyat-add/birimfiyat-add.component';
import { FilePreviewerComponent } from '../components/apas-components/file-preview-component/file-previewer.component';
import { BirimfiyatListComponent } from '../components/apas-components/birimfiyat/birimfiyat-list/birimfiyat-list.component';
import { YapilanIslerAddPhotoComponent } from '../components/santiye/yapilanIsler/yapilan-isler-add-photo/yapilan-isler-add-photo.component';
import { ApasAlertComponent } from '../components/Apas-Modules/apas-alert-modul/apas-alert/apas-alert.component';
import { ApasImageLightboxComponent } from '../components/Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { ApasImageUploaderComponent } from '../components/Apas-Modules/apas-image-uploader/apas-image-uploader.component';
import { ApasImalatUygunsuzlukItemComponent } from '../components/Apas-Modules/apas-imalat-uygunsuzluk-item-module/apas-imalat-uygunsuzluk-item/apas-imalat-uygunsuzluk-item.component';
import { ApasInfiniteScrollComponent } from '../components/Apas-Modules/infinite-scroll/infinite-scroll.component';
import { UrunEkleComponent } from '../components/depolar/urun-ekle/urun-ekle.component';
import { FirmaEkleComponent } from '../components/firma/firma-ekle/firma-ekle.component';
import { GorevDetayComponent } from '../components/gorev/gorev-detay/gorev-detay.component';
import { GorevEkleComponent } from '../components/gorev/gorev-ekle/gorev-ekle.component';
import { GorevListComponent } from '../components/gorev/gorev-list/gorev-list.component';
import { PozEkleModalComponent } from '../components/hakedis/hakedis-arka-kisim/hakedis-metraj/poz-ekle-modal/poz-ekle-modal.component';
import { HakedisListBoxComponent } from '../components/hakedis/hakedis-list-box/hakedis-list-box.component';
import { HatirlatmaDetayComponent } from '../components/hatirlatmalar/hatirlatma-detay/hatirlatma-detay.component';
import { HatirlatmaEkleComponent } from '../components/hatirlatmalar/hatirlatma-ekle/hatirlatma-ekle.component';
import { HatirlatmaInfinitComponent } from '../components/hatirlatmalar/hatirlatma-list/hatirlatma-infinit/hatirlatma-infinit.component';
import { HatirlatmaListComponent } from '../components/hatirlatmalar/hatirlatma-list/hatirlatma-list.component';
import { PersonelGrubuOlusturComponent } from '../components/personel/personel-grubu-olustur/personel-grubu-olustur.component';
import { PuantajDetayForNamesComponent } from '../components/puantaj/puantaj-detay-for-names/puantaj-detay-for-names.component';
// tslint:disable-next-line: max-line-length
import { DigerFirmaPersonelComponent } from '../components/puantaj/puantaj-ekle/diger-firma-personel-ekle/personel-ekle.component';
import { PuantajEkleModalComponent } from '../components/puantaj/puantaj-ekle/Puantaj-ekle-modal/puantaj-ekle-modal.component';
import { TopluPuantajEkleModalComponent } from '../components/puantaj/puantaj-ekle/toplu-puantaj-ekle-modal/toplu-puantaj-ekle-modal.component';
import { SantiyePuantajDetayComponent } from '../components/puantaj/santiye-puantaj-detay/santiye-puantaj-detay.component';
import { PursantajEkleComponent } from '../components/pursantaj/pursantaj-ekle/pursantaj-ekle.component';
import { SantiyeDosyalarComponent } from '../components/santiye/santiye-dosyalar/santiye-dosyalar.component';
import { SantiyeGunlukRaporComponent } from '../components/santiye/santiye-gunluk-rapor/santiye-gunluk-rapor.component';
import { SantiyeImgListComponent } from '../components/santiye/santiye-photo/santiye-img-list/santiye-img-list.component';
import { SantiyeImgUploadComponent } from '../components/santiye/santiye-photo/santiye-img-upload/santiye-img-upload.component';
import { SantiyePhotoComponent } from '../components/santiye/santiye-photo/santiye-photo-gallery/santiye-photo.component';
import { YapilanIslerDashboardComponent } from '../components/santiye/yapilanIsler/yapilan-isler-dashboard/yapilan-isler-dashboard.component';
import { YapilanIslerDetayComponent } from '../components/santiye/yapilanIsler/yapilan-isler-detay/yapilan-isler-detay.component';
import { YapilanIslerModalComponent } from '../components/santiye/yapilanIsler/yapilan-isler-modal/yapilan-isler-modal.component';
import { MapComponent } from '../components/sozlesme/map/map.component';
import { KullaniciTakvimComponent } from '../components/takvim/kullanici-takvim/kullanici-takvim.component';
import { TakvimDetayComponent } from '../components/takvim/takvim-detay/takvim-detay.component';
import { DepoMiktarModalComponent } from '../components/talep/depo-miktar-modal/depo-miktar-modal.component';
import { TalepDetayComponent } from '../components/talep/talep-detay/talep-detay.component';
import { TalepListDashboardComponent } from '../components/talep/talep-list-dashboard/talep-list-dashboard.component';
import { TalepListModalComponent } from '../components/talep/talep-list-modal/talep-list-modal.component';
import { TalepModalComponent } from '../components/talep/talep-modal/talep-modal.component';
import { YardimListComponent } from '../components/yardim/yardim-list/yardim-list.component';
import { ApasInvalidDirective } from '../directives/apas-invalid.directive';
import { ApasRoundPipeDirective } from '../directives/apas-round-pipe.directive';
import { ApasRoundDirective } from '../directives/apas-round.directive';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { IzypayPaymentModalComponent } from '../profil/firma-profil-main/payment/izypay-payment-modal/izypay-payment-modal.component';
import { ProfilPhotoComponent } from '../profil/kullanici-profil-main/profil-photo/profil-photo.component';
import { LaboratuvarService } from '../services/laboratuvar.service';
import { CalendarHeaderComponent } from './calender-utils/calender-header.component';
import { NgGanttEditorModule } from 'ng-gantt';
import { GanttItemDetayComponent } from '../components/gantt/gantt-item-detay/gantt-item-detay.component';
import { ShowganttComponent } from '../components/gantt/showgantt/showgantt.component';
import { YapilanIslerEkleComponent } from '../components/santiye/yapilanIsler/yapilan-isler-ekle/yapilan-isler-ekle.component';
import { YapilanIslerGrafikComponent } from '../components/santiye/yapilanIsler/yapilan-isler-grafik/yapilan-isler-grafik.component';
import { YapilanIslerComponent } from '../components/santiye/yapilanIsler/yapilan-isler/yapilan-isler.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { YapilanIsEkleModalComponent } from '../components/santiye/yapilanIsler/yapilan-is-ekle-modal/yapilan-is-ekle-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TagsComponent } from '../components/apas-components/tags/tags.component';

import { ApasProductComponent } from './selects/apas-product-component/apas-product-component.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ApasEncryptePipe } from '../directives/apas-encrypte-pipe';
import { NotesListComponent } from '../components/apas-components/notes-list/notes-list.component';
import { TagInputComponent } from '../components/apas-components/tag-input/tag-input.component';
import { TagItemComponent } from '../components/apas-components/tag-item/tag-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TaseronMutabakatListComponent } from '../components/taseron/taseron-mutabakat-list/taseron-mutabakat-list.component';
import { NgxIbanModule } from 'ngx-iban';
import { NgxMaskModule } from 'ngx-mask';
import { PersonelEkleFirmaComponent } from '../components/personel/personel-ekle-firma/personel-ekle-firma.component';
import { ApasFilesComponent } from '../components/apas-components/apas-files/apas-files.component';
import { FileSizePipe } from '../@theme/pipes/filesize.pipe';
import { EmptylistComponent } from '../components/apas-components/emptylist/emptylist.component';
import { PrepareComponent } from '../components/apas-components/prepare/prepare.component';
import { EmptyselectComponent } from '../components/apas-components/emptyselect/emptyselect.component';
import * as echarts from 'echarts';
import { ApasParaItemComponent } from '../components/apas-components/apas-para-item/apas-para-item.component';
import { ParaNumberDirective } from '../directives/para-directive';
import { ApasTelefonInputComponent } from '../components/apas-components/apas-telefon-input/apas-telefon-input.component';
import { ApasDecryptePipe } from '../directives/apas-decrypte-pipe';
import { ApasMutabakatListComponent } from '../components/apas-components/apas-mutabakat-list/apas-mutabakat-list.component';
import { PersonelMainComponent } from '../components/personel/personel-main/personel-main.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ApasMonthPickerModule } from '../components/Apas-Modules/apas-month-picker/apas-month-picker.module';
import { PuantajMainComponent } from '../components/puantaj/puantaj-main/puantaj-main.component';
// tslint:disable-next-line: max-line-length
import { PuantajGunListComponent } from '../components/puantaj/puantaj-main/puantaj-gun-list/puantaj-gun-list.component';
import { SantiyeAlbumComponent } from '../components/santiye/santiye-photo/santiye-album/santiye-album.component';
import { SantiyeAlbumDetayComponent } from '../components/santiye/santiye-photo/santiye-album-detay/santiye-album-detay.component';
import { SantiyeSelectComponent } from '../components/apas-components/santiye-select/santiye-select.component';
import { PersonelInviteComponent } from '../components/personel/personel-invite/personel-invite';

import {
  NbRouteTabsetModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule,
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule,
  NbCheckboxModule,
  NbCardModule,
  NbToastrModule,
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbTimepickerModule,
  NbBadgeModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { JobSelectComponent } from './selects/job-select/job-select.component';
import { PersonelExcelImportComponent } from '../components/puantaj/puantaj-ekle/personel-excel-import/personel-excel-import.component';
import { ApasFilterComponent } from '../components/apas-components/apas-filter/apas-filter.component';
import { SantiyeDetayPencereComponent } from '../components/santiye/santiye-detay-pencere/santiye-detay-pencere.component';
import { MiniTakvimComponent } from '../components/takvim/mini-takvim/mini-takvim.component';
import { TakvimIsProgramiDetayComponent } from '../components/takvim/takvim-is-programi-detay/takvim-is-programi-detay.component';
import { SantiyeAlbumModalComponent } from '../components/santiye/santiye-photo/santiye-album-modal/santiye-album-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadinglistComponent } from '../components/apas-components/loadinglist/loadinglist.component';
import { NguCarouselModule } from '@ngu/carousel';
import { EditPursantajModalComponent } from '../components/santiye/yapilanIsler/edit-pursantaj-modal/edit-pursantaj-modal.component';
import { NumberOnlyDirective } from '../directives/apas-only-number.directive';
import { DateOnlyDirective } from '../directives/date-only.directive';
import { PersonelSelectComponent } from '../components/apas-components/personel-select/personel-select.component';
import { PasswordInputComponent } from '../components/apas-components/password-input/password-input.component';
import { SafePipe } from '../@theme/pipes/safe.pipe';
import { PuantajPersonComponent } from '../components/puantaj/puantaj-person/puantaj-person.component';
import { DosyaEkleInputComponent } from '../components/apas-components/dosya-ekle-input/dosya-ekle-input.component';
import { ApasFilesUploadComponent } from '../components/apas-components/apas-files/apas-files-upload/apas-files-upload.component';
import { OtherPersonelSelectComponent } from '../components/apas-components/other-personel-select/other-personel-select.component';
import { ImzaDetayComponent } from '../components/is-guvenligi/imza/imza-detay/imza-detay.component';
import { CheckListMainComponent } from '../components/apas-components/check-list/check-list-main/check-list-main.component';
import { CheckListTemplateComponent } from '../components/apas-components/check-list/check-list-template/check-list-template.component';
import { CheckDuzenleComponent } from '../components/apas-components/check-list/check-duzenle/check-duzenle.component';
import { UrunCikisComponent } from '../components/depolar/urun-cikis/urun-cikis.component';
import { DepoSelectComponent } from '../components/apas-components/depo-select/depo-select';
// tslint:disable-next-line: max-line-length
import { IliskiliFirmaEkleComponent } from './components/organization/iliskili-firma-ekle/iliskili-firma-ekle.component';
import { PersonelDetayComponent } from '../components/personel/personel-detay/personel-detay.component';
import { PersonelOzlukBilgileriComponent } from '../components/personel/personel-detay/personel-ozluk-bilgileri/personel-ozluk-bilgileri.component';
import { FirmaBilgiGuncelleComponent } from '../profil/firma-profil-main/firma-bilgi-guncelle/firma-bilgi-guncelle.component';
import { MutabakatSatirEkleComponent } from '../components/mutabakat/mutabakat-satir-ekle/mutabakat-satir-ekle.component';
import { MutabakatListComponent } from '../components/mutabakat/mutabakat-list/mutabakat-list.component';
import { GelenTekliflerListComponent } from '../components/teklifler/gelen-teklifler-list/gelen-teklifler-list.component';
import { UrunGirisiYapComponent } from '../components/depolar/depo-main/urun-girisi-yap/urun-girisi-yap.component';
import { DepoMainComponent } from '../components/depolar/depo-main/depo-main.component';
import { UrunHareketlerComponent } from '../components/depolar/urun-hareketler/urun-hareketler.component';
import { UrunHareketEditComponent } from '../components/depolar/urun-hareketler/urun-hareket-edit/urun-hareket-edit.component';
import { DepoEkleComponent } from '../components/depolar/depo-ekle/depo-ekle.component';
import { TaseronHakedisEkleComponent } from '../components/hakedis/taseron-hakedis/taseron-hakedis-ekle/taseron-hakedis-ekle.component';
import { IsMakinasiPuantajRaporuComponent } from '../components/is-makinalari/is-makinasi-puantaj-raporu/is-makinasi-puantaj-raporu.component';
import { IsMakinasiSelectComponent } from '../components/is-makinalari/is-makinalari-select/is-makinasi-select/is-makinasi-select.component';
import { MakinaTypeSelectComponent } from '../components/is-makinalari/is-makinalari-select/makina-type-select/makina-type-select.component';
import { SantiyeFotografRollerComponent } from '../components/santiye/santiye-fotograf-roller/santiye-fotograf-roller.component';
import { MarkaSelectComponent } from '../components/apas-components/marka-select/marka-select.component';
import { AppInterceptor } from './app-interceptor';
import { TarihColComponent } from '../components/apas-components/tarih-col/tarih-col.component';
import { OrganizationSelectComponent } from './components/organization/organization-select/organization-select.component';
// tslint:disable-next-line: max-line-length
import { IliskiliFirmaEditComponent } from './components/organization/iliskili-firma-edit/iliskili-firma-edit.component';
import { ApasCalendarComponent } from '../components/Apas-Modules/apas-calendar/apas-calendar.component';
import { EFaturaGirisComponent } from '../pages/muhasebe/e-fatura-sureci/e-fatura-giris/e-fatura-giris.component';
import { BasvuruSecenekleriComponent } from '../pages/muhasebe/e-fatura-sureci/basvuru-secenekleri/basvuru-secenekleri.component';
import { BasvuruBilgisiComponent } from '../pages/muhasebe/e-fatura-sureci/basvuru-bilgisi/basvuru-bilgisi.component';
import { MoneyCurrencyInputComponent } from '../components/apas-components/money-currency-input/money-currency-input.component';
import { FaturaStatusComponent } from '../pages/muhasebe/entegrasyon/fatura-status/fatura-status.component';
import { BaseCreateComponent } from '../components/BaseViews/base-create/base-create.component';
import { BaseDetailComponent } from '../components/BaseViews/base-detail/base-detail.component';
import { BaseListComponent } from '../components/BaseViews/base-list/base-list.component';
import { BaseListTemplateComponent } from '../components/BaseViews/base-list/base-list-template/base-list-template.component';
import { MusteriService } from '../pages/crm/crm-main/musteri/musteri.service';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MiktarBirimComponent } from '../components/apas-components/miktar-birim/miktar-birim.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { OdemePlaniDetayComponent } from '../pages/muhasebe/muhasebe-main/muhasebe-cari/modallar/cari-odeme-plani/odeme-plani-detay/odeme-plani-detay.component';
import { CariOdemePlaniListComponent } from '../pages/muhasebe/muhasebe-main/muhasebe-cari/modallar/cari-odeme-plani/cari-odeme-plani-list/cari-odeme-plani-list.component';
 
export function playerFactory() {
  return player;
}

const BaseEntryComponent = [
  BaseCreateComponent,
  BaseDetailComponent,
  BaseListTemplateComponent,

];
const BaseComponets = [
  ...BaseEntryComponent,
  BaseListComponent,
  BaseListTemplateComponent,

];

const NB_MODULES = [
  NbRouteTabsetModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule.forRoot(),
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule.forRoot(),
  NbCheckboxModule,
  NbCardModule,
  NbToastrModule.forRoot(),
  NgCircleProgressModule.forRoot({}),
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbWindowModule.forRoot(),
  NbToastrModule.forRoot(),
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbEvaIconsModule,
  NbBadgeModule,
];

const ApasEntryComponents = [
  ApasFilesUploadComponent,
  NotesListComponent,
  ApasFilesComponent,
  FilePreviewerComponent,
  ApasAlertComponent,
  ApasImageLightboxComponent,
];

const selectComponents = [
  MoneyCurrencyInputComponent,
  MiktarBirimComponent,
  DateRangeComponent,
];

const ApasComponent = [
  ...selectComponents,
  ...ApasEntryComponents,
  ApasCalendarComponent,
  ApasImalatUygunsuzlukItemComponent,
  DosyaEkleInputComponent,
  ApasInvalidDirective,
  ApasRoundDirective,
  ApasRoundPipeDirective,
  ParaNumberDirective,
  ApasTelefonInputComponent,
  ApasEncryptePipe,
  SafePipe,
  ApasDecryptePipe,
  ApasAlertComponent,
  PhoneMaskDirective,
  ApasInfiniteScrollComponent,
  ApasImageUploaderComponent,
  TagsComponent,
  ApasFilterComponent,
  TagItemComponent,
  TagInputComponent,
  EmptylistComponent,
  EmptyselectComponent,
  PrepareComponent,
  ApasProductComponent,
  ApasParaItemComponent,
  SantiyeSelectComponent,
  JobSelectComponent,
  LoadinglistComponent,
  OrganizationSelectComponent,
  PersonelSelectComponent,
  OtherPersonelSelectComponent,
  PasswordInputComponent,
  MarkaSelectComponent,
  TarihColComponent,
];

const SantiyeEntryComponents = [
  SantiyeDosyalarComponent,
  SantiyeImgUploadComponent,
  SantiyeAlbumModalComponent,
  MapComponent,
  SantiyePhotoComponent,

];

const SantiyeComponents = [
  ...SantiyeEntryComponents,
  SantiyeFotografRollerComponent,
  SantiyeDetayPencereComponent,
  MiniTakvimComponent,
  SantiyePuantajDetayComponent,
  SantiyeGunlukRaporComponent,
  SantiyeImgListComponent,
  SantiyeAlbumComponent,
  SantiyeAlbumDetayComponent,
  PursantajEkleComponent,
];


const DepoEntryComponents = [
  UrunGirisiYapComponent,
  UrunHareketlerComponent,
  UrunHareketEditComponent,
  UrunCikisComponent,
  DepoEkleComponent,
  DepoMiktarModalComponent,
  UrunEkleComponent,

];

const DepoComponents = [
  ...DepoEntryComponents,
  DepoMainComponent,
  DepoSelectComponent,

];

const TeklifComponents = [
  GelenTekliflerListComponent,
];

const MutabakatEntryComponents = [
  MutabakatSatirEkleComponent,
];

const MutabakatComponents = [
  ...MutabakatEntryComponents,
  MutabakatListComponent,
  TaseronMutabakatListComponent,
  ApasMutabakatListComponent, // Murat kontrol et
];

const PersonelEntryComponents = [
  PersonelDetayComponent,
  PersonelOzlukBilgileriComponent,
  PersonelGrubuOlusturComponent,
  PersonelEkleFirmaComponent,
  PersonelInviteComponent,
];

const PersonelComponent = [
  ...PersonelEntryComponents,
  PersonelMainComponent,
];

const PuantajEntryComponents = [
  PuantajEkleModalComponent,
  TopluPuantajEkleModalComponent,
  PuantajGunListComponent,
  PuantajPersonComponent,
];

const PuantajComponent = [
  ...PuantajEntryComponents,
  PuantajMainComponent,
  PuantajDetayForNamesComponent,
];

const KullaniciEntryComponents = [
  TakvimDetayComponent,
  ProfilPhotoComponent,

];
const KullaniciComponent = [
  ...KullaniciEntryComponents,
  KullaniciTakvimComponent,

];

const FirmaEntryComponents = [
  FirmaEkleComponent,
  FirmaBilgiGuncelleComponent,
  IliskiliFirmaEkleComponent,
  IliskiliFirmaEditComponent,
  FirmaBilgiGuncelleComponent,
  IzypayPaymentModalComponent,

];
const FirmaComponent = [
  ...FirmaEntryComponents,
];

const TalepEntryComponents = [
  TalepModalComponent,
  TalepDetayComponent,
];

const TalepComponent = [
  ...TalepEntryComponents,
  TalepListDashboardComponent,
  TalepListModalComponent,
];

const YapilanIslerEntryComponents = [
  YapilanIslerDetayComponent,
  YapilanIslerModalComponent,
  YapilanIslerGrafikComponent,
  YapilanIslerEkleComponent,
  YapilanIsEkleModalComponent,
  YapilanIslerAddPhotoComponent,
];

const yapilanIslerComponent = [
  ...YapilanIslerEntryComponents,
  YapilanIslerDashboardComponent,
  YapilanIslerComponent,


];

const gorevEntryComponents = [
  GorevListComponent,
  GorevEkleComponent,
  GorevDetayComponent,
];

const gorevComponent = [
  ...gorevEntryComponents,

];

const hatirlatmaEntryComponents = [
  HatirlatmaListComponent,
  HatirlatmaDetayComponent,
  HatirlatmaEkleComponent,
];

const hatirlatmaComponent = [
  ...hatirlatmaEntryComponents,
  HatirlatmaInfinitComponent,

];

const taseronmaEntryComponents = [
  PersonelExcelImportComponent,
  DigerFirmaPersonelComponent,
];

const taseronmaComponent = [
  ...taseronmaEntryComponents,
];

const bagimsizEntryComponents = [
  CalendarHeaderComponent,
  YardimListComponent,
];

const bagimsizComponent = [
  ...bagimsizEntryComponents,
];

const hakedisEntryComponents = [
  PozEkleModalComponent,
  TaseronHakedisEkleComponent,

];

const hakedisComponent = [
  ...hakedisEntryComponents,
  HakedisListBoxComponent,
  BirimfiyatListComponent,
  BirimfiyatAddComponent,
];

const isProgramiEntryComponents = [
  GanttItemDetayComponent,
  TakvimIsProgramiDetayComponent,
  EditPursantajModalComponent,

];

const isProgramiComponent = [
  ...isProgramiEntryComponents,
  ShowganttComponent,

];

const isGuvenligiEntryComponents = [
  ImzaDetayComponent,
];

const isGuvenligiComponent = [
  ...isGuvenligiEntryComponents,

];

const checkEntryComponents = [
  CheckListMainComponent,
  CheckListTemplateComponent,
  CheckDuzenleComponent,

];

const checkComponent = [
  ...checkEntryComponents,
];

const isMakinalariEntryComponents = [
  IsMakinasiPuantajRaporuComponent,
];

const isMakinalariComponents = [
  ...isMakinalariEntryComponents,
  IsMakinasiSelectComponent,
  MakinaTypeSelectComponent,

];

const muhasebeEntryComponents = [
  OdemePlaniDetayComponent,
];

const MuhasebeComponents = [
  ...muhasebeEntryComponents,
  CariOdemePlaniListComponent,
];


const exportNbModules = [
  NbRouteTabsetModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule,
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule,
  NbCheckboxModule,
  NbCardModule,
  NbToastrModule,
  NgCircleProgressModule,
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbToastrModule,
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbEvaIconsModule,
  NbBadgeModule,
  NbFormFieldModule,
];

const angularCoreModules = [
  ScrollingModule,
  CommonModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  DragDropModule,
  VirtualScrollerModule,
];

const ApasPipeComponents = [
  FileSizePipe,
  SafePipe,
  ApasEncryptePipe,
  ApasDecryptePipe,
];

const ApasDirectiveComponents = [
  NumberOnlyDirective,
  DateOnlyDirective,
  ApasInvalidDirective,
  ApasRoundDirective,
  ApasRoundPipeDirective,
  ParaNumberDirective,
  PhoneMaskDirective,
  DateOnlyDirective,
];

const EntegrasyonEntryComponentes = [
  EFaturaGirisComponent,
  BasvuruSecenekleriComponent,
  BasvuruBilgisiComponent,
  FaturaStatusComponent,
];

const EntegrasyonComponents = [
  ...EntegrasyonEntryComponentes,
];

// Tüm Componentler Burada Birleşir

const MainEntryComponents = [
  ...BaseEntryComponent,
  ...DepoEntryComponents,
  ...MutabakatEntryComponents,
  ...PersonelEntryComponents,
  ...ApasEntryComponents,
  ...SantiyeEntryComponents,
  ...PuantajEntryComponents,
  ...KullaniciEntryComponents,
  ...FirmaEntryComponents,
  ...TalepEntryComponents,
  ...YapilanIslerEntryComponents,
  ...gorevEntryComponents,
  ...hatirlatmaEntryComponents,
  ...taseronmaEntryComponents,
  ...hakedisEntryComponents,
  ...bagimsizEntryComponents,
  ...isProgramiEntryComponents,
  ...isGuvenligiEntryComponents,
  ...checkEntryComponents,
  ...isMakinalariEntryComponents,
  ...EntegrasyonEntryComponentes,
  ...muhasebeEntryComponents,
];

const MainComponents = [
  ...BaseComponets,
  ...DepoComponents,
  ...TeklifComponents,
  ...MutabakatComponents,
  ...PersonelComponent,
  ...ApasComponent,
  ...SantiyeComponents,
  ...PuantajComponent,
  ...KullaniciComponent,
  ...FirmaComponent,
  ...TalepComponent,
  ...yapilanIslerComponent,
  ...gorevComponent,
  ...hatirlatmaComponent,
  ...taseronmaComponent,
  ...hakedisComponent,
  ...bagimsizComponent,
  ...isProgramiComponent,
  ...isGuvenligiComponent,
  ...checkComponent,
  ...ApasPipeComponents,
  ...ApasDirectiveComponents,
  ...isMakinalariComponents,
  ...EntegrasyonComponents,
  ...MuhasebeComponents,
];

@NgModule({
  declarations: [
    ...MainComponents,
    ColorPickerComponent,
  ],


  exports: [
    ...MainComponents,
    ...exportNbModules,
    ...angularCoreModules,
    NgSelectModule,
    NgxIbanModule,
    NgxCurrencyModule,
    NgxMaskModule,
    TranslateModule,
    AgmCoreModule,
    NgxEchartsModule,
    NgxSliderModule,
    NgxDatatableModule,
    LottieModule,
    NgxMaterialTimepickerModule,
    CalendarModule,
    IMaskModule,
    NgGanttEditorModule,
    NgxChartsModule,
    ChartModule,
    InfiniteScrollModule,
    ApasMonthPickerModule,
    CurrencyMaskDirective,
    ColorPickerComponent,

  ],
  entryComponents: [
    ...MainEntryComponents,
  ],
  imports: [
    ...NB_MODULES,
    TranslateModule,
    NgxCurrencyModule,
    CommonModule,
    AgmCoreModule,
    HttpClientModule,
    IMaskModule,
    NgSelectModule,
    NgxIbanModule,
    NgxEchartsModule.forRoot({ echarts: { init: echarts.init } }),
    NbTimepickerModule.forRoot(),
    NgxSliderModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    NgxDatatableModule,
    FormsModule,
    RouterModule,
    CommonModule,
    NgxMaterialTimepickerModule.setLocale('tr-TR'),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgGanttEditorModule.forRoot(),
    NgxChartsModule,
    ChartModule,
    DragDropModule,

    LottieModule.forRoot({ player: playerFactory }),
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    ApasMonthPickerModule.forRoot(),
    ScrollingModule,
    NguCarouselModule,
  ],

  providers: [
    LaboratuvarService,
    MusteriService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
})
export class SharedModule { }
