<nb-card style="min-width: 420px;" class="modalType animated fadeInRight" accent="primary">
  <nb-card-header>
    <div class="row">
      <div class="col-10">
        {{'DEPOMIKTAR.DEPODURUM'|translate}}
      </div>

      <div class="col-2">
        <button nbButton (click)="closed()" size="tiny" class="m-close" style="background: none !important;
      color: #999 !important;
      float: right;
      font-size: 18px;">X</button>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body class="p-0" [nbSpinner]="isLoading" >
  
  
    <table class="table">
      <thead>
        <tr>
          <th>{{'DEPOMIKTAR.DEPOADI'|translate}}</th>
          <th>{{'DEPOMIKTAR.MIKTAR'|translate}}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of rows">
          <td>{{item.warehouse_name}} </td>
          <td> <strong>{{item.total_amount}}</strong> {{getBirim(item.unit)}} </td>
        </tr>

      </tbody>
    </table>

    <aps-emptylist *ngIf="rows.length === 0 && !isLoading"
    [message]="'Ürün Bulunamadı'" [text]="' '" [genislik]="'200px'"
    [yukseklik]="'200px'"></aps-emptylist>
  </nb-card-body>
</nb-card>