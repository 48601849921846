import { Component, Input, OnInit, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Workbook } from 'exceljs';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import * as fs from 'file-saver';
import { bordered, responseKodlari, tarih_format } from '../../../../pages/constants';
import { PuantajService } from '../../../../services/puantaj.service';
import { removeAllNull } from '../../../../pages/global-functions';

@Component({
  selector: 'ngx-puantaj-gun-list',
  templateUrl: './puantaj-gun-list.component.html',
  styleUrls: ['./puantaj-gun-list.component.scss'],
})
export class PuantajGunListComponent implements OnInit {
  @Input() data;
  rows;
  detayDate = false;
  tarih_format = tarih_format;
  constructor(
    @Optional() private ref: NbDialogRef<PuantajGunListComponent>,
    private puantajService: PuantajService) { }

  ngOnInit(): void {
    console.log("Gelen Data:", this.data);
  }

  close() {
    this.ref.close();
  }

  isLoading = false;
  dayCount = 0;
  calisanDetay(item? , dayCount?) {
    this.rows = [];
    this.dayCount = dayCount;

    this.isLoading = !this.isLoading;
    this.detayDate = !this.detayDate;

    if (this.detayDate === true) {
      let dataFilter = {
        taseron_id: item.taseron_id,
        santiye_id: item.santiye_id,
        date: item.date,
      }
      dataFilter = removeAllNull(dataFilter);


      this.puantajService.getPuantajDetay(dataFilter).subscribe(data => {
        console.log("Çalışan bilgisi response", data);
        this.isLoading = false;
        this.rows = data;
      }, err => {
        Swal.fire(responseKodlari.ER00 as SweetAlertOptions);
        this.isLoading = false;
      });
    }

  }
  exampleExcelLoading = false;
  excelToPuantaj() {
    this.exampleExcelLoading = true;
    const workBook = new Workbook();

    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 9,
      bold: true,
    };

    const tumPuantajlarSheet = workBook.addWorksheet('puantaj');
    const puantajBaslikRow = tumPuantajlarSheet.getRow(2);

    tumPuantajlarSheet.mergeCells('A1:D1');
    const puantajTitle = tumPuantajlarSheet.getRow(1);
    const puantajTitleCell = puantajTitle.getCell('A');
    puantajTitleCell.value = this.data[0].taseron_name + " Puantaj Raporu";
    puantajTitleCell.font = { size: 15 };

    puantajTitleCell.alignment = { horizontal: 'center', vertical: 'middle' };
    puantajTitleCell.style.border = bordered;

    puantajBaslikRow.height = 20;

    const meslekColumn = tumPuantajlarSheet.getColumn('A');
    meslekColumn.width = 50;

    const adiColumn = tumPuantajlarSheet.getColumn('B');
    adiColumn.width = 50;

    const meslekAdiTitle = puantajBaslikRow.getCell('A');
    meslekAdiTitle.value = 'Mesleği';
    meslekAdiTitle.font = font;

    const adiTitle = puantajBaslikRow.getCell('B');
    adiTitle.value = 'Adı';
    adiTitle.font = font;

    const mesaiTitle = puantajBaslikRow.getCell('C');
    mesaiTitle.value = 'Mesai';
    mesaiTitle.font = font;

    const durumTitle = puantajBaslikRow.getCell('D');
    durumTitle.value = 'Durum';
    durumTitle.font = font;



    workBook.xlsx
      .writeBuffer()
      .then((exp) => {
        const dosyaAdi = 'PuantajExcell';
        const blob = new Blob([exp], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = dosyaAdi + '.xlsx';
        fs.saveAs(blob, filename);
        Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
        this.exampleExcelLoading = false;
      })
      .catch((err) => this.exampleExcelLoading = false);


  }

}
