<!--Yüklenen Resimi Göster Burda-->
<nb-card style="width:100%;" *ngIf="!client" class="animated modalType fadeInRight" size="giant">
  <nb-card-header>
    <div class="row">
      <div class="col">
        <!-- <div class="row" for="created"> {{info?(info?.created_time * 1000 | date:tarih_format):''}}</div> -->
        <nb-user class="user-option" *ngIf="!editMod" [name]="info?info?.created_by_name:null"
          [title]="info?(info?.created_time * 1000 | date:tarih_format):''"
          [picture]="info?info?.created_by_image:null"></nb-user>

      </div>
      <div class="col">
        <!-- <nb-icon icon="close-outline" class="kapat-buton" (click)="closed()"></nb-icon> -->
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body  [nbSpinner]="isLoading">
    <div class="row mr-0">
      <div class="col-1 text-center" style=" margin:auto;">
        <nb-icon icon="arrow-ios-back-outline" class="left-buton" (click)="previous()" [ngClass]="{hide:!canPrevius}">
        </nb-icon>
      </div>

      <div class="col-10 justify-content-center d-flex " style="height: 100% !important;">
        <a [href]="img">      
            <img class="img-fluid"  [ngClass]="{'img-opening':isLoading}" [src]="img">
        </a>

      </div>

      <div class="col-1 text-center p-0" style=" margin:auto;">
        <nb-icon icon="arrow-ios-forward-outline" class="right-buton" (click)="next()" [ngClass]="{hide:!canNext}">
        </nb-icon>
      </div>
    </div>

   
  </nb-card-body>

  <nb-card-footer>
    <div class="w-100" *ngIf="info">
      <label><b>{{'APASIMAGELIGHTBOX.ACIKLAMA' | translate }} </b></label>
      <div>
        {{info?(info?.title):''}}

      </div>

      <div>
        <nb-toggle style="margin-left: auto; margin-right: 16px;" [checked]="info?.is_public" disabled status="danger">
          {{'APASIMAGELIGHTBOX.ISVERENGORUNTULEYEBILIR' | translate }}
        </nb-toggle>
      </div>

    </div>
 
  </nb-card-footer>
</nb-card>

<nb-card style="min-width:700px; overflow:hidden" *ngIf="client" class="animated modalType fadeInRight" size="giant">
  <nb-card-header>
    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
  color: #fff !important;
  float: right;
  font-size: 18px;">X</button>
  </nb-card-header>

  <nb-card-body>
    <div class="row mr-0">
      <!-- <div class="col-1 text-center" style=" margin:auto;">
        <nb-icon icon="arrow-ios-back-outline" class="left-buton" (click)="geriGit()" [ngClass]="{hide:!canPrevius}">
        </nb-icon>
      </div> -->

      <div class="col-12 justify-content-center d-flex " style="height: 100% !important;">
        <img class="img-fluid" [src]="img">
      </div>

      <!-- <div class="col-1 text-center p-0" style=" margin:auto;">
        <nb-icon icon="arrow-ios-forward-outline" class="right-buton" (click)="ileriGit()" [ngClass]="{hide:!canNext}">
        </nb-icon>
      </div> -->
    </div>

  </nb-card-body>

</nb-card>