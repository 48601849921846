import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, AfterViewInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'tarih-col',
  templateUrl: './tarih-col.component.html',
  styleUrls: ['./tarih-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TarihColComponent implements OnInit, AfterViewInit {

  @Input() tarih: Date;
  @Input() index: number = null;
  @Input() color: boolean = false;

  today = new Date();
  @ViewChild('elm') elm: ElementRef;
  constructor(private renderer: Renderer2,
              private cd: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.setColor();

  }

  ngOnInit(): void {
  }


  setColor() {
    if (this.color) {

      if (this.tarih > this.today) {
        this.elm?.nativeElement?.classList.add('tarihi_gelmemis');
      } else {
        this.elm?.nativeElement?.classList.add('tarihi_gecmis');

      }
      this.cd.detectChanges();
    }
  }

}
