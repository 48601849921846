import { createReducer, createSelector, on } from '@ngrx/store';
import * as OrgActions from './organization.actions';


const defaultOrgState = {
        id: undefined,
        name: undefined,
        apas_id: undefined,
        phone: undefined,
        email: undefined,
        country: undefined,
        city: undefined,
        address: undefined,
        statu: undefined,
        yonetici: undefined,
        moduls: [],
        isLoaded: false,
        payment: false,
        is_admin: false,
        uyariHide: true,
};

const orgInfoReducer = createReducer(
        defaultOrgState,
        on(OrgActions.SetOrgInfo, (state, { ORG }) => ({ ...state, ...ORG, isLoaded: true })),
        on(OrgActions.updateOrgInfo, (state, { update }) => ({ ...state, ...update })),
        on(OrgActions.uyariClose, (state) => {
            return { ...state, uyariHide: !state.uyariHide };
        }),
);

function OrgInfoReducer(state, action) {
        return orgInfoReducer(state, action);
}


export {
        defaultOrgState,
        OrgInfoReducer,
};
