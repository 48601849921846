import { Directive, ElementRef, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[apasRound]',
  providers: [DecimalPipe],

})
export class ApasRoundDirective {
  tag: string = 'ApasRoundPipeDirective --->';
  @Input() roundCount: number;

  element: ElementRef;

  constructor(private elementRef: ElementRef, private decimalPipe: DecimalPipe) {
    this.element = elementRef;
    if (this.element) {
      this.element.nativeElement.addEventListener('keydown', (event) => {
        this.onInputChange((event.target.value));
      });
      this.element.nativeElement.addEventListener('keyup', (event) => {
        this.onInputChange((event.target.value));
      });

    }
  }


  onInputChange(event) {
    if (!event) { return true; }
    const calcNumber = (this.transform(event));
    this.element.nativeElement.value = calcNumber;
  }
  transform(event: string): string {
    // eğer sayı küsüratlı ise
    // sayının tam ve küsürat kısmını tutacak olan array
    let numberArray: string[] = [];
    let newNumber: string = event;
    if (newNumber.includes(',')) {
      // newNumber = newNumber.replace(',', '.');
      numberArray = newNumber.split(',');
    } else if (newNumber.includes('.')) {
      numberArray = newNumber.split('.');
    }
    if (numberArray.length > 1 && numberArray[1].length > 0) {
      numberArray[1] = numberArray[1].substr(0, this.roundCount ? this.roundCount : 6);
      newNumber = `${numberArray[0]}.${numberArray[1]}`;
    }
    return newNumber;
  }

}
