
export const newNode = function (pParent, pNodeType, pId = null, pClass = null, pText = null,
  pWidth = null, pLeft = null, pDisplay = null, pColspan = null, pAttribs = null) {
  const vNewNode = pParent.appendChild(document.createElement(pNodeType));
  if (pAttribs) {
    for (let i = 0; i + 1 < pAttribs.length; i += 2) {
      vNewNode.setAttribute(pAttribs[i], pAttribs[i + 1]);
    }
  }
  if (pId) vNewNode.id = pId; // I wish I could do this with setAttribute but older IEs don't play nice
  if (pClass) vNewNode.className = pClass;
  if (pWidth) vNewNode.style.width = (isNaN(pWidth * 1)) ? pWidth : pWidth + 'px';
  if (pLeft) vNewNode.style.left = (isNaN(pLeft * 1)) ? pLeft : pLeft + 'px';
  if (pText) {
    if (pText.indexOf && pText.indexOf('<') === -1) {
      vNewNode.appendChild(document.createTextNode(pText));
    } else {
      vNewNode.insertAdjacentHTML('beforeend', pText);
    }
  }
  if (pDisplay) vNewNode.style.display = pDisplay;
  if (pColspan) vNewNode.colSpan = pColspan;
  return vNewNode;
};

export const makeInput = function (formattedValue, editable, type = 'text', value = null, choices = null, task = null) {
  if (!value) {
    value = formattedValue;
  }
  if (editable) {
    switch (type) {
      case 'startDate':
        // Take timezone into account before converting to ISO String
        // tslint:disable-next-line: max-line-length
        value = value ? new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : '';
        let start_min = '';
        let start_max = '';
        if (task) {
          start_min = task.getStartMin() ? new Date(task.getStartMin() * 1000).toISOString().split('T')[0] : '';
          start_max = task.getStartMax() ? new Date(task.getStartMax() * 1000).toISOString().split('T')[0] : '';
        }
        return `<input class="gantt-inputtable" type="date" min="${start_min}" max="${start_max}" value="${value}" pattern="\d{1,2}/\d{1,2}/\d{4}">`;
      case 'endDate':
        // Take timezone into account before converting to ISO String
        // tslint:disable-next-line: max-line-length
        value = value ? new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : '';
        let end_min = '';
        let end_max = '';

        if (task) {
          end_min = task.getEndMin() ? new Date(task.getEndMin() * 1000).toISOString().split('T')[0] : '';
          end_max = task.getEndMax() ? new Date(task.getEndMax() * 1000).toISOString().split('T')[0] : '';

          console.log(task.getName(), 'END MIN : ', end_min, 'END MAX: ', end_max)

        }
        return `<input class="gantt-inputtable" type="date" min="${end_min}" max="${end_max}" value="${value}" pattern="\d{1,2}/\d{1,2}/\d{4}">`;
      case 'resource':
        if (choices) {
          const found = choices.filter(c => c.id == value || c.name == value);
          if (found && found.length > 0) {
            value = found[0].id;
          } else {
            choices.push({ id: value, name: value });
          }
          return `<select>` + choices.map(c => `<option value="${c.id}" ${value == c.id ? 'selected' : ''} >${c.name}</option>`).join('') + `</select>`;
        } else {
          return `<input class="gantt-inputtable" type="text" value="${value ? value : ''}">`;
        }
      case 'cost':
        return formattedValue;
      case 'dur':
        return formattedValue;
      case 'metraj':
        return formattedValue;
      default:
        return `<input class="gantt-inputtable" value="${value ? value : ''}">`;
    }
  } else {
    return formattedValue;
  }
};

export const sLine = function (x1, y1, x2, y2, pClass) {
  const vLeft = Math.min(x1, x2);
  const vTop = Math.min(y1, y2);
  const vWid = Math.abs(x2 - x1) + 1;
  const vHgt = Math.abs(y2 - y1) + 1;

  const vTmpDiv = document.createElement('div');
  vTmpDiv.id = this.vDivId + 'line' + this.vDepId++;
  vTmpDiv.style.position = 'absolute';
  vTmpDiv.style.overflow = 'hidden';
  vTmpDiv.style.zIndex = '0';
  vTmpDiv.style.left = vLeft + 'px';
  vTmpDiv.style.top = vTop + 'px';
  vTmpDiv.style.width = vWid + 'px';
  vTmpDiv.style.height = vHgt + 'px';

  vTmpDiv.style.visibility = 'visible';

  if (vWid == 1) vTmpDiv.className = 'glinev';
  else vTmpDiv.className = 'glineh';

  if (pClass) vTmpDiv.className += ' ' + pClass;

  this.getLines().appendChild(vTmpDiv);

  if (this.vEvents.onLineDraw && typeof this.vEvents.onLineDraw === 'function') {
    this.vEvents.onLineDraw(vTmpDiv);
  }

  return vTmpDiv;
};


export const getArrayLocationByID = function (pId) {
  const vList = this.getList();
  for (let i = 0; i < vList.length; i++) {
    if (vList[i].getID() == pId)
      return i;
  }
  return -1;
};

export const CalcTaskXY = function () {
  let vID;
  const vList = this.getList();
  let vBarDiv;
  let vTaskDiv;
  let vParDiv;
  let vLeft, vTop, vWidth;
  const vHeight = Math.floor((this.getRowHeight() / 2));

  for (let i = 0; i < vList.length; i++) {
    vID = vList[i].getID();
    vBarDiv = vList[i].getBarDiv();
    vTaskDiv = vList[i].getTaskDiv();
    if ((vList[i].getParItem() && vList[i].getParItem().getGroup() == 2)) {
      vParDiv = vList[i].getParItem().getChildRow();
    } else vParDiv = vList[i].getChildRow();

    if (vBarDiv) {
      vList[i].setStartX(vBarDiv.offsetLeft + 1);
      vList[i].setStartY(vParDiv.offsetTop + vBarDiv.offsetTop + vHeight - 1);
      vList[i].setEndX(vBarDiv.offsetLeft + vBarDiv.offsetWidth + 1);
      vList[i].setEndY(vParDiv.offsetTop + vBarDiv.offsetTop + vHeight - 1);
    }
  }
};

