<div>
    <div class="row py-4 " style="background: #fff; border-radius: 7px; margin: auto;">
        <div class="d-flex w-100 pl-2">
            <div *ngIf="formValue">
                <span *ngFor="let item of formValue.value | keyvalue; let i = index;">
                    <span
                        *ngIf="item.key !=='count' && item.key !=='page' && item.key !=='order_by'  && item.key !=='search'">
                        <span [ngSwitch]="typeOfVal(item.value)">
                            <!-- Boolean ise-->
                            <span *ngSwitchCase="'boolean'" (click)="sagLayoutHide = true">
                                <span *ngIf="item.value && filterKeyGoster(item.key)" class="filterBadge"
                                    [style]="'background:'+colorSet[i+1]">
                                    <b>{{'FILTER.'+ (formValue.get(item.key.toString())['translate_key'] ?
                                        formValue.get(item.key.toString())['translate_key'] : item.key) | translate }}</b>
                                    <nb-icon icon="close-outline" *ngIf="item.value && filterKeyGoster(item.key)"
                                        (click)="clearFilterItem(item.key)"></nb-icon>
                                </span>

                            </span>
                            <!-- Boolean ise-->

                            <!-- String ise-->
                            <span *ngSwitchCase="'string'">
                                <span *ngIf="item.value && filterKeyGoster(item.key)" class="filterBadge"
                                    [style]="'background:'+colorSet[i+1]" (click)="sagLayoutHide = true">
                                    <b>{{'FILTER.'+ (formValue.get(item.key.toString())['translate_key'] ?
                                        formValue.get(item.key.toString())['translate_key'] : item.key) | translate }}</b>
                                    <nb-icon icon="close-outline" *ngIf="item.value && filterKeyGoster(item.key)"
                                        (click)="clearFilterItem(item.key)"></nb-icon>
                                </span>
                            </span>
                            <!-- String ise-->

                            <!-- Obje İse-->
                            <span *ngSwitchCase="'object'">
                                <span *ngFor="let row of item.value | keyvalue; let j = index;">
                                    <span *ngIf="row.value" class="filterBadge"
                                        [style]="'background:'+colorSet[colorSet.length - j]"
                                        (click)="sagLayoutHide = true">
                                        {{'FILTER.'+ (formValue.get(item.key.toString()).get(row.key)['translate_key'] ?
                                        formValue.get(item.key.toString()).get(row.key)['translate_key'] : row.key) | translate }}
                                        <nb-icon icon="close-outline" *ngIf="row.value"
                                            (click)="clearFilterItem(row.key,item.key)"></nb-icon>

                                    </span>
                                    <!-- {{item.value && filterKeyGoster(row.key)  ? row.key : ''}} -->

                                </span>
                                <span *ngIf="item.key === 'start_date' || item.key==='end_date'">
                                    <span *ngIf="item.value" class="filterBadge" [style]="'background:'+colorSet[i+1]"
                                        (click)="sagLayoutHide = true">
                                        <b>{{'FILTER.'+ (formValue.get(item.key.toString())['translate_key'] ?
                                            formValue.get(item.key.toString())['translate_key'] : item.key) | translate }}</b>
                                        <nb-icon icon="close-outline" *ngIf="item.value && filterKeyGoster(item.key)"
                                            (click)="clearFilterItem(item.key)"></nb-icon>
                                    </span>

                                </span>

                            </span>
                            <!-- Obje İse-->

                            <!--Array İse-->
                            <!-- MURAT TODO BUNU NEDEN KONTROL ETMİŞTİK -->

                             <!-- item.value.lenght > 0 -->
                            <span *ngSwitchCase="'array'">
                                <span *ngIf="item.value  && filterKeyGoster(item.key)" class="filterBadge"
                                    [style]="'background:'+colorSet[i+1]" (click)="sagLayoutHide = true">
                                    <b> {{'FILTER.'+ (formValue.get(item.key.toString())['translate_key'] ?
                                        formValue.get(item.key.toString())['translate_key'] : item.key) | translate }}
                                    </b>
                                    <nb-icon icon="close-outline"
                                        *ngIf="item.value && filterKeyGoster(item.key)"
                                        (click)="clearFilterItem(item.key)"></nb-icon>
                                </span>
                            </span>
                            <!--Array İse-->

                            <!--Number İse-->
                            <span *ngSwitchCase="'number'">
                                <span *ngIf="item.value && filterKeyGoster(item.key)" class="filterBadge"
                                    [style]="'background:'+colorSet[i+1]" (click)="sagLayoutHide = true">
                                    <b>{{'FILTER.'+ (formValue.get(item.key.toString())['translate_key'] ?
                                        formValue.get(item.key.toString())['translate_key'] : item.key) | translate }}</b>
                                    <nb-icon icon="close-outline" *ngIf="item.value && filterKeyGoster(item.key)"
                                        (click)="clearFilterItem(item.key)"></nb-icon>
                                </span>
                            </span>
                            <!--Number İse-->

                            <!--  -->

                        </span>
                    </span>
                </span>
                <!--
                            Filtreyi Temizle Şimdilik kapattım fonksiyonu hazır  
                            <button nbButton (click)="formClear()" status="danger" size="small" hero style="margin-top: -15px !important;">
                            Filtreyi Temizle
                            <nb-icon icon="close-outline"></nb-icon> 
                    
                    </button> -->

            </div>

        </div>
        <div class="col-md-5">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"
                        style="border-color: #e4e9f2;  background: #f7f9fc;">
                        <nb-icon icon="search-outline"></nb-icon>
                    </span>
                </div>
                <input type="text" class="form-control src-icn serachInputFilter" [placeholder]="araText" aria-label="Username"
                    aria-describedby="basic-addon1" [(ngModel)]="value"
                    style="border-left: none;border-color: #e4e9f2;">

                <div class="input-group-append button-filter-kapsayici " style="cursor: pointer;" *ngIf="showFilterButton" (click)="sagLayoutHide = !sagLayoutHide" >
                    <span class="input-group-text aps-filter-button "> <!--  style="border-color: #e4e9f2;  background: #f7f9fc;" -->
                        <nb-icon icon="bar-chart-2-outline" style="transform: rotate(272deg);"> </nb-icon>
                        Filtrele
                    </span>
                </div>

                <!-- <button size="small" *ngIf="showFilterButton" (click)="sagLayoutHide = !sagLayoutHide" nbButton
                    class="apsFilterButonu">
                </button> -->

            </div>
        </div>
        <ng-container *ngTemplateOutlet="filterSag"></ng-container>

        <div class="col-md filterSaga">

            <ng-container *ngTemplateOutlet="islemler"></ng-container>

        </div>
    </div>


    <div *ngIf="sagLayoutHide" class="apsDrop" (click)="sagLayoutHide = false"></div>
    <div class="sagLayout animated fadeInRight" cdkDrag *ngIf="sagLayoutHide">
        <nb-card style="border-top-width: 0rem !important;height: 100% !important;">
            <!--Header-->
            <nb-card-header cdkDragHandle>
                Filtrele
                <button nbButton (click)="sagLayoutHide = false" size="tiny" class="m-close"
                    style="background: none !important;   color: #000 !important;  float: right;  font-size: 18px;">X</button>
            </nb-card-header>
            <!--// Header-->

            <!--Body-->
            <nb-card-body *ngIf="body">
                <ng-container *ngTemplateOutlet="body"></ng-container>

                <div class="text-center">
                    <button nbButton size="small" status="warning" class="mt-5" (click)="getFilteredData()">Sonuçları
                        Göster</button>
                </div>

            </nb-card-body>
            <!--// Body-->
        </nb-card>
    </div>

</div>