import { formatDateStr } from './utils/date_utils';
import { addListenerInputCell, addListenerClickCell, addListenerButtonClick } from './events';
import { newNode, makeInput } from './utils/draw_utils';

export const COLUMN_ORDER = [
  'vShowRes',
  'vShowDur',
  'vShowComp',
  'vShowStartDate',
  'vShowEndDate',
  'vShowPlanStartDate',
  'vShowPlanEndDate',
  'vShowCost',
  'vShowMetraj',
  'vShowPursantaj',
  'vAdditionalHeaders',
  'vShowAddEntries',
];


const COLUMNS_TYPES = {
  'vShowRes': 'res',
  'vShowDur': 'dur',
  'vShowComp': 'comp',
  'vShowStartDate': 'startdate',
  'vShowEndDate': 'enddate',
  'vShowPlanStartDate': 'planstartdate',
  'vShowPlanEndDate': 'planenddate',
  'vShowCost': 'cost',
  'vShowMetraj': 'metraj',
  'vShowPursantaj': 'pursantaj',
  'vShowAddEntries': 'addentries',
};

export const draw_header = function (column, i, vTmpRow, vTaskList, vEditable, vEventsChange, vEvents,
  vDateTaskTableDisplayFormat, vAdditionalHeaders, vFormat, vLangs, vLang, vResources, Draw) {
  let vTmpCell, vTmpDiv;

  if ('vShowRes' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gres');
    const text = makeInput(vTaskList[i].getResource(), vEditable, 'resource', vTaskList[i].getResource(), vResources);
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setResource(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'res', Draw, 'change');
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'res');
  }
  if ('vShowDur' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gdur');
    // tslint:disable-next-line: max-line-length
    const text = makeInput(vTaskList[i].getDuration(vFormat, vLangs[vLang]), vEditable, 'dur', vTaskList[i].getDuration());
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setDuration(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'dur', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'dur');
  }
  if ('vShowComp' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gcomp');
    const text = makeInput(vTaskList[i].getCompStr(), vEditable, 'percentage', vTaskList[i].getCompVal());
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => { task.setComp(e.target.value); task.setCompVal(e.target.value); };
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'comp', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'comp');
  }
  if ('vShowStartDate' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gstartdate');
    const v = formatDateStr(vTaskList[i].getStartVar(), vDateTaskTableDisplayFormat, vLangs[vLang]);
    const text = makeInput(v, vEditable, 'startDate', vTaskList[i].getStartVar(), null, vTaskList[i]);
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setStart(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'start', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'start');
  }
  if ('vShowEndDate' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'genddate');
    const v = formatDateStr(vTaskList[i].getEndVar(), vDateTaskTableDisplayFormat, vLangs[vLang]);
    const text = makeInput(v, vEditable, 'endDate', vTaskList[i].getEndVar(), null, vTaskList[i]);
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setEnd(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'end', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'end');
  }
  if ('vShowPlanStartDate' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gplanstartdate');
    // tslint:disable-next-line: max-line-length
    const v = vTaskList[i].getPlanStart() ? formatDateStr(vTaskList[i].getPlanStart(), vDateTaskTableDisplayFormat, vLangs[vLang]) : '';
    const text = makeInput(v, vEditable, 'date', vTaskList[i].getPlanStart());
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setPlanStart(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'planstart', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'planstart');
  }
  if ('vShowPlanEndDate' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gplanenddate');
    // tslint:disable-next-line: max-line-length
    const v = vTaskList[i].getPlanEnd() ? formatDateStr(vTaskList[i].getPlanEnd(), vDateTaskTableDisplayFormat, vLangs[vLang]) : '';
    const text = makeInput(v, vEditable, 'date', vTaskList[i].getPlanEnd());
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setPlanEnd(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'planend', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'planend');
  }
  if ('vShowCost' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gcost');
    const text = makeInput(vTaskList[i].getCost(), vEditable, 'cost');
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setCost(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'cost', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'cost');
  }
  if ('vShowMetraj' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gmetraj');
    const text = makeInput(vTaskList[i].getMetraj(), vEditable, 'metraj');
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setMetraj(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'metraj', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'metraj');
  }
  if ('vShowPursantaj' === column) {
    vTmpCell = newNode(vTmpRow, 'td', null, 'gpursantaj');
    const text = makeInput(vTaskList[i].getPursantaj(), vEditable, 'pursantaj');
    vTmpDiv = newNode(vTmpCell, 'div', null, null, text);
    const callback = (task, e) => task.setPursantaj(e.target.value);
    addListenerInputCell(vTmpCell, vEventsChange, callback, vTaskList, i, 'pursantaj', Draw);
    addListenerClickCell(vTmpCell, vEvents, vTaskList[i], 'pursantaj');
  }

  if ('vAdditionalHeaders' === column && vAdditionalHeaders) {
    // tslint:disable-next-line: forin
    for (const key in vAdditionalHeaders) {
      const header = vAdditionalHeaders[key];
      const css = header.class ? header.class : `gadditional-${key}`;
      const data = vTaskList[i].getDataObject();
      vTmpCell = newNode(vTmpRow, 'td', null, `gadditional ${css}`);
      vTmpDiv = newNode(vTmpCell, 'div', null, null, data ? data[key] : '');
      addListenerClickCell(vTmpCell, vEvents, vTaskList[i], `additional_${key}`);
    }
  }

  if ('vShowAddEntries' === column) {
    console.log('vShowAddEntries');
    vTmpCell = newNode(vTmpRow, 'td', null, 'gaddentries');
    const callback = (task, e) => { };

    const detailButton = '<button class=\'addButtonStyle\'>!</button>';
    const infoDiv = newNode(vTmpCell, 'span', null, null, detailButton);
    addListenerButtonClick(infoDiv, vEventsChange, callback, vTaskList, i, 'infoentries', Draw.bind(this));

    if (vEditable) {
      const addButton = '<button class=\'addButtonStyle\'>+</button>';
      const addDiv = newNode(vTmpCell, 'span', null, null, addButton);
      addListenerButtonClick(addDiv, vEventsChange, callback, vTaskList, i, 'addentries', Draw.bind(this));

      const removeButton = '<button class=\'addButtonStyle\'>-</button>';
      const removeDiv = newNode(vTmpCell, 'span', null, null, removeButton);
      addListenerButtonClick(removeDiv, vEventsChange, callback, vTaskList, i, 'removeentries', Draw.bind(this));
    }
  }
};

export const draw_bottom = function (column, vTmpRow, vAdditionalHeaders) {
  if ('vAdditionalHeaders' === column && vAdditionalHeaders) {
    // tslint:disable-next-line: forin
    for (const key in vAdditionalHeaders) {
      const header = vAdditionalHeaders[key];
      const css = header.class ? header.class : `gadditional-${key}`;
      newNode(vTmpRow, 'td', null, `gspanning gadditional ${css}`, '\u00A0');
    }
  } else {
    const type = COLUMNS_TYPES[column];
    newNode(vTmpRow, 'td', null, `gspanning g${type}`, '\u00A0');
  }
};


export const draw_task_headings = function (column, vTmpRow, vLangs, vLang, vAdditionalHeaders, vEvents, vEditable) {
  let nodeCreated;
  if ('vAdditionalHeaders' === column && vAdditionalHeaders) {
    // tslint:disable-next-line: forin
    for (const key in vAdditionalHeaders) {
      const header = vAdditionalHeaders[key];
      const text = header.translate ? vLangs[vLang][header.translate] : header.title;
      const css = header.class ? header.class : `gadditional-${key}`;
      nodeCreated = newNode(vTmpRow, 'td', null, `gtaskheading gadditional ${css}`, text);

    }
  } else {
    const _type = COLUMNS_TYPES[column];

    nodeCreated = newNode(vTmpRow, 'td', null, `gtaskheading g${_type}`, vLangs[vLang][_type]);
    addListenerClickCell(nodeCreated, vEvents, { hader: true, column }, _type);

  }
};
