import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { CariModel } from '../../../../../models/cari.model';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../../../../global-functions';
import { OdemePlaniDetayComponent } from '../odeme-plani-detay/odeme-plani-detay.component';
import { MuhasebeService } from '../../../../..//muhasebe-service/muhasebe.service';
@Component({
  selector: 'odeme-plani-list',
  templateUrl: './cari-odeme-plani-list.component.html',
  styleUrls: ['./cari-odeme-plani-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CariOdemePlaniListComponent implements OnInit, OnChanges {

  @Input() cari: CariModel;
  form: FormGroup;
  rows = [];
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private muhasebeService: MuhasebeService,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cari) {
      this.rows = [];
      this.form.get('cari_id').setValue(changes.cari.currentValue.id);
    }
  }

  ngOnInit(): void {

  }

  initForm() {
    this.form = this.fb.group({
      page: 1,
      count: 20,
      cari_id: [],
      search: [],
    });

    this.form.get('cari_id').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getOdemePlanList();
      }, 150);
    });
  }

  getOdemePlanList() {
    console.log('ÖDEME PLANI ALINIYOR : ', this.form.get('cari_id').value);
    this.isLoading = true;
    this.muhasebeService.getOdemePlani(removeAllNull(this.form.value)).subscribe((resp: any) => {
      resp.forEach(odemePlan => {
        odemePlan = this.clearItem(odemePlan);
        this.rows = addOrUpdate(this.rows, odemePlan);
      });
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      console.log('loadin ödeme plan error : ', err);
    });
  }

  clearItem(data) {
    data.ilk_taksit_tarihi = convertDateWithTZ(data, 'ilk_taksit_tarihi');
    data.sonraki_taksit_tarihi = convertDateWithTZ(data, 'sonraki_taksit_tarihi');
    return data;
  }

  openDetailView(instanceId: number) {

    this.nbDialogService.open(OdemePlaniDetayComponent, {
      context: {
        instanceId: instanceId,
        cari: this.cari,
      }, closeOnBackdropClick: false,
    }).onClose.subscribe(() => {
      setTimeout(() => {
        this.rows = [];
        this.getOdemePlanList();

      }, 1000);
    });
  }
}
