<nb-card class="modalType animated fadeInRight" size="giant" style="width: 75%;" [formGroup]="form"
  nbSpinnerSize="tiny">
  <nb-card-header [class]="arkaplan+' d-flex align-items-center justify-content-between'">
    <span>
      {{gorev?.title}}
    </span>
    <button nbButton (click)="closed()" status="basic" class="m-close">X</button>
  </nb-card-header>

  <nb-card-body [nbSpinner]="isPatching">

    <div class="text-right">
      <button nbButton status="primary" size="small" *ngIf="gorev?.is_mine && !gorevDuzenle" (click)="duzenle()"> {{
        'GOREVLER.DUZENLE' | translate }}</button>
      <button nbButton status="warning" size="small" *ngIf="gorev?.is_mine" (click)="sil()"> {{ 'GOREVLER.SIL' |
        translate }}</button>
      <button nbButton status="danger" size="small" *ngIf="gorev?.is_mine && gorevDuzenle" (click)="duzenle()"> {{
        'GOREVLER.IPTAL' | translate }}</button>

    </div>
    <table class="table table-striped table-borderless">
      <tbody>
        <tr>
          <th width="30%"> {{ 'GOREVLER.GOREVVER' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>{{gorev?.created_by_name}}</td>
        </tr>

        <tr>
          <th> Başlık :
            <span style="float: right;">:</span>
          </th>
          <td>

            <!--İçerik Göster-->
            <ng-container *ngIf="!gorevDuzenle">{{ gorev?.title }}</ng-container>
            <!--// İçerik Göster-->

            <!-- İçerik Düzenle-->
            <ng-container *ngIf="gorevDuzenle">
              <div class="form-group">
                <input nbInput fullWidth [placeholder]="'GOREVLER.ICERIK' | translate" formControlName="title"
                  apasInvalid>
              </div>

            </ng-container>
            <!-- //İçerik Düzenle -->
          </td>
        </tr>

        <tr>
          <th> {{ 'GOREVLER.ICERIK' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>

            <!--İçerik Göster-->
            <ng-container *ngIf="!gorevDuzenle">{{ gorev?.content }}</ng-container>
            <!--// İçerik Göster-->

            <!-- İçerik Düzenle-->
            <ng-container *ngIf="gorevDuzenle">
              <div class="form-group">
                <textarea nbInput [placeholder]="'GOREVLER.ICERIK' | translate" fullWidth formControlName="content"
                  apasInvalid>
              </textarea>
              </div>

            </ng-container>
            <!-- //İçerik Düzenle -->
          </td>
        </tr>
        <tr>
          <th> {{ 'GOREVLER.BITISTARIHI' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>
            <!--Tarih Göster-->
            <ng-container *ngIf="!gorevDuzenle">
              <b>{{ gorev?.end_time ? (gorev?.end_time | date: 'dd/MM/yyyy') : '--/--/----'}}</b>
            </ng-container>
            <!--// Tarih Göster-->

            <!--Tarih Ddüzenle-->
            <ng-container *ngIf="gorevDuzenle">

              <div class="col p-0">
                <div class="form-group">
                  <input formControlName="end_time" nbInput fullWidth [nbDatepicker]="formpicker" dateOnly
                    apasInvalid />
                  <nb-datepicker #formpicker></nb-datepicker>
                </div>
              </div>
            </ng-container>
            <!--// Tarih Düzenle-->
          </td>
        </tr>

        <tr>
          <th> {{ 'GOREVLER.GOREVLILER' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>
            <ng-container *ngIf="!gorevDuzenle">
              <b style="font-weight: 400; display: inline-block;"
                *ngFor="let item of gorev?.assign_to; let  i = index;">
                <span class="badge badge-secondary text-secondary linked-div m-1"
                  [style]="'background:'+colorSet[i+1]+';' + 'font-size: medium;'">
                  {{item?.name}}
                </span>
              </b>
            </ng-container>

            <ng-container *ngIf="gorevDuzenle">
              <div class="form-group">
                <personel-select [multiple]="true" [bindValue]="'apas_id'" formControlName="assign_to_ids" apasInvalid>
                </personel-select>
              </div>
            </ng-container>
          </td>
        </tr>


        <tr>
          <th> Gözlemciler :
            <span style="float: right;">:</span>
          </th>
          <td>
            <ng-container *ngIf="!gorevDuzenle">
              <b style="font-weight: 400; display: inline-block;"
                *ngFor="let item of gorev?.observable_names; let  i = index;">
                <span class="badge badge-secondary text-secondary linked-div m-1"
                  [style]="'background:'+colorSet[i+1]+';' + 'font-size: medium;'">
                  {{item}}
                </span>
              </b>
            </ng-container>

            <ng-container *ngIf="gorevDuzenle">
              <personel-select [multiple]="true" [bindValue]="'apas_id'" formControlName="observers">
              </personel-select>
            </ng-container>
          </td>
        </tr>


        <tr>
          <th> {{ 'GOREVLER.ONEMSEVIYE' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>
            <ng-container *ngIf="!gorevDuzenle"> {{ gorev?.durum }}</ng-container>


            <ng-container *ngIf="gorevDuzenle">
              <div class="form-group">
                <ng-select [items]="importance_level" bindValue="value" appendTo="body" apasInvalid
                  class="ng-select-without-bottom-margin" [placeholder]="'GOREVLER.ONEMSEVIYE' | translate "
                  name="importance_level" formControlName="importance_level">
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [class]="item?.bicim+' onem-bicim'">{{item?.label}}</div>
                  </ng-template>
                </ng-select>
              </div>
            </ng-container>

          </td>
        </tr>
        <tr>
          <th> {{ 'GOREVLER.YUZDE' | translate }}
            <span style="float: right;">:</span>
          </th>
          <td>
            <div class="d-flex">
              <span (click)="setValue(percentage.value - 10)" class="linked-div">
                <nb-icon icon="minus-outline" style="color:#000;"></nb-icon>
              </span>
              <nb-progress-bar [value]="percentage.value" [status]="status" [displayValue]="true" style="width: 100%;">
              </nb-progress-bar>
              <span (click)="setValue(percentage.value + 10)" class="linked-div">
                <nb-icon icon="plus-outline" style="color:#000;"></nb-icon>
              </span>

            </div>

            <div class="text-right mt-3">
              <nb-checkbox (checkedChange)="toggle($event)" [checked]="gorevTamamlandi" *ngIf="gorev?.can_edit">
                {{ 'GOREVLER.TAMAMLADI' | translate }}
              </nb-checkbox>
            </div>
          </td>
        </tr>

        <tr *ngIf="gorevTamamlandi">
          <th> 
            Görev Sonucu Depodan Malzeme Çıkışı Yap : 
            <span style="float: right;">:</span>
          </th>
          <td>
            <button nbButton size="small" (click)="urunIslemYap()"
                    style="background:#e1ca05 !important; color:#fff;">
                    <nb-icon icon="arrowhead-right-outline"></nb-icon> Ürün Çıkışı Yap
                  </button>
          </td>
        </tr>
      </tbody>
    </table>
<!-- 
    <div *ngIf="gorevTamamlandi">
      <div>
        <b>Görev Sonucu Depodan Malzeme Çıkışı Yap : </b>
      </div>

      <div [formGroup]="malzemeForm">
        <div formArrayName="liste" *ngFor="let mutabakats of mazelemeFormArray.controls; let i = index;">

          <div class="row" [formGroupName]="i">

            <div class="col-md-3 form-group">
              <ng-select formControlName="warehouse_id" apasInvalid class="noBgSelect outoo uzun-select" appendTo="body"
                [placeholder]="'DEPO.DEPOSECINIZ' | translate" bindLabel="title" bindValue="id" [items]="depolar"
                [searchable]="true" [clearable]="true" [searchable]="true" #depo [closeOnSelect]="true">
                <ng-template ng-label-tmp let-item="item">
                  <b style="color:#ff7043;"> {{item.title}}</b>
                </ng-template>
              </ng-select>
            </div>


            <div class="col-md-4 form-group">
              <apas-product-component [showHizmetButton]="false" formControlName="product_id" apasInvalid></apas-product-component>
            </div>

            <div class="col-md-3 form-group">
              <ng-select #agreeSelect class="uzun-select ng-select-without-bottom-margin" [items]="brand$ | async"
                [loading]="brandLoading" [typeahead]="brandInput$" appendTo="body" [addTag]="false"
                [placeholder]="'DEPO.MARKA' | translate" bindLabel="title" bindValue="id" formControlName="brand_id"
                [searchable]="true" [selectOnTab]="true" apasInvalid>
              </ng-select>
            </div>

            <div class="col-md-2 form-group">
              <input type="text" nbInput paraDirective [encrypt]="false" [decrypt]="false"
                [plcholder]="'DEPO.MIKTAR'|translate" fullWidth status="basic" formControlName="amount"
                formControlName="amount" apasInvalid>
            </div>
            <div class="col-md-10">
              <input type="text" fullWidth placeholder="Açıklama" nbInput formControlName="note" apasInvalid>
            </div>
            <div class="col-md-2 form-group text-right">
              <button nbButton status="warning" (click)="removeMalzemeItem(i)">-</button>
              <button nbButton status="primary" (click)="addMalzemeItem()">+</button>
            </div>

          </div>

        </div>
      </div>

      <div class="col-md-12 mt-2 text-center">
        <button nbButton status="warning" type="submit" (click)="urunCikis()">
          Malzeme Çıkışı Yap
        </button>
      </div>

    </div> -->
    <div class="d-flex justify-content-center" *ngIf="gorevDuzenle">
      <button nbButton status="primary" type="submit" *ngIf="gorev?.can_edit" (click)="patchForm()">
        Güncelle
      </button>
    </div>
  </nb-card-body>

</nb-card>