import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbButtonComponent } from '@nebular/theme';
import * as moment from 'moment-timezone';
import { PursantajModel } from '../../../models/pursantaj.model';
import { PursantajService } from '../../../services/pursantaj.service';
import { SantiyeService } from '../../../services/santiye.service';
import { PursantajEkleComponentUpdateType } from '../../pursantaj/pursantaj-ekle/pursantaj-ekle.component';
import { JSGantt } from '../../Apas-Modules/apas-gantt/jsgantt';
import { isDrawing, scroolPostion } from '../../Apas-Modules/apas-gantt/events';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { currency, getBirim, responseKodlari } from '../../../pages/constants';
import { convertDateWithTZ, formatNumberWithCurrency } from '../../../pages/global-functions';


@Component({
  selector: 'showgantt',
  templateUrl: './showgantt.component.html',
  styleUrls: ['./showgantt.component.css'],
  providers: [PursantajService, SantiyeService],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(0)',
        }),
      ),
      state(
        'out',
        style({
          transform: 'translateY(-100%)',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class ShowganttComponent implements OnInit, AfterViewInit {
  @ViewChildren('pursantajid') btns: QueryList<ElementRef>;
  @ViewChild('ganttEditorContainer', { static: true }) ganttEditorContainer: ElementRef;
  public formats = ['Hour', 'Day', 'Week', 'Month', 'Quarter'];
  public data: any;
  btn: NbButtonComponent;
  santiyeId: number;
  buttonStyle = 'color: white; background: #01579B !important; border: none !important; font-size: 14px; height: 20px; width: auto; border-radius: 2px !important;';
  editorg;
  public id = 'anggantteditor' + Math.floor(Math.random() * 1000000);
  start_day: any;
  end_day: any;
  format = 'week';
  can_edit: boolean = false;
  isLoading: boolean = false;
  editorOptions;
  editor: any;
  drawStatus = isDrawing;
  showInfoBox = false;
  currentPursantaj: number;
  currentTask;
  forceRefresh: boolean = false;
  duzenle: boolean = false;
  sozlesme_worth: number;
  sozlesme_currency: number;
  @ViewChild('showgantt') mainView: ElementRef;

  constructor(
    private pursantajService: PursantajService,
    private _activatedRouter: ActivatedRoute,
    private _router: Router,
  ) {

    this._activatedRouter.params.subscribe(data => {

      this.santiyeId = data.santiyeId;

      if (!this.santiyeId) {
        this._router.navigate(['pages/dashboard']);
      }
    });
  }


  gerigit() {
    this._router.navigate(['pages/dashboard']);
  }


  ngAfterViewInit() {
    this.mainView.nativeElement.parentElement.classList.add('h-100');
    this.mainView.nativeElement.parentElement.parentElement.classList.add('h-100');

    this.mainView.nativeElement.classList.add('h-100');
    const currentStyle = this.mainView.nativeElement.classList;
  }

  ngOnInit() {
    this.createGantt();

    this.getPursantajList();
  }

  createGantt() {
    const g = this.editor = new (<any>JSGantt).GanttChart(this.ganttEditorContainer.nativeElement, this.format);

    if (g.getDivId() != null) {
      this.editorOptions = {
        vFormat: 'day',
        vShowAddEntries: true,
        vEditable: false,
        vHide: false,
        vUseSort: 1,
        vCanEdit: false,
        vShowRes: 0,
        vShowComp: 0,
        vUseToolTip: 0,
        vCaptionType: 'Complete',
        vLang: 'tr',
        vShowCost: 1,
        vShowMetraj: 1,
        vShowPursantaj: 1,
        vUseMove: 1,
        vDateInputFormat: 'dd/mm/yyyy',
        vQuarterColWidth: 100,
        vRowHeight: 45,
        vEventsChange: {
          start: (task, event, cell, column) => {
            this.editor.setScrollTo(scroolPostion);
            this.currentTask = event;
            // tslint:disable-next-line: max-line-length
            this.pursantajGuncelle(event.getAllData().pName, event.getOriginalID(), PursantajEkleComponentUpdateType.DateRate, event.getStart());
          },
          end: (task, event, cell, column) => {
            this.currentTask = task;
            this.editor.setScrollTo(scroolPostion);
            // tslint:disable-next-line: max-line-length
            this.pursantajGuncelle(event.getAllData().pName, event.getOriginalID(), PursantajEkleComponentUpdateType.DateRate, null, event.getEnd());
          },
          taskname: (task, event, cell, column) => {
            this.currentTask = task;
            // tslint:disable-next-line: max-line-length
            this.pursantajGuncelle(event.getName(), event.getAllData().pDataObjec.pID, PursantajEkleComponentUpdateType.Name);
          },
          pursantaj: (task, event, cell, column) => {
            // tslint:disable-next-line: max-line-length
            this.pursantajGuncelle(event.getPursantaj(), event.getAllData().pDataObjec.pID, PursantajEkleComponentUpdateType.Rate);

          },
          move: (task, start, end) => {
            this.editor.setScrollTo(scroolPostion);
            this.currentTask = task;
            // tslint:disable-next-line: max-line-length
            this.pursantajGuncelle(task.getAllData().pName, task.getOriginalID(), PursantajEkleComponentUpdateType.DateRate, start, end);
          },
          addentries: (task, event, cell, column) => {
            this.yeniEkle(event.getOriginalID());
          },
          removeentries: (task, event, cell, column) => {
            this.deleteItem(event);
            if (this.currentTask && event.getOriginalID() == this.currentTask.getOriginalID()) {
              this.hideInfo();
            }
          },
          infoentries: (task, event, cell, column) => {
            this.currentTask = task;
            this.duzenle = false;
            this.detayGoster(event);
          },
          depend: (task1, task2, pType) => {
            this.setDepend(task1, task2, pType);
          },
        },
        vColumnOrder: ['vShowAddEntries', 'vShowPursantaj', 'vShowComp', 'vShowStartDate', 'vShowEndDate', 'vShowDur', 'vShowCost', 'vShowMetraj'],
        vEvents: {
          afterDraw: () => {
            const slider = g.getChartBody();
            let isDown = false;
            let startX;
            let startY;
            let scrollLeft;

            if (slider) {
              slider.addEventListener('mousedown', (e) => {
                isDown = true;
                e.preventDefault();
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY;
                scrollLeft = slider.scrollLeft;
              });
              slider.addEventListener('mouseleave', () => {
                isDown = false;
              });
              slider.addEventListener('mouseup', () => {
                isDown = false;
              });
              slider.addEventListener('mousemove', (e) => {
                if (!isDown || isDrawing) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walkX = (x - startX) * 1; // change scroll-speed here
                slider.scrollLeft = scrollLeft - walkX;
                window.scrollTo(0, window.scrollY + (startY - e.pageY));
              });
            }
          },
        },
      };
      g.setOptions(this.editorOptions);
      g.setUseRowHlt(1);
      g.setScrollTo('today');
      g.Draw();
    }
  }
  getPursantajList() {
    this.pursantajService.getNewPursantajList(this.santiyeId).subscribe(resp => {
      this.start_day = convertDateWithTZ(resp, 'sozlesme_start');
      this.can_edit = resp.can_edit;
      this.end_day = convertDateWithTZ(resp, 'sozlesme_end');
      this.data = resp.pursantaj_list;
      this.sozlesme_worth = resp.sozlesme_worth;
      this.sozlesme_currency = resp.sozlesme_currency;
      this.drawGanttItems(resp);
    });
  }


  findTask(pursantajId) {
    const liste = this.editor.vTaskList;

    let findPurs;

    liste.map(elm => {
      if (elm.getOriginalID() == pursantajId) {
        findPurs = elm;
      }
    });

    return findPurs;
  }
  calculatCost(pursantaj_rate, completed_percentage?) {
    let crry = null;
    if (this.sozlesme_currency) {

      const item = currency.find((val) => val.value === this.sozlesme_currency);
      if (item) {
        crry = item.label;
      }
    }
    const purstantaj_cost = Number(pursantaj_rate * this.sozlesme_worth / 100);

    const pursantaj_cost_string = formatNumberWithCurrency(purstantaj_cost, crry);

    if (completed_percentage) {

      const completed_percentage_const = Number(completed_percentage * purstantaj_cost / 100);

      const completed_percentage_const_string = formatNumberWithCurrency(completed_percentage_const, crry);

      return `${pursantaj_cost_string} <br> <span style="color: green;"> / (${completed_percentage_const_string}) </span>`;

    }
    return `${pursantaj_cost_string}`;
  }

  formatMetraj(element) {
    if (element.metraj) {
      let unit = '';
      if (element.metraj_unit) {
        unit = getBirim(element.metraj_unit);
      }

      return `${element.metraj} ${unit} <br> <span style="color: green;"> / (${element.tamamlanan_metraj ? element.tamamlanan_metraj : 0}) </span> ${unit}`;
    } else {
      return '';
    }

  }
  changeElement(element, obj) {
    element.pStart = obj.pStart;
    element.pEnd = obj.pEnd;
    element.pParent = obj.pParent;
    element.pColor = obj.pColor;
    element.pName = obj.pName;
    this.editor.Draw();
  }

  drawGanttItems(data) {
    data.pursantaj_list.forEach(obj => {
      obj = this.manipuleData(obj);
      const element = this.findTask(data.id);
      if (element) {
        this.changeElement(element, obj);
      } else {
        this.editor.AddTaskItemObject(obj);

      }
    },
    );
    this.can_edit = data.can_edit;
    this.editor.setCanEdit(this.can_edit);
    this.editor.setShowAddEntries(true);
    this.editor.setMinDate(this.start_day);
    this.editor.setMaxDate(this.end_day);
    this.editor.Draw();
  }

  manipuleData(element) {
    if (element.pursantaj_name == 'null') {
      element.pursantaj_name = ' ';
    }

    if (element.start_day) {
      element['pStart'] = convertDateWithTZ(element, 'start_day');
      if (element.id === 0) {
        element['pStart'] = moment(element.start_day - 10, 'X').utc().format('DD/MM/YYYY HH:mm');
      }
    }
    if (element.end_day) {
      element['pEnd'] = convertDateWithTZ(element, 'end_day');
      if (element.id === 0) {
        element['pStart'] = moment(element.start_day + 10, 'X').utc().format('DD/MM/YYYY HH:mm');
      }
    }


    element['pID'] = element.id;
    element['pPursantaj'] = element.pursantaj_rate;
    element['pGroup'] = 1;
    element['pCost'] = this.calculatCost(element.pursantaj_rate, element.completed_percentage);
    element['pMetraj'] = this.formatMetraj(element);
    /**
     *
     * start_max_date : Alt Pursantajların en düşünüğünün başlangıç tarihi
     * start_min_date : Üst Pursantatın Başlangıç Tarihi
     * end_max_date   : Üst Pursantajın Bitiş Tarihi
     * end_min_date   : Alt Pursantajları en yüksek bitişi tarihi
     *
     */

    element['pStartMin'] = element.start_min_date;
    element['pStartMax'] = element.start_max_date;
    element['pEndMin'] = element.end_min_date;
    element['pEndMax'] = element.end_max_date;

    if (element.ust_pursantaj_id) {
      element['pParent'] = element.ust_pursantaj_id;
      element['pOpen'] = 0;
      element['pColor'] = element.color;
      element['pCompColor'] = element.comp_color;

      if (element.ust_pursantaj_id == 1) {
        element['pClass'] = 'ggroupblack';
      } else {
        element['pOpen'] = 1;
        element['pClass'] = 'gtaskpink';
      }

      if (element.alt_pursantaj) {
        if (element.alt_pursantaj.length === 0) element['pGroup'] = 1;
      }
    } else {
      element['pParent'] = 0;
      element['pClass'] = 'ggroupblack';
      element['pColor'] = element.color;
      element['pCompColor'] = element.comp_color;
    }
    element['pComp'] = element.completed_percentage;
    element['pName'] = element.pursantaj_name;
    if (!element.pursantaj_name) {
      element['pName'] = ' ';
    }
    element['pDepend'] = [];
    if (element.ss && element.ss.length > 0) {
      element.ss.forEach(val => {
        val = `${val.id}SS`;
        element['pDepend'].push(val);
      });
    }

    if (element.sf && element.sf.length > 0) {
      element.sf.forEach(val => {
        val = `${val.id}SF`;
        element['pDepend'].push(val);
      });

    }
    if (element.ff && element.ff.length > 0) {

      element.ff.forEach(val => {
        val = `${val.id}FF`;
        element['pDepend'].push(val);

      });
    }

    if (element.fs && element.fs.length > 0) {

      element.fs.forEach(val => {
        val = `${val.id}FS`;
        element['pDepend'].push(val);

      });
    }
    element.pGantt = this.editor;
    return element;
  }


  updateTask(pursantajId, newData) {
    const findPurs = this.findTask(pursantajId);
    findPurs.setDepend(newData.pDepend);
    findPurs.setName(newData.pName);
    findPurs.setCost(newData.pCost);
    this.editor.Draw();
  }

  yeniEkle(elementId) {

    const row = this.data.find(x => x.id == elementId);

    const altData = {
      santiye_id: this.santiyeId,
      ust_pursantaj_id: elementId,
    };
    this.isLoading = true;
    this.duzenle = false;

    this.pursantajService.postPursantaj(this.santiyeId, altData).subscribe((response: PursantajModel) => {
      this.isLoading = false;
      const manipulated = this.manipuleData(response);
      this.data = [...this.data, manipulated];
      row.alt_pursantaj.push(manipulated.id);

      this.editor.AddTaskItemObject(manipulated);
      this.editor.Draw();

      const event = this.findTask(response.id);
      this.duzenle = true;
      this.detayGoster(event);
    }, () => {
      this.isLoading = false;
    });
  }

  deleteItem(event) {
    const elementId = event.getOriginalID();
    const pID = event.getID();

    const row = this.data.find(elm => elm.id == elementId);

    if (!row.alt_pursantaj.some(f => this.data.some(s => s.id === f)) || row.alt_pursantaj.length <= 0) {

      this.pursantajService.pursantajDelete(row.identifier).subscribe(resp => {

        this.editor.RemoveTaskItem(pID);
        this.editor.Draw();

        const parent = this.data.find(f => f.id === row.ust_pursantaj_id);
        if (parent) {
          parent.alt_pursantaj = parent.alt_pursantaj.filter(f => f !== row.id);
        }
        this.data = this.data.filter(item => item.id !== row.id);
        Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);

      }, (err) => {
        if (err) {
          if (err.error.ER26) {
            Swal.fire({
              icon: 'info',
              confirmButtonText: 'Tamam',
              title: 'Önce Silmek İstediğiniz Nesnenin Alt Nesnelerini Siliniz.',
            });
          } else if (err.error.ER32 !== undefined) {

            Swal.fire(responseKodlari.ER32 as SweetAlertOptions).then(({ value }) => {
              if (value) {
                this.pursantajService.pursantajDeleteAnyWay(row.identifier).subscribe(resp => {
                  this.editor.RemoveTaskItem(pID);
                  this.editor.Draw();

                  const parent = this.data.find(f => f.id === row.ust_pursantaj_id);
                  if (parent) {
                    parent.alt_pursantaj = parent.alt_pursantaj.filter(f => f !== row.id);
                  }
                  this.data = this.data.filter(item => item.id !== row.id);
                  Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
                }, (err) => {
                  console.log('ERR : ', err);
                });
              }
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: 'info',
        confirmButtonText: 'Tamam',
        title: 'Önce Silmek İstediğiniz Nesnenin Alt Nesnelerini Siliniz.',
      });
    }
  }


  detayGoster(element) {

    if (this.showInfoBox) {
      this.hideInfo();
      setTimeout(() => {
        this.currentTask = element;
        this.showInfoBox = true;
      }, 1000);
    } else {
      this.currentTask = element;
      this.showInfoBox = true;
    }
  }


  hideInfo() {
    this.showInfoBox = false;
    this.currentTask = null;
  }


  toNbRangePicker(row): string {

    const startDay = row.start_day;
    const endDay = row.end_day;
    let start: string = '';
    let end: string = '';
    if (startDay) {
      start = moment(startDay, 'X').format('ll');
    }
    if (endDay) {
      end = moment(endDay, 'X').format('ll');
    }
    if (!(start && end)) {
      return null;
    }
    return `${start}-${end}`;
  }


  changeDateForPost(tstamp, end?) {
    const momentDay = moment(tstamp, 'X').utc();
    let startDay = momentDay.hour(0).minute(0).second(0).unix();
    if (end) {
      startDay = momentDay.hour(23).minute(59).second(59).unix();
    }
    return startDay;
  }


  pursantajGuncelle(event, pursantajId, guncelle: PursantajEkleComponentUpdateType, start_day?: Date, end_day?: Date) {
    let pursantajData = {};
    pursantajId = Number(pursantajId);
    let row = this.data.find(x => x.id === pursantajId);
    this.isLoading = true;

    switch (guncelle) {
      case PursantajEkleComponentUpdateType.Name:
        pursantajData = {
          santiye_id: this.santiyeId,
          pursantaj_name: event,
        };
        break;
      case PursantajEkleComponentUpdateType.Rate:
        pursantajData = {
          santiye_id: this.santiyeId,
          pursantaj_rate: (event) ? event : 0,
        };
        break;
      case PursantajEkleComponentUpdateType.DateRate:
        pursantajData = { santiye_id: this.santiyeId };
        if (start_day) {
          pursantajData['start_day'] = this.changeDateForPost(start_day);
        }
        if (end_day) {
          pursantajData['end_day'] = this.changeDateForPost(end_day, true);
        }
        break;
      case PursantajEkleComponentUpdateType.Color:
        pursantajData = {
          santiye_id: this.santiyeId,
          color: event,
        };
        break;
      case PursantajEkleComponentUpdateType.All:
        pursantajData = event;
        pursantajData['start_day'] = this.changeDateForPost(event.start_day);
        pursantajData['end_day'] = this.changeDateForPost(event.end_day, true);
        pursantajData['santiye_id'] = this.santiyeId;
        break;
    }
    this.pursantajService.pursantajPatch(pursantajId, pursantajData).subscribe(data => {
      this.isLoading = false;

      row = this.manipuleData(data);
      this.updateTask(data.id, row);
    }, (err) => {
      this.isLoading = false;
    });

  }
  setDepend(t1, t2, pType, direct?: boolean) {
    this.isLoading = true;
    let data = { id: t1, connect_id: t2, connect_type: pType };

    if (!direct) {
      const dataTask1 = this.data.find(x => x.id == t1.getOriginalID());
      const dataTask2 = this.data.find(x => x.id == t2.getOriginalID());
      data = { id: dataTask1.id, connect_id: dataTask2.id, connect_type: pType };
    }
    this.pursantajService.addDepend(data).subscribe(resp => {
      this.forceRefresh = true;
      this.isLoading = false;
      const row = this.manipuleData(resp);
      this.updateTask(resp.id, row);

    }, (err) => this.isLoading = false);
  }

  setNewValue(event) {
    if (event.all) {
      this.pursantajGuncelle(event.all, this.currentTask.getOriginalID(), PursantajEkleComponentUpdateType.All);
    } else if (event.pursantaj_name) {
      this.currentTask.setName(event.pursantaj_name);
      this.editor.Draw();

    } else if (event.pursantaj_rate) {
      this.currentTask.setPursantaj(event.pursantaj_rate);
      this.currentTask.setCost(this.calculatCost(event.pursantaj_rate));
      this.editor.Draw();

    } else if (event.start_day) {
      this.changeDateForPost(event.start_day);
      this.currentTask.setStart(event.start_day);
      this.editor.Draw();
    } else if (event.end_day) {
      this.currentTask.setEnd(event.end_day);
      this.editor.Draw();
    } else if (event.color) {
      this.currentTask.setColor(event.color);
      this.editor.Draw();
    } else if (event.depend) {
      this.setDepend(event.depend.t1, event.depend.t2, event.depend.pType, true);
    } else if (event.metraj) {
      this.currentTask.setMetraj(this.formatMetraj(event));
      this.editor.Draw();
    } else if (event.metraj_unit) {
      this.currentTask.setMetraj(this.formatMetraj(event));
      this.editor.Draw();
    }
  }
}
