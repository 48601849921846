  <nb-card class="card modalType animated fadeInRight" size="giant" style="border: unset !important;" [formGroup]="form">
    <nb-card-header>
      {{santiyeTitle}}
      <!-- <br> Form:{{form.value |json}} -->
      <button nbButton (click)="closed()" size="tiny" class="m-close"
        style="background: none !important; float: right;  font-size: 18px;">X</button>
    </nb-card-header>
    <nb-card-body class="p-0" [nbSpinner]="isLoading || isSubmitting" nbSpinnerSize="tiny" >


      <!--Dosya Listele-->
      <!--Search-->
      <div class="m-search">
        <input nbInput (keyup)="dosyaTableArea($event)" [placeholder]="'SANTIYEDOSYALAR.DOSYAARA'|translate" *ngIf="sGizleGoster"
          class="animated slideInRight">
        <nb-icon [icon]="mIcon" (click)="searchGizleGoster()"></nb-icon>
      </div>
      <!--// Search-->
      <ngx-datatable #myTable class="material expandable bootstrap  ngx-datatable" [columnMode]="ColumnMode.flex"
        [headerHeight]="50" [footerHeight]="0" rowHeight="auto" [rows]="rows">


        <!--Dosya Tarihi-->
        <ngx-datatable-column prop="created_time" [name]="'PUANTAJDETAY.TARIH'|translate" [flexGrow]="1">
          <ng-template let-row="row" let-rowIndex="rowIndex" let-value="value" ngx-datatable-cell-template>
            <!-- {{row.created_time * 1000 | date:tarih_format}} -->
            <strong> {{rowIndex+1}} &nbsp; </strong>
            {{row.created_time?(row.created_time * 1000 | date:tarih_format):'--/--/----'}}
          </ng-template>
        </ngx-datatable-column>
        <!--//Dosya Tarihi-->

        <!--Dosya Adı-->
        <ngx-datatable-column prop="title" [name]="'HAKEDISCUSTOMIDARIHAKEDIS.DOSYAADI'|translate" [flexGrow]="1">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row.title}}

          </ng-template>
        </ngx-datatable-column>
        <!--//Dosya Adı-->

        <!--Dosya Açıklama-->
        <ngx-datatable-column prop="note" [name]="'APASIMAGELIGHTBOX.ACIKLAMA'|translate" [flexGrow]="2">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div>
              <p>{{row.note}}</p>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <!--//Dosya Açıklama-->

        <!--Rapor Dosysı-->
        <ngx-datatable-column prop="dosya" [name]="'HAKEDISCUSTOMIDARIHAKEDIS.DOSYA'|translate" [flexGrow]="1">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <!-- <button size="tiny" nbButton status="info">
              <nb-icon icon="file-text-outline"></nb-icon>Doyayı Aç
            </button> -->

            <button [disabled]="row.isDownloading" (click)="downLoadFile(row.id,row)" size="tiny" nbButton
              status="info">
              <nb-icon icon="file-text-outline"></nb-icon>
              {{row.isDownloading?'İndriliyor:%'+row.percentDone:'Dosyayı Aç'}}
            </button>
            <button *ngIf="row?.can_delete" [disabled]="row.isDownloading" (click)="deleteFile(row)" size="tiny" nbButton status="warning">
              <nb-icon icon="file-text-outline"></nb-icon>
              {{'URUNSIL.SIL'|translate}}
            </button>
          </ng-template>
        </ngx-datatable-column>
        <!--//Rapor Dosyası-->


      </ngx-datatable>
      <!--// Dosya Listele-->
    </nb-card-body>
    <nb-card-footer *ngIf="santiye?.can_edit" class="m-header" style="border-top: 5px solid #c3ced3; ">
      <!--Dosya Ekle-->
      <div class="form-padding row m-2 align-items-center">

        <b>{{'HAKEDISCUSTOMIDARIHAKEDIS.DOSYAEKLE'|translate}}</b>
        <div class="col-md">
          <div class="floating">
            <input [placeholder]="'SANTIYEDOSYALAR.DOSYAADI'|translate" nbInput fullWidth formControlName="title" />
          </div>
        </div>

        <div class="col-md">
          <div class="floating">
            <textarea nbInput style="height: 40px;margin-top: 5px;" type="number" [placeholder]="'SANTIYEDOSYALAR.ACIKLAMA'|translate" fullWidth
              formControlName="note"></textarea>

          </div>
        </div>
        <!-- <div class="col-md">
     <div class="spinner-border text-primary" 
     style="position: relative; bottom: 50%;"></div> 
  </div> -->



        <div class="col-md">
          <!-- <div *ngIf="!currentFile" 
      class="default-img linked-div" 
      (click)="openFile(fileInput)">
      <nb-icon icon="plus-circle-outline" 
      style="width: auto;   height: auto; color: #443939;"></nb-icon> <br>
      <span> Fotoğraf Seç</span>

      <input #fileInput type="file" 
      accept="image/*" 
      style="display:none" 
       (change)="openFileDialog($event)">
    </div> -->
          <button nbButton status="basic" (click)="openFile(fileInput)" class="m-0">{{'SANTIYEDOSYALAR.DOSYASEC'|translate}}</button>
          <input #fileInput type="file" [accept]="acceptFileFormats" style="display:none"
            (change)="openFileDialog($event)">
          <button *ngIf="form.get('_file').valid" [disabled]="isSubmitting" nbButton status="primary" class="ml-2"
            (click)="uploadFile()"> {{isSubmitting?'Yükleniyor %'+percentDone:'Yükle'}}</button>
        </div>
      </div>
      <!--// Dosya Ekle-->
    </nb-card-footer>
  </nb-card>