<div class="d-flex align-items-center">
    <div *ngIf="!control.value">
        <button nbButton status="warning" (click)="openFile(fileInput)" class="m-0" style="border: none !important;"> <nb-icon icon="attach-2-outline"></nb-icon> 
        {{buttonText}}
        </button>
        <input class="dosyaEkleInput" #fileInput type="file" [accept]="dosyaTip" (change)="openFileDialog($event)">
    </div>
    <div class="d-flex align-items-center"> 
        <label class="m-0" style="line-break: anywhere;" *ngIf="control.value">
            <span *ngIf="dosya_adi">
               {{dosya_adi | slice : 0:30}} 
            </span>
            <span *ngIf="!dosya_adi">
                Dosya 
             </span>
            <nb-icon class="linked-div" (click)="control.reset()" icon="close-square-outline"></nb-icon>
        </label>
    </div>
</div>