import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { tarih_format } from '../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { SantiyeService } from '../../../services/santiye.service';
import { ApasImageLightboxComponent } from '../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { SantiyeAlbumModalComponent } from '../santiye-photo/santiye-album-modal/santiye-album-modal.component';
import { SantiyeImgUploadComponent } from '../santiye-photo/santiye-img-upload/santiye-img-upload.component';

@Component({
  selector: 'santiye-fotograf-roller',
  templateUrl: './santiye-fotograf-roller.component.html',
  styleUrls: ['./santiye-fotograf-roller.component.scss'],
  providers: [SantiyeService],
})
export class SantiyeFotografRollerComponent implements OnInit {
  @Input() santiye_id;
  @Input() count = 10;

  isLoading: boolean = false;
  form: FormGroup;
  serializer = this.domSanitizer;
  tarih_format = tarih_format;

  constructor(
    private santiyeService: SantiyeService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
    private router: Router,
    private domSanitizer: DomSanitizer,

  ) {
    this.initForm();
  }


  ngOnInit(): void {
    this.form.get('santiye_id').setValue(this.santiye_id);
    this.form.get('count').setValue(this.count);
    setTimeout(() => {
      this.getFotolarList();

    }, 150);
  }

  initForm() {
    this.form = this.fb.group({
      end_date: [],
      start_date: [],
      santiye_id: [this.santiye_id, Validators.required],
      show_yapilan_isler: [true],
      show_santiye_photos: [true],
      count: [this.count],
    });
  }

  santiyeFotolar = [];
  request;

  getFiltederData() {
    this.santiyeFotolar = [];
    this.getFotolarList();
  }
  getFotolarList() {
    if (this.form.valid && !this.isLoading) {
      this.isLoading = true;
      const filter = removeAllNull(this.form.value);
      if (this.request) this.request.unsubscribe();
      this.request = this.santiyeService.getImageCalander(filter).subscribe(data => {
        this.isLoading = false;
        data.data.forEach(elm => {
          elm['tarih'] = convertDateWithTZ(elm, 'date');
          this.santiyeFotolar = addOrUpdate(this.santiyeFotolar, elm, 'date');
        });

        this.santiyeFotolar = [...this.santiyeFotolar];

      }, err => {
        this.isLoading = false;
      });
    }
  }

  imgClick(image: string, imageIndex: number) {
    const context: {} = {
      image: image,
      url: 'santiye/imagedetay/',
      // 'url': 'santiye/imagedetay/' + this.santiye_id + '/' + imageId + '/',
      santiye_id: this.santiye_id,
      imageIndex: imageIndex,
      groupIndex: 0,
      rows: [{ images: this.santiyeFotolar }],

    };
    console.log('CONTEXT:', context);


    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }

  tumFotolar() {
    this.router.navigate([`pages/santiye/santiye-album/${this.santiye_id}/`]);
  }

  isLoadingDetay = false;

  getAlbumDetay(date?) {
    const data = {
      santiye_id: this.santiye_id,
      santiye_name: '',
      start_date: date,
      showImalatPhotos: true,
      showSantiyePhotos: true,
    };

    this.nbDialogService.open(
      SantiyeAlbumModalComponent, {
      context: { modalData: data },
    });
  }

  yeniFotoEkle() {
    this.nbDialogService.open(
      SantiyeImgUploadComponent,
      { context: { santiyeId: this.santiye_id } })
      .onClose.subscribe(data => {
        if (data === true) {
          this.getFiltederData();
        }
      });
  }

}
