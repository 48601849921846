import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants, tarih_format } from '../../../pages/constants';
import { removeAllNull, addOrUpdate, convertDateWithTZ } from '../../../pages/global-functions';
import { MutabakatService } from '../../../services/mutabakat.service';
import { OrganizasyonService } from '../../../services/organizasyon.service';

@Component({
  selector: 'apas-mutabakat-list',
  templateUrl: './apas-mutabakat-list.component.html',
  styleUrls: ['./apas-mutabakat-list.component.scss'],
  providers: [MutabakatService, OrganizasyonService],
})
export class ApasMutabakatListComponent implements OnInit, OnChanges {
  @Input() company_id: number;
  @Input() headerShow: boolean = true;
  form: FormGroup;
  rows: any[] = [];
  isLoading: boolean = false;
  convert = Constants.prototype;
  tarih_format = tarih_format;

  get searchControl(): FormControl { return this.form.get('search') as FormControl; }
  constructor(
    private mutabakatService: MutabakatService,
    private fb: FormBuilder,
    private organizasyonService: OrganizasyonService,
    ) {
    this.initForm();
    this.orgSubscription();

  }

  ngOnInit(): void {

    if (this.company_id) {
      this.form.addControl('organization_id', new FormControl(this.company_id));
      setTimeout(() => {
        this.getMutabakatList();
      }, 100);
    }
  }


  organizationSubscription;
  hasAuthorization: boolean = false;
  orgSubscription() {
    this.organizationSubscription = this.organizasyonService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const hakedis = modules.find(x => x.slug === 'mutabakatlar');
        if (hakedis.value) {
          this.hasAuthorization = true;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.company_id) {
      this.rows = [];
      this.form.get('mutabakat_taraf_id').setValue(changes?.company_id.currentValue);
      this.getFilteredData();
    }
  }

  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      search: [],
      mutabakat_taraf_id: [null, Validators.required],
    });
    this.form.controls.search.valueChanges.subscribe(val => this.getFilteredData());
  }

  getFilteredData() {
    this.form.get('page').setValue(0);
    this.rows = [];
    setTimeout(() => {
      this.getMutabakatList();
    }, 150);
  }

  getMutabakatList() {
    if (!this.hasAuthorization) return;
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.mutabakatService.getNewMutabakatListesi(formVal).subscribe(data => {

      data.forEach(elm => {
        elm = this.clearData(elm);
        this.rows = addOrUpdate(this.rows, elm);
      });
      this.isLoading = false;
    }, (err) => {
      this.isEnd = true;
      this.isLoading = false;
    });
  }

  isEnd: boolean = false;
  goToNextPage() {
    if (!this.isLoading && !this.isEnd) {
      this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
      this.getMutabakatList();

    }
  }

  clearData(item) {
    item.created_time = convertDateWithTZ(item, 'created_time');
    item.start_date = convertDateWithTZ(item, 'start_date');
    item.finish_date = convertDateWithTZ(item, 'finish_date');
    return item;
  }



  search(event) {
    const len = event.target.value.length;
    const arama = event.target.value.toUpperCase();


    if (len > 2) {
      this.rows = [];
      this.form.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.form.get('search').setValue(arama);
    } else if (len === 0) {
      this.form.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.form.get('search').setValue(null);
    } else {
      return;
    }
    this.getMutabakatList();
  }

}
