import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SantiyeService } from '../../../services/santiye.service';
import {
  tarih_format,
  Constants,
  getBirim,
  getMoneyCurrency,
  responseKodlari,
  dokumTypes,
} from '../../../pages/constants';
import * as moment from 'moment';
import {
  dateToUtc,
  exportExcelListeWithHeaders,
  removeAllNull,
  toTimestamp,
} from '../../../pages/global-functions';
import 'moment/locale/tr';
import { HttpErrorResponse } from '@angular/common/http';
import { PuantajService } from '../../../services/puantaj.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import {Row, Workbook, Worksheet, Borders, Alignment, FillPattern} from 'exceljs';
import * as fs from 'file-saver';
import { DepoService } from '../../../services/depo.service';
import { IsMakinalariService } from '../../../services/is_makinalari.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { YapilanIslerService } from '../../../services/yapilan-isler.service';

@Component({
  selector: 'santiye-gunluk-rapor',
  templateUrl: './santiye-gunluk-rapor.component.html',
  styleUrls: ['./santiye-gunluk-rapor.component.css'],
  providers: [
    SantiyeService,
    PuantajService,
    IsMakinalariService,
    DepoService,
    YapilanIslerService,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SantiyeGunlukRaporComponent implements OnInit {
  math = Math;
  santiyeId: number;
  gunlukRapor;
  convert = Constants.prototype;
  getBirim = getBirim;
  getMoneyCurrency = getMoneyCurrency;
  tarih_format = tarih_format;
  spinner;
  imgPath = 'assets/weather-icon/';
  tag: string = 'santiyeGunlukRaporComponent --->';

  serhat$: Observable<any>;
  constructor(
    private router: Router,
    private _gunlukRaporService: SantiyeService,
    private puantajService: PuantajService,
    private depoService: DepoService,
    private isMakinalariService: IsMakinalariService,
    private yapilanIslerService: YapilanIslerService,
  ) {
    if (this.router.getCurrentNavigation().extras.state != null) {
      this.santiyeId = this.router.getCurrentNavigation().extras.state.santiyeId;
    } else {
      this.router.navigate(['/pages/dashboard']);
    }
  }

  ngOnInit() {
    this.raporGetir();
  }
  endDate;
  startDate;
  raporGetir() {
    this.gunlukRapor = [];
    this.spinner = true;
    const startAt = this.momentDate(true);
    const endAt = this.momentDate(false);
    this._gunlukRaporService
      .getSantiyeRapor(this.santiyeId, startAt, endAt)
      .subscribe(
        (data) => {
          this.gunlukRapor = data;
          // Satır sayılarını eşitlemek için makine listesine veya depo listesine boş eleman pushlar

          this.esitle();
          this.spinner = false;
        },
        (err: HttpErrorResponse) => {
          this.spinner = false;
          this.gunlukRapor = undefined;
        },
      );
  }

  momentDate(startOrEnd) {
    let convertedDate;
    if (!this.startDate) {
      this.startDate = new Date();
    }
    if (startOrEnd) {
      if (this.startDate) {
        convertedDate = moment(this.startDate);
      } else {
      console.log('(ELSE) START DATE : ', this.startDate);

        convertedDate = moment(new Date());
      }
    } else {
      if (this.endDate) {
        console.log('END DATE : ', this.endDate);
        convertedDate = moment(this.endDate);
      } else {
        convertedDate = moment(this.startDate);
      }
    }
    convertedDate.set({ hour: 12, minute: 12, second: 0 });
    convertedDate = convertedDate.format('X');

    return convertedDate;
  }
  esitle() {
    //#region İşler
    if (this.gunlukRapor) {
      const devam_eden_isler = this.gunlukRapor.devam_eden_isler.length;
      const biten_isler = this.gunlukRapor.biten_isler.length;
      const makine_sayisi = this.gunlukRapor.machines.length;
      const urun_sayisi = this.gunlukRapor.depo.length;

      if (biten_isler === 0) {
        this.gunlukRapor.biten_isler.push({ title: '' });
      }
      if (devam_eden_isler === 0) {
        this.gunlukRapor.devam_eden_isler.push({ title: '' });
      }

      if (urun_sayisi === 0) {
        const bosItem = {
          count: null,
          machine_type: null,
        };
        this.gunlukRapor.machines.push(bosItem);
      }
      if (makine_sayisi === 0) {
        const bosItem = {
          count: null,
          product_name: null,
          amount: null,
          unit: null,
        };
        this.gunlukRapor.depo.push(bosItem);
      }
    }
    //#endregion
    //#region   Jobs

    if (this.gunlukRapor) {
      const sonSatir = this.gunlukRapor.puantaj_list.length % 3;
      if (sonSatir !== 0) {
        for (let i = 0; i < 3 - sonSatir; i++) {
          this.gunlukRapor.puantaj_list.push({ job: '' });
        }
      }
    }
    //#endregion
  }

  workbook: Workbook;
  isExcelDownloading: boolean = false;

  async yazdir() {
    this.isExcelDownloading = true;
    this.workbook = new Workbook();
    const newData = {
      puantajData: [],
      depoData: [],
      betonData: [],
      makineData: [],
      islerData: [],
    };

    if (this.gunlukRapor.is_mine) {
      const puantaj = this.getPuantajData();
      const depo = this.getDepoData();
      const beton = this.getBetonReport();
      const makine = this.getIsMakinesiRapor();
      const isler = this.getYapilaIsReport();

      const puantajPromise = Promise.resolve(puantaj).catch((err) =>
        console.log('PUANTAJ ERR : ', err),
      );
      const depoPromise = Promise.resolve(depo).catch((err) =>
        console.log('DEPO ERR : ', err),
      );
      const betonPromise = Promise.resolve(beton).catch((err) =>
        console.log('BETON ERR : ', err),
      );
      const makinePromise = Promise.resolve(makine).catch((err) =>
        console.log('makine ERR : ', err),
      );
      const islerPromise = Promise.resolve(isler).catch((err) =>
        console.log('isler ERR : ', err),
      );

      const completeResult = Promise.all([
        puantajPromise,
        depoPromise,
        betonPromise,
        makinePromise,
        islerPromise,
      ])
        .then(async (values) => {
          console.log('VALUES : ', values);
          const puantajData = values[0];
          const depoData = values[1];
          const betonData = values[2];
          const makineData = values[3];
          const islerData = values[4];
          if (puantajData)
            await this.manipulatePuantajData(puantajData).then(
              (data) => (newData.puantajData = data),
            );
          newData.depoData = depoData;
          newData.betonData = betonData;
          newData.makineData = makineData;
          newData.islerData = islerData;
          return newData;
        })
        .catch((err) => {
          console.log('ERR : ', err);
          return newData;
        })
        .then(async (data) => {
          await this.exportMainExcel();
          if (data['depoData']) await this.exportDepoExcel(data['depoData']);
          if (data['betonData']) await this.exportBetonExcel(data['betonData']);
          if (data['makineData'])
            await this.exportMakineExcel(data['makineData']);
          if (data['islerData']) await this.exportIslerExcel(data['islerData']);
          if (data['puantajData'])
            await this.exportPuantajExcel(data['puantajData']);
        })
        .then(() => {
          this.saveAsExcel();
        });
    } else {
      this.exportMainExcel().then(() => {
        this.saveAsExcel();
      });
    }
  }

  saveAsExcel() {
    this.workbook.xlsx
      .writeBuffer()
      .then((exp) => {
        const startStr = moment(this.gunlukRapor.date, 'X').format(
          'DD_MM_YYYY',
        );
        const endStr = moment(this.gunlukRapor.end_date, 'X').format(
          'DD_MM_YYYY',
        );
        let tarihStr = '';
        if (startStr === endStr) {
          tarihStr = `${startStr} tarihli`;
        } else {
          tarihStr = `${startStr} - ${endStr} Tarihleri Arası`;
        }
        let dosyaAdi = `${tarihStr} ${this.gunlukRapor.work_name} Günlük Rapor`;
        const blob = new Blob([exp], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        dosyaAdi = dosyaAdi.replace(
          /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          '',
        );

        const filename = dosyaAdi + '.xlsx';
        fs.saveAs(blob, filename);
        Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
        this.isExcelDownloading = false;
      })
      .catch((err) => {
        console.log('BAŞARISIZ', err);
        this.isExcelDownloading = false;
      });
  }

  getPuantajData = async () => {
    let filter = {
      start_date: this.momentDate(true),
      end_date: this.momentDate(false),
      santiye_id: this.santiyeId,
    };
    filter = removeAllNull(filter);

    if (filter.start_date) filter.start_date = Number(filter.start_date);
    if (filter.end_date) filter.end_date = Number(filter.end_date);
    const newData = [];
    console.log('FİLTRE : ', filter);
    return await this.puantajService.getExcelData(filter).toPromise();
  }

  manipulatePuantajData = async (resp) => {
    const newData = [];
    let gmt = 'Europe/Istanbul';
    if (resp[0].gmt) gmt = resp[0].gmt;
    resp.forEach((element) => {
      element.tarih = moment(element.date, 'X');
      console.log(element.tarih.format('DD/MM/YYYY : hh-mm'));

      const finded = newData.find(
        (x) => x.taseron_name === element.taseron_name,
      );
      if (finded) {
        finded.puantajs.push(element);
      } else {
        const newNesne = {
          taseron_name: element.taseron_name,
          puantajs: [],
          days: [],
        };
        newNesne.puantajs.push(element);
        newData.push(newNesne);
      }
    });
    newData.forEach((santiye) => {
      santiye.puantajs.forEach((elm) => {
        const finded = santiye.days.find(
          (x) =>
            x.tarih.format('DD/MM/YYYY') === elm.tarih.format('DD/MM/YYYY'),
        );
        if (finded) {
          const jobFind = finded.jobs.find((x) => x.job_name === elm.job_name);
          if (jobFind) {
            jobFind.puantajList.push(elm);
          } else {
            const newJob = {
              job_name: elm.job_name,
              puantajList: [elm],
            };
            finded.jobs.push(newJob);
          }
        } else {
          const newDate = {
            tarih: elm.tarih,
            jobs: [
              {
                job_name: elm.job_name,
                puantajList: [elm],
              },
            ],
          };
          santiye.days.push(newDate);
        }
      });
    });
    newData.forEach((santiye) => {
      const sorted = santiye.days.sort(
        (n1, n2) => n1.tarih.format('YYYYYMMDD') - n2.tarih.format('YYYYYMMDD'),
      );
      santiye.days = sorted;
      console.log('SORTED : ', sorted);
      santiye.days.forEach((element) => {
        element.tarih = element.tarih.format('DD/MM/YYYY');
      });
    });
    return newData;
  }
  async exportMainExcel() {
    const sheet = this.workbook.addWorksheet('İcmal');
    const baslikRow = sheet.getRow(1);
    sheet.mergeCells('A1:F1');
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 11,
      bold: true,
    };

    const subTitleFont = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };

    const border: Borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
      diagonal: {},
    };

    const centerAligment: Alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: false,
      shrinkToFit: false,
      readingOrder: 'ltr',
      textRotation: 0,
      indent: 1,
    };

    const leftAligment: Alignment = {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: false,
      shrinkToFit: false,
      readingOrder: 'ltr',
      textRotation: 0,
      indent: 1,
    };

    baslikRow.height = 30;
    const baslikCell = baslikRow.getCell(1);
    // tslint:disable-next-line: max-line-length
    baslikCell.value = `${moment(this.gunlukRapor.date, 'X').format(
      'DD.MM.YYYY',
    )} - ${moment(this.gunlukRapor.end_date, 'X').format(
      'DD.MM.YYYY',
    )} Tarihleri Arası ${this.gunlukRapor.work_name} Günlük Rapor`;
    baslikCell.alignment = centerAligment;
    baslikCell.border = border;
    baslikCell.font = font;
    sheet.getColumn(1).width = 20;
    sheet.getColumn(2).width = 15;
    sheet.getColumn(3).width = 20;
    sheet.getColumn(4).width = 15;
    sheet.getColumn(5).width = 20;
    sheet.getColumn(6).width = 15;

    const isinAdiRow = sheet.getRow(2);
    isinAdiRow.height = 25;
    sheet.mergeCells('A2:C2');
    sheet.mergeCells('D2:F2');
    const isinAdiTitleCell = sheet.getCell('A2');
    isinAdiTitleCell.value = 'İşveren';
    isinAdiTitleCell.alignment = leftAligment;
    isinAdiTitleCell.border = border;

    const isinAdiValueCell = sheet.getCell('D2');
    isinAdiValueCell.value = this.gunlukRapor.employee;
    isinAdiValueCell.alignment = leftAligment;
    isinAdiValueCell.border = border;

    const yukleniciRow = sheet.getRow(3);
    yukleniciRow.height = 25;
    sheet.mergeCells('A3:C3');
    sheet.mergeCells('D3:F3');
    const yukleniciTitleCell = sheet.getCell('A3');
    yukleniciTitleCell.value = 'Yüklenici';
    yukleniciTitleCell.alignment = leftAligment;
    yukleniciTitleCell.border = border;

    const yukleniciValueCell = sheet.getCell('D3');
    yukleniciValueCell.value = this.gunlukRapor.contractor;
    yukleniciValueCell.alignment = leftAligment;
    yukleniciValueCell.border = border;

    const sureRow = sheet.getRow(4);
    sureRow.height = 25;

    sheet.mergeCells('A4:C4');
    sheet.mergeCells('D4:E4');

    const sureTitleCell = sheet.getCell('A4');
    sureTitleCell.value = 'İşin Süresi (Gün)';
    sureTitleCell.alignment = leftAligment;
    sureTitleCell.border = border;

    const sureValueCell = sheet.getCell('D4');
    sureValueCell.value = `${this.gunlukRapor.work_time} Gün`;
    sureValueCell.alignment = leftAligment;
    sureValueCell.border = border;

    const baslamaRow = sheet.getRow(5);
    baslamaRow.height = 25;

    sheet.mergeCells('A5:C5');
    sheet.mergeCells('D5:E5');
    const baslamaTitleCell = sheet.getCell('A5');
    baslamaTitleCell.value = 'İşin Başlama Tarihi';
    baslamaTitleCell.alignment = leftAligment;
    baslamaTitleCell.border = border;

    const baslamaValueCell = sheet.getCell('D5');
    baslamaValueCell.value = moment(this.gunlukRapor.start_date, 'X').format(
      'DD/MM/YYYY',
    );
    baslamaValueCell.alignment = leftAligment;
    baslamaValueCell.border = border;

    const bitisRow = sheet.getRow(6);
    bitisRow.height = 25;
    sheet.mergeCells('A6:C6');
    sheet.mergeCells('D6:E6');
    const bitisTitleCell = sheet.getCell('A6');
    bitisTitleCell.alignment = leftAligment;
    bitisTitleCell.border = border;

    bitisTitleCell.value = 'İşin Sözleşmeye Göre Bitim Tarihi';
    const bitisValueCell = sheet.getCell('D6');
    bitisValueCell.value = moment(
      this.gunlukRapor.predicted_end_date,
      'X',
    ).format('DD/MM/YYYY');
    bitisValueCell.alignment = leftAligment;
    bitisValueCell.border = border;

    const teklifRow = sheet.getRow(7);
    teklifRow.height = 25;
    sheet.mergeCells('A7:C7');
    sheet.mergeCells('D7:E7');
    const teklifTitleCell = sheet.getCell('A7');
    teklifTitleCell.value = 'İşin Teklif Bedeli';
    teklifTitleCell.alignment = leftAligment;
    teklifTitleCell.border = border;

    const teklifValueCell = sheet.getCell('D7');
    // tslint:disable-next-line: max-line-length
    const curr = this.gunlukRapor.contract_worth_currency
      ? getMoneyCurrency(this.gunlukRapor.contract_worth_currency)
      : '';
    teklifValueCell.value = `${this.gunlukRapor.contract_worth} ${curr}`;

    teklifValueCell.alignment = leftAligment;
    teklifValueCell.border = border;

    if (this.endDate) {
      const havaRow = sheet.getRow(8);
      teklifRow.height = 25;
      sheet.mergeCells('A8:C8');
      sheet.mergeCells('D8:E8');
      const havaTitleCell = sheet.getCell('A8');
      havaTitleCell.value = 'Hava Durumu';
      havaTitleCell.alignment = leftAligment;
      havaTitleCell.border = border;

      const havaValueCell = sheet.getCell('D8');
      let havaDurumu = '';
      if (this.gunlukRapor.weather.max_temp) {
        havaDurumu = `${this.gunlukRapor.weather.max_temp} °C ${this.gunlukRapor.weather.max_temp} °C`;
      }
      havaValueCell.value = havaDurumu;
      havaValueCell.alignment = leftAligment;
      havaValueCell.border = border;
    }

    sheet.mergeCells('F4:F5');
    const raporNoCell = sheet.getCell('F4');
    raporNoCell.value = `Rapor No ${this.gunlukRapor.rapor_no}`;
    raporNoCell.alignment = {
      ...leftAligment,
      ...{ wrapText: true, shrinkToFit: true },
    };

    raporNoCell.border = border;

    let tarih_val = moment(this.gunlukRapor.date, 'X').format('DD/MM/YYYY');

    if (this.endDate) {
      const tarih_end_val = moment(this.gunlukRapor.end_date, 'X').format(
        'DD/MM/YYYY',
      );
      tarih_val = `${tarih_val} - ${tarih_end_val}`;
    }
    sheet.mergeCells('F6:F8');
    const tarihCell = sheet.getCell('F6');
    tarihCell.value = `Rapor Tarihi ${tarih_val}`;
    tarihCell.alignment = {
      ...leftAligment,
      ...{ wrapText: true, shrinkToFit: true },
    };
    tarihCell.border = border;

    sheet.mergeCells('A9:F9');

    const puantajTitleRow = sheet.getRow(10);
    puantajTitleRow.height = 25;
    sheet.mergeCells('A10:F10');

    const puantajTitleCell = sheet.getCell('A10');
    puantajTitleCell.value = 'Şantiye Puantajı';
    puantajTitleCell.alignment = centerAligment;
    puantajTitleCell.border = border;
    puantajTitleCell.font = subTitleFont;

    const puantajSubTitleRow = sheet.getRow(12);
    puantajTitleRow.height = 25;
    sheet.mergeCells('A11:D11');
    sheet.mergeCells('E11:F11');

    const puantajSubTitleCell = sheet.getCell('A11');
    puantajSubTitleCell.value = 'Ekip Adı';
    puantajSubTitleCell.alignment = leftAligment;
    puantajSubTitleCell.border = border;

    const puantajSub2TitleCell = sheet.getCell('E11');
    puantajSub2TitleCell.value = 'Personel Sayısı';
    puantajSub2TitleCell.alignment = centerAligment;
    puantajSub2TitleCell.border = border;

    const puantajStartRow = 12;
    let puantajRow = puantajStartRow;

    this.gunlukRapor.puantaj_list.forEach((element) => {
      if (element.job) {
        const curPuantajRow = sheet.getRow(puantajRow);
        curPuantajRow.height = 20;
        sheet.mergeCells(`A${puantajRow}:D${puantajRow}`);
        sheet.mergeCells(`E${puantajRow}:F${puantajRow}`);
        const jobCell = sheet.getCell(`A${puantajRow}`);
        jobCell.value = element.job;
        const countCell = sheet.getCell(`E${puantajRow}`);
        countCell.value = element.count;
        puantajRow += 1;
      }
    });
    sheet.mergeCells(`A${puantajRow}:D${puantajRow}`);
    sheet.mergeCells(`E${puantajRow}:F${puantajRow}`);
    const toplamCell = sheet.getCell(`A${puantajRow}`);
    toplamCell.value = 'Toplam';
    const toplamValueCell = sheet.getCell(`F${puantajRow}`);
    toplamValueCell.value = this.gunlukRapor.day_puantaj.last_puantaj_count;
    console.log('TOPLAM CELL : ', puantajRow);
    puantajRow += 1;

    sheet.mergeCells(`A${puantajRow}:F${puantajRow}`);
    puantajRow += 1;

    const depoStartRow = puantajRow + 1;
    let curDepoIndex = depoStartRow;

    const depoTitleRow = sheet.getRow(curDepoIndex);
    depoTitleRow.height = 25;
    sheet.mergeCells(`A${curDepoIndex}:F${curDepoIndex}`);

    const depoTitleCell = sheet.getCell(`A${curDepoIndex}`);
    depoTitleCell.value = 'Gelen Malzeme';
    depoTitleCell.alignment = centerAligment;
    depoTitleCell.border = border;
    depoTitleCell.font = subTitleFont;
    curDepoIndex += 1;

    const depoSubTitleRow = sheet.getRow(curDepoIndex);
    depoSubTitleRow.height = 25;
    sheet.mergeCells(`A${curDepoIndex}:D${curDepoIndex}`);
    sheet.mergeCells(`E${curDepoIndex}:F${curDepoIndex}`);

    const depoSubTitleCell = sheet.getCell(`A${curDepoIndex}`);
    depoSubTitleCell.value = 'Gelen Malzeme';
    depoSubTitleCell.alignment = leftAligment;
    depoSubTitleCell.border = border;

    const depoSub2TitleCell = sheet.getCell(`E${curDepoIndex}`);
    depoSub2TitleCell.value = 'Miktar';
    depoSub2TitleCell.alignment = centerAligment;
    depoSub2TitleCell.border = border;
    curDepoIndex += 1;

    this.gunlukRapor.depo.forEach((element) => {
      if (element.product_name) {
        const curDepoRow = sheet.getRow(curDepoIndex);
        curDepoRow.height = 20;
        sheet.mergeCells(`A${curDepoIndex}:D${curDepoIndex}`);
        const urunCell = sheet.getCell(`A${curDepoIndex}`);
        urunCell.value = element.product_name;
        const amountCell = sheet.getCell(`E${curDepoIndex}`);
        const amountVal = element.amount ? element.amount : 0;
        amountCell.value = `${amountVal}`;
        amountCell.alignment = { vertical: 'bottom', horizontal: 'right' };
        const unitCell = sheet.getCell(`F${curDepoIndex}`);
        const unitVal = element.unit ? getBirim(element.unit) : '';
        unitCell.value = unitVal;
        curDepoIndex += 1;
      }
    });

    sheet.mergeCells(`A${curDepoIndex}:F${curDepoIndex}`);

    const betonStartRow = curDepoIndex + 1;
    let betonDepoIndex = betonStartRow;

    const betonTitleRow = sheet.getRow(betonDepoIndex);
    betonTitleRow.height = 25;
    sheet.mergeCells(`A${betonDepoIndex}:F${betonDepoIndex}`);

    const betonTitleCell = sheet.getCell(`A${betonDepoIndex}`);
    betonTitleCell.value = 'Dökülen Beton';
    betonTitleCell.alignment = centerAligment;
    betonTitleCell.border = border;
    betonTitleCell.font = subTitleFont;
    betonDepoIndex += 1;

    const betonSubTitleRow = sheet.getRow(betonDepoIndex);
    betonSubTitleRow.height = 25;
    sheet.mergeCells(`A${betonDepoIndex}:D${betonDepoIndex}`);
    sheet.mergeCells(`E${betonDepoIndex}:F${betonDepoIndex}`);

    const betonSubTitleCell = sheet.getCell(`A${betonDepoIndex}`);
    betonSubTitleCell.value = 'Beton Sınıfı';
    betonSubTitleCell.alignment = leftAligment;
    betonSubTitleCell.border = border;

    const betonSub2TitleCell = sheet.getCell(`E${betonDepoIndex}`);
    betonSub2TitleCell.value = 'Miktar';
    betonSub2TitleCell.alignment = centerAligment;
    betonSub2TitleCell.border = border;
    betonDepoIndex += 1;

    this.gunlukRapor.beton_dok.forEach((element) => {
      if (element.beton_sinif) {
        const curDepoRow = sheet.getRow(betonDepoIndex);
        curDepoRow.height = 20;
        sheet.mergeCells(`A${betonDepoIndex}:D${betonDepoIndex}`);
        const urunCell = sheet.getCell(`A${betonDepoIndex}`);
        urunCell.value = element.beton_sinif;
        const amountCell = sheet.getCell(`E${betonDepoIndex}`);
        amountCell.value = `${element.miktar}`;
        amountCell.alignment = { vertical: 'bottom', horizontal: 'right' };

        const unitCell = sheet.getCell(`F${betonDepoIndex}`);
        const unitVal = element.unit ? getBirim(element.unit) : '';
        unitCell.value = 'm³';

        betonDepoIndex += 1;
      }
    });

    sheet.mergeCells(`A${betonDepoIndex}:F${betonDepoIndex}`);

    const isMakinesiStartRow = betonDepoIndex + 1;
    let isMakinesiIndex = isMakinesiStartRow;

    const makineTitleRow = sheet.getRow(isMakinesiIndex);
    makineTitleRow.height = 25;
    sheet.mergeCells(`A${isMakinesiIndex}:F${isMakinesiIndex}`);

    const makineTitleCell = sheet.getCell(`A${isMakinesiIndex}`);
    makineTitleCell.value = 'Çalışan İş Makineleri';
    makineTitleCell.alignment = centerAligment;
    makineTitleCell.border = border;
    makineTitleCell.font = subTitleFont;
    isMakinesiIndex += 1;

    const makineSubTitleRow = sheet.getRow(isMakinesiIndex);
    makineSubTitleRow.height = 25;
    sheet.mergeCells(`A${isMakinesiIndex}:D${isMakinesiIndex}`);
    sheet.mergeCells(`E${isMakinesiIndex}:F${isMakinesiIndex}`);

    const makineSubTitleCell = sheet.getCell(`A${isMakinesiIndex}`);
    makineSubTitleCell.value = 'Makine Tipi';
    makineSubTitleCell.alignment = leftAligment;
    makineSubTitleCell.border = border;

    const makineSub2TitleCell = sheet.getCell(`E${isMakinesiIndex}`);
    makineSub2TitleCell.value = 'Adet';
    makineSub2TitleCell.alignment = centerAligment;
    makineSub2TitleCell.border = border;
    isMakinesiIndex += 1;

    this.gunlukRapor.machines.forEach((element) => {
      if (element.machine_type) {
        const curMakineRow = sheet.getRow(isMakinesiIndex);
        curMakineRow.height = 20;
        sheet.mergeCells(`A${isMakinesiIndex}:D${isMakinesiIndex}`);
        const makineCell = sheet.getCell(`A${isMakinesiIndex}`);
        makineCell.value = element.machine_type;
        const amountCell = sheet.getCell(`E${isMakinesiIndex}`);
        amountCell.value = `${element.count}`;

        const unitCell = sheet.getCell(`F${isMakinesiIndex}`);
        const unitVal = element.unit ? getBirim(element.unit) : '';
        unitCell.value = 'adet';
        isMakinesiIndex += 1;
      }
    });

    sheet.mergeCells(`A${isMakinesiIndex}:F${isMakinesiIndex}`);

    const devamEdenIslerStartRow = isMakinesiIndex + 1;
    let devamEdenIslerIndex = devamEdenIslerStartRow;

    const devamEdenIslerTitleRow = sheet.getRow(devamEdenIslerIndex);
    devamEdenIslerTitleRow.height = 25;
    sheet.mergeCells(`A${devamEdenIslerIndex}:F${devamEdenIslerIndex}`);

    const devamEdenIslerTitleCell = sheet.getCell(`A${devamEdenIslerIndex}`);
    devamEdenIslerTitleCell.value = 'Devam Eden İşler';
    devamEdenIslerTitleCell.alignment = centerAligment;
    devamEdenIslerTitleCell.border = border;
    devamEdenIslerTitleCell.font = subTitleFont;
    devamEdenIslerIndex += 1;

    const devamEdenIslerSubTitleRow = sheet.getRow(devamEdenIslerIndex);
    devamEdenIslerSubTitleRow.height = 25;
    sheet.mergeCells(`A${devamEdenIslerIndex}:D${devamEdenIslerIndex}`);
    sheet.mergeCells(`E${devamEdenIslerIndex}:F${devamEdenIslerIndex}`);

    const devamEdenIslerSubTitleCell = sheet.getCell(`A${devamEdenIslerIndex}`);
    devamEdenIslerSubTitleCell.value = 'İşin Adı';
    devamEdenIslerSubTitleCell.alignment = leftAligment;
    devamEdenIslerSubTitleCell.border = border;

    const devamEdenIslerSub2TitleCell = sheet.getCell(
      `E${devamEdenIslerIndex}`,
    );
    devamEdenIslerSub2TitleCell.value = 'Tamalanma Oranı';
    devamEdenIslerSub2TitleCell.alignment = centerAligment;
    devamEdenIslerSub2TitleCell.border = border;
    devamEdenIslerIndex += 1;

    this.gunlukRapor.devam_eden_isler.forEach((element) => {
      if (element.title) {
        const curdevamEdenIslerRow = sheet.getRow(devamEdenIslerIndex);
        curdevamEdenIslerRow.height = 20;
        sheet.mergeCells(`A${devamEdenIslerIndex}:D${devamEdenIslerIndex}`);
        sheet.mergeCells(`E${devamEdenIslerIndex}:F${devamEdenIslerIndex}`);

        const devamEdenIslerCell = sheet.getCell(`A${devamEdenIslerIndex}`);
        devamEdenIslerCell.value = element.title;
        if (element.desc) {
          devamEdenIslerCell.note = element.desc;
        }
        const amountCell = sheet.getCell(`E${devamEdenIslerIndex}`);
        amountCell.value = `${element.work_done_percentage}`;
        devamEdenIslerIndex += 1;
      }
    });

    sheet.mergeCells(`A${devamEdenIslerIndex}:F${devamEdenIslerIndex}`);

    const bitenEdenIslerStartRow = devamEdenIslerIndex + 1;
    let bitenEdenIslerIndex = bitenEdenIslerStartRow;

    const bitenIslerTitleRow = sheet.getRow(bitenEdenIslerIndex);
    bitenIslerTitleRow.height = 25;
    sheet.mergeCells(`A${bitenEdenIslerIndex}:F${bitenEdenIslerIndex}`);

    const bitenIslerTitleCell = sheet.getCell(`A${bitenEdenIslerIndex}`);
    bitenIslerTitleCell.value = 'Biten İşler';
    bitenIslerTitleCell.alignment = centerAligment;
    bitenIslerTitleCell.border = border;
    bitenIslerTitleCell.font = subTitleFont;
    bitenEdenIslerIndex += 1;

    const bitenIslerSubTitleRow = sheet.getRow(bitenEdenIslerIndex);
    bitenIslerSubTitleRow.height = 25;
    sheet.mergeCells(`A${bitenEdenIslerIndex}:D${bitenEdenIslerIndex}`);
    sheet.mergeCells(`E${bitenEdenIslerIndex}:F${bitenEdenIslerIndex}`);

    const bitenIslerSubTitleCell = sheet.getCell(`A${bitenEdenIslerIndex}`);
    bitenIslerSubTitleCell.value = 'İşin Adı';
    bitenIslerSubTitleCell.alignment = leftAligment;
    bitenIslerSubTitleCell.border = border;

    const bitenIslerSub2TitleCell = sheet.getCell(`E${bitenEdenIslerIndex}`);
    bitenIslerSub2TitleCell.value = 'Tamalanma Oranı';
    bitenIslerSub2TitleCell.alignment = centerAligment;
    bitenIslerSub2TitleCell.border = border;
    bitenEdenIslerIndex += 1;

    this.gunlukRapor.biten_isler.forEach((element) => {
      if (element.title) {
        const curbitenIslerRow = sheet.getRow(bitenEdenIslerIndex);
        curbitenIslerRow.height = 20;
        sheet.mergeCells(`A${bitenEdenIslerIndex}:D${bitenEdenIslerIndex}`);
        sheet.mergeCells(`E${bitenEdenIslerIndex}:F${bitenEdenIslerIndex}`);

        const bitenIslerCell = sheet.getCell(`A${bitenEdenIslerIndex}`);
        bitenIslerCell.value = element.title;
        if (element.desc) {
          bitenIslerCell.note = element.desc;
        }
        const amountCell = sheet.getCell(`E${bitenEdenIslerIndex}`);
        amountCell.value = `${element.work_done_percentage}`;
        bitenEdenIslerIndex += 1;
      }
    });

    sheet.mergeCells(`A${bitenEdenIslerIndex}:F${bitenEdenIslerIndex}`);

    const imzaStartRow = bitenEdenIslerIndex + 1;
    let imzaIndex = imzaStartRow;
    const imzaTitleRow = sheet.getRow(imzaIndex);
    imzaTitleRow.height = 30;

    sheet.mergeCells(`A${imzaIndex}:C${imzaIndex}`);
    sheet.mergeCells(`D${imzaIndex}:F${imzaIndex}`);

    const yukTitleCell = sheet.getCell(`A${imzaIndex}`);
    let yukelniciVal = 'Yüklenici';
    if (this.gunlukRapor.contractor) {
      yukelniciVal = `${this.gunlukRapor.contractor}\r\n(${yukelniciVal})`;
    }
    yukTitleCell.value = yukelniciVal;
    yukTitleCell.alignment = centerAligment;
    yukTitleCell.font = font;
    yukTitleCell.border = border;

    const isvenerTitleCell = sheet.getCell(`D${imzaIndex}`);
    let isverenVal = 'İşveren';
    if (this.gunlukRapor.employee) {
      isverenVal = `${this.gunlukRapor.employee}\r\n(İşveren)`;
    }

    isvenerTitleCell.value = isverenVal;
    isvenerTitleCell.alignment = centerAligment;
    isvenerTitleCell.font = font;
    isvenerTitleCell.border = border;
    imzaIndex += 1;

    const imzaAreaRow = sheet.getRow(imzaIndex);
    imzaAreaRow.height = 40;

    sheet.mergeCells(`A${imzaIndex}:C${imzaIndex}`);
    sheet.mergeCells(`D${imzaIndex}:F${imzaIndex}`);

    const yukAreaTitleCell = sheet.getCell(`A${imzaIndex}`);
    yukAreaTitleCell.alignment = centerAligment;
    yukAreaTitleCell.font = font;
    yukAreaTitleCell.border = border;

    const isvenerAreaCell = sheet.getCell(`D${imzaIndex}`);
    isvenerAreaCell.alignment = centerAligment;
    isvenerAreaCell.font = font;
    isvenerAreaCell.border = border;
  }
  async exportPuantajExcel(data) {
    /**
     * Başlıklar Liste Şeklinde [{title: Ana Başlık, data_name: 'created_time'}] şeklinde tanımlanır.
     * Listeleme Başlıkların Veriliş şekline Göre Yapılır.
     */
    const start = moment(this.gunlukRapor.date, 'X').format('DD_MM_YYYY');
    const bitis = moment(this.gunlukRapor.end_date, 'X').format('DD_MM_YYYY');

    const anaBaslik = `${
      this.gunlukRapor.work_name ? this.gunlukRapor.work_name : ''
    } Puantaj Dökümü - ${start} - ${bitis}`;
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };

    data.forEach((element) => {
      const sheet = this.workbook.addWorksheet(
        `${element.taseron_name} Puantaj`,
      );

      // Önce Başlıklar Satırı Oluşturulur.
      const basliklar = [
        { header: 'Tarih', key: 'tarih', width: 20 },
        { header: 'Meslek', key: 'job_name', width: 30 },
        { header: 'Tam Gün', key: 'job_name', width: 30 },
        { header: 'Yarım Gün', key: 'job_name', width: 30 },

        { header: 'Kişi', key: 'employee', width: 40 },
        { header: 'Kimlik No', key: 'kimlik_no', width: 30 },
        { header: 'Çalışma Durumu', key: 'yarim_gun', width: 30 },
        { header: 'Mesai', key: 'mesai', width: 10 },
      ];

      const taseronRow = sheet.getRow(1);
      sheet.mergeCells('A1:H1');
      const taseronCell = sheet.getCell('A1');
      taseronCell.value = `${
        this.gunlukRapor.work_name ? this.gunlukRapor.work_name : ''
      }  ${element.taseron_name}`;
      taseronCell.font = font;
      taseronCell.alignment = { vertical: 'middle', horizontal: 'center' };
      taseronRow.height = 20;

      const baslikRow = sheet.getRow(2);
      baslikRow.height = 20;

      basliklar.forEach((elm, i) => {
        const colum = sheet.getColumn(i + 1);
        colum.width = elm.width;
        const firtCell = baslikRow.getCell(i + 1);
        firtCell.value = elm.header;
        firtCell.font = font;
        firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      sheet.autoFilter = {
        from: {
          row: 2,
          column: 1,
        },
        to: {
          row: 2,
          column: basliklar.length,
        },
      };

      let rowIndex = 3;
      let jobIndex = 3;
      let curr = 3;
      let toplam_tam_gun = 0;
      let toplam_yarim_gun = 0;
      element.days.forEach((day, i) => {
        let alan = rowIndex;
        let tam_gun = 0;
        let yarim_gun = 0;
        day.jobs.forEach((per) => {
          alan += per.puantajList.length;
          const jobNameArea = `B${jobIndex}:B${
            jobIndex + per.puantajList.length - 1
          }`;
          sheet.mergeCells(jobNameArea);
          jobIndex += per.puantajList.length;
          const jobAreaCell = sheet.getCell(`B${jobIndex - 1}`);
          jobAreaCell.value = per.job_name;
          jobAreaCell.alignment = { vertical: 'middle', horizontal: 'center' };
          per.puantajList.forEach((kisi) => {
            sheet.getCell(`E${curr}`).value = kisi.employee;
            sheet.getCell(`F${curr}`).value = kisi.employee_tckimlik;
            sheet.getCell(`G${curr}`).value = kisi.yarim_gun
              ? 'Yarım Gün'
              : 'Tam Gün';
            sheet.getCell(`H${curr}`).value = kisi.mesai;
            curr += 1;
            if (kisi.yarim_gun) {
              yarim_gun += 1;
              toplam_yarim_gun += 1;
            } else {
              tam_gun += 1;
              toplam_tam_gun += 1;
            }
          });
        });

        const mergeText = `A${rowIndex}:A${alan - 1}`;
        sheet.mergeCells(mergeText);
        const tarihCell = sheet.getCell(`A${alan - 1}`);
        tarihCell.value = day.tarih;
        tarihCell.alignment = { vertical: 'middle', horizontal: 'center' };

        const tamMargeText = `C${rowIndex}:C${alan - 1}`;
        sheet.mergeCells(tamMargeText);
        const tamCell = sheet.getCell(`C${alan - 1}`);
        tamCell.value = tam_gun;
        tamCell.alignment = { vertical: 'middle', horizontal: 'center' };

        const yarimMargeText = `D${rowIndex}:D${alan - 1}`;
        sheet.mergeCells(yarimMargeText);
        const yarimCell = sheet.getCell(`D${alan - 1}`);
        yarimCell.value = yarim_gun;
        yarimCell.alignment = { vertical: 'middle', horizontal: 'center' };
        rowIndex = alan;
      });
    });
  }
  async getDepoData() {
    let filter = {
      start_date: this.momentDate(true),
      end_date: this.momentDate(false),
      santiye_id: this.santiyeId,
    };
    filter = removeAllNull(filter);

    if (filter.start_date) filter.start_date = Number(filter.start_date);
    if (filter.end_date) filter.end_date = Number(filter.end_date);
    return await this.depoService.filterController(filter).toPromise();
  }
  async exportDepoExcel(data) {
    const basliklar = [
      { header: 'Tarih', key: 'created_time', width: 20 },
      { header: 'Ürün Adı', key: 'product_name', width: 50 },
      { header: 'Marka', key: 'brand_name', width: 30 },
      { header: 'Miktar', key: 'amount', width: 20 },
      { header: 'Birim', key: 'unit', width: 20 },

      { header: 'İşlem', key: 'warehouse_in_out_type', width: 30 },
      { header: 'Tedarikci', key: 'delivery_by_org_name', width: 40 },
      { header: 'İşlemi Yapan', key: 'created_by_name', width: 40 },
      { header: 'Karşı Taraf Personel', key: 'delivery_by_name', width: 40 },
      { header: 'Not', key: 'note', width: 70 },
    ];

    console.log('DEPO DATA : ', data);
    data.forEach((elm) => {
      if (elm.created_time)
        elm.created_time = moment(elm.created_time, 'X').format('DD/MM/YYYY');
      if (elm.warehouse_in_out_type) {
        switch (elm.warehouse_in_out_type) {
          case 1:
            elm.warehouse_in_out_type = 'Sahadan İade';
            break;
          case 2:
            elm.warehouse_in_out_type = 'Tedarikçiden Giriş';
            break;
          case 3:
            elm.warehouse_in_out_type = 'Sahaya Ürün Çıkışı';
            break;
          case 4:
            elm.warehouse_in_out_type = 'Tedarikçiye İade';
            break;
          case 5:
            elm.warehouse_in_out_type = 'Silinen';
            break;
        }
      }
      if (elm.unit) elm.unit = `${getBirim(elm.unit)}`;
      if (elm.irsaliye_image) elm.image = elm.irsaliye_image;
    });
    const sheet = this.workbook.addWorksheet('Depo Icmal');

    // Önce Başlıklar Satırı Oluşturulur.
    const baslikRow = sheet.getRow(1);
    baslikRow.height = 20;
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };
    basliklar.forEach((elm, i) => {
      const colum = sheet.getColumn(i + 1);
      colum.width = elm.width;
      const firtCell = baslikRow.getCell(i + 1);
      firtCell.value = elm.header;
      firtCell.font = font;
      firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: 1,
        column: basliklar.length,
      },
    };
    data.forEach((element, i) => {
      const temp = [];
      basliklar.forEach((baslik, k) => {
        temp.push(element[baslik.key]);
      });

      sheet.addRow(temp);
    });
  }

  async getBetonReport() {
    let filter = {
      start_date: this.momentDate(true),
      end_date: this.momentDate(false),
      santiye_id: this.santiyeId,
      detay: true,
    };
    filter = removeAllNull(filter);

    if (filter.start_date) filter.start_date = Number(filter.start_date);
    if (filter.end_date) filter.end_date = Number(filter.end_date);

    return await this.depoService.getBetons({ filter: filter }).toPromise();
  }
  async exportBetonExcel(data) {
    const basliklar = [
      { header: 'Tarih', key: 'created_time', width: 20 },
      { header: 'Beton Sınıfı', key: 'beton_sinif_title', width: 20 },
      { header: 'Şantiye', key: 'santiye_name', width: 30 },
      { header: 'Miktar', key: 'miktar', width: 20 },
      { header: 'Tedarikci', key: 'tedarikci_name', width: 30 },
      { header: 'İrsaliye No', key: 'irsaliye_no', width: 30 },
      { header: 'Teslim Eden', key: 'teslim_eden', width: 30 },
      { header: 'Döküm Şekli', key: 'dokum_sekli', width: 20 },
      { header: 'Mahal', key: 'mahal', width: 30 },
      { header: 'Birim', key: 'unit', width: 10 },
      { header: 'İşlemi Yapan', key: 'created_by_name', width: 40 },
    ];

    data.forEach((elm) => {
      if (elm.created_time)
        elm.created_time = moment(elm.created_time, 'X').format('DD/MM/YYYY');
      if (elm.dokum_sekli) {
        elm.dokum_sekli = dokumTypes.find(
          (x) => (x.id = elm.dokum_sekli),
        ).title;
      }
      elm['unit'] = 'm³';
    });

    const sheet = this.workbook.addWorksheet('Beton Icmal');

    // Önce Başlıklar Satırı Oluşturulur.
    const baslikRow = sheet.getRow(1);
    baslikRow.height = 20;
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };
    basliklar.forEach((elm, i) => {
      const colum = sheet.getColumn(i + 1);
      colum.width = elm.width;
      const firtCell = baslikRow.getCell(i + 1);
      firtCell.value = elm.header;
      firtCell.font = font;
      firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: 1,
        column: basliklar.length,
      },
    };
    data.forEach((element, i) => {
      const temp = [];
      basliklar.forEach((baslik, k) => {
        temp.push(element[baslik.key]);
      });
      sheet.addRow(temp);
    });
  }

  async getIsMakinesiRapor() {
    let filter = {
      start_time: this.momentDate(true),
      finish_time: this.momentDate(false),
      santiye_id: this.santiyeId,
    };
    filter = removeAllNull(filter);

    if (filter.start_time) filter.start_time = Number(filter.start_time);
    if (filter.finish_time) filter.finish_time = Number(filter.finish_time);

    return await this.isMakinalariService
      .getIsMakinalariPuantajRaporu({ filter: filter })
      .toPromise();
  }

  async exportMakineExcel(data) {
    console.log('IS MANKINESI DATA : ', data);
    data.forEach((item) => {
      if (item.start_time && item.finish_time) {
        item.tarih = moment(item.start_time, 'X')
          .tz(item.gmt)
          .format('DD/MM/YYYY');
        item.baslangic = moment(item.start_time, 'X')
          .tz(item.gmt)
          .format('DD/MM/YYYY HH:mm');
        if (item.finish_time) {
          item.bitis = moment(item.finish_time, 'X')
            .tz(item.gmt)
            .format('DD/MM/YYYY HH:mm');
        }
      }
    });
    const basliklar = [
      { header: 'Tarih', key: 'tarih', width: 20 },
      { header: 'Şantiye', key: 'santiye_name', width: 30 },
      { header: 'Makine Tipi', key: 'machine_type_title', width: 40 },
      { header: 'Taşeron', key: 'taseron_name', width: 30 },
      { header: 'Başlatan', key: 'baslatan', width: 30 },
      { header: 'Durduran', key: 'durduran', width: 30 },
      { header: 'Başlangıç Saati', key: 'baslangic', width: 20 },
      { header: 'Bitiş Saati', key: 'bitis', width: 30 },
      { header: 'Açıklama', key: 'note', width: 40 },
    ];
    const sheet = this.workbook.addWorksheet('İş Makinesi İcmal');

    // Önce Başlıklar Satırı Oluşturulur.
    const baslikRow = sheet.getRow(1);
    baslikRow.height = 20;
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };
    basliklar.forEach((elm, i) => {
      const colum = sheet.getColumn(i + 1);
      colum.width = elm.width;
      const firtCell = baslikRow.getCell(i + 1);
      firtCell.value = elm.header;
      firtCell.font = font;
      firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: 1,
        column: basliklar.length,
      },
    };
    data.forEach((element, i) => {
      const temp = [];
      basliklar.forEach((baslik, k) => {
        temp.push(element[baslik.key]);
      });
      sheet.addRow(temp);
    });
  }

  async getYapilaIsReport() {
    let filter = {
      start_date: this.momentDate(true),
      end_date: this.momentDate(false),
      santiye_id: this.santiyeId,
    };
    filter = removeAllNull(filter);

    if (filter.start_date) filter.start_date = Number(filter.start_date);
    if (filter.end_date) filter.end_date = Number(filter.end_date);

    return await this.yapilanIslerService
      .getYapilanIslerList(filter)
      .toPromise();
  }

  async exportIslerExcel(data) {
    console.log('Yapılan İşler DATA : ', data);
    data.forEach((item) => {
      let gmt = 'Europe/Istanbul';
      if (item.gmt) {
        gmt = item.gmt;
      }
      if (item.date) {
        item.date = moment(item.date, 'X').tz(gmt).format('DD/MM/YYYY');
      }
    });
    const basliklar = [
      { header: 'Tarih', key: 'date', width: 20 },
      { header: 'İşin Adı', key: 'title', width: 40 },
      {
        header: 'Tamamlanma Oranı (%)',
        key: 'work_done_percentage',
        width: 20,
      },
      { header: 'Açıklama', key: 'desc', width: 40 },
    ];
    const sheet = this.workbook.addWorksheet('Yapılan İşler');

    // Önce Başlıklar Satırı Oluşturulur.
    const baslikRow = sheet.getRow(1);
    baslikRow.height = 20;
    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };
    basliklar.forEach((elm, i) => {
      const colum = sheet.getColumn(i + 1);
      colum.width = elm.width;
      const firtCell = baslikRow.getCell(i + 1);
      firtCell.value = elm.header;
      firtCell.font = font;
      firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    sheet.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: 1,
        column: basliklar.length,
      },
    };
    let currIndex = 2;
    data.forEach((element, i) => {
      const tarihCell = sheet.getCell(`A${currIndex}`);
      tarihCell.value = element.date;

      const workNameCell = sheet.getCell(`B${currIndex}`);
      workNameCell.value = element.title;

      const percentageCell = sheet.getCell(`C${currIndex}`);
      percentageCell.value = element.work_done_percentage;
      percentageCell.alignment = { vertical: 'bottom', horizontal: 'left' };

      const descCell = sheet.getCell(`D${currIndex}`);
      descCell.value = element.desc ? element.desc : '';

      if (element.work_done_percentage == 100) {
        const fill: FillPattern = {
          type: 'pattern',
          pattern: 'mediumGray',
          fgColor: { argb: 'FFFF0000' },
        };
        tarihCell.style.fill = fill;
        workNameCell.style.fill = fill;
        percentageCell.style.fill = fill;
        descCell.style.fill = fill;
      }
      currIndex += 1;
    });
  }
}
