import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TalepService } from '../../../services/talep.service';
import { TalepModel } from '../../../models/talep.model';
import { KullaniciModel } from '../../../models/kullanici_model';
import { telefonKodu } from '../../../pages/constants';
import { select, Store } from '@ngrx/store';
import * as fromUser from '../../../store/user/user.connector';

@Component({
  selector: 'talep-modal',
  templateUrl: './talep-modal.component.html',
  styleUrls: ['./talep-modal.component.css'],
  providers: [TalepService],
})
export class TalepModalComponent implements OnInit {
  telefonKodu = telefonKodu[0].maskList;

  @Input() talep: TalepModel;
  user: KullaniciModel;
  tag: string = 'TalepModalComponent --->';
  isSubmitting: boolean;
  constructor(private ref: NbDialogRef<TalepModalComponent>,
    private _talepService: TalepService,
    private store: Store<{User}>
    ) {
  }

  ngOnInit() {
    console.log(this.tag, 'talep:', this.talep);
    this.ref.onBackdropClick.subscribe(val => {
      console.log('BACKDROP CLICK : ', val);
      this.closed();
    });
    this.store.pipe(select(fromUser.selectUser)).subscribe(user => {
      this.user = user;
    });
  }
  closed() {
    this.ref.close(this.talep);
  }

  talepGorev(talep: number) {
    this.isSubmitting = true;
    this._talepService.ownTalep(talep).subscribe(data => {
      this.isSubmitting = false;

      this.talep = data;

      console.log('TALEP OLAY : ', this.talep,this.user);
      const benVarmiyim = data.owned_by.find(x => x.user_id === this.user.id);
      console.log('Ben Varmıyım: ', benVarmiyim,this.talep,this.user);

        if (benVarmiyim) {
          this.talep['owned_by_me'] = true;
        } else {
          this.talep['owned_by_me'] = false;
        }
    }, () => {
      this.isSubmitting = false;
    });
  }
}
