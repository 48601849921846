import { SantiyeModel } from './../models/santiye_model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImageModel } from '../models/image.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SantiyeService {

  private readonly API_URL = `${environment.backendurl}api/santiye/`;

  constructor(private httpClient: HttpClient) { }

  getSantiyeList(data?: any): Observable<SantiyeModel[]> {

    const url: string = this.API_URL + 'search/';
    data = data ?  data : {};
    return this.httpClient.post<SantiyeModel[]>(url, data);

  }

  getSantiyeListSearch(search): Observable<SantiyeModel[]> {
    const url = this.API_URL + 'search/';
    return this.httpClient.post<SantiyeModel[]>(url, { active: false, search: search });

  }
  getSantiyeForSozlesme(_id: number): Observable<SantiyeModel[]> {
    return this.httpClient.get<SantiyeModel[]>(this.API_URL + 'detay/' + _id);
  }

  getSantiyeForID(_id: number) {
    return this.httpClient.get<SantiyeModel>(this.API_URL + 'detay/' + _id + '/');
  }

  addSantiye(santiye: SantiyeModel): Observable<SantiyeModel> {
    return this.httpClient.post<SantiyeModel>(this.API_URL, santiye);
  }
  patchSantiye(santiye: SantiyeModel, id: number) {
    const url: string = `${this.API_URL}update/${id}/`;
    return this.httpClient.patch(url, santiye);
  }

  addTest(santiye: SantiyeModel) {
    return this.httpClient.post(this.API_URL + 'test/', '');
  }
  /** id si bilinen bir santiyenin 10 günlük resimlerini gönderir.
   * @param id Resimlerini almak istediğimiz şantiyenin id'si
   * @param time UnixTimestamp türünden resim almak istediğimiz günün tarihi.
   */
  getImagesByTime(id: number, time?: number): Observable<any[]> {
    // Eğer time değeri girilmez ise direk santiyeye id ye get atmak için
    const timeStr = time ? time + '/' : '';
    const url = this.API_URL + 'imagelist/' + id + '/' + timeStr;
    return this.httpClient.get<any[]>(url);
  }
  /**
   * Şantiyeye ait kaliteli bir resim indirmye yarar.
   * @param santiyeId Resmin indirileceği şantiyenin id'si
   * @param imageId İndirilecek resmin id'si
   */
  getImageDetay(santiyeId: number, imageId: number): Observable<ImageModel> {
    // imagedetay
    const url = this.API_URL + 'imagedetay/' + santiyeId + '/' + imageId + '/';
    return this.httpClient.get<ImageModel>(url);
  }

  updateImage(imageId: number, data): Observable<ImageModel> {
    const url = `${this.API_URL}imageupdate/${imageId}/`;
    return this.httpClient.patch<ImageModel>(url, data);
  }

  getImageTest(): Observable<ImageModel[]> {
    // imagedetay
    const url = this.API_URL + 'imagelist/5/';
    return this.httpClient.get<ImageModel[]>(url);
  }
  postImageTest(data: ImageModel): Observable<ImageModel> {
    const url = this.API_URL + 'imageupload/5/';
    return this.httpClient.post<ImageModel>(url, data);
  }

  getSantiyeRapor(santiye_id: number, startDate, endDate?) {
    let url: string = `${this.API_URL}rapor/${santiye_id}/${startDate}/`;
    if (endDate) {
      url = `${url}${endDate}/`;
    }
    return this.httpClient.get<any>(url);
  }

  getSantiyRemaining(): any {
    return this.httpClient.get(this.API_URL + 'kalan/');
  }


  updateSantiye(santiye_id, data): Observable<SantiyeModel> {
    const url: string = `${this.API_URL}update/${santiye_id}/`;
    return this.httpClient.patch<SantiyeModel>(url, data);
  }
  uploadFile(file: any) {
    const url: string = `${this.API_URL}file/add/`;
    const req = new HttpRequest('POST', url, file, { reportProgress: true });
    return this.httpClient.request(req);
  }
  getFiles(santiyeId: number): Observable<any[]> {
    const url: string = `${this.API_URL}file/list/${santiyeId}/`;
    return this.httpClient.get<any[]>(url);
  }

  getFilesPercent(santiyeId: number, id: number) {
    const url: string = `${this.API_URL}file/${santiyeId}/${id}/`;
    // return this.httpClient.get<any>(url);
    const req = new HttpRequest('GET', url, { reportProgress: true });
    return this.httpClient.request(req);
  }

  getMySantiyes(): Observable<any[]> {
    // Sadece bana ait şantiyeleri listele. (Mini Serializer ile select boxlar için)
    const url: string = `${this.API_URL}mymini/`;

    return this.httpClient.get<any[]>(url);
  }
  getSantiyeMyMini(): Observable<any[]> {
    // Sadece bana ait şantiyeleri listele. (Mini Serializer ile select boxlar için)
    const url: string = `${this.API_URL}mymini/`;

    return this.httpClient.get<any[]>(url);
  }

  getSantiyeListMini(): Observable<any[]> {

    // İlişkili olduğum şantiyeleri listele. (Mini Serializer İle )
    const url: string = `${this.API_URL}mini/`;
    return this.httpClient.get<any[]>(url);

  }

  searchSantiye(term: any, page?: number, isActive?): Observable<SantiyeModel[]> {
    const url: string = `${this.API_URL}search/${page ? `${page}/` : ''}`;
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post<SantiyeModel[]>(url, { search: term, active: isActive ? !isActive : true });
  }

  getSantiyeUygunsuzlukList(data?: any): Observable<any[]> {
    const url: string = `${this.API_URL}uygunsuzluk/list/`;
    return this.httpClient.post<any[]>(url, data);
  }


  deleteImage(id: number) {
    const url: string = `${this.API_URL}image/delete/${id}/`;
    return this.httpClient.delete<any>(url);
  }

  deleteFile(instanceId: Number) {
    const url: string = `${this.API_URL}file/delete/${instanceId}/`;

    return this.httpClient.delete<any>(url);
  }


  getImageCalander(filter): Observable<any> {
    // Filter = {santiye_id: 103, start_date?: 12232123123, show_yapilan_isler?: true}
    const url: string = `${this.API_URL}imagecalender/`;
    return this.httpClient.post<any>(url, filter);
  }

  getImageForDay(filter): Observable<any> {
    // filter = {santiye_id: 103, start_date: start of the day or range, end_time: end of the day or range};

    const url: string = `${this.API_URL}imageforday/`;
    return this.httpClient.post<any>(url, filter);
  }

  getSantiyeSelectList(filter): Observable<any> {
    const url: string = `${this.API_URL}newmini/`;
    return this.httpClient.post<any>(url, filter);
  }

}
