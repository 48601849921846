import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { PursantajService } from '../../../services/pursantaj.service';
import { PursantajModel } from '../../../models/pursantaj.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { birim, responseKodlari } from '../../../pages/constants';
import * as moment from 'moment-timezone';
import { EventEmitter } from '@angular/core';
import { NbAccordionComponent, NbAccordionItemComponent, NbActionComponent, NbDialogService } from '@nebular/theme';
import { ApasImageLightboxComponent } from '../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { YapilanIsEkleModalComponent } from '../../santiye/yapilanIsler/yapilan-is-ekle-modal/yapilan-is-ekle-modal.component';
import { animate, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { YapilanIslerEkleComponent } from '../../santiye/yapilanIsler/yapilan-isler-ekle/yapilan-isler-ekle.component';


@Component({
  selector: 'gantt-item-detay',
  templateUrl: './gantt-item-detay.component.html',
  styleUrls: ['./gantt-item-detay.component.css'],
  providers: [PursantajService],
  animations: [
    trigger('childAnimation', [
      state(
        'in',
        style({ backgroundColor: 'white' }),
      ),
      state(
        'out',
        style({ backgroundColor: 'white' }),
      ),
      transition('in => out', [
        animate(
          '1s',
          keyframes([
            style({ backgroundColor: 'white' }),
            style({ backgroundColor: 'orange' }),
            style({ backgroundColor: 'white' }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class GanttItemDetayComponent implements OnInit, OnChanges {
  @Input() pursantajId: any;
  @Input() duzenle: boolean = false;
  @Output() kapat: EventEmitter<boolean> = new EventEmitter();
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @Output() changeForceUpdate: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('duzenle') acDuzenle;
  @ViewChild('bilgiler') acBilgiler;
  @ViewChild('yapilanis') yapilanIslerAccordion: NbAccordionItemComponent;
  @ViewChild('mainAccordion') mainAccordion: NbAccordionComponent;
  @Input() santiyeId: any;
  allPursantajs: PursantajModel[];
  pursantajData: any;
  isLoading: boolean = false;
  form: FormGroup;
  isAllListLoading: boolean = false;
  animate: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() forceRefresh: boolean = false;
  get ssList(): FormArray { return this.form.get('ss') as FormArray; }
  get sfList(): FormArray { return this.form.get('sf') as FormArray; }
  get fsList(): FormArray { return this.form.get('fs') as FormArray; }
  get ffList(): FormArray { return this.form.get('ff') as FormArray; }

  renkList: any[];
  isOpen: boolean = false;
  fc = 0; // Para Directive Force Refresh
  birim = birim;
  constructor(
    private pursantajService: PursantajService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();
  }
  openMode() {
    setTimeout(() => {
      this.acBilgiler.close();
      this.acDuzenle.open();
      this.animate = false;
    }, 500);

  }


  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (changes) {
        console.log('yapilan iş : ', this.yapilanIslerAccordion);
        console.log('main accordion : ', this.mainAccordion);
        if (changes.forceRefresh && changes.forceRefresh.currentValue) {
          this.getPursantajDetay();
          this.newDepend.setValue(null);
        }
        if (changes.pursantajId && changes.pursantajId.currentValue) {
          if (!(changes.pursantajId.currentValue instanceof Array)) {
            this.pursantajId = changes.pursantajId.currentValue;
          }
          this.getPursantajDetay();
        }
        if (changes.duzenle) {
          if (changes.duzenle.currentValue) {
            this.animate = true;
            this.openMode();
          }
        }
      }
    }, 500);


  }
  close() {
    this.kapat.emit(true);
  }

  ngOnInit() {
    this.isLoading = true;
    this.pursantajService.colorList().subscribe(resp => {
      this.renkList = resp;
    });
    if (this.santiyeId) {
      this.isAllListLoading = true;
      this.pursantajService.getPursantaj(this.santiyeId).subscribe(resp => {
        this.isAllListLoading = false;
        if (this.pursantajId) {
          const element = resp.find(x => x.id === this.pursantajData?.id);
          if (element) {
            element.disabled = true;
          }
        }

        this.allPursantajs = resp;
        this.returnData = [...this.allPursantajs];
      }, (err) => this.isAllListLoading = false);
    }
  }
  /**
   *
   * start_max_date : Alt Pursantajların en düşünüğünün başlangıç tarihi
   * start_min_date : Üst Pursantatın Başlangıç Tarihi

   * end_max_date   : Üst Pursantajın Bitiş Tarihi
   * end_min_date   : Alt Pursantajları en yüksek bitişi tarihi
   *
   */
  start_max_date: Date;
  start_min_date: Date;
  end_max_date: Date;
  end_min_date: Date;
  getPursantajDetay() {
    if (this.pursantajId) {
      this.form.reset();
      this.pursantajService.getAllDetayForPursantaj(this.pursantajId.getOriginalID()).subscribe(resp => {
        this.pursantajData = resp;
        this.isLoading = false;

        this.pursantajData.start_day = convertDateWithTZ(this.pursantajData, 'start_day');
        this.pursantajData.end_day = convertDateWithTZ(this.pursantajData, 'end_day');
        // tslint:disable-next-line: max-line-length
        this.start_max_date = moment(this.pursantajData.start_max_date, 'X').utc().toDate();
        // tslint:disable-next-line: max-line-length
        this.start_min_date = moment(this.pursantajData.start_min_date, 'X').utc().toDate();

        this.end_max_date = moment(this.pursantajData.end_max_date, 'X').utc().toDate();
        this.end_min_date = moment(this.pursantajData.end_min_date, 'X').toDate();
        if (!this.santiyeId) {
          this.santiyeId = resp.santiye_id;
        }
        this.changeForceUpdate.emit(false);
        this.modelToValue();
      }, (err) => {
        this.isLoading = false;
      });


    }

  }
  initForm() {
    this.form = this.fb.group({
      pursantaj_name: [],
      pursantaj_rate: [],
      metraj: [],
      metraj_unit: [],
      start_day: [],
      end_day: [],
      ust_pursantaj_id: [],
      color: [],
    });
  }

  addItemToControl(event, control) {
    const formGroup = this.form.get(control) as FormArray;
    formGroup.clear();
    event.forEach(element => {
      const newToAdd = this.fb.group(
        {
          id: [element.id],
          pursantaj_name: [element.pursantaj_name],
        },
      );
      formGroup.push(newToAdd);
    });
  }

  modelToValue() {
    if (this.pursantajData.start_day) {
      this.form.get('start_day').setValue(this.pursantajData.start_day);
    }
    if (this.pursantajData.end_day) {
      this.form.get('end_day').setValue(this.pursantajData.end_day);
    }
    if (this.renkList) {
      this.pursantajData.color = this.renkList.find(x => x.color_code == this.pursantajData.color);
      if (this.pursantajData.color) {
        this.form.get('color').setValue(this.pursantajData.color.color);
        if (this.pursantajData.ust_pursantaj_id) {
          this.form.get('color').setValue({ disabled: true });
        }
      }
    }
    if (this.pursantajData.pursantaj_name) {
      this.form.get('pursantaj_name').setValue(this.pursantajData.pursantaj_name);
    }
    if (this.pursantajData.pursantaj_rate) {
      this.form.get('pursantaj_rate').setValue(this.pursantajData.pursantaj_rate);
    }

    if (this.pursantajData.metraj) {
      this.form.get('metraj').setValue(this.pursantajData.metraj);
    }
    if (this.pursantajData.metraj_unit) {
      this.form.get('metraj_unit').setValue(this.pursantajData.metraj_unit);
    }

    console.log('MODEL TO VALUE : ', this.pursantajData);
  }
  valueToModel(data) {
    if (data.start_day) {
      data.start_day = moment(data.start_day, 'X').unix();
    }

    if (data.end_day) {
      data.end_day = moment(data.end_day, 'X').unix();
    }
    return data;
  }

  openYapilanIs() {
    this.nbDialogService.open(YapilanIslerEkleComponent,
      {
        context:
          { imalatCinsi: this.pursantajId.getOriginalID(), santiye_id: this.santiyeId },
      }).
      onClose.subscribe(() => this.getYapilanIsList());
      // this._nbDiaologService.open(
      //   YapilanIslerEkleComponent, { context: { santiye_id: this.santiyeId, row: event } },
      // ).onClose.subscribe((val) => {
      //   this.filtrele();
      // });
  }

  removeDepend(s, pType) {
    const newText: string = `${this.pursantajData.pursantaj_name} ile ${s.pursantaj_name} arasındaki ${pType} bağlantısını kaldırmak istediğinizden <u>Emin Misiniz?</u>`;
    // tslint:disable-next-line: max-line-length
    Swal.fire({ ...responseKodlari.removePursantajDepend, ...{ html: newText } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        const data = {
          id: this.pursantajData.id,
          connect_id: s.id,
          connect_type: pType,
        };
        this.pursantajService.removeDepend(data).subscribe(resp => {
          this.pursantajData = resp;
        });
      }
    });
  }
  newDepend: FormControl = new FormControl();
  addNewDepend(pType) {
    const formData = this.newDepend.value;
    if (formData) {
      const data = {
        t1: this.pursantajData.id,
        t2: formData.id,
        pType: pType,
      };
      this.setFormValues('depend', data);
    }
  }
  openImageDetail(photo, photos, photo_count, photo_index) {

    const context: {} = {
      url: `yapilanisler/${photo.object_id}/note/detail`,
      // 'url': 'santiye/imagedetay/' + this.santiyeId + '/' + imageId + '/',
      image: photo,
      imageIndex: photo_index,
      groupIndex: 0,
      method: 'post',
      rows: [{ images: photos }],
    };
    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }

  openImageList(altis) { }
  yapilanisler;

  currentTab(event) {
    if (!event) {
      this.getYapilanIsList();
    }
  }

  setFormValues(formItem: string, data?: any) {

    if (formItem) {
      setTimeout(() => {

        switch (formItem) {
          case 'start_day':
            this.changeValue.emit({start_day: this.form.get('start_day').value});
            break;
          case 'end_day':
            console.log('END DAY : ', this.form.get('end_day').value);
            this.changeValue.emit({end_day: this.form.get('end_day').value});
            break;
          case 'pursantaj_rate':
            this.changeValue.emit({ pursantaj_rate: this.form.get('pursantaj_rate').value });
            break;
          case 'pursantaj_name':
            this.changeValue.emit({ pursantaj_name: this.form.get('pursantaj_name').value });
            break;
          case 'metraj':
            this.changeValue.emit({ metraj: this.form.get('metraj').value });
            break;
          case 'metraj_unit':
            this.changeValue.emit({ metraj: this.form.get('metraj_unit').value });
            break;
          case 'all':
            const formData = removeAllNull(this.form.value);
            this.changeValue.emit({all: formData});
            break;
          case 'depend':
            this.changeValue.emit({ depend: data });
        }
      }, 500);

    }
  }
  yapilanListLoading = false;
  getYapilanIsList() {
    this.yapilanisler = [];
    this.yapilanListLoading = true;
    this.pursantajService.yapilanIsDetay(this.pursantajId.getOriginalID()).subscribe(resp => {
      this.yapilanisler = resp;
      this.yapilanListLoading = false;
    },
      (err) => { this.yapilanListLoading = false; });
  }
  returnData;
  selectList(event) {
    if (this.allPursantajs) {
      this.returnData = null;
      this.returnData = this.allPursantajs;

      const pType = event.tabTitle;
      switch (pType) {
        case 'FS':
          const fs_list = [];
          if (this.pursantajData) {
            this.pursantajData.fs.forEach(fselm => {
              fs_list.push(fselm.id);
            });
          }

          if (this.returnData) {
            this.returnData.forEach(element => {
              if (fs_list.find(x => element.id == x)) {
                element.disabled = true;
              }
            });
          }
          break;
        case 'FF':
          const ff_list = [];

          if (this.pursantajData) {
            this.pursantajData.ff.forEach(fselm => {
              ff_list.push(fselm.id);
            });
          }

          if (this.returnData) {
            this.returnData.forEach(element => {
              if (ff_list.find(x => element.id == x)) {
                element.disabled = true;
              }
            });
          }
          break;
        case 'SF':
          const sf_list = [];

          if (this.pursantajData) {
            this.pursantajData.sf.forEach(fselm => {
              sf_list.push(fselm.id);
            });
          }
          if (this.returnData) {
            this.returnData.forEach(element => {
              if (sf_list.find(x => element.id == x)) {
                element.disabled = true;
              }
            });
          }
          break;
        case 'SS':
          const ss_list = [];

          if (this.pursantajData) {
            this.pursantajData.ss.forEach(fselm => {
              ss_list.push(fselm.id);
            });
          }

          if (this.returnData) {
            this.returnData.forEach(element => {
              if (ss_list.find(x => element.id == x)) {
                element.disabled = true;
              }
            });
          }
          break;
        default:
          break;
      }
    } else {
      return [];
    }
  }
}
