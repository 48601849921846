import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { NbDialogService } from '@nebular/theme';
import { TakvimDetayComponent } from '../takvim-detay/takvim-detay.component';
import { TakvimService } from '../../../services/takvim.service';
import { TakvimModel } from '../../../models/takvim.model';
import * as moment from 'moment';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kullanici-takvim',
  templateUrl: './kullanici-takvim.component.html',
  styleUrls: ['./kullanici-takvim.component.css'],
  providers: [TakvimService],
})
export class KullaniciTakvimComponent implements OnInit, OnDestroy {

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  takvim: any;
  @Input() length: number;
  @Input() locale: string = 'tr';
  @Output() viewChange: EventEmitter<string> = new EventEmitter();
  showIsProgrami: boolean = false;
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  isLoading: boolean = true;
  hatirltamHandler$: Subscription;

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  
  constructor(private _nbDialogService: NbDialogService,
    private _takvimService: TakvimService,
    private store: Store<{ HatirlatmaRefresh }>) {
      this.hatirltamHandler$ = store.pipe(select('HatirlatmaRefresh')).subscribe(data => {
        this.getDate();
      });
  }
  ngOnDestroy(): void {
    this.hatirltamHandler$.unsubscribe();
  }
  ngOnInit() {
    this.getDate();
    // console.log('Ayı Ver', this.view);
  }

  takvimClick(gun) {
    console.log('Gün Detayı:', gun.day.events);
    const context: {} = {
      'takvimDetay': gun.day.events,
      'takvimTarih': Number(gun.day.date.getTime()) / 1000,
    };
    this._nbDialogService.open(TakvimDetayComponent, { context });

    // this._nbDialogService.open(TakvimDetayComponent, { context }).onClose.subscribe(data => {
    //   console.log('Hatırlatma Ekleme closed Takvim', data);

    //   this.getDate();


    // });

  }


  getDate() {
    this.isLoading = true;
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];
    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    const myDateStart = moment(getStart(this.viewDate));
    const myDateEnd = moment(getEnd(this.viewDate));

    this._takvimService.getTakvim(Number(myDateStart.format('X')), Number(myDateEnd.format('X'))).subscribe(data => {
      this.takvim = data;
      this.isLoading = false;
      // console.log('Takvim', data);
      this.takvim.forEach(takvimD => {
        if(takvimD.type == 4 && !this.showIsProgrami) this.showIsProgrami = true;
        takvimD.start = new Date(takvimD.date * 1000);
      });
    });
    // console.log('başlangıç:', new Date(Number(myDateStart.format('x'))));
    // console.log('Bitiş:', new Date(Number(myDateEnd.format('x'))));
  }
  openIsProgramiList(){

  }
}
