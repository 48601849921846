
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, merge, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../../pages/global-functions';
import { IsMakinalariService } from '../../../../services/is_makinalari.service';
import { ControlValueAccessorConnector } from '../../../../shared/control-value-accessor-connector';

@Component({
  selector: 'is-makinasi-select',
  template: `
  <ng-select
      [items]="makinaList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="makinaInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindValue="id"
      bindLabel="plate"
      [addTag]="false"
      [closeOnSelect]="!multiple"
      [multiple]="multiple"
      [selectOnTab]="true"
      [placeholder]="'Makina'"
      class="uzun-select"
      ngDefaultControl
      (change)="makinaSelect($event)"
      [formControl]="control"
  >
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        {{item.plate}} ({{item.machine_type_name}})
    </ng-template>
  </ng-select>`
  ,
  providers: [
    IsMakinalariService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsMakinasiSelectComponent),
      multi: true,
    },
  ],
})
// tslint:disable-next-line: max-line-length
export class IsMakinasiSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {
  isLoading: boolean = false;

  // tslint:disable-next-line: max-line-length
  @Input() bindValue: string = 'id'; // true ? sadece benim şirketime ait şantiyeleri liste : ilişkili olduğum tüm şantiyeleri listele

  // tslint:disable-next-line: max-line-length
  @Input() is_active: boolean = true; // null : tüm şanyiyeleri listele - true : sadece aktif şantiyeleri listele - false : sadece pasif şantiyeleri listele

  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() own: boolean;
  @Input() machineSearch: any; // Search Edilecek kelimeyi gönderin;
  @Input() allMachines: boolean = false;
  tag: string = '------ Machine  Selectttttttttttttt ------------> ';

  constructor(
    private isMakinalariService: IsMakinalariService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.firstList();
    if (this.machineSearch) {
      setTimeout(() => {
        this.makinaInput$.next(this.machineSearch);
      }, 1000);

    }
  }

  ngOnInit(): void {
    this.getMachineList();
  }


  makinaList$: Observable<any[]>;
  makinaInput$ = new Subject<string>();

  getMachineList() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    this.makinaList$ = concat(
      of([]), // default items
      this.makinaInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.isMakinalariService.listMachines(
            removeAllNull({ search: term, small: true, all_machines: this.allMachines }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }
  makinaSelect(event) {
    this.change.emit(event);
  }

  private firstList() {
    this.getMachineList();
    setTimeout(() => {
      this.makinaInput$.next('');
    }, 300);
  }


}
