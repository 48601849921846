import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PersonelGrubuOlusturComponent } from '../../personel/personel-grubu-olustur/personel-grubu-olustur.component';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DuyuruService } from '../../../services/duyuru.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { TaseronService } from '../../../services/taseron.service';

@Component({
  selector: 'duyuru-ekle',
  templateUrl: './duyuru-ekle.component.html',
  styleUrls: ['./duyuru-ekle.component.css'],
  providers: [OrganizasyonService, DuyuruService, TaseronService],
})
export class DuyuruEkleComponent implements OnInit {
  @ViewChild('agreeSelect') productSelect: NgSelectComponent;
  personeller;
  form: FormGroup;
  isLoading: boolean = false;
  tag: string = 'DuyuruEkleComponent --->';
  firmaLoading = true;
  constructor(private ref: NbDialogRef<DuyuruEkleComponent>,
    private _nbDiaologService: NbDialogService,
    private _duyuruService: DuyuruService,
    private _taseronService: TaseronService,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      title: [null, Validators.required],
      content: [null, Validators.required],
      announcated_foruser: [null, Validators.required],
    });
  }



  ngOnInit() {
    this._taseronService.getAllTaseron().subscribe(data => {
      this.personeller = data;
      this.firmaLoading = false;
    });
  }
  duyuruGonder() {
    console.log('Duyuru Formu: ', this.form.value);
    if (this.form.valid) {
      const formData = this.valuesToModel();
      this.isLoading = true;
      this._duyuruService.postDuyuru(formData).subscribe(data => {
        this.isLoading = false;

        Swal.fire({
          icon: 'success',
          title: 'Duyuru Gönderildi!',
          timer: 1500,
        }).then(() => {
          this.form.reset();
          this.ref.close();
        });
      }, (err: HttpErrorResponse) => {
        this.isLoading = false;

        if (err) {
          Swal.fire({
            icon: 'error',
            title: 'Duyuru Gönderilemedi !!!',
            timer: 1500,
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tüm alanları kontrol ediniz.',
        timer: 1500,
      });
    }

  }
  valuesToModel() {
    this.form.markAsTouched();

    const data = this.form.value;
    console.log(this.tag, 'form value:', this.form.value);

    const tmp_announcated_foruser = [];

    data.announcated_foruser.forEach(el => {
      tmp_announcated_foruser.push(el);
    });

    data.announcated_foruser = tmp_announcated_foruser;
    return data;

  }

  grupla = (item, selected) => {
    if (selected.company_name && item.company_name) {
      return item.company_name === selected.company_name;
    }
    if (item.name && selected.name) {
      return item.name === selected.name;
    }
    return false;
  }

  closed() {
    this.ref.close();
  }

  duyuruGrubu(select: NgSelectComponent) {
    select.close();
    this._nbDiaologService.open(PersonelGrubuOlusturComponent);
  }

}
