<nb-card status="primary" class="card" style="width: 1000px; height: 600px;">
    <nb-card-header>
        {{santiyeName}}
        <button nbButton (click)="closed()" size="tiny" class="m-close" style="background: none !important; color: #fff !important;  float: right;  font-size: 18px;">X</button>
    </nb-card-header>
    <nb-card-body>
        <nb-tabset>
            <nb-tab [tabTitle]="'SANTIYEDETA.FOTOGRAFLAR' |translate" class="p-3" [tabId]="tapTaype.List">
                <santiye-img-list [santiyeId]="santiyeId"></santiye-img-list>
            </nb-tab>
            <nb-tab [tabTitle]="'SANTIYEDETA.YENIFOTOGRAFEKLE' |translate" [tabId]="tapTaype.Add">
                <santiye-img-upload [santiyeId]="santiyeId" [santiye]="santiye" (onSuccessUploads)="successUploads()"></santiye-img-upload>
            </nb-tab>
        </nb-tabset>



    </nb-card-body>
</nb-card>