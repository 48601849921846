import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TaseronModel } from '../../../../models/taseron.model';
import { TaseronService } from '../../../../services/taseron.service';
import { SantiyeModel } from '../../../../models/santiye_model';
import { SantiyeService } from '../../../../services/santiye.service';
import { PuantajService } from '../../../../services/puantaj.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FormBuild } from '../../../../pages/formInterfaces';
import { responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'puantaj-ekle-modal',
  templateUrl: './puantaj-ekle-modal.component.html',
  styleUrls: ['./puantaj-ekle-modal.component.scss'],
  providers: [TaseronService, SantiyeService, PuantajService],
  encapsulation: ViewEncapsulation.None,
})
export class PuantajEkleModalComponent implements OnInit, FormBuild {

  tag: string = 'puantaj-ekle-modal --->';

  personelEkle: boolean = false;
  // Modal Açılırken Gelen Context Veriler
  taseron_id: number;
  taseron;
  taseron_name;
  santiye_id: number;
  disableControl: boolean = true;

  // Puantaj Ekleme Formu
  isLoading: boolean = false;
  form: FormGroup;
  puantajForm: FormGroup;
  newPersonelForm: FormGroup;
  allCheck: FormControl = new FormControl(false);
  get personelsFormArray() { return this.puantajForm.controls.personels as FormArray; }

  constructor(
    protected ref: NbDialogRef<PuantajEkleModalComponent>,
    private formBuilder: FormBuilder,
    private taseronService: TaseronService,
    private puantajService: PuantajService,
  ) {
    this.initForm();
  }
  closed(data?) {
    this.ref.close(data);

  }

  ngOnInit() {
    if (this.taseron_id) {
      this.form.get('taseron_id').setValue(this.taseron_id);
      if (this.disableControl) this.form.get('taseron_id').disable();
    } else if (this.santiye_id) {
      this.form.get('santiye_id').setValue(this.santiye_id);
      if (this.disableControl) this.form.get('santiye_id').disable();
    }


  }
  initForm() {
    this.form = this.formBuilder.group({
      taseron_id: [null, Validators.required],
      santiye_id: [null, Validators.required],
      date: [new Date(), Validators.required],
      firma_type: ['firma'], // Back-End firma id veya taşeron id üzerinden arama yapabilsin diye.
    });

    this.puantajForm = this.formBuilder.group({
      personels: new FormArray([]),
    });

    this.form.valueChanges.subscribe((data) => {
      this.getCalismaDurumlari();
    });

    this.newPersonelForm = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null],
      job_id: [null, Validators.required],
    });
  }

  // şantiyey puantaj eklerken taseron değiştirdiğimizde formu yeniler.
  getCalismaDurumlari() {
    this.allCheck.setValue(false);
    this.personelsFormArray.clear();
    this.totalPuantajHesapla();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
      
    this.isLoading = true;
    const formVal = removeAllNull(this.form.getRawValue());
    this.puantajService.postCurrentPuantaj(formVal)
      .subscribe(personels => {
        this.setPersonelList(personels);
      }, () => {
        this.setPersonelList();
      });
  }

  // puantaj kaydet


  /* Puantaj Ekle Region */

  saveLoad = false;

  setPersonelList(personels?) {
    if (personels) {
      personels.forEach((elm) => {
        this.addFormItem(elm);
      });
    } else {
      this.personelsFormArray.reset();
    }
    this.totalPuantajHesapla();

    this.isLoading = false;
  }

  addFormItem(elm) {

    const formItem = this.formBuilder.group({
      apas_id: [elm.apas_id],
      taseron_employee: [elm.id],
      job: [elm.job_id ? elm.job_id.id : null],
      name: [elm.name],
      yarim_gun: [elm.calisma_durumu],
      tam: [elm.calisma_durumu === null ? elm.calisma_durumu : !elm.calisma_durumu],
      overtime: [elm.mesai ? elm.mesai : 0, [Validators.max(24), Validators.min(0)]],
    });
    this.personelsFormArray.push(formItem);

  }

  /*
   * Eğer seçilmiş ise tam günlerdeki tikler olacak ve yarım günlerdeki tikler kalkacaktır.
  */
  selectAll(checked: boolean) {
    if (checked) {
      this.personelsFormArray.controls.forEach((element:FormControl) => {
         element.get('tam').setValue(true);
         element.get('yarim_gun').setValue(false);
         element.get('job').setValidators(Validators.required);
         element.get('job').updateValueAndValidity(); 
         element.get('job').markAsTouched();
      });

    } else {
      this.personelsFormArray.controls.forEach((element:FormControl) => { 
        element.get('tam').setValue(false);
        element.get('yarim_gun').setValue(false);
        element.get('job').setValidators(null);
        element.get('job').updateValueAndValidity(); 
        element.get('job').markAsTouched();

      });
    }


    this.totalPuantajHesapla();

  }

  /*
   * Eğer Tam gün seçilmiş ise yarım gündeki tikler kalkacaktır.
  */
  deSelectIfTamSelected(checked: boolean, id: number) {
    if (checked) {
      this.personelsFormArray.at(id).get('job').setValidators(Validators.required);
    } else {
      this.personelsFormArray.at(id).get('job').setValidators(null);
    }
    this.personelsFormArray.at(id).get('tam').setValue(checked);
    this.personelsFormArray.at(id).get('yarim_gun').setValue(false);



    this.personelsFormArray.at(id).get('job').updateValueAndValidity();

    this.personelsFormArray.at(id).get('job').markAsTouched();
    this.totalPuantajHesapla();

  }

  /*
   * Eğer yarım gün seçilmiş ise tam gündeki tikler kalkacaktır.
  */
  deSelectIfYarimSelected(checked: boolean, id: number) {
    if (checked) {
      this.personelsFormArray.at(id).get('job').setValidators(Validators.required);
    } else {
      this.personelsFormArray.at(id).get('job').setValidators(null);
    }
    if (true) { this.allCheck.setValue(false); }
    this.personelsFormArray.at(id).get('yarim_gun').setValue(checked); 
    this.personelsFormArray.at(id).get('tam').setValue(false);

    this.personelsFormArray.at(id).get('job').updateValueAndValidity();

    this.personelsFormArray.at(id).get('job').markAsTouched();
    this.totalPuantajHesapla();

  }

  totalTam = 0;
  totalYarim = 0;
  totalPuantajHesapla(){
    this.totalTam = 0;
    this.totalYarim = 0;
    const personelArray = this.personelsFormArray.value;
    const tamTotal = personelArray.filter(elm => elm.tam === true);
    const yarimtotal = personelArray.filter(elm => elm.yarim_gun === true);

    this.totalTam = tamTotal.length;
    this.totalYarim = yarimtotal.length;

    console.log('Total Puantaj Hesapla',this.personelsFormArray.controls);
  }



  postForm() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.saveLoad = true;
    const formData = this.valuesToModel();
    this.form.markAllAsTouched();

    const mainForm = removeAllNull(this.form.getRawValue());

    if (formData) {

      mainForm['puantaj_items'] = formData;
      this.puantajService.addPuantaj(mainForm).subscribe(data => {
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.ref.close();
        this.saveLoad = false;
      },
        (err: HttpErrorResponse) => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          this.saveLoad = false;
        });

    } else {
      Swal.fire({ icon: 'error', title: 'Lütfen önce personel ekleyin.', showConfirmButton: false, timer: 1500 });
      this.saveLoad = false;

    }
  }

  valuesToModel() {
    let _data = removeAllNull(this.personelsFormArray.value);
    _data = _data.filter(el => (el.tam || el.yarim_gun));
    return _data;
  }


  /* Puantaj Ekle Region End */



  /* Personel EKle Region */


  postPersonelForm() {
    if (!this.newPersonelForm.valid) {
      this.newPersonelForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    const formData = removeAllNull(this.newPersonelForm.value);

    const mainFormData = removeAllNull(this.form.getRawValue());

    if (!mainFormData.taseron_id) {
      this.form.get('taseron_id').markAsTouched();
      return;
    }

    this.taseronService.addTaseronPersonel(formData, mainFormData.taseron_id).subscribe(data => {
      this.isLoading = false;
      this.newPersonelForm.reset();
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.addNewPersonelToForm(data);
    }, (err: HttpErrorResponse) => {
      this.errors(err);
      this.isLoading = false;
    });
  }

  addNewPersonelToForm(elm) {

    const formItem = this.formBuilder.group({
      apas_id: [elm.apas_id],
      taseron_employee: [elm.id],
      job: [elm.job_id ? elm.job_id.id : null],
      name: [elm.name],
      yarim_gun: [elm.calisma_durumu],
      tam: [true],
      overtime: [0, [Validators.max(24), Validators.min(0)]],
    });
    this.personelsFormArray.insert(0, formItem);
  }

  errors(error: HttpErrorResponse) {
    // Eğer Tc kimlik no zaten kayıtlı ise
    if (error.error.ER05 !== undefined) {
      Swal.fire(responseKodlari.ER05 as SweetAlertOptions);
      // Eğer eposta zaten kayıtlı ise
    } else if (error.error.email !== undefined) {
      Swal.fire(responseKodlari.email as SweetAlertOptions);
    } else if (error.error.phone !== undefined) {
      Swal.fire(responseKodlari.phone as SweetAlertOptions);
    } else if (error.error.ER23 !== undefined) {
      Swal.fire(responseKodlari.ER23 as SweetAlertOptions);
    } else {
      Swal.fire(responseKodlari.unSuccessOrgRegister as SweetAlertOptions);
    }
  }

  /* Personel EKle Region End */

}

