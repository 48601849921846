import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ColumnMode } from '@swimlane/ngx-datatable';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BothEmptyValidator } from '../../../../kullanici/components/validators';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { SantiyeService } from '../../../../services/santiye.service';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { YapilanIslerAddPhotoComponent, NoteType } from '../yapilan-isler-add-photo/yapilan-isler-add-photo.component';
import { EditPursantajModalComponent } from '../edit-pursantaj-modal/edit-pursantaj-modal.component';

@Component({
  selector: 'yapilan-isler-ekle',
  templateUrl: './yapilan-isler-ekle.component.html',
  styleUrls: ['./yapilan-isler-ekle.component.scss'],
  providers: [YapilanIslerService],
  encapsulation: ViewEncapsulation.None,
})
export class YapilanIslerEkleComponent implements OnInit {
  @Input() santiye_id: number;
  @Input() row: any;
  santiyeDetay;
  @Input() canEdit: boolean = true;
  ColumnMode = ColumnMode;
  imalatCinsi;
  tarih_format = tarih_format;
  yapilanIsForm: FormGroup;
  yapilanIslerList = [];
  spinner = true;
  @Input() pursantajId: number;
  isLoading: boolean;
  sozlesme_id: number;
  eventC;
  @Input() isModal: boolean = false;

  constructor(

    private yapilanIslerService: YapilanIslerService,
    private nbDialogService: NbDialogService,
    private fb: FormBuilder,
    private santiyeService: SantiyeService,
    private ref: NbDialogRef<YapilanIslerEkleComponent>,
  ) {

    if (this.santiye_id) {
      this.santiyeService.getSantiyeForID(this.santiye_id).subscribe(response => {
        this.santiyeDetay = response;
      });
    }
    this.initForm();
  }

  ngOnInit() {
    if (this.row?.pursantaj_id) {
      this.pursantajId = this.row.pursantaj_id;
    }
    this.getYapilanIs();
  }

  getYapilanIs() {
    this.yapilanIslerService.getYapilanIsPursantaj(this.santiye_id).subscribe((data) => {
      this.imalatCinsi = data;
      if (this.pursantajId) {
        const found = this.imalatCinsi.find(x => Number(x.id) === Number(this.pursantajId));
        if (found) {
          this.pursantajSec(found);
          this.yapilanIsForm.get('pursantaj_id').setValue(found.id);
          this.yapilanIsForm.get('date').setValue(new Date());
        }
      }
    });
  }

  initForm() {
    this.yapilanIsForm = this.fb.group({
      pursantaj_id: [null, Validators.required],
      total_pursantaj: [null],
      work_done_percentage: [null, BothEmptyValidator('tamamlanan_metraj')],
      toplam_metraj: [],
      tamamlanan_metraj: [null, BothEmptyValidator('work_done_percentage')],
      desc: [],
      applied_rate: [0],
      date: [new Date(), Validators.required],
    });

    this.yapilanIsForm.get('tamamlanan_metraj').valueChanges.subscribe(val => {
      this.calculateMetraj();
    });

    this.yapilanIsForm.get('work_done_percentage').valueChanges.subscribe(val => {
      this.pursantajHesapla();
    });

  }

  forceRefresh = 0;
  pursantajSec(event) {
    this.yapilanIsForm.reset();
    this.yapilanIslerList = [];
    this.pursantajId = undefined;
    if (event && event.id) {
      this.yapilanIslerList = [];
      this.spinner = true;
      this.pursantajId = event.id;
      if (event.metraj) event.metraj = Number(event.metraj);
      console.log('Seçilen İmalat ', event);
      this.yapilanIsForm.get('pursantaj_id').setValue(this.pursantajId);
      this.yapilanIsForm.get('total_pursantaj').setValue(event.pursantaj_rate);
      this.yapilanIsForm.get('toplam_metraj').setValue(event.metraj);
      this.yapilanIsForm.get('toplam_metraj').disable();
      this.forceRefresh += 1;

      if (!event.pursantaj_rate && !event.metraj) {
        this.editPursantaj(event, true);
      }

    }
  }

  yapilanIsEkle() {
    if (!this.yapilanIsForm.valid) {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      this.yapilanIsForm.markAllAsTouched();
      console.log('form errors ', this.yapilanIsForm);
      return;
    }

    const formData = removeAllNull(this.yapilanIsForm.value);
    formData['santiye_id'] = this.santiye_id;

    console.log('Yapılan İşler Form Data', formData);
    this.isLoading = true;
    this.yapilanIslerService.postYapilanIs(formData).subscribe(
      (data) => {
        this.isLoading = false;

        Swal.fire({
          ...responseKodlari.wantToAddPhoto,
        } as SweetAlertOptions).then(({ value }) => {
          if (value) {
            // Fotgraf ekeleme alanı
            console.log('Fotoğraf ekelenecek');
            // tslint:disable-next-line: max-line-length
            this.nbDialogService.open(YapilanIslerAddPhotoComponent,
              {
                context: {
                  contentId: data.id,
                  tip: NoteType.Liste,
                  modul: 'yapilanisler',
                },
              });
          }
        });

        const tmp = [data];
        this.yapilanIsForm.reset();
        this.spinner = false;
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
      },
    );

  }

  pursantajHesapla() {
    const pursantaj = Number(this.yapilanIsForm.get('total_pursantaj').value);
    const totalPursantaj = Number(this.yapilanIsForm.get('work_done_percentage').value);
    let hesap = (pursantaj * totalPursantaj) / 100;
    if (!hesap) hesap = 0;

    console.log('Applied Rate  :', hesap);
    this.yapilanIsForm.get('applied_rate').setValue(hesap);
    this.updateValidity();

  }

  calculateMetraj() {
    const formVal = this.yapilanIsForm.getRawValue();

    const toplam = formVal.toplam_metraj ? formVal.toplam_metraj : 0;
    const tamamlanan_metraj = formVal.tamamlanan_metraj ? formVal.tamamlanan_metraj : 0;

    // 1500 300 100 X = 100 * tamamlanan_metraj / toplam

    let calculated_percentage = 100 * tamamlanan_metraj / toplam;
    calculated_percentage = Number(calculated_percentage.toFixed(2));
    if (!(isFinite(calculated_percentage))) calculated_percentage = 0;

    console.log('work_done_percentage', calculated_percentage);

    this.yapilanIsForm.get('work_done_percentage').setValue(calculated_percentage);
    this.updateValidity();
  }

  updateValidity() {
    this.yapilanIsForm.controls.work_done_percentage.updateValueAndValidity({ emitEvent: false });
    this.yapilanIsForm.controls.tamamlanan_metraj.updateValueAndValidity({ emitEvent: false });
  }


  addTagPromise = (title) => {

    return this.yapilanIslerService.addDigerPursantaj({ title: title, santiye_id: this.santiye_id }).subscribe(resp => {
      // this.imalatCinsi = addOrUpdate(this.imalatCinsi, resp);
      // this.yapilanIsForm.get('pursantaj_id').setValue(resp.id);
      this.pursantajSec(resp);
    });
  }

  editPursantaj(data, isNew) {
    this.nbDialogService.open(
      EditPursantajModalComponent,
      {
        context: {
          data: data,
          santiye_id: this.santiye_id,
          pursantajList: this.imalatCinsi,
          isNew: isNew,
        },
        closeOnBackdropClick: false,
      },
    ).onClose.subscribe(val => {
      this.close(val);
    });
  }

  close(resp?) {
    this.ref.close(resp);
  }
}
