import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { MapResultModel } from '../models/location.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs';
import { switchMap, tap,map } from 'rxjs/operators';

declare var google: any;

@Injectable()
export class LocationService {
  private geocoder: any;

  constructor(private mapLoader: MapsAPILoader) { }

  private initGeocoder() {
    console.log('Init geocoder!');
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return fromPromise(this.mapLoader.load())
        .pipe(
          tap(() => this.initGeocoder()),
          map(() => true)
        );
    }
    return of(true);
  }
  result: MapResultModel[];
  // administrative_area_level_1
  geocodeAddress(location: string): Observable<any> {
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({ 'address': location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.result = results;
              let country = results[0].address_components.find(data => data.types[0] === 'country').long_name ?
                results[0].address_components.find(data => data.types[0] === 'country').long_name : '';
              let city = results[0].address_components.find(data => data.types[0] === 'locality') ?
                results[0].address_components.find(data => data.types[0] === 'locality').long_name : false;
              console.log('CITY 1', city);
              if (!city) {
                city = results[0].address_components.find(data => data.types[0] ===
                  'administrative_area_level_1') ?
                  results[0].address_components.find(data => data.types[0] ===
                    'administrative_area_level_1').long_name : false;
              }
              console.log('CITY 2', city);

              let Cityname = '';
              if (city) Cityname = city;
              else Cityname = '';

              console.log('Geocoding complete!', results);
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                city: Cityname,
                country: country,
              });
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({ lat: 39.9122168, lng: 32.8505287 });
            }
            observer.complete();
          });
        })
      })
    )
  }
  geocodeAddressByLocation(lat, lng): Observable<any> {
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({ 'location': { lat: lat, lng: lng } }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              this.result = results;
              let country = results[0].address_components.find(data => data.types[0] === 'country').long_name ?
                results[0].address_components.find(data => data.types[0] === 'country').long_name : '';
              let city = results[0].address_components.find(data => data.types[0] === 'locality') ?
                results[0].address_components.find(data => data.types[0] === 'locality').long_name : false;
              console.log('CITY 1', city);
              
              if (!city) {
                city = results[0].address_components.find(data => data.types[0] ===
                  'administrative_area_level_1') ?
                  results[0].address_components.find(data => data.types[0] ===
                    'administrative_area_level_1').long_name : false;
              }
              console.log('CITY 2', city);

              let Cityname = '';
              if (city) Cityname = city;
              else Cityname = '';
              console.log('Geocoding complete!', results);
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                city: Cityname,
                country: country,
                address: results[0].formatted_address,
              });
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })
      })
    )
  }
}