import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { birim, responseKodlari } from '../../../../../pages/constants';
import { MetrajService } from '../../../../../services/metraj.service';

@Component({
  selector: 'poz-ekle-modal',
  templateUrl: './poz-ekle-modal.component.html',
  styleUrls: ['./poz-ekle-modal.component.css'],
})
export class PozEkleModalComponent implements OnInit {
  birim = birim;
  form: FormGroup;
  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private metrajService: MetrajService,
    private ref: NbDialogRef<PozEkleModalComponent>,
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      new_poz_no: [null, Validators.required],
      title: [null, Validators.required],
      unit: [null, Validators.required],
      new_price: [null, Validators.required],
    });
  }

  pozEkle() {
    if (this.form.valid) {
      this.isLoading = true;
      this.metrajService.addNewPoz(this.form.value).subscribe(
        (resp) => {
          // console.log('POZ EKLE RESPONSE : ', resp);
          this.isLoading = false;
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(
            () => {
              this.ref.close(resp);
            },
          );
        },
        (err) => {
          console.warn('ERROR : ', err);
          this.isLoading = false;
        },
      );
    } else {
      this.form.markAllAsTouched();
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
    }
  }

  closed(value?) {
    this.ref.close(value);
  }
}
