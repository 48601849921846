import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format, tarih_format_long } from '../../../pages/constants';
import { b64toBlob, downLoadPdf } from '../../../pages/global-functions';
import { ApasFileService } from '../../../services/apasfile.service';
import { AdobePdfViewSDKClient } from './adobe-view-pdf-sdk.service';

@Component({
  selector: 'file-previewer',
  templateUrl: './file-previewer.component.html',
  styleUrls: ['./file-previewer.component.scss'],
  providers: [AdobePdfViewSDKClient, ApasFileService],
})
export class FilePreviewerComponent implements OnInit {
  @Input('url') url: string;
  @Input('module') module: string;
  @Input('fieldName') fieldName: string;
  item;
  isDownloading: boolean = false;
  docOrImage: string;
  tarih_format = tarih_format;
  tarih_format_long = tarih_format_long;
  docFiles = ['txt', 'doc', 'docx', 'xls', 'xlsx'];
  imageFiles = ['jpeg', 'jpg', 'png', 'gif'];
  isLoading: boolean = false;
  previewUrl: string;
  // fileSave = FileSaverApas.prototype;
  constructor(
    private injector: Injector,
    public ref: NbDialogRef<FilePreviewerComponent>,
  ) { }


  protected get cd(): ChangeDetectorRef { return this.injector.get(ChangeDetectorRef); }

  protected get viewSDKClient(): AdobePdfViewSDKClient { return this.injector.get(AdobePdfViewSDKClient); }

  protected get apasFileService(): ApasFileService { return this.injector.get(ApasFileService); }


  ngOnInit(): void {
    if (this.item.file && !this.item._file) this.item._file = this.item.file;
    this.setDataFormat();
  }

  urlSetted: boolean = true;
  pdfContent: any;
  @ViewChild('pdfview') pdfview: ElementRef;

  setDataFormat() {
    console.log('ITem',this.item);
    if (this.docFiles.includes(this.item?.extension)) {
      this.docOrImage = 'doc';
      this.previewUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.item.file_url}`;
      this.cd.detectChanges();
      this.cd.reattach();
    } else if (this.item?.extension === 'pdf') {
      this.docOrImage = 'pdf';
      this.showData();

      this.pdfPriview(this.item._file);
    } else if (this.imageFiles.includes(this.item?.extension)) {
      this.docOrImage = 'image';
    }
    setTimeout(() => {
      this.cd.detectChanges();
      this.cd.reattach();
    }, 350);
  }

  showData() {

    setTimeout(() => {
      this.cd.detectChanges();
    }, 550);
  }

  pdfPriview(_file) {
    const cblob = b64toBlob(_file);
    this.onFileUpload(cblob);
  }


  onFileUpload(cblob: Blob) {
    this.viewSDKClient.ready().then(() => {
      const reader = new FileReader();
      reader.onloadend = e => {
        const filePromise = Promise.resolve(e.target.result);
        /* Helper function to render the file using PDF Embed API. */
        this.viewSDKClient.previewFileUsingFilePromise('pdf-div', filePromise, 'PDF');
      };
      reader.readAsArrayBuffer(cblob);
    });
  }
  close() {
    this.ref.close();
  }

  indir() {
    if (!this.item?.download_url) {
      const dosyaAdi = this.item?.title ? this.item?.title : 'Dosya';
      const formatli = `${dosyaAdi}.${this.item.extension}`;
      downLoadPdf(this.item._file, formatli);
    } else {
      
      this.apasFileService.downloadFile(this.item?.download_url).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.item['percentDone'] = 0;

              this.item['isDownloading'] = true;
              break;
            case HttpEventType.DownloadProgress:
              this.item['percentDone'] = Math.round(
                (100 * event.loaded) / event.total,
              );

              break;
            case HttpEventType.Response:
              const fileKey = 'zip';
              this.item[fileKey] = event.body;
              this.item['isDownloading'] = false;
              this.item[fileKey] = event.body;
              const fileName = this.item?.title ? `${this.item.title}.zip` : 'dosya.zip';
              this.nonB64downLoadFile(event.body, 'application/zip');
              break;
          }
        },
        (err: HttpErrorResponse) => {
          
          if (err.error instanceof Error) {
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          }
        },
      );
    }
    // this.fileSave.onSave(this.item._file, formatli);
  }


  nonB64downLoadFile(data: any, type: string, fileName?: string) {
    const blob = new Blob([data], { type: type });

    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName ? fileName : 'dosya.zip';
    document.body.appendChild(downloadLink);
  }
}
