import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GorevModel } from '../models/gorev_model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class GorevService {

    private readonly API_URL = `${environment.backendurl}api/gorev/`;
    constructor(private httpClient: HttpClient,
    ) { }

    gorevList(filter: any) {
        const url = `${this.API_URL}list/`;
        return this.httpClient.post<any[]>(url, filter);
    }

    gorevDetay(instanceId: number): Observable<any> {
        const url: string = `${this.API_URL}detail/${instanceId}/`;
        return this.httpClient.get<any>(url);
    }

    addItem(data) {
        const url: string = `${this.API_URL}add/`;
        return this.httpClient.post(url, data);
    }

    deleteItem(instanceId: number): Observable<any> {

        const url = `${this.API_URL}delete/${instanceId}/`;
        return this.httpClient.delete<any>(url);
    }

    patchGorev(instanceId: number, context: any) {
        const url: string = `${this.API_URL}update/${instanceId}/`;
        return this.httpClient.patch<GorevModel>(url, context);
    }
}
