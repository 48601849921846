import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import { tarih_format } from '../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, exportExcelListeWithHeaders, removeAllNull } from '../../../pages/global-functions';
import { PuantajService } from '../../../services/puantaj.service';

@Component({
  selector: 'ngx-puantaj-person',
  templateUrl: './puantaj-person.component.html',
  styleUrls: ['./puantaj-person.component.scss'],
  providers: [PuantajService],
})
export class PuantajPersonComponent implements OnInit {
  organizationId: number;
  employee: any;

  rows: any;
  form: FormGroup;
  detayForm: FormGroup;
  dateType: FormControl = new FormControl('tumu');

  tarih_format = tarih_format;
  detay: boolean = false;
  constructor(
    @Optional() private ref: NbDialogRef<PuantajPersonComponent>,
    private puantajService: PuantajService,
    private fb: FormBuilder,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.form.get('apas_id').patchValue(this.employee?.apas_id);
    this.form.get('organization_id').patchValue(this.organizationId);
    this.getPuantaj();
    console.log('employee : ', this.employee);
    this.dateTypeListener();
  }

  initForm() {
    this.form = this.fb.group({
      apas_id: [this.employee?.apasId, Validators.required],
      organization_id: [this.organizationId],
      start_date: [null],
      end_date: [null],
      santiye_active: [true],
      santiye_id: [],
    });

    this.form.valueChanges.subscribe(val => {
      setTimeout(() => {
        this.rows = [];
        this.getPuantaj();
      }, 150);
    });
  }

  dateTypeListener() {
    this.dateType.valueChanges.subscribe(data => {

      const now = moment();
      const monday = now.clone().startOf('week');
      const sunday = now.clone().endOf('week');
      const firstDayOfMonth = now.clone().startOf('month');
      const lastDayOfMonth = now.clone().endOf('month');
      switch (data) {
        case 'tumu': {
          this.form.get('start_date').setValue(null);
          this.form.get('end_date').setValue(null);
          break;
        }
        case 'bugun': {
          this.form.get('start_date').setValue(now.toDate());
          this.form.get('end_date').setValue(now.toDate());
          break;
        }
        case 'buhafta': {
          this.form.get('start_date').setValue(monday.toDate());
          this.form.get('end_date').setValue(sunday.toDate());
          break;
        }
        case 'buay': {
          this.form.get('start_date').setValue(firstDayOfMonth.toDate());
          this.form.get('end_date').setValue(lastDayOfMonth.toDate());
          break;
        }
        default: {
          this.form.get('start_date').setValue(null);
          this.form.get('end_date').setValue(null);
          break;
        }
      }
    });
  }
  detayRows = [];
  isLoading: boolean = false;
  getPuantaj() {
    const formVal = removeAllNull(this.form.value);
    this.isLoading = true;
    this.puantajService.getEmployeePuantaj(formVal).subscribe(resp => {
      console.log('Resp : ', resp);
      resp.results.forEach(elm => {
        elm = this.clearData(elm);
      });
      this.detay = resp.detay;
      if (this.detay) {
        this.detayRows = resp.results;
      } else {
        this.rows = resp.results;
      }
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  clearData(elm) {
    elm.max_date = convertDateWithTZ(elm, 'max_date');
    elm.min_date = convertDateWithTZ(elm, 'min_date');
    elm.date = convertDateWithTZ(elm, 'date');
  }

  close() {
    this.ref.close();
  }


  collepseOrderBySet(row) {
    row.collepseOrderBy = !row.collepseOrderBy;
    if (row.taseron_list) {
      if (row.collepseOrderBy) {
        row.taseron_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.taseron_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    } else {
      if (row.collepseOrderBy) {
        row.santiye_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.santiye_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    }
  }
  detayItem;
  puantajDetay(row) {
    console.log('detay : ', this.detay);
    this.form.get('santiye_id').setValue(row.santiye_id);
  }

  hideDetail() {
    this.form.get('santiye_id').setValue(null);
  }

  listeExcel() {



    const formVal = removeAllNull(this.form.value);
    formVal.page = 0;
    formVal.full = true;
    let currentRows = [...this.rows];

    // this.rows.forEach(element => {

    //   currentRows = addOrUpdate(currentRows, element);
    // });
    const basliklar = [
      { header: 'Tarih', key: 'max_date', width: 20 },
      { header: 'Şantiye', key: 'santiye_name', width: 50 },
      { header: 'Toplam Puatnaj', key: 'total_count', width: 30 },
      // { header: 'Para Birimi ', key: 'currency', width: 30 },

    ];
    console.log('Current Rows', currentRows);

    exportExcelListeWithHeaders(basliklar, currentRows, 
      this.employee?.name + ' Puantaj Bilgileri', this.employee?.name + ' Puantaj Bilgileri');
  }
}
