import {
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    Optional,
    Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[dateOnly]',
})
export class DateOnlyDirective implements DoCheck, AfterViewInit {
    // belli bir html elementiindeki değişimi yakalar
    observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            this.onInputChange();
            // // console.log(mutation);
        });
    });
    kapatButton: HTMLElement;
    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Optional() private control: NgControl,
    ) {
        this.kapatButton = this.renderer.createElement('span');
        this.kapatButton.innerHTML = 'x';
        this.kapatButton.onclick = () => {
            this.control.control.setValue(null);
        };

        this.kapatButton.classList.add('dateClear');
        this.kapatButton.classList.add('d-none');
        if (el) {
            this.renderer.appendChild(el.nativeElement.parentElement, this.kapatButton);
            this.observer.observe(this.el.nativeElement, {
                attributes: true,
                childList: true,
                characterData: true,
            });
        }
    }
    inputEl: HTMLInputElement;
    addButton: boolean = false;
    ngAfterViewInit(): void {
        this.inputEl = this.el.nativeElement;
        this.inputEl.type = 'button';

    }
    onInputChange() {

        if (this.control.value) {
            this.kapatButton.classList.remove('d-none');
        } else {
            this.kapatButton.classList.add('d-none');
        }

    }
    ngDoCheck(): void { }
}
