import { Component, OnInit } from '@angular/core';
import { YapilanIslerEkleComponent } from '../yapilan-isler-ekle/yapilan-isler-ekle.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SantiyeService } from '../../../../services/santiye.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { addOrUpdate, removeAllNull } from '../../../../pages/global-functions';
import { tarih_format } from '../../../../pages/constants';
import { NbDialogService } from '@nebular/theme';
import { YapilanIslerGrafikComponent } from '../yapilan-isler-grafik/yapilan-isler-grafik.component';

@Component({
  selector: 'yapilan-isler',
  templateUrl: './yapilan-isler.component.html',
  styleUrls: ['./yapilan-isler.component.scss'],
  providers: [SantiyeService, YapilanIslerService],
})
export class YapilanIslerComponent implements OnInit {
  santiyeId: number;
  santiyeDetay: any;
  form: FormGroup;
  isLoading = false;
  rows = [];
  durdur = false;
  tarih_format = tarih_format;
  constructor(
    private router: Router,
    private _activatedRouter: ActivatedRoute,
    private santiyeService: SantiyeService,
    private fb: FormBuilder,
    private yapilanIsService: YapilanIslerService,
    private _nbDiaologService: NbDialogService,
  ) {
    this._activatedRouter.params.subscribe(data => {
      this.santiyeId = data.santiye_id;

      if (this.santiyeId) {

        this.santiyeService.getSantiyeForID(this.santiyeId).subscribe(response => {
          this.santiyeDetay = response;
        });
      } else {
        this.router.navigate(['pages/dashboard']);
      }

    });

  }

  ngOnInit() {
    this.initForm();
    this.getYapilanIsList();
  }


  initForm() {
    this.form = this.fb.group({
      santiye_id: [this.santiyeId],
      search: [], 
      end_date: [],
      start_date: [],
    });
    this.form.get('search').valueChanges.subscribe(() => {
      this.getFilteredData();
    });
  }

  getYapilanIsList() {
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.yapilanIsService.getAggreagetdYapilanIslerList(formVal).subscribe(data => {
      data.forEach(elm => {
        this.rows = addOrUpdate(this.rows, elm, 'pursantaj_id');
      },
      );
      console.log("Yapılan İşkler Data:", data);
      this.rows = [...this.rows];
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      this.durdur = true;
    });

  }

  getFilteredData() {
    this.rows = [];
    this.durdur = false;
    this.getYapilanIsList();
  }



  yapilanIsEkle(event?) {
    this._nbDiaologService.open(
      YapilanIslerEkleComponent, { context: { santiye_id: this.santiyeId, row: event } },
    ).onClose.subscribe((val) => {
      this.getFilteredData();
    });
  }


  grafikModal(item) {
    const data = { santiye_id: this.santiyeId, pursantaj_id: item.pursantaj_id };
    this._nbDiaologService.open(YapilanIslerGrafikComponent, {
      context: {
        data: data,
      },
    }).onClose.subscribe(() => {
      this.getFilteredData();
    });

  }

  geriGit() {
    this.router.navigateByUrl('pages/dashboard');
  }

}
