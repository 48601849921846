import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ViewEncapsulation } from '@angular/core';
import { HatirlatmaService } from '../../../../services/hatirlatma.service';
import { ApasInfiniteScrollComponent } from '../../../Apas-Modules/infinite-scroll/infinite-scroll.component';
import { tarih_format } from '../../../../pages/constants';
import { NbDialogService } from '@nebular/theme';
import { HatirlatmaDetayComponent } from '../../hatirlatma-detay/hatirlatma-detay.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'hatirlatma-infinit',
  templateUrl: './hatirlatma-infinit.component.html',
  styleUrls: ['./hatirlatma-infinit.component.css'],
  providers: [HatirlatmaService],
  encapsulation: ViewEncapsulation.None,
})
export class HatirlatmaInfinitComponent implements OnInit, OnDestroy {
  @ViewChildren(ApasInfiniteScrollComponent) apasInfiniteScrollComponent!: QueryList<ApasInfiniteScrollComponent>;
  buyukGetir;
  tarih_format = tarih_format;
  hatirlatmalar: any[] = [];
  pageRowCount: number = 20;
  private hatirlatmaHandler$: Subscription;

  constructor(
    private nbDialog: NbDialogService,
    private hatirlatmaService: HatirlatmaService,
    ) {
    this.getHatirlatmalar();
  }
  ngOnDestroy(): void {
    this.hatirlatmaHandler$.unsubscribe();
  }

  ngOnInit() {
  }
  onScrool(event: ApasInfiniteScrollComponent) {
    let time;
    if (this.hatirlatmalar.length > 0) {
      time = this.hatirlatmalar[this.hatirlatmalar.length - 1].reminder_time;
    }
    this.hatirlatmaService.getHatirlatma(time).subscribe(data => {
      this.hatirlatmalar = [...this.hatirlatmalar, ...data];
      if (data.length < this.pageRowCount) {
        event.isLoaiding = false;
      }
    }, (err: HttpErrorResponse) => {
      event.isLoaiding = false;
    });
  }
  hatirlatmaDetay(_id) {
    this.nbDialog.open(HatirlatmaDetayComponent, {
      context: {
        hatirlatmaID: _id,
      },
    });
  }
  getHatirlatmalar() {
    this.hatirlatmalar = [];
    this.hatirlatmaService.getHatirlatma().subscribe(data => {
      this.hatirlatmalar = [...this.hatirlatmalar, ...data];
    });
  }

}
