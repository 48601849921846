import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { removeAllNull, unsubscribers } from '../../../pages/global-functions';

@Component({
  selector: 'base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss'],
})
export class BaseListComponent implements OnInit, OnDestroy, AfterViewInit {

  loadDataAction = null;
  loadDataConnector = null;

  resetAction = null;

  isLoadingConnector = null;
  endDataConnector = null;

  isLoadedAction = null;
  isEndActionAction = null;

  deleteDataAction = null;
  deleteDataConnector = null;


  detailComponent = null;
  addNewComponent = null;
  selectAction = null;


  rows: any = [];
  isLoading = false;
  isEnd = false;
  form: FormGroup;

  get filterFormGroup(): FormGroup { return this.form.get('filter') as FormGroup; }

  constructor(
    public injector: Injector,
    public store: Store<{}>,
    public fb: FormBuilder,
    public nbDilogService: NbDialogService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (!this.loadDataAction && !this.loadDataConnector) {
      throw new Error('Load Data Action And Connector Must Be Provided');
    }

    this.getFilteredData();
    this.subscriptions();
  }

  ngAfterViewInit(): void {
  }

  initForm() {
    this.form = this.fb.group({
      page: [1],
      count: [20],
      search: [],
      filter: this.fb.group({

      }),
    });
    this.form.get('search').valueChanges.subscribe(val => {
      this.isLoading = true;
      setTimeout(() => {
        this.getFilteredData();
      }, 1500);
    });
  }

  getFilteredData() {
    this.isLoading = false;
    this.form.get('page').setValue(1);
    this.rows = [];
    this.store.dispatch(this.resetAction());
    this.initDataLoadings();
  }

  async goToNextPage() {
    if (!this.isEnd && !this.isLoading) {
      await this.form.get('page').setValue(this.form.get('page').value + 1);
      this.initDataLoadings();
    }
  }

  initDataLoadings() {
    this.store.dispatch(this.loadDataAction(removeAllNull(this.form.value)));
  }



  subscriptionList = [];
  subscriptions() {
    this.subscriptionList[2] = this.store.select(this.loadDataConnector).subscribe((data: any[]) => {
      this.rows = data;
    });

    this.subscriptionList[0] = this.store.select(this.isLoadingConnector).subscribe((loading: boolean) => {
      this.isLoading = loading;
    });

    if (this.endDataConnector) {
      this.subscriptionList[1] = this.store.select(this.endDataConnector).subscribe((end: boolean) => {
        this.isEnd = end;
      });
    }

  }

  ngOnDestroy(): void {
    unsubscribers(this.subscriptionList);
  }

  detail(selectedId) {
    this.store.dispatch(this.selectAction({ selectedId: selectedId }));
    this.nbDilogService.open(this.detailComponent);
  }

  addNew() {
    this.nbDilogService.open(this.addNewComponent);
  }

  editData(selectedId) {
    this.store.dispatch(this.selectAction({ selectedId: selectedId }));
    this.nbDilogService.open(this.addNewComponent, { context: { edit: true } });
  }



}
