import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { Workbook } from 'exceljs';
import * as moment from 'moment-timezone';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SantiyeModel } from '../../../models/santiye_model';
import { TaseronModel } from '../../../models/taseron.model';
import { responseKodlari, tarih_format } from '../../../pages/constants';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { PuantajService } from '../../../services/puantaj.service';
import { TaseronService } from '../../../services/taseron.service';
import { PuantajEkleModalComponent } from '../puantaj-ekle/Puantaj-ekle-modal/puantaj-ekle-modal.component';
import { PuantajGunListComponent } from './puantaj-gun-list/puantaj-gun-list.component';
import * as fs from 'file-saver';
import { TopluPuantajEkleModalComponent } from '../puantaj-ekle/toplu-puantaj-ekle-modal/toplu-puantaj-ekle-modal.component';

@Component({
  selector: 'ngx-puantaj-main',
  templateUrl: './puantaj-main.component.html',
  styleUrls: ['./puantaj-main.component.scss'],
  providers: [PuantajService, TaseronService],
})
export class PuantajMainComponent implements OnInit {
  sagLayoutHide = false;
  taseronlar: TaseronModel[];
  santiyeList: SantiyeModel[];
  form: FormGroup;
  isLoading: boolean = false;
  rows;
  puantajGrupla = new FormControl('santiye');
  listeTipi = 'santiye';
  tarih_format = tarih_format;
  dateType: FormControl = new FormControl('tumu');
  constructor(private puantajService: PuantajService,
    private taseronService: TaseronService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getTaseronList();
    this.getSantiyeList();

    this.puantajGrupla.valueChanges.subscribe(data => {
      this.listeTipi = data;
    });

    this.dateTypeListener();
    this.getFilteredData();

  }

  initForm() {
    this.form = this.fb.group({
      taseron_id: [],
      santiye_id: [],
      end_date: [],
      start_date: [],
      santiye_active: [true],
      search: [],
    });

    this.form.controls.search.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });

    this.form.get('santiye_active').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.get('start_date').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.get('end_date').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.dateType.valueChanges.subscribe(val => {
      this.getFilteredData();
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    // this.dateType.setValue('buay');

  }


  dateTypeListener() {
    this.dateType.valueChanges.subscribe(data => {

      const now = moment();
      const monday = now.clone().startOf('week');
      const sunday = now.clone().endOf('week');
      const firstDayOfMonth = now.clone().startOf('month');
      const lastDayOfMonth = now.clone().endOf('month');
      switch (data) {
        case 'tumu': {
          this.form.get('start_date').setValue(null);
          this.form.get('end_date').setValue(null);
          break;
        }

        case 'bugun': {
          this.form.get('start_date').setValue(now.toDate());
          this.form.get('end_date').setValue(now.toDate());
          break;
        }

        case 'buhafta': {
          this.form.get('start_date').setValue(monday.toDate());
          this.form.get('end_date').setValue(sunday.toDate());
          break;
        }

        case 'buay': {
          this.form.get('start_date').setValue(firstDayOfMonth.toDate());
          this.form.get('end_date').setValue(lastDayOfMonth.toDate());
          break;
        }


        default: {
          this.form.get('start_date').setValue(null);
          this.form.get('end_date').setValue(null);
          break;
        }
      }
    });
  }

  getFilteredData() {
    this.rows = null;
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.puantajService.getNewList(formVal).subscribe(resp => {
      this.isLoading = false;
      this.rows = resp;
      this.rows = this.getDateConvert(this.rows);

    }, (err) => {
      this.isLoading = false;
    });
  }

  getDateConvert(rowss) {
    rowss.taseron.forEach(element => {
      element['min_date'] = convertDateWithTZ(element, 'min_date');
      element['max_date'] = convertDateWithTZ(element, 'max_date');
      element['collepseOrderBy'] = false;
      element.santiye_list.forEach(elm => {
        elm['max_stamp'] = convertDateWithTZ(elm, 'max_stamp');
      });
    });
    rowss.santiye.forEach(element => {
      element['min_date'] = convertDateWithTZ(element, 'min_date');
      element['max_date'] = convertDateWithTZ(element, 'max_date');
      element['collepseOrderBy'] = false;
      element.taseron_list.forEach(elm => {
        elm['max_stamp'] = convertDateWithTZ(elm, 'max_stamp');

      });
    });
    return rowss;
  }


  getSantiyeList() {
    this.puantajService.getSantiyeListForPuantaj().subscribe(data => {
      this.santiyeList = data;
    });
  }

  getTaseronList() {
    this.taseronService.getTaseronlar().subscribe(data => {
      this.taseronlar = data;
    });
  }

  yeniPuantajEKle(item?) {
    const context = {
      'taseron_id': item.taseron_id,
      'santiye_id': item.santiye_id,
      'disableControl': false,
    };

    this.nbDialogService.open(PuantajEkleModalComponent, { context, closeOnBackdropClick: false })
      .onClose.subscribe(() => {
        this.getFilteredData();
      });

  }


  topluPuantajEkele() {
    this.nbDialogService.open(TopluPuantajEkleModalComponent).onClose.subscribe(() => {
      this.getFilteredData();
    });
  }

  puantajDetay(row) {
    this.nbDialogService.open(PuantajGunListComponent, { context: { data: row } });
  }


  startDate;
  endDate;
  isExcelDownloading = false;
  workbook: Workbook;
  exportExcell() {
    if (!this.form.get('santiye_id').value || !this.form.get('start_date').value || !this.form.get('end_date').value) {
      Swal.fire(responseKodlari.santiyeVeTarihSec as SweetAlertOptions);
      this.sagLayoutHide = true;
      return;
    }


    this.isExcelDownloading = true;
    this.workbook = new Workbook();
    const newData = {
      puantajData: [],
    };


    const puantaj = this.getPuantajData();

    const puantajPromise = Promise.resolve(puantaj).catch((err) =>
      console.log('PUANTAJ ERR : ', err),
    );

    const completeResult = Promise.all([
      puantajPromise,
    ])
      .then(async (values) => {
        console.log('VALUES : ', values);
        const puantajData = values[0];
        if (puantajData)
          await this.manipulatePuantajData(puantajData).then(
            (data) => (newData.puantajData = data),
          );
        return newData;
      })
      .catch((err) => {
        console.log('ERR : ', err);
        return newData;
      })
      .then(async (data) => {
        if (data['puantajData'])
          await this.exportPuantajExcel(data['puantajData']);
      })
      .then(() => {
        this.saveAsExcel();
      });
  }



  getPuantajData = async () => {

    const filter = removeAllNull(this.form.value);

    if (filter.start_date) filter.start_date = Number(filter.start_date);
    if (filter.end_date) filter.end_date = Number(filter.end_date);
    const newData = [];
    console.log('FİLTRE : ', filter);
    return await this.puantajService.getExcelData(filter).toPromise();
  }


  manipulatePuantajData = async (resp) => {
    const newData = [];
    let gmt = 'Europe/Istanbul';
    if (resp[0].gmt) gmt = resp[0].gmt;
    resp.forEach((element) => {
      element.tarih = moment(element.date, 'X');
      console.log(element.tarih.format('DD/MM/YYYY : hh-mm'));

      const finded = newData.find(
        (x) => x.taseron_name === element.taseron_name,
      );
      if (finded) {
        finded.puantajs.push(element);
      } else {
        const newNesne = {
          taseron_name: element.taseron_name,
          puantajs: [],
          days: [],
        };
        newNesne.puantajs.push(element);
        newData.push(newNesne);
      }
    });
    newData.forEach((santiye) => {
      santiye.puantajs.forEach((elm) => {
        const finded = santiye.days.find(
          (x) =>
            x.tarih.format('DD/MM/YYYY') === elm.tarih.format('DD/MM/YYYY'),
        );
        if (finded) {
          const jobFind = finded.jobs.find((x) => x.job_name === elm.job_name);
          if (jobFind) {
            jobFind.puantajList.push(elm);
          } else {
            const newJob = {
              job_name: elm.job_name,
              puantajList: [elm],
            };
            finded.jobs.push(newJob);
          }
        } else {
          const newDate = {
            tarih: elm.tarih,
            jobs: [
              {
                job_name: elm.job_name,
                puantajList: [elm],
              },
            ],
          };
          santiye.days.push(newDate);
        }
      });
    });
    newData.forEach((santiye) => {
      const sorted = santiye.days.sort(
        (n1, n2) => n1.tarih.format('YYYYYMMDD') - n2.tarih.format('YYYYYMMDD'),
      );
      santiye.days = sorted;
      console.log('SORTED : ', sorted);
      santiye.days.forEach((element) => {
        element.tarih = element.tarih.format('DD/MM/YYYY');
      });
    });
    return newData;
  }







  async exportPuantajExcel(data) {
    /**
     * Başlıklar Liste Şeklinde [{title: Ana Başlık, data_name: 'created_time'}] şeklinde tanımlanır.
     * Listeleme Başlıkların Veriliş şekline Göre Yapılır.
     */

    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 12,
      bold: true,
    };

    data.forEach((element) => {
      const sheet = this.workbook.addWorksheet(
        `${element.taseron_name} Puantaj`,
      );
      console.log('Excell Data:', data);
      // Önce Başlıklar Satırı Oluşturulur.
      const basliklar = [
        { header: 'Tarih', key: 'tarih', width: 20 },
        { header: 'Meslek', key: 'job_name', width: 30 },
        { header: 'Tam Gün', key: 'job_name', width: 30 },
        { header: 'Yarım Gün', key: 'job_name', width: 30 },

        { header: 'Kişi', key: 'employee', width: 40 },
        { header: 'Kimlik No', key: 'kimlik_no', width: 30 },
        { header: 'Çalışma Durumu', key: 'yarim_gun', width: 30 },
        { header: 'Mesai', key: 'overtime', width: 10 },
      ];

      const taseronRow = sheet.getRow(1);
      sheet.mergeCells('A1:H1');
      const taseronCell = sheet.getCell('A1');
      taseronCell.font = font;
      taseronCell.value = element.taseron_name + ' Puantaj';
      taseronCell.alignment = { vertical: 'middle', horizontal: 'center' };
      taseronRow.height = 20;


      const baslikRow = sheet.getRow(2);
      baslikRow.height = 20;

      basliklar.forEach((elm, i) => {
        const colum = sheet.getColumn(i + 1);
        colum.width = elm.width;
        const firtCell = baslikRow.getCell(i + 1);
        firtCell.value = elm.header;
        firtCell.font = font;
        firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      sheet.autoFilter = {
        from: {
          row: 2,
          column: 1,
        },
        to: {
          row: 2,
          column: basliklar.length,
        },
      };

      let rowIndex = 3;
      let jobIndex = 3;
      let curr = 3;
      let toplam_tam_gun = 0;
      let toplam_yarim_gun = 0;
      element.days.forEach((day, i) => {
        let alan = rowIndex;
        let tam_gun = 0;
        let yarim_gun = 0;
        day.jobs.forEach((per) => {
          alan += per.puantajList.length;
          const jobNameArea = `B${jobIndex}:B${jobIndex + per.puantajList.length - 1
            }`;
          sheet.mergeCells(jobNameArea);
          jobIndex += per.puantajList.length;
          const jobAreaCell = sheet.getCell(`B${jobIndex - 1}`);
          jobAreaCell.value = per.job_name;
          jobAreaCell.alignment = { vertical: 'middle', horizontal: 'center' };
          per.puantajList.forEach((kisi) => {
            sheet.getCell(`E${curr}`).value = kisi.employee;
            sheet.getCell(`F${curr}`).value = kisi.employee_tckimlik;
            sheet.getCell(`G${curr}`).value = kisi.yarim_gun
              ? 'Yarım Gün'
              : 'Tam Gün';
            sheet.getCell(`H${curr}`).value = kisi.overtime;
            curr += 1;
            if (kisi.yarim_gun) {
              yarim_gun += 1;
              toplam_yarim_gun += 1;
            } else {
              tam_gun += 1;
              toplam_tam_gun += 1;
            }
          });
        });

        const mergeText = `A${rowIndex}:A${alan - 1}`;
        sheet.mergeCells(mergeText);
        const tarihCell = sheet.getCell(`A${alan - 1}`);
        tarihCell.value = day.tarih;
        tarihCell.alignment = { vertical: 'middle', horizontal: 'center' };

        const tamMargeText = `C${rowIndex}:C${alan - 1}`;
        sheet.mergeCells(tamMargeText);
        const tamCell = sheet.getCell(`C${alan - 1}`);
        tamCell.value = tam_gun;
        tamCell.alignment = { vertical: 'middle', horizontal: 'center' };

        const yarimMargeText = `D${rowIndex}:D${alan - 1}`;
        sheet.mergeCells(yarimMargeText);
        const yarimCell = sheet.getCell(`D${alan - 1}`);
        yarimCell.value = yarim_gun;
        yarimCell.alignment = { vertical: 'middle', horizontal: 'center' };
        rowIndex = alan;
      });
    });
  }

  saveAsExcel() {
    this.workbook.xlsx
      .writeBuffer()
      .then((exp) => {

        let dosyaAdi = ` Puantaj Rapor`;
        const blob = new Blob([exp], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        dosyaAdi = dosyaAdi.replace(
          /[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
          '',
        );

        const filename = dosyaAdi + '.xlsx';
        fs.saveAs(blob, filename);
        Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
        this.isExcelDownloading = false;
      })
      .catch((err) => {
        console.log('BAŞARISIZ', err);
        this.isExcelDownloading = false;
      });
  }


  orderStatuses = {
    min_date: true,
    max_date: null,
  };

  orderBy(orderKey, status) {

    Object.keys(this.orderStatuses).forEach(key => {
      this.orderStatuses[key] = null;
    });

    this.orderStatuses[orderKey] = !status;
    this.orderStatuses = { ...this.orderStatuses };
    this.orderBySet(status, orderKey);
    console.log('ORDER STATUS : ', status, this.orderStatuses);
    if (status) {
      orderKey = `-${orderKey}`;
    }

  }
  orderBySet(status, orderKey) {

    if (status) {
      console.log('Order Key True', orderKey, status);
      this.rows.santiye.sort((a, b) => b[orderKey] - a[orderKey]);
      this.rows.taseron.sort((a, b) => b[orderKey] - a[orderKey]);

    } else {
      console.log('Order Key Falseasd', orderKey, status);
      this.rows.santiye.sort((a, b) => a[orderKey] - b[orderKey]);
      this.rows.taseron.sort((a, b) => a[orderKey] - b[orderKey]);

    }

  }


  collepseOrderBySet(row) {
    row.collepseOrderBy = !row.collepseOrderBy;
    if (row.taseron_list) {
      if (row.collepseOrderBy) {
        row.taseron_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.taseron_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    } else {
      if (row.collepseOrderBy) {
        row.santiye_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.santiye_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    }
  }


}

