import { Injectable, Component, NgModule } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';

import * as duyuruActions from './duyuru.actions';
import { DuyuruModel } from '../../models/duyuru.model';
import { DuyuruService } from '../../services/duyuru.service';


@Injectable()
export class DuyuruEffects {

    readedBildirim$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(duyuruActions.setDuyuruReaded),
            mergeMap(({ id }) => this.duyuruService.setReaded(id)
                .pipe(
                    map((duyuru: DuyuruModel) => {
                        return duyuruActions.setDuyuruReadedSuccess({
                            duyuru: { id: id, changes: { isReaded: true } },
                        });
                    }),
                    catchError(() => of(duyuruActions.setDuyuruReadedFail({ err: 'Bildirim yüklenemedi.' }))),
                )),
        );
    });

    loadDuyurular$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(duyuruActions.loadDuyuru),
            mergeMap(({ page }) => this.duyuruService.getDuyuru(page)
                .pipe(
                    map((duyurular: DuyuruModel[]) => {
                        const isEnd: boolean = duyurular.length < 20;
                        return (duyuruActions.loadDuyuruSuccess({ duyuruItems: duyurular, isEnd: isEnd })
                        );
                    }),
                    catchError(() => of(duyuruActions.loadDuyuruFail('Duyuru yüklenemedi.'))),
                )),
        );
    });


    constructor(
        private actions$: Actions,
        private duyuruService: DuyuruService,
    ) {

    }

}
