import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cariHareketTypes, responseKodlari, taksitHareketTypes } from '../../../../../../constants';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { removeAllNull } from '../../../../../../global-functions';
import { ApasEncryptePipe } from '../../../../../../../directives/apas-encrypte-pipe';
import { MuhasebeService } from '../../../../../muhasebe-service/muhasebe.service';

@Component({
  selector: 'apx-taksit-ekle',
  templateUrl: './taksit-ekle.component.html',
  styleUrls: ['./taksit-ekle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MuhasebeService],
})
export class TaksitEkleComponent implements OnInit {
  data;
  form: FormGroup;
  odemeTuru = taksitHareketTypes();
  isLoading: boolean = false;

  constructor(
    private ref: NbDialogRef<TaksitEkleComponent>,
    private fb: FormBuilder,
    private muhasebeService: MuhasebeService,
    private encryptePipe: ApasEncryptePipe,
  ) {
  }


  ngOnInit(): void {
    console.log('Taksit Ekle Data', this.data);
    this.initForm();
    this.form.get('odeme_plan').setValue(this.data.id);
    this.form.get('currency').setValue(this.data.currency);
    this.form.get('sender_cari_id').setValue(this.data.cari_id);
  }

  initForm() {
    this.form = this.fb.group({
      odeme_plan: [this.data?.id, Validators.required],
      sender_cari_id: [null, Validators.required],
      must_pay_price: [null, Validators.required],
      currency: [null, Validators.required],
      must_pay_date: [new Date(), Validators.required],
      total_price: [0],
      note: [],
      hareket_type: [cariHareketTypes.odemeTaksit, Validators.required],
    });
  }
  close(refresh: boolean = false) {
    this.ref.close(refresh);
  }

  async postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('Form not Valid : ', this.form);
      return;
    }
    const swalMessage: SweetAlertOptions = {
      icon: 'question',
      title: 'Onaylıyor Musunuz?',
      html: `Yaptığınız bu işlem, ilgili ödeme planının borç miktarını ve ilgili carinin toplam borç miktarını değiştirecektir.
      </br><b>Onaylıyor Musunuz?</b>`,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'Evet',
    };
    Swal.fire(swalMessage).then(val => {
      if (val.isConfirmed) {
        this.isLoading = true;
        const formVal = removeAllNull(this.form.value);
        const zero = this.encryptePipe.transform('0');
        formVal['total_price'] = zero;
        this.muhasebeService.addCariHareket(formVal).subscribe(resp => {
          this.isLoading = false;
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
          this.close(true);
        }, (err) => {
          this.isLoading = false;
        });
      }
    });


  }

}
