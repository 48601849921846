<nb-card class="modalType animated fadeInRight" style="width: 70vw; height: 80vh; border-top: unset;"  cdkDrag nbSpinnerSize="tiny">
  <nb-card-header cdkDragHandle>
    <div class="row">
      <div class="col-11">
        <div class="row">
          <div class="col-md-6">
            {{'TOPLUPUANTAJEKLE.TOPLUPUANTAJEKLE'|translate}}
          </div>
        </div>
      </div>
      <div class="col-1">
        <button nbButton (click)="close()" size="tiny" class="m-close" style="background: none !important;
          color: black !important;
          float: right;
          font-size: 18px;">X</button>
      </div>
    </div>

    <div class="row" [formGroup]="filterForm">
      <div class="col-md-3">
        <label for="snty" class="form-label label">Şantiye</label> 
        <santiye-select formControlName="santiye_id" [mine]="true" [is_active]="true"></santiye-select>
      </div>

      <div class="col-2">
        <label for="tarih" class="form-label label">Tarih</label>
        <input nbInput [nbDatepicker]="tarih" dateOnly fullWidth formControlName="date" apasInvalid>
        <nb-datepicker #tarih></nb-datepicker>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body [formGroup]="form" [nbSpinner]="isLoadings['isSubmitting']">

    <div formArrayName="puantajList">
      <div class="row form-padding mt-3" *ngFor="let item of puantajList.controls;let i = index" [formGroupName]="i">
        <div class="col-3">
          <label class="form-label label">{{ 'PUANTAJ.BIRIMLER' | translate }}</label>
          <!-- <ng-select class="m-select uzun-select" [items]="taseronList" appendTo="body" [addTag]="true"
            [placeholder]="'PUANTAJ.TASERONSEC' | translate " style="max-width: 250px;"
            [loading]="isLoadings['taseron']" bindLabel="name" bindValue="id" formControlName="taseron_id" apasInvalid>
          </ng-select> -->
          <organization-select formControlName="taseron_id" (change)="$event" [organizationSearch]="item.get('taseron_name').value" apasInvalid></organization-select> 
        </div>
        <div class="col-3">
          <label class="form-label label">{{ 'PUANTAJ.BIRIMLER2' | translate }}</label>
          <job-select formControlName="job" [addTag]="true" apasInvalid></job-select>
        </div>
        <div class="col-2 ">
          <label class="form-label label">{{ 'PUANTAJ.BIRIMLER3' | translate }}</label>
          <input nbInput fullWidth type="number" numberOnly [placeholder]="'PUANTAJ.TAM' | translate"
            formControlName="tam_gun_adet">
        </div>
        <div class="col-2 ">
          <label class="form-label label">{{ 'PUANTAJ.BIRIMLER4' | translate }}</label>
          <input nbInput type="number" numberOnly fullWidth [placeholder]="'PUANTAJ.YARIM' | translate"
            formControlName="yarim_gun_adet">
        </div>
        <div class="col-2 pt-4">
          <button  size="medium" nbButton (click)="removeRow(i)" status="danger">{{ 'PUANTAJ.SIL' | translate }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2 p-0">
          <button  size="medium" nbButton (click)="addRow()" status="warning">Ekle</button> 

        </div>
      </div>
  

    </div>





  </nb-card-body>


  <nb-card-footer class="d-flex justify-content-center">
    <button nbButton (click)="postForm()" status="primary">{{ 'PUANTAJ.KAYDET' | translate }}</button>
  </nb-card-footer>
</nb-card>