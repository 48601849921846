<nb-card class="modalType animated fadeInRight" size="giant" style="width: 600px;">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>
            <span>
                Firma Bilgileri Düzenle
            </span>
        </span>

        <div>
            <button nbButton [status]="firma?.is_active ? 'info':'warning'" size="small" (click)="taseronPasif()">
                <nb-icon [icon]="firma?.is_active ? 'eye-off-outline' : 'eye-outline'"
                    style="float: left; margin-left: 4px; align-items: center; text-align: center;"></nb-icon>
                {{firma?.is_active? 'Pasif' : 'Aktif'}} Yap
            </button>
            <!-- <button nbButton size="small" *ngIf="firma?.can_delete" (click)="taseronDelete()"
                class="silButon">
                <nb-icon icon="trash-outline"
                    style="float: left; margin-left: 4px; align-items: center; text-align: center;"></nb-icon>
                Sil
            </button> -->
            <button nbButton (click)="close()" size="small" class="heryerdeClose" style="float: right;">X</button>
        </div>

    </nb-card-header>
    <nb-card-body [formGroup]="form" [nbSpinner]="isSubmitting" nbSpinnerSize="tiny">

        <div class="row">

            <label class="w-100 label mx-3" style="font-size: large;"> Firma Bilgileri </label>
            <!--Firma Adı-->
            <div class=" col-12 form-group">
                <label class="label">{{'APASDISIFIRMAEKLE.FIRMAKURUMADI'|translate}}</label>
                <input type="text" nbInput fullWidth formControlName="name" apasInvalid>

            </div>
            <!--//Firma Adı -->
            <div class="col-12 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.SEHIRSEC'|translate}} </label>
                <div>
                    <ng-select [items]="sehirler" bindLabel="name" bindValue="name" appendTo="body" apasInvalid
                        [placeholder]="'APASDISIFIRMAEKLE.SEHIRSEC'|translate" fullWidth formControlName="city">
                    </ng-select>
                </div>
            </div>

            <label class="w-100 label mx-3" style="font-size: large;">
                {{'APASDISIFIRMAEKLE.FIRMAKURUMYETKILIBILGILERI'|translate}}
            </label>

            <!--Ad soyad-->
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.AD'|translate}} </label>
                <input type="text" formControlName="first_name" nbInput fullWidth apasInvalid>

            </div>
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.SOYAD'|translate}} </label>
                <input type="text" formControlName="last_name" nbInput fullWidth apasInvalid>

            </div>
            <!--Ad soyad-->
            <!-- Telefon-->
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.TELEFON'|translate}} </label>
                <div>
                    <apas-telefon-input apasInvalid formControlName="phone"></apas-telefon-input>
                </div>
            </div>
            <!-- // Telefon-->
            <!-- Telefon Email-->
            <div class="col-md-6 form-group">
                <div class="form-group">
                    <label class="label"> {{'APASDISIFIRMAEKLE.EMAIL'|translate}} </label>
                    <input type="text" formControlName="email" nbInput fullWidth apasInvalid>
                </div>
            </div>
            <!-- // Telefon Email-->

        </div>
        <!--Apas dışı Bilgi-->
        <div class="text-center">
            <button nbButton (click)="patchForm()" status="primary" [nbSpinner]="isSubmitting"
                [disabled]="formDisabled">
                Güncelle
            </button>
        </div>
        <!--Apas Dışı Bilgi-->
    </nb-card-body>
</nb-card>