import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { GorevService } from '../../../services/gorev.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { colorSet } from '../../apas-components/apas-filter/apas-filter.component';
import { UrunCikisComponent } from '../../depolar/urun-cikis/urun-cikis.component';
import { DepoModel } from '../../../models/depo.model';
import { DepoService } from '../../../services/depo.service';
import { ProductService } from '../../../services/product.service';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { Observable, Subject, concat, of } from 'rxjs';
import { UrunGirisiYapComponent } from '../../depolar/depo-main/urun-girisi-yap/urun-girisi-yap.component';


@Component({
  selector: 'gorev-detay',
  templateUrl: './gorev-detay.component.html',
  styleUrls: ['./gorev-detay.component.css'],
  providers: [OrganizasyonService, GorevService],
  encapsulation: ViewEncapsulation.None,
})
export class GorevDetayComponent implements OnInit {
  colorSet = colorSet;
  isPatching: boolean = false;
  arkaplan: any;
  depolar: DepoModel[];
  gorevId: number;
  gorev: any;
  spinner = true;
  assign: string[] = [];
  gorevTamamlandi: boolean = false;
  gorev_percent: number[] = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
  ];
  form: FormGroup;
  brand$: Observable<any[]>;
  brandList: any[] = [];
  brandLoading: boolean = false;
  brandInput$ = new Subject<string>();
  tag: string = 'GorevDetayComponent --->';
  get percentage(): FormControl { return this.form.get('percentage') as FormControl; }
  default_brand_items: any[];
  malzemeForm: FormGroup;

  get mazelemeFormArray(): FormArray { return this.malzemeForm.get('liste') as FormArray; }
  constructor(protected ref: NbDialogRef<GorevDetayComponent>,
    private fb: FormBuilder,
    private gorevService: GorevService,
    private nbDialogService: NbDialogService,
    private depoService: DepoService,
    private _productService: ProductService,
    private cd: ChangeDetectorRef,
  ) {
    this.initForm();

  }

  ngOnInit() {
    this.depoService.getDepo().subscribe(data => {
      this.depolar = data;
    });
    this.getGorevDetail();
  }
  isLoading: boolean = false;
  getGorevDetail() {

    this.isLoading = true;
    this.gorevService.gorevDetay(this.gorevId).subscribe(async (data) => {
      this.isLoading = false;
      this.gorev = await this.clearData(data);
      this.form.patchValue(this.gorev);
      // this.refresh();
    }, (err) => this.isLoading = false);
  }

  async clearData(data) {

    data.end_time = convertDateWithTZ(data, 'end_time');
    data.created_time = convertDateWithTZ(data, 'created_time');

    data['assign_to_ids'] = await data?.assign_to?.map(m => m = m.apas_id);
    data['observable_names'] = await data?.observers?.map(x => x.name);
    data['observers'] = await data?.observers?.map(x => x.apas_id);
    data['durum'] = this.importance_level.find(x => x.value === data.importance_level)?.label;
    return data;
  }


 

  initForm() {
    this.form = this.fb.group({
      title: [null, [Validators.required, Validators.maxLength(120)]],
      content: [null, [Validators.required, Validators.maxLength(1200)]],
      end_time: [null, Validators.required],
      assign_to_ids: [null, Validators.required],
      importance_level: [null, Validators.required],
      percentage: [0],
      observers: [null],
    });

    this.form.controls.percentage.valueChanges.subscribe(val => {
      if (val === 100) {
        this.gorevTamamlandi = true;
      }
      this.isPatching = true;
      // tslint:disable-next-line: max-line-length
      this.gorevService.patchGorev(this.gorev.id, { percentage: val }).subscribe(response => this.isPatching = false, (err) => this.isPatching = false);

    });

    this.malzemeForm = this.fb.group({
      liste: this.fb.array([]),
    });

    this.addMalzemeItem();
  }

  addMalzemeItem() {
    const malzemeItem = this.fb.group({
      amount: [null, [Validators.required]],
      in_or_out: [false],
      warehouse_id: [null, Validators.required],
      note: [],
      product_id: [null, Validators.required],
      brand_id: [null],
      product_type: [1],
      warehouse_in_out_type: [3],
    });

    this.mazelemeFormArray.push(malzemeItem);
  }

  removeMalzemeItem(index) {
    this.mazelemeFormArray.removeAt(index);
  }

  urunCikis() {
    if (!this.malzemeForm.valid) {
      this.malzemeForm.markAllAsTouched();
      return;
    }
    const formVal = removeAllNull(this.mazelemeFormArray.value);
    this.depoService.addInOrOut(formVal).subscribe(
      (response) => {
        this.isPatching = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(
          () => {
            this.ref.close(response);
          },
        );
      },
      (err) => (this.isPatching = false),
    );
  }


  patchForm() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = removeAllNull(this.form.value);
    this.isPatching = true;
    this.gorevService.patchGorev(this.gorev.id, formData).subscribe(response => {
      this.isPatching = false;
      this.gorev = this.clearData(response);
      this.form.patchValue(this.gorev);
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.duzenle();
      this.closed();

      console.log(this.tag, 'response:', response);

    }, (err: HttpErrorResponse) => {
      this.isPatching = false;
    });
  }

  closed() {
    this.ref.close();
  }

  setValue(newValue) {
    this.form.get('percentage').setValue(Math.min(Math.max(newValue, 0), 100));
  }

  get status() {
    const value = this.percentage.value;
    if (value <= 10) {
      return 'danger';
    } else if (value <= 20) {
      return 'danger';
    } else if (value <= 30) {
      return 'danger';
    } if (value <= 40) {
      return 'warning';
    } else if (value <= 50) {
      return 'warning';
    } else if (value <= 60) {
      return 'warning';
    } else if (value <= 70) {
      return 'info';
    } else if (value <= 80) {
      return 'info';
    } else if (value <= 90) {
      this.gorevTamamlandi = false;
      return 'info';
    } else {
      this.gorevTamamlandi = true;
      return 'success';
    }
  }

  toggle(checked: boolean) {
    if (checked === true) {
      this.gorevTamamlandi = checked;
      this.setValue(100);
    } else {
      this.gorevTamamlandi = checked;
      this.setValue(-100);
    }
  }
  gorevDuzenle = false;
  duzenle() {
    this.gorevDuzenle = !this.gorevDuzenle;
  }

  importance_level = [
    { label: 'Çok Önemli', value: 1, bicim: 'cokonemli-select', level: 'cokonemli' },
    { label: 'Önemli', value: 2, bicim: 'onemli-select', level: 'onemli' },
    { label: 'Normal', value: 3, bicim: 'normal-select', level: 'normal' },
  ];

  sil() {

    const deleteText = `${this.gorev.title} başlıklı görevi silmek istediğinizden <b><u>Emin Misiniz</u> ? </b>`;
    Swal.fire({ ...responseKodlari.shureToDelete, ...{ html: deleteText } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.gorevService.deleteItem(this.gorev.id).subscribe(response => {
          console.log('RESPONSE', response);

          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);

          this.ref.close();
        });
      }

    });
  }

  urunIslemYap() { 
    const contextData = {
      type: 3,
    };
    this.nbDialogService.open(UrunGirisiYapComponent, { context: { data: contextData } });
  }
}
