

<nb-card accent="primary" class="max-400 border nb-card-border-1">
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <span>
      {{ 'SOZLESME.SONYAPILANIS' | translate }}
    </span>
    <button nbButton size="tiny" status="info" (click)="goToYapianIsler()">Tümünü Gör</button>
  </nb-card-header>

  <nb-card-body class="p-0" style="overflow-x: hidden;">
    <div class="aps-splt" style="background-color: #fff;" [nbSpinner]="isLoading">
      <div class="row w-100 p-0 m-0 apsHeaderTable">
    
        <div class="col-md-3">
          <span>Tarih</span>
        </div>
        <div class="col-md">
          <span>İmalat Cinsi</span>
        </div>
    
        <div class="col-md-2">
          <span>Durum</span>
        </div>
        <div class="col-md-2">
          <span>İşlemler</span>
        </div>
    
      </div>
    
      <div class="row p-0 mt-5 yatayOrtala" style="width: 95%;" *ngIf="!isLoading && (!rows || rows.length === 0)"> 
        <b>
          <nb-icon icon="alert-circle-outline"></nb-icon> 
          Yapılan İş Bulunamadı ...
        </b>
      </div>
    
      <nb-list *ngIf="rows && rows.length > 0" >
    
        <nb-list-item class="aps-hover p-0 listCustom linked-div" *ngFor="let row of rows;let i = index;" [nbTooltip]="row?.desc ? row?.desc:'Açıklama Yok'"> 
          <div class="col-md-3" (click)="yapilanIsDetay(row)">
            <span><b>{{i+1}}</b> | {{row.date * 1000 | date:tarih_format}}  </span>
          </div>
          <div class="col-md" (click)="yapilanIsDetay(row)">
            <span>{{row.title}} </span>
          </div>
     
          <div class="col-md-2" (click)="yapilanIsDetay(row)">
            <span>
              
    
            <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>0 && row.work_done_percentage <=30" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#F44336'" [innerStrokeColor]="'#FFCDD2'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="1000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
    
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>30 && row.work_done_percentage <=60" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#FF9800'" [innerStrokeColor]="'#FFE0B2'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="1000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
    
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>60 && row.work_done_percentage <=90" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#2196F3'" [innerStrokeColor]="'#BBDEFB'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="1000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
    
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>90 && row.work_done_percentage <=100" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#4CAF50'" [innerStrokeColor]="'#C8E6C9'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="1000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
            </span>
          </div>
          <div class="col-md-2"> 
            <button nbButton size="tiny" status="info" (click)="grafikModal(row)">{{'YAPILANISLERDASHBOARD.DETAY'|translate}}</button>
          </div>
        </nb-list-item>
    
      
      </nb-list>
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right p-0">
    <button nbButton status="primary" size="tiny" (click)="yapilanIsEkle()">Yeni Ekle </button>
  </nb-card-footer>
</nb-card>