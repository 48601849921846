import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment-timezone';
import { prefixBase64, tarih_format } from '../../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import { SantiyeService } from '../../../../services/santiye.service';
import { ApasImageLightboxComponent } from '../../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { SantiyeAlbumDetayComponent } from '../santiye-album-detay/santiye-album-detay.component';
import { SantiyeImgUploadComponent } from '../santiye-img-upload/santiye-img-upload.component';

@Component({
  selector: 'ngx-santiye-album',
  templateUrl: './santiye-album.component.html',
  styleUrls: ['./santiye-album.component.scss'],
})
export class SantiyeAlbumComponent implements OnInit {
  rows = [];
  santiye_id;
  form: FormGroup;
  tarih_format = tarih_format;
  prefixBase64 = prefixBase64;
  serializer = this.domSanitizer;
  isLoading: boolean = false;
  constructor(private activatedRouter: ActivatedRoute,
    private santiyeService: SantiyeService,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private nbDialogService: NbDialogService,
    private router: Router) {
    this.activatedRouter.params.subscribe(data => {
      this.santiye_id = data.santiyeid;
    });

  }

  ngOnInit(): void {
    this.initForm();
    this.getSantiyeImage();
  }

  initForm() {
    this.form = this.fb.group({
      start_date: [],
      santiye_id: [Number(this.santiye_id)],
      show_yapilan_isler: [true],
      show_santiye_photos: [true],
      search: [],
    });

    this.form.get('show_yapilan_isler').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.filtrele();
      }, 150);
    });

    this.form.get('show_santiye_photos').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.filtrele();
      }, 150);
    });

    this.form.get('search').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.rows = [];
        this.page = 0;
        this.isEnd = false;
        this.isLoading = false;
        this.getSantiyeImage(0);
      }, 150);
    });
  }

  filtrele() {
    this.rows = [];
    this.page = 0;
    this.isEnd = false;
    this.isLoading = false;
    this.getSantiyeImage();
  }

  next_start_date;
  santiyeName;

  request;
  isEnd: boolean = false;
  showAsList: boolean = false;
  getSantiyeImage(page?) {

    console.log('GETTING SANYIYE IMAGES ', this.santiye_id, 'IS LOADING : ', this.isLoading);
    if (this.santiye_id && !this.isLoading) {
      this.isLoading = true;

      if (this.request) {
        this.request.unsubscribe();
      }

      const filter = removeAllNull(this.form.value);
      if (page != undefined) {
        console.log(' PAGE IS NOT UNDEFİNED ', page);
        delete filter.start_date;
        filter.page = page;
      }

      console.log('REQUEST GİDİYOR ', filter);
      this.request = this.santiyeService.getImageCalander(filter).subscribe(data => {
        this.isLoading = false;
        this.next_start_date = data.next_start_date;
        this.santiyeName = data.santiye_name;
        this.showAsList = data.list_or_group;
        data.data.forEach(elm => {
          elm['tarih'] = convertDateWithTZ(elm, 'date');
          this.rows = addOrUpdate(this.rows, elm, 'date');
        });
     
        this.rows = [...this.rows];

      }, err => {
        this.isEnd = true;
        this.isLoading = false;
      });
    }
  }

  goToNextPage() {
    console.log('GO NEXT ', moment(this.next_start_date, 'X'));
    if (!this.isLoading && !this.isEnd) {
      this.form.get('start_date').setValue(this.next_start_date);

      this.getSantiyeImage();
    }
  }

  yeniFotoEkle() {
    this.nbDialogService.open(
      SantiyeImgUploadComponent,
      { context: { santiyeId: this.santiye_id } })
      .onClose.subscribe(data => {
        if (data === true) {
          this.getSantiyeImage();
        }
      });
  }

  albumDetay: boolean = false;
  dayData;
  dateC;
  getAlbumDetay(date?) {
    console.log("date", date);
    this.dateC = {
      santiye_id: this.santiye_id,
      santiye_name: this.santiyeName,
      start_date: date,
      showImalatPhotos: this.form.get('show_yapilan_isler').value,
      showSantiyePhotos: this.form.get('show_santiye_photos').value,
    };
    this.router.navigate(['pages/santiye/santiye-album-detay'], {
      state: { data: this.dateC },
    });
  }

  imgClick(
    image: string,
    imageIndex: number,
  ) {
    const context: {} = {
      image: image,
      url: 'santiye/imagedetay/',
      // 'url': 'santiye/imagedetay/' + this.santiyeId + '/' + imageId + '/',
      santiyeId: this.santiye_id,
      imageIndex: imageIndex,
      groupIndex: 0,
      rows: [{ images: this.rows }],

    };
    console.log('CONTEXT:', context);


    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }
  page = 0;
  goToSearchNext() {
    this.page += 1;
    this.getSantiyeImage(this.page);
  }
}
