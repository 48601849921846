import * as cariActions from './cari.actions';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { CariModel } from '../../models/cari.model';
import { addOrUpdate } from '../../../global-functions';


interface CariTurInterface {
    taseron: boolean;
    tedarikci: boolean;
    musteri: boolean;
    personel: boolean;
}

interface CariFilterInterface {
    search?: any;
    tags?: any[];
    turu?: CariTurInterface;
    page?: number;
    reset?: boolean;
    is_active: boolean;
    borclu?: boolean;
    alacakli?: boolean;
}

interface CariState extends EntityState<CariModel> {
    selectedcariId: number | null;
    selectedHareketId: number | null;
    loading: boolean;
    loaded: boolean;
    error: String;
    filter: CariFilterInterface;
    isEnd: boolean;

}

const cariAdapter: EntityAdapter<CariModel> = createEntityAdapter<CariModel>();
const defaultCariState: CariState = {
    ids: [],
    entities: {},
    selectedcariId: null,
    selectedHareketId: null,
    loading: false,
    loaded: false,
    filter: {
        search: null,
        tags: null,
        borclu: null,
        alacakli: null,
        turu: {
            taseron: true,
            tedarikci: true,
            musteri: true,
            personel: true,
        },
        is_active: true,
        reset: false,
        page: 0,
    },
    error: '',
    isEnd: false,
};

const initialState = cariAdapter.getInitialState(defaultCariState);

const cariReducer = createReducer(
    initialState,
    on(cariActions.loadCari, (state, props) => {
        return { ...state, loading: true };
    }),
    on(cariActions.loadCariSuccess, (state, props) => {

        return cariAdapter.upsertMany(props.cariItem, {
            ...state,
            loading: false,
            loaded: true,
            isEnd: props.isEnd,
        });
    }),
    on(cariActions.loadCariFail, (state, props) => {
        return { ...state, loading: false, isEnd: props.isEnd, error: props.error };
    }),
    on(cariActions.addCariItem, (state, props) => {
        console.log('ADD CARI ITEM : ', props);
        return cariAdapter.upsertOne(props.cariItem, { ...state });
    }),
    on(cariActions.updateCariSuccess, (state, { cari }) => {
        return cariAdapter.updateOne(cari, { ...state });
    }),
    on(cariActions.selectCariItem, (state, props) => {
        return { ...state, selectedcariId: props.id };
    }),
    on(cariActions.filterCari, (state, props) => {
        return { ...state, loading: true };
    }),
    on(cariActions.setCariFilter, (state, props) => {

        return { ...state, filter: props, isEnd: false };
    }),

    on(cariActions.setCariEnd, (state, props) => {
        return { ...state, isEnd: props.isEnd };
    }),

    on(cariActions.loadCariHareket, (state, props) => {
        const cariUpdate: Update<CariModel> = { id: props.cariId, changes: { isLoading: true } };
        return cariAdapter.updateOne(cariUpdate, { ...state });
    }),

    on(cariActions.removeCariHareket, (state, props) => {
        const cariUpdate: Update<CariModel> = { id: props.cariId, changes: { isLoading: true } };
        return cariAdapter.updateOne(cariUpdate, { ...state });
    }),

    on(cariActions.setSelectedHareketId, (state, props) => {
        return { ...state, selectedHareketId: props.hareketId };
    }),

    on(cariActions.updateHareketItem, (state, props) => {
        const currentHesap = state.entities[state.selectedcariId];
        console.log('Update hareket ', props.hareket);
        const currentHareket = currentHesap.cari_harekets.find(x => x.id === props.hareket.id);
        const updateHareket = { ...currentHareket, ...props.hareket };
        const hareket = addOrUpdate(currentHesap.cari_harekets, updateHareket);
        const hesap: Update<CariModel> = { id: state.selectedcariId, changes: { cari_harekets: hareket } };
        console.log('update hareket item', hesap);
        return cariAdapter.updateOne(hesap, { ...state });
    }),
);

function CariReducer(action, state) {
    return cariReducer(action, state);
}


export {
    CariTurInterface,
    CariFilterInterface,
    CariState,
    cariAdapter,
    defaultCariState,
    CariReducer,
};
