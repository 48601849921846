<nb-card class="modalType animated fadeInRight" style="width: 500px;">
    <nb-card-header class="d-flex justify-content-between">
        <span>
        <span class="badge badge-success" [nbTooltip]="'Bu talebe teklif alacak personeller'">{{talep.owned_by_count}}</span> {{talep.title}}

        </span>
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>
    <nb-card-body  class="p-0"  [nbSpinner]="isSubmitting" nbSpinnerSize="tiny">
        <table class="table ">
            <thead>
                <tr>
                    <th scope="col">{{ 'SATINALMATALEP.ADI1' | translate }}</th>
                    <th scope="col">{{ 'SATINALMATALEP.TEL1' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of talep.owned_by">
                    <td>{{item?.name}}</td>
                     <td>{{(item?.phone)?(item?.phone | mask:telefonKodu):''}}</td> 
                </tr>

            </tbody>
        </table>
    <div class="d-flex align-items-center justify-content-between p-1">
        <label class="label mb-0 text-right" *ngIf="!talep.owned_by_me">{{ 'SATINALMATALEP.TEKLIFAL' | translate }}</label>
        <button nbButton status="success" size="small" *ngIf="!talep.owned_by_me" (click)="talepGorev(talep.id)">{{ 'SATINALMATALEP.GOREVEKLE' | translate }} </button>
        <button nbButton status="warning" size="small" *ngIf="talep.owned_by_me" (click)="talepGorev(talep.id)" >{{ 'SATINALMATALEP.ILGILENME' | translate }}</button>

    </div>
</nb-card-body>

</nb-card>