import { Component, Input, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BildirimModel } from '../../../../models/bildirim.model';
import { BildirimService } from '../../../../services/bildirim.service';
import { responseKodlari } from '../../../../pages/constants';
import { Store } from '@ngrx/store';
import * as bildirimActions from '../../../../store/bildirim/bildirim.actions';
import { ApasNaviagationService } from '../../../../pages/apas-navigator-service/apas-navigation-service';
import { ApasAlertServiceService } from '../../apas-alert-modul/services/apas-alert.service';
import { showPdf } from '../../../../pages/global-functions';
import { Router } from '@angular/router';
import { ClearOrgInfo } from '../../../../store/organization/organization.actions';


export enum BildirimItemModuleType {
  'Hatirlatma' = 'hatirlatma',
  'Mutabakat' = 'mutabakat',
}

export enum BildirimItemType {
  'Detail' = 'detail',
  'Link' = 'link',
  'Blank' = 'blank',
  'File' = 'file',

}
@Component({
  selector: 'apas-notification-item',
  templateUrl: './apas-notification-item.component.html',
  styleUrls: ['./apas-notification-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApasNotificationItemComponent {
  tag: string = 'ApasNotificationItemComponent --->';
  @Input() context: BildirimModel;

  constructor(private bildirimService: BildirimService,
    private apasNaviagationService: ApasNaviagationService,
    private apasAlertServiceService: ApasAlertServiceService,
    private store: Store<{}>,
    private router: Router) {
  }



  click() {
    // bildirim nesnesi yok ise
    if (!this.context) {
      return false;
    }
    // bildirim zaten okun mamışsa okundu olarak işaretle.
    if (!this.context.is_readed) {
      this.store.dispatch(bildirimActions.setBildirimReaded({
        id: this.context.bildirim_id,
      }));
    }

    switch (this.context.type) {
      case BildirimItemType.Blank:

        break;
      case BildirimItemType.Detail:
        this.apasNaviagationService.navigateModule({ module: this.context.module, payload: this.context });
        break;
      case BildirimItemType.File:
        this.openFileNotification();
        break;

      case BildirimItemType.Link:
        this.openLinkNotification();
        break;

      default:
        break;
    }
  }
  /**
   * SC03 zaten onaylandı
   */
  openLinkNotification() {
    Swal.fire({
      icon: 'question',
      title: this.context.title,
      text: this.context.message + ' onaylıyor musunuz?',
      showCancelButton: true,
      confirmButtonText: 'Onayla',
      cancelButtonText: 'Onaylama',
      showLoaderOnConfirm: true,

      preConfirm: () => {
        return this.bildirimService.setLing(this.context.content.link).then(response => {
          Swal.update({ html: undefined });
          return response;
        }).catch(error => {
          Swal.update({ html: undefined });
          if (error.error.SC03 !== undefined) {
            Swal.update({ showConfirmButton: false, cancelButtonText: 'Kapat', icon: 'error' });
            Swal.showValidationMessage(
              'Zaten Onaylanmıştır.',
            );
          } else {
            Swal.showValidationMessage(
              'İşlem gerçekleştirilemedi.Lütfen daha sonra tekrar deneyiniz.',
            );
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((accept) => {

      if (accept.value) {
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        if (accept.value.SC10) {
          localStorage.removeItem('org');
          this.store.dispatch(ClearOrgInfo());
          this.router.navigateByUrl('/karsilama');
        }
      }
    });
  }



  openFileNotification() {
    this.apasAlertServiceService.fire({
      title: this.context.title,
      text: this.context.message,
      fileButtonText: 'Dosyayı Görüntüle',
      confirmButtonText: 'İmzala',
      cancelButtonText: 'Kapat',
      denyButtonText: 'Reddet',

      preConfirm: () => {
        // this.apasAlertServiceService.close();
        this.apasAlertServiceService.update({ showDenyButton: false, showFileButton: false });
        return this.bildirimService.setLing(this.context.content.link).then(response => {
          return response;
        }).catch(error => {
          if (error.SC03 !== undefined) {
            this.apasAlertServiceService.update({
              showFileButton: false,
              showConfirmButton: false,
              showDenyButton: false,
              showCancelButton: true,
              icon: 'error',
            });
            this.apasAlertServiceService.showValidationMessage(
              'Zaten Onaylanmıştır.',
            );
          } else {
            this.apasAlertServiceService.update({
              showFileButton: false,
              showConfirmButton: false,
              showDenyButton: false,
              showCancelButton: true,
              icon: 'error',
            });

            this.apasAlertServiceService.showValidationMessage(
              'İşlem gerçekleştirilemedi.Lütfen daha sonra tekrar deneyiniz.',
            );
          }
        });

      },
      preDeny: () => {
        this.apasAlertServiceService.update({ showDenyButton: true, showFileButton: false, showCancelButton: false });
        return this.bildirimService.setLing(this.context.content.refuse).then(response => {
          return response;
        }).catch(error => {
          this.apasAlertServiceService.update({ html: undefined });
          if (error.SC03 !== undefined) {
            this.apasAlertServiceService.update({
              showFileButton: false,
              showConfirmButton: false,
              showDenyButton: false,
              showCancelButton: true,
              icon: 'error',
            });
            this.apasAlertServiceService.showValidationMessage(
              'Zaten İza kaldırılmış.',
            );
          } else {
            this.apasAlertServiceService.update({
              showFileButton: false,
              showConfirmButton: false,
              showCancelButton: true,
              showDenyButton: false,
              icon: 'error',
            });

            this.apasAlertServiceService.showValidationMessage(
              'İşlem gerçekleştirilemedi.Lütfen daha sonra tekrar deneyiniz.',
            );
          }
        });
      },
      preFile: () => {
        if (this.context.content['fileBase64']) {
          showPdf(this.context.content['fileBase64']);

        } else {
          this.apasAlertServiceService.update({
            showConfirmButton: false,
            showDenyButton: false,
            showFileButton: true,
            showCancelButton: false,
          });
          return this.bildirimService.getFileLing(this.context.content.file).then((response) => {
            this.context.content['fileBase64'] = response._file;
            showPdf(response._file);
            this.apasAlertServiceService.update({
              showFileButton: true,
              showConfirmButton: true,
              showCancelButton: false,
              showDenyButton: true,
              icon: 'error',
            });
          }).catch(error => {
            this.apasAlertServiceService.update({});
            this.apasAlertServiceService.update({
              showFileButton: false,
              showConfirmButton: false,
              showCancelButton: true,
              showDenyButton: false,
              icon: 'error',
            });
            this.apasAlertServiceService.showValidationMessage(
              'İşlem gerçekleştirilemedi.Lütfen daha sonra tekrar deneyiniz.',
            );
          });
        }
      },
    }).then((data) => {
      //
    });
  }

}
