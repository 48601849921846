import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { removeNull } from '../../../../pages/global-functions';
import { FormBuild } from '../../../../pages/formInterfaces';
import { TaseronService } from '../../../../services/taseron.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { telefonKodu, responseKodlari } from '../../../../pages/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { PersonelExcelImportComponent } from '../personel-excel-import/personel-excel-import.component';

@Component({
  selector: 'firma-personel-ekle-modal',
  templateUrl: './personel-ekle.component.html',
  styleUrls: ['./personel-ekle.component.css'],
  providers: [TaseronService],

})
export class DigerFirmaPersonelComponent implements OnInit, FormBuild {
  firma: any;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    protected cd: ChangeDetectorRef,
    private taseronService: TaseronService,
    private ref: NbDialogRef<DigerFirmaPersonelComponent>,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();
  }

  tag: string = 'puantaj-ekle-modal-personel --->';

  telefon_kodu = telefonKodu;
  ulkem: FormControl = new FormControl(telefonKodu[0]);

  ngOnInit() {
    if (!this.firma) {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      this.ref.close();
    } else {
      this.form.get('firma_id').setValue(this.firma?.id);
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      firma_id: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      kimlik_no: [null],
      phone: [null],
      job_id: [null, Validators.required],
    });
  }

  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('Errors : ', this.form);
      return;
    }
    this.isLoading = true;
    const formData = removeNull(this.form.value);
    this.taseronService.addTaseronPersonel(formData, this.firma?.id).subscribe(data => {
      this.isLoading = false;
      Swal.fire({
        ...responseKodlari.successProgress,
        timer: 10000,
        confirmButtonText: 'Yeni Ekle',
        showConfirmButton: true,
        cancelButtonText: 'Kapat',
        showCancelButton: true,
      } as SweetAlertOptions).then(({ value }) => {
        if (value) {
          this.resetForm();
        } else {
          this.ref.close(data);
        }
      });
    }, (err: HttpErrorResponse) => {
      this.isLoading = false;
      this.errors(err);
    });
  }

  resetForm() {
    this.form.reset();
    this.form.get('firma_id').setValue(this.firma?.id);
  }

  errors(error: HttpErrorResponse) {
    // console.log('hata------>', error);
    // Eğer Tc kimlik no zaten kayıtlı ise
    if (error.error.ER05 !== undefined) {
      Swal.fire(responseKodlari.ER05 as SweetAlertOptions);
      // Eğer eposta zaten kayıtlı ise
    } else if (error.error.email !== undefined) {
      Swal.fire(responseKodlari.email as SweetAlertOptions);
    } else if (error.error.phone !== undefined) {
      Swal.fire(responseKodlari.phone as SweetAlertOptions);
    } else if (error.error.ER23 !== undefined) {
      Swal.fire(responseKodlari.ER23 as SweetAlertOptions);
    } else {
      Swal.fire(responseKodlari.unSuccessOrgRegister as SweetAlertOptions);
    }
  }

  close(resp?) {
    this.ref.close(resp);
  }

  excelImport() {
    this.nbDialogService.open(
      PersonelExcelImportComponent,
      { context: { firma: this.firma } },
    ).onClose.subscribe(resp => {
      this.close(resp);
    });
  }

}
