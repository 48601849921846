<nb-card style="max-width: 500px; min-width: 500px;" size="giant" class="modalType animated fadeInRight">
  <nb-card-header >

    <b style="font-weight: 400;">{{'YAPILANISLERDETAY.IMALATCINSI'|translate}}</b> {{yapilanIs.pursantaj_title}}
    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
    color: #000 !important;
    float: right;
    font-size: 18px;">X</button>




  </nb-card-header>
  <nb-card-body  [nbSpinner]="isLoading">
    <div class="text-center">
      <circle-progress [percent]="yapilanIs.work_done_percentage" [radius]="40" [outerStrokeWidth]="12"
        *ngIf="yapilanIs.work_done_percentage>0 && yapilanIs.work_done_percentage <=30" [innerStrokeWidth]="12"
        [space]="-12" [outerStrokeColor]="'#F44336'" [innerStrokeColor]="'#FFCDD2'" [titleFontSize]="'25'"
        [unitsFontSize]="'25'" [showSubtitle]="false" [animation]="true" [animationDuration]="1000"
        [startFromZero]="false" [responsive]="false" [radius]="40">
      </circle-progress>

      <circle-progress [percent]="yapilanIs.work_done_percentage" [radius]="40" [outerStrokeWidth]="12"
        *ngIf="yapilanIs.work_done_percentage>30 && yapilanIs.work_done_percentage <=60" [innerStrokeWidth]="12"
        [space]="-12" [outerStrokeColor]="'#FF9800'" [innerStrokeColor]="'#FFE0B2'" [titleFontSize]="'25'"
        [unitsFontSize]="'25'" [showSubtitle]="false" [animation]="true" [animationDuration]="1000"
        [startFromZero]="false" [responsive]="false" [radius]="40">
      </circle-progress>

      <circle-progress [percent]="yapilanIs.work_done_percentage" [radius]="40" [outerStrokeWidth]="12"
        *ngIf="yapilanIs.work_done_percentage>60 && yapilanIs.work_done_percentage <=90" [innerStrokeWidth]="12"
        [space]="-12" [outerStrokeColor]="'#2196F3'" [innerStrokeColor]="'#BBDEFB'" [titleFontSize]="'25'"
        [unitsFontSize]="'25'" [showSubtitle]="false" [animation]="true" [animationDuration]="1000"
        [startFromZero]="false" [responsive]="false" [radius]="40">
      </circle-progress>

      <circle-progress [percent]="yapilanIs.work_done_percentage" [radius]="40" [outerStrokeWidth]="12"
        *ngIf="yapilanIs.work_done_percentage>90 && yapilanIs.work_done_percentage <=100" [innerStrokeWidth]="12"
        [space]="-12" [outerStrokeColor]="'#4CAF50'" [innerStrokeColor]="'#C8E6C9'" [titleFontSize]="'25'"
        [unitsFontSize]="'25'" [showSubtitle]="false" [animation]="true" [animationDuration]="1000"
        [startFromZero]="false" [responsive]="false" [radius]="40">
      </circle-progress>

      <h6 class="text-center">{{'YAPILANISLERDETAY.DURUM'|translate}}</h6>
    </div>
    <div class="text-right">
      <button nbButton size="tiny" status="primary" (click)="editY()">Düzenle</button>
      <button nbButton size="tiny" status="danger" (click)="deleteYapilanIs()">Sil</button>
    </div>
    <table class="table table-bordered">

      <tbody [formGroup]="form">
        <tr>
          <th scope="row">{{'YAPILANISLERDETAY.IMALATTARIHI'|translate}}</th>
          <td *ngIf="!edit">{{yapilanIs.date * 1000 | date: tarih_format}}</td>
          <td *ngIf="edit">
            <input nbInput size="small" fullWidth formControlName="date" [nbDatepicker]="date">
            <nb-datepicker #date></nb-datepicker>
          </td>

        </tr>
        <tr *ngIf="edit">
          <th scope="row">{{'ACIKLAMAILEYAPILANIS.KUMULATIF'|translate}}</th>
          <td><input nbInput size="small" fullWidth formControlName="work_done_percentage"></td>
        </tr>
        <tr>
          <th scope="row">{{'YAPILANISLERDETAY.IMALATCINSI'|translate}}</th>
          <td>{{yapilanIs.title}}</td>
        </tr>
        <tr *ngIf="yapilanIs.tamamlanan_metraj">
          <th scope="row">
            Tamamlanan Metraj
          </th>

          <td *ngIf="!edit">
            {{yapilanIs.tamamlanan_metraj}}
          </td>

          <td *ngIf="edit">
            <input nbInput fullWidth formControlName="tamamlanan_metraj" paraDirective [encrypt]="false"
              [decrypt]="false" [negative]="false">
          </td>
        </tr>
        <tr>
          <th scope="row">{{'YAPILANISLERDETAY.ACIKLAMA'|translate}}</th>
          <td *ngIf="!edit">{{yapilanIs.desc}}</td>
          <td *ngIf="edit"><input nbInput size="small" fullWidth formControlName="desc"></td>
        </tr>
      </tbody>
    </table>
    <div class="text-center" *ngIf="edit">
      <button nbButton size="small" status="warning" (click)="patchForm()">Güncelle</button>
    </div>

    <div class="row d-flex" *ngIf="!edit">

      <span *ngIf="yapilanIs?.photos" class="col-11" style="float: left;">
        <ngu-carousel #myCarousel [inputs]="carouselTileConfig" [dataSource]="yapilanIs?.photos" class="carouselMargin">
          <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
            <!-- [@slider]="ani" PLease this animation to this tile to see animation. stackblitz have error with animations -->
            <div class="tile">
              <img *ngIf="item?.image" [src]="item?.image" class="img-fluid img-thumbnail"
                style="max-height: 60px; width: 100%;" [alt]="item.note"
                (click)="openImageDetail(item, yapilanIs?.photos, yapilanIs?.photos.photo_count, i)">
            </div>
  
            </ngu-tile>
          <nb-icon icon="arrow-ios-back-outline" NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.5:1"></nb-icon>
          <nb-icon icon="arrow-ios-forward-outline" NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.5:1"></nb-icon>
  
        </ngu-carousel>
      </span>
      <div class="linked-div col-1 d-flex p-0 justify-content-center" style="margin-left: auto;" (click)="addMorePhoto()">
        <span style="background-color:#01579B !important; padding: 6px; border-radius: 50%; margin-top: auto;margin-bottom: auto; height: 32px; width: 32px;" [nbTooltip]="'Fotoğraf Ekle'">
          <nb-icon icon="plus-outline" style="transform: scale(1.3); color: white;"></nb-icon> 
        </span>
      </div>

    </div>


  </nb-card-body>
 

</nb-card>