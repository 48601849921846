import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { OrganizasyonService } from '../services/organizasyon.service';
import { SetOrgInfo } from '../store/organization/organization.actions';
@Injectable({
  providedIn: 'root',
})

export class AuthGuard implements CanActivate {
  constructor(private atuhService: NbAuthService,
    private router: Router) { }
  canActivate() {

    return this.atuhService.isAuthenticatedOrRefresh()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['logout']);
          } else {

          }
        }),
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<{ OrgInfo, Redirect }>,
    private organizationService: OrganizasyonService,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {

    return new Observable<boolean>(obs => {
      this.organizationService.org$.subscribe(org => {

        if (org.id && org.payment !== undefined) {
          /** State içerisin org bilgisi varsa true olarak yoluna devam eder. */
          if (org.payment) {
            obs.next(true);
          } else {
            // this.router.navigate(['profil/odeme']);
            obs.next(true);
          }
        } else {
          /** State içinde org bilgisi yoksa localStorage'a bakar. */
          const org_info = JSON.parse(localStorage.getItem('org'));
          if (org_info) {
            /* Local Storage'da org bilgisi varsa, state org bilgisini basar ve yoluna devam eder.*/
            this.store.dispatch(SetOrgInfo({ ORG: org_info }));
            obs.next(true);
          } else {
            /**Local Storage'da org bilgisi de yoksa false döner ve karşılamaya ilerler. */
            this.router.navigate(['karsilama']);
            obs.next(false);
          }
        }

      });

    });
  }
}
