import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ImageModel } from '../../../models/image.model';
import { ApasImageUplaoder } from '../../../services/apasImageUplaoder.service';
import { resizeImage } from '../../../pages/global-functions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormBuild } from '../../../pages/formInterfaces';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

/** Sunucuya veri gönderme tipini belirtir.
 * @member patch HttpClient.patch metodunu kullanır.
 * @member post HttpClient.post metodunu kullanır.
 */
export enum ApasImageUploaderMethotType {
  'patch' = 0,
  'post' = 1,
}
/** sunucuya veri yükleme butonunun gözükme durumunu yönetir.
 * @member manuel Kullanıcıya Upload butonunu gösterir.
 * @member auto Upload butonu gözükmez. .upload() kullanılarak
 * siz yükleme işlemini başlatmalısınız.
 * */
export enum ApasImageUploaderUploadType {
  'manuel' = 0,
  'auto' = 1,
}
@Component({
  selector: 'apas-image-uploader',
  templateUrl: './apas-image-uploader.component.html',
  providers: [ApasImageUplaoder],
  styleUrls: ['./apas-image-uploader.component.css'],
  encapsulation: ViewEncapsulation.None,
})
/**
 * Sunucuya resim yüklemek için yardımcı olur.
 * @param Src File cinsinden gösterilecek resimi ifade eder.
 * @param Url resmin yüklenceği api/ ' dan sonraki url.
 */
export class ApasImageUploaderComponent implements OnInit, FormBuild {


  @Input('Url') targetUrl: string;
  @Input('Src') set src(value: File | String) {
    // console.log('tip->', typeof (value), 'value->', value);

    // if (value instanceof File) {
    //   console.log('bu bir File');
    // }
    // if (value instanceof String) {
    //   console.log('bu bir File');
    // }

    this.showImage(value);
  }
  @Input('uploadType') uploadType: ApasImageUploaderUploadType = ApasImageUploaderUploadType.manuel;

  @Input('showDetay') detay: boolean = true;
  @Input('showDateInput') showDateInput: boolean = false;
  @Input('showPublic') public: boolean = false;

  @Input('publicText') publicText: string = '';
  
  /**
   * Sunucuya veri gönderilir iken kullanılacak methd.ör:post,patch
   */
  @Input('method') method: ApasImageUploaderMethotType = ApasImageUploaderMethotType.post;
  /**
   * Resim sunucuya başarılı bir şekilde yüklendiğinde tetiklenir.
   */
  @Output() success: EventEmitter<ApasImageUploaderComponent> = new EventEmitter();
  /**
     * Dosya sistemden başarılı birşekilde gösterildiğinde  tetiklenir.
     */
  @Output() successLoad: EventEmitter<ApasImageUploaderComponent> = new EventEmitter();

  /**
   * İşlem sırasında bir hata olursa tetiklenir.
   */
  @Output() fail: EventEmitter<ApasImageUploaderComponent> = new EventEmitter();

  /**
   * Resmin kaldırıldığında tetiklenir.
   */
  @Output() onRemove: EventEmitter<ApasImageUploaderComponent> = new EventEmitter();

  readonly message = { value: 'İşlem başarılı.', error: '' };
  uploadtype = ApasImageUploaderUploadType;
  tag: string = 'apas-image-uploader.component --->';
  ImageUrl;
  loading: boolean;
  uploading: boolean = false;
  isSuccess: boolean = false;
  isAnimationComplate: boolean = false;
  isSubmited: boolean = false;
  respons: ImageModel;
  form: FormGroup;
  // titleControl: FormControl = new FormControl();
  constructor(private apasImageUploader: ApasImageUplaoder,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer) { }



  ngOnInit() {
    this.initForm();
  }
  initForm(): void {
    this.form = this.fb.group({
      titleControl: [null, Validators.maxLength(512)],
      publicControl: [false],
      created_time: [new Date()],
    });

  }
  /** [src] ile gelen FileList nesneslinden birini gösteren fonksiyon
   * @param file File cinsinden data
   */
  showImage(file: File | String) {

    if (file instanceof File) {
      this.showFile(file);
    } else if (typeof (file) === 'string') {
      this.showBase64(file);
    }
  }
  showBase64(file: String) {
    this.loading = false;
    this.ImageUrl = this.domSanitizer.bypassSecurityTrustUrl('' + file);
    this.successLoad.emit(this);
  }
  showFile(file: File) {
    this.loading = true;

    // Dosya okumamızı sağlar.
    const reader = new FileReader();
    // Dosyayı okur ve ImgaUrl'e yükler.
    reader.onload = () => {
      this.ImageUrl = reader.result as string;
      this.loading = false;
      this.successLoad.emit(this);
    };
    // resimin Gözükmesi için onu güvenilir olarak işaretler.
    // aksi taktirde resim gösterilmiyecektir.
    reader.readAsDataURL(file);
  }

  /**
   * Resimleri suncuya yükleyn fonksiyon.
   */
  upload() {
    // console.log('METHOD:', this.method);
    if (!this.targetUrl) {
      this.urlError();
    }
    // kullanıcının girdiği metin
    let title = null;
    if (this.detay) {
      title = this.form.get('titleControl').value;
    }
    let isPublic = false;
    if (this.public) {
      isPublic = this.form.get('publicControl').value;
    }

    let createTime = moment(this.form.get('created_time').value).unix();

    // zaten bir yükleme yapıyorsak tekrar yükleme yapmasını engelliyor.
    if (this.loading && this.uploading) {
      return;
    }
    // yükleme işleminin başladığını belirtiyor.
    this.uploading = true;
    // sunucuya yüklenmden once resmin yeniden boyutlandırlması icin gerekli
    // resizing işlemini yapan fonksiyon
    resizeImage(this.ImageUrl).then((resizedImage: string) => {
      // sunucuya gödermek için Image modeli  oluşturuluyor.
      const img = { image: resizedImage, title: title, is_public: isPublic, created_time: createTime} as ImageModel;
      // const img = { image: resizedImage.split(',')[1], title: title } as ImageModel;
      // post resim sunucuya gönderiliyor.
      if (this.method === ApasImageUploaderMethotType.post) {
        this.isSubmited = true;
        this.apasImageUploader.postImage(this.targetUrl, img).subscribe((data: ImageModel) => {
          this.respons = data;
          this.uploading = false;
          this.isSuccess = true;
          // Resim yüklendiğinde text areayı kapatır.
          this.form.get('titleControl').disable();
          this.success.emit(this); // sonucun başarılı olma durumunu tetikler.
        }, (err) => {
          this.message.value = 'fail';
          this.fail.emit(this); // başarısız durumunu tetikler.
          this.isSuccess = false;
          this.uploading = false;

          throw new Error('Resim sunucuya yüklenemedi. error:' + err.message);
        });
      }

      // patch  resim sunucuya gönderiliyor.
      if (this.method === ApasImageUploaderMethotType.patch) {
        this.isSubmited = true;

        this.apasImageUploader.patchImage(this.targetUrl, img.image).subscribe((data: ImageModel) => {
          this.respons = data;
          this.uploading = false;
          this.isSuccess = true;
          // Resim yüklendiğinde text areayı kapatır.
          this.form.get('titleControl').disable();
          this.success.emit(this); // sonucun başarılı olma durumunu tetikler.
        }, (err) => {
          this.message.value = 'fail';
          this.isSuccess = false;
          this.uploading = false;
          this.fail.emit(this); // başarısız durumunu tetikler.
          throw new Error('Resim sunucuya yüklenemedi. error:' + err.message);
        });
      }

    }, () => {
      this.message.error = 'Resim boyutlandırılamadı.';
      this.fail.emit(this); // başarısız durumunu tetikler.
      throw new Error('Resim boyutlandırılamadı.');
    });

  }
  /**
   * Kullanıcı Url vermez ise çalışır.
   */
  urlError() {
    throw new Error('Url verdinmi knk :D Url:' + this.targetUrl + ' ör:[Url]="senin urlin');
  }


  remove() {
    this.onRemove.emit(this);
  }


}
