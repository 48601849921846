<nb-card class="modalType animated fadeInRight" size="giant" style="width: 600px;">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>
            <span>
                Yeni Firma Ekle
            </span>
            <nb-icon icon="question-mark-circle-outline" class="ml-2" [nbPopover]="bilgi" nbPopoverTrigger="hover">
            </nb-icon>
        </span>
        <div>
            <button nbButton (click)="close()" size="small" class="heryerdeClose" style="float: right;">X</button>
        </div>

    </nb-card-header>
    <nb-card-body [formGroup]="form" [nbSpinner]="isLoading" nbSpinnerSize="tiny">

        <div class="row">

            <label class="w-100 label mx-3" style="font-size: large;"> Firma Bilgileri </label>
            <!--Firma Adı-->
            <div class=" col-12 form-group">
                <label class="label">{{'APASDISIFIRMAEKLE.FIRMAKURUMADI'|translate}}</label>
                <input type="text" nbInput fullWidth formControlName="name" [nbAutocomplete]="firmaComplete"
                    apasInvalid>
                <nb-autocomplete class="w-100" #firmaComplete (selectedChange)="onSelectionChange($event);">
                    <nb-option class="w-100 d-flex" *ngFor="let option of filteredOptions$ | async" [value]="option">
                        <nb-user #userProfile size="large" class="user-option" [name]="option.name" [showName]="false"
                            [picture]="option.image">
                        </nb-user>
                        <span>
                            <div class="d-flex align-items-center">
                                <strong style="font-size: large;"> {{option.name}} </strong>
                                <label class="label w-auto mb-0 ml-2 ">{{option.city ? '(' + option.city +')' :
                                    ''}}</label>
                            </div>
                            <div class="d-flex align-items-center">
                                <label class="label w-auto mb-0  mr-2" style="color: #FF7043;">ApasID : </label>
                                <small><b>{{option.apas_id}}</b></small>
                            </div>
                        </span>

                    </nb-option>
                </nb-autocomplete>

            </div>
            <!--//Firma Adı -->
            <div class="col-12 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.SEHIRSEC'|translate}} </label>
                <div>
                    <ng-select [items]="sehirler" bindLabel="name" bindValue="name" appendTo="body" apasInvalid
                        [placeholder]="'APASDISIFIRMAEKLE.SEHIRSEC'|translate" fullWidth formControlName="city">
                    </ng-select>
                </div>
            </div>

            <label class="w-100 label mx-3" style="font-size: large;">
                {{'APASDISIFIRMAEKLE.FIRMAKURUMYETKILIBILGILERI'|translate}}
            </label>

            <!--Ad soyad-->
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.AD'|translate}} </label>
                <input type="text" formControlName="first_name" nbInput fullWidth apasInvalid>

            </div>
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.SOYAD'|translate}} </label>
                <input type="text" formControlName="last_name" nbInput fullWidth apasInvalid>

            </div>
            <!--Ad soyad-->
            <!-- Telefon-->
            <div class="col-md-6 form-group">
                <label class="label"> {{'APASDISIFIRMAEKLE.TELEFON'|translate}} </label>
                <div>
                    <apas-telefon-input apasInvalid formControlName="phone" class="d-block"></apas-telefon-input>
                </div>
            </div>
            <!-- // Telefon-->
            <!-- Telefon Email-->
            <div class="col-md-6 form-group">
                <div class="form-group">
                    <label class="label"> {{'APASDISIFIRMAEKLE.EMAIL'|translate}} </label>
                    <input type="text" formControlName="email" nbInput fullWidth apasInvalid>
                </div>
            </div>
            <!-- // Telefon Email-->

        </div>
        <!--Apas dışı Bilgi-->
        <div class="text-center">
            <button nbButton (click)="postForm()" status="primary" [nbSpinner]="isLoading"
                [disabled]="isLoading">
                {{'APASDISIFIRMAEKLE.KAYDET'|translate}}
            </button>
        </div>
        <!--Apas Dışı Bilgi-->
    </nb-card-body>
</nb-card>


<ng-template #bilgi>

    <!-- Bilgi -->
    <nb-alert outline="basic" style="font-weight: 400; max-width: 450px;" class="m-0">
        <div class="d-flex align-items-center m-3">
            <nb-icon class="mr-2" icon="info-outline" style="color: blue;"></nb-icon>
            <label class="label m-0" style="font-size: large;">Apas+ İle Taşeron Nasıl Eklenir ? </label>
        </div>
        <ul>
            <li style="margin-bottom: 8px;">
                <p class="mb-1">
                    {{'APASIDFIRMAEKLE.COKUZUNCUMLE1'|translate}}
                </p>
                <p class="mb-1">

                    {{'APASIDFIRMAEKLE.COKUZUNCUMLE2'|translate}}
                </p>
                <p class="mb-1">
                    {{'APASIDFIRMAEKLE.COKUZUNCUMLE3'|translate}}
                </p>
            </li>

            <li>
                <p class="mb-1">
                    {{'APASIDFIRMAEKLE.COKUZUNCUMLE4'|translate}}
                </p>
                <p class="mb-1">
                    {{'APASIDFIRMAEKLE.COKUZUNCUMLE5'|translate}}
                </p>
            </li>
        </ul>
    </nb-alert>
</ng-template>