
  <ng-select
      [items]="jobs$ | async"
      [loading]="isLoading"
      appendTo="body"
      typeToSearchText="Arama yapmak için en az 2 karakter yazınız."
      [minTermLength]="2"
      bindLabel="name"
      bindValue="id"
      [addTag]="addTagPromise"
      [addTagText]="'Yeni Meslek Ekle'"
      [multiple]="false"
      [selectOnTab]="false"
      [formControl]="control"
      [placeholder]="'JBOSELECT'|translate"
      class="uzun-select d-block"
      ngDefaultControl
  >

  </ng-select>