import { Component, OnInit, ViewChildren, QueryList, ViewEncapsulation, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {
  ApasImageUploaderComponent,
  ApasImageUploaderMethotType,
} from '../../../components/Apas-Modules/apas-image-uploader/apas-image-uploader.component';
import Swal from 'sweetalert2';
import { Store, select } from '@ngrx/store';

import * as userActions from '../../../store/user/user.actions';
import { DomSanitizer } from '@angular/platform-browser';
import OrgInfoModel from '../../../models/orgInfo.model';
import { prefixBase64 } from '../../../pages/constants';
import { updateOrgInfo } from '../../../store/organization/organization.actions';
import { selectUser } from '../../../store/user/user.connector';
export enum profilType {
  'User' = 'user',
  'Company' = 'company',
  'Personel' = 'personel',
}
@Component({
  selector: 'profil-photo',
  templateUrl: './profil-photo.component.html',
  styleUrls: ['./profil-photo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfilPhotoComponent implements OnInit {
  type: profilType = profilType.User;
  org_info: OrgInfoModel;
  apasId: number;
  @Input() image: any;
  @ViewChildren(ApasImageUploaderComponent) files!: QueryList<ApasImageUploaderComponent>;
  tag: string = 'profil-photo --->';
  currentFile: File;
  requestMethod = ApasImageUploaderMethotType.patch;
  url: string;
  prefixBase64 = prefixBase64;
  myKullanici;

  serializer = this.domSanitizer;
  constructor(private ref: NbDialogRef<ProfilPhotoComponent>,
    private domSanitizer: DomSanitizer,
    private store: Store<{}>) {



  }


  ngOnInit() {
    switch (this.type) {
      case profilType.User:
        this.url = 'kullanici/imageupdate/';
        break;
      case profilType.Company:
        this.url = 'organization/update/';
        break;
      case profilType.Personel:
        this.url = `organization/updatepersonel/${this.apasId}/`;
        break;
    }

    if (this.type === profilType.User) {
      this.store.pipe(select(selectUser)).subscribe(user => {
        this.myKullanici = user;
      });
    } else if (this.type === profilType.Company) {
      this.myKullanici = JSON.parse(localStorage.getItem('org'));
      this.myKullanici.image = this.myKullanici.image;
    } else if (this.type === profilType.Personel) {
      this.myKullanici = {};
      this.myKullanici.image = null;
    }

  }

  closed() {
    this.ref.close({ kullanici: this.myKullanici });
  }

  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.click();
  }
  /**
   * Resimler seçildikden sonra çalışan fonksiyon.
   * Seçilen resimleri daha önceden eklenen fotograf dizisine ekler.
   * başlangıcda liste de 0 tane eleman vardırç
   * @param currentFiles currentFiles: File[] = [] olarak tanımlanır.
   * @param event Seçilen dosyaların listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  openFileDialog(event) {
    // uyumsus dosyaların ismleri burda toplanacak.
    let ignores: string = '';
    this.currentFile = (event.target as HTMLInputElement).files[0];
    // eğer bu dosya resim ise onu kabulet
    if (this.currentFile.type.indexOf('image') !== 0) {
      // eğer resim değilse onu kabul etmez. ve ignores string listesine ekler.
      ignores += '<br><div style="padding: 5px;border-bottom: 1px solid #ebebeb;">' + this.currentFile.name + '</div>';
    }

    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (ignores.length > 0) {
      Swal.fire({
        icon: 'error', title: 'Lütfen sadece uyumlu resim dosyalarını seçiniz.',
        html: '<div style="color: #f27474;text-align: left !important;">'
          + ignores + '</div>', showConfirmButton: true,
      });
    }
  }
  /**
   * Dosya sistemden başarılı birşekilde gösterildiğinde tetiklenir.
   */
  successLoad() {
    // console.log(this.tag, 'loaded');
  }
  /**
   * Resim sunucuya başarılı bir şekilde yüklendiğinde çalışır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  success(event: ApasImageUploaderComponent) {
    if (this.type === profilType.User) {
      this.store.dispatch(userActions.loadUser());
      console.log(this.tag, 'Resim başarılı birşekilde yüklendi', event.respons);
      if (!this.files.some(f => f.isSuccess === false)) {
        Swal.fire({
          icon: 'success',
          title: 'İşlem başarılı',
          html: 'Tüm resimler başarılı birşekilde yüklendi',
          showConfirmButton: false,
          timer: 2000,

        }).then(() => this.closed());

      }
    } else if (this.type === profilType.Company) {
      if (event.respons.image) {
        event.respons.image = event.respons.image;
      }
      localStorage.setItem('org', JSON.stringify(event.respons));
      this.org_info.image = event.respons.image;
      this.store.dispatch(updateOrgInfo({ update: { image: event.respons.image } }));
    } else if (this.type === profilType.Personel) {
      this.ref.close({ response: event.respons.image });
    }
  }
  /**
   * Seçilen resmi listeden kaldırır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  remove(event) {
    // console.log(this.tag, 'Resim başarılı bir şekilde silindi:', event, 'index:', i);
    this.currentFile = null;
  }
  /**
   * Tümresimleri zaten yüklenmemiş olan tüm resimleri sunucuya yükler.
   */
  uploadAll() {
    if (this.files) {
      this.files.forEach(f => {

        if (!f.isSuccess) { f.upload(); }
      });

    } else {
      throw new Error('@ViewChild files bulunamadı.');
    }
  }

}
