
const es = {
    'january': 'Enero',
    'february': 'Febrero',
    'march': 'Marzo',
    'april': 'Abril',
    'maylong': 'Mayo',
    'june': 'Junio',
    'july': 'Julio',
    'august': 'Agosto',
    'september': 'Septiembre',
    'october': 'Octubre',
    'november': 'Noviembre',
    'december': 'Diciembre',
    'jan': 'Ene',
    'feb': 'Feb',
    'mar': 'Mar',
    'apr': 'Abr',
    'may': 'May',
    'jun': 'Jun',
    'jul': 'Jul',
    'aug': 'Ago',
    'sep': 'Sep',
    'oct': 'Oct',
    'nov': 'Nov',
    'dec': 'Dic',
    'sunday': 'Domingo',
    'monday': 'Lunes',
    'tuesday': 'Martes',
    'wednesday': 'Miércoles',
    'thursday': 'Jueves',
    'friday': 'Viernes',
    'saturday': 'Sábado',
    'sun': '	Dom',
    'mon': '	Lun',
    'tue': '	Mar',
    'wed': '	Mie',
    'thu': '	Jue',
    'fri': '	Vie',
    'sat': '	Sab',
    'res': 'Recurso',
    'dur': 'Duración',
    'comp': '% Compl.',
    'completion': 'Completado',
    'startdate': 'Inicio',
    'addentries': 'Add Entries',

    'planstartdate': 'Inicio Planificado',
    'cost': 'Coste',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'enddate': 'Fin',
    'planenddate': 'Fin Planificado',
    'moreinfo': 'Más Información',
    'nodata': 'No tasks found',
    'notes': 'Notas',
    'format': 'Formato',
    'hour': 'Hora',
    'day': 'Día',
    'week': 'Semana',
    'month': 'Mes',
    'quarter': 'Trimestre',
    'hours': 'Horas',
    'days': 'Días',
    'weeks': 'Semanas',
    'months': 'Meses',
    'quarters': 'Trimestres',
    'hr': 'h',
    'dy': 'Día',
    'wk': 'Sem.',
    'mth': 'Mes',
    'qtr': 'Trim.',
    'hrs': 'h',
    'dys': 'Días',
    'wks': 'Sem.',
    'mths': 'Meses',
    'qtrs': 'Trim.',
    'tooltipLoading': 'Cargando...'
  };
  
  const en = {
    'format': 'Format',
    'hour': 'Hour',
    'day': 'Day',
    'week': 'Week',
    'month': 'Month',
    'quarter': 'Quarter',
    'hours': 'Hours',
    'days': 'Days',
    'weeks': 'Weeks',
    'months': 'Months',
    'quarters': 'Quarters',
    'hr': 'Hr',
    'dy': 'Day',
    'wk': 'Wk',
    'mth': 'Mth',
    'qtr': 'Qtr',
    'hrs': 'Hrs',
    'dys': 'Days',
    'wks': 'Wks',
    'mths': 'Mths',
    'qtrs': 'Qtrs',
    'res': 'Resource',
    'dur': 'Duration',
    'comp': '% Comp.',
    'completion': 'Completion',
    'startdate': 'Start Date',
    'addentries': 'Add Entries',

    'planstartdate': 'Plan Start Date',
    'enddate': 'End Date',
    'planenddate': 'Plan End Date',
    'cost': 'Cost',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'More Information',
    'nodata': 'No tasks found',
    'notes': 'Notes',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'maylong': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'jan': 'Jan',
    'feb': 'Feb',
    'mar': 'Mar',
    'apr': 'Apr',
    'may': 'May',
    'jun': 'Jun',
    'jul': 'Jul',
    'aug': 'Aug',
    'sep': 'Sep',
    'oct': 'Oct',
    'nov': 'Nov',
    'dec': 'Dec',
    'sunday': 'Sunday',
    'monday': 'Monday',
    'tuesday': 'Tuesday',
    'wednesday': 'Wednesday',
    'thursday': 'Thursday',
    'friday': 'Friday',
    'saturday': 'Saturday',
    'sun': 'Sun',
    'mon': 'Mon',
    'tue': 'Tue',
    'wed': 'Wed',
    'thu': 'Thu',
    'fri': 'Fri',
    'sat': 'Sat',
    'tooltipLoading': 'Loading...'
  }
  
  const de = {
    'format': 'Ansicht',
    'hour': 'Stunde',
    'day': 'Tag',
    'week': 'Woche',
    'month': 'Monat',
    'quarter': 'Quartal',
    'hours': 'Stunden',
    'days': 'Tage',
    'weeks': 'Wochen',
    'months': 'Monate',
    'quarters': 'Quartale',
    'hr': 'h',
    'dy': 'T',
    'wk': 'W',
    'mth': 'M',
    'qtr': 'Q',
    'hrs': 'Std',
    'dys': 'Tage',
    'wks': 'Wochen',
    'mths': 'Monate',
    'qtrs': 'Quartal',
    'res': 'Resource',
    'addentries': 'Add Entries',
    'dur': 'Dauer',
    'comp': '%Fertig',
    'completion': 'Fertigstellung',
    'startdate': 'Erste Buchu',
    'planstartdate': 'Erste Buchu Plan',
    'enddate': 'Letzte Buchung',
    'planenddate': 'Plan Letzte Buchung',
    'cost': 'Cost',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Weitere Infos',
    'nodata': 'No tasks found',
    'notes': 'Anmerkung',
    'january': 'Jänner',
    'february': 'Februar',
    'march': 'März',
    'april': 'April',
    'maylong': 'Mai',
    'june': 'Juni',
    'july': 'Juli',
    'august': 'August',
    'september': 'September',
    'october': 'Oktober',
    'november': 'November',
    'december': 'Dezember',
    'jan': 'Jan',
    'feb': 'Feb',
    'mar': 'Mar',
    'apr': 'Apr',
    'may': 'Mai',
    'jun': 'Jun',
    'jul': 'Jul',
    'aug': 'Aug',
    'sep': 'Sep',
    'oct': 'Okt',
    'nov': 'Nov',
    'dec': 'Dez',
    'sunday': 'Sonntag',
    'monday': 'Montag',
    'tuesday': 'Dienstag',
    'wednesday': 'Mittwoch',
    'thursday': 'Donnerstag',
    'friday': 'Freitag',
    'saturday': 'Samstag',
    'sun': 'So',
    'mon': 'Mo', 'tue': 'Di', 'wed': 'Mi', 'thu': 'Do', 'fri': 'Fr', 'sat': 'Sa'
  }
  
  const pt = {
    'hours': 'Horas',
    'days': 'Dias',
    'weeks': 'Weeks',
    'months': 'Months',
    'quarters': 'Quarters',
    'format': 'Formato',
    'hour': 'Hora',
    'day': 'Dia',
    'week': 'Semana',
    'month': 'Mês',
    'quarter': 'Trimestre',
    'hr': 'hr',
    'dy': 'dia',
    'wk': 'sem.',
    'mth': 'mês',
    'qtr': 'qtr',
    'hrs': 'hrs',
    'dys': 'dias',
    'wks': 'sem.',
    'mths': 'meses',
    'qtrs': 'qtrs',
    'completion': 'Terminado',
    'comp': '% Completado',
    'moreinfo': 'Mais informações',
    'nodata': 'Sem atividades',
    'notes': 'Notas',
    'res': 'Responsável',
    'dur': 'Duração',
    'startdate': 'Data inicial',
    'addentries': 'Add Entries',

    'planstartdate': 'Plan Data inicial',
    'enddate': 'Data final',
    'planenddate': 'Plan Data final',
    'cost': 'Custo',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'jan': 'Jan',
    'feb': 'Fev',
    'mar': 'Mar',
    'apr': 'Abr',
    'may': 'Mai',
    'jun': 'Jun',
    'jul': 'Jul',
    'aug': 'Ago',
    'sep': 'Set',
    'oct': 'Out',
    'nov': 'Nov',
    'dec': 'Dez',
    'january': 'Janeiro',
    'february': 'Fevereiro',
    'march': 'Março',
    'april': 'Abril',
    'maylong': 'Maio',
    'june': 'Junho',
    'july': 'Julho',
    'august': 'Agosto',
    'september': 'Setembro',
    'october': 'Outubro',
    'november': 'Novembro',
    'december': 'Dezembro',
    'sun': 'Dom',
    'mon': 'Seg',
    'tue': 'Ter',
    'wed': 'Qua',
    'thu': 'Qui',
    'fri': 'Sex',
    'sat': 'Sab'
  }
  
  const ru = {
    'january': 'Январь',
    'february': 'Февраль',
    'march': 'Март',
    'april': 'Апрель',
    'maylong': 'Май',
    'june': 'Июнь',
    'july': 'Июль',
    'august': 'Август', 'september': 'Сентябрь',
    'october': 'Октябрь',
    'november': 'Ноябрь',
    'december': 'Декабрь',
    'jan': 'Янв',
    'feb': 'Фев',
    'mar': 'Мар',
    'apr': 'Апр',
    'may': 'Май',
    'jun': 'Июн',
    'jul': 'Июл',
    'aug': 'Авг',
    'sep': 'Сен',
    'oct': 'Окт',
    'nov': 'Ноя',
    'dec': 'Дек',
    'sunday': 'Воскресенье',
    'monday': 'Понедельник',
    'tuesday': 'Вторник',
    'wednesday': 'Среда',
    'thursday': 'Четверг',
    'friday': 'Пятница',
    'saturday': 'Суббота',
    'sun': '	Вс',
    'mon': '	Пн',
    'tue': '	Вт',
    'wed': '	Ср',
    'thu': '	Чт',
    'fri': '	Пт',
    'sat': '	Сб',
    'res': 'Ресурс',
    'dur': 'Длительность',
    'comp': '% выполнения',
    'completion': 'Выполнено',
    'startdate': 'Нач. дата',
    'addentries': 'Add Entries',

    'planstartdate': 'Plan Нач. дата',
    'enddate': 'Кон. дата',
    'planenddate': 'Plan Кон. дата',
    'cost': 'Cost',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Детали',
    'nodata': 'No tasks found',
    'notes': 'Заметки',
    'format': 'Формат',
    'hour': 'Час',
    'day': 'День',
    'week': 'Неделя',
    'month': 'Месяц',
    'quarter': 'Кварт',
    'hours': 'Часов',
    'days': 'Дней',
    'weeks': 'Недель',
    'months': 'Месяцев',
    'quarters': 'Кварталов',
    'hr': 'ч.',
    'dy': 'дн.',
    'wk': 'нед.',
    'mth': 'мес.',
    'qtr': 'кв.',
    'hrs': 'ч.',
    'dys': 'дн.',
    'wks': 'нед.',
    'mths': 'мес.',
    'qtrs': 'кв.',
    'tooltipLoading': 'Загрузка...'
  }
  
  /**
   * Mois : http://bdl.oqlf.gouv.qc.ca/bdl/gabarit_bdl.asp?id=3619
     Jours : http://bdl.oqlf.gouv.qc.ca/bdl/gabarit_bdl.asp?id=3617
   */
  const fr = {
    'january': 'Janvier',
    'february': 'Février',
    'march': 'Mars',
    'april': 'Avril',
    'maylong': 'Mai',
    'june': 'Juin',
    'july': 'Juillet',
    'august': 'Août',
    'september': 'Septembre',
    'october': 'Octobre',
    'november': 'Novembre',
    'december': 'Décembre',
    'jan': 'Janv',
    'feb': 'Févr',
    'mar': 'Mars',
    'apr': 'Avr',
    'may': 'Mai',
    'jun': 'Juin',
    'jul': 'Juil',
    'aug': 'Août',
    'sep': 'Sept',
    'oct': 'Oct',
    'nov': 'Nov',
    'dec': 'Déc',
    'sunday': 'Dimanche',
    'monday': 'Lundi',
    'tuesday': 'Mardi',
    'wednesday': 'Mercredi',
    'thursday': 'Jeudi',
    'friday': 'Vendredi',
    'saturday': 'Samedi',
    'sun': 'Dim',
    'mon': 'Lun',
    'tue': 'Mar',
    'wed': 'Mer',
    'thu': 'Jeu',
    'fri': 'Ven',
    'sat': 'Sam',
    'res': 'Ressource',
    'dur': 'Durée',
    'comp': '% Term.',
    'completion': 'Terminé',
    'startdate': 'Début',
    'addentries': 'Add Entries',

    'planstartdate': 'Plan Début',
    'enddate': 'Fin',
    'planenddate': 'Plan Fin',
    'cost': 'Cost',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': "Plus d'informations",
    'nodata': 'No tasks found',
    'notes': 'Notes',
    'format': 'Format',
    'hour': 'Heure',
    'day': 'Jour',
    'week': 'Semaine',
    'month': 'Mois',
    'quarter': 'Trimestre',
    'hours': 'Heures',
    'days': 'Jours',
    'weeks': 'Semaines',
    'months': 'Mois',
    'quarters': 'Trimestres',
    'hr': 'h',
    'dy': 'j',
    'wk': 'sem',
    'mth': 'mois',
    'qtr': 'tri',
    'hrs': 'h',
    'dys': 'j',
    'wks': 'sem',
    'mths': 'mois',
    'qtrs': 'tri'
  }
  
  const cn = {
    'january': '一月',
    'february': '二月',
    'march': '三月',
    'april': '四月',
    'maylong': '五月',
    'june': '六月',
    'july': '七月',
    'august': '八月',
    'september': '九月',
    'october': '十月',
    'november': '十一月',
    'december': '十二月',
    'jan': '一月',
    'feb': '二月',
    'mar': '三月',
    'apr': '四月',
    'may': '五月',
    'jun': '六月',
    'jul': '七月',
    'aug': '八月',
    'sep': '九月',
    'oct': '十月',
    'nov': '十一月',
    'dec': '十二月',
    'sunday': '星期日',
    'monday': '星期一',
    'tuesday': '星期二',
    'wednesday': '星期三',
    'thursday': '星期四',
    'friday': '星期五',
    'saturday': '星期六',
    'sun': '星期日',
    'mon': '星期一',
    'tue': '星期二',
    'wed': '星期三',
    'thu': '星期四',
    'fri': '星期五',
    'sat': '星期六',
    'res': '資源',
    'dur': '時程',
    'comp': '達成率',
    'completion': '達成',
    'startdate': '起始日期',
    'addentries': 'Add Entries',

    'planstartdate': '計劃起始日期',
    'enddate': '截止日期',
    'planenddate': '計劃截止日期',
    'cost': '成本',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': "更多資訊",
    'nodata': 'No tasks found',
    'notes': '備註',
    'format': '格式',
    'hour': '時',
    'day': '日',
    'week': '星期',
    'month': '月',
    'quarter': '季',
    'hours': '小時',
    'days': '天',
    'weeks': '週',
    'months': '月',
    'quarters': '季',
    'hr': '小時',
    'dy': '天',
    'wk': '週',
    'mth': '月',
    'qtr': '季',
    'hrs': '小時',
    'dys': '天',
    'wks': '週',
    'mths': '月',
    'qtrs': '季'
  }
  
  const sv = {
    'format': 'Filter',
    'hour': 'Timme',
    'day': 'Dag',
    'week': 'Vecka',
    'month': 'Månad',
    'quarter': 'Kvartal',
    'hours': 'Timmar',
    'days': 'Dagar',
    'weeks': 'Veckor',
    'months': 'Månader',
    'quarters': 'Kvartal',
    'hr': 'Timme',
    'dy': 'Dag',
    'wk': 'Vecka',
    'mth': 'Månad',
    'qtr': 'Q',
    'hrs': 'Timmar',
    'dys': 'Dagar',
    'wks': 'Veckor',
    'mths': 'Månader',
    'qtrs': 'Q',
    'res': 'Resurs',
    'dur': 'Tidsåtgång',
    'comp': '% klart',
    'completion': 'Klart',
    'startdate': 'Startdatum',
    'addentries': 'Add Entries',

    'planstartdate': 'Planerad startdatum',
    'enddate': 'Slutdatum',
    'planenddate': 'Planerad slutdatum',
    'cost': 'Kostnad',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Mer Information',
    'nodata': 'No tasks found',
    'notes': 'Notes',
    'january': 'januari',
    'february': 'februari',
    'march': 'mars',
    'april': 'april',
    'maylong': 'maj',
    'june': 'juni',
    'july': 'juli',
    'august': 'augusti',
    'september': 'september',
    'october': 'oktober',
    'november': 'november',
    'december': 'december',
    'jan': 'jan',
    'feb': 'feb',
    'mar': 'mar',
    'apr': 'apr',
    'may': 'maj',
    'jun': 'jun',
    'jul': 'jul',
    'aug': 'aug',
    'sep': 'sep',
    'oct': 'okt',
    'nov': 'nov',
    'dec': 'dec',
    'sunday': 'söndag',
    'monday': 'måndag',
    'tuesday': 'tisdag',
    'wednesday': 'onsdag',
    'thursday': 'torsdag',
    'friday': 'fredag',
    'saturday': 'lördag',
    'sun': 'sön',
    'mon': 'mån',
    'tue': 'tis',
    'wed': 'ons',
    'thu': 'tor',
    'fri': 'fre',
    'sat': 'lör'
  }
  
  const nl = {
    'format': 'Format',
    'hour': 'Uur',
    'day': 'Dag',
    'week': 'Week',
    'month': 'Maand',
    'quarter': 'Kwartaal',
    'hours': 'Uren',
    'days': 'Dagen',
    'weeks': 'Weken',
    'months': 'Maanden',
    'quarters': 'Kwartalen',
    'hr': 'uur',
    'dy': 'dag',
    'wk': 'wk',
    'mth': 'mnd',
    'qtr': 'kw',
    'hrs': 'uren',
    'dys': 'dagen',
    'wks': 'weken',
    'mths': 'maanden',
    'qtrs': 'kwartalen',
    'res': 'Resource',
    'dur': 'Doorlooptijd',
    'comp': '% gereed',
    'completion': 'Gereed',
    'startdate': 'Startdatum',
    'addentries': 'Add Entries',

    'planstartdate': 'Geplande startdatum',
    'enddate': 'Einddatum',
    'planenddate': 'Geplande einddatum',
    'cost': 'Kosten',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Meer informatie',
    'nodata': 'No tasks found',
    'notes': 'Notities',
    'january': 'januari',
    'february': 'februari',
    'march': 'maart',
    'april': 'april',
    'maylong': 'mei',
    'june': 'juni',
    'july': 'juli',
    'august': 'augustus',
    'september': 'september',
    'october': 'oktober',
    'november': 'november',
    'december': 'december',
    'jan': 'jan',
    'feb': 'feb',
    'mar': 'mrt',
    'apr': 'apr',
    'may': 'mei',
    'jun': 'jun',
    'jul': 'jul',
    'aug': 'aug',
    'sep': 'sep',
    'oct': 'okt',
    'nov': 'nov',
    'dec': 'dec',
    'sunday': 'zondag',
    'monday': 'maandag',
    'tuesday': 'dinsdag',
    'wednesday': 'woensdag',
    'thursday': 'donderdag',
    'friday': 'vrijdag',
    'saturday': 'zaterdag',
    'sun': 'zo',
    'mon': 'ma',
    'tue': 'di',
    'wed': 'wo',
    'thu': 'do',
    'fri': 'vr',
    'sat': 'za'
  }
  
  const id = {
    'format': 'Format',
    'hour': 'Jam',
    'day': 'Hari',
    'week': 'Minggu',
    'month': 'Bulan',
    'quarter': 'Kuartal',
    'hours': 'Jam',
    'days': 'Hari',
    'weeks': 'Minggu',
    'months': 'Bulan',
    'quarters': 'Kuartal',
    'hr': 'Jam',
    'dy': 'Hari',
    'wk': 'Min',
    'mth': 'Bln',
    'qtr': 'Krtl',
    'hrs': 'Jam',
    'dys': 'Hari',
    'wks': 'Min',
    'mths': 'Bln',
    'qtrs': 'Krtl',
    'res': 'Sumber Daya',
    'dur': 'Durasi',
    'comp': '% Penyelesaian',
    'completion': 'Penyelesaian',
    'startdate': 'Tanggal Mulai',
    'addentries': 'Add Entries',

    'planstartdate': 'Perencanaan Tanggal Mulai',
    'enddate': 'Tanggal Akhir',
    'planenddate': 'Perencanaan Tanggal Akhir',
    'cost': 'Biaya',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Informasi Lebih Lanjut',
    'nodata': 'No tasks found',
    'notes': 'Catatan',
    'january': 'Januari',
    'february': 'Februari',
    'march': 'Maret',
    'april': 'April',
    'maylong': 'Mei',
    'june': 'Juni',
    'july': 'Juli',
    'august': 'Agustus',
    'september': 'September',
    'october': 'Oktober',
    'november': 'November',
    'december': 'Desember',
    'jan': 'Jan',
    'feb': 'Feb',
    'mar': 'Mar',
    'apr': 'Apr',
    'may': 'Mei',
    'jun': 'Jun',
    'jul': 'Jul',
    'aug': 'Agu',
    'sep': 'Sep',
    'oct': 'Okt',
    'nov': 'Nov',
    'dec': 'Des',
    'sunday': 'Minggu',
    'monday': 'Senin',
    'tuesday': 'Selasa',
    'wednesday': 'Rabu',
    'thursday': 'Kamis',
    'friday': 'Jumat',
    'saturday': 'Sabtu',
    'sun': 'Min',
    'mon': 'Sen',
    'tue': 'Sel',
    'wed': 'Rab',
    'thu': 'Kam',
    'fri': 'Jum',
    'sat': 'Sab'
  }
  
  const tr =
  {
    'format': 'Biçim',
    'hour': 'Saat',
    'day': 'Gün',
    'week': 'Hafta',
    'month': 'Ay',
    'quarter': 'Çeyrek Yıl',
    'hours': 'Saat',
    'days': 'Gün',
    'weeks': 'Hafta',
    'months': 'Ay',
    'quarters': 'Çeyrek Yıl',
    'hr': 'Saat',
    'dy': 'Gün',
    'wk': 'Hft',
    'mth': 'Ay',
    'qtr': 'Çyrk',
    'hrs': 'Saat',
    'dys': 'Gün',
    'wks': 'Hft',
    'mths': 'Ay',
    'qtrs': 'Çyrk',
    'res': 'Kaynak',
    'dur': 'Süre',
    'comp': '% Tamamlanma.',
    'completion': 'Tamamlanma',
    'startdate': 'Başlangıç Tarihi',
    'addentries': 'İşlemler',

    'planstartdate': 'Plan Başlama Tarihi',
    'enddate': 'Bitiş Tarihi',
    'planenddate': 'Plan Bitiş Tarihi',
    'cost': 'Tutar',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Daha Fazla Bilgi',
    'nodata': 'No tasks found',
    'notes': 'Notlar',
    'january': 'Ocak',
    'february': 'Şubat',
    'march': 'Mart',
    'april': 'Nisan',
    'maylong': 'Mayıs',
    'june': 'Haziran',
    'july': 'Temmuz',
    'august': 'Ağustos',
    'september': 'Eylül',
    'october': 'Ekim',
    'november': 'Kasım',
    'december': 'Aralık',
    'jan': 'Oca',
    'feb': 'Şub',
    'mar': 'Mar',
    'apr': 'Nis',
    'may': 'May',
    'jun': 'Haz',
    'jul': 'Tem',
    'aug': 'Ağu',
    'sep': 'Eyl',
    'oct': 'Eki',
    'nov': 'Kas',
    'dec': 'Ara',
    'sunday': 'Pazar',
    'monday': 'Pazartesi',
    'tuesday': 'Salı',
    'wednesday': 'Çarşamba',
    'thursday': 'Perşembe',
    'friday': 'Cuma',
    'saturday': 'Cumartesi',
    'sun': 'Paz',
    'mon': 'Pzt',
    'tue': 'Sal',
    'wed': 'Çrş',
    'thu': 'Prş',
    'fri': 'Cum',
    'sat': 'Cmt'
  }
  
  const ja =
  {
    'format': 'タイムライン表示',
    'hour': '時',
    'day': '日',
    'week': '週',
    'month': '月',
    'quarter': '四半期',
    'hours': '時間',
    'days': '日間',
    'weeks': '週間',
    'months': '月間',
    'quarters': '四半期',
    'hr': '時',
    'dy': '日',
    'wk': '週',
    'mth': '月',
    'qtr': '四',
    'hrs': '時間',
    'dys': '日間',
    'wks': '週間',
    'mths': '月間',
    'qtrs': '四半期',
    'res': 'リソース',
    'dur': '期間',
    'comp': '進捗率',
    'completion': '進捗率',
    'startdate': '開始日',
    'addentries': 'Add Entries',

    'planstartdate': '予定開始日',
    'enddate': '期日',
    'planenddate': '予定期日',
    'cost': 'コスト',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': '詳細',
    'nodata': 'No tasks found',
    'notes': 'ノート',
    'january': '1月',
    'february': '2月',
    'march': '3月',
    'april': '4月',
    'maylong': '5月',
    'june': '6月',
    'july': '7月',
    'august': '8月',
    'september': '9月',
    'october': '10月',
    'november': '11月',
    'december': '12月',
    'jan': '1月',
    'feb': '2月',
    'mar': '3月',
    'apr': '4月',
    'may': '5月',
    'jun': '6月',
    'jul': '7月',
    'aug': '8月',
    'sep': '9月',
    'oct': '10月',
    'nov': '11月',
    'dec': '12月',
    'sunday': '日曜日',
    'monday': '月曜日',
    'tuesday': '火曜日',
    'wednesday': '水曜日',
    'thursday': '木曜日',
    'friday': '金曜日',
    'saturday': '土曜日',
    'sun': '日',
    'mon': '月',
    'tue': '火',
    'wed': '水',
    'thu': '木',
    'fri': '金',
    'sat': '土',
    'tooltipLoading': 'ローディング中...'
  }
  
  const cs = {
    'format': 'Zobrazení',
    'hour': 'Hodina',
    'day': 'Den',
    'week': 'Týden',
    'month': 'Měsíc',
    'quarter': 'Kvartál',
    'hours': 'Hodiny',
    'days': 'Dni',
    'weeks': 'Týdny',
    'months': 'Měsíce',
    'quarters': 'Kvartály',
    'hr': 'Ho',
    'dy': 'Den',
    'wk': 'Tyd',
    'mth': 'Měs',
    'qtr': 'Kvar',
    'hrs': 'Ho',
    'dys': 'Dni',
    'wks': 'Tyd',
    'mths': 'Měs',
    'qtrs': 'Kvar',
    'res': 'Přiřazeno',
    'dur': 'Trvání',
    'comp': '% Hotovo',
    'completion': 'Hotovo',
    'startdate': 'Start',
    'addentries': 'Add Entries',

    'planstartdate': 'Plánovaný start',
    'enddate': 'Konec',
    'planenddate': 'Plánovaný konec',
    'cost': 'Náklady',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'Více informací',
    'nodata': 'No tasks found',
    'notes': 'Poznámky',
    'january': 'Leden',
    'february': 'Únor',
    'march': 'Březen',
    'april': 'Duben',
    'maylong': 'Květen',
    'june': 'Červen',
    'july': 'Červenec',
    'august': 'Srpen',
    'september': 'Září',
    'october': 'Říjen',
    'november': 'Listopad',
    'december': 'Prosinec',
    'jan': 'Led',
    'feb': 'Úno',
    'mar': 'Bře',
    'apr': 'Dub',
    'may': 'Kvě',
    'jun': 'Čer',
    'jul': 'Čvc',
    'aug': 'Srp',
    'sep': 'Zář',
    'oct': 'Říj',
    'nov': 'Lis',
    'dec': 'Pro',
    'sunday': 'Neděle',
    'monday': 'Pondělí',
    'tuesday': 'Úterý',
    'wednesday': 'Středa',
    'thursday': 'Čtvrtek',
    'friday': 'Pátek',
    'saturday': 'Sobota',
    'sun': 'Ne',
    'mon': 'Po',
    'tue': 'Út',
    'wed': 'St',
    'thu': 'Čt',
    'fri': 'Pa',
    'sat': 'So',
    'tooltipLoading': 'Nahrávám...'
  }
  
  const hu =
  {
    'format': 'Formátum',
    'hour': 'Óra',
    'day': 'Nap',
    'week': 'Hét',
    'month': 'Hónap',
    'quarter': 'Negyedév ',
    'hours': 'Órák',
    'days': 'Nap',
    'weeks': 'Hét',
    'months': 'Hónap',
    'quarters': 'Negyedév',
    'hr': 'Ó',
    'dy': 'Nap',
    'wk': 'Hét',
    'mth': 'Hó',
    'qtr': 'NÉ',
    'hrs': 'Óra',
    'dys': 'Nap',
    'wks': 'Hét',
    'mths': 'Hó',
    'qtrs': 'NÉ',
    'res': 'Erőforrás',
    'dur': 'Időtartam',
    'comp': '% Kész',
    'completion': 'Elkészült',
    'startdate': 'Kezdés',
    'addentries': 'Add Entries',

    'planstartdate': 'Tervezett kezdés',
    'enddate': 'Befejezés',
    'planenddate': 'Tervezett befejezés',
    'cost': 'Költség',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': 'További információ',
    'nodata': 'No tasks found',
    'notes': 'Jegyzetek',
    'january': 'Január',
    'february': 'Február',
    'march': 'Március',
    'april': 'Április',
    'maylong': 'Május',
    'june': 'Június',
    'july': 'Július',
    'august': 'Augusztus',
    'september': 'Szeptember',
    'october': 'Október',
    'november': 'November',
    'december': 'December',
    'jan': 'Jan',
    'feb': 'Feb',
    'mar': 'Már',
    'apr': 'Ápr',
    'may': 'Máj',
    'jun': 'Jún',
    'jul': 'Júl',
    'aug': 'Aug',
    'sep': 'Szep',
    'oct': 'Okt',
    'nov': 'Nov',
    'dec': 'Dec',
    'sunday': 'Vasárnap',
    'monday': 'Hétfő',
    'tuesday': 'Kedd',
    'wednesday': 'Szerda',
    'thursday': 'Csütörtök',
    'friday': 'Péntek',
    'saturday': 'Szombat',
    'sun': 'Vas',
    'mon': 'Hé',
    'tue': 'Ke',
    'wed': 'Sze',
    'thu': 'Csü',
    'fri': 'Pén',
    'sat': 'Szo',
    'tooltipLoading': 'Belöltés...'
  }
  
  var ko = {
    'format': '구분',
    'hour': '시',
    'day': '일',
    'week': '주',
    'month': '월',
    'quarter': '분기',
    'hours': '시',
    'days': '일',
    'weeks': '주',
    'months': '월',
    'quarters': '분기',
    'hr': '시',
    'dy': '일',
    'wk': '주',
    'mth': '월',
    'qtr': '분기',
    'hrs': '시',
    'dys': '일',
    'wks': '주',
    'mths': '월',
    'qtrs': '분기',
    'res': '이름',
    'dur': '기간',
    'comp': '% ',
    'completion': '완료',
    'startdate': '시작일자',
    'addentries': 'Add Entries',

    'planstartdate': '계획 시작일자',
    'enddate': '종료일자',
    'planenddate': '계획 종료일자',
    'cost': '비용',
    'metraj': 'Metraj',
    'pursantaj': 'Pursantaj',
    'moreinfo': '더 많은 정보',
    'nodata': 'No tasks found',
    'notes': '비고',
    'january': '1월',
    'february': '2월',
    'march': '3월',
    'april': '4월',
    'maylong': '5월',
    'june': '6월',
    'july': '7월',
    'august': '8월',
    'september': '9월',
    'october': '10월',
    'november': '11월',
    'december': '12월',
    'jan': '1',
    'feb': '2',
    'mar': '3',
    'apr': '4',
    'may': '5',
    'jun': '6',
    'jul': '7',
    'aug': '8',
    'sep': '9',
    'oct': '10',
    'nov': '11',
    'dec': '12',
    'sunday': '일요일',
    'monday': '월요일',
    'tuesday': '화요일',
    'wednesday': '수요일',
    'thursday': '목요일',
    'friday': '금요일',
    'saturday': '토요일',
    'sun': '일',
    'mon': '월',
    'tue': '화',
    'wed': '수',
    'thu': '목',
    'fri': '금',
    'sat': '토',
    'tooltipLoading': '로딩중...'
  }
  
  export { en, es, de, pt, fr, ru, cn, sv, nl, id, tr, ja, cs, hu, ko }