import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'yapilan-isler-modal',
  templateUrl: './yapilan-isler-modal.component.html',
  styleUrls: ['./yapilan-isler-modal.component.css']
})
export class YapilanIslerModalComponent implements OnInit {

  santiye_id;

  constructor(private ref: NbDialogRef<YapilanIslerModalComponent>) { }

  ngOnInit() {
    console.log('Yapılan İşler Modal Şantiye İd', this.santiye_id);
  }
  closed() {
    this.ref.close();
  }

}
