import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cariHareketTypes, responseKodlari } from '../../../../../../constants';
import { NbDialogRef } from '@nebular/theme';
import { removeAllNull } from '../../../../../../global-functions';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import {ApasEncryptePipe} from '../../../../../../../directives/apas-encrypte-pipe';
import { MuhasebeService } from '../../../../../muhasebe-service/muhasebe.service';


@Component({
  selector: 'apx-taksit-duzenle',
  templateUrl: './taksit-duzenle.component.html',
  styleUrls: ['./taksit-duzenle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaksitDuzenleComponent implements OnInit {
  data;
  form: FormGroup;
  isLoading = false;
  constructor(
    private ref: NbDialogRef<TaksitDuzenleComponent>,
    private fb: FormBuilder,
    private muhasebeService: MuhasebeService,
    private encryptePipe: ApasEncryptePipe,
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.data) {
      this.valueToFormSet();
    }
  }

  valueToFormSet() {
    this.form.patchValue(this.data);
  }

  initForm() {
    this.form = this.fb.group({
      total_price: [null, Validators.required],
      date: [new Date(), Validators.required],
      note: [],
    });
  }

  close(refresh: boolean = false) {
    this.ref.close(refresh);
  }

  async patch() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('form not valid : ', this.form);
      return;
    }

    const swalMessage: SweetAlertOptions = {
      icon: 'question',
      title: 'Onaylıyor Musunuz?',
      html: `Yaptığınız fiyat değişiklikleri bu taksitin dahil olduğu ödeme planının borç miktarını güncelleyecek, ilgili carinin toplam borç miktarını değiştirecektir.
      Bu değişikliği </br><b> Onaylıyor Musunuz? </b>`,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'Evet',
    };

    const swalStatus = await Swal.fire(swalMessage);
    if (!swalStatus.isConfirmed) return;

    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    formVal.total_price = this.encryptePipe.transform(formVal.total_price);
    if (
      this.data.hareket_type === cariHareketTypes.pesinat ||
      this.data.hareket_type === cariHareketTypes.odenmisPesinat ||

      this.data.hareket_type === cariHareketTypes.odemeTaksit ||
      this.data.hareket_type === cariHareketTypes.odemeTaksitTahsilat ||

      this.data.hareket_type === cariHareketTypes.araOdeme ||
      this.data.hareket_type === cariHareketTypes.araOdeneOdenmis ||

      this.data.hareket_type === cariHareketTypes.sonOdeme ||
      this.data.hareket_type === cariHareketTypes.odenmisSonOdeme
    ) {
      formVal['must_pay_price'] = formVal.total_price;
      delete formVal.total_price;
      formVal['must_pay_date'] = formVal.date;
      delete formVal.date;
    }

    this.muhasebeService.updateCariHareket(this.data.id, formVal).subscribe(resp => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(true);
      this.isLoading = false;
    }, (err) => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      console.log('error : ', err);
      this.isLoading = false;
    });
  }

}
