import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NguCarouselConfig } from '@ngu/carousel';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { YapilanIslerAddPhotoComponent, NoteType } from '../yapilan-isler-add-photo/yapilan-isler-add-photo.component';
import { ApasImageLightboxComponent } from '../../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';

@Component({
  selector: 'yapilan-isler-detay',
  templateUrl: './yapilan-isler-detay.component.html',
  styleUrls: ['./yapilan-isler-detay.component.css'],
  providers: [YapilanIslerService],
})


export class YapilanIslerDetayComponent implements OnInit {

  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 5, all: 0 },
    speed: 500,
    point: {
      visible: true,
    },
    touch: true,
    loop: true,
    interval: { timing: 7500 },
    animation: 'lazy',
  };
  tempData: any[];

  yapilanIs;
  tarih_format = tarih_format;
  edit: boolean = false;
  imalatCinsi;
  form: FormGroup;
  isLoading: boolean = false;

  constructor(private ref: NbDialogRef<YapilanIslerDetayComponent>,
    private nbDialogService: NbDialogService,
    private yapilanIslerService: YapilanIslerService,
    private fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit() {
    if (this.yapilanIs.edit) {
      this.editY();
    }
    this.yapilanIsList();
  }

  closed(resp?) {
    this.ref.close(resp);
  }
  initForm() {
    this.form = this.fb.group({
      id: [],
      date: [],
      pursantaj_id: [],
      work_done_percentage: [],
      tamamlanan_metraj: [],
      desc: [],
    });
  }

  yapilanIsList() {
    this.isLoading = true;
    this.yapilanIslerService.getDetay(this.yapilanIs.id).subscribe(resp => {
      this.yapilanIs = resp;
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  openImageDetail(photo, photos, photo_count, photo_index) {

    const context: {} = {
      url: `yapilanisler/${photo.object_id}/note/detail`,
      // 'url': 'santiye/imagedetay/' + this.santiyeId + '/' + imageId + '/',
      image: photo,
      imageIndex: photo_index,
      groupIndex: 0,
      method: 'post',
      rows: [{ images: photos }],
    };
    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }

  editY() {
    this.edit = !this.edit;
    if (this.edit) {
      this.yapilanIslerService.getYapilanIsPursantaj(this.yapilanIs.santiye_id).subscribe((data) => {
        this.imalatCinsi = data;
      });
      this.form.patchValue(this.yapilanIs);
    }
  }

  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formVal = removeAllNull(this.form.value);

    this.yapilanIslerService.updateYapilanIs(this.yapilanIs.id, formVal).subscribe(resp => {
      this.yapilanIs = resp;
      this.edit = !this.edit;
      this.closed(true);
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    });
  }
  deleteYapilanIs() {

    const text = `${this.yapilanIs.pursantaj_title} işinde ${this.yapilanIs.work_done_percentage} yüzdeli ilerlemeyi silmek istediğinize emin misiniz?`;

    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: text } } as SweetAlertOptions).then(value => {
      if (!value.dismiss) {
        this.yapilanIslerService.deleteYapilanIs(this.yapilanIs.id).subscribe(resp => {
          this.ref.close(true);
        });
      }
    });
  }

  addMorePhoto() {

    const context = {
      contentId: this.yapilanIs.id,
      tip: NoteType.Liste,
      modul: 'yapilanisler',
    };

    console.log('Context : ', context);

    this.nbDialogService.open(YapilanIslerAddPhotoComponent,
      { context: context, closeOnBackdropClick: false },
    ).onClose.subscribe(() => {
      this.yapilanIsList();
    });
  }
}
