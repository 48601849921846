
     <apas-base-calendar
       [boundingMonth]="boundingMonth"
       [startView]="startView"
       [date]="date"
       [min]="min"
       [max]="max"
       [filter]="filter"
       [monthCellComponent]="monthCellComponent"
       [yearCellComponent]="yearCellComponent"
       [size]="size"
       [visibleDate]="visibleDate"
       [showNavigation]="showNavigation"
       [weekNumberSymbol]="weekNumberSymbol"
       (dateChange)="dateChange.emit($event)"
     ></apas-base-calendar>
   