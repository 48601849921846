import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ImageModel } from '../models/image.model';
import { environment } from '../../environments/environment';


@Injectable()
export class ApasImageLightBoxService {
    private readonly API_URL = `${environment.backendurl}` + 'api/';
    constructor(private httpClient: HttpClient) { }
    /**
       * Şantiyeye ait kaliteli bir resim indirmye yarar.
       * @param url Resmin indirileceği şantiyenin id'si ve image id'sini içerir.
       * ör: santiye/imagedetay/5/45/
       */
    getImageDetay(url, method: string, data?): Observable<ImageModel> {
        // imagedetay
        url = this.API_URL + url;
        data = data ? data : '';
        if (method === 'get') {
            return this.httpClient.get<ImageModel>(url);
        } else if (method === 'post') {
            return this.httpClient.post<ImageModel>(url, data);
        } else {
            return this.httpClient.get<ImageModel>(url);
        }
    }


}
