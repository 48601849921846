import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CrmTeklif } from '../../crm-model/projeler-model';
import * as teklifAction from './teklifler.actions';
interface CrmTeklifState extends EntityState<CrmTeklif> {
    selectedTeklifId: number | null;
    end: boolean;
    addLoading: boolean;
    addSuccess: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
}

const defaultCrmTeklifState: CrmTeklifState = {
    ids: [],
    entities: {},
    selectedTeklifId: null,
    end: false,
    addLoading: false,
    addSuccess: null,
    loading: false,
    loaded: false,
    error: '',
};

const teklifAdapter: EntityAdapter<CrmTeklif> = createEntityAdapter<CrmTeklif>();
const initialState = teklifAdapter.getInitialState(defaultCrmTeklifState);

const teklifReducer = createReducer(
    initialState,

    on(teklifAction.loadData, (state, _props) => {
        return { ...state, loading: true };
    }),

    on(teklifAction.loadDataSuccess, (state, props: any) => {
        console.log('Teklifler Add Reducer',props);
        return teklifAdapter.upsertMany(props.data.results, {
            ...state,
            loading: false,
            loaded: true,
            end: props.isEnd,
        });
    }),

    on(teklifAction.loadDataFail, (state, _props) => {
        return { ...state, error: _props.error, loading: false };
    }),

    on(teklifAction.resetData, () => {
        return { ...defaultCrmTeklifState, ...{ loading: true, end: false } };
    }),

    on(teklifAction.selectTeklif, (state, props:any) => {
        return { ...state, selectedTeklifId: props.selectedId.id };
    }),

    on(teklifAction.addNewTeklif, (state) => {
        return { ...state, addLoading: true };
    }),
    on(teklifAction.updateTeklif, (state) => {
        return { ...state, addLoading: true };
    }),

    on(teklifAction.addNewTeklifSuccess, (state, props: any) => {
        return { ...state, ...teklifAdapter.upsertOne(props.data, state), addLoading: false, addSuccess: true };
    }),

    on(teklifAction.addNewTeklifFail, (state, props) => {
        return { ...state, error: props.error, addLoading: false, addSuccess: false };
    }),

    on(teklifAction.getTeklifDetail, (state, props) => {
        return { ...state, ...{ loading: true } };
    }),

    on(teklifAction.setTeklifDetail, (state, props) => {
        let teklif = state.entities[props.teklifId];
        teklif = { ...teklif, ...props.data };
        return { ...state, ...teklifAdapter.upsertOne(teklif, state), loading: false, addSuccess: true };

    }),
);


function CrmTeklifReducers(action, state) {
    return teklifReducer(action, state);
}


export {
    CrmTeklifState,
    defaultCrmTeklifState,
    CrmTeklifReducers,
    teklifAdapter,
};
