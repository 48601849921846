<nb-card class="modalType" size="giant" style="width: 60%;border: unset;">
    <nb-card-header>
        <div class="d-flex d-flex justify-content-between align-items-center">
            <div>
                <span>{{item?.title}}</span>

                <span class="badge badge-primary" *ngIf="item?.is_signed">İmzalandı</span>
                <span class="badge badge-warning" *ngIf="!item?.is_signed ">İmzalanmadı</span>
            </div>
            <div>
                <button nbButton size="small" [status]="item?.is_active ? 'warning' : 'danger'"
                    (click)="arsiveKaldir()">
                    <nb-icon icon='archive-outline'></nb-icon> {{item?.is_active ? 'Arşive Kaldır' :
                    'Arşivden Çıkart'}}
                </button>

                <button  *ngIf="false" nbButton size="small" status="info" (click)="formDuzenle()">
                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                </button>
                <button *ngIf="item?.can_delete" nbButton size="small" status="warning" class="silButon" (click)="deleteForm()">
                    <nb-icon icon='trash-outline'></nb-icon> Sil
                </button>
                <button nbButton status="basic" (click)="close()">
                    <nb-icon icon="close-outline"></nb-icon>
                </button>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body #frame *ngIf="item?.file_url && previewUrl && docOrImage == 'doc'" style="overflow-y: hidden;">
        <iframe width='100%' height='100%' [src]="previewUrl | safe" frameborder="0"></iframe>
    </nb-card-body>

    <nb-card-body *ngIf="docOrImage === 'image'" [nbSpinner]="isLoading">
        <img [src]="item?._file | safe" alt="image" style="width: 100%; height: auto;">
    </nb-card-body>

    <nb-card-body *ngIf="docOrImage == 'pdf'" style="overflow: hidden;">
        <object #pdfview type="application/pdf" [data]='item?._file | safe' width="100%" height="100%"></object>
    </nb-card-body>
    <nb-card-footer>
        <table>
            <tr *ngIf="item?.note" class="pb-1" [nbPopover]="notePop" [nbPopoverContext]="item?.description"
                nbPopoverTrigger="hover" nbPopoverPlacement="bottom">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Açıklama
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.description}}
                </td>
            </tr>

            <tr *ngIf="item?.santiye_name" class="pb-1">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Şantiye
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.santiye_name}}
                </td>
            </tr>

            <tr *ngIf="item?.signed_by_name" class="pb-1">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Sorumlu
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.signed_by_name}}
                    <small class="badge badge-primary" >( {{item?.company_name}} )</small>
                </td>
            </tr>

            <tr *ngIf="item?.signed_time" class="pb-1">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        İmzalanma Tarihi
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.signed_time | date : tarih_format }}
                </td>
            </tr>

            <tr *ngIf="item?.created_by_name" class="pb-1">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Ekleyen
                    </label>
                </td>
                <td class="pl-4">
                        {{item?.created_by_name}}
                    <small class="badge badge-primary" >( {{item?.organization_name}} )</small>
                </td>
            </tr>

            <tr *ngIf="item?.created_time">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Eklenme Tarihi
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.created_time | date : tarih_format_long}}
                </td>
            </tr>
        </table>
        <div class="row d-flex justify-content-center">
            <button nbButton (click)="indir()" status="info" #hero>
                <nb-icon icon="download-outline"></nb-icon> İndir
            </button>

            <button *ngIf="item.can_sign" nbButton status="success" (click)="imzala()" size="tiny">İmzala</button>
            <button *ngIf="item.can_sign" nbButton status="warning" (click)="reddet()" size="tiny">Reddet</button>

        </div>

    </nb-card-footer>
</nb-card>


<ng-template #notePop let-note>
    <div style="padding: 8px; border-radius: 4px; background-color: #151a30; color: white; width: 600px; height: auto;">
        {{note}}</div>
</ng-template>