import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DuyuruModel } from '../../../../models/duyuru.model';
import { tarih_format } from '../../../../pages/constants';

@Component({
  selector: 'apas-duyuru-item',
  templateUrl: './apas-duyuru-item.component.html',
  styleUrls: ['./apas-duyuru-item.component.css']
})
export class ApasDuyuruItemComponent implements OnInit {
  @Input() context: DuyuruModel;
  @Input() showReaded: boolean = true;
  @Output() duyuruClick: EventEmitter<DuyuruModel> = new EventEmitter();
  @Output() duyuruCheckClick: EventEmitter<DuyuruModel> = new EventEmitter();
  tarih_format = tarih_format;
  tag: string = 'ApasDuyuruItemComponent --->';
  constructor() { }

  ngOnInit() {
  }

  click() {
    // bildirim nesnesi yok ise
    if (!this.context) {
      console.error(`${this.tag} componentinde hata oluşdu. duyuru nesnesi bulunamadı.`);
      return false;
    }
    this.duyuruClick.emit(this.context);
  }
  checkClick() {
    // bildirim nesnesi yok ise
    if (!this.context) {
      console.error(`${this.tag} componentinde hata oluşdu. duyuru nesnesi bulunamadı.`);
      return false;
    }
    this.duyuruCheckClick.emit(this.context);
  }
}
