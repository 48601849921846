import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Subscription, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class LaboratuvarService implements OnDestroy {
    private readonly API_URL = `${environment.backendurl}api/laboratuvar/`;

    private urlHandler$: Subscription;
    constructor(private httpClient: HttpClient) {
    }
    ngOnDestroy(): void {
        this.urlHandler$.unsubscribe();
    }


    postRapor(data): Observable<any> {
        const url: string = this.API_URL + 'add/';
        const req = new HttpRequest('POST', url, data, { reportProgress: true });
        return this.httpClient.request(req);
    }
    updateRapor(data, instanceId): Observable<any> {
        const url: string = `${this.API_URL}update/${instanceId}/`;
        const req = new HttpRequest('PATCH', url, data, { reportProgress: true });
        return this.httpClient.request(req);
    }

    deleteRapor(instanceId): Observable<any> {
        const url: string = `${this.API_URL}delete/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getRaporlar(sanitiye_id?: number | null, page?: number): Observable<any[]> {
        console.log(` getRaporlar -> page: ${page}  santiye_id:${sanitiye_id}`);

        const url: string = sanitiye_id !== null ?
            (this.API_URL + `santiyelist/${sanitiye_id}/` + (page ? `${page}/` : '0/'))
            : (this.API_URL + `list/` + (page ? `${page}/` : '0/'));


        console.log(` getRaporlar -> url:${url}`);

        return this.httpClient.get<any[]>(url);
    }
    public stopRequest: Subject<void> = new Subject<void>();

    getNewRaporList(data) {
        const url: string = `${this.API_URL}list/`;
        /* once the subject emits a value, cancel the request*/
        return this.httpClient.post<any[]>(url, data);
    }

    getRaporlarSantiye(santiyeId: number, page?: number): Observable<any[]> {
        console.log(` getRaporlar -> sanityeid:${santiyeId} - page: ${page}  `);

        const url: string = (this.API_URL + `santiyelist/${santiyeId}/` + (page ? `${page}/` : '0/'));

        return this.httpClient.get<any[]>(url);
    }
    getRapor(raporId: number): Observable<any> {
        const url: string = this.API_URL + 'tek/' + raporId + '/';
        return this.httpClient.get<any>(url);
    }
    getRaporPercend(raporId: number): Observable<HttpEvent<any>> {
        const url: string = this.API_URL + 'tek/' + raporId + '/';
        // return this.httpClient.get<any>(url);
        const req = new HttpRequest('GET', url, { reportProgress: true });
        return this.httpClient.request(req);
    }

    searchSantiye(search: string): Observable<any[]> {
        const url: string = `${this.API_URL}santiye/list/`;
        return this.httpClient.post<any[]>(url, { 'search': search });
    }

}

