import { Injectable } from '@angular/core';
import { INaviagete } from '../../models/apas-naviagator.model';
import { NbDialogService } from '@nebular/theme';
import { GorevModulType } from './naviagtor-types';
import { GorevDetayComponent } from '../../components/gorev/gorev-detay/gorev-detay.component';

@Injectable({
  providedIn: 'root',
})
export class GorevNaigatorService implements INaviagete {

  constructor(private nbDialog: NbDialogService) { }

  naviagate({ module, payload }) {
    this.convertToModule({ module: module, id: payload.content.id });
  }
  convertToModule({ module, id }) {
    console.log('gorev navigate--->',module);
    switch (module) {
      case GorevModulType.Detay:
        this.nbDialog.open(GorevDetayComponent, {
          context: {
            gorevId: id,
          },
        });
        break;

    }
  }

}
