import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NbMenuService, NB_WINDOW } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { tarih_format } from '../../../../pages/constants';
import {
  ApasImalatUygunsuzlukContext,
  ApasImalatUygunsuzlukItem,
  ApasImalatUygunsuzlukItemOptinos,
  ApasImalatUygunsuzlukMenuType,
} from '../../model/apas-imalat-uygunsuzluk-item.model';

@Component({
  selector: 'apas-imalat-uygunsuzluk-item',
  templateUrl: './apas-imalat-uygunsuzluk-item.component.html',
  styleUrls: ['./apas-imalat-uygunsuzluk-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApasImalatUygunsuzlukItemComponent implements OnInit {
  /**
   * Komponentin datası
   */
  @Input() context: ApasImalatUygunsuzlukContext = {};

  /** Menüde görünecek liste */
  editMenuItems: ApasImalatUygunsuzlukItem[] = [];
  /** Menü leri kısıtlamak veya yeni menü eklmek için kullanılır. */
  @Input() options: ApasImalatUygunsuzlukItemOptinos = { menuItems: [] };

  tarih_format = tarih_format;

  isEditing: boolean = false;
  tag: string = 'ApasImalatUygunsuzlukItemComponent --->';
  constructor(private nbMenuService: NbMenuService, @Inject(NB_WINDOW) private window) {


  }

  ngOnInit() {
    this.initialize();
    this.editMenuItems = [
      {
        title: 'Düzenle',
        type: ApasImalatUygunsuzlukMenuType.Edit,
        hidden: !(this.options.canEdit && this.options.canEdit),
      },
      {
        title: 'Detay',
        type: ApasImalatUygunsuzlukMenuType.Detail,
        hidden: !(this.options.canDetails && this.options.canDetails),

      },
      {
        title: 'Sil',
        type: ApasImalatUygunsuzlukMenuType.Delete,
        hidden: !(this.options.canDelete && this.options.canDelete),

      },
    ];
    console.log(this.tag, 'options:', this.options);
    const items: ApasImalatUygunsuzlukItem[] = this.options && this.options.menuItems ? this.options.menuItems : [];
    console.log(this.tag, 'items:', items);
    this.editMenuItems = [...this.editMenuItems, ...items];



  }
  initialize() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'options-context-menu'),
        map(({ item }) => item),
      )
      .subscribe((item: ApasImalatUygunsuzlukItem) => {
        switch (item.type) {
          case ApasImalatUygunsuzlukMenuType.Edit:
            console.log(this.tag, 'Düzenle');
            this.startEditing();
            break;
          case ApasImalatUygunsuzlukMenuType.Detail:
            console.log(this.tag, 'Detay');
            break;
          case ApasImalatUygunsuzlukMenuType.Delete:
            console.log(this.tag, 'Sil');
            break;

          default:
            item.click && item.click({
              munuItem: item,
              value: this.context,
            });
            // if (item.click) {
            //   item.click(item);
            // }
            break;

        }
      });

  }
  startEditing() {
    this.isEditing = true;
  }
  endEditing() {
    this.isEditing = false;
  }

  currentFile;
  openFile(file) {

  }
  openFileDialog(file) {
    
  }


}

