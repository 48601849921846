import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DuyuruService } from '../../../../services/duyuru.service';
import { tarih_format } from '../../../../pages/constants';
import { Store, select } from '@ngrx/store';
import { DuyuruModel, GidenDuyuruInfinitModel } from '../../../../models/duyuru.model';
import * as duyuruActions from '../../../../store/duyuru/duyuru.actions';
import * as fromduyuru from '../../../../store/duyuru/duyuru.connector';
import { DuyuruDetayComponent } from '../../../duyuru/duyuru-detay/duyuru-detay.component';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'apas-duyuru',
  templateUrl: './apas-duyuru.component.html',
  styleUrls: ['./apas-duyuru.component.css'],
  providers: [DuyuruService],
})
export class ApasDuyuruComponent implements OnInit, AfterViewInit {

  unReadedCount: number = 0;
  tarih_format = tarih_format;
  tag: string = 'DuyuruListComponent --->';

  gidenDuyuru: GidenDuyuruInfinitModel = {
    duyurular: [],
    isEnd: false,
    isLoading: false,
    page: 0,
    pageSize: 20,
  };

  duyuruInfo: any;
  duyurular: DuyuruModel[] = [];
  pageSize: number = 20;
  isInit: boolean = false;

  constructor(private _nbDialogService: NbDialogService,
    private _duyuruService: DuyuruService,
    private store: Store<{ Duyuru }>) {

    //#region Duyuru
    this.store.pipe(select(fromduyuru.selectDuyuruInfo)).subscribe(duyuruInfo => {
      this.duyuruInfo = duyuruInfo;
    });
    this.store.pipe(select(fromduyuru.selectDuyuru),
      // switchMap((data: DuyuruModel[]) => of(data.sort((a, b) => b.created_time - a.created_time))),
    ).subscribe(duyurular => {
      this.duyurular = duyurular;
      console.log(this.tag, 'duyurular', this.duyurular);
    });

  }

  ngOnInit() {
    this.store.pipe(select(fromduyuru.selectDuyuruLoaded)).subscribe(isLoaded => {
      if (!isLoaded) {
        this.store.dispatch(duyuruActions.loadDuyuru({}));
      }
    });


  }

  ngAfterViewInit(): void {
    // akışı başlatır.
    // eğer daha önce akış indirilmiş ise başdan çekmez.
    if (!this.duyuruInfo.loaded && this.duyurular.length <= 0) {
      this.store.dispatch(duyuruActions.loadDuyuru({}));
    } else {
      this.isInit = true;
    }

  }

  duyuruDetay(item) {
    this._nbDialogService.open(DuyuruDetayComponent, { context: { duyuru: item } });
  }

  gelenDuyuruClicked(event: DuyuruModel) {
    // bildirim zaten okun mamışsa okundu olarak işaretle.
    if (!event.isReaded) {
      this.store.dispatch(duyuruActions.setDuyuruReaded({
        id: event.id,
      }));
    }
  }
  loadNext() {
    console.log(this.tag, 'called loadNext!');

    if (this.duyuruInfo.loading
      || this.duyurular === undefined
      || this.duyuruInfo.end
      || (!this.duyuruInfo.loaded && !this.isInit)) { return false; }
    // gideceğimiz sayfa numarasını veririr.
    const pageCount: number = Math.floor(this.duyurular.length / this.pageSize);

    this.store.dispatch(duyuruActions.loadDuyuru({ page: pageCount }));


  }

  loadNextGiden() {
    console.log(this.tag, 'called loadNextGiden! gidenDuyuru:', this.gidenDuyuru);

    if (this.gidenDuyuru.isLoading
      || this.gidenDuyuru.duyurular === undefined
      || this.gidenDuyuru.isEnd) { return false; }
    // gideceğimiz sayfa numarasını veririr.

    this.gidenDuyuru.isLoading = true;
    this._duyuruService.getDuyuruGiden({ page: this.gidenDuyuru.page }).subscribe((response: DuyuruModel[]) => {
      this.gidenDuyuru.duyurular = [...this.gidenDuyuru.duyurular, ...response];
      this.gidenDuyuru.page = Math.floor(this.gidenDuyuru.duyurular.length / this.gidenDuyuru.pageSize);
      this.gidenDuyuru.isEnd = response.length < this.gidenDuyuru.pageSize;
      this.gidenDuyuru.isLoading = false;

    }, (error: HttpErrorResponse) => {
      this.gidenDuyuru.isLoading = false;
      this.gidenDuyuru.isEnd = true;
    });


  }


}
