import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BirimFiyatModel } from '../models/birimfiyat.model';
import { environment } from '../../environments/environment';

@Injectable()
export class BirimFiyatService {
    private readonly API_URL = `${environment.backendurl}api/birimfiyat/`;

    constructor(private httpClient: HttpClient) { }

    searchPozNo(search: string, page?: number): Observable<BirimFiyatModel[]> {
        const url = page ? this.API_URL + page + '/' : this.API_URL;
        // console.log('infinit ng-select url:', url);
        return this.httpClient.post<BirimFiyatModel[]>(url, { 'search': search });
    }

    searchWithTitle(search: string, page?: number): Observable<BirimFiyatModel[]> {
        const url = page ? this.API_URL + page + '/' : this.API_URL;
        // console.log('infinit ng-select Title url:', url, 'search:' + search);
        return this.httpClient.post<BirimFiyatModel[]>(url, { 'title_search': search });
    }
}
