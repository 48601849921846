import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IsMakinasiModel,
  IsMakinasiYakitModel,
  IsMakinasiBakimModel,
  IsMakinasiHasarModel,
  IsMakinasiInsurance,
  IsMakinasiMotorInfo,
  IsMakinsiCalismaRaporModel,
  MachineTypeModel,
} from '../models/is_makinalari.model';
import { Observable, of } from 'rxjs';
import { SantiyeModel } from '../models/santiye_model';
import { removeAllNull } from '../pages/global-functions';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IsMakinalariService {
  private readonly API_URL = `${environment.backendurl}api/ismakinalari/`;
  constructor(private httpClient: HttpClient) { }
  /**
   * @param filters filtreler ; ile ayırılarak verilir.ör:gear_type>0;own_statu=True
   * @param shorts sırlama ölçütünü belirler.tersini yapmak icin başına '-'(tire) karekteri konulmalı.
   * defaultu=-product_year
   * @param pageIndex sayfa numarası
   */
  getFilterAndShort(
    filters?: string,
    shorts?: string,
    pageIndex?: number,
  ): Observable<IsMakinasiModel[]> {
    const filter: string = filters ? filters : 'product_year>0'; // default filtre tüm aracların gelmesi icin.
    const short: string = shorts ? shorts : '-product_year'; // default sıralama
    const index: number = pageIndex ? pageIndex : 0;
    const url =
      this.API_URL + 'filtre/' + filter + '/' + index + '/' + short + '/';
    return this.httpClient.get<IsMakinasiModel[]>(url);
  }

  listMachines(data): Observable<IsMakinasiModel[]> {
    const url = `${this.API_URL}newlist/`;
    return this.httpClient.post<IsMakinasiModel[]>(url, data);
  }

  getIsMakinalariTypes(data): Observable<any> {
    const url = this.API_URL + 'type/';
    return this.httpClient.post<any>(url, data);
  }

  getIsMakinalariDetay(id: number): Observable<IsMakinasiModel> {
    const url = this.API_URL + 'tek/' + id + '/';
    return this.httpClient.get<IsMakinasiModel>(url);
  }

  getIsMakinalariDetayBakim(id: number): Observable<any> {
    const url = this.API_URL + 'maintenance/' + id + '/';
    return this.httpClient.get<any>(url);
  }

  getSigorta(id: number): Observable<IsMakinasiInsurance[]> {
    const url = this.API_URL + 'insurance/' + id + '/';
    return this.httpClient.get<IsMakinasiInsurance[]>(url);
  }

  getTaseronIsMakinalari(): Observable<IsMakinasiModel[]> {
    const url = this.API_URL + 'taseron/list/';
    return this.httpClient.get<IsMakinasiModel[]>(url);
  }
  postIsMakinalari(data: IsMakinasiModel): Observable<IsMakinasiModel> {
    const url = this.API_URL + 'add/';
    return this.httpClient.post<IsMakinasiModel>(url, data);
  }
  patchIsMakinalari(
    id: number,
    data: any,
  ): Observable<any> {
    const url = this.API_URL + 'update/' + id + '/';
    return this.httpClient.patch<any>(url, data);
  }
  getYakitCTN(id: number) {
    const url = this.API_URL + 'fuelcnt/' + id + '/';
    return this.httpClient.get(url);
  }
  getYakitPage(id: number, page: number): Observable<any[]> {
    const url = this.API_URL + 'fuel/' + id + '/' + page + '/';
    return this.httpClient.get<any[]>(url);
  }
  getIsMakinalariYakit(id: number): Observable<IsMakinasiYakitModel[]> {
    const url = this.API_URL + 'fuel/' + id + '/';
    return this.httpClient.get<IsMakinasiYakitModel[]>(url);
  }
  postIsMakinalariYakit(
    id: number,
    data: IsMakinasiYakitModel,
  ): Observable<IsMakinasiYakitModel> {
    const url = this.API_URL + 'fuel/' + id + '/';
    return this.httpClient.post<IsMakinasiYakitModel>(url, data);
  }
  patchIsMakinalariYakit(
    id: number,
    data: IsMakinasiYakitModel,
  ): Observable<IsMakinasiYakitModel> {
    const url = this.API_URL + 'fuelupdate/' + id + '/';
    return this.httpClient.patch<IsMakinasiYakitModel>(url, data);
  }
  postIsMakinalariBakim(
    id: number,
    data: IsMakinasiBakimModel,
  ): Observable<IsMakinasiBakimModel> {
    const url = this.API_URL + 'maintenance/' + id + '/';
    return this.httpClient.post<IsMakinasiBakimModel>(url, data);
  }
  patchIsMakinalariBakim(
    id: number,
    data: IsMakinasiBakimModel,
  ): Observable<IsMakinasiBakimModel> {
    const url = this.API_URL + 'maintenanceupdate/' + id + '/';
    return this.httpClient.patch<IsMakinasiBakimModel>(url, data);
  }
  getIsMakinalarikaza(id: number): Observable<any[]> {
    const url = this.API_URL + 'damage/' + id + '/';
    return this.httpClient.get<any[]>(url);
  }

  deleteIsMakinasiKaza(instanceId): Observable<any> {
    const url: string = `${this.API_URL}damagedelete/${instanceId}/`;
    return this.httpClient.delete<any>(url);
  }

  getIsMakinalariCakismaRaporu(
    id: number,
  ): Observable<IsMakinsiCalismaRaporModel[]> {
    const url = `${this.API_URL}motorinfo/list/${id}/`;
    return this.httpClient.get<IsMakinsiCalismaRaporModel[]>(url);
  }

  getIsMakinalariPuantajRaporu(filter?: any): Observable<any> {
    const url = `${this.API_URL}raporla/`;
    return this.httpClient.post<any>(url, removeAllNull(filter));
  }

  updateMotorInfo(instanceId, data): Observable<any> {
    const url: string = `${this.API_URL}motorinfoupdate/${instanceId}/`;
    return this.httpClient.patch<any>(url, data);
  }


  deleteMotorInfo(instanceId): Observable<any> {
    const url: string = `${this.API_URL}deletemotorinfo/${instanceId}/`;
    return this.httpClient.delete<any>(url);
  }

  postIsMakinalarikaza(
    id: number,
    data: IsMakinasiHasarModel,
  ): Observable<IsMakinasiHasarModel> {
    const url = this.API_URL + 'damage/' + id + '/';
    return this.httpClient.post<IsMakinasiHasarModel>(url, data);
  }
  patchIsMakinalariKaza(
    id: number,
    data: IsMakinasiHasarModel,
  ): Observable<IsMakinasiHasarModel> {
    const url = this.API_URL + 'damageupdate/' + id + '/';
    return this.httpClient.patch<IsMakinasiHasarModel>(url, data);
  }

  getRuhsat(is_makinasi_id: number): Observable<any> {
    const url = this.API_URL + 'ruhsat/' + is_makinasi_id + '/';
    return this.httpClient.get(url);
  }

  getMotorInfo(is_makinasi_id: number): Observable<IsMakinasiMotorInfo> {
    const url = this.API_URL + 'motorinfo/' + is_makinasi_id + '/';
    return this.httpClient.get<IsMakinasiMotorInfo>(url);
  }

  getMotorInfoList(is_makinasi_id: number): Observable<IsMakinasiMotorInfo[]> {
    const url = this.API_URL + 'motorinfo/list/' + is_makinasi_id + '/';
    return this.httpClient.get<IsMakinasiMotorInfo[]>(url);
  }

  getSantiyeListForMachine(isMakinasiId: number): Observable<SantiyeModel[]> {
    const url = this.API_URL + 'santiye/list/' + isMakinasiId + '/';
    return this.httpClient.get<SantiyeModel[]>(url);
  }

  getSantiyeList(): Observable<SantiyeModel[]> {
    const url = this.API_URL + 'santiye/list/';
    return this.httpClient.get<SantiyeModel[]>(url);
  }
  /**
   * İş makinasını çalıştırır.
   * @param data Çalıştırılacak iş makinasının bilgilerini içerir.
   */
  setStartMotor(data: any): Observable<any> {
    const url: string = this.API_URL + 'motorinfo/';
    return this.httpClient.post<SantiyeModel[]>(url, data);
  }
  /**
   * İş makinasını durdurur.
   * @param isMakinasiId Durdurulacak iş makinasının id'si.
   */
  setStopMotor(isMakinasiId: number, formData): Observable<any> {
    // const url: string = this.API_URL + 'motorinfoupdate/';
    const url: string = this.API_URL + `motorinfo/${isMakinasiId}/`;

    return this.httpClient.post<any>(url, formData);
  }

  getOperators(data: any): Observable<any[]> {
    const url: string =
      this.API_URL +
      'driverlist/' +
      data.machine_id +
      '/' +
      data.santiye_id +
      '/';
    return this.httpClient.get<any[]>(url);
  }

  getMachineTypes(): Observable<MachineTypeModel[]> {
    const url: string = this.API_URL + 'type/';
    return this.httpClient.get<MachineTypeModel[]>(url);
  }

  getSantiyeMachines(santiye_id): Observable<any[]> {
    const url: string =
      this.API_URL + 'santiyemachinework/list/' + santiye_id + '/';

    return this.httpClient.get<any[]>(url);
  }

  /**
   * Belli bir tarih aralığındaki tüm iş makinalarının yakıt geçmişini getirir.
   * @param param  içinde {start,end} bilgisi olan bir nesne alır
   * @returns YakıtEkleModel[] cinsinden çıktı verir.
   */
  getYakitRapor({ start, end }): Observable<IsMakinasiYakitModel[]> {
    const url: string = `${this.API_URL}fuelreport/${start}/${end}/`;

    return this.httpClient.get<IsMakinasiYakitModel[]>(url);
  }
  getMarka() {
    const marka = require('../pages/resources/ismarka.json');
    return of(marka);
  }

  getToplu(santiye_id: number) {
    const url: string = `${this.API_URL}toplu/${santiye_id}/`;
    return this.httpClient.get<any[]>(url);
  }

  postToplu(santiye_id: number, data) {
    const url: string = `${this.API_URL}toplu/${santiye_id}/`;
    return this.httpClient.post<any[]>(url, data);
  }

  deleteTopluItem(instanceId: number) {
    const url: string = `${this.API_URL}toplu/delete/${instanceId}/`;
    return this.httpClient.delete(url);
  }

  fuelReport(data): Observable<any[]> {
    const url: string = `${this.API_URL}fuelreport/`;
    return this.httpClient.post<any[]>(url, data);
  }

  addMotorSaati(data) {
    const url: string = `${this.API_URL}addkm/`;
    return this.httpClient.post<any[]>(url, data);
  }

  deleteBakimBilgisi(id) {
    const url: string = `${this.API_URL}deletebakim/${id}/`;
    return this.httpClient.delete(url);
  }


  addMachineType(data): Observable<any> {
    const url: string = `${this.API_URL}addtype/`;
    return this.httpClient.post<any>(url, data);
  }

  deleteYakit(id): Observable<any> {
    const url: string = `${this.API_URL}deletefuel/${id}/`;
    return this.httpClient.delete<any>(url);
  }


  addSefer(data) {
    const url: string = `${this.API_URL}addserfer/`;
    return this.httpClient.post<any>(url, data);
  }


  getMachineWorkList(santiyeId) {
    const url: string = `${this.API_URL}santiyeagg/${santiyeId}/`;
    return this.httpClient.get(url);
  }
}
