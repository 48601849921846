<nb-card class="card animated fadeInRight modalType" style="width: 1000px;" size="giant">
  <nb-card-header>
      Yeni Fotoğraf Ekle
      <button nbButton (click)="closed()" size="tiny" class="m-close heryerdeClose" >X</button>
  </nb-card-header>
  <nb-card-body>
<div class="row">

  <div class="col-md-3" *ngFor="let file of currentFiles; let i = index">
    <apas-image-uploader 
      [Url]="targetUrl" 
      [Src]="file" 
      [showPublic]="true"
      [showDateInput]="true"
      [publicText]="'İşveren Görüntüleyebilir.'" 
      (success)="success($event,i)" 
      (onRemove)="remove($event,i)"
      (successLoad)="successLoad()"
    ></apas-image-uploader>

  </div>
  <div class="col-md-3">
    <div class="default-img linked-div" (click)="openFile(fileInput)">
      <nb-icon icon="plus-circle-outline" style="width: auto;   height: auto; color: #443939;"></nb-icon>
      <span *ngIf="currentFiles.length <= 0"> {{'SANTIYEIMGUPLOAD.FOTOGRAFSEC'|translate}}</span>
      <span *ngIf="currentFiles.length > 0"> {{'SANTIYEIMGUPLOAD.FOTOGRAFEKLE'|translate}}</span>
    </div>
    <input #fileInput type="file" accept="image/*" style="display:none" multiple (change)="openFileDialog($event)">

  </div>


</div>

<div *ngIf="currentFiles.length > 0" class="col-md-12 text-center">
  <hr>
  <button nbButton status="primary" size="small" (click)="uploadAll()">
    <nb-icon style="margin-right: 5px;" icon="cloud-upload-outline"></nb-icon>{{'SANTIYEIMGUPLOAD.TUMUNUYUKLE'|translate}}
  </button>
</div>
</nb-card-body>
</nb-card>