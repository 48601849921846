<nb-card class="modalType animated fadeInRight" size="giant" style="width: 500px;">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>
            Yeni Hakediş Oluştur
        </span>
        <button nbButton (click)="close()" size="medium" class="heryerdeClose" style="float: right;">X</button>

    </nb-card-header>

    <nb-card-body>
        <div class="row" [formGroup]="form">
            <div class="col-12 form-group">
                <label class="label">
                    Firma
                </label>
                <organization-select formControlName="taseron" (change)="selectTaseron($event)" apasInvalid
                    class="d-block"></organization-select>
            </div>
            <div class="col-12 form-group">
                <label class="label"> Şantiye </label>
                <santiye-select [mine]="true" formControlName="santiye" (change)="selectSantiye($event)" apasInvalid
                    class="d-block"></santiye-select>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button nbButton status="primary" class="m-0" size="small" (click)="yeniHakedisOlustur()">
                {{'TASERONHAKEDIS.YENIHAKEDISEKLE' |translate}}
            </button>
        </div>
    </nb-card-body>
</nb-card>