import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PuantajNamesModel } from '../../../models/puantaj_model';
import { tarih_format } from '../../../pages/constants';
import { yazdirArray, YazdirTableHead } from '../../../pages/global-functions';
import { PuantajService } from '../../../services/puantaj.service';
import { ApasYazdirService } from '../../Apas-Modules/services/apas-yazdir.service';

@Component({
  selector: 'puantaj-detay-for-names',
  templateUrl: './puantaj-detay-for-names.component.html',
  styleUrls: ['./puantaj-detay-for-names.component.css'],
})
export class PuantajDetayForNamesComponent implements OnInit {
  @Input() puantajArgs;
  @Input() puantajArgsForPrint;

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  rows: PuantajNamesModel[] = [];
  tag: string = 'PuantajDetayForNamesComponent --->';
  isLoadings: any = {};
  constructor(
    private puantajService: PuantajService,
    private apasYazdirService: ApasYazdirService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.isLoadings['puantaj'] = true;

    this.puantajService.getSantiyeMainListNames(this.puantajArgs).subscribe(
      (response) => {
        response.map((el) => {
          el['durum'] = el.yarim_gun ? 'Yarım' : 'Tam';
        });
        this.rows = [...response];

        this.isLoadings['puantaj'] = false;

        console.log(this.tag, 'response:', response);
      },
      () => {
        this.isLoadings['puantaj'] = false;
      },
    );
  }
  onGoBack() {
    this.goBack.emit();
  }
  saveAsPDF() {
    // const head: YazdirTableHead = {
    //   titles: ['Adı', 'Meslek', 'Mesai(saat)', 'Durum'],
    //   keys: ['employee', 'job_name', 'overtime', 'durum'],
    // };
    // yazdirArray(head, this.rows);
    this.apasYazdirService.Yazdir({
      head: {
        date: this.puantajArgsForPrint.date
          ? this.datePipe.transform(
              this.puantajArgsForPrint.date * 1000,
              tarih_format,
            )
          : '',
        mainTitle: 'İsim Puantaj Raporu',
        title: `Taşeron: ${this.puantajArgsForPrint.taseron_name} 
        <br> Şantiye: ${this.puantajArgsForPrint.santiye_name} `,
      },
      body: {
        tableHeader: [
          {
            name: 'Adı',
            prop: 'employee',
            isDate: false,
          },
          {
            name: 'Meslek',
            prop: 'job_name',
            isDate: false,
          },
          {
            name: 'Mesai(saat)',
            prop: 'overtime',
            isDate: false,
          },
          {
            name: 'Durum',
            prop: 'durum',
            isDate: false,
          },
        ],
        tableBody: this.rows,
      },
    });
  }
}
