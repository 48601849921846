import { Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { birim } from '../../../pages/constants';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';

@Component({
  selector: 'miktar-birim',
  templateUrl: './miktar-birim.component.html',
  styleUrls: ['./miktar-birim.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiktarBirimComponent),
      multi: true,
    },
  ],
})
export class MiktarBirimComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {
  birimler = birim;

  @Input() miktarControl!: FormControl;
  @Input() miktarControlName: string;

  @Input() birimControl!: FormControl;
  @Input() birimControlName: string = 'birim';
  
  @Input() selectedUnit: number = null;

  constructor(injector: Injector) {
    super(injector);
  }

  get miktarControlForm(): any {
    if (this.miktarControl) {
      return this.miktarControl;
    } else if (this.miktarControlName) {
      this.miktarControl = this.controlContainer?.control?.get(this.miktarControlName) as FormControl;
      return this.miktarControl;
    }
  }


  get birmControlForm(): FormControl {
    if (this.birimControl) {
      return this.birimControl;
    } else if (this.birimControlName) {
      this.birimControl = this.controlContainer?.control?.get(this.birimControlName) as FormControl;
      return this.birimControl;
    }
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedUnit']){
      this.birmControlForm.setValue(changes['selectedUnit'].currentValue);
    }
  }

  

}
