
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import { SantiyeService } from '../../../../services/santiye.service';
import { ApasImageLightboxComponent } from '../../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { SantiyeImgUploadComponent } from '../santiye-img-upload/santiye-img-upload.component';

@Component({
  selector: 'santiye-album-detay',
  templateUrl: './santiye-album-detay.component.html',
  styleUrls: ['./santiye-album-detay.component.scss'],
  providers: [SantiyeService],
})
export class SantiyeAlbumDetayComponent implements OnInit, OnChanges {
  serializer = this.domSanitizer;
  dayData = [];
  isLoadingDetay: boolean = false;
  form: FormGroup;
  dateC;
  santiyeName;
  next_start_date;
  tarih_format = tarih_format;
  constructor(private fb: FormBuilder,
    private santiyeService: SantiyeService,
    private domSanitizer: DomSanitizer,
    private nbDialogService: NbDialogService,
    private router: Router) {

    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.data) {
      console.log('STATE DATA : ', state.data);
      this.dateC = this.router.getCurrentNavigation().extras.state.data;
      this.initForm();
      this.santiyeName = this.dateC.santiye_name;

    } else {
      this.router.navigate(['pages/dashboard']);
    }

  }

  ngOnInit(): void {
    this.initEditForm();
    this.filtrele();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('CHANGE : ', changes);
    if (this.form) {
      this.filtrele();
    }
  }



  initForm() {
    this.form = this.fb.group({
      santiye_id: [this.dateC.santiye_id],
      search: [],
      start_date: [this.dateC.start_date],
      show_yapilan_isler: [this.dateC.showImalatPhotos],
      show_santiye_photos: [this.dateC.showSantiyePhotos],
    });

    this.form.get('show_yapilan_isler').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.filtrele();
      }, 150);
    });

    this.form.get('show_santiye_photos').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.filtrele();
      }, 150);
    });

    this.form.get('search').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.filtrele();
      }, 150);
    });
  }





  editForm: FormGroup;

  initEditForm() {
    this.editForm = this.fb.group({
      title: [],
      created_time: [],
      is_public: [],
    });
  }
  isEnd: boolean = false;
  filtrele() {
    this.dayData = [];
    this.isLoadingDetay = true;
    this.isEnd = false;
    this.getAlbumDetayData();
  }

  totalCount;
  getAlbumDetayData() {
    this.isLoadingDetay = true;
    let filter = removeAllNull(this.form.value);
    this.santiyeService.getImageForDay(filter).subscribe(data => {
      console.log('data:', data);
      this.next_start_date = data.next_start_date;
      this.totalCount = data.total_count;
      data.data.forEach(elm => {
        elm['tarih'] = convertDateWithTZ(elm, 'created_time');
        this.dayData = addOrUpdate(this.dayData, elm);
        this.isLoadingDetay = false;

      });
      if (data.data.lenght === 0) {
        this.isEnd = true;
      }
      this.dayData = [...this.dayData];

    }, err => {
      this.isEnd = true;
      this.isLoadingDetay = false;
    });

    console.log("İs End", this.isEnd);
  }
  yeniFotoEkle() {
    this.nbDialogService.open(
      SantiyeImgUploadComponent,
      { context: { santiyeId: this.dateC.santiye_id } })
      .onClose.subscribe(data => {
        if (data === true) {
          this.getAlbumDetayData();
        }
      });
  }


  goToNextPage() {
    console.log("Next Oldu");

    console.log("Next Time:", this.next_start_date);
    if (!this.isLoadingDetay) {
      this.form.get('start_date').setValue(this.next_start_date);

      this.getAlbumDetayData();


    }
  }
  getLastDate(rows: any[]): number {
    let last: number;
    if (rows.length > 0) {
      const lastGroup: any = rows[rows.length - 1];
      const lastImage: any = lastGroup.images[lastGroup.images.length - 1];
      last = lastImage.created_time;
    }
    return last;
  }

  imgClick(image: string, imageIndex: number) {
    const context: {} = {
      image: image,
      url: 'santiye/imagedetay/',
      // 'url': 'santiye/imagedetay/' + this.santiyeId + '/' + imageId + '/',
      santiyeId: this.dateC.santiye_id,
      imageIndex: imageIndex,
      groupIndex: 0,
      rows: [{ images: this.dayData }],

    };
    console.log('CONTEXT:', context);


    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }

  fotografSil(image) {
    Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(
      ({ value }) => {
        if (value) {
          this.santiyeService.deleteImage(image.id).subscribe((resp) => {
            console.log('resp', resp);
            this.dayData = this.dayData.filter((img) => img.id !== image.id);

          });
        }
      },
    );
  }
  fotografEdit(image) {
    this.dayData.forEach(grp => {
      grp['editMod'] = false;
    });
    image['editMod'] = true;

    this.editForm.get('title').setValue(image.title);

    const newDate = new Date(image.created_time * 1000);
    this.editForm.get('created_time').setValue(newDate);
    this.editForm.get('is_public').setValue(image.is_public);
  }

  updateImage(image) {
    const formVal = removeAllNull(this.editForm.value);

    this.santiyeService.updateImage(image.id, formVal).subscribe(resp => {
      // image = resp;
      // image['editMod'] = false;
      // this.dayData = this.dayData.filter(x => x.id !== resp.id);
      // const formatTarih = convertDateWithTZ(resp, 'created_time');
      // const to_add = this.dayData.find(x => x.tarih === formatTarih);
      // if (to_add) {
      //   to_add.images = [...to_add.images, ...[image]];
      // } else {
      //   const newImageArray = {
      //     adet: 1,
      //     created_time: image.created_time,
      //     tarih: formatTarih,
      //     images: [image],
      //   };
      //   this.dayData = [...this.dayData, ...[newImageArray]];
      // }
      // this.dayData.forEach((gun, i) => {
      //   console.log('ELM', gun.tarih , gun.images.length);
      //     console.log('TO REMOVE : ', gun);
      //     this.dayData = this.dayData.filter(x => x !== gun);

      // });

      // this.dayData.sort((x, y) => y.created_time - x.created_time);
      // this.dayData = [...this.dayData];
      // console.log('ALL IMAGES : ', this.dayData);
      this.getAlbumDetayData();

      this.editForm.reset();
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);

    });
  }

}
