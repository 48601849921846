import { Component, Injector, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../../pages/constants';
import { IsGuvenligiServiceV2 } from '../../../../services/isguvenligiV2.service';
import { FilePreviewerComponent } from '../../../apas-components/file-preview-component/file-previewer.component';
import { ImzaEkleComponent } from '../imza-ekle/imza-ekle.component';

@Component({
  selector: 'ngx-imza-detay',
  templateUrl: './imza-detay.component.html',
  styleUrls: ['./imza-detay.component.scss'],
  providers: [IsGuvenligiServiceV2],
})
export class ImzaDetayComponent extends FilePreviewerComponent implements OnInit {

  constructor(
    injector: Injector,
    ref: NbDialogRef<ImzaDetayComponent>,
    private isGuvenligiServiceV2: IsGuvenligiServiceV2,
    private nbDialogService: NbDialogService,
  ) {
    super(injector, ref);
   }

  ngOnInit(): void {
    this.setDataFormat();
  }


  imzala() {
    const context: SweetAlertOptions = {
      icon: 'question',
      title: 'Emin Misiniz?',
      html: `${this.item?.title} başlıklı sözleşmeyi imzalamak istediğinizden <u><b>Emin Misiniz</b></u>?`,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'İmzala',
    };
    Swal.fire(context).then(({value}) => {
      if (value) {
        this.isLoading = true;
        this.isGuvenligiServiceV2.sozlesmeImzala(this.item?.identifier).subscribe(resp => {
          this.item = {...this.item, ...resp};
          this.isLoading = false;
        }, (err) => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          this.isLoading = false;
        });
      }
    });
  }

  reddet() {
    const context: SweetAlertOptions = {
      icon: 'question',
      title: 'Emin Misiniz?',
      html: `${this.item?.title} başlıklı sözleşmeyi reddetmek istediğinizden <u><b>Emin Misiniz</b></u>? </br> Bu İşlem Geri Alınamaz.`,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'İmzala',
    };
    Swal.fire(context).then(({value}) => {
      if (value) {
        this.isLoading = true;
        this.isGuvenligiServiceV2.sozlesmeReddet(this.item?.identifier).subscribe(resp => {
          this.item = {...this.item, ...resp};
          this.isLoading = false;
        }, (err) => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          this.isLoading = false;
        });
      }
    });
  }



  arsiveKaldir() {
    const text = `${this.item.title} başlıklı dosyayı <b>${this.item.is_active ? 'Arşive Kaldırmak' : 'Arşivden Çıkartmak'}</b> istediğinizden Emin Misiniz?`;

    Swal.fire({ ...responseKodlari.shureToPasif, ...{ html: text } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;
        this.isGuvenligiServiceV2.updateImza(
          this.item.identifier, { is_active: !this.item.is_active }).subscribe(resp => {
            this.item = resp;
            this.isLoading = false;
            Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
            this.item['forceUpdate'] = true;
            this.close(true);
          }, (err) => {
            this.isLoading = false;
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          });
      }

    });

  }

  deleteForm() {
    const text = `${this.item.title} başlıklı dosyayı Silmek İstediğinize Emin Misiniz?`;

    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: text } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;
        this.isGuvenligiServiceV2.deleteImza(this.item.identifier).subscribe(resp => {
          // Listeleme Componenti yenilemeye zorla
          this.item['forceUpdate'] = true;
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.close(true);

        }, (err) => {
          this.isLoading = false;
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
      }

    });

  }

  close(resp?) {
    this.ref.close(resp);
  }
  formDuzenle() {
    console.log('İtemin İçinde Ne Varsa Göster', this.item);
    this.nbDialogService.open(ImzaEkleComponent,
      { context: { editData: this.item } }).onClose.subscribe((resp) => {
        if (resp) {
         this.item = resp;
        }
      });
  }


}
