import { Component, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TopluPuantajModel } from '../../../../models/puantaj_model';
import { SantiyeModel } from '../../../../models/santiye_model';
import { TaseronModel } from '../../../../models/taseron.model';
import { responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { KullaniciService } from '../../../../services/kullanici.service';
import { PuantajService } from '../../../../services/puantaj.service';
import { SantiyeService } from '../../../../services/santiye.service';
import { TaseronService } from '../../../../services/taseron.service';

@Component({
  selector: 'toplu-puantaj-ekle-modal',
  templateUrl: './toplu-puantaj-ekle-modal.component.html',
  styleUrls: ['./toplu-puantaj-ekle-modal.component.css'],
  providers: [SantiyeService],
})
export class TopluPuantajEkleModalComponent implements OnInit {
  isLoadings = {};
  taseronList: TaseronModel[] = [];
  santiyeList: SantiyeModel[] = [];
  taseron;

  form: FormGroup;
  filterForm: FormGroup;
  tag: string = 'TopluPuantajEkleModalComponent --->';

  get puantajList(): FormArray { return this.form.get('puantajList') as FormArray; }
  constructor(private fb: FormBuilder,
    private taseronService: TaseronService,
    private puantajService: PuantajService,
    private santiyeService: SantiyeService,
    private ref: NbDialogRef<TopluPuantajEkleModalComponent>) {

    this.initForm();
    this.isLoadings['taseron'] = true;
    this.isLoadings['santiye'] = true;
    this.isLoadings['isSubmitting'] = false;

    this.taseronService.getTaseronlar().subscribe(data => {
      this.isLoadings['taseron'] = false;

      this.taseronList = data;
    }, () => {
      this.isLoadings['taseron'] = false;

    });

  }

  ngOnInit() {
    this.addRow();
  }
  initForm() {
    this.form = this.fb.group({
      puantajList: this.fb.array([]),
    });

    this.filterForm = this.fb.group({
      santiye_id: [null, Validators.required],
      date: [new Date(), Validators.required],
    });

    this.filterForm.valueChanges.subscribe(val => {
      setTimeout(() => {
        this.getPuantajList();
      }, 150);
    });
  }

  postForm() {

    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      return;
    }

    // const formData = this.valueToModel();
    let formData = removeAllNull(this.form.value);
    formData = formData.puantajList;
    this.isLoadings['isSubmitting'] = true;
    const filterFormData = removeAllNull(this.filterForm.value);
    const santiye_id = filterFormData.santiye_id;
    const date = filterFormData.date;
    filterFormData['puantaj_items'] = formData;
    this.puantajService.postTopluPuantaj(filterFormData).subscribe(response => {
      this.isLoadings['isSubmitting'] = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.close());
    }, () => {
      this.isLoadings['isSubmitting'] = false;

    });

  }
  removeRow(index: number) {
    if (this.puantajList.length > 1) {
      this.puantajList.removeAt(index);
    }
  }

  addRow(item?: TopluPuantajModel) {
    const form: FormGroup = this.fb.group({
      created_time: [new Date()],
      tam_gun_adet: [],
      yarim_gun_adet: [],
      job: [null, Validators.required],
      taseron_id: [null, Validators.required],
      taseron_name: [null],
      santiye_id: [null],
    });

    if (item) {
      form.patchValue(item);
      form.get('job').patchValue(item.job ? item.job.id : null);
    }
    this.puantajList.push(form);
  }

  close() {
    this.ref.close();
  }

  getPuantajList() {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    this.isLoadings['isSubmitting'] = true;

    const formVal = removeAllNull(this.filterForm.value);
    this.puantajList.clear();
    this.puantajService.getTopluPuantaj(formVal).subscribe(response => {
      this.isLoadings['isSubmitting'] = false;
      response.forEach(element => {
        this.addRow(element);
      });

      if (response.length === 0) {
        this.addRow();
      }
    }, () => {
      this.isLoadings['isSubmitting'] = false;
      this.addRow();

    });
  }

}
