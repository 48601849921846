<nb-card style="border-top: unset; max-width: 500px;">
    <nb-card-header>
        <div class="" style="float: left;">
            <nb-icon icon="layers-outline"></nb-icon>
            {{item?.sozlesme_name ? item?.sozlesme_name : ''}}
        </div>
        <div style="float: right;">
            <button nbButton (click)="close()" size="tiny" class="m-close" style="background: none !important;
            color: black !important;
            float: right;
            font-size: 18px;">X</button>
        </div>
    </nb-card-header>

    <nb-card-body>
        <div class="row w-100 p-0 m-0 ">
            <div class="col-12 p-0 m-0">
                <span>
                    {{item?.title}}
                <!-- {{item?.title | slice:0:length ? length:30}}{{item?.title?.length > length ? '...' : null}} -->

                </span>

            </div>
            <div class="col-md-12" [nbTooltip]="item?.title">
                <div class="row">

            
                    <div class="col-md-12 row d-flex align-items-center">
                        <div class="col-md-8">
                            <div class="row" [ngStyle]="{'color': tamamlananColor}">
                                <div class="col-md-9 p-0 m-0">
                                    Tamamlanma Oranı
                                </div>
                                <div class="col-md-3  p-0 m-0">
                                    : <b>{{item.completed}}</b>
                                </div>
                            </div>
                            <div class="row" [ngStyle]="{'color': ongorulenColor}">
                                <div class="col-md-9 p-0 m-0">
                                    Öngörülen Oran
                                </div>
                                <div class="col-md-3  p-0 m-0">
                                    : <b>{{item.should_completed}}</b>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4" style="text-align: right;">
                            <ngx-charts-pie-chart [view]="[150, 100]" [scheme]="colorScheme" [results]="item.data"
                                [gradient]="gradient" [legend]="showLegend" [legendPosition]="legendPosition"
                                [labels]="showLabels" [doughnut]="isDoughnut">
                            </ngx-charts-pie-chart>
                            <!-- <chart type="pie" [data]="item.data" [options]="options"></chart> -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-1">
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer style="text-align: center;">
        <button nbButton (click)="goToGantt()" size="tiny" status="info">İş Programını Görüntüle</button>

    </nb-card-footer>
</nb-card>