import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TalepModel } from '../models/talep.model';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class TalepService {
    private readonly API_URL = `${environment.backendurl}api/talep/`;

    constructor(private httpClient: HttpClient) { }

    getTaleps(formData): Observable<TalepModel[]> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.post<TalepModel[]>(url, formData);
    }

    getTalepsForSantiye(santiyeId: number, page?: number): Observable<TalepModel[]> {
        // console.log("Talep Service Sayfası: ", santiye, page);
        const url: string = `${this.API_URL}santiye/${santiyeId}/${page ? (page + '/') : ''}`;
        return this.httpClient.get<TalepModel[]>(url);
    }

    getTalepDetails(talep_id: number): Observable<TalepModel> {
        return this.httpClient.get<TalepModel>(this.API_URL + 'detay/' + talep_id + '/');

    }

    patchTalep(id: number, data: any): Observable<TalepModel> {
        return this.httpClient.patch<TalepModel>(this.API_URL + 'tek/' + id + '/', data);
    }

    searchTaleps(santiyeId: number, search?: string) {
        const url: string = `${this.API_URL}search/${santiyeId}/${search ? search : ''}`;
        return this.httpClient.get<TalepModel[]>(url);
    }

    ownTalep(id: number): Observable<TalepModel> {
        // Bir talebe teklif alacağımı belirler veya teklif alacağımı vaad ettiğim bir talepten çekilmemi sağlar
        const own_url = this.API_URL + 'own/' + id + '/';
        return this.httpClient.patch<TalepModel>(own_url, '');
    }

    listOwnedTaleps(): Observable<TalepModel[]> {
        // Benim Teklif almayı vaad ettiğim talepleri listeler
        return this.httpClient.get<TalepModel[]>(this.API_URL + 'own/');
    }

    addTalep(talep: TalepModel[]): Observable<TalepModel[]> {
        return this.httpClient.post<TalepModel[]>(this.API_URL, talep);
    }

    approveTalep(idendifier: string): Observable<TalepModel> {
        const url: string = `${this.API_URL}approve/${idendifier}/`;
        return this.httpClient.get<TalepModel>(url);

    }

    talepPersonels(): Observable<any[]> {
        const url: string = `${this.API_URL}personellist/`;
        return this.httpClient.get<any[]>(url);
    }
    getTeklifForm(indetifier: string): Observable<any> {
        const url: string = `${this.API_URL}teklifexcel/${indetifier}/`;
        return this.httpClient.get<any>(url);
    }
}

