<nb-card size="giant" class="modalType animated slideInRight" style="border-top: unset !important; width: 30%;">
  <nb-card-header>
    <div class="row">
      <div class="col aling-items-center d-flex">
        <b> {{'YAPILANISLEREKLE.EKLE'|translate}} </b>
      </div>
      <button nbButton style="float: right; margin-left: auto !important;" (click)="close()">
        <nb-icon icon="close-outline"></nb-icon>
      </button>

    </div>
  </nb-card-header>
  <nb-card-body>

    <div class="row p-0 m-0 form-padding yapilan_is_ekle">
      <div class="col-12">
        <span>
          Pursantaj veya metraj bilgisi ile yapılan iş ekleyebilirsiniz.
        </span>

      </div>
    </div>

    <form [formGroup]="yapilanIsForm" [nbSpinner]="isLoading" nbSpinnerSize="tiny">
      <div class="row form-padding yapilan_is_ekle">
        <!--İmalat-->
        <div class="col-12">
          <label for="pursantaj_id" class="form-label label">{{'YAPILANISLERDETAY.IMALATCINSI'|translate}}</label>
          <ng-select id="pursantaj_id" fullWidth appendTo="body" [addTag]="addTagPromise"
            [placeholder]="'YAPILANISLERDETAY.IMALATCINSI'|translate" [items]="imalatCinsi" bindValue="id"
            bindLabel="pursantaj_name" formControlName="pursantaj_id" (change)="pursantajSec($event)"
            [addTagText]="'Yeni pursantaj ekle'" [selectOnTab]="true" apasInvalid>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="d-flex">
                <div>
                  <b style="font-size:medium; color:gray">{{item.pursantaj_name}}</b><br>
                  <b style="font-size:small;white-space: break-spaces;">{{item.pursantaj_}} </b><br>
                  <small>{{'YAPILANISLEREKLE.IMALATINSOZLESMEDEKIORANI'|translate}}
                    <b *ngIf="item.pursantaj_rate">{{item.pursantaj_rate}}%</b></small><br>
                </div>

                <div class="text-right w-100" >
                  <nb-icon class="editButtonPosition" icon="edit-2-outline" (click)="editPursantaj(item, false)">
                  </nb-icon>
                </div>

               
              </div>
            </ng-template>
          </ng-select>
        </div>
        <!--// İmalat-->
        <ng-container>

          <!-- Tarih -->
          <div class="col-12">
            <label for="aciklama" class="label form-label">Tarih</label>

            <input class="custom-input main-sub floating__input" type="button" formControlName="date" nbInput fullWidth placeholder="Tarih" [nbDatepicker]="startDate" apasInvalid/>
              <nb-datepicker #startDate></nb-datepicker>

          </div>
          <!--// Tarih -->

          <!--Tamamlanma-->
          <div class="col-12  py-2" [nbTooltip]="'YAPILANISLEREKLE.BUIMALATIN'|translate">
            <label for="tamamlanma" class="form-label label">{{'ACIKLAMAILEYAPILANIS.KUMULATIF'|translate}}</label>
            <label for="toplam_metraj" class="label form-label"
              style="float: right;">{{'YAPILANISLEREKLE.PURSANTAJ'|translate}}
              <apas-para-item style="font-size: large; color: #2ce69b !important;" [value]="yapilanIsForm.get('total_pursantaj').value" [decrypt]="false" [alacak]="false"
                [negative]="false"></apas-para-item> <span style="font-size: large; color: #2ce69b !important;">
                %</span>
            </label>

            <input 
              id="tamamlanma" 
              class="custom-input main-sub floating__input" 
              paraDirective [encrypt]="false" [decrypt]="false" [negative]="false" [forceRefresh]="forceRefresh" [plcholder]="'Kümülatif Tamamlanma Oranı'" 
              nbInput fullWidth formControlName="work_done_percentage"  apasInvalid/>
          </div>
          <!--// Tamamlanma-->

          <!--Toplam Metraj-->
          <div class="col-12 py-2"
            [nbTooltip]="'Zorunlu Değidir. Metraj Üzerinden Yapılan iş hesaplamak isterseniz girebilirsiniz.'">
            <label for="tamamlanan_metraj" class="label form-label">Tamamlanan Metraj</label>
            <label for="toplam_metraj" class="label form-label" style="float: right;">Toplam Metraj 
              <apas-para-item style="font-size: large; color: #2ce69b !important;"  [currency]="'m2'" [value]="yapilanIsForm.get('toplam_metraj').value" [decrypt]="false" [alacak]="false"
                [negative]="false"></apas-para-item> <span style="font-size: large; color: #2ce69b !important;">
                m2</span> </label>

              <input id="tamamlanan_metraj" class="custom-input main-sub" type="text"
                formControlName="tamamlanan_metraj" nbInput paraDirective [encrypt]="false" [decrypt]="false"
                [negative]="false" [forceRefresh]="forceRefresh" fullWidth [plcholder]="'Tamamlanan Metraj'" apasInvalid />
          </div>
          <!--// Toplam Metraj-->

          <!--Açıklama-->
          <div class="col-12">
            <label for="aciklama" class="label form-label">{{'YAPILANISLERDETAY.ACIKLAMA'|translate}}</label>

            <input id="aciklama" class="custom-input main-sub floating__input" type="text" formControlName="desc"
              nbInput novalidate fullWidth placeholder=" " />
          </div>
          <!--// Açıklama-->

          <!--Toplam-->
          <div class="col-12 d-flex pt-4 justify-content-center"
            [nbTooltip]="'YAPILANISLEREKLE.GERCEKLESENTOPLAM' | translate">

            <label for="tot" class="form-label label"><span style="font-size: large;">
                {{'YAPILANISLEREKLE.GERCEKLESMEORANI'|translate}} : </span> 
                <span style="font-weight: bolder;font-size: large; color: #2ce69b !important;">{{yapilanIsForm.get('applied_rate').value}}
                %</span></label>
          </div>
          <!--// Toplam-->

          <div class="col-12 d-flex justify-content-center">
            <button nbButton status="primary" (click)="yapilanIsEkle()">{{'YAPILANISLEREKLE.KAYDET'|translate}}</button>
          </div>
        </ng-container>

      </div>
    </form>
  </nb-card-body>
</nb-card>

