import { Component, OnInit } from '@angular/core';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { NbDialogRef, NbAccordionItemBodyComponent, NbAccordionItemComponent } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { removeAllNull } from '../../../pages/global-functions';
import { HttpErrorResponse } from '@angular/common/http';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { PersonelGrup } from '../../../models/personel_grup.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { closestIndexTo } from 'date-fns/esm';

@Component({
  selector: 'personel-grubu-olustur',
  templateUrl: './personel-grubu-olustur.component.html',
  styleUrls: ['./personel-grubu-olustur.component.css'],
  providers: [OrganizasyonService],
})
export class PersonelGrubuOlusturComponent implements OnInit {
  gruplar: PersonelGrup[] = [];

  isLoading: boolean = false;
  form: FormGroup;

  currentEditedGroupIndex: number;
  patchLoading: boolean;
  assign_to_ids(index: number): FormArray { return this.gurups.at(index).get('assign_to_ids') as FormArray; }
  get gurups(): FormArray { return this.form.get('gurups') as FormArray; }

  get gurupsForAdd(): FormGroup { return this.form.get('gurupsForAdd') as FormGroup; }
  get assign_to_idsForAdd(): FormArray { return this.gurupsForAdd.get('assign_to_ids') as FormArray; }
  postLoading: boolean = false;





  tag: string = 'PersonelGrubuOlusturComponent --->';
  grupPersonel: any[];
  editGrupPersonel: any[];
  editGrupPersonelForAdd: any[];



  constructor(private organizationService: OrganizasyonService,
    private ref: NbDialogRef<PersonelGrubuOlusturComponent>,
    private fb: FormBuilder) {
    this.intForm();
  }

  ngOnInit() {
    this.organizationService.getMyOrganizationsPersonels().subscribe(
      data => {
        this.grupPersonel = data;
        this.grupPersonel.forEach((el) => {
          el['disabled'] = false;
        });
        this.editGrupPersonel = this.grupPersonel;
        this.editGrupPersonelForAdd = this.grupPersonel;

      });
    this.getGroups();

  }
  getGroups() {
    this.isLoading = true;
    this.organizationService.getOrganizasyonGroups().subscribe(response => {
      this.isLoading = false;

      this.gruplar = response;
      this.gruplar.forEach(el => this.addGroup(el));
      console.log(this.tag, ' Tum gurplar:', this.gruplar);
    }, () => {
      this.isLoading = false;

    });
  }
  intForm() {
    this.form = this.fb.group({
      gurups: this.fb.array([]),
      gurupsForAdd: this.fb.group({
        id: [],
        title: [null, Validators.required],
        assign_to_ids: this.fb.array([]),
      }),

    });
    // this.addPersonelForAdd();
  }
  addGroup(group?) {
    this.gurups.push(this.fb.group({
      id: [group ? group.id : null],
      title: [group ? group.title : null, Validators.required],
      assign_to_ids: this.fb.array([]),
      edit: group ? false : true,
      forAdd: [group ? false : true],
    }));
    const lastIndex: number = ((this.gurups.controls.length - 1) >= 0) ? this.gurups.controls.length - 1 : 0;
    if (group) {
      group.assign_to_ids.forEach(element => {
        this.assign_to_ids(lastIndex).push(this.fb.group(
          {
            name: [element.name],
            apas_id: [element.apas_id],
            added: [false],
          }));
      });
    }
    // else {
    //   this.assign_to_ids(lastIndex).push(this.fb.group(
    //     {
    //       name: [],
    //       apas_id: [],
    //       added: [false],
    //     }));
    // }
    /**
     * {
      apas_id: [person ? person.apas_id : null],
      name: [person ? person.name : null],
      addted: [person ? true : false],
    }
     */
  }
  addPersonel(index: number, person?) {
    this.assign_to_ids(index).push(this.fb.group({
      apas_id: [person ? person.apas_id : null],
      name: [person ? person.name : null],
      added: [true],
    }));
    this.refreshDisableds();

  }
  addPersonelForAdd(person?) {
    this.assign_to_idsForAdd.push(this.fb.group({
      apas_id: [person ? person.apas_id : null, Validators.required],
      name: [person ? person.name : null, Validators.required],
      added: [person ? true : false],
    }));
  }
  unEditGroup(accordionItem: NbAccordionItemComponent, index: number) {
    accordionItem.collapsed = true;
    this.cancelEditGroup(index);
    this.gurups.at(index).get('edit').setValue(false);


  }
  unEditGroupForAdd(accordionItem: NbAccordionItemComponent) {
    console.log(this.tag, 'cleared !');
    this.resetForAdd();
  }
  resetForAdd() {
    this.gurupsForAdd.reset();
    this.assign_to_idsForAdd.clear();
    this.refreshDisabledsForAdd();

  }
  cancelEditGroup(index: number) {
    this.assign_to_ids(index).clear();
    this.gurups.at(index).get('title').reset();
    this.gurups.at(index).get('title').setValue(this.gruplar[index].title);
    this.gruplar[index].assign_to_ids.forEach(el => this.addPersonel(index, el));
  }
  closed() {
    this.ref.close();
  }


  removePersonel(index: number, userIndex: number) {
    this.assign_to_ids(index).removeAt(userIndex);
    this.gurups.at(index).markAsTouched();
    this.refreshDisableds();

  }
  removePersonelForAdd(index: number) {
    this.assign_to_idsForAdd.removeAt(index);
    this.gurupsForAdd.markAsTouched();
    this.refreshDisabledsForAdd();

  }

  selectPersonel(event) {
    // console.log(this.tag, 'event:', event);

  }
  /** Grubu düznelmek için kullanılr */
  editGroup(accordionItem: NbAccordionItemComponent, index) {
    accordionItem.collapsed = false;

    if (this.gurups.controls.some(el =>
      (el.get('edit').value &&
        (el.touched || el.dirty)) &&
      !el.get('forAdd').value)) {
      Swal.fire({
        icon: 'question',
        text: 'Güncellenmemiş alanlar var devam etmek istiyormusunuz?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then(value => {
        if (value.value) {
          this.cancelEditGroup(this.currentEditedGroupIndex);
          this.currentEditedGroupIndex = index;
          this.disableAllGroups();
          this.gurups.at(index).get('edit').setValue(true);
          console.log(this.tag, 'event:', this.gurups.at(index).value);
        }
      });
    } else {
      this.currentEditedGroupIndex = index;
      this.disableAllGroups();
      this.gurups.at(index).get('edit').setValue(true);
      console.log(this.tag, 'event:', this.gurups.at(index).value);
    }

  }
  /**Düzenleme durumundaki gruba personel eklemeye yarar. */
  selectLastPersonel(event, index) {
    console.log(this.tag, 'edited personels:', this.form.value);

    console.log(this.tag, 'event:', event);

    if (event) {
      this.addPersonel(index, { ...event });
      this.gurups.at(index).markAsTouched();

      this.refreshDisableds();
    }
  }

  /**Düzenleme durumundaki gruba personel eklemeye yarar. */
  selectLastPersonelForAdd(event, index?) {
    console.log(this.tag, 'edited personels:', this.form.value);

    console.log(this.tag, 'event:', event);

    if (event) {
      this.addPersonelForAdd({ ...event });
      this.gurupsForAdd.markAllAsTouched();

      this.refreshDisabledsForAdd();
    }
  }

  disableAllGroups() {
    this.gurups.controls.forEach(el => {
      if (!el.get('forAdd').value) {
        el.get('edit').setValue(false);
        el.markAsUntouched();
      }

    });
    this.refreshDisableds();

  }
  refreshDisableds() {

    // console.log(this.tag, 'edited personels:', this.assign_to_ids(this.currentEditedGroupIndex).value);
    this.editGrupPersonel = this.editGrupPersonel.filter((m) => {
      m['disabled'] = this.assign_to_ids(this.currentEditedGroupIndex)
        .controls.some(f => f.get('apas_id').value === m.apas_id);
      return true;
    });
  }
  refreshDisabledsForAdd() {

    // console.log(this.tag, 'edited personels:', this.assign_to_ids(this.currentEditedGroupIndex).value);
    this.editGrupPersonelForAdd = this.editGrupPersonelForAdd.filter((m) => {
      m['disabled'] = this.assign_to_idsForAdd.controls.some(f => f.get('apas_id').value === m.apas_id);
      return true;
    });
  }



  postForm(accordinItem: NbAccordionItemComponent) {
    accordinItem.collapsed = !accordinItem.collapsed;
    const formData = this.valueToFormForAdd();

    if (this.gurupsForAdd.valid && this.assign_to_idsForAdd.length > 0) {
      this.postLoading = true;
      this.organizationService.postPersonelGrup(formData).subscribe(response => {
        this.postLoading = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => {
          this.addGroup(response);
          this.resetForAdd();
        });
        console.log(this.tag, ' Add response:', response);
      }, () => {
        this.postLoading = false;
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

      });
    } else {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      this.gurupsForAdd.markAllAsTouched();
    }
  }
  valueToFormForAdd() {
    console.log(this.tag, 'gurupsForAdd:', this.gurupsForAdd);
    console.log(this.tag, 'gurupsForAdd data:', this.gurupsForAdd.value);
    const data = removeAllNull(this.gurupsForAdd.value);
    const ids: number[] = [];
    if (data.assign_to_ids.length > 0) {
      data.assign_to_ids.forEach(el => ids.push(el.apas_id));
      data.assign_to_ids = ids;
    }
    console.log(this.tag, 'cleared gurupsForAdd data:', data);

    return data;
  }

  patchForm(form: any, accordinItem: NbAccordionItemComponent, index: number) {
    accordinItem.collapsed = !accordinItem.collapsed;
    const formData = this.valueToFormForPatch(form);
    if (form.valid && this.assign_to_ids(index).controls.length > 0) {
      this.patchLoading = true;
      this.organizationService.patchPersonelGrup(formData, formData.id).subscribe(response => {
        console.log(this.tag, 'response:', response);

        this.patchLoading = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => {
          // this.addGroup(response);
          form.get('edit').setValue(false);
          // this.resetForAdd();
        });
        console.log(this.tag, ' Add response:', response);
      }, () => {
        this.patchLoading = false;
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

      });
    } else {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      this.form.markAllAsTouched();
    }
  }
  valueToFormForPatch(from: FormGroup) {
    console.log(this.tag, 'post from:', from);
    console.log(this.tag, 'post from data:', from.value);
    const data = removeAllNull(from.value);
    const ids: number[] = [];
    if (data.assign_to_ids.length > 0) {
      data.assign_to_ids.forEach(el => ids.push(el.apas_id));
      data.assign_to_ids = ids;
    }
    console.log(this.tag, 'cleared post from data:', data);

    return data;
  }
  deleteForm(form: any, accordinItem: NbAccordionItemComponent, index: number) {
    accordinItem.collapsed = !accordinItem.collapsed;

    this.patchLoading = true;
    this.organizationService.deletePersonelGrup(form.get('id').value).subscribe(response => {
      console.log(this.tag, 'response:', response);
      this.patchLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => {
        // this.addGroup(response);
        // form.get('edit').setValue(false);
        this.gurups.removeAt(index);
        // this.resetForAdd();
      });
      console.log(this.tag, ' Add response:', response);
    }, () => {
      this.patchLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

    });

  }
}
