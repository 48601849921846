<nb-card size="giant" class="modalType animated fadeInRight" style="min-width: 90%;">
    <nb-card-header>
        <span>{{cari?.unvan}} ({{data?.note}}) Ödeme Planı Detay </span>
        <button nbButton size="small" status="basic" class="heryerdeClose" (click)="close()">x</button>
    </nb-card-header>
    <nb-card-body class="p-0 m-0" [nbSpinner]="isLoading">
        <table class="table table-bordered">

            <tbody>
                <tr>
                    <th scope="row" colspan="8">
                        <span style="color: #FF7043;">
                            Peşinat Bilgileri
                        </span>
                    </th>
                </tr>
                <tr>
                    <td colspan="2">
                        <label class="mr-2 label">Peşinat Tarihi:</label>
                        <tarih-col [tarih]="data?.advance_payment_date"></tarih-col>
                    </td>
                    <td colspan="2">
                        <label class="label">Peşinat Oranı:</label>
                        {{data?.advance_payment_percentage}}%
                    </td>
                    <td colspan="2">
                        <label class="label">Peşinat Tutarı:</label>
                        <apas-para-item [value]="data?.advance_payment_total" [currency]="data?.currency"></apas-para-item>
                    </td>
                    <td>
                        <label class="label">Ödenen Peşinat Tutarı:</label>
                        <apas-para-item [value]="data?.odenen_pesinat_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                    <td>
                        <label class="label">Kalan Peşinat Tutarı:</label>
                        <apas-para-item [value]="data?.bekleyen_pesinat_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                </tr>
                <tr>
                    <th scope="row" colspan="8">
                        <span style="color: #FF7043;">
                            Taksit Ve Ara Ödeme Bilgileri
                        </span>
                    </th>
                </tr>
                <tr>
                    <td>
                        <label class="label">İlk Taksit Tarihi</label>
                        <tarih-col [tarih]="data?.ilk_taksit_tarihi"></tarih-col>
                    </td>
                    <td>
                        <label class="label">Son Taksit Tarihi</label>
                        <tarih-col [tarih]="data?.son_taksit_tarihi"></tarih-col>
                    </td>
                    <td>
                        <label class="label">Toplam Taksit Oranı:</label>
                        {{data?.installment_percentage}}%
                    </td>
                    <td>
                        <label class="label">Toplam Taksit Tutarı:</label>
                        <apas-para-item [value]="data?.installment_payment_total" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                    <td>
                        <label class="label">Ara Ödeme Oranı:</label>
                        {{data?.interim_percentage}}%
                    </td>
                    <td>
                        <label class="label">Ara Ödeme Tutarı:</label>
                        <apas-para-item [value]="data?.interim_payment_total" [currency]="data?.currency">
                        </apas-para-item>
                    </td>

                    <td>
                        <label class="label">Ödenen Taksit + Ara Ödeme Tutarı</label>
                        <apas-para-item [value]="data?.odenen_taksit_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                    <td>
                        <label class="label">Kalan Taksit + Ara Ödeme Tutarı:</label>
                        <apas-para-item [value]="data?.bekleyen_taksit_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>

                </tr>

                <tr>
                    <th scope="row" colspan="8">
                        <span style="color: #FF7043;">
                            Son Ödeme Bilgileri
                        </span>
                    </th>
                </tr>
                <tr>
                    <td colspan="2">
                        <label class="mr-2 label">Son Ödeme Tarihi:</label>
                        <tarih-col [tarih]="data?.last_payment_date"></tarih-col>
                    </td>

                    <td colspan="2">
                        <label class="label">Son Ödeme Oranı</label>
                        {{data?.last_payment_percentage}}%
                    </td>
                    <td colspan="2">
                        <label class="label">Son Ödeme Tutarı:</label>
                        <apas-para-item [value]="data?.last_payment_total" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                    <td>
                        <label class="label">Ödenen Son Ödeme Tutarı:</label>
                        <apas-para-item [value]="data?.odenen_son_odeme_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                    <td>
                        <label class="label">Kalan Son Ödeme Tutarı:</label>
                        <apas-para-item [value]="data?.bekleyen_son_odeme_tutari" [currency]="data?.currency">
                        </apas-para-item>
                    </td>
                </tr>

                <tr>
                    <th scope="row" colspan="8">
                        <span style="color: #FF7043;">
                            Genel Bilgiler
                        </span>
                    </th>
                </tr>
                <tr style="background:#f3f9ff;">
                    <td colspan="4">
                        <b>Sonraki Taksit Tarihi</b>
                        <tarih-col [tarih]="data?.sonraki_taksit_tarihi"></tarih-col>
                    </td>
                    <td colspan="2">
                        <b>Toplam Tutar: </b>
                        <div>
                            <apas-para-item [value]="data?.total_price" [currency]="data?.currency">
                            </apas-para-item>
                        </div>
                    </td>
                    <td >
                        <b>Toplam Ödenen Tutar: </b>
                        <div>
                            <apas-para-item [value]="data?.payed_total" [currency]="data?.currency">
                            </apas-para-item>
                        </div>
                    </td>
                    <td >
                        <b>Toplam Kalan Tutar: </b>
                        <div>
                            <apas-para-item [value]="data?.debt_total" [currency]="data?.currency">
                            </apas-para-item>
                        </div>
                    </td>

                </tr>

            </tbody>
        </table>
        <div>
            <div class="row mx-2 tableHeader">
                <div class="p-1 col-2">
                    Taksit Tarihi
                </div>

                <div class="p-1 col-2">
                    Tahsilat Tarihi
                </div>

                <div class="p-1 col-2">
                    Taksit Tutarı
                </div>

                <div class="p-1 col-3">
                    Not
                </div>

                <div class="p-1 col-2">
                    Taksit Durumu
                </div>
                <div class="p-1 col-1"></div>
            </div>
            <nb-list>
                <nb-list-item *ngFor="let taksit of data?.taksitler; let i = index" style="min-height:80px">
                    <div class="row w-100">
                        <div class="col-2">
                            <tarih-col [index]="i" [tarih]="taksit.must_pay_date"></tarih-col>
                        </div>

                        <div class="col-2">
                            <tarih-col [tarih]="taksit.date" *ngIf="
                            taksit?.hareket_type === cariHareketTypes.pesinatTahsilat ||
                            taksit?.hareket_type === cariHareketTypes.odemeTaksitTahsilat ||
                            taksit?.hareket_type === cariHareketTypes.araOdemeTahsilat ||
                            taksit?.hareket_type === cariHareketTypes.sonOdemeTahsilat
                            "></tarih-col>
                        </div>
                        <div class="col-2">
                            <apas-para-item [value]="taksit.must_pay_price"></apas-para-item>
                        </div>

                        <div class="col-3">
                            {{taksit.note}}
                        </div>
                        <div class="col-2">
                            <strong [innerHtml]="'hareket_html.'+taksit.hareket_type | translate"></strong>
                        </div>
                        <div class="p-1 col-1 text-right">
                            <button nbButton status="info" size="tiny" [nbPopover]="islemler"
                                [nbPopoverContext]="taksit" nbPopoverPlacement="bottom">İşlemler</button>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <div class="text-center">

            <button nbButton status="primary" size="small" (click)="openTaksitEkle()">Taksit Ekle</button>

            <button class="excel_button extandButton" nbButton size="small" (click)="exportToExcel()"
                [nbSpinner]="excelLoading" [disabled]="excelLoading">
                <i class="fas fa-file-excel"></i>
                <span>
                    Excel Çıktısı Al
                </span>
            </button>

            <button nbButton [nbTooltip]="'Sil'" size="small" class="delete_islem" (click)="sil()"
                [disabled]="isLoading">
                <nb-icon icon='trash-outline'>
                </nb-icon>Sil
            </button>

        </div>
    </nb-card-footer>

</nb-card>

<ng-template #islemler let-data>
    <div class="m-2 p-2">
        <div>
            <button nbButton size="tiny" fullWidth status="info" style="background: #097733 !important;" *ngIf="
            data?.hareket_type === cariHareketTypes.pesinat ||
            data?.hareket_type === cariHareketTypes.odemeTaksit ||
            data?.hareket_type === cariHareketTypes.araOdeme ||
            data?.hareket_type === cariHareketTypes.sonOdeme" (click)="taksitOde(data)">
                Tahsil Et
            </button>
        </div>
        <div>
            <button status="danger" fullWidth nbButton size="tiny" *ngIf="
            data?.hareket_type === cariHareketTypes.pesinat ||
            data?.hareket_type === cariHareketTypes.odemeTaksit ||
            data?.hareket_type === cariHareketTypes.araOdeme ||
            data?.hareket_type === cariHareketTypes.sonOdeme" (click)="odendiOlarakBelirt(data.id)">
                Tahsil Edildi Olarak İşaretle
            </button>

        </div>
        <div>
            <button class="delete_islem" fullWidth nbButton size="tiny" *ngIf="
            data?.hareket_type === cariHareketTypes.pesinatTahsilat ||
            data?.hareket_type === cariHareketTypes.odenmisPesinat ||

            data?.hareket_type === cariHareketTypes.odemeTaksitTahsilat ||
            data?.hareket_type === cariHareketTypes.taksitOdenmis ||

            data?.hareket_type === cariHareketTypes.araOdemeTahsilat ||
            data?.hareket_type === cariHareketTypes.araOdeneOdenmis ||

            data?.hareket_type === cariHareketTypes.sonOdemeTahsilat ||
            data?.hareket_type === cariHareketTypes.odenmisSonOdeme                                 
            " (click)="odemeyiIptalEt(data)"> Tahsilatı İptal Et </button>

        </div>
        <div>
            <button nbButton size="tiny" fullWidth status="warning" (click)="openEdit(data)">Düzenle</button>

        </div>
        <div>
            <button nbButton size="tiny" fullWidth status="danger" class="delete_islem"
                (click)="deleteTaksitItem(data)">Sil</button>
        </div>
    </div>

</ng-template>