<ng-select
              #select
              [items]="personelList$ | async"
              [loading]="isLoading"
              appendTo="body"
              [typeahead]="personelInput$"
              typeToSearchText="Arama yapmak için yazınız."
              bindLabel="name"
              [bindValue]="bindValue"
              [addTag]="false"
              [groupBy]="multiple ? 'group' : null"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              [multiple]="multiple"
              [selectOnTab]="true"
              [formControl]="control"
              [placeholder]="'Personel Seç'"
              [closeOnSelect]="!multiple"
              class="uzun-select"
              ngDefaultControl
              (change)="personelSelected($event)"
          >

      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
          <button nbButton size="small" status="primary" hero (click)="addNewPersonel(select)"> Personel Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="grupOlsutur()" *ngIf="multiple"> Personel Grubu Ekle  </button>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>