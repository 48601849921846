import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PuantajModel, TopluPuantajModel, PuantajMainParams, TopluPuantajDetayModel, PuantajNamesModel } from '../models/puantaj_model';
import { removeAllNull } from '../pages/global-functions';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class PuantajService {
    private readonly API_URL = `${environment.backendurl}api/puantaj/`;

    constructor(private httpClient: HttpClient) { }

    //#region  Taşeron Component
    getPuantaj(): Observable<PuantajModel[]> {
        return this.httpClient.get<PuantajModel[]>(this.API_URL + 'list/');
    }

    addPuantaj(formData): any {
        const url: string = `${this.API_URL}add/`;
        return this.httpClient.post(url, formData);
    }

    getPuantajList<T>(exp: string): Observable<any> {
        const myPath = this.API_URL + 'list/' + exp + '/';
        return this.httpClient.get<T[]>(myPath);
    }
    getCurrentPuantaj(taseron_id: number, santiye_id: number): Observable<any[]> {
        const url = `${this.API_URL}personelcalismalari/${santiye_id}/${taseron_id}/`;
        return this.httpClient.get<any[]>(url);
    }

    postCurrentPuantaj(filter) {
        const url = `${this.API_URL}personelcalismalari/`;
        return this.httpClient.post<any[]>(url, filter);
    }
    /**
     * Belli bir süre aralığındaki @var apas_id 'ye göre puantaj bilgisi getirir.
     * @param from başlangıç tarihi linux timestamp
     * @param to bitiş tarihi linux timestamp
     * @param apas_id puantajı istenen kisinin @var apas_id 'si
     */
    getPuantajForRange(from: number, to: number, apas_id: number): Observable<PuantajModel[]> {
        const url = `${this.API_URL}personel/${from}/${to}/${apas_id}/`;
        return this.httpClient.get<PuantajModel[]>(url);
    }

    getSantiyeListForPuantaj(): Observable<any[]> {
        const url: string = `${this.API_URL}santiyelist/`;

        return this.httpClient.get<any[]>(url);
    }

    postTopluPuantaj(data): Observable<TopluPuantajModel[]> {
        const url: string = `${this.API_URL}newtoplupuantajadd/`;
        return this.httpClient.post<TopluPuantajModel[]>(url, data);
    }
    getTopluPuantaj(data): Observable<TopluPuantajModel[]> {
        const url: string = `${this.API_URL}newtoplupuantajlist/`;
        return this.httpClient.post<TopluPuantajModel[]>(url, data);
    }


    /** Tarih aralığına göre puantaj getirir.
     * @param args aralık ve tür bilgisini belirtir.taseron veya santiye
     */
    getSantiyeMainList(args?: PuantajMainParams): Observable<TopluPuantajModel[]> {
        const url: string = `${this.API_URL}mainlist/`;
        const params: PuantajMainParams = removeAllNull(args);
        return this.httpClient.post<TopluPuantajModel[]>(url, params);
    }
    /** Tarih aralığına göre puantaj getirir.
   * @param args aralık ve tür bilgisini belirtir.taseron veya santiye
   */
    getSantiyeMainListDetay(args?: PuantajMainParams): Observable<TopluPuantajDetayModel> {
        const url: string = `${this.API_URL}listdetay/`;
        const params: PuantajMainParams = removeAllNull(args);
        return this.httpClient.post<TopluPuantajDetayModel>(url, params);
    }

    getPuantajDetay(data: any) {
        const url: string = `${this.API_URL}listdetay/`;
        return this.httpClient.post<any>(url, data);
    }

    getSantiyeMainListNames(args?: PuantajMainParams): Observable<PuantajNamesModel[]> {
        const url: string = `${this.API_URL}detay/`;
        const params: PuantajMainParams = removeAllNull(args);
        return this.httpClient.post<PuantajNamesModel[]>(url, params);
    }

    getExcelData(filter: any) {
        const url: string = `${this.API_URL}excel/`;
        filter = removeAllNull(filter);
        return this.httpClient.post<PuantajNamesModel[]>(url, filter);
    }

    getNewList(filter: any) {
        const url: string = `${this.API_URL}newlist/`;
        return this.httpClient.post(url, filter);
    }

    getEmployeePuantaj(data: any): Observable<any> {
        const url: string = `${this.API_URL}employeepuantaj/`;
        return this.httpClient.post<any>(url, data);
    }
}
