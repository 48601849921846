import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { YapilanIslerEkleComponent } from '../yapilan-isler-ekle/yapilan-isler-ekle.component';

@Component({
  selector: 'yapilan-is-ekle-modal',
  templateUrl: './yapilan-is-ekle-modal.component.html',
  styleUrls: ['./yapilan-is-ekle-modal.component.css']
})
export class YapilanIsEkleModalComponent implements OnInit {
  @Input() santiye_id: number;
  @Input() canEdit: boolean = true;
  @Input() pursantajId: number;
  @Input() isModal: boolean;
  constructor(private ref: NbDialogRef<YapilanIsEkleModalComponent>) { }

  ngOnInit() {
  }

  close(){
    this.ref.close();
  }
}
