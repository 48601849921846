

import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../../pages/global-functions';
import { IsMakinalariService } from '../../../../services/is_makinalari.service';
import { ControlValueAccessorConnector } from '../../../../shared/control-value-accessor-connector';
@Component({
  selector: 'makina-type-select',
  template: `
  <ng-select
      [items]="makinaTypeList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="makinaInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindValue="id"
      bindLabel="title"
      [addTag]="addTagPromise"
      [addTagText]="'Ekle'"
      [multiple]="multiple"
      [selectOnTab]="true"
      [placeholder]="'Makina Tipi'"
      class="uzun-select"
      ngDefaultControl
      (change)="makinaSelect($event)"
      [formControl]="control"
  >
  </ng-select>`
  ,
  providers: [
    IsMakinalariService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MakinaTypeSelectComponent),
      multi: true,
    },
  ],
})
export class MakinaTypeSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {
  isLoading: boolean = false;

  // tslint:disable-next-line: max-line-length
  @Input() bindValue: string = 'id'; // true ? sadece benim şirketime ait şantiyeleri liste : ilişkili olduğum tüm şantiyeleri listele

  // tslint:disable-next-line: max-line-length
  @Input() is_active: boolean = true; // null : tüm şanyiyeleri listele - true : sadece aktif şantiyeleri listele - false : sadece pasif şantiyeleri listele

  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() own: boolean;
  tag: string = '------ Machine Type Select ------------> ';
  constructor(
    private isMakinalariService: IsMakinalariService,
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.tag, changes);
    this.firstList();
  }

  ngOnInit(): void {
    this.getMachineTypes();
  }
  makinaTypeList$: Observable<any[]>;
  makinaInput$ = new Subject<string>();
  getMachineTypes() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    this.makinaTypeList$ = concat(
      of([]), // default items
      this.makinaInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.isMakinalariService.getIsMakinalariTypes(
            removeAllNull({ search: term }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getMachineTypes();
    setTimeout(() => {
      this.makinaInput$.next('');
    }, 300);
  }

  addTagPromise = (title) => {

    return this.isMakinalariService.addMachineType({ title: title }).subscribe(resp => {
      this.change.emit(resp);
      this.makinaInput$.next(resp.title);
    });
  }
  makinaSelect(event) {
    this.change.emit(event);
  }


}

