import { Injectable, OnInit } from '@angular/core';
import { constants } from 'os';


@Injectable()
export class ParaInput implements OnInit {
    binlikAyrac = '.';
    floatingAyrac: string = this.binlikAyrac === '.' ? ',' : '.';
    floatingCount: number = 0;
    // tslint:disable-next-line: max-line-length
    numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete', 'Tab']; // '-', 

    constructor() {
        try {
            this.binlikAyrac = JSON.parse(localStorage.getItem('org')).ayrac;
            this.floatingAyrac = this.binlikAyrac === '.' ? ',' : '.';

        } catch (error) {
            
        }
        document.documentElement.addEventListener('keydown', event => {
            const elm = event.target;
            if (elm instanceof HTMLInputElement) {

                if (elm.className.indexOf('paraMask') !== -1) {

                    if (this.numbers.indexOf(event.key) === -1 || event.key === this.binlikAyrac) {
                        event.preventDefault();
                        return;
                    }
                    if (elm.value.length > 0 && event.key === '-') { // negatif işareti ancak başta olabilir
                        event.preventDefault();
                        return;
                    }
                    if (elm.value.length === 0 && event.key === this.floatingAyrac) {
                        event.preventDefault();
                        return;
                    }
                    // tslint:disable-next-line: max-line-length
                    if (event.key === this.floatingAyrac && elm.value.indexOf(this.floatingAyrac) !== -1) { // zaten bi tane virgül yazmışsa
                        event.preventDefault();
                        return;
                    }

                }
            }
        });
        document.documentElement.addEventListener('keyup', event => {
            let negatifmi = 0;
            const elm = event.target;

            if (elm instanceof HTMLInputElement) {

                if (elm.className.indexOf('paraMask') !== -1) {
                    if (event.key === this.floatingAyrac) {

                    }

                    let para = '';
                    para = elm.value;
                    const dizi = [...para];
                    if (dizi[0] === '-') {
                        // negatif sayı ise '-' yi sil sonra tekrar koyacaksın
                        dizi.splice(0, 1); negatifmi = 1; } 
                    dizi.forEach((item, index) => { // binlik ayraç varsa temizle, aşağıda yeniden yazacaksın
                        if (item === this.binlikAyrac) dizi.splice(index, 1);
                    })
                    let floatingAyrac_Index = dizi.indexOf(this.floatingAyrac);

                    let para_len = 0;

                    if (floatingAyrac_Index != -1) { //floating ayraç var
                        para_len = floatingAyrac_Index;
                    } else para_len = dizi.length;


                    let kac_tane_ucluk = Math.floor(para_len / 3); //12.564.256.878--->3
                    let uclukten_kalan = para_len % 3; // /12.564.256.878--->2

                    for (let i = 1; i < kac_tane_ucluk; i++) { // 1234567890 len=10 index=9 
                        dizi.splice((para_len - (3 * i)), 0, this.binlikAyrac);
                    }

                    if (uclukten_kalan > 0 && kac_tane_ucluk > 0) { //1.234
                        dizi.splice(uclukten_kalan, 0, this.binlikAyrac);
                    }

                    para = '';
                    if (negatifmi) { para += '-'; } // sildiğin - yi tekrar yaz (binlik ayıraçta 3 er li grupta sqayılmasın diye)
                    dizi.forEach(item => {
                        para += item;
                    })
                    elm.value = para;

                }
            }
        });



    }

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
}


export function paraInputManuel(sayi) {
    const binlikAyrac = JSON.parse(localStorage.getItem('org')).ayrac;
    const floatingAyrac: string = binlikAyrac === '.' ? ',' : '.';
    let negatifmi = 0;
    let para = '';
    para = String(sayi);
    const dizi = [...para];
    if (dizi[0] == '-') { dizi.splice(0, 1); negatifmi = 1; } // negatif sayı ise '-' yi sil sonra tekrar koyacaksın

    const floatingAyrac_Index = dizi.indexOf('.');

    let para_len = 0;

    // tslint:disable-next-line: triple-equals
    // floating ayraç var
    if (floatingAyrac_Index !== -1) {

        para_len = floatingAyrac_Index;
        dizi.splice(floatingAyrac_Index, 1, floatingAyrac)
    } else para_len = dizi.length;


    const kac_tane_ucluk = Math.floor(para_len / 3); // 12.564.256.878--->3
    const uclukten_kalan = para_len % 3; // /12.564.256.878--->2


    for (let i = 1; i < kac_tane_ucluk; i++) { // 1234567890 len=10 index=9 
        dizi.splice((para_len - (3 * i)), 0, binlikAyrac);
    }

    if (uclukten_kalan > 0 && kac_tane_ucluk > 0) { // 1.234
        dizi.splice(uclukten_kalan, 0, binlikAyrac);
    }

    para = '';
    if (negatifmi) { para += '-'; } // sildiğin - yi tekrar yaz (binlik ayıraçta 3 er li grupta sqayılmasın diye)
    dizi.forEach(item => {
        para += item;
    })
    return para;

}

