import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, concat, of } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { DigerFirmaPersonelComponent } from '../../puantaj/puantaj-ekle/diger-firma-personel-ekle/personel-ekle.component';

@Component({
  selector: 'other-personel-select',
  template: `<ng-select
      #select
      [items]="personelList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="personelInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindLabel="name"
      [bindValue]="bindValue"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'Personel Seç'"
      class="uzun-select"
      ngDefaultControl
      (change)="personelSelected($event)"
  >
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
      <ng-template ng-footer-tmp *ngIf="addTag && organization?.demo === true ">
          <button nbButton size="small" status="primary" hero (click)="addNewPersonel()"> Personel Ekle </button>
      </ng-template>

  </ng-select>`
  ,
  providers: [
    OrganizasyonService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtherPersonelSelectComponent),
      multi: true,
    },
  ],
})
export class OtherPersonelSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  isLoading: boolean = false;
  @ViewChild('select') selectBox: NgSelectComponent;
  // tslint:disable-next-line: max-line-length
  @Input() bindValue: string = 'id'; // true ? sadece benim şirketime ait şantiyeleri liste : ilişkili olduğum tüm şantiyeleri listele

  // tslint:disable-next-line: max-line-length
  @Input() is_active: boolean = true; // null : Sadece aktif personelleri listele

  @Input() multiple: boolean = false;

  @Input() showAddButtons: boolean = false;

  @Input() organizationId: number;
  @Input() isUser: boolean;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() addTag: boolean = false;

  tag: string = '------ Personel Select ------------> ';
  constructor(
    private organizationService: OrganizasyonService,
    injector: Injector,
    private nbDialogService: NbDialogService,
  ) {
    super(injector);
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.tag, changes);
    this.firstList();
    if (changes?.organizationId) {
      this.getOrganizationInformation();
    }
  }

  ngOnInit(): void {
    this.getPersonel();
  }

  organization: any;
  getOrganizationInformation() {
    this.organizationService.getRelatedCompany(this.organizationId).subscribe(resp => {
      this.organization = resp;
    });
  }

  personelSelected(event) {
    this.change.emit(event);
  }

  personelList$: Observable<any[]>;
  personelInput$ = new Subject<string>();
  getPersonel() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    const context = {
      filter: { is_active: this.is_active, is_user: this.isUser },
      organization_id: this.organizationId,

    };
    this.personelList$ = concat(
      of([]), // default items
      this.personelInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.organizationService.getOtherOrganizationsPersonelsV2({ ...context, ...{ search: term } }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getPersonel();
    setTimeout(() => {
      this.personelInput$.next('');
    }, 300);
  }

  addNewPersonel() {
    // Bu Kısım Henüz Yapılmadı. Firma demo ise ekleme yapılabilir.
    // tslint:disable-next-line: max-line-length
    this.selectBox.close();
    this.nbDialogService.open(
      DigerFirmaPersonelComponent, {
      closeOnBackdropClick: false,
      context: { firma: this.organization },
    },
    ).onClose.subscribe(val => {

      if (val) {
        this.control.setValue(val[this.bindValue]);
      }
    });
  }
}
