import { Component, OnInit, Input } from '@angular/core';
import { tarih_format } from '../../../../pages/constants';
import { NbDialogService } from '@nebular/theme';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { ApasInfiniteScrollComponent } from '../../../Apas-Modules/infinite-scroll/infinite-scroll.component';
import { HttpErrorResponse } from '@angular/common/http';
import { YapilanIslerDetayComponent } from '../yapilan-isler-detay/yapilan-isler-detay.component';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { addOrUpdate, removeAllNull } from '../../../../pages/global-functions';
import { YapilanIslerEkleComponent } from '../yapilan-isler-ekle/yapilan-isler-ekle.component';
import { YapilanIslerGrafikComponent } from '../yapilan-isler-grafik/yapilan-isler-grafik.component';
import { Router } from '@angular/router';

@Component({
  selector: 'yapilan-isler-dashboard',
  templateUrl: './yapilan-isler-dashboard.component.html',
  styleUrls: ['./yapilan-isler-dashboard.component.css'],
  providers: [YapilanIslerService],
})
export class YapilanIslerDashboardComponent implements OnInit {
  isLoading: boolean = false;
  durdur = false;
  pageRowCount: number = 20;
  tarih_format = tarih_format;
  spinner: boolean = true;
  form: FormGroup;
  rows = [];
  @Input() santiye_id: number;
  datas: any;
  constructor(
    private nbDiaologService: NbDialogService,
    private _yapilanIslerService: YapilanIslerService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getYapilanIsList();
  }


  initForm() {
    this.form = this.fb.group({
      santiye_id: [this.santiye_id],
    });

  }

  getYapilanIsList() {
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this._yapilanIslerService.getAggreagetdYapilanIslerList(formVal).subscribe(data => {
      data.forEach(elm => {
        this.rows = addOrUpdate(this.rows, elm, 'pursantaj_id');
      },
      );
      this.rows = [...this.rows];
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      this.durdur = true;
    });

  }

  filtrele() {
    this.rows = [];
    this.durdur = false;
    this.getYapilanIsList();
  }


  yapilanIsDetay(item) {
    this.nbDiaologService.open(YapilanIslerDetayComponent, {
      context: {
        yapilanIs: item,
      },
    }).onClose.subscribe(() => {
      this.filtrele();
    });
  }

  yapilanIsEkle(event?) {
    this.nbDiaologService.open(YapilanIslerEkleComponent, { context: { santiye_id: this.santiye_id, row: event } },
    ).onClose.subscribe((val) => {
      this.filtrele();
    });
  }


  grafikModal(item) {
    const data = { santiye_id: this.santiye_id, pursantaj_id: item.pursantaj_id };
    this.nbDiaologService.open(YapilanIslerGrafikComponent, {
      context: {
        data: data,
      },
    }).onClose.subscribe(() => {
      this.filtrele();
    });
  }

  goToYapianIsler() {
    const url: string = `pages/santiye/yapilan-isler/${this.santiye_id}`;
    this.router.navigateByUrl(url);
  }

}
