import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class IsGuvenligiServiceV2 {

    private readonly API_URL_V1 = `${environment.backendurl}api/isguvenligi/`;

    private readonly API_URL = `${this.API_URL_V1}` + 'v2/';

    constructor(private httpClient: HttpClient) { }

    getFileTypeList(): Observable<any[]> {
        const url: string = this.API_URL_V1 + 'dosya/dosyatipi/';
        return this.httpClient.get<any[]>(url);
    }

    /** Ramak Kala Region */
    getRamakKalaList(data): Observable<any> {
        const url: string = `${this.API_URL}ramakkala/list/`;
        return this.httpClient.post(url, data);
    }

    getRamakKalaDetail(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}ramakkala/detail/${identifier}/`;
        return this.httpClient.get<any>(url);
    }

    addRamakKala(data): Observable<any> {
        const url: string = `${this.API_URL}ramakkala/add/`;
        return this.httpClient.post(url, data);
    }

    updateRamakKala(identifier, data): Observable<any> {
        const url: string = `${this.API_URL}ramakkala/update/${identifier}/`;
        return this.httpClient.patch(url, data);
    }

    deleteRamakKala(identifier): Observable<any> {
        const url: string = `${this.API_URL}ramakkala/delete/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    /** // Ramak Kala Region END // */

    /** // Uygunsuzluk Region // */

    getUygunsuzlukList(data): Observable<any> {
        const url: string = `${this.API_URL}uygunsuzluk/list/`;
        return this.httpClient.post(url, data);
    }

    getUygunsuzlukDetail(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}uygunsuzluk/detail/${identifier}/`;
        return this.httpClient.get<any>(url);
    }

    addUygunsuzluk(data): Observable<any> {
        const url: string = `${this.API_URL}uygunsuzluk/add/`;
        return this.httpClient.post(url, data);
    }

    updateUygunsuzluk(identifier, data): Observable<any> {
        const url: string = `${this.API_URL}uygunsuzluk/update/${identifier}/`;
        return this.httpClient.patch(url, data);
    }

    deleteUygunsuzluk(identifier): Observable<any> {
        const url: string = `${this.API_URL}uygunsuzluk/delete/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    /** // Uygunsuzluk Region END // */



    /** // Kaza Region // */

    getKazaList(data): Observable<any> {
        const url: string = `${this.API_URL}kaza/list/`;
        return this.httpClient.post(url, data);
    }

    getKazaDetail(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}kaza/detail/${identifier}/`;
        return this.httpClient.get<any>(url);
    }

    addKaza(data): Observable<any> {
        const url: string = `${this.API_URL}kaza/add/`;
        return this.httpClient.post(url, data);
    }

    updateKaza(identifier, data): Observable<any> {
        const url: string = `${this.API_URL}kaza/update/${identifier}/`;
        return this.httpClient.patch(url, data);
    }

    deleteKaza(identifier): Observable<any> {
        const url: string = `${this.API_URL}kaza/delete/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    /** // Kaza Region END // */



    /** // İmza Region // */

    getImzaList(data): Observable<any> {
        const url: string = `${this.API_URL}imza/list/`;
        return this.httpClient.post(url, data);
    }

    getImzaDetail(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}imza/detail/${identifier}/`;
        const req = new HttpRequest('GET', url, { reportProgress: true });
        return this.httpClient.request(req);
    }

    addImza(data): Observable<any> {
        const url: string = `${this.API_URL}imza/add/`;
        return this.httpClient.post(url, data);
    }

    updateImza(identifier, data): Observable<any> {
        const url: string = `${this.API_URL}imza/update/${identifier}/`;
        return this.httpClient.patch(url, data);
    }

    deleteImza(identifier): Observable<any> {
        const url: string = `${this.API_URL}imza/delete/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    sozlesmeImzala(identifier): Observable<any> {
        const url: string = `${this.API_URL}imza/sign/${identifier}/`;
        return this.httpClient.get(url);
    }

    sozlesmeReddet(identifier): Observable<any> {
        const url: string = `${this.API_URL}imza/refuse/${identifier}/`;
        return this.httpClient.get(url);
    }
    /** // İmza Region END // */
}
