<nb-card class="animated fadeInRight"  size="giant"   [nbSpinner]="isLoading" style="position: absolute; top: 0; right: 0; height: 100%; min-width: 30%;border: 0;">
  <nb-card-header>
    {{rows[0]?.pursantaj_title}}
    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
    color: #000 !important;
    float: right;
    font-size: 18px;">X</button>
  </nb-card-header>
  <nb-card-body class="p-1">

    <div class="text-center"> 
      <ngx-charts-line-chart
          [view]="[1000, 250]"
          [scheme]="colorScheme"
          [results]="veriler"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [autoScale]="true"
          [timeline]="true"
          [yAxis]="showYAxis"
          [legend]="false"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel">
      </ngx-charts-line-chart>
    </div>

      <div class="row w-100 p-0 m-0 apsHeaderTable">

        <div class="col-md-2">
          <span>Tarih</span>
        </div>
        <!-- <div class="col-md-2">
          <span>İmalat Cinsi</span>
        </div> -->
        <div class="col-md-3">
          <span>Açıklama</span>
        </div>
        <div class="col-md ">
          <span>Durum</span>
        </div>
        <div class="col-md-4">
          <span>Detay</span>
        </div>
  
      </div>

  
      <nb-list *ngIf="rows && rows.length > 0" >
  
        <nb-list-item class="aps-hover p-0 listCustom linked-div" *ngFor="let row of rows;let i = index;"> 
          <div class="col-md-2" (click)="yapilanIsDetay(row)">
            <span><b>{{i+1}}</b> | {{row.date | date:tarih_format}}  </span>
          </div>
          <!-- <div class="col-md-2" (click)="yapilanIsDetay(row)">
            <span>{{row.title}} </span>
          </div> -->
          <div class="col-md-3" (click)="yapilanIsDetay(row)">
            <span>{{row.desc}}</span>
          </div>
          <div class="col-md" (click)="yapilanIsDetay(row)">
            <span> 
            <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>0 && row.work_done_percentage <=30" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#F44336'" [innerStrokeColor]="'#FFCDD2'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="3000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
  
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>30 && row.work_done_percentage <=60" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#FF9800'" [innerStrokeColor]="'#FFE0B2'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="3000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
  
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>60 && row.work_done_percentage <=90" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#2196F3'" [innerStrokeColor]="'#BBDEFB'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="3000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
  
          <circle-progress [percent]="row.work_done_percentage" [radius]="20" [outerStrokeWidth]="6"
            *ngIf="row.work_done_percentage>90 && row.work_done_percentage <=100" [innerStrokeWidth]="6" [space]="-6"
            [outerStrokeColor]="'#4CAF50'" [innerStrokeColor]="'#C8E6C9'" [titleFontSize]="'12'" [unitsFontSize]="'12'"
            [showSubtitle]="false" [animation]="true" [animationDuration]="3000" [startFromZero]="false"
            [responsive]="false" [radius]="30">
          </circle-progress>
            </span>
          </div>
          <div class="col-md-4">
           
            <button nbButton size="small" status="primary" (click)="yapilanIsDetay(row)">Tüm Detayları Gör</button>
            <button nbButton size="small" status="warning" (click)="yapilanIsDetay(row,true)">Güncelle</button> 
            <button nbButton size="small" status="danger" (click)="deleteYapilanIs(row)">Sil</button>

              
          </div>
  
  
        </nb-list-item>
  
      
      </nb-list>
          
  </nb-card-body>


 
</nb-card>