import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MuhasebeService } from '../../../../..//muhasebe-service/muhasebe.service';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ApasDecryptePipe } from '../../../../../../../directives/apas-decrypte-pipe';
import {
  convertDateWithTZ,
  exportExcelSheetListeWithHeaders,
  findMoneyFormatForExcel,
  formatNumberWithCurrency,
  writeExcelFile,
} from '../../../../../../global-functions';
import { OdemePlaniTaksitOdeComponent } from '../odeme-plani-taksit-ode/odeme-plani-taksit-ode.component';
import { TranslateService } from '@ngx-translate/core';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import { cariHareketTypes, responseKodlari } from 'src/app/pages/constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TaksitEkleComponent } from '../taksit-ekle/taksit-ekle.component';
import { TaksitDuzenleComponent } from '../taksit-duzenle/taksit-duzenle.component';

@Component({
  selector: 'apx-odeme-plani-detay',
  templateUrl: './odeme-plani-detay.component.html',
  styleUrls: ['./odeme-plani-detay.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [MuhasebeService],
})
export class OdemePlaniDetayComponent implements OnInit {
  instanceId;
  cari;
  data;
  cariUnvan;
  isLoading: boolean = false;
  cariHareketTypes = cariHareketTypes;

  constructor(
    private ref: NbDialogRef<OdemePlaniDetayComponent>,
    private muhasebeService: MuhasebeService,
    private nbDialogService: NbDialogService,
    private apasDecrypte: ApasDecryptePipe,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    console.log('Gelen Data : ', this.instanceId);
    this.getOdemePlaniDetail();
  }

  getOdemePlaniDetail() {
    console.log('DATA ID : ', this.instanceId);
    this.isLoading = true;
    this.muhasebeService.getOdemePlaniDetail(this.instanceId).subscribe((resp: any) => {
      resp = this.clearItem(resp);
      console.log('resp taksit', resp);
      this.data = resp;
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  close() {
    this.ref.close();
  }

  clearItem(data) {
    data.taksitler.forEach(item => {
      item.must_pay_date = convertDateWithTZ(item, 'must_pay_date');
      item.date = convertDateWithTZ(item, 'date');
      item.total_price = Number(this.apasDecrypte.transform(item?.total_price));
      item.must_pay_price = Number(this.apasDecrypte.transform(item?.must_pay_price));
    });
    data.taksitler = data.taksitler.sort((x, y) => x.date - y.date);
    data.advance_payment_date = convertDateWithTZ(data, 'advance_payment_date');
    data.sonraki_taksit_tarihi = convertDateWithTZ(data, 'sonraki_taksit_tarihi');
    data.son_taksit_tarihi = convertDateWithTZ(data, 'son_taksit_tarihi');
    data.ilk_taksit_tarihi = convertDateWithTZ(data, 'ilk_taksit_tarihi');
    data.last_payment_date = convertDateWithTZ(data, 'last_payment_date');


    data.total_price = Number(this.apasDecrypte.transform(data?.total_price));
    data.advance_payment_total = Number(this.apasDecrypte.transform(data?.advance_payment_total));

    data.odenen_pesinat_tutari = Number(this.apasDecrypte.transform(data?.odenen_pesinat_tutari));
    data.bekleyen_pesinat_tutari = Number(this.apasDecrypte.transform(data?.bekleyen_pesinat_tutari));

    data.installment_payment_total = Number(this.apasDecrypte.transform(data?.installment_payment_total));
    data.odenen_taksit_tutari = Number(this.apasDecrypte.transform(data?.odenen_taksit_tutari));
    data.bekleyen_taksit_tutari = Number(this.apasDecrypte.transform(data?.bekleyen_taksit_tutari));
    data.payed_total = Number(this.apasDecrypte.transform(data?.payed_total));
    data.debt_total = Number(this.apasDecrypte.transform(data?.debt_total));

    data.interim_payment_total = Number(this.apasDecrypte.transform(data?.interim_payment_total));
    data.odenen_son_odeme_tutari = Number(this.apasDecrypte.transform(data?.odenen_son_odeme_tutari));
    data.bekleyen_son_odeme_tutari = Number(this.apasDecrypte.transform(data?.bekleyen_son_odeme_tutari));
    data.last_payment_total = Number(this.apasDecrypte.transform(data?.last_payment_total));


    return data;
  }
  taksitOde(data) {

    this.nbDialogService.open(
      OdemePlaniTaksitOdeComponent,
      { context: { selectedTaksit: data, cariId: data.sender_cari_id } },
    ).onClose.subscribe(() => {
      this.getOdemePlaniDetail();
    });
  }

  sil() {
    const message: SweetAlertOptions = responseKodlari.shureToDelete as SweetAlertOptions;
    message.html = `${this.data.note} Ödeme Planını Silmek İstediğinizden Emin Misiniz?
    <br/> Onaylamanız Durumunda Bu Ödeme Planı ile ilgili olan <b>tüm cari hareketler silinecektir</b>.
    <br/> <b>Bu İşlem Geri Alınamaz.</b>
    `;

    Swal.fire(message).then(value => {
      if (value.isConfirmed) {
        this.isLoading = true;
        this.muhasebeService.deleteOdemePlani(this.data.id).subscribe(resp => {
          this.isLoading = false;
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.close();
        }, (err) => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          this.isLoading = false;
        });
      }
    });
  }

  exportMainPageToExcel(workBook: Workbook) {
    const anaBaslik = `${this.cari?.unvan} (${this.data?.note}) Ödeme Planı Detay`;
    const sheet = workBook.addWorksheet('Genel Bilglier');


    // Genişlikler
    sheet.getColumn(1).width = 15;
    sheet.getColumn(2).width = 15;
    sheet.getColumn(3).width = 15;
    sheet.getColumn(4).width = 15;
    sheet.getColumn(5).width = 15;
    sheet.getColumn(6).width = 15;
    sheet.getColumn(7).width = 30;
    sheet.getColumn(8).width = 30;

    let baslikIndex = 1;

    const anaRow = sheet.getRow(baslikIndex);
    sheet.mergeCells(baslikIndex, baslikIndex, baslikIndex, 8);
    anaRow.height = 30;
    const anaBaslikFont = {
      name: 'Arial Black',
      color: { argb: 'ff7043' },
      family: 2,
      size: 20,
      bold: true,
    };

    const araBaslikFont = {
      name: 'Arial Black',
      color: { argb: 'ff7043' },
      family: 2,
      size: 12,
      bold: true,
    };
    const formula = findMoneyFormatForExcel(this.data.currency);
    const moneyFormlula = formula ? formula : '₺#.##0,00;[RED]-₺#.##0,00';

    const anacell = anaRow.getCell(1);
    anacell.value = anaBaslik;
    anaRow.font = anaBaslikFont;
    anaRow.alignment = { vertical: 'middle', horizontal: 'center' };
    baslikIndex += 1;


    const pesinatRow = sheet.getRow(baslikIndex);
    pesinatRow.font = araBaslikFont;

    const pesinatTitleCell = pesinatRow.getCell(1);
    pesinatRow.height = 30;
    pesinatTitleCell.value = 'Peşinat Bilgileri';
    pesinatRow.alignment = { vertical: 'middle', horizontal: 'left' };

    sheet.mergeCells(baslikIndex, 1, baslikIndex, 8);
    baslikIndex += 1;

    const pesinatTitleContentRow = sheet.getRow(baslikIndex);
    const pesinatContentRow = sheet.getRow(baslikIndex + 1);

    pesinatContentRow.height = 20;
    pesinatTitleContentRow.height = 20;

    pesinatTitleContentRow.alignment = { vertical: 'middle', horizontal: 'left' };
    pesinatContentRow.alignment = { vertical: 'middle', horizontal: 'left' };

    const pesinatTarihiTitleCell = pesinatTitleContentRow.getCell(1);
    const pesinatTarihiContentCell = pesinatContentRow.getCell(1);

    // Peşinat Tarihi 2x
    sheet.mergeCells(baslikIndex, 1, baslikIndex, 2);
    sheet.mergeCells(baslikIndex + 1, 1, baslikIndex + 1, 2);

    pesinatTarihiTitleCell.value = `Peşinat Tarihi`;
    pesinatTarihiContentCell.value = moment(this.data.advance_payment_date).format('ll');


    // Peşinat Onarı 2x
    sheet.mergeCells(baslikIndex, 3, baslikIndex, 4);
    sheet.mergeCells(baslikIndex + 1, 3, baslikIndex + 1, 4);

    const pesinatOranTitleCell = pesinatTitleContentRow.getCell(3);
    const pesinatOranContentCell = pesinatContentRow.getCell(3);
    pesinatOranTitleCell.value = `Peşinat Oranı`;
    pesinatOranContentCell.value = `%${this.data.advance_payment_percentage}`;


    // Peşinat Tutarı 2x
    sheet.mergeCells(baslikIndex, 5, baslikIndex, 6);
    sheet.mergeCells(baslikIndex + 1, 5, baslikIndex + 1, 6);

    const pesinatTutarTitleCell = pesinatTitleContentRow.getCell(5);
    const pesinatTutarContentCell = pesinatContentRow.getCell(5);
    pesinatTutarTitleCell.value = `Peşinat Tutarı`;
    pesinatTutarContentCell.numFmt = moneyFormlula;
    pesinatTutarContentCell.value = Number(this.data.advance_payment_total);

    const pesinatOdenenTitleCell = pesinatTitleContentRow.getCell(7);
    const pesinatOdenenContentCell = pesinatContentRow.getCell(7);
    pesinatOdenenTitleCell.value = `Ödenen Peşinat Tutarı`;
    pesinatOdenenContentCell.numFmt = moneyFormlula;
    pesinatOdenenContentCell.value = Number(this.data.odenen_pesinat_tutari);


    const pesinatKalanTitleCell = pesinatTitleContentRow.getCell(8);
    const pesinatKalanContentCell = pesinatContentRow.getCell(8);

    pesinatKalanTitleCell.value = `Kalan Peşinat Tutarı`;
    pesinatKalanContentCell.numFmt = moneyFormlula;
    pesinatKalanContentCell.value = Number(this.data.bekleyen_pesinat_tutari);

    baslikIndex += 2;


    const taksitMainTitleRow = sheet.getRow(baslikIndex);
    taksitMainTitleRow.font = araBaslikFont;

    const taksitMainTitleCell = taksitMainTitleRow.getCell(1);
    taksitMainTitleRow.height = 30;
    taksitMainTitleCell.value = 'Taksit Ve Ara Ödeme Bilgileri';
    taksitMainTitleCell.alignment = { vertical: 'middle', horizontal: 'left' };

    sheet.mergeCells(baslikIndex, 1, baslikIndex, 8);
    baslikIndex += 1;

    const taksitContentTitleRow = sheet.getRow(baslikIndex);
    const taksitContentRow = sheet.getRow(baslikIndex + 1);

    taksitContentTitleRow.height = 20;
    taksitContentRow.height = 20;

    taksitContentRow.alignment = { vertical: 'middle', horizontal: 'left' };
    taksitContentRow.alignment = { vertical: 'middle', horizontal: 'left' };

    // TAKSİTLER
    const ilkTaksitTarihiTitleCell = taksitContentTitleRow.getCell(1);
    const ilkTaksitTarihiContentCell = taksitContentRow.getCell(1);
    ilkTaksitTarihiTitleCell.value = `İlk Taksit Tarihi`;
    ilkTaksitTarihiContentCell.value = moment(this.data?.ilk_taksit_tarihi).format('ll');

    const sonTaksitTarihiTitleCell = taksitContentTitleRow.getCell(2);
    const sonTaksitTarihiContentCell = taksitContentRow.getCell(2);
    sonTaksitTarihiTitleCell.value = `Son Taksit Tarihi`;
    sonTaksitTarihiContentCell.value = moment(this.data?.son_taksit_tarihi).format('ll');


    const taksitOranTitleCell = taksitContentTitleRow.getCell(3);
    const taksitOranContentCell = taksitContentRow.getCell(3);
    taksitOranTitleCell.value = `Toplam Taksit Oranı`;
    taksitOranContentCell.value = `%${this.data?.installment_percentage}`;


    const taksitTutarTitleCell = taksitContentTitleRow.getCell(4);
    const taksitTutarContentCell = taksitContentRow.getCell(4);
    taksitTutarTitleCell.value = `Toplam Taksit Tutarı`;
    taksitTutarContentCell.numFmt = moneyFormlula;
    taksitTutarContentCell.value = Number(this.data?.installment_payment_total);

    // ARA ÖDEMELER
    const araOdemeOranTitleCell = taksitContentTitleRow.getCell(5);
    const araOdemeOranContentCell = taksitContentRow.getCell(5);
    araOdemeOranTitleCell.value = `Ara Ödeme Oranı`;
    araOdemeOranContentCell.value = `%${this.data?.interim_percentage}`;

    const araOdemeTutarTitleCell = taksitContentTitleRow.getCell(6);
    const araOdemeTutarContentCell = taksitContentRow.getCell(6);
    araOdemeTutarTitleCell.value = `Ara Ödeme Tutarı`;
    araOdemeTutarContentCell.numFmt = moneyFormlula;
    araOdemeTutarContentCell.value = Number(this.data?.interim_payment_total);


    const taksitOdenenTutarTitleCell = taksitContentTitleRow.getCell(7);
    const taksitOdenenTutarContentCell = taksitContentRow.getCell(7);
    taksitOdenenTutarTitleCell.value = `Ödenen Taksit + Ara Ödeme Tutarı`;
    taksitOdenenTutarContentCell.numFmt = moneyFormlula;
    taksitOdenenTutarContentCell.value = Number(this.data?.odenen_taksit_tutari);


    const taksitKalanTutarTitleCell = taksitContentTitleRow.getCell(8);
    const taksitKalanTutarContentCell = taksitContentRow.getCell(8);
    taksitKalanTutarTitleCell.value = `Kalan Taksit + Ara Ödeme Tutarı`;
    taksitKalanTutarContentCell.numFmt = moneyFormlula;
    taksitKalanTutarContentCell.value = Number(this.data?.bekleyen_taksit_tutari);

    baslikIndex += 2;



    // SON ÖDEME BİLGİLERİ

    const sonOdemeRow = sheet.getRow(baslikIndex);
    sonOdemeRow.font = araBaslikFont;

    const sonOdemeTitleCell = sonOdemeRow.getCell(1);
    sonOdemeRow.height = 30;
    sonOdemeTitleCell.value = 'Son Ödeme Bilgileri';
    sonOdemeRow.alignment = { vertical: 'middle', horizontal: 'left' };

    sheet.mergeCells(baslikIndex, 1, baslikIndex, 8);
    baslikIndex += 1;

    const sonOdemeTitleContentRow = sheet.getRow(baslikIndex);
    const sonOdemeContentRow = sheet.getRow(baslikIndex + 1);

    sonOdemeContentRow.height = 20;
    sonOdemeTitleContentRow.height = 20;

    sonOdemeTitleContentRow.alignment = { vertical: 'middle', horizontal: 'left' };
    sonOdemeContentRow.alignment = { vertical: 'middle', horizontal: 'left' };

    const sonOdemeTarihiTitleCell = sonOdemeTitleContentRow.getCell(1);
    const sonOdemeTarihiContentCell = sonOdemeContentRow.getCell(1);

    // Son Ödeme Tarihi 2x
    sheet.mergeCells(baslikIndex, 1, baslikIndex, 2);
    sheet.mergeCells(baslikIndex + 1, 1, baslikIndex + 1, 2);

    sonOdemeTarihiTitleCell.value = `Son Ödeme Tarihi`;
    sonOdemeTarihiContentCell.value = this.data.last_payment_date ? moment(this.data.last_payment_date).format('ll') : '';


    // Son Ödeme Onarı 2x
    sheet.mergeCells(baslikIndex, 3, baslikIndex, 4);
    sheet.mergeCells(baslikIndex + 1, 3, baslikIndex + 1, 4);

    const sonOdemeOranTitleCell = sonOdemeTitleContentRow.getCell(3);
    const sonOdemeOranContentCell = sonOdemeContentRow.getCell(3);
    sonOdemeOranTitleCell.value = `Son Ödeme Oranı`;
    sonOdemeOranContentCell.value = `%${this.data?.last_payment_percentage}`;


    // Son Ödeme Tutarı 2x
    sheet.mergeCells(baslikIndex, 5, baslikIndex, 6);
    sheet.mergeCells(baslikIndex + 1, 5, baslikIndex + 1, 6);

    const sonOdemeTutarTitleCell = sonOdemeTitleContentRow.getCell(5);
    const sonOdemeTutarContentCell = sonOdemeContentRow.getCell(5);
    sonOdemeTutarTitleCell.value = `Son Ödeme Tutarı`;
    sonOdemeTutarContentCell.numFmt = moneyFormlula;
    sonOdemeTutarContentCell.value = Number(this.data?.last_payment_total);

    const sonOdemeOdenenTitleCell = sonOdemeTitleContentRow.getCell(7);
    const sonOdemeOdenenContentCell = sonOdemeContentRow.getCell(7);
    sonOdemeOdenenTitleCell.value = `Ödenen Son Ödeme Tutarı`;
    sonOdemeOdenenContentCell.numFmt = moneyFormlula;
    sonOdemeOdenenContentCell.value = Number(this.data?.odenen_son_odeme_tutari);


    const sonOdemeKalanTitleCell = sonOdemeTitleContentRow.getCell(8);
    const sonOdemeKalanContentCell = sonOdemeContentRow.getCell(8);

    sonOdemeKalanTitleCell.value = `Kalan Son Ödeme Tutarı`;
    sonOdemeKalanContentCell.numFmt = moneyFormlula;
    sonOdemeKalanContentCell.value = Number(this.data?.bekleyen_son_odeme_tutari);

    baslikIndex += 2;



    // GENEL BİLGİLER

    const genelOdemeRow = sheet.getRow(baslikIndex);
    genelOdemeRow.font = araBaslikFont;

    const genelOdemeTitleCell = genelOdemeRow.getCell(1);
    genelOdemeRow.height = 30;
    genelOdemeTitleCell.value = 'Genel Bilgiler';
    genelOdemeRow.alignment = { vertical: 'middle', horizontal: 'left' };

    sheet.mergeCells(baslikIndex, 1, baslikIndex, 8);
    baslikIndex += 1;

    const genelOdemeTitleContentRow = sheet.getRow(baslikIndex);
    const genelOdemeContentRow = sheet.getRow(baslikIndex + 1);

    genelOdemeContentRow.height = 20;
    genelOdemeTitleContentRow.height = 20;

    genelOdemeTitleContentRow.alignment = { vertical: 'middle', horizontal: 'left' };
    genelOdemeContentRow.alignment = { vertical: 'middle', horizontal: 'left' };

    const genelOdemeTarihiTitleCell = genelOdemeTitleContentRow.getCell(1);
    const genelOdemeTarihiContentCell = genelOdemeContentRow.getCell(1);

    // Son Ödeme Tarihi 2x
    sheet.mergeCells(baslikIndex, 1, baslikIndex, 4);
    sheet.mergeCells(baslikIndex + 1, 1, baslikIndex + 1, 4);

    genelOdemeTarihiTitleCell.value = `Sonraki Taksit Tarihi`;
    // tslint:disable-next-line: max-line-length
    genelOdemeTarihiContentCell.value = this.data?.sonraki_taksit_tarihi ? moment(this.data?.sonraki_taksit_tarihi).format('ll') : '';


    // Son Ödeme Tutarı 2x
    sheet.mergeCells(baslikIndex, 5, baslikIndex, 6);
    sheet.mergeCells(baslikIndex + 1, 5, baslikIndex + 1, 6);

    const genelOdemeTutarTitleCell = genelOdemeTitleContentRow.getCell(5);
    const genelOdemeTutarContentCell = genelOdemeContentRow.getCell(5);
    genelOdemeTutarTitleCell.value = `Toplam Tutar`;
    genelOdemeTutarContentCell.numFmt = moneyFormlula;
    genelOdemeTutarContentCell.value = Number(this.data?.total_price);

    const genelOdemeOdenenTitleCell = genelOdemeTitleContentRow.getCell(7);
    const genelOdemeOdenenContentCell = genelOdemeContentRow.getCell(7);
    genelOdemeOdenenTitleCell.value = `Toplam Ödenen Tutar`;
    genelOdemeOdenenContentCell.numFmt = moneyFormlula;
    genelOdemeOdenenContentCell.value = Number(this.data?.payed_total);


    const genelOdemeKalanTitleCell = genelOdemeTitleContentRow.getCell(8);
    const genelOdemeKalanContentCell = genelOdemeContentRow.getCell(8);

    genelOdemeKalanTitleCell.value = `Toplam Kalan Tutar`;
    genelOdemeKalanContentCell.numFmt = moneyFormlula;
    genelOdemeKalanContentCell.value = Number(this.data?.debt_total);
  }

  excelLoading: boolean = false;
  exportToExcel() {

    this.excelLoading = true;
    const currentRows = this.data.taksitler;
    currentRows.forEach(element => {
      element['hareket_durum'] = this.translate.instant(element.hareket_type);

    });
    const basliklar = [
      { header: 'Taksit Tarihi', key: 'must_pay_date', width: 15 },
      { header: 'Tahsilat Tarihi', key: 'date', width: 20 },
      { header: 'Taksit Tutarı', key: 'must_pay_price', width: 20, money: true },
      { header: 'Taksit Durumu', key: 'hareket_durum', width: 40 },
      { header: 'Açıklama', key: 'note', width: 40 },
    ];

    const sheetTitle = `${this.cari.unvan}(${this.data.note}) Taksit Tablosu`;

    const workBook = new Workbook();
    this.exportMainPageToExcel(workBook);
    const sheet = exportExcelSheetListeWithHeaders(workBook, basliklar, currentRows, 'Ödeme Planı', sheetTitle);

    const fileName = `${this.cari.unvan}(${this.data.note}) Ödeme Planı`;
    writeExcelFile(workBook, fileName);
    // Bizi çoğaltmaları lazım.
    this.excelLoading = false;
  }

  odendiOlarakBelirt(item: number) {
    const swalMessage: SweetAlertOptions = {
      icon: 'question',
      title: 'Emin Misiniz?',
      html: `
        Bu işlem sonucunda <b>Cari Hesabın bakiyesinde bir değişklik olmayacaktır.</b> <br/>
        <b>Hesaplarınız</b> üzerinde de bir <b>etkisi olmayacaktır</b>. <br/>
        Ödeme Planınız <b>Toplam Ödenen tutar</b> ve <b>Bekleyen tutar</b> bilgileri güncellenecektir.<br/><br/>
        <b>Onaylıyor Musunuz?</b>
        `,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'Onayla',
    };

    Swal.fire(swalMessage).then((value) => {
      console.log('Value : ', value);
      if (value.isConfirmed) {
        this.muhasebeService.odenmisOlarakIsaretle(item).subscribe(resp => {
          console.log('reso : ', resp);
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions);

          this.getOdemePlaniDetail();

        });
      }
    });
  }

  async odemeyiIptalEt(item) {
    const swalMessage: SweetAlertOptions = {
      icon: 'question',
      title: 'Emin Misiniz?',
      html: `
        <b>${formatNumberWithCurrency(item.total_price, item.currency)} tutarlı ${moment(item.date).format('ll')} tarihli </b><br/>
         ödemeyi iptal etmek istediğinizden <b>Emin Misiniz?</b> \n
        Bu işlem sonucunda ${item.sender_name} carinizin borç miktarı ${formatNumberWithCurrency(item.total_price, item.currency)} artacak,
        ${item.reciver_name} kasanızın toplam bakiyesi azalacaktır.
        <br/><b>Onaylıyor Musunuz?</b>
        `,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'Onayla',
    };

    const swalStatus = await Swal.fire(swalMessage);
    if (!swalStatus.isConfirmed) return;

    this.isLoading = true;

    this.muhasebeService.odenmediOlarakIsaretle(item.id).subscribe(resp => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.getOdemePlaniDetail();
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  openTaksitEkle() {
    this.nbDialogService.open(TaksitEkleComponent, {
      context: { data: this.data },
      closeOnBackdropClick: false,
    }).onClose.subscribe((refresh: boolean) => {
      if (refresh) this.getOdemePlaniDetail();
    });
  }

  openEdit(item) {
    this.nbDialogService.open(
      TaksitDuzenleComponent,
      { context: { data: item }, closeOnBackdropClick: false },
    ).onClose.subscribe((refresh: boolean) => {
      if (refresh) this.getOdemePlaniDetail();
    });
  }

  async deleteTaksitItem(item) {
    const swalMessage: SweetAlertOptions = {
      icon: 'question',
      title: 'Emin Misiniz?',
      html: `
        <b>${formatNumberWithCurrency(item.total_price, item.currency)} tutarlı ${moment(item.date).format('ll')} tarihli </b><br/>
         ${this.translate.instant(item.hareket_type)} işlemini silmek istediğinize <b>Emin Misiniz?</b> \n
        Bu işlem sonucunda ödeme planınız güncellencek, toplam borç bilgisi değiştirilecektir.
        <br/><b>Onaylıyor Musunuz?</b>
        `,
      showCancelButton: true,
      cancelButtonText: 'İptal',
      showConfirmButton: true,
      confirmButtonText: 'Sil',
    };

    const swalStatus = await Swal.fire(swalMessage);
    if (!swalStatus.isConfirmed) return;

    this.isLoading = true;
    this.muhasebeService.deleteCariHareket(item.id).subscribe(resp => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.getOdemePlaniDetail();
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
    });

  }

}

