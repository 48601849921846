<nb-card class="modalType" size="giant" style="border-top: unset;">

    <nb-card-header class="d-flex">
        <span>
            {{data?.pursantaj_name}} Düzenle
        </span>
        <button nbButton style="float: right; margin-left: auto !important;" (click)="close()">
            <nb-icon icon="close-outline"></nb-icon>
        </button>
        <nb-icon>

        </nb-icon>
    </nb-card-header>

    <nb-card-body>
        <div [formGroup]="form">

            <!--Firma Bilgisi (sadece orbone girebilir)-->
            <div class="form-group">
                <label class="label form-label">Pursantaj Grubu</label>
                <ng-select id="pursantaj_id" appendTo="body" [addTag]="false"
                    [placeholder]="'YAPILANISLERDETAY.IMALATCINSI'|translate" [items]="pursantajList" bindValue="id"
                    bindLabel="pursantaj_name" formControlName="ust_pursantaj_id" [addTagText]="'Yeni pursantaj ekle'"
                    [selectOnTab]="true" [loading]="isPursantajLoading"
                    (change)="updatePursantaj()"
                    >
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <b style="font-size:medium; color:gray">{{item.pursantaj_name}}</b><br>
                        <b style="font-size:small;white-space: break-spaces;">{{item.pursantaj_}}
                        </b><br>
                    </ng-template>
                </ng-select>

            </div>
            <!--// Firma Bilgisi-->
            <!-- Pursantaj Adı -->
            <div class="form-group">
                <label class="label form-label">Pursantaj Adı</label>
                <input type="text" fullWidth nbInput formControlName="pursantaj_name" (blur)="updatePursantaj()">
            </div>
            <!--// Pursantaj Adı -->
            <div class="form-group row">

                <!-- Pursantaj Oranı -->
                <div class="col-6" style="float: left;">
                    <label class="label form-label">Pursantaj Oranı</label>
                    <input nbInput fullWidth type="text" formControlName="pursantaj_rate" 
                        apasInvalid
                        paraDirective 
                        [encrypt]="false"
                        [decrypt]="false" 
                        [negative]="false" 
                        [forceRefresh]="forceRefresh" 
                        [plcholder]="'Pursantaj Oranı'"
                        (blur)="updatePursantaj()"
                    >
                </div>
                <!--// Pursantaj Oranı -->

                <!-- Toplam Metraj -->
                <div class="col-6" style="float: left;">
                    <label class="label form-label">Toplam Metraj</label>
                    <input nbInput fullWidth type="text" 
                        formControlName="metraj" 
                        apasInvalid 
                        paraDirective 
                        [encrypt]="false"
                        [decrypt]="false" 
                        [negative]="false" 
                        [forceRefresh]="forceRefresh" 
                        [plcholder]="'Toplam Metraj'" 
                        (blur)="updatePursantaj()"
                    >
                </div>
                <!--// Toplam Metraj-->
            </div>
            <div class="form-group row">
                <!-- İş Başlangıç Tarihi -->

                <div class=" col-6" style="float: left;">
                    <label class="label form-label">İş Başlangıç Tarihi</label>
                    <input nbInput fullWidth formControlName="start_day" [nbDatepicker]="startDate" (blur)="updatePursantaj()">
                    <nb-datepicker #startDate></nb-datepicker>
                </div>
                <!-- // İş Başlangıç Tarihi -->

                <!-- İş Bitiş Tarihi -->
                <div class="col-6" style="float: left;">
                    <label class="label form-label">İş Bitiş Tarihi</label>
                    <input nbInput fullWidth formControlName="end_day" [nbDatepicker]="endDate" (blur)="updatePursantaj()">
                    <nb-datepicker #endDate></nb-datepicker>
                </div>
                <!-- // İş Bitiş Tarihi -->
            </div>

            <hr />


            <span>

                <div class="form-group row">
                    <!--Tamamlanan Pursantaj-->

                    <div class="col-6" [nbTooltip]="'YAPILANISLEREKLE.BUIMALATIN'|translate">
                        <label for="tamamlanma"
                            class="form-label label">{{'ACIKLAMAILEYAPILANIS.KUMULATIF'|translate}}</label>

                        <input id="tamamlanma" class="custom-input main-sub floating__input" type="number"
                            nbInput fullWidth formControlName="work_done_percentage" 
                            paraDirective [encrypt]="false"
                            [decrypt]="false" [negative]="false" [forceRefresh]="forceRefresh" fullWidth
                            apasInvalid
                            [plcholder]="'ACIKLAMAILEYAPILANIS.KUMULATIF'|translate" 
                            
                            />
                        <div class="uyari" style="position: relative; top: -15px;"
                            *ngIf="form.get('work_done_percentage').touched && form.get('work_done_percentage').hasError('required')">
                            {{'YAPILANISLEREKLE.BOSBIRAKMAYINIZ'|translate}}
                        </div>
                    </div>

                    <!--// Tamamlanan Pursantaj -->

                    <!--Tamamlanan Metraj-->

                    <div class="col-6"
                        [nbTooltip]="'Zorunlu Değidir. Metraj Üzerinden Yapılan iş hesaplamak isterseniz girebilirsiniz.'">
                        <label for="tamamlanan_metraj" class="label form-label">Tamamlanan Metraj</label>

                        <input [disabled]="true" id="tamamlanan_metraj" class="custom-input main-sub" type="text"
                            formControlName="tamamlanan_metraj" nbInput paraDirective [encrypt]="false"
                            [decrypt]="false" [negative]="false" [forceRefresh]="forceRefresh" fullWidth apasInvalid
                            [plcholder]="'Tamamlanan Metraj'" />
                    </div>
                    <!--// Tamamlanan Metraj-->
                </div>

                <!--Açıklama-->
                <div class="form-group row">
                    <div class="col-3">
                        <label for="" class="label form-label">
                            Tarih
                        </label>
                        <input nbInput type="button" fullWidth [nbDatepicker]="workDate" formControlName="date">
                        <nb-datepicker #workDate></nb-datepicker>
                    </div>
                    <div class="col-9">
                        <label for="aciklama" class="label form-label">{{'YAPILANISLERDETAY.ACIKLAMA'|translate}}</label>

                        <input id="aciklama" class="custom-input main-sub floating__input" type="text"
                            formControlName="desc" nbInput novalidate fullWidth placeholder=" " apasInvalid/>
                    </div>

                </div>
                <!--// Açıklama-->
                <!--Toplam-->
                <div class="form-group text-center">
                    <label for="tot" class="form-label label" style="color: #2ce69b !important"><span
                            style="font-size: large;"> {{'YAPILANISLEREKLE.GERCEKLESMEORANI'|translate}} : </span> <span
                            style="font-weight: bolder;font-size: x-large;">{{form.get('applied_rate').value}} %</span></label>
                </div>
                <!--// Toplam-->
            </span>

            <div class="form-group text-center">
                <button nbButton status="primary" (click)="postForm()" [disabled]="isLoading" [nbSpinner]="isLoading">
                    {{isNew ? 'Kaydet' : 'Güncelle'}}
                </button>
            </div>
        </div>

    </nb-card-body>
</nb-card>