<nb-card size="giant" class="modalType animated fadeInRight" style="max-width: 1200px;width: 1200px; border-top: unset;"
    [formGroup]="form">
    <nb-card-header class="d-flex justify-content-between">
        <div class="">
            {{ 'PUANTAJ.PUANTAJEKLE' | translate }}
        </div>
        <button nbButton (click)="closed()" size="tiny" class="m-close"
            style="background: none !important; float: right; font-size: 18px;">X</button>
    </nb-card-header>
    <!--Taşeron'a Ait Personel Bulunuyorsa Puantaj Ekleme Penceresi Açılacak -->
    <nb-card-body [nbSpinner]="isLoading">
        <!-- Ana kart body-->

        <div class="row row pl-3 pr-3">
            <div class="col-md">
                <label for="tsrn" class="form-label label">Taşeron</label>
                <organization-select formControlName="taseron_id" (change)="taseron=$event"
                    [organizationSearch]="taseron_name"></organization-select>

                <div *ngIf="form.get('taseron_id').touched && form.get('taseron_id').hasError('required')"
                    class="uyari">
                    {{ 'PUANTAJ.UYARI3' | translate }}
                </div>
            </div>
            <div class="col-md">
                <label for="snty" class="form-label label">Şantiye</label>
                <santiye-select formControlName="santiye_id" [mine]="true" [is_active]="true">

                </santiye-select>
                <div *ngIf="form.get('santiye_id').touched && form.get('santiye_id').hasError('required')"
                    class="uyari">
                    {{ 'PUANTAJ.UYARI3' | translate }}
                </div>
            </div>
            <div class="col-md">
                <label for="date" class="form-label label">Tarih</label>
                <input id="date" apasInvalid nbInput [placeholder]="'Tarih Seçiniz'" dateOnly
                    [nbDatepicker]="startpicker" fullWidth formControlName="date">
                <nb-datepicker #startpicker></nb-datepicker>
            </div>
        </div>

        <div *ngIf="form.valid">
            <!-- Yeni Personel -->
            <div class="row animated slideInUp" [formGroup]="newPersonelForm" *ngIf="personelEkle"
                style="background: #e6e6e64f;   padding: 1rem !important; margin: 1rem 0px !important;">
                <div class="col-3">
                    <label class="form-label label">İsim</label>
                    <input nbInput fullWidth formControlName="first_name" placeholder="İsim" apasInvalid>
                </div>
                <div class="col-3">
                    <label class="form-label label">Soyisim</label>
                    <input nbInput fullWidth formControlName="last_name" placeholder="Soyisim" apasInvalid>
                </div>
                <div class="col-3">
                    <label class="form-label label">Telefon</label>
                    <apas-telefon-input class="d-block" formControlName="phone" [labelShow]="false" apasInvalid>
                    </apas-telefon-input>
                </div>
                <div class="col-2">
                    <label class="form-label label">Meslek</label>
                    <job-select class="d-block" [addTag]="true" formControlName="job_id" apasInvalid></job-select>
                </div>
                <div class="col-1 d-flex justify-content-end mb-3">
                    <b style="margin-top: auto;">
                        <button nbButton status="primary" size="small" (click)="postPersonelForm()">
                            Ekle
                        </button>
                    </b>
                </div>
            </div>
            <!-- // Yeni Personel -->


            <!-- Puantaj Listesi -->
            <form [formGroup]="puantajForm">
                <div class="aps-splt">
                    <div class="row" style="display: flex;  justify-content: flex-start; align-items: center;">

                        <div class="col-md-3 ml-3">
                            <nb-checkbox [formControl]="allCheck" (checkedChange)="selectAll($event)"><b>{{
                                    'PUANTAJ.ISARET' |
                                    translate }}</b> </nb-checkbox>
                        </div>
                        <div class="col-md text-right" *ngIf="taseron?.demo">
                            <button nbButton [status]="personelEkle ? 'danger':'primary'" size="small"
                                (click)="personelEkle = !personelEkle" style="position: relative;right: 15px;
                            top: 2px;">
                                {{personelEkle ? 'Kapat':'Yeni Personel Ekle'}}
                            </button>
                        </div>
                    </div>
                    <nb-list formArrayName="personels" style="overflow: hidden;">
                        <nb-list-item class="tableHeader text-left">
                            <div class="col-md-3  ">
                                <strong>{{ 'PUANTAJ.PERSONELAD' | translate }}</strong>
                            </div>
                            <div class="col-md-3 ">
                                <strong>{{ 'PUANTAJ.CALISMA' | translate }}</strong>
                            </div>
                            <div class="col-md-4">
                                <strong>{{ 'PUANTAJ.MESLEK' | translate }}</strong>
                            </div>

                            <div class="col-md ">
                                <strong>{{ 'PUANTAJ.MESAI' | translate }}</strong>
                            </div>
                        </nb-list-item>
                        <nb-list-item [formGroupName]="i"
                            *ngFor="let i = index; let creds of personelsFormArray.controls;">
                            <div class="col-md-3">
                                {{creds.get('name').value }}
                            </div>
                            <div class="col-md-3 ">
                                <nb-checkbox (checkedChange)="deSelectIfTamSelected($event, i)" formControlName="tam"
                                    class="mr-3">
                                    {{ 'PUANTAJ.TAMGUN' | translate }}
                                </nb-checkbox>
                                <nb-checkbox (checkedChange)="deSelectIfYarimSelected($event, i)"
                                    formControlName="yarim_gun">{{
                                    'PUANTAJ.YARIMGUN' | translate }}
                                </nb-checkbox>
                            </div>
                            <div class="col-md-4 ngBottom">
                                <job-select formControlName="job" [addTag]="true" apasInvalid></job-select>
                            </div>

                            <div class="col-md" *ngIf="(creds.get('tam').value || creds.get('yarim_gun').value)">
                                <input nbInput fullWidth numberOnly formControlName="overtime" apasInvalid>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </div>

            </form>
            <!-- // Puantaj Listesi -->

        </div>

        <aps-emptylist *ngIf="!isLoading && this.form.invalid" [message]="'PUANTAJ.SANTIYESEC' |   translate "
            [text]="' '" [genislik]='"200px"' [yukseklik]="'200px'"></aps-emptylist>

    </nb-card-body>
    <!-- //Ana kart body-->
    <nb-card-footer>
        <div class="row">
            <div class="col text-center">


                <div class="mb-5 mr-3">
                    <span class="col-md-2 text-center">
                        <b>Tam Gün </b>
                        <span class="apasBadgeSuccess">{{totalTam}}</span>
                    </span>
                    <span class="col-md-2 text-center">
                        <b>Yarım Gün </b> 
                        <span class="apasBadgeWarn">{{totalYarim}}</span>
                    </span>
                </div>

                <button (click)="postForm()" nbButton status="primary" [nbSpinner]="saveLoad" nbSpinnerStatus="control"
                    [disabled]="saveLoad"> {{ 'PUANTAJ.PUANTAJKAYDET' | translate }}
                </button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>