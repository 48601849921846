import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { HakedisService } from '../../../../services/hakedis.service';

@Component({
  selector: 'ngx-taseron-hakedis-ekle',
  templateUrl: './taseron-hakedis-ekle.component.html',
  styleUrls: ['./taseron-hakedis-ekle.component.scss'],
  providers: [HakedisService],
})
export class TaseronHakedisEkleComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private hakedisService: HakedisService,
    private ref: NbDialogRef<TaseronHakedisEkleComponent>,
    private router: Router,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }

  form: FormGroup;
  isLoading: boolean = false;

  initForm() {
    this.form = this.fb.group({
      taseron: [null, Validators.required],
      santiye: [null, Validators.required],
    });
  }
  _type: string = 'taseron';

  santiye;
  selectSantiye(event) {
    this.santiye = event;
  }


  taseron;
  selectTaseron(event) {
    this.taseron = event;
  }
  yeniHakedisOlustur() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    console.log('YENİ HAKEDİŞ OLUŞTUR', this.taseron);
    this.isLoading = true;
    console.log('yeni hakediş oluştur.', this.form.value);
    const _santiye = this.form.get('santiye').value;
    const _taseron = this.form.get('taseron').value;

    this.hakedisService
      .getCreateStatus(this._type, _santiye, _taseron)
      .subscribe(
        (veri) => {
          this.isLoading = false;

          console.log('Gelen Data', veri);

          if (veri['SC001']) {
            const metin = `${this.santiye?.title} şantiyesinde ${this.taseron?.name} yeni hakediş
             oluşturmak istediğinizden Emin Misiniz?`;
            // tslint:disable-next-line: max-line-length
            Swal.fire({ ...responseKodlari.hakedis.successToCreateNew, text: metin } as SweetAlertOptions).then((value) => {
              if (value.value) {
                this.createHakedis();
              }
            });
          } else if (veri['SC002']) {
            const metin = `${this.santiye?.title} şantiyesinde ${this.taseron?.name} yeni hakediş
            oluşturmak istediğinizden Emin Misiniz?
            Bu işlemi onayladığınızda ${veri['hakedis_no']} numaralı hakediş üzerinde değişiklik yapamazsınız.`;
            // tslint:disable-next-line: max-line-length
            Swal.fire({ ...responseKodlari.hakedis.successToCreateNew, text: metin } as SweetAlertOptions).then((value) => {
              if (value.value) {
                this.createHakedis();
              }
            });
          } else if (veri['HER001']) {
            const metin = `${this.santiye?.title} şantiyesinde ${this.taseron?.name} yeni hakediş
          oluşturmak İşveren firma ve Taşeron firmanın ${veri['hakedis_no']}
            numaralı hakedişi imzalaması gerekir. ${veri['hakedis_no']} numaralı
            hakedişi imzaya göndermek ister misniz?`;
            Swal.fire({
              ...responseKodlari.hakedis.signatureError,
              html: metin,
            } as SweetAlertOptions).then((value) => {
              if (value.value) {
                this.hakedisService
                  .sendToSign(veri['hakedis_id'])
                  .subscribe((sonuc) => console.log('İmza Durumu', sonuc));
              }
            });
          }
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        },
      );
  }

  createHakedis() {
    const _santiye = this.form.get('santiye').value;
    const _taseron = this.form.get('taseron').value;
    this.hakedisService.createNewHakedis(this._type, _santiye, _taseron).subscribe((data) => {
      this.close(data);
    });
  }
  close(resp?) {
    if (resp) {
      this.hakedisDetay(resp);
    }
    this.ref.close();
  }

  hakedisDetay(detay?: any) {
    this.router.navigateByUrl(
      `pages/hakedis/hakedis-detail/${detay.id}`,
      {
        state:
          { hakedisId: detay.id, data: detay },
      },
    );
  }
}
