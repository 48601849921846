import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../pages/global-functions';
import { SantiyeService } from '../../../services/santiye.service';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';

@Component({
  selector: 'santiye-select',
  template: `
  <ng-select
      [items]="santiyeList$ | async"
      [loading]="isLoading"
      [loadingText]="'Yükleniyor...'"
      appendTo="body"
      [typeahead]="santiyeInput$"
      typeToSearchText="Arama yapmak için en az 2 karakter giriniz."
      [minTermLength]="2"
      bindLabel="title"
      bindValue="id"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'ISMAKINASIPUANTAJRAPORU.SANTIYESEC'|translate"
      class="uzun-select"
      ngDefaultControl
      (change)="santiyeSelected($event)"
  >

  </ng-select>`
  ,
  styleUrls: ['./santiye-select.component.scss'],
  providers: [
    SantiyeService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SantiyeSelectComponent),
      multi: true,
    },
  ],
})
export class SantiyeSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {
  isLoading: boolean = false;

  // tslint:disable-next-line: max-line-length
  @Input() mine: boolean = true; // true ? sadece benim şirketime ait şantiyeleri liste : ilişkili olduğum tüm şantiyeleri listele

  // tslint:disable-next-line: max-line-length
  @Input() is_active: any = true; // null : tüm şanyiyeleri listele - true : sadece aktif şantiyeleri listele - false : sadece pasif şantiyeleri listele

  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  tag: string = '------ Şantiye Select ------------> ';
  constructor(
    private santiyeService: SantiyeService,
    injector: Injector,
    cd: ChangeDetectorRef,
  ) {
    super(injector);
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.tag, changes);
    this.firstList();
  }

  ngOnInit(): void {
    this.getSantiye();
  }
  santiyeSelected(event) {
    this.change.emit(event);
  }

  santiyeList$: Observable<any[]>;
  santiyeInput$ = new Subject<string>();
  getSantiye() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    this.santiyeList$ = concat(
      of([]), // default items
      this.santiyeInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.santiyeService.getSantiyeSelectList(
            removeAllNull({ search: term, mine: this.mine, is_active: this.is_active }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getSantiye();
    setTimeout(() => {
      this.santiyeInput$.next('');
    }, 300);
  }
}
