import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment-timezone';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { ApasFilesComponent } from '../../../apas-components/apas-files/apas-files.component';
import { PersonelDetayComponent } from '../personel-detay.component';
@Component({
    selector: 'personel-ozluk-bilgileri',
    templateUrl: './personel-ozluk-bilgileri.component.html',
    styleUrls: ['./personel-ozluk-bilgileri.component.scss'],
})
export class PersonelOzlukBilgileriComponent implements OnInit, OnChanges {
    @Input() kullaniciBilgileri;
    form: FormGroup;
    izinForm: FormGroup;
    santiyeForm: FormGroup;
    tarih_format = tarih_format;
    saveLoad = false;
    
    constructor(private fb: FormBuilder,
        private organizationService: OrganizasyonService,
        private personelDetayComponent: PersonelDetayComponent,
        private cd: ChangeDetectorRef,
        private nbDialogService: NbDialogService,
    ) {
        this.initForm();

    }
    ngOnChanges(changes: SimpleChanges): void {
        console.log('Changes : ', changes);
        if (changes.kullaniciBilgileri) {
            this.kullaniciBilgileri = changes.kullaniciBilgileri.currentValue;
            this.izinForm.get('apas_id').setValue(this.kullaniciBilgileri?.apas_id);
            this.form.get('start_date').setValue(this.kullaniciBilgileri?.start_date);
            this.form.get('finish_date').setValue(this.kullaniciBilgileri?.finish_date);
            this.santiyeForm.get('apas_id').setValue(this.kullaniciBilgileri?.apas_id);
            this.gunHesapla();
        }

    }

    gunHesapla(typeForm?) {
        if (typeForm) {
            const formData = this.izinForm.value;
            const finish = moment(formData.finish_date);
            const start = moment(formData.start_date);
            let toplamIzin = finish.diff(start, 'days');
            toplamIzin += 1;
            if (toplamIzin > 0) {
                this.izinForm.get('toplam_izin').setValue(toplamIzin, { emitEvent: false });
            }
        } else {

            if (this.kullaniciBilgileri?.leavedays_set?.length > 0) {
                this.kullaniciBilgileri.leavedays_set.forEach(element => {
                    const finish = moment(element.finish_date);
                    const start = moment(element.start_date);
                    const toplamIzin = Math.floor(finish.diff(start) / 86400000);
                    // element['izin_suresi'] = toplamIzin;
                });

            }

        }
    }
    ngOnInit(): void {
    }
    //izinHakiFormArray: FormArray = new FormArray([]);
    initForm() {
        this.form = this.fb.group({
            start_date: [this.kullaniciBilgileri?.start_date ? this.kullaniciBilgileri?.start_date : null],
            finish_date: [this.kullaniciBilgileri?.finish_date ? this.kullaniciBilgileri?.finish_date : null],
        });
        this.santiyeForm = this.fb.group({
            santiye: [null, Validators.required],
            start_date: [null, Validators.required],
            finish_date: [null],
            apas_id: [this.kullaniciBilgileri?.apas_id, Validators.required],
        });
        this.izinForm = this.fb.group({
            apas_id: [this.kullaniciBilgileri?.apas_id],
            start_date: [null, Validators.required],
            finish_date: [null, Validators.required],
            sick_or_dayoff: [true, Validators.required],
            toplam_izin: [0],
            reason: [null, Validators.required],
        });
        this.izinForm.valueChanges.subscribe(() => {
            this.gunHesapla(true);
        });

    }

    newSantiye() {
        if (!this.santiyeForm.valid) {
            this.santiyeForm.markAllAsTouched();
            return;
        }
        const formData = this.santiyeForm.value;
        formData.start_date = formData.start_date ? moment(formData.start_date).unix() : null;
        formData.finish_date = formData.finish_date ? moment(formData.finish_date).unix() : null;
        this.organizationService.addWorkArea(formData)
            .subscribe(response => {
                this.personelDetayComponent.getPersonelProfile();
                Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
                this.santiyeForm.reset();
            }, err => {
                Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

            });
    }

    patchSantiye(instanceId) {
        if (!this.santiyeForm.valid) {
            this.santiyeForm.markAllAsTouched();
            return;
        }
        const formData = this.santiyeForm.value;
        formData.start_date = formData.start_date ? moment(formData.start_date).unix() : null;
        formData.finish_date = formData.finish_date ? moment(formData.finish_date).unix() : null;
        this.organizationService.updateWorkArea(instanceId, formData)
            .subscribe(response => {
                this.personelDetayComponent.getPersonelProfile();
                Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
                this.santiyeForm.reset();
            }, err => {
                Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

            });
    }


    deleteWorkArea(row) {
        Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(({value}) => {
            if (value) {
                this.organizationService.deleteWorkArea(row.id).subscribe(val => {
                    this.personelDetayComponent.getPersonelProfile();
                    Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
                });
            }
        });

    }
    upDateSantiye(item) {
        console.log('Şantiye data:', item);
        if (item?.edit === false) {
            this.kullaniciBilgileri?.workareas_set.forEach(element => {
                element['edit'] = false;
            });
            this.santiyeForm.reset();
            this.santiyeForm.patchValue(item);
            item['edit'] = true;
        } else {
            this.santiyeForm.reset();
            item['edit'] = false;
        }
    }




    updatePersonel() {
        const formData = this.form.value;
        console.log('formData.', formData);
        formData.start_date = formData.start_date ? moment(formData.start_date).unix() : null;
        formData.finish_date = formData.finish_date ? moment(formData.finish_date).unix() : null;
        this.organizationService.updatePersonelInformation(formData, this.kullaniciBilgileri.apas_id)
            .subscribe(response => {
                this.personelDetayComponent.getPersonelProfile();
                Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
            }, err => {
                Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

            });

    }


    izinEdit = false;
    guncelleId;

    izinHakkiGuncelle(item, event) {
        console.log('Güncellenecek izin hakkı bilgileri', item, event.target.value);
        this.organizationService.updateYearLeaveDays(item.id, { izin_hakki: event.target.value }).subscribe(resp => {
            this.personelDetayComponent.getPersonelProfile(); 
        });
    }
    newIzin() {
        if (!this.izinForm.valid) {
            this.izinForm.markAllAsTouched();
            return;
        }
        this.saveLoad = true;
        const formData = removeAllNull(this.izinForm.value);
        this.organizationService.addLeaveDays(formData)
            .subscribe((response: any) => {
                this.personelDetayComponent.getPersonelProfile();
                // response.finish_date = convertDateWithTZ(response,'finish_date');
                // response.start_date = convertDateWithTZ(response,'start_date');
                // this.kullaniciBilgileri?.leavedays_set.push(response);
                // this.gunHesapla(); 
                this.saveLoad = false;
                this.izinForm.reset();
                this.izinForm.get('toplam_izin').setValue(0, { emitEvent: false });
                this.izinForm.get('apas_id').setValue(this.kullaniciBilgileri?.apas_id, { emitEvent: false });
                this.izinForm.get('sick_or_dayoff').setValue(true, { emitEvent: false });
                Swal.fire(responseKodlari.successProgress as SweetAlertOptions);

            }, err => {
                this.saveLoad = false;
            });

    }


    izinPatch() {
        if (!this.izinForm.valid) {
            this.izinForm.markAllAsTouched();
            return;
        }
        this.saveLoad = true;
        const formData = removeAllNull(this.izinForm.value);
        this.organizationService.updateLeaveDays(this.guncelleId, formData)
            .subscribe((response: any) => {
                this.personelDetayComponent.getPersonelProfile();

                // this.kullaniciBilgileri.leavedays_set = this.kullaniciBilgileri.leavedays_set.filter(x => x.id !== this.guncelleId);
                // setTimeout(() => {
                //     response.finish_date = convertDateWithTZ(response,'finish_date');
                //     response.start_date = convertDateWithTZ(response,'start_date');
                //     this.kullaniciBilgileri?.leavedays_set.push(response);
                //     this.gunHesapla(); 

                // },1000); 

                Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
                this.saveLoad = false;
                this.izinForm.reset();
                this.izinEdit = false;
                this.guncelleId = null;
                this.izinForm.get('toplam_izin').setValue(0, { emitEvent: false });
                this.izinForm.get('apas_id').setValue(this.kullaniciBilgileri?.apas_id, { emitEvent: false });

            }, err => {
                this.saveLoad = false;
                Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

            });

    }

    deleteIzin(instanceId) {
        Swal.fire(
            {
                ...responseKodlari.shureToDelete,
                ...{
                    text: `İzini silmek istediğinize eminmisiniz.`
                }
            } as SweetAlertOptions).then(({ value }) => {
                if (value) {
                    this.organizationService.deleteLeaveDays(instanceId).subscribe(() => {
                        Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
                        this.personelDetayComponent.getPersonelProfile();

                        // this.kullaniciBilgileri.leavedays_set = this.kullaniciBilgileri.leavedays_set.filter(x => x.id !== instanceId);


                    });

                }
            });
    }


    izinGuncelle(item) {
        console.log('item sick_or_dayoff', item?.sick_or_dayoff);
        this.izinForm.patchValue(item);
        this.guncelleId = item.id;
        this.izinEdit = true;

    }

    izinPatchIptal() {
        this.izinForm.reset();
        this.guncelleId = null;
        this.izinEdit = false;
    }

    // addIzinHakkiFormItem(data) {
    //     const izinHakkiForm = this.fb.group({
    //         id: [data.id, Validators.required],
    //         year: [data.year, Validators.required],
    //         izin_hakki: [data.izin_hakki, Validators.required]
    //     });
    //     this.izinHakiFormArray.push(izinHakkiForm);
    // }


}
