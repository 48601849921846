import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { getMoneyCurrency, tarih_format } from '../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { HakedisService } from '../../../services/hakedis.service';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { TaseronHakedisEkleComponent } from '../taseron-hakedis/taseron-hakedis-ekle/taseron-hakedis-ekle.component';

@Component({
  selector: 'hakedis-list-box',
  templateUrl: './hakedis-list-box.component.html',
  styleUrls: ['./hakedis-list-box.component.css'],
  providers: [HakedisService],
})
export class HakedisListBoxComponent implements OnInit, OnChanges, OnDestroy {

  @Input() tasronOrSantiye: boolean = false; // True İse Taşeron, False İse Şantiye
  @Input() instanceId: number; // Taşeron veya şantiye id
  @ViewChild('myTable') table: any;
  ColumnMode = ColumnMode;
  isLoading: boolean = false;
  hakedisDetails: any;
  getMoneyCurrency = getMoneyCurrency;
  tarih_format = tarih_format;
  rows: any[] = [];
  hasAuthorization: boolean = false;
  get filterFormGroup(): FormGroup { return this.form.get('filter') as FormGroup; }
  constructor(
    private hakedisService: HakedisService,
    private fb: FormBuilder,
    private router: Router,
    private nbDialogService: NbDialogService,
    private organizasyonService: OrganizasyonService,
  ) {
    this.initForm();
    this.orgSubscription();
  }

  organizationSubscription;
  orgSubscription() {
    this.organizationSubscription = this.organizasyonService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const hakedis = modules.find(x => x.slug === 'hakedisler');
        if (hakedis.value) {
          this.hasAuthorization = true;
        }
      }
    });
  }
  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.instance_id) {
      if (this.tasronOrSantiye) {
        this.filterFormGroup.get('taseron_id').setValue(changes.instanceId.currentValue);
      } else {
        this.filterFormGroup.get('instance_id').setValue(changes.instanceId.currentValue);
      }
      this.getFilteredData();
    }
  }

  ngOnInit() {
    if (this.hasAuthorization) {
      if (this.tasronOrSantiye) {
        this.filterFormGroup.get('taseron_id').setValue(this.instanceId);
      } else {
        this.filterFormGroup.get('instance_id').setValue(this.instanceId);
      }
      this.getHakedisList();
    }
  }

  form: FormGroup;
  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      search: [],
      type: ['taseron'],
      filter: this.fb.group({
        instance_id: [],
        taseron_id: [],
        start_date: [],
        end_date: [],
      }),
    });

    this.form.controls.search.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });

  }

  getFilteredData() {
    this.form.get('page').setValue(0);
    this.rows = [];
    this.getHakedisList();
  }


  request;

  getHakedisList() {
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    if (this.request) this.request.unsubscribe();
    this.hakedisService.listHakedis(formVal).subscribe((data) => {
      data.forEach(elm => {
        elm = this.clearData(elm);
        this.rows = addOrUpdate(this.rows, elm);
      });
      console.log('hakedişler : ', this.rows);
      this.isLoading = false;
    },
      () => {
        this.isLoading = false;
      },
    );
  }

  clearData(elm) {
    elm.son_hakedis_tarihi = convertDateWithTZ(elm, 'son_hakedis_tarihi');
    return elm;
  }

  onDetailToggle(event: any, row) {
    console.log('Detail Toggled', event);
    if (!event) return;
    row['loading'] = true;
    this.hakedisService.getHakedisItems(row.id).subscribe(
      (data) => {
        console.table(data);
        this.hakedisDetails = data;
        row['hakedis_details'] = data;
        row['loading'] = false;
      },
      () => {
        row['loading'] = false;
      },
    );
  }

  hakedisDetay(detay?: any) {
    this.router.navigateByUrl(
      `pages/hakedis/hakedis-detail/${detay.id}`,
      {
        state:
          { hakedisId: detay.id, data: detay },
      },
    );
  }


  addNew() {
    console.log('add new hakedis.');
    const context = {};
    if (this.tasronOrSantiye) {
      context['taseron_id'] = this.instanceId;
    } else {
      context['santiye_id'] = this.instanceId;
    }
    // tslint:disable-next-line: max-line-length
    this.nbDialogService.open(TaseronHakedisEkleComponent, { context: context, closeOnBackdropClick: false }).onClose.subscribe(resp => {
      if (resp) {
        this.hakedisDetails(resp);
      }
    });
  }
}
