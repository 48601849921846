import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { responseKodlari, telefonKodu, Ulkeler } from '../../../../pages/constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApasdisiFirmaEkleType } from '../organizationTypeEnums';

@Component({
  selector: 'iliskili-firma-ekle',
  templateUrl: './iliskili-firma-ekle.component.html',
  styleUrls: ['./iliskili-firma-ekle.component.scss'],
  providers: [OrganizasyonService],
  encapsulation: ViewEncapsulation.None,
})
export class IliskiliFirmaEkleComponent implements OnInit {

  addType: ApasdisiFirmaEkleType = ApasdisiFirmaEkleType.Taseron;
  telefon_kodu = telefonKodu;
  ulkem: FormControl = new FormControl(telefonKodu[0]);
  isLoading = false;
  sehirler: any[] = Ulkeler[0].sehir;

  form: FormGroup;
  tag: string = 'ApasdisiFirmaEkleComponent ----> ';
  constructor(
    private ref: NbDialogRef<IliskiliFirmaEkleComponent>,
    private formBuilder: FormBuilder,
    private organizasyonService: OrganizasyonService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initForm();
    this.form.get('instance_type').setValue(this.addType);
  }

  initForm() {
    this.form = this.formBuilder.group({
      apas_id: [null],
      name: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.email],
      city: [null, Validators.required],
      instance_type: [this.addType],
    });
    this.form.get('name').valueChanges.subscribe(val => {
      this.searchFirma(val);
      this.clearFormData();
    });

    this.form.get('apas_id').valueChanges.subscribe(val => {
      this.addOrRemoveValidators();
    });
  }

  postForm() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formdata = this.form.value;

    this.organizasyonService.apasDisi(formdata).subscribe((data: any) => {
      this.isLoading = false;
      if (data.demo) {
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Kayıt Başarılı!',
          html: 'Firma yetkilisinden <b class="text-danger">onay bekleniyor.</b>',
        });
      }
      this.ref.close(data);
    },
      (err: HttpErrorResponse) => {
        this.isLoading = false;

        const errorResponse: SweetAlertOptions = {
          icon: 'error',
          title: 'Kayıt Başarısız !',
        };
        if (err.error?.ER33) {
          errorResponse['html'] = 'Bu firmayı daha önce eklediniz. <b class="text-primary"> Firma yetkilisi onay verdikten sonra </b> kaydınız tamamlanacaktır.';
        }
        Swal.fire(errorResponse);
      });

  }

  close() {
    this.ref.close();
  }


  options: any[];
  filteredOptions$: Observable<any[]>;

  searchFirma(name) {
    if (name) {
      this.organizasyonService.searchOrganization(name, false).subscribe(resp => {
        this.options = resp;
        this.filteredOptions$ = of(this.options);
      }, (err) => {
        this.options = [];
        this.filteredOptions$ = of([]);
      });
    }

  }
  show_other_info: boolean = false;

  onSelectionChange(event) {
    setTimeout(() => {
      if (event?.yonetici) {
        const splitName = event.yonetici.split(' ');
        event['first_name'] = splitName[0];
        event['last_name'] = splitName[1];
      }
      this.form.patchValue(event, { emitEvent: false });
      this.addOrRemoveValidators();
      this.cd.detectChanges();
    }, 500);
  }


  clearFormData() {
    this.form.get('apas_id').setValue(null, { emitEvent: false });
    this.form.get('first_name').setValue(null, { emitEvent: false });
    this.form.get('last_name').setValue(null, { emitEvent: false });
    this.form.get('phone').setValue(null, { emitEvent: false });
    this.form.get('email').setValue(null, { emitEvent: false });
    this.form.get('city').setValue(null, { emitEvent: false });
    this.addOrRemoveValidators();
  }
  addOrRemoveValidators() {
    if (this.form.get('apas_id').value) {
      this.show_other_info = false;
      this.form.get('first_name').disable();
      this.form.get('last_name').disable();
      this.form.get('city').disable();
      this.form.get('phone').setValidators(null);
      this.form.get('phone').disable();
      this.form.get('email').disable();
    } else {
      this.show_other_info = true;
      this.form.get('first_name').enable();
      this.form.get('last_name').enable();
      this.form.get('city').enable();
      this.form.get('phone').setValidators(Validators.required);
      this.form.get('phone').enable();
      this.form.get('email').enable();

    }
    this.form.updateValueAndValidity();
  }
}
