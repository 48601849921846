import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { convertDateWithTZ } from '../../../../pages/global-functions';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { YapilanIslerDetayComponent } from '../yapilan-isler-detay/yapilan-isler-detay.component';

@Component({
  selector: 'yapilan-isler-grafik',
  templateUrl: './yapilan-isler-grafik.component.html',
  styleUrls: ['./yapilan-isler-grafik.component.css'],
  providers: [YapilanIslerService],
})
export class YapilanIslerGrafikComponent implements OnInit {
  single: any[];
  rows;
  tarih_format = tarih_format;
  @Input() data;
  isLoading = false;
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel: 'Years';
  public showYAxisLabel = true;
  public yAxisLabel: 'Salary';
  public legend_title = 'İmalat';
  public graphDataChart: any[];
  public colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  veriler: any[] = [];
  constructor(private ref: NbDialogRef<YapilanIslerGrafikComponent>,
    private yapilanIsService: YapilanIslerService,
    private _nbDialogService: NbDialogService) { }

  ngOnInit() {
    this.pursantajList();
  }
  format() {
    this.veriler['name'] = this.rows[0].pursantaj_title;
    const series: any[] = [];
    this.rows.forEach((element, i) => {
      const mydate = moment(element.date);
      series.push({
        name: mydate.format('ll'),
        value: element.work_done_percentage.toString(),
      });
    });
    series.reverse();
    this.veriler['series'] = series;
    this.veriler = [this.veriler];
    console.log('İçinden Geçilen Veri', this.veriler);
  }

  pursantajList() {
    this.rows = [];
    this.isLoading = true;
    this.yapilanIsService.getYapilanIsFilter(this.data.santiye_id, this.data.pursantaj_id).subscribe(data => {
      this.isLoading = false;
      data.forEach(elm => {
        elm = this.clearData(elm);
      });
      this.rows = data;
      this.format();
    }, err => {
      this.isLoading = false;
    });
  }

  clearData(elm) {
    elm.date = convertDateWithTZ(elm, 'date');
    return elm;
  }

  yapilanIsDetay(item, edit?) {
    if (edit) {
      item['edit'] = true;
    }
    this._nbDialogService.open(YapilanIslerDetayComponent, {
      context: {
        yapilanIs: item,
      },
    }).onClose.subscribe(data => {
      this.pursantajList();
    });
  }

  deleteYapilanIs(row) {

    const text = `${row.pursantaj_title} işinde ${row.work_done_percentage} yüzdeli ilerlemeyi silmek istediğinize emin misiniz?`;

    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: text } } as SweetAlertOptions).then(value => {
      console.log("Sil Value", value);
      if (!value.dismiss) {
        this.yapilanIsService.deleteYapilanIs(row.id).subscribe(resp => {
          this.pursantajList();
          setTimeout(() => {
            if(this.rows.length === 0){
              this.closed();
            }
          },1000);
        });
      }
    });
  }

  closed() {
    this.ref.close();
  }



}
