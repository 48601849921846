import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class YapilanIslerService {
    private readonly API_URL = `${environment.backendurl}api/yapilanisler/`;

    constructor(private httpClient: HttpClient) { }

    getYapilanIsler(data): Observable<any> {
        return this.httpClient.post<any>(this.API_URL, data);
    }

    getYapilanIsPursantaj(_id: number, sozlesme?: boolean): Observable<any> {
        const url: string = `${this.API_URL}${sozlesme ? 'endsozlesme/' : 'end/'}${_id}/`;
        return this.httpClient.get<any>(url);
    }

    getYapilanIsFilter(santiyeId: number, pursantajId): Observable<any> {
        const url: string = `${this.API_URL}santiye/tek/${santiyeId}/${pursantajId}/`;
        return this.httpClient.get<any>(url);
    }

    postYapilanIs(data): Observable<any> {
        return this.httpClient.post<any>(this.API_URL + 'santiye/add/', data);
    }

    updateYapilanIs(instanceId, data): Observable<any> {
        const url: string = `${this.API_URL}santiye/update/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteYapilanIs(instanceId) {
        const url: string = `${this.API_URL}santiye/delete/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getFiziksel(santiyeId) {
        const url: string = `${environment.backendurl}api/yapilanisler/santiye/fizikselilerleme/${santiyeId}/`;
        return this.httpClient.get<any>(url);
    }

    getSantiyeWorkGraphic(santiyeId): any {
        const url: string = `${this.API_URL}santiye/genelilerleme/${santiyeId}/`;
        return this.httpClient.get(url);
    }

    getYapilanIslerListForDiger(santiyeId): Observable<any[]> {
        const url: string = `${this.API_URL}santiye/diger/${santiyeId}/`;
        return this.httpClient.get<any[]>(url);
    }

    getYapilanIslerListForSelect(data): Observable<any[]> {
        const url: string = `${this.API_URL}santiye/diger/list/`;
        return this.httpClient.post<any[]>(url, data);
    }

    getDetay(instanceId: number): Observable<any> {
        const url: string = `${this.API_URL}santiye/detay/${instanceId}/`;
        return this.httpClient.get<any>(url);
    }

    getYapilanIslerList(filter: any): Observable<any> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.post<any>(url, filter);
    }

    getAggreagetdYapilanIslerList(filter: any): Observable<any> {
        const url: string = `${this.API_URL}agglist/`;
        return this.httpClient.post<any>(url, filter);
    }


    addDigerPursantaj(data) {
        const url: string = `${this.API_URL}addnewpursantaj/`;
        return this.httpClient.post<any>(url, data);

    }
}

