<div class="swal2-popup swal2-modal swal2-icon-question swal2-show" [class.swal2-hide]="isClose" style="display: block !important; width: 35em !important;" [nbSpinner]="ispreConfirmLoading || ispreDenyLoading || ispreFileLoading" nbSpinnerSize="tiny">

    <div class="swal2-header">

        <div class="swal2-icon swal2-question swal2-icon-show" style="display: flex;">
            <div class="swal2-icon-content">?</div>
        </div>

        <h2 class="swal2-title" style="display: flex;"> {{options.title}} </h2>
    </div>

    <div class="apas-alert-content">

        <div class="swal2-html-container"> {{options.text}} </div>
        <div class="swal2-validation-message" *ngIf="validationMessage" style="display: flex; margin-left: -20px; margin-right: -20px;">{{validationMessage}} </div>


    </div>

    <div class="swal2-actions">

        <button *ngIf="ispreFileLoading" class="swal2-confirm swal2-styled" style="display: inline-block;" [disabled]="true"> İndiriliyor </button>
        <button *ngIf="!ispreFileLoading && options.showFileButton" class="swal2-confirm swal2-styled" style="display: inline-block;" (click)="file()">{{options.fileButtonText}} </button>

        <button *ngIf="ispreConfirmLoading" class="swal2-confirm swal2-styled" style="display: inline-block;" disabled> {{'APAS_ALERT.IMZALANIYOR' | translate}} </button>
        <button *ngIf="!ispreConfirmLoading && options.showConfirmButton" class="swal2-confirm swal2-styled" style="display: inline-block;" (click)="confirm()">{{options.confirmButtonText}} </button>

        <button *ngIf="ispreDenyLoading" class="swal2-cancel swal2-styled" style="display: inline-block;" (click)="deny()"> {{'APAS_ALERT.REDDEDILIYOR' | translate}} </button>
        <button *ngIf="!ispreDenyLoading && options.showDenyButton " class="swal2-cancel swal2-styled" style="display: inline-block;" (click)="deny()">{{options.denyButtonText}} </button>

        <button *ngIf="options.showCancelButton" class="swal2-cancel swal2-styled" style="display: inline-block;" (click)="cancel()">{{options.cancelButtonText}} </button>

    </div>
</div>