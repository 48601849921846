import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { IsGuvenligiImzaDosyaModel } from '../../../../models/is_guvenligi.model';
import { IsGuvenligiServiceV2 } from '../../../../services/isguvenligiV2.service';

@Component({
  selector: 'imza-ekle',
  templateUrl: './imza-ekle.component.html',
  styleUrls: ['./imza-ekle.component.css'],
  providers: [OrganizasyonService, IsGuvenligiServiceV2],
})
export class ImzaEkleComponent implements OnInit {
  acceptFileFormats: string = '.pdf';
  currentFile: File;

  isFileCurrect: boolean = false;
  isFileUploading: boolean = false;

  percentDone: number = 100;
  isEdit: boolean = false;
  editFile: IsGuvenligiImzaDosyaModel = undefined;

  dosya_adi;


  tag: string = 'ImzaEkleComponent  --->';
  form: FormGroup;
  get file(): FormControl { return this.form.get('_file') as FormControl; }


  isLoadings = {};
  isSubmitting: boolean = false;

  otherOrgPersonel_apas_id: number = undefined;
  editData;
  constructor(private fb: FormBuilder,
    private ref: NbDialogRef<ImzaEkleComponent>,
    private isGuvenligiService: IsGuvenligiServiceV2) {

  }

  ngOnInit() {
    this.initForm();
    if (this.editData) {
      this.form.patchValue(this.editData);
      setTimeout(() => {
        this.form.get('signed_by').setValue(this.editData.signed_by);
        console.log('formun Son Hali:', this.form.value);

      }, 500);
    }
  }
  initForm() {
    this.form = this.fb.group({
      santiye_id: [null],
      title: [null, Validators.required],
      file: [null, Validators.required],
      signed_by: [null, Validators.required],
      company_id: [null, Validators.required],
      description: [null, Validators.required],
    });
  }


  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = removeAllNull(this.form.value);
    this.isSubmitting = true;
    this.isGuvenligiService.addImza(formData).subscribe((response) => {
      console.log(this.tag, 'post respnse:', response);
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.closed(response);
      this.isSubmitting = false;
    }, (err) => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      this.isSubmitting = false;
    });

  }

  patchForm() {
    if (!this.form.valid) {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      this.form.markAllAsTouched();
      return;
    }
    const formData = removeAllNull(this.form.value);
    this.isSubmitting = true;
    this.isGuvenligiService.updateImza(this.editData.identifier, formData).subscribe((response) => {
      this.isSubmitting = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.closed(response);
      this.isSubmitting = false;
    }, (err) => {
      this.isSubmitting = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

    });
  }


  closed(data?) {
    this.ref.close(data);
  }




}
