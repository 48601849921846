import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'talep-list-modal',
  templateUrl: './talep-list-modal.component.html',
  styleUrls: ['./talep-list-modal.component.css']
})
export class TalepListModalComponent implements OnInit {

  @Input() santiye_id: number;

  constructor(private ref: NbDialogRef<TalepListModalComponent>) { }


  ngOnInit() {
    console.log('Modal Santiye ID modal', this.santiye_id);
  }
closed(){
  this.ref.close();
}
}
