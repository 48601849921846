import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DuyuruModel } from '../models/duyuru.model';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class DuyuruService {
    private readonly API_URL = `${environment.backendurl}api/duyuru/`;


    constructor(private httpClient: HttpClient) { }
    getDuyuru(lastDate?: number) {
        const url: string = `${this.API_URL}gelen/${lastDate ? lastDate + '/' : ''}`;
        // console.log('duyuru service ---> getDuyuru url:', url);
        return this.httpClient.get(url);
    }
    /**
   * Geçmiş akış verisini çeker
   * @param date belli bir tarihden itibaren akış getirir.
   * @param page infinitolark çekilecek olan sayfanın indisini belirtir.
   */

    getDuyuruGiden(params?: { date?: number, page?: number }): Observable<DuyuruModel[]> {
        const url: string = `${this.API_URL}giden/${params.page ? (params.page + '/') : ''}`;
        return this.httpClient.get<DuyuruModel[]>(url);
    }

    postDuyuru(data) {
        const url: string = `${this.API_URL}add/`;
        return this.httpClient.post(url, data);
    }

    setReaded(id: number) {
        const url: string = `${this.API_URL}update/${id}/`;
        return this.httpClient.patch<DuyuruModel>(url, { isReaded: true });
    }

}
