<div class="d-flex justify-content-between align-items-center experience">
  <h6>Detaylı Bilgiler</h6>

</div>

<nb-list class="userIslemler">
  <nb-list-item class="px-0 p-0">
    <nb-accordion class="w-100" style="box-shadow: none;">
      <nb-accordion-item>
        <nb-accordion-item-header class="px-0">
          <div class="col-md p-1"><b>Bağlı Olduğu Şantiye</b></div>
          <div class="col-md text-center">
            <b>{{kullaniciBilgileri?.work_area?.santiye_title}}</b>
            <label class="label form-label w-100" style="margin-left: 8px;" *ngIf="kullaniciBilgileri?.work_area?.start_date "> ( {{kullaniciBilgileri?.work_area?.start_date * 1000 | date: tarih_format }} ) </label>
            <label class="label form-label w-100" style="margin-left: 8px;" *ngIf="!kullaniciBilgileri?.work_area?.start_date "> </label>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body class="p-0" [formGroup]="santiyeForm">
          <table class="table table-bordered" *ngFor="let item of kullaniciBilgileri?.workareas_set; let i = index;">
            <tbody>
              <tr>
                <th scope="row" class="izinTitle">Şantiye</th>
                <td *ngIf="!item?.edit"> {{ item?.santiye_title }} </td>
                <td *ngIf="item?.edit">
                  <santiye-select class="d-block" [is_active]="null" [mine]="true" formControlName="santiye"></santiye-select> 
                </td>
              </tr>
              <tr>
                <th scope="row" class="izinTitle">Başlangıç Tarihi </th>
                <td *ngIf="!item?.edit">{{ item?.start_date | date : tarih_format }}</td>
                <td *ngIf="item?.edit">
                  <input nbInput fullWidth [nbDatepicker]="editbaslamaTarihi" placeholder="Başlangıç Tarihi" formControlName="start_date">
                  <nb-datepicker #editbaslamaTarihi></nb-datepicker>
                </td>
              </tr>
              <tr>
                <th scope="row" class="izinTitle">Bitiş Tarihi</th>
                <td *ngIf="item?.finish_date && !item?.edit">{{ item?.finish_date | date : tarih_format }}</td>
                <td *ngIf="!item?.finish_date && !item?.edit">Devam ediyor</td>
                <td *ngIf="item?.edit">
                  <input nbInput fullWidth [nbDatepicker]="editBitis" placeholder="Bitiş Tarihi" formControlName="finish_date">
                <nb-datepicker #editBitis [min]="item?.start_time"></nb-datepicker>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <button nbButton size="tiny" status="warning" (click)="upDateSantiye(item)" *ngIf="!item?.edit">Düzenle</button>
                  <button nbButton size="tiny" class="silButton" status="danger" *ngIf="!item?.edit" (click)="deleteWorkArea(item)"> Sil </button>

                  <button nbButton size="tiny" status="warning"  *ngIf="item?.edit" (click)="patchSantiye(item.id)">Güncelle</button> 
                  <button nbButton size="tiny" class="silButton" status="danger" (click)="upDateSantiye(item)" *ngIf="item?.edit" > İptal </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row form-padding" [formGroup]="santiyeForm">
            <div class="col-md">
              <label class="label">Şantiye Seç</label>
              <santiye-select class="d-block" [mine]="true" formControlName="santiye" apasInvalid></santiye-select>
            </div>
            <div class="col-md">
              <label class="label">Başlangıç Tarihi</label>
              <input nbInput fullWidth [nbDatepicker]="baslamaTarihi" placeholder="Başlangıç Tarihi" formControlName="start_date" apasInvalid>
              <nb-datepicker #baslamaTarihi></nb-datepicker>
            </div>
            <div class="pt-4">
              <button nbButton status="primary" (click)="newSantiye()">Kaydet</button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-list-item>

  <nb-list-item class="px-0 p-0" [formGroup]="form">
    <nb-accordion class="w-100" style="box-shadow: none;">
      <nb-accordion-item>
        <nb-accordion-item-header style="display: grid;" class="pr-0">
          <div class="row w-100">
            <div class="col-md p-1"><b>Çalışma Süresi:</b></div>
            <div class="col-md text-center">
              <b *ngIf="kullaniciBilgileri?.calisma_suresi">({{kullaniciBilgileri.calisma_suresi}})</b><br>
              <label class="label" style="display: initial;">{{kullaniciBilgileri?.start_date | date:tarih_format}}</label>
              <label class="label" style="display: initial;" *ngIf="kullaniciBilgileri?.finish_date"> - {{kullaniciBilgileri?.finish_date | date:tarih_format}}</label>
            </div>  
          </div>
          <!-- <div class="row w-100" *ngIf="kullaniciBilgileri?.finish_date">
            <div class="col-md p-1"><b>İşten Ayrılma Tarihi:</b></div>
            <div class="col-md text-center">{{kullaniciBilgileri.finish_date | date:tarih_format}}</div>  
          </div>
          <div class="row w-100" *ngIf="kullaniciBilgileri?.calisma_suresi">
            <div class="col-md p-1"><b></b></div>
            <div class="col-md text-center">{{kullaniciBilgileri.calisma_suresi}}</div>  
          </div> -->
          
        </nb-accordion-item-header>
        <nb-accordion-item-body class="p-0">
          <div class="row form-padding">
            <div class="col-md form-group">
              <label class="label">İşe Başlama Tarihi</label>
              <input nbInput fullWidth [nbDatepicker]="baslamaTarihi2" placeholder="İşe Başlama Tarihi" dateOnly formControlName="start_date">
              <nb-datepicker #baslamaTarihi2></nb-datepicker>
            </div>
            <div class="col-md form-group">
              <label class="label">İşten Ayrılma Tarihi</label>
              <input nbInput fullWidth [nbDatepicker]="isbitisTarihi" placeholder="İşten Ayrılma Tarihi" dateOnly formControlName="finish_date">
              <nb-datepicker #isbitisTarihi></nb-datepicker>
            </div>
            <div class="pt-4">
              <button nbButton status="primary" (click)="updatePersonel()">Kaydet</button>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-list-item>



  <nb-list-item class="px-0 p-0">
    <nb-accordion class="w-100" style="box-shadow: none;">
      <nb-accordion-item>
        <nb-accordion-item-header style="display: grid;">
          <div class="row">
            <div class="col-md p-1"><b>Kullanılan Yıllık İzin(Gün):</b></div>
            <div class="col-md text-center">
              <b> {{ kullaniciBilgileri?.leave_days?.leave_days ? kullaniciBilgileri?.leave_days?.leave_days : 0 }} </b> / 
              <b style="color: green;">{{ kullaniciBilgileri?.leave_days?.toplam_izin_hakki ? kullaniciBilgileri?.leave_days?.toplam_izin_hakki : 0 }}</b></div>  
          </div>
          <div class="pr-5">
            <hr>
          </div>
          <div class="row">
            <div class="col-md p-1"><b>Kullanılan Hastalık İzni(Gün):</b></div>
            <div class="col-md text-center"><b>{{kullaniciBilgileri?.leave_days?.sick_days ? kullaniciBilgileri?.leave_days?.sick_days : 0}}  </b></div>  
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body class="p-0">


          <div *ngIf="!izinEdit">
            <div class="row" *ngFor="let item of kullaniciBilgileri?.annualyearlyleavedays_set; let i = index;">
              <div class="col-1 form-group">
                <label class="form-label label">Yıl </label>
                <b class="w-100 d-block mt-2">{{item?.year}}</b>
              </div>
              <div class="col-2 form-group" >
                <label class="form-label label">İzin Hakkı </label>
                <input nbInput numberOnly size="tiny" apasInvalid [value]="item?.izin_hakki" fullWidth
                  (blur)="izinHakkiGuncelle(item,$event)">
              </div>
            
           
              <div class="col form-group mb-0" style="border-right: 1px solid #ccc;">
                <label class="form-label label w-100 text-center">Kullanılan İzinler </label>
                <span class="mt-2 text-center d-block w-100">
                  <b class="izinSayisi">{{item?.total_leave_days}}</b>
                </span>
              </div>
              <div class="col form-group">
                <label class="form-label label w-100 text-center">Raporlu Olduğu Gün Sayısı </label>
                <span class="mt-2 text-center d-block w-100">
                  <b class="izinSayisi" style="background-color: #ff7043;">{{item?.total_sick_days}}</b>
                </span>
              </div>
            </div>
            <table class="table table-bordered" *ngFor="let item of kullaniciBilgileri?.leavedays_set;let i = index;">
              <tbody>
                <tr>
                  <th scope="row" class="izinTitle">İzin Başlama Tarihi</th>
                  <td>{{item?.start_date | date:tarih_format}}</td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Bitiş Tarihi</th>
                  <td>{{item?.finish_date | date:tarih_format}}</td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">Toplam İzin(Gün)</th>
                  <td>{{item?.days}}</td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Sebebi</th>
                  <td>{{item?.reason}}</td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İşlem Yap</th>
                  <td>
                    <button nbButton size="tiny" status="warning" (click)="izinGuncelle(item)">Düzenle</button>
                    <button nbButton size="tiny" status="danger" (click)="deleteIzin(item.id)"> Sil </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--Yeni İzin ekle-->
            <h6 class="text-success">Yeni izin ekle</h6>
            <table class="table table-bordered" [formGroup]="izinForm">
              <tbody>
                <tr>

                  <th scope="row" class="izinTitle">İzin Başlama Tarihi</th>
                  <td>
                    <div>
                      <input nbInput fullWidth [nbDatepicker]="baslamaTarihi" placeholder="Başlama Tarihi"
                        formControlName="start_date" apasInvalid>
                      <nb-datepicker #baslamaTarihi
                        [max]="izinForm.get('finish_date').value ? izinForm.get('finish_date').value : null ">
                      </nb-datepicker>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Bitiş Tarihi</th>
                  <td>
                    <input nbInput fullWidth [nbDatepicker]="bitisTarihi" placeholder="Bitiş Tarihi" apasInvalid
                      formControlName="finish_date">
                    <nb-datepicker #bitisTarihi
                      [min]="izinForm.get('start_date').value ? izinForm.get('start_date').value : null ">
                    </nb-datepicker>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">Toplam İzin(Gün)</th>
                  <td>
                    <b style="color:#ff7043">{{izinForm.get('toplam_izin').value}} Gün</b>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Sebebi</th>
                  <td>
                    <nb-radio-group formControlName="sick_or_dayoff" class="m-option">
                      <nb-radio [value]="true">Yıllık İzin</nb-radio>
                      <nb-radio [value]="false">Raporlu</nb-radio>
                    </nb-radio-group>
                    <textarea nbInput placeholder="İzin Sebebi" fullWidth apasInvalid
                      formControlName="reason"></textarea>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center">
                    <button nbButton status="primary" size="small" [nbSpinner]="saveLoad" [disabled]="saveLoad"
                      *ngIf="!izinEdit" (click)="newIzin()">Kaydet</button>
                    <button nbButton status="danger" size="small" *ngIf="izinEdit"
                      (click)="izinPatchIptal()">İptal</button>
                    <button nbButton status="primary" size="small" [nbSpinner]="saveLoad" [disabled]="saveLoad"
                      *ngIf="izinEdit" (click)="izinPatch()">Güncelle</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!--// Yeni İzin Ekle-->
          </div>

          <div *ngIf="izinEdit">
            <b>İzin Güncelle</b>
            <table class="table table-bordered" [formGroup]="izinForm">
              <tbody>
                <tr>

                  <th scope="row" class="izinTitle">İzin Başlama Tarihi</th>
                  <td>
                    <input nbInput fullWidth [nbDatepicker]="baslamaTarihi" placeholder="Başlama Tarihi" apasInvalid
                      formControlName="start_date">
                    <nb-datepicker #baslamaTarihi
                      [max]="izinForm.get('finish_date').value ? izinForm.get('finish_date').value : null ">
                    </nb-datepicker>

                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Bitiş Tarihi</th>
                  <td>
                    <input nbInput fullWidth [nbDatepicker]="bitisTarihi" placeholder="Bitiş Tarihi" apasInvalid
                      formControlName="finish_date">
                    <nb-datepicker #bitisTarihi
                      [min]="izinForm.get('start_date').value ? izinForm.get('start_date').value : null ">
                    </nb-datepicker>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">Toplam İzin(Gün)</th>
                  <td>
                    <b style="color:#ff7043">{{izinForm.get('toplam_izin').value}} Gün</b>
                  </td>
                </tr>
                <tr>
                  <th scope="row" class="izinTitle">İzin Sebebi</th>
                  <td>
                    <nb-radio-group formControlName="sick_or_dayoff" class="m-option">
                      <nb-radio [value]="true">Yıllık İzin</nb-radio>
                      <nb-radio [value]="false">Raporlu</nb-radio>
                    </nb-radio-group>
                    <textarea nbInput placeholder="İzin Sebebi" fullWidth apasInvalid
                      formControlName="reason"></textarea>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center">
                    <button nbButton status="primary" size="small" [nbSpinner]="saveLoad" [disabled]="saveLoad"
                      *ngIf="!izinEdit" (click)="newIzin()">Kaydet</button>
                    <button nbButton status="danger" size="small" 
                      *ngIf="izinEdit" (click)="izinPatchIptal()">İptal</button>
                    <button nbButton status="primary" size="small" [nbSpinner]="saveLoad" [disabled]="saveLoad"
                      *ngIf="izinEdit" (click)="izinPatch()">Güncelle</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-list-item>





</nb-list>