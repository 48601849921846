<nb-card size="medium" class="plus-card"  
  style="min-width: 500px !important; max-width: 500px !important; height: 100% !important; background-color: #FAFAFA !important;" 
  [nbSpinner]="isLoading">
  <nb-card-header>
    <div class="row">
      <div class="col-sm-1 linked-div" (click)="close()">X</div>

      <div class="col-sm-10">
        {{pursantajData?.pursantaj_name}}
      </div>
      <div class="col-sm-1">
        <!-- <button nbButton size="small" status="basic" (click)="editMod()">
          <nb-icon icon="edit-2-outline"></nb-icon>
        </button> -->
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="p-1" >
    <nb-accordion *ngIf="!isLoading" #mainAccordion>
      <nb-accordion-item #bilgiler>
        <nb-accordion-item-header>{{'GANTT.PURSANTAJBILGILERI'|translate}}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <nb-card>
            <nb-card-body>
              <div class="row">
                <div class="col-sm-12">
                  <b>{{pursantajData?.pursantaj_name}}</b>
                </div>
                <div class="col sm-12" style="font-size: 13px;">
                  {{'ISMAKINASIPUANTAJRAPORU.BASLANGICTARIHI'|translate}} <b>{{pursantajData?.start_day * 1000 | date}}</b>
                </div>
                <div class="col sm-12" style="font-size: 13px;">
                  {{'ISMAKINASIPUANTAJRAPORU.BITISTARIHI'|translate}} <b>{{pursantajData?.start_day *1000 | date}}</b>
                </div>

                <div class="col-sm-12">
                  <nb-progress-bar [value]="pursantajData?.completed_percentage" status="success">{{pursantajData?.completed_percentage}}</nb-progress-bar>
                </div>
              </div>
            </nb-card-body>
          </nb-card>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item *ngIf="pursantajData.can_edit">
        <nb-accordion-item-header>{{'GANTT.BAGLAR'|translate}}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <nb-tabset fullWidth (changeTab)="selectList($event)">
            <nb-tab tabTitle="SS" [badgeText]="pursantajData?.ss.length" badgeStatus="danger" class="m-0 p-0" style="overflow: hidden;">
              <div class="row">
                <div class="col-sm-12  m-2">
                  <b style="color: orange;">{{'GANTT.SSBASLAMADAN'|translate}}</b>
                </div>
                <div class="row w-100 m-0 p-0">
                  <div class="col-sm-12" *ngIf="pursantajData?.ss.length > 0">
                    <div class="col-sm-12" *ngFor="let s of pursantajData?.ss">
                      <div class="row">
                        <div class="col-sm-10">
                          • {{s?.pursantaj_name}}
                        </div>
                        <div class="col-sm-2" *ngIf="isEditing">
                          <nb-icon icon="close-circle-outline" class="linked-div" (click)="removeDepend(s, 'SS')">
                          </nb-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="isEditing">
                    <div class="row w-100">
                      <div class="col-sm-8">
                        <ng-select 
                          fullWidth 
                          [items]="returnData"
                          bindLabel="pursantaj_name"
                          bindId="id"
                          appendTo="body"
                          [placeholder]="'PURSANTAJEKLE.PURSANTA'|translate"
                          [formControl]="newDepend"
                          >

                        </ng-select>
                      </div>
                      <div class="col-sm-4">
                        <button size="small" nbButton (click)="addNewDepend('SS')" style="float: right;">{{'MUHASEBE.YENIEKLE'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nb-tab>
            <nb-tab tabTitle="SF" [badgeText]="pursantajData?.sf.length" badgeStatus="danger" class="m-0 p-0"  style="overflow: hidden;">
              <div class="row">
                <div class="col-sm-12  m-2">
                  <b style="color: orange;">{{'GANTT.SFBASLAMADAN'|translate}}</b>
                </div>
                <div class="row w-100 m-0 p-0">
                  <div class="col-sm-12" *ngIf="pursantajData?.sf.length > 0">
                    <div class="col-sm-12" *ngFor="let s of pursantajData?.sf">
                      <div class="row">
                        <div class="col-sm-10">
                          • {{s?.pursantaj_name}}
                        </div>
                        <div class="col-sm-2" *ngIf="isEditing">
                          <nb-icon icon="close-circle-outline" class="linked-div" (click)="removeDepend(s, 'SF')">
                          </nb-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="isEditing">
                    <div class="row w-100">
                      <div class="col-sm-8">
                        <ng-select 
                          fullWidth 
                          [items]="returnData"
                          bindLabel="pursantaj_name"
                          bindId="id"
                          appendTo="body"
                          [placeholder]="'PURSANTAJEKLE.PURSANTA'|translate"
                          [formControl]="newDepend"
                          >

                        </ng-select>
                      </div>
                      <div class="col-sm-4">
                        <button size="small" nbButton (click)="addNewDepend('SF')" style="float: right;">{{'MUHASEBE.YENIEKLE'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nb-tab>
            <nb-tab tabTitle="FF" [badgeText]="pursantajData?.ff.length" badgeStatus="danger" class="m-0 p-0"  style="overflow: hidden;">
              <div class="row">
                <div class="col-sm-12  m-2">
                  <b style="color: orange;">{{'GANTT.FFBITMEDEN'|translate}}</b>
                </div>
                <div class="row w-100 m-0 p-0">
                  <div class="col-sm-12" *ngIf="pursantajData?.ff.length > 0">
                    <div class="col-sm-12" *ngFor="let s of pursantajData?.ff">
                      <div class="row">
                        <div class="col-sm-10">
                          • {{s?.pursantaj_name}}
                        </div>
                        <div class="col-sm-2" *ngIf="isEditing">
                          <nb-icon icon="close-circle-outline" class="linked-div" (click)="removeDepend(s, 'FF')">
                          </nb-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="isEditing">
                    <div class="row w-100">
                      <div class="col-sm-8">
                        <ng-select 
                          fullWidth 
                          [items]="returnData"
                          bindLabel="pursantaj_name"
                          bindId="id"
                          appendTo="body"
                          [placeholder]="'PURSANTAJEKLE.PURSANTA'|translate"
                          [formControl]="newDepend"
                          >

                        </ng-select>
                      </div>
                      <div class="col-sm-4">
                        <button size="small" nbButton (click)="addNewDepend('FF')" style="float: right;">{{'MUHASEBE.YENIEKLE'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nb-tab>
            <nb-tab tabTitle="FS" [badgeText]="pursantajData?.fs.length" badgeStatus="danger" class="m-0 p-0"  style="overflow: hidden;">
              <div class="row  ">
                <div class="col-sm-12 m-2">
                  <b style="color: orange;">{{'GANTT.FSBITMEDEN'|translate}}</b>
                </div>
                <div class="row w-100 m-0 p-0">
                  <div class="col-sm-12" *ngIf="pursantajData?.fs.length > 0">
                    <div class="col-sm-12" *ngFor="let s of pursantajData?.fs">
                      <div class="row">
                        <div class="col-sm-10">
                          • {{s?.pursantaj_name}}
                        </div>
                        <div class="col-sm-2" *ngIf="isEditing">
                          <nb-icon icon="close-circle-outline" class="linked-div" (click)="removeDepend(s, 'FS')">
                          </nb-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="isEditing">
                    <div class="row w-100">
                      <div class="col-sm-8">
                        <ng-select 
                          fullWidth 
                          [items]="returnData"
                          bindLabel="pursantaj_name"
                          bindId="id"
                          appendTo="body"
                          [placeholder]="'PURSANTAJEKLE.PURSANTA'|translate"
                          [formControl]="newDepend"
                          >

                        </ng-select>
                      </div>
                      <div class="col-sm-4">
                        <button size="small" nbButton (click)="addNewDepend('FS')" style="float: right;">{{'MUHASEBE.YENIEKLE'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nb-tab>
          </nb-tabset>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item  #yapilanIs (collapsedChange)="currentTab($event)"> <!-- (collapsedChange)="currentTab($event)"-->
        <nb-accordion-item-header [nbSpinner]="yapilanListLoading" >{{'TASERONHAKEDIS.YAPILANIS'|translate}}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <div style="margin-bottom: 10px; " *ngFor="let anais of yapilanisler">
            <div> <b class="s1">{{anais.pursantaj_name}}</b></div>
            <nb-card *ngFor="let altis of anais?.liste" class="m-1">
              <nb-card-body class="pt-0 pb-2" [nbTooltip]="'Ekleyen : ' + altis?.created_by_name">
               <div class="row">
                <div class="col-sm-3">
                 <b style="font-size: 12px;">{{altis.date * 1000 | date}}</b>
                </div>
                <div class="col-sm-9">
                  <nb-progress-bar [value]="altis.work_done_percentage" status="success"
                    style="border: 1px solid #7b7b7b !important; border-radius: 0.25rem;">
                    %{{altis.work_done_percentage}} </nb-progress-bar>
                </div>
               </div>
               <div class="row">
                <div class="col-sm-12" *ngIf="altis?.desc" style="font-size: 13px;">
                  <b>{{'GLOBAL.NOTE'|translate}} </b> {{altis?.desc}}
                </div>
               </div>
               <div class="row">

                <div class="col-sm-12" *ngIf="altis.photo_count > 0">
                  <div class="row">
                    <div class="col-sm-11">
                      <img *ngFor="let photo of altis?.photos; let photo_index=index" [src]="photo?.image" class="img-fluid img-thumbnail openImageList(altis)"
                      style="max-height: 60px; max-width: 100px;" [alt]="photo.note" (click)="openImageDetail(photo, altis?.photos, altis?.photos.photo_count, photo_index)">

                    </div>

                    <div class="col-sm-1 p-1 linked-div" *ngIf="altis.photo_count > 3" (click)="openImageList(altis)">
                        <nb-icon icon="arrowhead-right-outline"></nb-icon>
                    </div>
                  </div>
                </div>

               </div>
              </nb-card-body>
            </nb-card>

          </div>
            <div>
              <button nbButton *ngIf="pursantajData?.alt_pursantaj?.length == 0"(click)="openYapilanIs()" size="small" style="float: right;">{{'GANTT.YENIISEKLE'|translate}}</button>
            </div>
        </nb-accordion-item-body>

      </nb-accordion-item>
      <nb-accordion-item class="duzenle-item"  *ngIf="isEditing && pursantajData.can_edit" [@childAnimation]="animate ? 'in' : 'out'" #duzenle>
        <nb-accordion-item-header>{{'GANTT.BILGILERIDUZENLE'|translate}}</nb-accordion-item-header>

        <nb-accordion-item-body *ngIf="form">
          <form [formGroup]="form">
            <div class="row ">

              <div class="form-row">

                <div class="form-group col-sm-12">
                  <label for="name"  class="form-label label">{{'GANTT.PURSANTAJADI'|translate}}</label>
                  <input #name nbInput fullWidth [placeholder]="'GANTT.PURSANTAJADI'|translate" class="form-control cutom-input-color"
                    formControlName="pursantaj_name" apasInvalid (blur)="setFormValues('pursantaj_name')">
                </div>

                <div class="form-group col-sm-12">
                  <label for="rate"  class="form-label label">{{'GANTT.PURSANTAJORANI'|translate}}</label>
                  <input #rate 
                    nbInput fullWidth 
                    paraDirective
                    [plcholder]="'GANTT.PURSANTAJORANI'|translate"
                    [encrypt]="false"
                    [decrypt]="false"
                    [negative]="false"
                    [forceRefresh]="fc"
                    class="form-control cutom-input-color"
                    formControlName="pursantaj_rate" 
                    apasInvalid (blur)="setFormValues('pursantaj_rate')">
                </div>

                <div class="form-group col-sm-12">
                  <label for="rate" class="form-label label">Metraj</label>
                  <div class="d-flex">
                    <input #rate 
                    nbInput 
                    paraDirective
                    [plcholder]="'Metraj'"
                    [encrypt]="false"
                    [decrypt]="false"
                    [negative]="false"
                    [forceRefresh]="fc"
                    class="form-control cutom-input-color"
                    formControlName="metraj" 
                    apasInvalid (blur)="setFormValues('metraj')">
                    <ng-select 
                      [items]="birim" 
                      appendTo="body" 
                      [addTag]="false" 
                      [placeholder]="'DEPO.BIRIM' | translate" 
                      bindLabel="label"
                      bindValue="value" 
                      [searchable]="true" 
                      style="width: 50%;"
                      formControlName="metraj_unit"
                      (change)="setFormValues('metraj_unit')"
                      >
                    </ng-select>
                  </div>

            
                </div>

                <div class="form-group col-sm-12">
                  <label for="start"  class="form-label label">
                    {{'GANTT.ISINBASLANGICTARIHI'|translate}}
                  </label>
                  <input 
                    #start 
                    nbInput 
                    fullWidth 
                    placeholder=" " 
                    fieldSize="small" 
                    [nbDatepicker]="startDay"
                    formControlName="start_day"
                    >
                  <nb-datepicker #startDay (dateChange)="setFormValues('start_day')"
                  [max]="start_max_date"
                  [min]="start_min_date"
                  ></nb-datepicker>
                </div>
                <div class="form-group col-sm-12">
                  <label for="end"  class="form-label label">
                    {{'GANTT.ISINBITISTARIHI'|translate}}
                  </label>
                  <input 
                    nbInput
                    fullWidth 
                    placeholder=" " 
                    fieldSize="small" 
                    [nbDatepicker]="end"
                    formControlName="end_day"

                    >
                  <nb-datepicker #end (dateChange)="setFormValues('end_day')"
                    [max]="end_max_date"
                    [min]="end_min_date"
                  ></nb-datepicker>
                </div>
                <div class="form-group col-sm-12">
                  <label for="renk"  class="form-label label">{{'GANTT.RENK'|translate}}</label>
                    <ng-select 
                    
                    [items]="renkList"
                    bindValue="color"
                    bindLabel="color"
                    appendTo="body"
                    class="ng-select-without-bottom-margin"
                    [placeholder]="'GANTT.RENK'|translate"
                    name="color"
                    formControlName="color"
                    (blur)="setFormValues('color')"
                    
                    >
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                          <div class="w-100" [ngStyle]="{'background-color':item.color_code, 'color':item.color_code}">
                              {{item.color}}
                          </div>
                      </ng-template>
                      <ng-template ng-label-tmp let-item="item" let-index="index" class="w-100">
                        <div class="w-100" [ngStyle]="{'background-color':item.color_code, 'color':item.color_code}">
                          {{item.color}}
                       </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-sm-12">
                  <button nbButton (click)="setFormValues('all')">{{'GOREVLIGRUBU.KAYDET'|translate}}</button>
                </div>
              </div>
            </div>
          </form>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

  </nb-card-body>

</nb-card>