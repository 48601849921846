import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { LocationService } from '../../../services/location.service';
import { Store } from '@ngrx/store';
import { LocationModel } from '../../../models/location.model';
import { Input } from '@angular/core';
import { SetLocation } from '../../../store/aplikasyon/aplikasyon.actions';
@Component({
  selector: 'apas-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  providers: [LocationService],
})
export class MapComponent implements OnInit {
  @Input() isCard: boolean = false;
  @Input() santiyeMap: boolean = false;
  @Input() lat: any = 37;
  @Input() lng: any = 37;
  @Output() onDraggedMap: EventEmitter<any> = new EventEmitter();

  draggable: boolean = true;
  searchInput: string;
  sendData: any[] = [];
  address = 'London';
  location: LocationModel;


  Longs: any;
  Lats: any;
  constructor(private locationService: LocationService,
    private ref: ChangeDetectorRef,
    private store: Store<{}>) { }

  ngOnInit() {

    this.location = {
      lat: this.lat,
      lng: this.lng,
      city: '',
      country: '',
      address: '',
    };
    console.log(this.location);

  }

  markerDragged($event: MouseEvent) {
    this.Lats = $event.coords.lat;
    this.Longs = $event.coords.lng;

    const _location: LocationModel = { lat: this.Lats, lng: this.Longs, city: '', country: '', address: '' };
    this.store.dispatch(SetLocation({ curLoc: _location }));
  }

  DragEnd() {
    this.addressByLocation(this.Lats, this.Longs);
  }
  showLocation() {
    this.addressToCoordinates();
  }

  addressToCoordinates() {
    this.locationService.geocodeAddress(this.searchInput)
      .subscribe((location: LocationModel) => {
        const _location: LocationModel = {
          lat: location.lat,
          lng: location.lng,
          city: location.city,
          country: location.country,
          address: '',
        };
        this.store.dispatch(SetLocation({ curLoc: location }));
        this.location = location;
        this.ref.detectChanges();
      },
      );
  }
  mapClicked(event) {
    /*
    coords:
      lat: 37.020883003038186
      lng: 37.004782490707406
    */
    const { lat, lng } = event.coords;
    this.Lats = lat;
    this.Longs = lng;
    const _location: LocationModel = { lat: this.Lats, lng: this.Longs, city: '', country: '', address: '' };
    this.store.dispatch(SetLocation({ curLoc: _location }));
    this.addressByLocation(lat, lng);
  }
  addressByLocation(lat, lng) {
    this.locationService.geocodeAddressByLocation(lat, lng)
      .subscribe((location: any) => {
        this.store.dispatch(SetLocation({ curLoc: location }));
        this.location = location;
        this.ref.detectChanges();
      },
      );
  }
}

