import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  NbBaseCalendarModule,
  NbCalendarKitModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbOverlayModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { ApasBaseCalendarComponent } from './calander/apas-base-calander.component';
import { ApasMonthCalendarComponent } from './calander/apas-calender.component';
import { ApasMonthCalendarRangeComponent } from './calander/apas-month-calendar-range.component';
import { ApasMonthAdapterService, ApasMonthRangeAdapterService } from './monthpicker-adapter';
import { ApasMonthPickerComponent, ApasMonthRangepickerComponent, ApasBasePickerComponent } from './monthpicker.component';
import { ApasMonthPickerDirective, APAS_MONTH_ADAPTER } from './monthpicker.directive';

@NgModule({
  declarations: [
    ApasBaseCalendarComponent,
    ApasMonthCalendarComponent,
    ApasMonthCalendarRangeComponent,
    ApasMonthPickerDirective,
    ApasMonthPickerComponent,
    ApasMonthRangepickerComponent,
    ApasBasePickerComponent,
  ],
  imports: [
    CommonModule,
    NbOverlayModule,
    NbCalendarModule,
    NbCalendarRangeModule,
    NbCardModule,
    NbBaseCalendarModule,
    NbTimepickerModule,
    NbCalendarKitModule,
    // NbMomentDateModule,
  ],
  exports: [
    ApasBaseCalendarComponent,
    ApasMonthCalendarComponent,
    ApasMonthCalendarRangeComponent,
    ApasMonthPickerDirective,
    ApasMonthPickerComponent,
    ApasMonthRangepickerComponent,
    ApasBasePickerComponent,
  ],
  entryComponents: [
    ApasMonthCalendarComponent,
    ApasMonthCalendarRangeComponent,
  ],
})
export class ApasMonthPickerModule {

  static forRoot(): ModuleWithProviders<ApasMonthPickerModule> {
    return {
      ngModule: ApasMonthPickerModule,
      providers: [
        DatePipe,
        {
          provide: APAS_MONTH_ADAPTER,
          multi: true,
          useClass: ApasMonthAdapterService,
        },
        {
          provide: APAS_MONTH_ADAPTER,
          multi: true,
          useClass: ApasMonthRangeAdapterService,
        },
      ],
    };
  }
}
