import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';

export const EmailValidation = [Validators.required, Validators.email];
export const PasswordValidation = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(24),
];
export const NameValidation = [Validators.required];
export const PhoneValidation = [Validators.required];

export const RepeatPasswordValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const passwordAgain = control.get('passwordAgain');

  return password && passwordAgain && password.value !== passwordAgain.value ?
    { 'RepeatPasswordValidator': true } : null;
};

// tslint:disable-next-line: max-line-length
export const BothCantBeNullValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const control1 = control.get('tamamlanan_metraj');
  const control2 = control.get('work_done_percentage');

  // tslint:disable-next-line: max-line-length

  if (!control1 && !control2) {
    return { 'BothCantBeNullValidator': true };
  }
  return null;
};


export function BothEmptyValidator(otherControl: string): ValidatorFn {
  return (control: FormControl): ValidationErrors => {
    const mainForm: FormGroup = control.parent as FormGroup;
    if (!mainForm) return null;

    const secondControl: FormControl = mainForm.get(otherControl) as FormControl;
    if (control.value) {
      // secondControl.updateValueAndValidity({emitEvent: false});
      return null;
    } else if (secondControl.value) {
      // control.updateValueAndValidity({emitEvent: false});
      return null;
    }
    return { 'bothempty': true };
  };
}
