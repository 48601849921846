import { createAction, props } from '@ngrx/store';

export const loadData = createAction('[CRM Load Satis List]', props<{ filter?: any[] }>());
export const loadDataSuccess = createAction('[CRM Load Success Satis List]', props<{ data: any[], isEnd?: boolean }>());
export const loadDataFail = createAction('[CRM Load Satis Fail]', props<{ error: string, isEnd?: boolean }>());

export const resetData = createAction('[CRM Filter Data Satis]');

export const selectSatis = createAction('[CRM Selected Satis]', props<{ selectedId: number }>());

export const addNewSatis = createAction('[CRM Add New Satis]', props<{ data: any ,module}>());
export const addNewSatisSuccess = createAction('[CRM Add New Satis Success]', props<{ data: any }>());
export const addNewSatisFail = createAction('[CRM Add New Satis Fail]', props<{ error: any }>());



export const getSatisDetail = createAction('[Get] CRM Satis Detay', props<{satisId: number}>());
export const setSatisDetail = createAction('[Set] CRM Satis Detay', props<{satisId: number, data: any }>());
