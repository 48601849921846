<ng-select
      [items]="organizationList$ | async"
      [loading]="isLoading"
      [clearable]="clearable"
      appendTo="body"
      [typeahead]="organizationInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindLabel="name"
      [bindValue]="bindValue"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'Firma Seç'"
      (change)="organizationSelected($event)"
  >
      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
      <div class="d-flex justify-content-center">

      <button nbButton size="small" status="primary" hero (click)="openNewFirm('isveren')" *ngIf="showIsverenAddButton"> Yeni İşveren Firma Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('laboratuvar')" *ngIf="showLaboratuvarAddButton"> Yeni Laboratuvar Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('musavir')" *ngIf="showMusavirAddButton"> Yeni Müşvari Firma Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('kontrol')" *ngIf="showKontrolAddButton"> Yeni Kontrol Firması Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('isguvenligi')" *ngIf="showIsGuvenligiAddButton"> Yeni İş Güvenliği Firması Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('taseron')" *ngIf="showTaseronAdd"> Taşeron Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('tedarikci')" *ngIf="showTedarikciAdd"> Tedarikçi Ekle </button>
      </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>