<nb-card class="modalType animated fadeInRight" size="giant" style="width: 75%;">
  <nb-card-header class="d-flex justify-content-between">
    <span>Görevler</span>
    <!-- <b>({{plate}})</b> -->
    <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
  </nb-card-header>


  <nb-card-body [formGroup]="form" style="height: 30rem;" [nbSpinner]="isLoading">
    <div class="aps-splt">
      <nb-card>
        <!--Filter Template-->
        <apas-filter [islemlerTemplate]="islemler" [araText]="'Görev Ara'" [formValue]="form"
          formControlName="search" [showFilterButton]="false"></apas-filter>

     
        <ng-template #islemler>
          <span class="mr-2">
            <ng-select #biten [items]="gorevType" bindLabel="name" class="uzun-select ng-select-without-bottom-margin"
              [searchable]="false" autofocus bindValue="id" appendTo="body" formControlName='tip' [clearable]="false">
              <ng-template ng-option-tmp let-item="item">
                {{'GOREV.'+item.name | translate}}
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                {{'GOREV.'+item.name | translate}}
            </ng-template>
            </ng-select>
          </span>
          <button nbPopoverPlacement="bottom" status="warning" nbButton class="ekleIcon"
            (click)="openYeniGorev()">
            <nb-icon icon='plus-outline'> </nb-icon> {{'YENIEKLE'|translate}}
          </button>
          <!-- <button class="excel_button" nbButton type="file" size="small" (click)="listeExcel()" [nbSpinner]="excelReporting">Excel Raporu</button> -->

        </ng-template>
        <!--// Filter Template-->
      </nb-card>
      <nb-card class="anaTable">
        <nb-card-header style="position: relative !important; top: 0 !important;">
          <div class="row m-0 p-0 tableHeader">
            <div class="col-md-3">
              Başlangıç - Bitiş Tarihi
            </div>

            <div class="col-md-3">
              Başlık
            </div>
            <div class="col-md-2">
              Oluşturan
            </div>
            <div class="col-md">
              Görevliler
            </div>
            <div class="col-md-1">

            </div>
          </div>
        </nb-card-header>
        <nb-card-body>
          <nb-list class="row p-0 m-0 w-100" nbInfiniteList [threshold]="500" (bottomThreshold)="onScrool()">
            <nb-list-item class="aps-hover p-0" *ngFor="let row of rows;let i = index;">
              <div class="col-md-3">
                <b>{{i+1}} |</b>
                {{row?.created_time | date: tarih_format}} -
                {{row?.end_time | date: tarih_format}}
              </div>
              <div class="col-md-3">
                <b [nbTooltip]="row?.title">{{row?.title | slice:0:30}} {{row?.title.length > 30 ? '...':''}} </b>
              </div>

              <div class="col-md-2">
                {{row?.created_by_name}}
              </div>
              <div class="col-md">

                <b style="font-weight: 400; display: inline-block;"
                  *ngFor="let item of row?.assign_to; let  i = index;">
                  <span class="badge badge-secondary text-secondary linked-div m-1"
                    [style]="'background:'+colorSet[i+1]">
                    {{item?.name}}
                  </span>
                </b>
              </div>
              <div class="col-md-1">
                <button status="info" nbButton size="small" (click)="openDetail(row?.id)">Detay</button>
              </div>
            </nb-list-item>
          </nb-list>
          <aps-emptylist *ngIf="!isLoading && !(rows && rows.length > 0)">

          </aps-emptylist>
        </nb-card-body>
      </nb-card>
    </div>
  </nb-card-body>
</nb-card>


<!-- <nb-card  class="modalType animated fadeInRight" size="giant" style="width:1000px;">
 
    <nb-card-header class="p-2">
        <div class="row" style="align-items: center;">
            <div class="col-md-5 col-xxl-5">

                <nb-icon icon="icon-gorevler" pack="custom-icons"></nb-icon>
                {{ 'GOREVLER.TITLE' | translate }}
            </div>
            <div class="col-md pr-0">
                <ng-select #biten [items]="gorevType" bindLabel="name" class="ng-select-without-bottom-margin"
                    [searchable]="false" autofocus bindValue="id" appendTo="body" [formControl]="tipControl"
                    [clearable]="false">

                </ng-select>
            </div>
            <div class="col-xxl-3 col-md-3 pl-0 text-right" style="margin-right: 10px;">

                <button nbButton size="tiny" (click)="openYeniGorev()" class="p-1" status="primary"
                    [nbTooltip]=" 'GOREVLER.GOREVEKLE' | translate">
                    <nb-icon icon="plus-outline" style="color:#fff;width: 22px;  height: 22px;"></nb-icon>
                </button>

                <nb-icon [icon]="mIcon" style="width: 24px; height: 24px;" (click)="searchGizleGoster()"
                    class="linked-div">
                </nb-icon>

                <div class="m-search">
                    <input nbInput [formControl]="searchControl" style="position: absolute; right: 45px; bottom: -5px;"
                        [placeholder]=" 'GOREVLER.GOREVARA' | translate" *ngIf="sGizleGoster"
                        class="animated slideInRight">
                </div>

                <button nbButton 
                    style="background: none !important; padding: 0px; color: black !important;" (click)="closed()">
                    <nb-icon icon="close-outline"></nb-icon>
                </button>




            </div>
        </div>


    </nb-card-header>

    <nb-card-body class="p-2"  > 
        <nb-list nbInfiniteList [threshold]="500" (bottomThreshold)="onScrool()">
            <nb-list-item *ngFor="let i=index; let gorev of rows" style="border: none;">

                <nb-card [accent]="gorev.gorevKimin" class="w-100 linked-div" style="margin: 0px !important;" (click)="openDetail(gorev.id)">
                <nb-card-body style="overflow: hidden;" class="pb-1 pt-0">
                    <div class="row">
                        <div class="col-md-8 pt-3">
                            <p style="margin-bottom:0px;">

                                <small style="opacity: 0.8;"> {{ 'GOREVLER.OLUSTUR' | translate
                                    }}{{gorev.created_by_name}}</small> - <small
                                    style="opacity:0.8">{{gorev.created_time  | date: tarih_format}}</small>

                                <br>{{ 'GOREVLER.BASLIK' | translate }} <b>{{gorev.title}}</b>
                            </p>

                            <p style="opacity: 0.8;margin-bottom:0px;"> {{ 'GOREVLER.GOREVLILER' | translate }}<b
                                    style="font-weight: 400;" *ngFor="let item of gorev.assign_to"> {{item.name}} ,
                                </b><br></p>

                            <p style="opacity: 0.8;margin-bottom:0px;"> {{ 'GOREVLER.BITIS' | translate }} <b
                                    style="font-weight: 400;"> {{gorev.end_time | date: tarih_format}}
                                </b><br></p>
                        </div>
                        <div class="col-md text-center">
                            <circle-progress [percent]="gorev.percentage" [radius]="20" [outerStrokeWidth]="6"
                                [innerStrokeWidth]="6" [space]="-6" [outerStrokeColor]="gorev.char1"
                                [innerStrokeColor]="gorev.char2" [titleFontSize]="'12'" [unitsFontSize]="'12'"
                                [showSubtitle]="false" [animation]="true" [animationDuration]="1000"
                                [startFromZero]="false" [responsive]="false">

                            </circle-progress>
                            <br>
                            <b> {{ 'GOREVLER.ILERLEME' | translate }} <br>
                                <small> {{ 'GOREVLER.KALANGUN' | translate }}
                                    {{(gorev.kalanSure>0)?gorev.kalanSure:((gorev.kalanSure===0)?'Son gün':'Günü geçti')}}</small>
                            </b>

                        </div>

                    </div>

                </nb-card-body>
            </nb-card>

            </nb-list-item>

            
          </nb-list>
        
       
    </nb-card-body>


</nb-card> -->