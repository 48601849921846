<nb-card class="modalType animated fadeInRight" size="giant" style="width:70%">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>Başvuru Seçenekleri</span>
        <button nbButton (click)="close()" class="heryerdeClose" size="tiny">x</button>
    </nb-card-header>

    <nb-card-body>

        <div class="row animated slideInUp">
            <div class="col-12">
                <nb-card class="efaturaBilgiCard">
                    <nb-card-body>
                        <div class="row">
                            <div class="col-md-2 d-flex justify-content-center align-items-center" style="border-right: 1px solid #eee;">
                                <nb-icon icon="file-text-outline"></nb-icon>
                            </div>
                            <div class="col-md">
                                <h6>E-FATURA BAŞVURUSU</h6>
                                <p>Apas - eLogo İşbirliği Kapsamında E-Fatura / E-Arşiv Fatura kullanımınızı eLogo
                                    üzerinden yapabilirsiniz.
                                    Süreç hakkında daha detaylı bilgi almak için aşağıdaki <b class="secenekHizliButton linked-div"
                                        (click)="basvuruSec('ilkBasvuru')">E-Fatura Başvurusu Yapmak İstiyorum</b>
                                    Bölümünü takip ederek,
                                    eLogo ekibinin size ulaşmasını sağlayabilirsiniz.</p>

                                <p> eLogo kullanıyorsanız, <b class="secenekHizliButton linked-div"
                                        (click)="basvuruSec('giris')">zaten eLogo kullanıcısıyım</b> alanından eLogo
                                    kullanıcı adı ve şifreniz ile giriş yapabilirsiniz.</p>

                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="col-md-12">
                <nb-card class="efaturaBilgiCard">
                    <nb-card-body >
                        <div class="row">
                            <div class="col-md-2 d-flex justify-content-center align-items-center"
                                style="border-right: 1px solid #eee;">
                                <nb-icon style="transform: rotate(-90deg);" icon="download-outline"></nb-icon>
                            </div>
                            <div class="col-md">
                                <h6>ZATEN E-FATURA KULLANICISIYIM</h6>
                                <p>
                                    Eğer E-Fatura mükellefi iseniz veya E-Arşiv kullanıyorsanız ve <b>eLogo üyesi iseniz</b>, bu adımdan devam ederek
                                    eLogo giriş işlemlerini yapabilir
                                    ve hemen e-fatura gönderip almaya başlayabilirsiniz.
                                </p>
                                <div class="text-right" style="margin-top: auto;">
                                    <button nbButton status="warning" (click)="basvuruSec('giris')">Giriş Yap</button>
                                </div>
        
                            </div>
                        </div>

                    </nb-card-body>
                </nb-card>
            </div>

            <div class="col-md-12">

                <nb-card class="efaturaBilgiCard">
                    <nb-card-body >
                        <div class="row">
                            <div class="col-md-2 d-flex justify-content-center align-items-center"
                            style="border-right: 1px solid #eee;">
                                                            <nb-icon icon="paper-plane-outline"></nb-icon>
                            </div>

                            <div class="col-md">
                                <h6>E-FATURAYA YENİ BAŞVURU YAPMAK İSTİYORUM</h6>
                                <p>
                                    Bu adımdan devam ederek <b>eLogo'ya başvurunuzu yapabilir</b> ve iki iş günü içinde
                                    E-Fatura / e-Arşiv Fatura gönderip almaya başlayabilirsiniz.
                                </p>
                                <div class="text-right" style="margin-top: auto;">
                                    <button nbButton status="warning" (click)="basvuruSec('ilkBasvuru')">Başvuru Yap</button>
                                </div>
        
                            </div>
                        </div>


                    </nb-card-body>
                </nb-card>
            </div>

            
        </div>



    </nb-card-body>
</nb-card>