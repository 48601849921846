import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class EntegrasyonService {
    private readonly API_URL = `${environment.backendurl}api/entegrasyon/`;

    constructor(private httpClient: HttpClient) { }

    getEFatura(data: any): Observable<any> {
        const url: string = `${this.API_URL}listfatura/`;
        return this.httpClient.post<any>(url, data);
    }

    getStatus(): Observable<any> {
        const url: string = `${this.API_URL}status/`;
        return this.httpClient.get<any>(url);
    }

    getPdfFile(data): Observable<any> {
        const url: string = `${this.API_URL}getpdf/`;
        return this.httpClient.post(url, data);
    }

    getInvoiceDetail(ettn) {
        const url: string = `${this.API_URL}invoicedetail/${ettn}/`;
        return this.httpClient.get(url);
    }

    addLogoAppeal(data): Observable<any> {
        const url: string = `${this.API_URL}appeallogo/`;
        return this.httpClient.post(url, data);
    }

    addLoginInfo(data): Observable<any> {
        const url: string = `${this.API_URL}addlogininfo/`;
        return this.httpClient.post(url, data);
    }

    updateFaturaSetting(data): Observable<any> {
        const url: string = `${this.API_URL}updateentegrasyon/`;
        return this.httpClient.patch(url, data);
    }
    deleteLoginInfo(): Observable<any> {
        const url: string = `${this.API_URL}deletelogininfo/`;
        return this.httpClient.delete(url);
    }


    getVergiDairesi(): Observable<any> {
        const url: string = `${this.API_URL}vergidaire/`;
        return this.httpClient.get<any>(url);
    }

    getLoginInfo(): Observable<any> {
        const url: string = `${this.API_URL}getlogininfo/`;
        return this.httpClient.get<any>(url);
    }

    getGibUser(id): Observable<any> {
        const url: string = `${this.API_URL}getgibuser/`;
        return this.httpClient.get<any>(url + id + '/');
    }

    importXml(data): Observable<any> {
        const url: string = `${this.API_URL}importxml/`;
        return this.httpClient.post<any>(url, data);
    }

    refreshList() {
        const url: string = `${this.API_URL}refreshfaturalist/`;
        return this.httpClient.get(url);
    }

    addFatura(data) {
        const url: string = `${this.API_URL}addfatura/`;
        return this.httpClient.post(url, data);

    }

    resendFatura(ettn) {
        const url: string = `${this.API_URL}clearalis/`;
        return this.httpClient.post(url, { ettn: ettn });
    }

    getTaslakPdf(ettn) {
        const url: string = `${this.API_URL}taslakpdf/${ettn}/`;
        return this.httpClient.get(url);
    }

    sendFatura(data) {
        const url: string = `${this.API_URL}sendfatura/`;
        return this.httpClient.post(url, data);

    }

    previewPreFatura(identifier) {
        const url: string = `${this.API_URL}prefaturapreview/${identifier}`;
        return this.httpClient.get(url);
    }

    getAlisCount() {
        const url: string = `${this.API_URL}getaliscount/`;
        return this.httpClient.get(url);
    }

    setFaturaToCari(data) {
        const url: string = `${this.API_URL}setfaturatocari/`;
        return this.httpClient.post(url, data);
    }

    acceptInvoice(ettn) {
        const url: string = `${this.API_URL}acceptinvoice/${ettn}/`;
        return this.httpClient.get(url);
    }

    declineInvoice(ettn) {
        const url: string = `${this.API_URL}declineinvoice/${ettn}/`;
        return this.httpClient.get(url);
    }

}
