import { Component, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { NbBaseCalendarComponent } from '@nebular/theme';

@Component({
  selector: 'apx-calendar',
  templateUrl: './apas-calendar.component.html',
  styleUrls: ['./apas-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApasCalendarComponent extends NbBaseCalendarComponent<any, any> {

  @Output() monthChanced: EventEmitter<Date> = new EventEmitter();

  setVisibleDate(event):void{
    super.setVisibleDate(event);
    const dt = new Date(event.getFullYear(), event.getMonth(), 15);
    console.log('Seçilen Ay (month select)',this.visibleDate);
    this.monthChanced.emit(dt);
  }


  navigateNext():void{
    super.navigateNext();

    const dt = new Date(this.visibleDate.getFullYear(), this.visibleDate.getMonth(), 15);

    console.log(dt, 'Seçilen Ay (next)',this.visibleDate);

    this.monthChanced.emit(dt);
  }

  navigatePrev():void{
    super.navigatePrev();
    console.log('Seçilen Ay (prev)',this.visibleDate);

    const dt = new Date(this.visibleDate.getFullYear(), this.visibleDate.getMonth(), 15);
    this.monthChanced.emit(dt);
  }

}
