<nb-card style="min-width: 450px; " class="modalType animated fadeInRight"  [formGroup]="form" >
  <nb-card-header class="d-flex justify-content-between">
    <span>
      {{ 'PERSONEL.PERSONELGRUP' | translate }}
    </span>
    <button nbButton (click)="closed()" size="tiny" class="m-close" style="background: none !important;
      float: right;
      font-size: 18px;">X</button>
  </nb-card-header>
  <nb-card-body style="max-height: 600px;min-height:450px;" formArrayName="gurups" [nbSpinner]="isLoading || patchLoading || postLoading">

    <!-- {{this.form.value | json }} -->
    <nb-accordion class="w-100 m-user">
      <nb-accordion-item *ngFor=" let item of gurups.controls;let index=index" #accordionItem [formGroupName]="index">
        <nb-accordion-item-header *ngIf="!item.get('edit').value">
          <div style="width: 100%; display: flex; justify-content: space-between;">
            <div> {{item.get('title').value}}</div>
            <div style="margin-right:15px">
              <nb-icon (click)="editGroup(accordionItem,index)" icon="edit-outline"></nb-icon>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-header *ngIf="item.get('edit').value">
          <div class="row">
            <div class="col-6"> <input type="text" nbInput [placeholder]=" 'PERSONEL.GRUPADI' | translate" formControlName="title"
                fieldSize="small"></div>
            <div class="col-2">
              <button *ngIf="item.touched || item.dirty" nbButton (click)="patchForm(item,accordionItem,index)"
                size="tiny">{{ 'PERSONEL.GUNCELLE' | translate }}</button>
            </div>
            <div class="col-2">
              <button  nbButton style="background-color:#ff3d71 !important"
                size="tiny" (click)="deleteForm(item,accordionItem,index)">{{ 'PERSONEL.SIL' | translate }}</button>
            </div>
            <div class="col-2 d-flex justify-content-center" style="align-items: center;">
              <nb-icon (click)="unEditGroup(accordionItem,index)" icon="close-circle-outline"></nb-icon>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body *ngIf="!item.get('edit').value" formArrayName="assign_to_ids">

          <nb-user *ngFor="let users of assign_to_ids(index).controls;let userIndex=index"
            [name]="users.get('name').value" size="tiny" [formGroupName]="userIndex">
          </nb-user>

        </nb-accordion-item-body>

        <nb-accordion-item-body *ngIf="item.get('edit').value" formArrayName="assign_to_ids">
          <ng-container *ngFor="let person of assign_to_ids(index).controls;let userIndex=index">
            <div class="row">

              <div class="col-10">
                <nb-user [name]="person.get('name').value" size="tiny"></nb-user>
              </div>

              <div class="col">
                <nb-icon (click)="removePersonel(index,userIndex)" icon="close-circle-outline"></nb-icon>
              </div>

            </div>



          </ng-container>
          <!-- <ng-select #select bindValue="apas_id" bindLabel="name" [items]="editGrupPersonel" appendTo="body"
            [addTag]="false" [searchable]="true" [placeholder]="'PERSONEL.PERSONELSEC' | translate"
            (change)="selectLastPersonel($event,select,index)">
          </ng-select> -->


          <personel-select #select (change)="selectLastPersonel($event, index)" bindValue="apas_id">

          </personel-select>
        </nb-accordion-item-body>

      </nb-accordion-item>

      <nb-accordion-item #accordionItem [formGroup]="gurupsForAdd">
        <nb-accordion-item-header>
          <div class="row">
            <div class="col-7"> <input type="text" nbInput [placeholder]="'PERSONEL.GRUPADI' | translate" formControlName="title"
                fieldSize="small">


            </div>
            <div class="col-3">
              <button *ngIf="gurupsForAdd.touched || gurupsForAdd.dirty" (click)="postForm(accordionItem)" nbButton
                size="tiny">{{ 'PERSONEL.GRUPEKLE' | translate }}</button>
            </div>
            <div class="col-2 d-flex justify-content-center" style="align-items: center;">
              <nb-icon *ngIf="gurupsForAdd.touched || gurupsForAdd.dirty" (click)="unEditGroupForAdd(accordionItem)"
                icon="close-circle-outline"></nb-icon>
            </div>
          </div>

        </nb-accordion-item-header>
        <nb-accordion-item-body formArrayName="assign_to_ids">
          <ng-container *ngFor="let person of assign_to_idsForAdd.controls;let indexForAdd=index"
            [formGroupName]="indexForAdd">
            <!-- -->
            <div class="row" *ngIf="person.get('added').value">
              <div class="col-10">
                <nb-user [name]="person.get('name').value" size="tiny"></nb-user>
              </div>
              <div class="col">
                <nb-icon (click)="removePersonelForAdd(indexForAdd)" icon="close-circle-outline"></nb-icon>
              </div>
            </div>
          </ng-container>
          <!-- <ng-select #select bindValue="apas_id" bindLabel="name" [items]="editGrupPersonelForAdd" appendTo="body"
            [addTag]="false" [searchable]="true" [placeholder]="'PERSONEL.PERSONELSEC' | translate"
            (change)="selectLastPersonelForAdd($event,select)">
          </ng-select> -->

          <personel-select (change)="selectLastPersonelForAdd($event)" bindValue="apas_id">

          </personel-select>
          <div class="uyari" *ngIf="this.assign_to_idsForAdd.length < 1 && gurupsForAdd.valid">
            {{ 'PERSONEL.PERSONELSEC' | translate }}
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>



  </nb-card-body>

</nb-card>