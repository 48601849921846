import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { TagService } from '../../../services/tags.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbAccordionItemComponent, NbPopoverDirective } from '@nebular/theme';
import { removeAllNull } from '../../../pages/global-functions';

import { Store } from '@ngrx/store';
import * as tagActions from '../../../store/tags/tags.actions';
import * as fromTags from '../../../store/tags/tags.connector';


@Component({
  selector: 'tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css'],
  providers: [TagService],
})



export class TagsComponent implements OnInit, OnChanges {

  revealed = false;
  tagEditIndeks: FormControl = new FormControl();
  yeniEtiket = '';
  etiketler = [];

  selectedList: any;
  selectedItem: number;
  isLoading: boolean = false;
  isLoaded: boolean = false;
  @Input() tagTypes: string[];
  @Input() popOver: boolean = false;
  @Input() stil: string[];
  @Output() kapan = new EventEmitter<boolean>();
  @Output() transfer = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<any>();
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChildren(NbAccordionItemComponent) accordions: QueryList<NbAccordionItemComponent>;
  @ViewChild('backContainer') backContainer: ElementRef;

  title: FormControl = new FormControl(null, Validators.required);
  form: FormGroup;
  tagsList$;

  get mainList(): FormArray { return this.form.get('liste') as FormArray; }
  constructor(
    private tagService: TagService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store<{}>,
  ) {
    this.initForm();
    this.store.select(fromTags.selectTagLoading).subscribe(loading => {
      this.isLoading = loading;
    });

    this.store.select(fromTags.selectTagLoaded).subscribe(loaded => {
      this.isLoaded = loaded;
    });

    if (!this.isLoading && !this.isLoaded) {
      this.store.dispatch(tagActions.loadTag());
    }

  }

  listedEtikets;

  ngOnInit() {
    this.tagsList$ = this.tagService.tagList$;
    this.getTagLists();

    this.tagEditIndeks.valueChanges.subscribe(change => {
      this.selectedList = this.etiketler.find(x => x.id == change);

    });
  }

  getRefreshTagList() {
    this.store.dispatch(tagActions.loadTag());
    this.getTagLists();

  }
  getTagLists() {
    // Ana Tag Listelerini Getirir.
    this.tagsList$.subscribe(tags => {
      this.mainList.clear();
      this.etiketler = tags;
      this.etiketler.forEach(element => {
        this.addListeItem(element);
        this.reList();
      });


      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  removeTagItem(event) {
    this.secilenEtiketler = this.secilenEtiketler.filter(x => x.id !== event.id);
    this.removeItem.emit(event);
  }

  reList() {
    // Eğer Component Çağırılırken Listenin Kısıtlanması İstenmişse, Listeyi filtreler.
    if (this.tagTypes && this.tagTypes.length > 0) {

      if (this.tagTypes.indexOf('custom') === -1) {
        this.tagTypes.push('custom');
      }

      this.listedEtikets = [];

      this.tagTypes.forEach(element => {
        const bulunan = this.etiketler.filter(x => x.type == element);

        this.listedEtikets = [...this.listedEtikets, ...bulunan];
      });
    }

  }

  subList(id): FormArray {
    return this.mainList.at(id).get('tag_items') as FormArray;
  }

  initForm() {
    this.form = this.fb.group({
      liste: this.fb.array([]),
    });
  }

  addListeItem(data?: any) {
    const tagForm = this.fb.group({
      id: [],
      title: [],
      tag_items: this.fb.array([]),
    });

    if (data) {
      const tagItems = data.tag_items ? data.tag_items : [];

      tagForm.get('id').setValue(data.id);
      tagForm.get('title').setValue(data.title);
      if (tagItems) {
        tagItems.forEach(element => {
          this.addTagItems(tagForm, element);
        });
      }
      this.addTagItems(tagForm);

    }
    this.mainList.push(tagForm);
  }

  addTagItems(liste: FormGroup, data?) {
    const tagItem = this.fb.group({
      id: [],
      title: [],
      tag_group: [],
    });

    if (data) {
      tagItem.patchValue(data);
    }
    tagItem.get('tag_group').patchValue(liste.get('id').value);
    const tag_items = liste.get('tag_items') as FormArray;
    tag_items.push(tagItem);
  }

  ngOnChanges(changes: SimpleChanges): void {

    setTimeout(() => {
      if (changes) {
        if (changes.tagTypes) {
          this.reList();
        }
      }
    }, 500);
  }

  drag(event, item) {
    event.dataTransfer.setData('text', JSON.stringify(item));
  }

  addNewGroup() {
    const formData = this.title.value;

    this.tagService.addTagGroup({ title: formData, type: 'custom' }).subscribe(resp => {
      this.popover.hide();
      this.getRefreshTagList();
      const bcontainer = document.getElementById('bcontainer');
      setTimeout(() => {
        this.accordions.last.collapsed = false;
        this.cd.detectChanges();
      }, 300);

      setTimeout(() => {
        bcontainer.scrollTop = bcontainer.scrollHeight;
      }, 650);
    });
  }
  secilenEtiketler = [];
  baski(item) {
    this.secilenEtiketler.push(item);
    this.transfer.emit(item);
  }

  onRevealed() {
    this.revealed = !this.revealed;
  }

  etiketSil(item: any, i: number, k: number) {
    this.isLoading = true;
    const itemValue = item.value;
    Swal.fire({ ...responseKodlari.shureToDelete, title: "\'" + itemValue.title + "\' Etiketi Silinecek Onaylıyor Musunuz?" } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        const etiketGroup = this.etiketler.find(x => x.id == itemValue.tag_group);

        this.isLoading = false;
        this.tagService.deleteTagItem(itemValue.id).subscribe(resp => {
          etiketGroup.tag_items = etiketGroup.tag_items.filter(x => x.id != itemValue.id);
          const mainG = this.mainList.at(i).get('tag_items') as FormArray;

          mainG.removeAt(k);
        }, (err) => this.isLoading = false);
      }
    });
  }
  etiketEkle(i, k) {
    // Ana Tag Grubunun Formu
    const mainG = this.mainList.at(i) as FormGroup;

    // Tag Item Form Grubu
    const mainT = mainG.get('tag_items') as FormArray;
    // Tag Nesnesinin Kendi Form Controlleri
    const formG = mainT.at(k);
    const formValue = removeAllNull(formG.value);
    const etiketGroup = this.etiketler.find(x => x.id == formValue.tag_group);
    this.isLoading = true;

    this.tagService.addTagItem(formValue).subscribe(resp => {
      this.getRefreshTagList();
      formG.get('id').setValue(resp.id);
      this.addTagItems(mainG);
      this.isLoading = false;
      etiketGroup.tag_items.push(resp);
    }, (err) => this.isLoading = false);
  }
  etiketAdiDegis(item, i, k) {
    if (item.get('id').value) {
      const value = item.value;
      this.isLoading = true;
      const etiketGroup = this.etiketler.find(x => x.id == value.tag_group);
      const curEtiket = etiketGroup.tag_items.find(y => y.id == value.id);
      this.tagService.updateTagItem(value.id, { title: value.title }).subscribe(resp => {
        this.getRefreshTagList();

        item.get('title').setValue(resp.title);
        curEtiket.title = resp.title;

        this.isLoading = false;
      }, (err) => this.isLoading = false);
    } else {
      this.etiketEkle(i, k);
    }
  }

  grupAdiDegistir(etiket: any) {
    this.isLoading = true;
    const value = etiket.value;
    const etiketGroup = this.etiketler.find(x => x.id == value.id);
    this.tagService.updateTagGroup(value.id, { title: value.title }).subscribe(resp => {
      etiketGroup.title = resp.title;
      etiket.get('title').setValue(resp.title);
      this.getRefreshTagList();
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }
  grupSil(i: number) {
    const mainG = this.mainList.at(i);
    const formValue = mainG.value
    Swal.fire({ ...responseKodlari.shureToDelete, html: "\'" + formValue.title + "\' Etiket Grubu İçindeki Etiketler İle Birlikte Silinecek. <b>Onaylıyor Musunuz?</b>" } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        // this.isLoading = false;
        // this.etiketler = this.etiketler.filter(x => x.id != formValue.id)
        // this.reList()
        // this.getRefreshTagList();

        this.tagService.deleteTagGroup(formValue.id).subscribe(resp => {
          // this.mainList.removeAt(i);
        }, (err) => this.isLoading = false);
      }
    });
  }
  kapat() {
    this.kapan.emit(false);
  }
}
