<nb-card status="primary">
  <nb-card-header>
    {{'YAPILANISLERMODAL.SONYAPILANISLER'|translate}}
    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
    color: #fff !important;
    float: right;
    font-size: 18px;">X</button>
  </nb-card-header>
  <nb-card-body class="p-0" style="min-width:600px; max-height:540px; overflow: hidden; overflow-y: auto;">
    <yapilan-isler-dashboard [santiye_id]="santiye_id"></yapilan-isler-dashboard>
  </nb-card-body>
 
</nb-card>