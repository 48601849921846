import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import { SantiyeModel } from '../../../models/santiye_model';
import { WeatherListItem } from '../../../models/weather.model';
import { Constants, tarih_format, telefonKodu } from '../../../pages/constants';
import { daysOfTimestamps } from '../../../pages/global-functions';
import { SantiyeService } from '../../../services/santiye.service';
import { SantiyeAlbumModalComponent } from '../santiye-photo/santiye-album-modal/santiye-album-modal.component';

@Component({
  selector: 'santiye-detay-pencere',
  templateUrl: './santiye-detay-pencere.component.html',
  styleUrls: ['./santiye-detay-pencere.component.scss'],
  providers: [SantiyeService],
})
export class SantiyeDetayPencereComponent implements OnInit {
  @Input() santiye_row: any;
  telefonKodu = telefonKodu[0].maskList;
  santiye: SantiyeModel;
  tarih_format = tarih_format;
  isLoading = false;
  c = Constants.prototype;
  mapKilitle = true;
  imgPath = 'assets/weather-icon/';
  constructor(
    private santiyeService: SantiyeService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getSantiye();
    this.santiye_row['latitude'] = Number(this.santiye_row['latitude']);
    this.santiye_row['longitude'] = Number(this.santiye_row['longitude']);
  }


  getSantiye() {
    this.isLoading = true;
    this.santiyeService.getSantiyeForID(this.santiye_row.id).subscribe(santiye_data => {
      this.santiye = santiye_data;
      /*  Kalan gün ve tarih hesabı */
      const endDay = moment(this.santiye.start_date, 'X');
      endDay.add({ day: (this.santiye.end_time - 1) });
      const fromNow: number = (daysOfTimestamps(this.santiye.start_date, (new Date()).getTime() / 1000, false));
      this.santiye.kalanSure = this.santiye.end_time - fromNow;
      this.santiye.end_date = Number(endDay.format('x'));

      this.isLoading = false;
    }, (err) => this.isLoading = true);
  }


  SortingAndPushWeather(WeatherArray: WeatherListItem[]) {
    // Min ve Max sıcaklığı bulmak için  gereken sorting işlemi
    WeatherArray.sort((ilkVeri, ikinciVeri) => {
      return ikinciVeri.main.temp - ilkVeri.main.temp;
    });
    // Max , min icon ve dereceleri belirşleme için
    const maxIcon = this.imgPath + WeatherArray[0].weather[0].icon + '.png';
    const minIcon = this.imgPath + WeatherArray[WeatherArray.length - 1].weather[0].icon + '.png';
    const max = WeatherArray[0].main.temp;
    const min = WeatherArray[WeatherArray.length - 1].main.temp;

    return {
      min: min,
      max: max,
      minIcon: minIcon,
      maxIcon: maxIcon,
    };
  }

  goToYapianIsler(santiye_id) {
    this.router.navigateByUrl('pages/santiye/yapilan-isler/' + santiye_id);
  }

  editSantiye() {
    this.router.navigate([`pages/santiye/santiye-ekle/${this.santiye.id}`]);
  }

  editSozlesme() {
    this.router.navigate([`pages/santiye/sozlesme-ekle/${this.santiye.sozlesme_id.id}`]);
  }

}
