import { Component, OnInit } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { BirimFiyatModel } from '../../../../models/birimfiyat.model';
import { BirimFiyatService } from '../../../../services/birimfiyat.service';

@Component({
  selector: 'birimfiyat-list',
  templateUrl: './birimfiyat-list.component.html',
  styleUrls: ['./birimfiyat-list.component.css'],
  providers: [BirimFiyatService],
})
export class BirimfiyatListComponent implements OnInit {
  birimFiyat$: Observable<BirimFiyatModel[]>;
  birimFiyatTmp: BirimFiyatModel[] = [];
  birimFiyatIsEnd: boolean = false;

  birimFiyatLoading = false;
  birimFiyatInput$ = new Subject<any>();

  birimFiyat2$: Observable<BirimFiyatModel[]>;

  birimFiyat2Tmp: BirimFiyatModel[] = [];
  birimFiyat2IsEnd: boolean = false;

  birimFiyatLoading2 = false;
  birimFiyatTitleInput$ = new Subject<any>();
  birimFiyatIsEnd2: boolean = false;

  metrajGroup_Headers = [];
  isLoading: boolean = true;
  isMetrajLoading: boolean = true;
  currentMetrajId: number = 0;
  tag: string = 'hakedis-metraj --->';


  isLoadings = {};
  terim: any;
  terim2: any;

  selectedBirim: BirimFiyatModel;

  constructor(private _birimFiyatService: BirimFiyatService) { }

  ngOnInit() {

    this.searchBirimFiyat();

    this.birimFiyat$.subscribe(data => { });

    this.birimFiyatTitleInput$.subscribe(data => {
      this.terim2 = data;
      if (data === null || data === undefined) {
        this.resetInfinitNgSelectTitle();
      }
    });

    this.searchBirimFiyatTitle();
    this.birimFiyat2$.subscribe(data => { });
    this.birimFiyatInput$.subscribe(data => {
      this.terim = data;
      if (data === null || data === undefined) {
        this.resetInfinitNgSelect();
      }
    });

  }



  // Poz No Select
  private searchBirimFiyat() {
    this.birimFiyat$ = concat(
      (of([])), // default items
      this.birimFiyatInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.birimFiyatLoading = true),
        switchMap(term => {
          let _term = term; // kullanıcının yazdığı
          let page; // undefinid page
          if (term) {
            if (term.page) { // eğer terim min icinde sayfa varsa,
              page = term.page;
              _term = term.term;
            } else {
              this.resetInfinitNgSelect();

            }
          }
          if (term === null || term === undefined) {
            this.resetInfinitNgSelect();
          }
          return this._birimFiyatService.searchPozNo(_term, page).pipe(
            catchError(() => of([])), // empty list on error
            tap((data) => {
              if (data.length <= 0)
                this.birimFiyatIsEnd = true;
              this.birimFiyatTmp = [...this.birimFiyatTmp, ...data];
              this.birimFiyatLoading = false;
            }),
          );
        }),
      ),
    );
  }
  private searchBirimFiyatTitle() {

    this.birimFiyat2$ = concat(
      of([]), // default items
      this.birimFiyatTitleInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.birimFiyatLoading2 = true),
        switchMap(term => {
          let _term = term; // kullanıcının yazdığı
          let page; // undefinid page
          if (term) {
            if (term.page) { // eğer terim min icinde sayfa varsa,
              page = term.page;
              _term = term.term;
            } else {
              this.resetInfinitNgSelectTitle();
            }
          }
          if (term === null || term === undefined) {
            this.resetInfinitNgSelectTitle();
          }
          return this._birimFiyatService.searchWithTitle(_term, page).pipe(
            catchError(() => of([])), // empty list on error
            tap((data) => {
              if (data.length <= 0)
                this.birimFiyat2IsEnd = true;
              this.birimFiyat2Tmp = [...this.birimFiyat2Tmp, ...data];
              this.birimFiyatLoading2 = false;
            }));
        }),
      ),
    );
  }
  resetInfinitNgSelect() {
    this.birimFiyatTmp = [];
    this.birimFiyatIsEnd = false;

  }

  resetInfinitNgSelectTitle() {
    this.birimFiyat2Tmp = [];
    this.birimFiyat2IsEnd = false;
  }

  selected(event) {
    this.selectedBirim = event;
  }

  onScrollToEnd() {
    if (!this.terim) { return; }

    if (this.terim.page) {
      if (!this.birimFiyatIsEnd) {
        this.birimFiyatInput$.next({ page: this.terim.page + 1, term: this.terim.term });
      }
    } else {
      this.birimFiyatInput$.next({ page: 1, term: this.terim });
    }
  }
  onScrollToEndTitle() {
    console.log(this.tag, 'scrollEnd', ' terim:', this.terim2);
    if (!this.terim2) { return; }

    if (this.terim2.page) {
      if (!this.birimFiyat2IsEnd) {
        this.birimFiyatTitleInput$.next({ page: this.terim2.page + 1, term: this.terim2.term });
      }
    } else {
      this.birimFiyatTitleInput$.next({ page: 1, term: this.terim2 });
    }
  }

}
