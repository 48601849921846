import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ProductModel } from '../../../models/product.model';
import { formatLongText, removeAllNull } from '../../../pages/global-functions';
import { TaseronPersonelModel } from '../../../models/taseron_personel.model';
import { TaseronService } from '../../../services/taseron.service';
import { DepoService } from '../../../services/depo.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { Store, select } from '@ngrx/store';
import * as fromUser from '../../../store/user/user.connector';
import { KullaniciModel } from '../../../models/kullanici_model';

@Component({
  selector: 'urun-cikis',
  templateUrl: './urun-cikis.component.html',
  styleUrls: ['./urun-cikis.component.css'],
  providers: [TaseronService, DepoService],
  encapsulation: ViewEncapsulation.None,
})
export class UrunCikisComponent implements OnInit {
  isSubmitting: boolean = false;
  urunCikisForm: FormGroup;
  urun: any;
  allPersonel: TaseronPersonelModel[];
  allUrunMarka: ProductModel[];
  formatLongText = formatLongText;
  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<UrunCikisComponent>,
    private _taseronService: TaseronService,
    private _depoService: DepoService,
    private store: Store<{}>,
  ) {
    this.initForm();

    this._taseronService.getAllTaseron().subscribe((data) => {
      this.allPersonel = data;
    });
  }
  name: string;
  ngOnInit() {
    this._depoService
      .getUrunHareket(this.urun.warehouse_id, this.urun.product_id_id)
      .subscribe((data) => {
        this.allUrunMarka = data;
        this.selectMarka(data[0]);

      });
    this.store
      .pipe(select(fromUser.selectUser))
      .subscribe((user: KullaniciModel) => (this.name = user.name));

    if (this.urun) {
      this.urunCikisForm.get('warehouse_id').setValue(this.urun.warehouse_id);
      this.urunCikisForm.get('product_id').setValue(this.urun.product_id);
      this.urunCikisForm.get('note').setValue(this.urun.note);
    } else {
      console.log('urun error');
      this.ref.close();
    }
  }
  initForm() {
    this.urunCikisForm = this.fb.group({
      delivery_by_apas_id: [],
      delivery_by_name: [],
      delivery_by_org_apas_id: [],
      amount: [null, [Validators.required]],
      link_to_own_model: [null, Validators.required],
      in_or_out: [false],
      warehouse_id: [],
      note: [],
      product_id: [],
      brand_id: [null, Validators.required],
      product_type: [1],
      warehouse_in_out_type: [3],
    });
  }
  closed() {
    this.ref.close();
  }
  selectMarka(event) {
    this.urunCikisForm.get('link_to_own_model').setValue(event.id);
    this.urunCikisForm.get('brand_id').setValue(event.brand_id);
  }
  selectedDeliveryPersonel(event) {
    console.log('Çıkış Yapan', event); // company_apas_id
    if (event) {
      this.urunCikisForm.get('delivery_by_name').setValue(event.name);
      this.urunCikisForm
        .get('delivery_by_org_apas_id')
        .setValue(event.company_apas_id);
      console.log(this.urunCikisForm.value);
    } else {
      this.urunCikisForm.get('delivery_by_name').reset();
      this.urunCikisForm.get('delivery_by_org_apas_id').reset();
    }
  }
  urunCikisYap() {
    if (!this.urunCikisForm.valid) {
      this.urunCikisForm.markAllAsTouched();
      console.log('FORM NOT VALID : ', this.urunCikisForm);
      return;
    }

    const formValue = removeAllNull(this.urunCikisForm.value);
    this.isSubmitting = true;
    this._depoService.addInOrOut(formValue).subscribe(
      (response) => {
        this.isSubmitting = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(
          () => {
            this.ref.close(response);
          },
        );
      },
      (err) => (this.isSubmitting = false),
    );
  }
}
