import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


export enum HakedisTabType {
    'Empty' = 'empty',
    'HakedisOnKapak' = 'hakedisonkapak',
    'HakedisRapor' = 'hakedisrapor',
    'HakedisOzet' = 'hakedisozet',
    'HakedisIcmali' = 'hakedisicmali',
    'HakedisFiyatFarki' = 'hakedisfiyatfarki',
    'HakedisYapilanIslerPusantajSiz' = 'hakedisyapilanislerpusantajsiz',
    'HakedisYapilanIslerPusantajli' = 'hakedisyapilanislerpusantajli',
    'HakedisMetrajEkle' = 'hakedismetrajekle',
    'HakedisMukayese' = 'hakedismukayese',
    'HakedisDosyaEkle' = 'hakedisdusyaekle',
}

export enum HakedisDosyaListType {
    'Liste' = 'liste',
    'DosyaEkle' = 'dosyaekle',
}

@Injectable()
export class HakedisService {

    private readonly API_URL = `${environment.backendurl}api/hakedis/`;
    private readonly API_URL2 = `${environment.backendurl}api/fiyatfark/`;


    constructor(private httpClient: HttpClient) { }
    getHakedisList(_type: string): Observable<any[]> {
        const url = this.API_URL + 'list/' + _type + '/';

        return this.httpClient.get<any[]>(url);
    }

    listHakedis(data): Observable<any[]> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.post<any[]>(url, data);
    }

    getHakedisItems(hakedisId: number): Observable<any[]> {
        const url = this.API_URL + 'tek/' + hakedisId + '/';

        return this.httpClient.get<any[]>(url);
    }

    /**
     * Bir hakedişin tüm dataylarını getirir.
     * @param hakedisId Detayı istenen hakedisin id'si
     */
    getHakedisDetay(hakedisId: number): Observable<any> {
        const url = this.API_URL + 'detay/' + hakedisId + '/';

        return this.httpClient.get<any>(url);
    }

    deleteHakedisItem(hakedisDetayId) {
        const url = `${this.API_URL}deleteitem/${hakedisDetayId}/`;
        return this.httpClient.delete(url);
    }

    getCreateStatus(_type: string, hakedisId: number, taseronId?: number): Observable<any[]> {
        /*
         Type isveren veya taseron olarak alınır.
         id şantiye id veya sözleşme id alır. şantiye id varsa, taşeron id de alınır.
         */
        let url = this.API_URL + 'create/' + _type + '/' + hakedisId + '/';

        if (taseronId) {
            url = this.API_URL + 'create/' + _type + '/' + hakedisId + '/' + taseronId + '/';
        }

        return this.httpClient.get<any[]>(url);
    }

    sendToSign(hakedisId: number) {
        const url = this.API_URL + 'sendtosign/' + hakedisId + '/';
        return this.httpClient.post<any[]>(url, {});

    }

    createNewHakedis(_type: string, instanceId: number, taseronId?: number) {
        /* Type isveren veya taseron olarak alınır.
            instanceId şantiye id veya sözleşme id alır.
            şantiye id alınırsa, taşeron id de alınır.*/
        let url = `${this.API_URL}create/${_type}/${instanceId}/`;
        if (taseronId) {
            url = `${this.API_URL}create/${_type}/${instanceId}/${taseronId}/`;
        }
        return this.httpClient.post<any>(url, {});
    }

    updateHakedis(hakedisId: number, data: any) {

        const url = this.API_URL + 'update/' + hakedisId + '/';

        return this.httpClient.patch<any>(url, data);

    }

    getArtisAzalis(hakedisId: number) {
        const url = this.API_URL + 'artisazalis/' + hakedisId + '/';

        return this.httpClient.get<any[]>(url);
    }

    updateArtisAzalis(hakedisId: number, data: any[]) {
        const url = this.API_URL + 'artisazalis/' + hakedisId + '/';

        return this.httpClient.patch<any[]>(url, data);
    }

    getHakedisOzet(hakedisId: number): Observable<any[]> {

        const url = this.API_URL + 'hakedisozet/' + hakedisId + '/';

        return this.httpClient.get<any[]>(url);

    }

    /*
    * @param _type : Hakediş Tipi Anahtar Teslimse (3), Hakediş Tipi Birim Fiyatlıysa (2)
    */
    listYapilanIsler(hakedisId: number, is_grubu: number, _type: number): Observable<any> {

        const url = this.API_URL + 'yapilanisler/' + hakedisId + '/' + is_grubu + '/' + _type + '/';
        return this.httpClient.get<any>(url);
    }

    updateYapilanIsler(hakedisId: number, data: any, identifier: string): Observable<any> {
        const url = this.API_URL + 'yapilanisler/' + hakedisId + '/' + identifier + '/';
        return this.httpClient.patch<any>(url, data);
    }

    listIcmal(hakedisId: number): Observable<any[]> {
        const url = this.API_URL + 'icmal/' + hakedisId + '/';
        return this.httpClient.get<any[]>(url);
    }

    getBazEndeks(hakedisId: number): Observable<any> {
        const url = this.API_URL2 + 'bazendeks/' + hakedisId + '/';

        return this.httpClient.get<any>(url);
    }

    updateBazEndeks(hakedisId: number, data: any): Observable<any> {
        const url = this.API_URL2 + 'bazendeks/' + hakedisId + '/';
        return this.httpClient.patch<any[]>(url, data);
    }

    getEndeksler(hakedisId: number): Observable<any> {
        const url = this.API_URL2 + 'endeksler/' + hakedisId + '/';
        return this.httpClient.get<any>(url);
    }

    createNewEndeks(hakedisId: number): Observable<any> {
        const url = this.API_URL2 + 'endeksler/' + hakedisId + '/';
        return this.httpClient.post<any>(url, '');

    }

    updateEndeksler(hakedisId: number, identifier: string, data: any): Observable<any[]> {
        const url = this.API_URL2 + 'endeksler/' + hakedisId + '/' + identifier + '/';
        return this.httpClient.patch<any[]>(url, data);
    }

    getEndeksTablo(hakedisId: number): Observable<any[]> {
        const url = this.API_URL2 + 'tablo/' + hakedisId + '/';
        return this.httpClient.get<any>(url);
    }

    getFiyatfark(hakedisId: number): Observable<any> {
        const url = this.API_URL2 + 'fiyatfark/' + hakedisId + '/';
        return this.httpClient.get<any>(url);
    }

    updateFiyatFark(hakedisId: number, data: any) {
        const url = this.API_URL2 + 'fiyatfark/' + hakedisId + '/';
        return this.httpClient.patch<any>(url, data);
    }

    createAylikPursantaj(hakedisId: number) {
        const url = this.API_URL2 + 'aylik/' + hakedisId + '/';
        return this.httpClient.post<any>(url, '');
    }

    updateAylikPursantaj(hakedisId: number, identifier: string, data: any) {
        const url = this.API_URL2 + 'aylik/update/' + hakedisId + '/' + identifier + '/';
        return this.httpClient.patch<any>(url, data);
    }

    deleteAylikPursantaj(hakedisId: number, identifier: string) {
        const url = this.API_URL2 + 'aylik/delete/' + hakedisId + '/' + identifier + '/';
        return this.httpClient.delete<any>(url);
    }

    getHakedisDosyalari(hakedisId: number): Observable<any[]> {

        const url = this.API_URL + 'dosyalar/' + hakedisId + '/';

        return this.httpClient.get<any[]>(url);

    }
    postRapor(data): Observable<any> {

        const url: string = this.API_URL + 'dosya/add/';
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,
        });
        return this.httpClient.request(req);
        // return this.httpClient.post(this.API_URL + 'dosya/add/', data);
    }
    patchRapor(id: number, data): Observable<any> {

        const url: string = `${this.API_URL}dosya/update/${id}/`;
        const req = new HttpRequest('PATCH', url, data, {
            reportProgress: true,
        });
        return this.httpClient.request(req);
        // return this.httpClient.post(this.API_URL + 'dosya/add/', data);
    }
    getRapor(raporId: number): Observable<any> {
        const url: string = this.API_URL + 'tek/' + raporId + '/';
        return this.httpClient.get<any>(url);
    }
    getRaporPercend(hakedisId: number, raporId: number): Observable<HttpEvent<any>> {
        // const url: string = this.API_URL + 'dosyalar/tek/' + raporId + '/';
        const url: string = `${this.API_URL}dosya/tek/${hakedisId}/${raporId}/`;
        const req = new HttpRequest('GET', url, {
            reportProgress: true,
        });
        return this.httpClient.request(req);
    }
    getRaporlar(hakedisId: number, page: number) {
        const url: string = this.API_URL + 'dosyalar/' + hakedisId + '/' + page + '/';
        return this.httpClient.get<any>(url);
    }


    getMukayese(hakedisId: number): Observable<any> {
        const url: string = this.API_URL + 'mukayese/' + hakedisId + '/';

        return this.httpClient.get<any>(url);
    }

    updateMukayese(hakedisId: number, data: any, itemId?: number): Observable<any> {
        let url: string = this.API_URL + 'mukayese/' + hakedisId + '/';
        if (itemId) {
            url = this.API_URL + 'mukayese/' + hakedisId + '/' + itemId + '/';
        }

        return this.httpClient.patch<any>(url, data);
    }


    getTaseronHakedisList(taseronId: number): Observable<any[]> {
        const url: string = `${this.API_URL}taseron/${taseronId}/`;

        return this.httpClient.get<any[]>(url);
    }

    getSantiyeHakedisList(santiyeId: number): Observable<any[]> {
        const url: string = `${this.API_URL}santiye/${santiyeId}/`;

        return this.httpClient.get<any[]>(url);
    }

    getHakedisYapilabilirSantiyeler(): Observable<any[]> {
        const url: string = `${this.API_URL}santiyelist/`;
        return this.httpClient.get<any[]>(url);
    }


    getHakedisYapilabilirSozlesmeler(): Observable<any[]> {
        const url: string = `${this.API_URL}sozlesmelist/`;
        return this.httpClient.get<any[]>(url);
    }

    getCustomHakedisOzet(hakedisId: number): Observable<any> {
        const url: string = `${this.API_URL}custom/${hakedisId}/`;
        return this.httpClient.get<any>(url);
    }

    updateCustomHakedisOzet(hakedisId: number, data?: any): Observable<any> {
        const url: string = `${this.API_URL}custom/${hakedisId}/`;
        const returnData = data ? data : {};
        return this.httpClient.patch<any>(url, returnData);
    }

    updateCustomHakedisOzetUploadFile(data?: any): Observable<any> {
        const url: string = `${this.API_URL}dosya/add/`;
        const req = new HttpRequest('POST', url, data, { reportProgress: true });
        return this.httpClient.request(req);
    }

    updateCustomHakedisOzetGetFiles(id?: any, page?: number): Observable<any[]> {
        const url: string = `${this.API_URL}dosyalar/${id}/${page ? page : 0}/`;
        return this.httpClient.get<any[]>(url);
    }

    getCustomHakedisOzetTekFile(id?: number, fileId?: number): Observable<any> {
        const url: string = `${this.API_URL}dosya/tek/${id}/${fileId}/`;
        const req = new HttpRequest('GET', url, {
            reportProgress: true,
        });
        return this.httpClient.request(req);
    }

    deleteFile(id: number, fileId) {
        const url: string = `${this.API_URL}dosya/update/${fileId}/`;
        return this.httpClient.patch<any>(url, { hakedis_id: id, is_deleted: true });
    }

    getExcel(hakedis_id: number): Observable<any> {
        const url: string = `${this.API_URL}excel/${hakedis_id}/`;
        return this.httpClient.get<any>(url);
    }

}


