import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MutabakatService } from '../../../services/mutabakat.service';
import {
  Constants,
  tarih_format,
  responseKodlari,
} from '../../../pages/constants';
import { NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import {
  addOrUpdate,
  convertDateWithTZ,
  dateRangeFormSetter,
  downLoadFile,
  downLoadPdf,
  removeAllNull,
} from '../../../pages/global-functions';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SantiyeModel } from '../../../models/santiye_model';
import { Router } from '@angular/router';
import { MutabakatSatirEkleComponent } from '../mutabakat-satir-ekle/mutabakat-satir-ekle.component';
import { colorSet } from '../../apas-components/apas-filter/apas-filter.component';
import { FilePreviewerComponent } from '../../apas-components/file-preview-component/file-previewer.component';

@Component({
  selector: 'mutabakat-list',
  templateUrl: './mutabakat-list.component.html',
  styleUrls: ['./mutabakat-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MutabakatService],
})
export class MutabakatListComponent implements OnInit {
  @Input() mutabakat_taraf_id = null;
  @Input() headerShow = true;
  @Input() filterShow = true;
  colorSet = colorSet;
  tarih_format = tarih_format;
  convert = Constants.prototype;
  rows = [];
  search: boolean = true;
  isUpdate = false;
  selectedRow;
  isLoading: boolean = false;
  get santiyeActive(): FormControl {
    return this.form.get('is_santiye_active') as FormControl;
  }
  constructor(
    private _mutabakatService: MutabakatService,
    private fb: FormBuilder,
    private router: Router,
    private nbDialogService: NbDialogService,
  ) {}

  ngOnInit() {
    this.initForm();

    this.refreshMutabakats();
    this.loadOrganizations();
    this.getSantiyeList();
  }

  mIcon: string = 'search';

  editMutabakat(row) {
    this.router.navigate(['pages/mutabakat/mutabakat-ekle'], {
      state: { data: row, isUpdate: true },
    });
  }

  form: FormGroup;
  get searchControl(): FormControl {
    return this.form.get('search') as FormControl;
  }
  initForm() {
    this.form = this.fb.group({
      santiye_id: [],
      mutabakat_taraf_id: [],
      end_date: [],
      start_date: [],
      company_id: [],
      tags: [],
      search: [],
      page: [0],
      count: [20],
      order_by: [],
      is_santiye_active: [true],
      is_active: [true],
    });

    // this.form.get('filter').get('machine_id')['translate_key'] = 'Murat'

    this.form.controls.search.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.controls.mutabakat_taraf_id.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.controls.is_active.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });

    this.form.controls.order_by.valueChanges.subscribe(() => {
      this.getFilteredData();
    });

    this.form.get('is_santiye_active').valueChanges.subscribe((val) => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
  }

  getFilteredData() {
    this.isLoading = true;
    this.isEnd = false;
    this.rows = [];
    this.form.get('page').setValue(0);
    setTimeout(() => {
      this.refreshMutabakats();
    }, 300);
  }

  refreshMutabakats() {
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    if (this.mutabakat_taraf_id) {
      formVal.mutabakat_taraf_id = this.mutabakat_taraf_id;
    }
    this._mutabakatService.getNewMutabakatListesi(formVal).subscribe(
      (data) => {
        data.forEach((elm) => {
          elm = this.clearData(elm);
          elm = this.totalCount(elm);
          this.rows = addOrUpdate(this.rows, elm);
        });
        this.isLoading = false;
      },
      (err) => {
        this.isEnd = true;
        this.isLoading = false;
      },
    );
  }
  totalCount(element) {
    element['total_prices'] = [];
    element.sub_mutabakat.forEach((elm) => {
      elm['currency'] = this.convert.getParaBirim(elm.price_currency);
      const arrayControl = element.total_prices.find(
        (x) => x.currency === elm?.currency,
      );
      if (arrayControl) {
        arrayControl.total += Number(elm.total_price);
      } else {
        const total = {
          currency: elm?.currency,
          total: Number(elm?.total_price),
        };
        element.total_prices.push(total);
      }
    });
    return element;
  }

  clearData(item) {
    item.created_time_con = convertDateWithTZ(item, 'created_time');
    item.start_date = convertDateWithTZ(item, 'start_date');
    item.finish_date = convertDateWithTZ(item, 'finish_date');
    item.sub_mutabakat.forEach((element) => {
      element.start_date = convertDateWithTZ(element, 'start_date');
      element.finish_date = convertDateWithTZ(element, 'finish_date');
    });
    return item;
  }

  postedOrUpdatedMutabakat(event) {
    event = this.clearData(event);
    if (
      !this.rows.some((s, index, array) => {
        if (s.id === event.id) {
          array[index] = event;
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.rows.unshift(event);
    }

    setTimeout(() => (this.rows = [...this.rows]), 500);
  }

  aproveMutabakat(row: any) {
    const metin = `${row.mutabakat_taraf_org_name} ile ${row.mutabakat_title}
    başlıklı Mutabakatı onaylamak istiyor musunuz?
    Bu işlem sonrasında ${row.mutabakat_taraf_org_name} firması bu mutabakatı değiştiremez.`;
    Swal.fire({
      ...responseKodlari.userCount,
      text: metin,
    } as SweetAlertOptions).then((value) => {
      if (value.value) {
        this._mutabakatService.aproveMutabakat(row.id).subscribe((response) => {
          row.is_approved = response['is_approved'];
        });
      }
    });
  }

  setDateFilter(event) {
    const dates = dateRangeFormSetter(event);
    this.form.get('filter').get('start_date').setValue(dates.startDay);
    this.form.get('filter').get('end_date').setValue(dates.endDay);
  }

  selectTag(event) {
    this.form.get('tags').setValue(event);
  }

  organizationLoading = false;
  organizationList = [];
  private loadOrganizations() {
    this.organizationLoading = true;
    this._mutabakatService.getFirmaList().subscribe(
      (resp) => {
        this.organizationList = resp;
        this.organizationList = [...this.organizationList];
        this.organizationLoading = false;
      },
      (err) => {
        this.organizationLoading = false;
      },
    );
  }

  isEnd: boolean = false;
  goToNextPage() {
    if (!this.isLoading && !this.isEnd) {
      this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
      this.refreshMutabakats();
    }
  }

  deleteMutabakat(item) {
    Swal.fire({
      ...responseKodlari.shureToDelete,
      ...{
        // tslint:disable-next-line: max-line-length
        text: `${item.mutabakat_title} başlıklı anlaşmayı silmek istediğinizden Emin Misiniz? Bu işlem Geri Alınamaz.`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;

        this._mutabakatService.deleteMutabakat(item.id).subscribe(
          (resp) => {
            this.rows = this.rows.filter((x) => x.id !== item.id);
            Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
            this.isLoading = false;
          },
          (err) => {
            Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
            this.isLoading = false;
          },
        );
      }
    });
  }

  downLoadFile(item) {
    if (item.has_dosya) {
      this._mutabakatService.getMutabakatFile(item.id).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              item['percentDone'] = 0;

              item['isDownloading'] = true;
              break;
            case HttpEventType.DownloadProgress:
              item['percentDone'] = Math.round(
                (100 * event.loaded) / event.total,
              );

              break;
            case HttpEventType.Response:
              console.log('event.body', event.body);
              const fileContext: {} = {
                _file: event.body.file,
                extension: event.body.extension,
              };
              this.nbDialogService.open(FilePreviewerComponent,
                {
                  context: { item: fileContext },
                  closeOnBackdropClick: false,
                });
              break;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          }
        },
      );
    }
  }
  santiyes: SantiyeModel[] = null;
  santiyeLoading: boolean = true;

  getSantiyeList() {
    this._mutabakatService.santiyeList().subscribe((data) => {
      this.santiyes = data;
      this.santiyeLoading = false;
    });
  }

  orderStatuses = {
    start_date: true,
    mutabakat_title: null,
    santiye_name: null,
    karsi_firma: null,
  };

  orderBy(orderKey, status) {
    Object.keys(this.orderStatuses).forEach((key) => {
      this.orderStatuses[key] = null;
    });

    // İkinci aşamada işlemi yapan orderKey'in statusu true ise false,
    // false ise true'ya atandı ki daha sonra tam tersi işlem yapılabilsin.
    this.orderStatuses[orderKey] = !status;
    this.orderStatuses = { ...this.orderStatuses };

    if (status) {
      orderKey = `-${orderKey}`;
    }
    // son olarak formun içindeki orderby anahtarı atandı.
    // Formda orderby değişmlerine (valueChanges) subscribe olunduğu için otomatik olarak yeni liste getirilecek.
    this.form.get('order_by').setValue(orderKey);
  }
  yeniUrunEkle(item) {
    this.nbDialogService
      .open(MutabakatSatirEkleComponent, { context: { data: item } })
      .onClose.subscribe((resp) => {
        if (resp) {
          this.getFilteredData();
        }
      });
  }
  urunGuncelle(item, mutabakat_id) {
    item['edit'] = true;
    item['mutabakat_id'] = mutabakat_id;
    this.nbDialogService
      .open(MutabakatSatirEkleComponent, { context: { data: item } })
      .onClose.subscribe((data) => {
        this.getFilteredData();
      });
  }
  urunDelete(mutabakatId, item) {
    const itemId = item.id;
    Swal.fire({
      ...responseKodlari.shureToDelete,
      ...{
        text: `${item.description} başlıklı Ürünü silmek istediğinizden Emin Misiniz? Bu işlem Geri Alınamaz.`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;

        this._mutabakatService
          .deleteMutabakatItem(mutabakatId, itemId)
          .subscribe(
            (resp) => {
              this.getFilteredData();
              Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
              this.isLoading = false;
            },
            (err) => {
              Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
              this.isLoading = false;
            },
          );
      }
    });
  }
  pasifYap(row) {
    Swal.fire({
      ...responseKodlari.shureToPasif,
      ...{
        html: `${row.mutabakat_title} başlıklı Anlaşmayı ${
          row.is_active ? 'Arşive Eklemek' : 'Arşivden Çıkarmak'
        } istediğinize </br> <b>Emin Misiniz?</b>`,
        confirmButtonText: `${row.is_active ? 'Ekle' : 'Çıkar'}`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;

        this._mutabakatService
          .patchMutabakat(row.id, { is_active: !row.is_active })
          .subscribe(
            (resp) => {
              this.getFilteredData();
              Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
              this.isLoading = false;
            },
            (err) => {
              Swal.fire(responseKodlari.unSuccessRegister as SweetAlertOptions);
              this.isLoading = false;
            },
          );
      }
    });
  }
}
