import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../../pages/global-functions';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { ControlValueAccessorConnector } from '../../../control-value-accessor-connector';
import {
  IliskiliFirmaEkleComponent,
} from '../iliskili-firma-ekle/iliskili-firma-ekle.component';
import { ApasdisiFirmaEkleType } from '../organizationTypeEnums';

@Component({
  selector: 'organization-select',
  template: `<ng-select
      [items]="organizationList$ | async"
      [loading]="isLoading"
      [clearable]="clearable"
      appendTo="body"
      [typeahead]="organizationInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindLabel="name"
      [bindValue]="bindValue"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'Firma Seç'"
      (change)="organizationSelected($event)"
  >
      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
      <div class="d-flex justify-content-center">

      <button nbButton size="small" status="primary" hero (click)="openNewFirm('isveren')" *ngIf="showIsverenAddButton"> Yeni İşveren Firma Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('laboratuvar')" *ngIf="showLaboratuvarAddButton"> Yeni Laboratuvar Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('musavir')" *ngIf="showMusavirAddButton"> Yeni Müşvari Firma Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('kontrol')" *ngIf="showKontrolAddButton"> Yeni Kontrol Firması Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('isguvenligi')" *ngIf="showIsGuvenligiAddButton"> Yeni İş Güvenliği Firması Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('taseron')" *ngIf="showTaseronAdd"> Taşeron Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm('tedarikci')" *ngIf="showTedarikciAdd"> Tedarikçi Ekle </button>
      </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>`
  ,
  providers: [
    OrganizasyonService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationSelectComponent),
      multi: true,
    },
  ],
})
export class OrganizationSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {
  isLoading: boolean = false;

  @Input() bindValue: string = 'id';
  @Input() is_active: boolean = true;
  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() showAddButtons: boolean = true;
  @Input() showIsverenAddButton: boolean = false;
  @Input() showLaboratuvarAddButton: boolean = false;
  @Input() showMusavirAddButton: boolean = false;
  @Input() showKontrolAddButton: boolean = false;
  @Input() showIsGuvenligiAddButton: boolean = false;

  @Input() showTaseronAdd: boolean = true;
  @Input() showTedarikciAdd: boolean = true;

  @Input() firmTypes = ['taseron', 'tedarikci', 'isveren'];
  @Input() demo: boolean;
  @Input() organizationSearch: any;
  @Input() santiyeId: number; // İş Güvenliği için
  @Input() isRelated: boolean = true;
  @Input() clearable: boolean = true;
  constructor(
    private organizationService: OrganizasyonService,
    injector: Injector,
    private nbDialogService: NbDialogService,
    private cd: ChangeDetectorRef,
  ) {
    super(injector);
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.firstList();
    if (this.organizationSearch) {
      setTimeout(() => {
        this.organizationInput$.next(this.organizationSearch);
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.getOrganizations();

  }
  organizationSelected(event) {
    this.change.emit(event);
  }

  organizationList$: Observable<any[]>;
  organizationInput$ = new Subject<string>();
  getOrganizations() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    this.organizationList$ = concat(
      of([]), // default items
      this.organizationInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.organizationService.relatedCompanies(
            removeAllNull(
              {
                search: term,
                filter: { is_active: true, status: this.firmTypes, demo: this.demo, is_related: this.isRelated },
                santiye_id: this.santiyeId,
              }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getOrganizations();
    setTimeout(() => {
      this.organizationInput$.next('');
    }, 300);
  }
  @ViewChildren(NgSelectComponent) ngSelects: QueryList<NgSelectComponent>;

  openNewFirm(firmaTipi) {
    this.ngSelects.forEach(elm => elm.close());
    switch (firmaTipi) {
      case ApasdisiFirmaEkleType.Taseron:
        this.nbDialogService.open(IliskiliFirmaEkleComponent).onClose.subscribe(resp => {
          if (resp) {
            const returnData = resp.company_id;
            returnData['demo'] = resp?.demo;
            this.organizationList$ = of([returnData]);
            setTimeout(() => {
              this.control.setValue(returnData[this.bindValue]);
            }, 150);
            this.change.emit(returnData);
          }
        });
        break;
      case ApasdisiFirmaEkleType.Tedarikci:
        this.nbDialogService.open(IliskiliFirmaEkleComponent,
          { context: { addType: ApasdisiFirmaEkleType.Tedarikci }, closeOnBackdropClick: false },
        ).onClose.subscribe(resp => {
          if (resp) {
            const returnData = {
              id: resp?.company_id,
              name: resp?.company_name,
              demo: resp?.demo,
            };
            console.log('Return Data', returnData);
            this.organizationList$ = of([returnData]);
            setTimeout(() => {
              this.control.setValue(returnData[this.bindValue]);
            }, 150);
            this.change.emit(returnData);
          }
        });
        break;
      case ApasdisiFirmaEkleType.Musteri:
        this.nbDialogService.open(IliskiliFirmaEkleComponent,
          { context: { addType: ApasdisiFirmaEkleType.Musteri }, closeOnBackdropClick: false },
        ).onClose.subscribe(resp => {
          if (resp) {
            const returnData = resp.company_id;
            returnData['demo'] = resp?.demo;
            this.organizationList$ = of([returnData]);
            setTimeout(() => {
              console.log('bind ediyorum ', returnData);
              console.log('bind value : ', returnData[this.bindValue]);
              this.control.setValue(returnData[this.bindValue]);
            }, 150);
            this.change.emit(returnData);
          }
        });
        break;
      case ApasdisiFirmaEkleType.Isveren:
        this.nbDialogService.open(IliskiliFirmaEkleComponent,
          { context: { addType: ApasdisiFirmaEkleType.Musteri }, closeOnBackdropClick: false },
        ).onClose.subscribe(resp => {
          if (resp) {

            const returnData = resp;
            console.log('bind ediyorum ', returnData);

            returnData['demo'] = resp?.demo;
            this.organizationList$ = of([returnData]);
            setTimeout(() => {
              console.log('bind value : ', returnData[this.bindValue]);

              this.control.setValue(returnData[this.bindValue]);
            }, 150);
            this.change.emit(returnData);
          }
        });
        break;
    }

    this.cd.detectChanges();
  }

}
