
export function decrypte(encrypted_text) {
    /**
     * Encrypte edilen bir texti alıp, decrypte eder.
     * @param encrypted_text : şifrelenmiş metin.
     */

    if (!encrypted_text) {
        return;
    }
    let clear_text;
    // Localstorage'dan firmanın özel anahtarını alır.
    let key = JSON.parse(localStorage.getItem('org')).scrk;
    // Encrypte text'i base64 formatından byte array olarak çözümler.
    const ciphertext = CryptoJS.enc.Base64.parse(encrypted_text);

    // ciphertext'in ilk 16 bitlik alanından iv çözümlenir.
    const iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;

    // key utf8'den byte array'a çevirilir.
    key = CryptoJS.enc.Utf8.parse(key);

    // decryption
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
        key,
        {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        },
    );

    // Elde Edilen decrypte edilen byte array UTF-8 stringe çevirilir.
    clear_text = decrypted.toString(CryptoJS.enc.Utf8);
    return clear_text;
}


export class ApasCrypto {
    key;
    constructor(secretKey?: string) {
        if (!secretKey) {
            secretKey = JSON.parse(localStorage.getItem('org'))?.scrk;
        }
        this.key = CryptoJS.enc.Utf8.parse(secretKey);
    }

    async decrypt(encrypted_text: string) {

        if (!encrypted_text) {
            return await null;
        }

        // Encrypte text'i base64 formatından byte array olarak çözümler.
        const ciphertext = CryptoJS.enc.Base64.parse(encrypted_text);

        // ciphertext'in ilk 16 bitlik alanından iv çözümlenir.
        const iv = ciphertext.clone();
        iv.sigBytes = 16;
        iv.clamp();
        ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
        ciphertext.sigBytes -= 16;

        // decryption
        const decrypted = CryptoJS.AES.decrypt(
            { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
            this.key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
            },
        );
        // Elde Edilen decrypte edilen byte array UTF-8 stringe çevirilir.
        return await decrypted.toString(CryptoJS.enc.Utf8);

    }


    async encrypte(raw_text: string | number) {
        /**
         * düz bir metin alınır ve encrypte edilir.
         * @param raw_text : şifrelenmemiş metin.
         */

        // Localstorage'dan firmanın özel anahtarı alınır. CyrptoJs ile UTF8 World Array Cinsine Çevirilir.
        if (!raw_text) return;
        raw_text = raw_text.toString();
        // 16 bitlik random iv üretilir.
        const iv = CryptoJS.lib.WordArray.random(16);

        // verilen düz text burada şifrelenir.
        const encrypted = CryptoJS.AES.encrypt(raw_text, this.key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
        }).ciphertext;

        // iv ve ciphertext birleşitirilir.
        const concatenned = CryptoJS.lib.WordArray.create()
            .concat(iv)
            .concat(encrypted);
        const conn = concatenned.toString(CryptoJS.enc.Base64);

        // veri base64 stringe çevirlir ve dönderilir.

        return conn;
    }
}
