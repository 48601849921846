import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GorevService } from '../../../services/gorev.service';
import { NbDateService, NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { removeAllNull } from '../../../pages/global-functions';
import { Store, select } from '@ngrx/store';
import { responseKodlari } from '../../../pages/constants';
import { KullaniciModel } from '../../../models/kullanici_model';
import * as fromUser from '../../../store/user/user.connector';
import * as moment from 'moment';


@Component({
  selector: 'gorev-ekle',
  templateUrl: './gorev-ekle.component.html',
  styleUrls: ['./gorev-ekle.component.css'],
  providers: [GorevService, OrganizasyonService],
})
export class GorevEkleComponent implements OnInit {

  form: FormGroup;
  tarihTakvim: number;
  firmalar;
  @ViewChild('agreeSelect') productSelect: NgSelectComponent;

  gorevlendirilebilir: any[] = [];
  gorevList: any[];
  gorevliGrup;
  isSubmiting: boolean = false;
  importance_level = [
    { label: 'Çok Önemli', value: 1, bicim: 'cokonemli-select' },
    { label: 'Önemli', value: 2, bicim: 'onemli-select' },
    { label: 'Normal', value: 3, bicim: 'normal-select' },
  ];

  org_info = JSON.parse(localStorage.getItem('org'));
  kullanici: KullaniciModel;
  tag: string = 'GorevEkleComponent --->';
  loadingPersonelGroups: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private gorevService: GorevService,
    protected dateService: NbDateService<Date>,
    private ref: NbDialogRef<GorevEkleComponent>,
    private organizationService: OrganizasyonService,
    private store: Store<{}>,
  ) {
    this.initForm();
    store.pipe(select(fromUser.selectUser))
      .subscribe((user: KullaniciModel) => this.kullanici = user);
  }

  ngOnInit() {

    if (this.org_info) {
      this.organizationService.getOrganizasyonlar().subscribe(data => {
        this.firmalar = data;
      });
      this.organizationService.getMyOrganizationsPersonels().subscribe(data => {
        console.log(this.tag, 'personeller:', data);
        this.gorevlendirilebilir = data;
        this.gorevlendirilebilir.forEach((el) => {
          el['disabled'] = false;
        });
      });
    } else {

      this.gorevlendirilebilir = [
        this.kullanici,
      ];

    }
  }

  initForm() {
    const nextWeekToday = moment(new Date()).add(7, 'days').toDate();
    this.form = this.formBuilder.group({
      assign_to_ids: [null, [Validators.required]],
      title: [null, [Validators.required, Validators.maxLength(120)]],
      content: [null, [Validators.required, Validators.maxLength(1200)]],
      end_time: [nextWeekToday, Validators.required],
      importance_level: [3, Validators.required],
      observers: [],
      organization_id: [this.org_info ? this.org_info.id : null],
    });

    this.form.get('assign_to_ids').valueChanges.subscribe(data => {
      if (data === null) { return true; }
      console.log(this.tag, 'gorevli sec:', data);
      this.gorevlendirilebilir.forEach(el => {
        el['disabled'] = false;

        data.forEach(slEl => {
          if (slEl === el['apas_id']) {
            el['disabled'] = true;
          }
        });
      });
    });

  }

  public postForm(): void {

    if (this.form.valid) {
      const formData = this.valueToModel();
      if (this.org_info) {
        formData['organization_id'] = this.org_info.id;
      } else {
        delete formData['organization_id'];
      }
      this.isSubmiting = true;
      this.gorevService.addItem(formData).subscribe(data => {
        this.isSubmiting = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.closed();
      }, (err) => {
        this.isSubmiting = false;
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

      });

    } else {
      this.form.markAllAsTouched();
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);

    }
  }
  valueToModel(): any {
    const _data = removeAllNull(this.form.value);
    return _data;
  }

  closed() {
    this.ref.close();
  }






}
