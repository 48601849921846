<nb-card style="width: 60%; border-top: unset;" size="giant" class="modalType">
    <nb-card-header class="header">
        {{modalData?.santiye_name}} - <span *ngIf="dayData && dayData.length > 0">{{dayData[0].tarih | date:
            tarih_format}} / {{dayData[0].tarih | date : 'EEEE'}}</span>
        <nb-icon class="linked-div closeIcon" icon='close-outline' (click)="close()" style="color:#000;">
        </nb-icon>
    </nb-card-header>
    <nb-card-body >
        <div class="row" [nbSpinner]="isLoading">
            <div class="col-md-4 p-1 gallery" *ngFor="let image of dayData;let imageIndex=index"
                [nbTooltip]="image?.title ? image.title:'Açıklama yok'"
                style="margin:auto;width:auto;height: 150px !important;margin-top:15px;">
                <div style="margin:5px;">
                    <img [src]="serializer.bypassSecurityTrustUrl(image.image)" class="amac5 img-fluid linked-div"
                        style="height: 150px;margin:3px;" (click)="imgClick(image?.image, imageIndex)" />
                </div>
            </div>
        </div>

    </nb-card-body>
</nb-card>