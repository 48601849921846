
  <ng-select
  class="noBgSelect outoo uzun-select"
  appendTo="body"
  [placeholder]="'DEPO.DEPOSECINIZ' | translate"
   style="border: 1px solid #ff7043;border-radius: 5px;height: 42px;"
   bindLabel="title"
   bindValue="id"
   [items]="depolar"
   [searchable]="true"
   [clearable]="true"
   [searchable]="true"
   #depo
   [formControl]="control"
 [closeOnSelect]="true"
 (change)="depoSelect($event)"
 >
    <ng-template ng-label-tmp let-item="item">
        <div class="d-flex align-items-center">
              <b>Seçilen Depo:</b> <b style="color:#ff7043;"> {{item.title}}</b>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="d-flex align-items-center justify-content-between">
        <div>
          <b>{{item.title}}</b>
          <small *ngIf="item?.santiye_name" class="label">( {{item.santiye_name}} )</small>
        </div>
        <div (click)="yeniDepo(depo,item)">
            <nb-icon icon="edit-outline"></nb-icon>
        </div>
        </div>
    </ng-template>
    <ng-template ng-footer-tmp>
        <div (click)="yeniDepo(depo)" class="linked-div yeniEkle">
        {{ 'DEPO.DEPOEKLE' | translate }}
        </div>
    </ng-template>