
      <ng-select
          #markaSelect
          class="uzun-select ng-select-without-bottom-margin"
          [items]="brand$ | async"
          [loading]="brandLoading"
          [typeahead]="brandInput$"
          appendTo="body"
          [addTag]="addTagPromise"
          [placeholder]="'DEPO.MARKA' | translate"
          bindLabel="title"
          bindValue="id"
          [searchable]="true"
          [selectOnTab]="true"
          apasInvalid
          [formControl]="control"
          [addTagText]="'Yeni Marka Ekle'"
      ></ng-select>