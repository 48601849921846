import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Router, ActivatedRoute } from '@angular/router';
import { PursantajService } from '../../../services/pursantaj.service';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { PursantajModel } from '../../../models/pursantaj.model';
import { isGrubu, tarih_format } from '../../../pages/constants';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { daysOfTimestamps } from '../../../pages/global-functions';
import { SantiyeService } from '../../../services/santiye.service';
import { SantiyeModel } from '../../../models/santiye_model';
export interface PursantajInfo {
  toplamPursantaj: number;
  yerTeslimTarihi: number;
  yerTeslimTarihiDate: any;
  isinBitisTarihi: number;

  isProgramiBaslangicTarihi: number;
  isProgramibitisTarihi: number;

}
export enum PursantajEkleComponentUpdateType {
  'Name' = 'name',
  'Rate' = 'rate',
  'DateRate' = 'daterate',
  'Color' = 'color',
  'All' = 'all',
}
@Component({
  selector: 'pursantaj-ekle',
  templateUrl: './pursantaj-ekle.component.html',
  styleUrls: ['./pursantaj-ekle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PursantajService],
})

export class PursantajEkleComponent implements OnInit {
  updateType = PursantajEkleComponentUpdateType;
  spinner: boolean = true;
  santiye_id: number;
  rows: any[] = [];
  pursantajForm: FormGroup;
  AltgrupForm: FormGroup;
  ColumnMode = ColumnMode;
  selected = [];
  isGrubuData = isGrubu;
  _currentTab;
  isGrubuTag: string;
  tarih_format = tarih_format;
  tag: string = 'PursantajEkleComponent ---->';
  pursantajInfo: PursantajInfo = {
    isProgramiBaslangicTarihi: 0,
    isProgramibitisTarihi: 0,
    isinBitisTarihi: 0,
    yerTeslimTarihi: 0,
    yerTeslimTarihiDate: null,
    toplamPursantaj: 0,
  };

  constructor(private _router: Router,
    private pursantajService: PursantajService,
    private _activatedRouter: ActivatedRoute,
    private santiyeService: SantiyeService) {
    this._activatedRouter.params.subscribe(data => {
      this.santiye_id = data.santiye_id;

      if (!this.santiye_id) {
        this._router.navigate(['pages/dashboard']);
      }

    });
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.rows = [];
    this.spinner = true;

    console.log(this.tag, 'Refresh:');
    this.santiyeService.getSantiyeForID(this.santiye_id).subscribe((santiye: SantiyeModel) => {
      const endDay = moment(santiye.start_date, 'X');
      this.pursantajInfo.yerTeslimTarihi = Number(endDay.format('x'));

      this.pursantajInfo.yerTeslimTarihiDate = new Date(this.pursantajInfo.yerTeslimTarihi);

      endDay.add({ day: (santiye.end_time - 1) });
      const fromNow: number = (daysOfTimestamps(santiye.start_date, (new Date()).getTime() / 1000, false));
      console.log(this.tag, 'fromNow:', fromNow);
      this.pursantajInfo.isinBitisTarihi = Number(endDay.format('x'));
    });
    this.pursantajService.getPursantaj(this.santiye_id).subscribe(data => {
      console.log('Pursantaj Data İlk Hali: ', data);
      // this.sirala(data);
      data.forEach((element) => {
        element['dateRange'] = this.toNbRangePicker(element);
        this.pursantajHesapla(data, element);
        element['isSub'] = false;
        element['isActive'] = true;
        element['treeStatus'] = 'collapsed';

        if (element.ust_pursantaj_id !== null) {
          element['treeStatus'] = 'collapsed';
          element['isActive'] = true;
          element['isSub'] = true;
        } else {

          element['treeStatus'] = 'collapsed';
          element['isActive'] = true;
          element['isSub'] = false;
        }
        if (element.alt_pursantaj.length < 1) {

          element['treeStatus'] = 'disabled';
        }

      });

      this.rows = [...data];
      this.checkTotalPursantaj();
      console.log('Data Son Hali:', this.rows);
      this.spinner = false;
    }, () => {
      this.spinner = false;

    });


  }

  pursantajHesapla(data, eleman) {
    data.map(r => {
      if (!r.alt_pursantajlar) {
        r.alt_pursantajlar = 0;
      }

      if (r.ust_pursantaj_id === eleman.id) {
        if (r.pursantaj_rate) {
          eleman.alt_pursantajlar += r.pursantaj_rate;
        }
      }

    });

  }
  checkTotalPursantaj() {

    let sum: number = 0;
    this.pursantajInfo.isProgramiBaslangicTarihi = 0;
    this.pursantajInfo.isProgramibitisTarihi = 0;
    this.pursantajInfo.yerTeslimTarihiDate = new Date(this.pursantajInfo.yerTeslimTarihi);


    this.rows.forEach(element => {

      if (element.end_day) {
        if (this.pursantajInfo.isProgramibitisTarihi <= 0) {
          this.pursantajInfo.isProgramibitisTarihi = (element.end_day);

        }
        if (Number(element.end_day) > this.pursantajInfo.isProgramibitisTarihi) {
          this.pursantajInfo.isProgramibitisTarihi = (element.end_day);
        }
      }
      if (element.start_day) {
        if (this.pursantajInfo.isProgramiBaslangicTarihi <= 0) {
          this.pursantajInfo.isProgramiBaslangicTarihi = (element.start_day);
        }
        if (Number(element.start_day) < this.pursantajInfo.isProgramiBaslangicTarihi) {
          this.pursantajInfo.isProgramiBaslangicTarihi = (element.start_day);

        }
      }

      if (!element.ust_pursantaj_id) {
        sum += Number(element.pursantaj_rate);
      }
    });
    this.pursantajInfo.toplamPursantaj = sum;

  }
  refreshPursantaj(item) {
    const parent = this.rows.find(f => f.id === item.ust_pursantaj_id);
    // console.log(this.tag, 'parent:', parent);
    if (parent) {
      parent.alt_pursantajlar = 0;
      parent.alt_pursantaj.forEach(element => {
        const anyAlt = this.rows.find(f => f.id === element);
        if (anyAlt) {
          parent.alt_pursantajlar += anyAlt.pursantaj_rate ?
            ((anyAlt.pursantaj_rate > anyAlt.alt_pursantajlar) ?
              anyAlt.pursantaj_rate : anyAlt.alt_pursantajlar) : 0;
          // parent.alt_pursantajlar = Number(parent.alt_pursantajlar.toFixed(6));
        }
      });

      const grandParent = this.rows.find(f => f.id === parent.ust_pursantaj_id);

      if (grandParent) {
        this.refreshPursantaj(parent);
      }
    }
  }


  yeniAltGrupEkle(row) {
    console.log(this.tag, ' row:', row);
    row.treeStatus = 'expanded';
    const altData = {
      santiye_id: this.santiye_id,
      ust_pursantaj_id: row.id,
    };
    row['isUpadated'] = true;

    this.pursantajService.postPursantaj(this.santiye_id, altData).subscribe((response: PursantajModel) => {
      row['isUpadated'] = false;

      console.log(this.tag, 'response:', response);

      response['dateRange'] = this.toNbRangePicker(response);
      const tmp = { ...response, isSub: true, isActive: true, treeStatus: 'expanded', alt_pursantajlar: 0 };
      this.rows = [...this.rows, tmp];
      row.alt_pursantaj.push(tmp.id);


    }, () => {
      row['isUpadated'] = false;

    });
  }


  pursantajGuncelle(event, row, guncelle: PursantajEkleComponentUpdateType) {
    console.log(this.tag, 'event--->', event.target.value);
    const value: string = event.target.value;
    let pursantajData = {};


    switch (guncelle) {
      case PursantajEkleComponentUpdateType.Name:
        pursantajData = {
          santiye_id: this.santiye_id,
          pursantaj_name: event.target.value,
        };
        break;
      case PursantajEkleComponentUpdateType.Rate:
        pursantajData = {
          santiye_id: this.santiye_id,
          pursantaj_rate: (event.target.value.length) ? event.target.value : 0,
        };
        break;
      case PursantajEkleComponentUpdateType.DateRate:
        const days: string[] = value.split('-');
        let startDay: number = null;
        let endDay: number = null;
        if (days.length > 0) {
          const startDayMoment = moment(days[0], 'll');
          startDay = Number(startDayMoment.format('X'));
          if (!startDayMoment.isValid()) {
            startDay = null;
          }
          console.log(this.tag, 'moment startDay valid:', startDayMoment.isValid());

          if (days.length > 1) {
            const endDayMoment = moment(days[1], 'll');

            endDay = Number(endDayMoment.format('X'));
            if (!endDayMoment.isValid()) {
              endDay = null;
            }
            console.log(this.tag, 'moment endDay valid::', endDayMoment.isValid());
          }
        }
        pursantajData = {
          santiye_id: this.santiye_id,
          start_day: startDay,
          end_day: endDay,
        };
        break;
    }
    console.log(this.tag, 'row:', row);
    row['isUpadated'] = true;
    this.pursantajService.pursantajPatch(row.id, pursantajData).subscribe(data => {
      row['isUpadated'] = false;

      console.log(this.tag, 'Response update:', data);
      Object.keys(data).forEach(key => {
        row[key] = data[key];
      });
      if (guncelle === PursantajEkleComponentUpdateType.Rate) {
        this.refreshPursantaj(row);
      }
      this.checkTotalPursantaj();
    }, () => {
      row['isUpadated'] = false;

    });

  }
  
  isDelete(row) {
    console.log('Rows Önce', this.rows);

    if (!row.alt_pursantaj.some(f => this.rows.some(s => s.id === f)) || row.alt_pursantaj.length <= 0) {
      row['isUpadated'] = true;

      this.pursantajService.pursantajDelete(row.identifier).subscribe(data => {
        row['isUpadated'] = false;

        console.log('Silme İşlemi Gerçekleşti:', data);
        const parent = this.rows.find(f => f.id === row.ust_pursantaj_id);
        if (parent) {
          parent.alt_pursantaj = parent.alt_pursantaj.filter(f => f !== row.id);
        }
        this.rows = this.rows.filter(item => item.id !== row.id);
        console.log('Rows Sonra', this.rows);
        this.refreshPursantaj(row);
      }, (err) => {
        row['isUpadated'] = false;

        if (err) {
          if (err.ER26) {
            Swal.fire({
              icon: 'info',
              confirmButtonText: 'Tamam',
              title: 'Önce Silmek İstediğiniz Nesnenin Alt Nesnelerini Siliniz.',
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: 'info',
        confirmButtonText: 'Tamam',
        title: 'Önce Silmek İstediğiniz Nesnenin Alt Nesnelerini Siliniz.',
      });
    }
  }
  onTreeAction(event: any) {
    const index = event.rowIndex;
    const row = event.row;
    if (row.treeStatus === 'expanded') {
      row.treeStatus = 'collapsed';
    } else {
      row.treeStatus = 'expanded';
    }
    this.rows = [...this.rows];
  }

  toNbRangePicker(row): string {

    const startDay = row.start_day;
    const endDay = row.end_day;
    let start: string = '';
    let end: string = '';
    if (startDay) {
      start = moment(startDay, 'X').format('ll');
    }
    if (endDay) {
      end = moment(endDay, 'X').format('ll');
    }
    if (!(start && end)) {
      return null;
    }
    return `${start}-${end}`;
  }
  yeniGrupEkle(){
    
  }
}
