<nb-card class="modalType animated fadeInRight" style="width: 1100px;" size="giant">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span class="d-flex align-items-center">
            <nb-icon class="linked-div" (click)="eraseMarka()" *ngIf="selectedMarka" 
            icon="arrow-back-outline" style="font-size:1.8rem;">
            </nb-icon>
            {{urun.name}}  {{selectedMarka?.brand_name ? '('+selectedMarka?.brand_name+')' :'' }}
        </span>
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>



    <nb-card-body [nbSpinner]="isLoading" class="p-0">

        <div class="history-tl-container" *ngIf="selectedMarka">
          
            <div class="wpb_column vc_column_container vc_col-sm-12">
                <div class="vc_column-inner ">
                    <div class="wpb_wrapper">
                        <div class="seofy_module_spacing">

                        </div>
                        <div class="seofy_module_time_line_vertical appear_anim" style="background: #eeeeee40;">

                            <ng-container *ngFor="let hareket of hareketler;let i = index;"> 
                                <div class="time_line-item  item_show animated flipInX" >
                                    <div class="time_line-date_wrap">
                                        <div class="seofy_hexagon">
                                            <div class="daire">
                                            </div>

                                        </div>

                                        <h4 class="time_line-date">
                                            {{hareket?.created_time | date:tarih_format}}<br>
                                            <small *ngIf="hareket?.delivery_by_name">
                                                <b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                            </small><br>
                                            <small *ngIf="hareket?.delivery_by_name"><b>
                                                {{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                            </small><br>
                                        </h4>
                                    </div>
                                    <div class="time_line-content text-left">
                                        <h5 class="time_line-title" >
                                         
                                        <span>
                                        <b [class]="'depoIslemTuru'+hareket.warehouse_in_out_type">
                                            {{c.setWarehouseInOutText(hareket.warehouse_in_out_type)}} 
                                        </b>
                                        </span>
                                        <span *ngIf="hareket.warehouse_in_out_type === 6" class="d-block">
                                            <b>Transfer Edilen Depo: </b> 
                                            {{hareket?.other_warehouse?.title }} 
                                        </span>
                                        <span *ngIf="hareket.warehouse_in_out_type === 7"  class="d-block">
                                            <b>Transfer Yapan Depo: </b> 
                                            {{hareket?.other_warehouse?.title }} 
                                        </span>

                                            <div class="item-title">
                                                <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}} </b>
            
                                                <apas-para-item [value]="hareket?.amount" [alacak]="false" [negative]="false"
                                                    [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                                </apas-para-item> {{c.getBirim(hareket?.unit)}}
                                                <br>
                                                <b>{{'DEPO.KALAN' | translate }} </b>
                                                <apas-para-item [value]="hareket?.kalan_miktar" [alacak]="false" [negative]="true"
                                                    [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                                </apas-para-item> {{c.getBirim(hareket?.unit)}}
            
                                            </div>
                                            <div class="item-title">
                                                <b>{{ 'DEPO.NOT' | translate }}</b> 
                                                {{hareket?.note }} 
                                            </div>
                                          
                                            <button nbButton status="warning" size="tiny" 
                                            [nbTooltip]="'Dosya'" *ngIf="hareket?.irsaliye_image"
                                            (click)="openIrsaliyeImage(hareket)">
                                            <nb-icon icon="file-outline"></nb-icon> {{'DOSYAAC'|translate}}
                                            </button>
            
                                            
            
                                            <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                                (click)="backToEdit(hareket)">
                                                <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                            </button>

                                            <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete"
                                                style="background-color: red !important; color:#fff !important;"
                                                (click)="deleteHareket(hareket)">
                                                <nb-icon icon="trash-outline"></nb-icon> Sil
                                            </button>  
            
                                        </h5>
                                    </div>
                                </div>
                            </ng-container>

                         
                            



                        </div>

                    </div>
                </div>
            </div>

        </div>

        <nb-list *ngIf="!selectedMarka">
            <nb-list-item *ngFor="let allMarkaItem of allUrunMarka"
                class="linked-div d-flex justify-content-between align-items-center"
                (click)="selectMarka(allMarkaItem)">
                <span>
                    <b>{{allMarkaItem.brand_name}}</b>
                    <small> -
                        ( <apas-para-item [value]="allMarkaItem.positive_amount" [alacak]="false" [negative]="false"
                            [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false"></apas-para-item>
                        -
                        <apas-para-item [value]="allMarkaItem.negative_amount" [alacak]="false" [negative]="false"
                            [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false"></apas-para-item>
                        ) =

                        (<apas-para-item [value]="allMarkaItem?.positive_amount - allMarkaItem.negative_amount"
                            [alacak]="false" [negative]="false" [decrypt]="false" [setColor]="false" [currency]="false"
                            [animate]="false"></apas-para-item>
                        )


                        {{globalConstants.getBirim(allMarkaItem?.unit)}}
                    </small>
                </span>


                <button nbButton [nbTooltip]="'Ürün Hareketlerini Tüm Detaylarıyla İncele'" size="small"
                    (click)="selectMarka(allMarkaItem)" style="float:right" status="info">
                    <nb-icon icon='eye-outline' style="transform: scale(1.5);"></nb-icon> Tüm Detaylar
                </button>
            </nb-list-item>


        </nb-list>
    </nb-card-body>
</nb-card>



  <!-- <ul class="tl">

                <ng-container *ngFor="let hareket of hareketler;let i = index;">
                    <li class="tl-item"
                        [ngClass]="{'sahadan': hareket.warehouse_in_out_type == 1, 'tedarikci': hareket.warehouse_in_out_type == 2, 'cikis': hareket.warehouse_in_out_type == 3, 'iade': hareket.warehouse_in_out_type == 4,  'sil': hareket.warehouse_in_out_type == 5}">
                        <div [ngSwitch]="hareket.warehouse_in_out_type">
                            <div *ngSwitchCase="1">
                                <div class="timestamp ">
                                    {{hareket?.created_time | date:tarih_format}}<br>
                                    <small><b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                    </small><br>
                                    <small><b>{{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                    </small><br>
                                </div>
                                <div class="item-title"><b>{{'URUNHAREKETLER.SAHADANDEPOYA' | translate}}</b>
                                </div>
                                <div class="item-title">
                                    <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}} </b>

                                    <apas-para-item [value]="hareket?.amount" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                    <b>{{'DEPO.KALAN' | translate }} </b>
                                    <apas-para-item [value]="hareket?.kalan_miktar" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                </div>
                                <div class="item-title"><b>{{ 'DEPO.NOT' | translate }}</b> {{hareket?.note }} </div>
                                <div class="item-title linked-div" *ngIf="hareket?.irsaliye_image"
                                    (click)="openIrsaliyeImage(hareket)"> <img class="img-fluid" style="height: 60px;"
                                        [src]="hareket?.irsaliye_image"></div>

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete"
                                    style="background-color: red !important; color:#fff !important;float: right"
                                    (click)="deleteHareket(hareket)">
                                    <nb-icon icon="trash-outline"></nb-icon> Sil
                                </button>  

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                    (click)="backToEdit(hareket)">
                                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                </button>

                            </div>
                            <div *ngSwitchCase="2">
                                <div class="timestamp ">
                                    {{hareket?.created_time | date:tarih_format}}<br>
                                    <small><b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                    </small><br>
                                    <small><b>{{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                    </small><br>
                                </div>
                                <div class="item-title"><b>{{'URUNHAREKETLER.TEDARIKCIDENDEPOYA'|translate}}</b>
                                </div>
                                <div class="item-title"><b>Tedarikci :</b> {{hareket?.delivery_by_org_name}}</div>
                                <div class="item-title">
                                    <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}} </b>

                                    <apas-para-item [value]="hareket?.amount" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                    <b>{{'DEPO.KALAN' | translate }} </b>
                                    <apas-para-item [value]="hareket?.kalan_miktar" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                </div>
                                <div class="item-title"><b>{{ 'DEPO.NOT' | translate }}</b> {{hareket?.note }} </div>
                                <div class="item-title linked-div" *ngIf="hareket?.irsaliye_image"
                                    (click)="openIrsaliyeImage(hareket)"> <img class="img-fluid" style="height: 60px;"
                                        [src]="hareket?.irsaliye_image"></div>
                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete" status="danger"
                                    (click)="deleteHareket(hareket)">
                                    <nb-icon icon="trash-outline"></nb-icon> Sil
                                </button>  

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                    (click)="backToEdit(hareket)">
                                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                </button>

                            </div>
                            <div *ngSwitchCase="3">
                                <div class="timestamp ">
                                    {{hareket?.created_time | date:tarih_format}}<br>
                                    <small><b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                    </small><br>
                                    <small><b>{{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                    </small><br>
                                </div>
                                <div class="item-title"><b>{{'URUNHAREKETLER.DEPODANSAHAYA'|translate}}</b>
                                </div>

                                <div class="item-title">
                                    <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}} </b>

                                    <apas-para-item [value]="hareket?.amount" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                    <b>{{'DEPO.KALAN' | translate }} </b>
                                    <apas-para-item [value]="hareket?.kalan_miktar" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                </div>
                                <div class="item-title"><b>{{ 'DEPO.NOT' | translate }}</b> {{hareket?.note }} </div>
                                <div class="item-title linked-div" *ngIf="hareket?.irsaliye_image"
                                    (click)="openIrsaliyeImage(hareket)"> <img class="img-fluid" style="height: 60px;"
                                        [src]="hareket?.irsaliye_image"></div>
                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete" status="danger"
                                    (click)="deleteHareket(hareket)">
                                    <nb-icon icon="trash-outline"></nb-icon> Sil
                                </button>  

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                    (click)="backToEdit(hareket)">
                                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                </button>
                            </div>
                            <div *ngSwitchCase="4">
                                <div class="timestamp ">
                                    {{hareket?.created_time | date:tarih_format}}<br>
                                    <small><b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                    </small><br>
                                    <small><b>{{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                    </small><br>
                                </div>
                                <div class="item-title"><b>{{'URUNHAREKETLER.TEDARIKCIYEIADE'|translate}}</b>
                                </div>

                                <div class="item-title"> <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}}
                                    </b>{{hareket?.amount}} {{c.getBirim(hareket?.unit)}} <b>{{
                                        'DEPO.KALAN' | translate }} </b>{{hareket?.kalan_miktar}}
                                    {{c.getBirim(hareket?.unit)}}</div>
                                <div class="item-title"><b>{{ 'DEPO.NOT' | translate }}</b> {{hareket?.note }} </div>
                                <div class="item-title linked-div" *ngIf="hareket?.irsaliye_image"
                                    (click)="openIrsaliyeImage(hareket)"> <img class="img-fluid" style="height: 60px;"
                                        [src]="hareket?.irsaliye_image"></div>
                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete" status="danger"
                                    (click)="deleteHareket(hareket)">
                                    <nb-icon icon="trash-outline"></nb-icon> Sil
                                </button>  

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                    (click)="backToEdit(hareket)">
                                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                </button>

                            </div>
                            <div *ngSwitchCase="5">
                                <div class="timestamp ">
                                    {{hareket?.created_time | date:tarih_format}}<br>
                                    <small><b>{{ 'DEPO.BIRIM4' | translate }}</b> {{hareket?.delivery_by_name }}
                                    </small><br>
                                    <small><b>{{ 'DEPO.BIRIM5' | translate }}</b> {{hareket?.created_by_name }}
                                    </small><br>
                                </div>
                                <div class="item-title"><b>{{'URUNHAREKETLER.SILINEN'|translate}}</b></div>

                                <div class="item-title">
                                    <b> {{hareket?.in_or_out ? 'Giriş:' :'Çıkış:'}} </b>

                                    <apas-para-item [value]="hareket?.amount" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                    <b>{{'DEPO.KALAN' | translate }} </b>
                                    <apas-para-item [value]="hareket?.kalan_miktar" [alacak]="false" [negative]="false"
                                        [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false">
                                    </apas-para-item> {{c.getBirim(hareket?.unit)}}

                                </div>
                                <div class="item-title"><b>{{ 'DEPO.NOT' | translate }}</b> {{hareket?.note }} </div>
                                <div class="item-title linked-div" *ngIf="hareket?.irsaliye_image"
                                    (click)="openIrsaliyeImage(hareket)"> <img class="img-fluid" style="height: 60px;"
                                        [src]="hareket?.irsaliye_image"></div>
                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_delete" status="danger"
                                    (click)="deleteHareket(hareket)">
                                    <nb-icon icon="trash-outline"></nb-icon> Sil
                                </button>  

                                <button nbButton size="tiny" *ngIf="editMod && hareket.can_edit" status="primary"
                                    (click)="backToEdit(hareket)">
                                    <nb-icon icon='edit-outline'></nb-icon> Düzenle
                                </button>

                            </div>

                        </div>
                    </li>

                </ng-container>


            </ul> -->

