import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MutabakatModel } from '../models/mutabakat.model';
import { SantiyeModel } from '../models/santiye_model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpRequest } from '@angular/common/http';


@Injectable()
export class MutabakatService {
    private readonly API_URL = `${environment.backendurl}api/mutabakat/`;

    constructor(private httpClient: HttpClient) { }
    getMutabakatListesi(): Observable<MutabakatModel[]> {
        return this.httpClient.get<MutabakatModel[]>(this.API_URL);
    }

    getNewMutabakatListesi(filterData): Observable<MutabakatModel[]> {
        const url: string = `${this.API_URL}newlist/`;
        return this.httpClient.post<MutabakatModel[]>(url, filterData);
    }

    getMutabakatDetail(mutabakatId: number): Observable<MutabakatModel> {
        const url = `${this.API_URL}tek/${mutabakatId}/`;
        return this.httpClient.get<MutabakatModel>(this.API_URL);
    }

    getMutabakatFile(instanceId: number) {
        const url = `${this.API_URL}tek/${instanceId}/`;
        const req = new HttpRequest('GET', url, { reportProgress: true });
        return this.httpClient.request(req);
    }

    postMutabakat(data): Observable<any> {
        const url = this.API_URL;
        return this.httpClient.post(url, data);
    }

    patchMutabakat(mutabakatId, data): Observable<MutabakatModel> {
        const url = `${this.API_URL}update/${mutabakatId}/`;
        return this.httpClient.patch<MutabakatModel>(url, data);
    }

    aproveMutabakat(mutabakatId: number) {
        const url = `${this.API_URL}aprove/${mutabakatId}/`;
        return this.httpClient.get(url);
    }

    getMutabakatForTaseron(filter): Observable<any[]> {
        const url = `${this.API_URL}companylist/`;
        return this.httpClient.post<any[]>(url, filter);
    }

    deleteMutabakat(instanceId) {
        const url: string = `${this.API_URL}delete/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    santiyeList(): Observable<SantiyeModel[]> {
        const url: string = `${this.API_URL}santiyelist/`;
        return this.httpClient.get<SantiyeModel[]>(url);
    }

    getFirmaList(): Observable<any[]> {
        const url: string = `${this.API_URL}firmalist/`;
        return this.httpClient.get<any[]>(url);
    }

    addItem(data: any[]): Observable<any[]> {
        const url: string = `${this.API_URL}additem/`;
        return this.httpClient.post<any[]>(url, data);
    }

    patchMutabakatItem(mutabakatId: number, itemId: number, data: any) {
        const url = `${this.API_URL}update/${mutabakatId}/${itemId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteMutabakatItem(mutabakatId: number, itemId: number) {
        const url: string = `${this.API_URL}delete/${mutabakatId}/${itemId}/`;
        return this.httpClient.delete(url);
    }

}


