import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BaseModel } from '../../../../models/base.model';
import { birim, Constants, responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { DepoService } from '../../../../services/depo.service';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { TaseronService } from '../../../../services/taseron.service';

@Component({
  selector: 'ngx-urun-hareket-edit',
  templateUrl: './urun-hareket-edit.component.html',
  styleUrls: ['./urun-hareket-edit.component.scss'],
})
export class UrunHareketEditComponent implements OnInit {
  @Input() editData;
  c = Constants.prototype;
  isLoading: boolean = false;
  form: FormGroup;
  editStatus = false;
  toEditHareket;
  birim = birim;
  tedarikci$: Observable<BaseModel[]>;
  organizationLoading = false;
  orgListeInput$ = new Subject<string>();
  personelList$: Observable<BaseModel[]>;
  minos: boolean = false; // işlem başlangıcında miktar verisi + veya - mi ?
  allPersonel: any;
  allLoading = true;
  constructor(private ref: NbDialogRef<UrunHareketEditComponent>,
    private fb: FormBuilder,
    private _organizasyonService: OrganizasyonService,
    private _depoService: DepoService,
    private _taseronService: TaseronService,
  ) {
    this.initForm();
    this.loadTedarikci();
    this.loadPersonel();
  }

  ngOnInit(): void {
    this._taseronService.getAllTaseron().subscribe((data) => {
      this.allPersonel = data;
      this.allLoading = false;
    });
    if (this.editData) {
      if (this.editData.amount < 0) this.minos = true;
      const amount = Math.abs(this.editData.amount);

      this.form.patchValue(this.editData);

      this.form.get('amount').setValue(amount);
      this.form.get('note').setValue(this.editData.note);
      this.form.get('unit').setValue(this.editData.unit);
      // this.form.get('unit').disable();
      this.form.get('created_time').setValue(this.editData.created_time);

      console.log('hareket.delivery_by_org_name : ', this.editData.delivery_by_org_name);


      setTimeout(() => {
        this.orgListeInput$.next(this.editData.delivery_by_org_name);
        this.personelListInput$.next(this.editData.delivery_by_name);
        this.form.get('delivery_by_org_apas_id').setValue(this.editData.delivery_by_org_apas_id);
        this.form.get('delivery_by_apas_id').setValue(this.editData.delivery_by_name);

      }, 500);


    }
  }

  initForm() {
    this.form = this.fb.group({
      id: [],
      created_time: [],
      delivery_by_org_apas_id: [],
      delivery_by_name: [],
      delivery_by_apas_id: [],
      amount: [],
      unit: [],
      irsaliye_image: [],
      file_change: [],
      note: [],
    });

    this.form.get('irsaliye_image').valueChanges.subscribe(val => {
      if (val) {
        this.form.get('file_change').setValue(true);
      } else {
        this.form.get('file_change').setValue(null);
      }
    });
  }

  tedarikciSelected(secim) {
    if (secim) this.form.controls.delivery_by_apas_id.reset();
    else this.form.controls.delivery_by_apas_id.reset();
  }
  private loadTedarikci() {
    this.tedarikci$ = concat(
      of([]), // default items
      this.orgListeInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.organizationLoading = true),
        switchMap(term => {
          console.log('SEARCH TERM : ', term);
          return this._organizasyonService.searchOrganization(term, true).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.organizationLoading = false;
            }),
          );
        },

        ),
      ),
    );
  }

  personelLoading = false;
  personelListInput$ = new Subject<string>();
  private loadPersonel() {
    this.personelList$ = concat(
      of([]), // default items
      this.personelListInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.personelLoading = true)),
        switchMap((term) => {
          if (term) {
            return this._organizasyonService
              .getOtherOrganizationsPersonels(
                this.form.get('delivery_by_org_apas_id').value,
                term,
              )
              .pipe(
                catchError(() => of([])), // empty list on error
                tap(() => (this.personelLoading = false)),
              );
          } else {
            this.personelLoading = false;
            return of([]);
          }
        }),
      ),
    );
  }
  selectedDeliveryPersonel(event) {
    if (event) {
      this.form.get('delivery_by_name').setValue(event.name);
    } else this.form.get('delivery_by_name').setValue(null);
  }

  isFileCleared: boolean = false;


  closed() {
    this.ref.close();
  }


  saveLoad: boolean = false;
  patchForm() {
    this.saveLoad = true;
    const formVal = removeAllNull(this.form.value);
    if (this.minos) {
      formVal.amount = formVal.amount * -1;
    }
    this._depoService
      .editHareket(this.editData.id, formVal)
      .subscribe((resp) => {
        this.saveLoad = false;
        console.log('RESP : ', resp);
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.ref.close({ refresh: true });
      }, err => {
        this.saveLoad = false;
      });
  }
}
