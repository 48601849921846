import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { NoteListModel, NoteModel } from '../models/note.model';


@Injectable()
export class NoteService {
    private readonly API_URL = `${environment.backendurl}api/`;

    constructor(private httpClient: HttpClient) { }

    getNoteList(module: string, instanceId: number, next?: string): Observable<NoteListModel> {
        let url: string = `${this.API_URL + module}/${instanceId}/notes/`;
        if (next) {
            next = next.replace(/^http:\/\//i, 'https://');
            url = next;
        }
        return this.httpClient.post<NoteListModel>(url, '');
    }

    createNote(module: string, instanceId: number, data: any): Observable<NoteModel> {
        const url: string = `${this.API_URL + module}/${instanceId}/note/create/`;
        return this.httpClient.post<NoteModel>(url, data);
    }

    updateNote(module: string, instanceId: number, noteId: number, data: any): Observable<NoteModel> {
        const url: string = `${this.API_URL + module}/${instanceId}/note/update/${noteId}/`;
        return this.httpClient.patch<NoteModel>(url, data);
    }

    noteDetail(module: string, instanceId: number, noteId: number): Observable<NoteModel> {
        const url: string = `${this.API_URL + module}/${instanceId}/note/detail/${noteId}/`;
        return this.httpClient.post<NoteModel>(url, '');
    }
}
