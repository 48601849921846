<nb-card class="modalType animated fadeInRight" style="width: 600px;">

    <nb-card-header>
        <span>
            Yapılan İşe Fotoğraf Ekle
        </span>
        <button nbButton (click)="close()" size="tiny" style="color: #000;float:right">X</button> </nb-card-header>
    <nb-card-body [formGroup]="form" >
        <div class="row">

            <div class="col-md-3" *ngFor="let file of currentFiles; let i = index">
                <apas-image-uploader [Url]="targetUrl" [Src]="file" [showPublic]="showPublic"
                    [publicText]="'İşveren Görüntüleyebilir.'" (success)="success($event,i)"
                    (onRemove)="remove($event,i)" (successLoad)="successLoad()"></apas-image-uploader>

            </div>
            <div class="col-md-3">
                <div class="default-img linked-div" (click)="openFile(fileInput)">
                    <nb-icon icon="plus-circle-outline" style="color: #443939;"></nb-icon>
                    <span *ngIf="currentFiles.length <= 0"> {{'SANTIYEIMGUPLOAD.FOTOGRAFSEC'|translate}}</span>
                    <span *ngIf="currentFiles.length > 0"> {{'SANTIYEIMGUPLOAD.FOTOGRAFEKLE'|translate}}</span>
                </div>
                <input #fileInput type="file" accept="image/*" style="display:none" multiple
                    (change)="openFileDialog($event)">
            </div>
        </div>

        <div *ngIf="currentFiles.length > 0" class="col-md-12 text-center">
            <button nbButton status="primary" size="small" (click)="uploadAll()">
                <nb-icon style="margin-right: 5px;" icon="cloud-upload-outline"></nb-icon>
                {{'SANTIYEIMGUPLOAD.TUMUNUYUKLE'|translate}}
            </button>
        </div>
    </nb-card-body>
</nb-card>