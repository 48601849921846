import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { HatirlatmaService } from '../../../services/hatirlatma.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'hatirlatma-ekle',
  templateUrl: './hatirlatma-ekle.component.html',
  styleUrls: ['./hatirlatma-ekle.component.css'],
  providers: [HatirlatmaService],
  encapsulation: ViewEncapsulation.None,
})
export class HatirlatmaEkleComponent implements OnInit {
  hatirlatmaForm: FormGroup;
  tarihTakvim: number;
  isAdd = false;
  isLoading: boolean = false;
  constructor(private ref: NbDialogRef<HatirlatmaEkleComponent>,
    private fb: FormBuilder,
    private hatirlatmaService: HatirlatmaService,
    private store: Store<{}>) {

  }

  ngOnInit() {
    this.hatirlatmaForm = this.fb.group({
      note: new FormControl(null, Validators.required),
      gun: new FormControl(this.tarihTakvim ? new Date(this.tarihTakvim * 1000) : null, Validators.required),
      saat: new FormControl(null, Validators.required),
    });

  }



  hatirlatmaEkle() {
    if (!this.hatirlatmaForm.valid) {
      this.hatirlatmaForm.markAllAsTouched();
      return;
    }
    const formData = this.valuesToModel();

    this.isLoading = true;
    this.hatirlatmaService.postHatirlatma(formData).subscribe(data => {
      this.isLoading = false;
      Swal.fire({
        icon: 'success',
        title: 'Hatırlatma Oluşturuldu!',
        timer: 1500,
      });
      this.hatirlatmaForm.reset();
      this.isAdd = true;
      this.closed();
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.isLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Hata  Hatırlatma Oluşturulamadı.',
        timer: 1500,
      });
    });
  }
  closed() {
    this.ref.close();

  }
  valuesToModel() {
    const data = this.hatirlatmaForm.value;
    const time = new Date(data.gun);
    const saat = data.saat.split(':');
    const myDate = moment(time);
    myDate.add({ hour: saat[0], minute: saat[1] });
    data['reminder_time'] = myDate.format('X');
    delete data.gun;
    delete data.saat;
    this.hatirlatmaForm.updateValueAndValidity();
    return data;
  }

}
