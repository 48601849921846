import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  EventEmitter,
  ChangeDetectorRef,
  ViewContainerRef,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'apas-filter',
  templateUrl: './apas-filter.component.html',
  styleUrls: ['./apas-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApasFilterComponent),
      multi: true,
    },
  ],
})
export class ApasFilterComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  /** 
   * Parent komponenti almanın daha iyi bir yöntemi bulunamadığı için self'ler this'lere eşitlendi.
   * Bunun benim python sevgimle ne alakası var şimdi?
   */
  @Input() sagLayoutHide: boolean = false;
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() buttonStyle: any;
  @Input() showFilterButton: boolean = true;
  @Input() araText: any = 'Ara';
  @Input() islemlerTemplate: TemplateRef<any>;
  @Input() filterSagTemplate: TemplateRef<any>;

  @Input() formValue: FormGroup;
  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(obj: string) {
    this.switch(obj);
  }

  @Input()
  public disabled: boolean = false;

  @Output()
  public change: EventEmitter<string> = new EventEmitter<string>();

  public onChange: (value: string) => {};
  public onTouched: () => void;

  private _value: string;


  body: TemplateRef<any>;
  header: TemplateRef<any>;
  filterSag: TemplateRef<any>;
  islemler: TemplateRef<any>;
  injector: any;
  @Input() parentComponent: any;
  constructor(
    private _cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,

  ) {
    this.injector = this.viewContainerRef.injector;

  }
  ngOnInit(): void {
    this.header = this.headerTemplate;
    this.body = this.bodyTemplate;
    this.islemler = this.islemlerTemplate;
    this.filterSag = this.filterSagTemplate;


  }

  ngAfterViewInit(): void {
    if (!this.parentComponent) {
      try {
        this.parentComponent = this.injector.view.component;
      } catch (error) {
        console.warn('parent komponent alınamadı');
      }
    }
  }

  public switch(value: string) {
    if (value !== this.value && this.disabled === false) {
      this._value = value;
      const sendValue = this.value;
      this.change.emit(sendValue);

      if (this.onChange) {
        this.onChange(sendValue);
      }

      this._cdr.markForCheck();
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  filterKey = filterKey;
  colorSet = colorSet;
  typeOfVal(val) {
    const arrayControl = Array.isArray(val);
    if (val && arrayControl) {
      return 'array';
    } else {
      return typeof val;
    }
  }
  filterKeyGoster(key) {
    let keyControl;

    if (this.formValue.get(key)['translate_key']) {
      return true;
    }


    keyControl = this.filterKey.find(x => x === key);
    if (keyControl) {
      return true;
    } else {
      false;
    }
  }

  clearFilterItem(keyItem, keyGroup?) {
    if (keyGroup) {
      this.formValue.get(keyGroup).get(keyItem).setValue(null);
      // Her Filtre sayfasında search valuchanges oolduğu için bir defa çalıştırıyoruz ki data yenilensin.
      this.getFilteredData();

    } else {
      this.formValue.get(keyItem).setValue(null);
      // Her Filtre sayfasında search valuchanges oolduğu için bir defa çalıştırıyoruz ki data yenilensin.
      this.formValue.get('search').setValue(null);

    }
  }


  getFilteredData() {
    this.sagLayoutHide = false;
    this.parentComponent.getFilteredData();
  }

}


export const filterKey = [
  'turu',
  'filter',
  'tags',
  'is_active',
  'cari_id',
  'with_fatura',
  'without_fatura',
  'start_date',
  'end_date',
  'firma',
  'hareket_type',
  'teklif_veren_id',
  'tedarikci_apas_id',
  'personel',
  'santiye_id',
  'aktif',
  'mutabakat_taraf_id',
  'pasif',
  'is_santiye_active',
  'santiye_active',
  'mine',
  'owned',
  'sended_to',
  'yetkili',
  'yetkisiz',
  'driver',
  'machine_type_id',
  'max_product_year ',
  'min_product_year ',
  'only_zimmetli ',
  'own_statu',
  'account_id',
  'taseron_id',
  'arac_id',
  'tedarikci_id',
  'importance_level',
  'is_signed',
  'signed_by',
  'company_id',
  'organization_id',
  'brand',
  'islemsiz',
  'faturali',
  'faturasiz',
  'content_type',
  'group_id',
  'musteri_id',
];
export const colorSet = [
  '#FF663340', '#FFB39940', '#00B3E640',
  '#E6B33340', '#3366E640', '#99996640', '#99FF9940', '#B34D4D40',
  '#80B30040', '#E6B3B340', '#6680B340', '#66991A40',
  '#FF99E640', '#CCFF1A40', '#FF1A6640', '#E6331A40', '#33FFCC40',
  '#66994D40', '#B366CC40', '#4D800040', '#B3330040', '#CC80C40',
  '#66664D40', '#991AFF40', '#E666FF40', '#4DB3FF40', '#1AB39940',
  '#E666B340', '#33991A40', '#CC999940', '#B3B31A40', '#00E68040',
  '#4D806640', '#80998040', '#E6FF8040', '#1AFF3340', '#99993340',
  '#FF338040', '#CCCC0040', '#66E64D40', '#4D80CC40', '#9900B340',
  '#E64D6640', '#4DB38040', '#FF4D4D40', '#99E6E640', '#6666FF40'];
