import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { toTimestampWithMoment } from './global-functions';

export const timeValidator: ValidatorFn = (
  control: FormGroup,
): ValidationErrors | null => {
  const startTime = control.get('start_time');
  const finishTime = control.get('finish_time');
  return startTime &&
    finishTime &&
    startTime.value &&
    finishTime.value &&
    // clockToTimestamp(startTime.value) > clockToTimestamp(finishTime.value)
    toTimestampWithMoment(startTime.value) >  toTimestampWithMoment(finishTime.value) ? { timeValidator: true }  : null;
};
