import { Injectable } from '@angular/core';
import { HatirlatmaNaigatorService } from '../hatirlatma/naigator.service';
import { GorevNaigatorService } from '../gorev/naigator.service';
import { ApasModules } from './naviagtor-types';




export class ApasNaviagetItem {
    tag: string = 'ApasNaviagetItem --->';
    /**
     * Bu nesnenin çalışması içindir.
     * Dışardan müdahale etmeyin.
     */
    private mainModule: ApasModules;
    /**
   * Bu nesnenin çalışması içindir.
   * Dışardan müdahale etmeyin.
   */
    private targetModule: string;
    /**
     * Ana modulu ifade eder.
     */
    get MainModule(): ApasModules { return this.mainModule; }
    /**
     * Ana moduldeki tabları ifade eder.
     */
    get TargetModule(): string { return this.targetModule; }

    /**
     * Module stringini nesne ye cevirir.
     */
    constructor(module: string) {
        this.mainModule = this.separateModules(module);
    }

    /**
     * String olan modulu nesneye çevirir.
     * @param modul String olarak modulu alır.'or:isguvenligi.uyugunsuzluk'
     * @var targetModulede burda belirlenir.
     */
    separateModules(modul: string): ApasModules {
        // string olan modulu '.' ile ayırım diziye donusturur.
        const moudles: string[] = modul.split('.');
        // return edilecek ana modul.
        let mainModule: ApasModules;
        console.log(this.tag, 'modules:', moudles);

        switch (moudles[0]) {
            case ApasModules.Hatirlatma:
                mainModule = ApasModules.Hatirlatma;
                this.targetModule = moudles[1];
                break;
            case ApasModules.Gorev:
                mainModule = ApasModules.Gorev;
                this.targetModule = moudles[1];
                break;
        }

        return mainModule;
    }


}

@Injectable({ providedIn: 'root' })
export class ApasNaviagationService {
    constructor(private hatirlatmaNavigatorService: HatirlatmaNaigatorService,
        private gorevNavigatorService: GorevNaigatorService) {

    }
    navigateModule({ module, payload }) {
        const apasNaviagetItem: ApasNaviagetItem = new ApasNaviagetItem(module);

        this.navigateTo({ module: apasNaviagetItem.MainModule, payload });
    }

    navigateTo({ module, payload }) {
        console.log(' ApasNaviagationService --->', module);
        switch (module) {
            case ApasModules.Hatirlatma:
                this.hatirlatmaNavigatorService.naviagate({ module: module, payload: payload });
                break;
            case ApasModules.Gorev:
                this.gorevNavigatorService.naviagate({ module: module, payload: payload });
                break;

            default:
                break;
        }
    }
}
