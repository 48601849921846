import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IsMakinsiCalismaRaporModel } from '../../../models/is_makinalari.model';
import { currency, responseKodlari, tarihFullFormat, tarih_format } from '../../../pages/constants';
import {
  addOrUpdate,
  calculateTimeBetweenStartEnd,
  calculateTotalTime,
  convertDateWithTZ,
  exportExcelListeWithHeaders,
  GlobalFunction,
  removeAllNull,
} from '../../../pages/global-functions';
import { IsMakinalariService } from '../../../services/is_makinalari.service';
import * as moment from 'moment-timezone';
import { CalismaGuncelleComponent } from '../is-makinasi-detay/calisma-detayi/calisma-guncelle/calisma-guncelle.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { DatePipe } from '@angular/common';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'is-makinasi-puantaj-raporu',
  templateUrl: './is-makinasi-puantaj-raporu.component.html',
  styleUrls: ['./is-makinasi-puantaj-raporu.component.css'],
})
export class IsMakinasiPuantajRaporuComponent implements OnInit {
  currency = currency;
  gb = GlobalFunction.prototype;
  @ViewChild('myTable') table: any;
  tarih_format = tarih_format;
  tarihFullFormat = tarihFullFormat;
  // page = new Page();
  rows: IsMakinsiCalismaRaporModel[];

  loadings: any = {};
  tag: string = 'CalismaDetayiComponent ----->';
  form: any;
  totalTime;

  machine_type;
  santiye_id;

  constructor(
    private isMakinalariService: IsMakinalariService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
    private datePipe: DatePipe,
    private router: Router,
    @Optional() private ref: NbDialogRef<IsMakinasiPuantajRaporuComponent>,
  ) {
    this.loadings['taseron'] = false;
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      start_time: [],
      finish_time: [],
      santiye_id: [],
      machine_id: [],
      machine_type_id: [],
      taseron_id: [],
      search: [],
      santiye_is_active: [true],
    });

    this.form.get('search').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getReport();
      }, 150);
    });
    this.form.get('santiye_id').valueChanges.subscribe((val) => {
      console.log('Santiye Selected Form', this.form.value);
    });
    this.form.get('santiye_is_active').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getReport();
      }, 150);
    });
  }

  ngOnInit() {
    if (this.machine_type) {
      this.form.get('machine_type_id').setValue(this.machine_type);
    }

    if (this.santiye_id) {
      this.form.get('santiye_id').setValue(Number(this.santiye_id));
      console.log('Şantiye Selected Form', this.form.value);
    }


    this.getReport();
  }

  editCalisma(item) {
    this.nbDialogService.open(CalismaGuncelleComponent, { context: { editData: item } }).onClose.subscribe(resp => {
      if (resp) {
        this.getReport();
      }
    });
  }

  request;
  isLoading: boolean = false;
  totalMiktar = 0;
  getReport() {
    this.isLoading = true;
    this.totalTime = 0;
    this.totalMiktar = 0;
    const filtre = removeAllNull(this.form.value);
    if (this.request) this.request.unsubscribe();

    this.request = this.isMakinalariService
      .getIsMakinalariPuantajRaporu({ filter: filtre })
      .subscribe((data) => {
        this.rows = data;
        console.log(this.tag, ' Puantaj Raporu:', data);
        this.rows.forEach(item => {
          item = this.dateClear(item);

          this.totalTime += moment(item.finish_time).diff(moment(item.start_time));
          if (item.work_type === 'sefer') {
            this.totalMiktar += Number(item.sefer);
          }
        });
        this.totalTime = calculateTotalTime(this.totalTime);

        this.isLoading = false;
      }, (err) => {
        this.rows = [];
        this.isLoading = false;
      });

  }

  excelReporting: boolean = false;


  dateClear(item) {
    if (!item.gmt) {
      item.gmt = 'Europe/Istanbul';
    }

    if (item.start_time) {
      item.start_time = convertDateWithTZ(item, 'start_time');
      item.baslangic = moment(item.start_time).format('DD/MM/yyyy HH:mm');

    }

    if (item.finish_time) {
      item.finish_time = convertDateWithTZ(item, 'finish_time');
      item.bitis = moment(item.finish_time).format('DD/MM/yyyy HH:mm');

    }

    if (item.start_time && item.finish_time) {
      item.total_work_time = calculateTimeBetweenStartEnd(item.start_time, item.finish_time);
    }

    return item;
  }


  listeExcel() {
    this.excelReporting = true;
    const formVal = removeAllNull(this.form.value);
    formVal['excel'] = true;
    formVal.page = 0;
    formVal.full = true;
    let totalFinish = 0;
    let totalStart = 0;
    let currentRows = [];
    const toExcelRequest: any = this.isMakinalariService.getIsMakinalariPuantajRaporu(formVal).toPromise();

    toExcelRequest.then(resp => {
      resp.forEach(elm => {
        totalFinish += elm.finish_time;
        totalStart += elm.start_time;
        elm = this.dateClear(elm);
        currentRows = addOrUpdate(currentRows, elm);
      });
      return resp;
    }).then(() => {
      const basliklar = [
        { header: 'Şantiye', key: 'santiye_name', width: 20 },
        { header: 'Makina Türü', key: 'machine_type_title', width: 30 },
        { header: 'Plaka', key: 'plate', width: 30 },
        { header: 'Taşeron ', key: 'taseron_name', width: 30 },
        { header: 'Başlangıç Tarihi', key: 'baslangic', width: 30 },
        { header: 'Bitiş Tarihi', key: 'bitis', width: 30 },
        { header: 'Çalışma Şekli', key: 'work_type', width: 30 },
        { header: 'Süre', key: 'total_work_time', width: 30 },
        { header: 'Sefer', key: 'sefer', width: 30 },

      ];

      const title = `İş Makinası Puantaj Raporu`;
      exportExcelListeWithHeaders(basliklar, currentRows, title, 'İş Makinası Puantaj Raporu');
    }).then(() => this.excelReporting = false);
  }

  deleteInfo(row) {
    const text = `${row.plate} plakalı aracın ${this.datePipe.transform(row.start_time, this.tarih_format)} tarihli çalışma raporunu silmek istedğinizden <b><u>Emin Misiniz?</u></b>`;

    Swal.fire({ ...responseKodlari.shureToDelete, ...{ html: text } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isLoading = true;
        this.isMakinalariService.deleteMotorInfo(row.id).subscribe(val => {
          this.isLoading = false;

          this.rows = this.rows.filter(x => x.id !== row.id);
          this.rows = [...this.rows];

          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
        }, err => {
          this.isLoading = false;

        });
      }
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
    });
  }


  close() {
    if (this.ref) {
      this.ref.close();
    } else {
      this.router.navigate(['/pages/is-makinalari/is-makinalari-list']);
    }
  }
}
