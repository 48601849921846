import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PursantajModel } from '../models/pursantaj.model';
import { environment } from '../../environments/environment';


@Injectable()
export class PursantajService {
    private readonly API_URL = `${environment.backendurl}api/pursantaj/`;

    constructor(private httpClient: HttpClient) { }

    getPursantaj(santiyeId: number): Observable<any> {
        const url: string = `${this.API_URL}${santiyeId}/`;
        return this.httpClient.get<any>(url);
    }

    postPursantaj(santiyeId, data) {
        const url: string = `${this.API_URL}${santiyeId}/`;
        return this.httpClient.post(url, data);
    }

    pursantajPatch(id: number, data): Observable<any> {
        const url: string = `${this.API_URL}update/${id}/`;
        return this.httpClient.patch<any>(url, data);
    }

    pursantajDelete(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}delete/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    pursantajDeleteAnyWay(identifier: string): Observable<any> {
        const url: string = `${this.API_URL}deleteanyway/${identifier}/`;
        return this.httpClient.delete<any>(url);
    }

    getPursantantajDetail(pursantajId: number): Observable<any> {
        const url: string = `${this.API_URL}detay/${pursantajId}/`;

        return this.httpClient.get<any>(url);
    }

    getNewPursantajList(santiyeId): Observable<any> {
        const url: string = `${this.API_URL}list/${santiyeId}/`;
        return this.httpClient.get<any>(url);
    }

    getAllDetayForPursantaj(pursantajId: number): Observable<any> {
        const url: string = `${this.API_URL}alldetay/${pursantajId}/`;
        return this.httpClient.get<any>(url);
    }

    addDepend(data: any): Observable<PursantajModel> {
        const url: string = `${this.API_URL}adddepend/`;
        return this.httpClient.post<PursantajModel>(url, data);
    }

    removeDepend(data: any) {
        const url: string = `${this.API_URL}removedepend/`;
        return this.httpClient.post(url, data);
    }

    colorList(): Observable<any[]> {
        const url: string = `${this.API_URL}colors/`;
        return this.httpClient.get<any[]>(url);
    }

    yapilanIsDetay(pursantajId): Observable<any> {
        const url: string = `${this.API_URL}yapilanisdetay/${pursantajId}/`;
        return this.httpClient.get<any>(url);
    }

    updateDefaults(santiyeId) {
        const url: string = `${this.API_URL}updatedef/${santiyeId}/`;
        return this.httpClient.get(url);
    }
}


