import * as duyuruActions from './duyuru.actions';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DuyuruModel } from '../../models/duyuru.model';

interface DuyuruState extends EntityState<DuyuruModel> {
    selectedDuyuruId: number | null;
    unReadedCount: number;
    end: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
}

const duyuruAdapter: EntityAdapter<DuyuruModel> = createEntityAdapter<DuyuruModel>();

const defaultDuyuruState: DuyuruState = {
    ids: [],
    entities: {},
    unReadedCount: 0,
    selectedDuyuruId: null,
    end: false,
    loading: false,
    loaded: false,
    error: '',
};

const initialState = duyuruAdapter.getInitialState(defaultDuyuruState);

function toplamReaded(toplam, next) {
    if (!next.isReaded)
        return ++toplam;
    else
        return toplam;
}
const duyuruReducer = createReducer(
    initialState,
    on(duyuruActions.loadDuyuruSuccess, (state, { duyuruItems, isEnd }) => {
        return duyuruAdapter.upsertMany(duyuruItems, {
            ...state,
            unReadedCount: state.unReadedCount + duyuruItems.reduce(toplamReaded, 0),
            loading: false,
            loaded: true,
            end: isEnd,
        });
    }),
    on(duyuruActions.loadDuyuruFail, (state, props) => {
        return { ...state, loading: false, error: props };
    }),
    on(duyuruActions.addItem, (state, { duyuruItem }) => {
        return duyuruAdapter.upsertOne(duyuruItem, {
            ...state,
        });
    }),
    on(duyuruActions.setDuyuruReadedSuccess, (state, { duyuru }) => {
        return duyuruAdapter.updateOne(duyuru,
            { ...state, unReadedCount: state.unReadedCount > 0 ? state.unReadedCount - 1 : 0 });
    }),
    on(duyuruActions.setDuyuruReadedFail, (state, { err }) => {
        return {
            ...state, loading: false, error: err,
        };
    }),

);

function DuyuruReducer(action, state) {
    return duyuruReducer(action, state);
}

export {
    DuyuruState,
    duyuruAdapter,
    defaultDuyuruState,
    DuyuruReducer,
};
