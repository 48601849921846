import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../app-state';
import { duyuruAdapter, DuyuruState } from './duyuru.reducer';


const getDuyuruFeatureState = createSelector(
    fromRoot.getUserStateSelector, (state: fromRoot.UserState) => state?.Duyuru,
);

const selectDuyuru = createSelector(
    getDuyuruFeatureState,
    duyuruAdapter.getSelectors().selectAll,
);

const selectUnReadedDuyuru = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state.unReadedCount,
);
const selectDuyuruInfo = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state,
);

const selectDuyuruLoading = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state.loading,
);

const selectDuyuruLoaded = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state.loaded,
);
const selectDuyuruEnd = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state.end,
);
const selectDuyuruError = createSelector(
    getDuyuruFeatureState,
    (state: DuyuruState) => state.error,
);

const getCurrentDuyuruId = createSelector(getDuyuruFeatureState,
    (state: DuyuruState) => state.selectedDuyuruId);

const selectDuyuruItem = createSelector(
    getDuyuruFeatureState,
    getCurrentDuyuruId,
    state => state.entities[state.selectedDuyuruId],
);

export {
    selectDuyuru,
    selectUnReadedDuyuru,
    selectDuyuruInfo,
    selectDuyuruLoading,
    selectDuyuruLoaded,
    selectDuyuruEnd,
    selectDuyuruError,
    getCurrentDuyuruId,
    selectDuyuruItem,
};
