
  <ng-select
      [items]="makinaList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="makinaInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindValue="id"
      bindLabel="plate"
      [addTag]="false"
      [closeOnSelect]="!multiple"
      [multiple]="multiple"
      [selectOnTab]="true"
      [placeholder]="'Makina'"
      class="uzun-select"
      ngDefaultControl
      (change)="makinaSelect($event)"
      [formControl]="control"
  >
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        {{item.plate}} ({{item.machine_type_name}})
    </ng-template>
  </ng-select>