<div style="position: relative;">
    <div class="col-md-2" style="position: absolute; top: 10px; right: 0px;">
        <button nbButton autofocus="false" (click)="close()" size="tiny" class="m-close" style="background: none !important;
          color: #000 !important;
          float: right;
          font-size: 18px;"> X </button>
    </div>

    <div>
    <yapilan-isler-ekle tabindex="1" [santiye_id]="santiye_id" [canEdit]="canEdit" [pursantajId]="pursantajId" [isModal]="isModal"></yapilan-isler-ekle>

    </div>

</div>