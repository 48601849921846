<nb-card style="min-width: 1280px;  max-width: 1305px;" class="modalType animated fadeInRight" size="giant">
  <nb-card-header>
    {{talepDetay?.title}}
    <button nbButton (click)="closed()" size="tiny" class="m-close"
      style="background: none !important;  color: #000 !important;  float: right;  font-size: 18px;">X</button>
  </nb-card-header>
  <nb-card-body class="p-0" [nbSpinner]="isLoading">
    <!--Talep Genel Bilgiler-->
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ 'SATINALMATALEP.TALEPTARIH' | translate }} </th>
          <th scope="col">{{ 'SATINALMATALEP.GURUPTITLE' | translate }}  </th>
          <th scope="col">{{ 'SATINALMATALEP.TALEPEDEN' | translate }} </th>
          <th scope="col">{{ 'SATINALMATALEP.SANTIYE' | translate }}</th>
          <!-- <th scope="col">İşlem</th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{talepDetay?.created_time * 1000 | date: tarih_format}}</td>
          <td>{{talepDetay?.title}}</td>
          <td>{{talepDetay?.created_by_name}}</td>
          <td>{{talepDetay?.santiye_name}}</td>
      
        </tr>

      </tbody>
    </table>
    <!--// Talep Genel Bilgiler-->

    <!--Talep Detayı-->
    <div style="background-color: #f2f2f2ab; padding: 15px;" >
      <b>{{ 'SATINALMATALEP.TALEPDETAY' | translate }} </b>
      <table class="table table-striped table-sm custom-table table-bordered">
        <thead>
          <th width="300">{{ 'SATINALMATALEP.BIRIM1' | translate }}</th>
          <th width="150">{{ 'SATINALMATALEP.BIRIM2' | translate }}</th>
          <th width="200">{{ 'SATINALMATALEP.BIRIM3' | translate }}</th>
        </thead>
        <tbody>
          
          <tr *ngFor="let talep_item of talepDetay?.talep_item">
            <td width="300">{{talep_item?.product_name}} </td>
            <td>{{talep_item?.quantity}} {{convert.getBirim(talep_item?.product_unit)}}</td>
            <td width="200">
              {{talep_item.note}}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <!--// Talep Detayı-->

    <!--Talep İl İlgilenenler-->
    <div style="background-color: #f2f2f2ab; padding: 15px;">
      <b>{{ 'SATINALMATALEP.TALEPLE' | translate }}</b>
      <table class="table table-striped table-sm custom-table table-bordered">
        <thead>
          <tr>
            <th scope="col">{{ 'SATINALMATALEP.ADI' | translate }} </th>
            <!-- <th scope="col">{{ 'SATINALMATALEP.SOYADI' | translate }} </th> -->
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let owned of talepDetay?.owned_by">
            <td>{{owned?.name}}</td>
            <!-- <td>{{owned.phone |imask :telefonKodu}}</td> -->
          </tr>

        </tbody>
      </table>
    </div>
    <!--// Talep ile ilgilenenler-->









    <div style="background-color: #f2f2f2ab;padding: 15px; width: 1280px;overflow: scroll;" *ngIf="talepDetay?.teklifs">
      <b>Gelen Teklifler</b>

    <table class=" table-striped  table table-bordered table-sm" style="width: 1500px;">
      <tr>
          <th scop="col" width="300" >{{ 'TEKLIFLER.URUN' | translate }}</th>
          <th  width="400" *ngFor="let item of talepDetay?.teklifs?.firmalar; let index = index;">
              <div class="d-flex justify-content-between">
                  <div>
                      {{item?.name}} 
                      <button *ngIf="item?.dosya" nbButton status="info" size="tiny" (click)="teklifDownload(item?.dosya?.id)" [nbSpinner]="dosyaSpinner">Teklif Dosyasını İndir</button>
                  </div>
                  <div>
                      <!-- <button #popover nbButton size="tiny" [nbPopover]="userRef"
                          *ngIf="!item?.is_approved">
                          <nb-icon icon="more-vertical-outline"></nb-icon>
                      </button> -->
                      <button nbButton disabled size="small" *ngIf="item?.is_approved"
                          status="success" class="onayli"
                          style="background-color: #00d68f !important; border-color: #00d68f !important; color: #ffffff !important;">{{
                          'TEKLIFLER.TEKLIFONAYLANDI' | translate }}</button>

                      <ng-template #userRef>
                          <nb-card style="margin-bottom: 0px; border-top-width: 0rem !important;">
                              <nb-card-body>
                               
<!-- 
                                  <div class="col-md-12">
                                      <button nbButton size="tiny" outLine status="primary"
                                          (click)="onaylaTeklifItem(item);" style="width: 180px;"
                                          *ngIf="!item?.is_approved">
                                          {{'GELENTEKLIFLERLIST.ONAYLA'|translate}}
                                      </button><br>

                                      <button nbButton size="tiny" outLine status="info"
                                          style="width: 180px;"
                                          (click)="teklifiGuncelle(row, item);"
                                          *ngIf="item?.can_edit">
                                          {{'GELENTEKLIFLERLIST.GUNCELLE'|translate}}
                                      </button><br>

                                     <button nbButton size="tiny" outLine status="warning"
                                          style="width: 180px;" (click)="teklifiSil(item.teklif_veren_id,row.id)"
                                          *ngIf="item?.can_edit">
                                          {{'GELENTEKLIFLERLIST.SIL'|translate}}
                                      </button><br>


                                  </div> -->
                              </nb-card-body>
                          </nb-card>
                      </ng-template>
                  </div>
              </div>
          </th>


      </tr>
      <tbody>
          <tr *ngFor="let item of talepDetay?.talep_item; let i = index;">
              <td width="300" >{{item?.product_name}} </td>
              <td *ngFor="let row of talepDetay?.teklifs?.firmalar">
                  <div class="row pr-3 pl-3">
                      <ng-container *ngFor="let firmaItem of row?.teklifler;">

                          <div class="col-11">                      
                              <strong>
                                  {{firmaItem?.unit_price| number :'0.2-2' }}
                                  {{getMoneyCurrency(firmaItem?.amount_currency)}}
                              </strong> x {{firmaItem?.teklif_amount}}
                              {{firmaItem?.unit ?
                              convert.getBirim(firmaItem?.unit)
                              : '-'}}
                              =
                              {{ firmaItem?.unit_price *
                              firmaItem?.teklif_amount}}
                              {{getMoneyCurrency(firmaItem?.amount_currency)}}
                              <span
                                  *ngIf="firmaItem?.brand">({{firmaItem?.brand?.title}})</span>
                          </div>
                          <div class="col-1" [nbPopover]="historyRef" [nbPopoverTrigger]="'click'"
                              (click)="getHistory(firmaItem?.identifier)">
                              <nb-icon icon="question-mark-circle-outline"></nb-icon>
                          </div>
                          <div class="col-12" style="color: blue;">
                              {{firmaItem?.note ? firmaItem?.note : '-'}}
                          </div>
                      </ng-container>
                  </div>

                  <ng-template #historyRef>
                      <nb-card class="m-header" style="height:auto; width: 400px;"
                          [nbSpinner]="isHistoryLoading">
                          <div class="row" *ngFor="let item of historyList; let i = index">
                              <div [class]="i%2 == 0 ? 'col bg-lightgray': 'col'">
                                  <div class="row">
                                      <div class="col-6">
                                          {{'GELENTEKLIFLERLIST.GUNCELLEYEN'|translate}}</div>
                                      <div class="col-6">{{item?.updated_by}}</div>
                                  </div>
                                  <div class="row">
                                      <div class="col-6">
                                          {{'GELENTEKLIFLERLIST.GUNCELLEMEZAMANI'|translate}}
                                      </div>
                                      <div class="col-6">
                                          {{item?.update_time * 1000 | date : tarihFullFormat}}
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-6">
                                          {{'GELENTEKLIFLERLIST.TEKLIFFIYATI'|translate}}
                                      </div>
                                      <div class="col-6">
                                          {{item?.unit_price}}
                                          {{getMoneyCurrency(item?.amount_currency)}}
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-6">
                                          {{'GELENTEKLIFLERLIST.TEKLIFMIKTARI'|translate}}
                                      </div>
                                      <div class="col-6">
                                          {{item?.teklif_amount}}
                                          {{convert.getBirim(item?.product_unit)}}
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col"
                                          style="width: 1px; background-color: black;">

                                      </div>
                                  </div>
                              </div>
                          </div>
                      </nb-card>
                  </ng-template>




              </td>
          </tr>

          <tr>
              <td colspan="1" class="text-right"><b>{{ 'TEKLIFLER.TOPLAM' | translate }}
                  </b></td>

               
              <td *ngFor="let item of talepDetay?.teklifs?.firmalar">
                  <button nbButton size="tiny" class="try" status="warning">
                      TRY:{{ toplamTutarHesapla(item).TL | number :'0.2-2'}}
                  </button>
                  <button nbButton status="warning" size="tiny" class="dolar">
                      {{'GELENTEKLIFLERLIST.DOLAR'|translate}}{{ toplamTutarHesapla(item).USD | number :'0.2-2'}}
                  </button>
                  <button nbButton status="warning" size="tiny" class="euro">
                      EURO: {{ toplamTutarHesapla(item).EUR | number :'0.2-2'}}
                  </button>
              </td>  

          </tr>

          <tr>
              <td colspan="1" class="text-right">
                  <b>{{ 'TEKLIFLER.ISLEMLER' | translate }}</b>
              </td>
              <td *ngFor="let item of talepDetay?.teklifs?.firmalar">
                  <div class="row">
                      <div class="col-12">
                          <div class="row">
                              <div class="col-4">
                                  <b>{{ 'TEKLIFLER.TEKLİFALAN' | translate }}</b>
                              </div>
                              <div class="col-8">
                                  {{item?.created_by_name}}
                              </div>

                          </div>
                      </div>
                      <div class="col-12">
                          <div class="row">
                              <div class="col-4">
                                  <b>{{'GELENTEKLIFLERLIST.TEKLIFTARIHI'|translate}} </b>
                              </div>
                              <div class="col-8">
                                  {{item?.date | date:tarih_format}}

                              </div>
                          </div>
                      </div>
                  </div>
              </td>
          </tr>


      </tbody>
  </table> 
</div>
  </nb-card-body>
 
</nb-card>