<nb-card class="cardExcel modalType animated fadeInRight">
    <nb-card-header class="excellHeader">
        <span class="baslik">
            <i class="fas fa-file-excel"></i>
            Personel Listesini İçe Aktar
        </span>
        <nb-icon class="linked-div closeIcon" icon='close-outline' (click)="close()">
        </nb-icon>
    </nb-card-header>

    <nb-card-body>


        <nb-card class="ex1">
            <nb-card-body>
                Eğer mevcut personelleri tek tek tanımlamak uzun sürecekse
                <button nbButton (click)="getExampleExcel()" size="small" class="excel_button"
                    [nbSpinner]="exampleExcelLoading" [disabled]="exampleExcelLoading">
                    <nb-icon icon="arrow-downward-outline"></nb-icon>
                    {{exampleExcelLoading ? 'Excel Şablonu Hazırlanıyor. Bu İşlem Bir Kaç Dakika Sürebilir.' : 'Excel Şablonu İndir' }}
                </button>
                düğmesini tıklayarak şablon dosyasını bilgisayarınıza indirin.
            </nb-card-body>
        </nb-card>

        <nb-card class="ex1">
            <nb-card-body>
                Mevcut personelleri şablon dosyasına kaydettikten sonra, aşağıdaki
                <button nbButton size="small" status="primary" [nbSpinner]="isLoading" (click)="openFile(fileInput)">
                    <nb-icon icon="arrow-upward-outline"></nb-icon> Excel Şablonu Yükle
                </button>
                <input class="dosyaEkleInput" #fileInput type="file" [accept]="'xls, xlsx'"
                    (change)="openFileDialog($event)">

                düğmesi ile sisteme yükleyin.
            </nb-card-body>
        </nb-card>


        <nb-accordion>
            <nb-accordion-item style="background: #ceffe8;" [expanded]="true">
                <nb-accordion-item-header><b class="text-danger"><i class="fas fa-info-circle"></i> Önemli Not</b>
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                    <li>Şablon üzerindeki kolonları değiştirmeyin.</li>
                    <li>Meslek sütununda personelin mesleğini seçiniz.İşaretlediğiniz mesleği daha sonra güncellemeniz
                        mümkün.</li>
                    <li>Telefon ve Kimlik no zurunlu alanlardır.</li>
                    <li>Telefon numaralarını başında 0 olmadan giriniz.</li>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>

    </nb-card-body>

</nb-card>