import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-takvim-is-programi-detay',
  templateUrl: './takvim-is-programi-detay.component.html',
  styleUrls: ['./takvim-is-programi-detay.component.scss']
})
export class TakvimIsProgramiDetayComponent implements OnInit {
  @Input() item: any;

  length: number = 30;

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  tamamlananColor = '#7aa3e5';
  ongorulenColor = '#a95963';
  bekleyenColor = '#aae3f5';
  colorScheme = {
    domain: [this.bekleyenColor, this.ongorulenColor, this.tamamlananColor, '#AAAAAA'],
  };


  constructor(
    private router: Router,
    private ref: NbDialogRef<TakvimIsProgramiDetayComponent>,
  ) { }

  ngOnInit(): void {
    this.setItemKeys();
  }

  setItemKeys() {
      this.item.completed = Number((this.item.completed).toFixed(2));
      this.item.should_completed = Number((this.item.should_completed).toFixed(2));
      const tamamlanan = this.item.completed;
      let ongorulen = this.item.should_completed;
      const tamami = 100 - ongorulen;
      ongorulen = ongorulen - tamamlanan;
      // Tamamı 100 - tamamlanan 30 - öngörünel 70
      // ongorulen = ongululen - tamamlanan // 40
      this.item.data = [
        {
          name: 'Tamamı',
          value: tamami,
        },
        {
          name: 'Öngörülen',
          value: ongorulen,
        },
        {
          name: 'Tamamlanan',
          value: tamamlanan,
        },
      ];
  }

  goToGantt() {
    this.router.navigate([`pages/santiye/gantt/${this.item.santiye_id}`], {state: {pursantajId: this.item.item_id}});
    this.close();
  }

  close() {
    this.ref.close();
  }
}
