import { Injectable } from '@angular/core';
import { ApasAlertOptions } from '../models/apas-alert.model';
import { NbDialogService } from '@nebular/theme';
import { ApasAlertComponent } from '../apas-alert/apas-alert.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApasAlertServiceService {

  close$ = new Subject<any>();
  update$ = new Subject<ApasAlertOptions>();
  showValidationMessage$ = new Subject<any>();

  defaultOptions: ApasAlertOptions = {
    title: 'Başlık',
    text:
      'Ahmet Pekmezci tarafından Zelal Özge Mimarlık firması' +
      'ile Keras Otel işinde 1 numaralı hakedişi başlatıldı.onaylıyor musunuz?',
    fileButtonText: 'Oku',
    cancelButtonText: 'Onaylama',
    confirmButtonText: 'Onayla',
    showConfirmButton: true,
    showFileButton: true,
    showDenyButton: true,
    icon: 'question',
  };
  tag: string = 'ApasAlertServiceService --->';

  constructor(private nbDialogService: NbDialogService) { }

  async fire(options?: ApasAlertOptions): Promise<any> {
    const myOptions: ApasAlertOptions = options ? {
      ...this.defaultOptions,
      ...options,
    } : this.defaultOptions;
    return await this.nbDialogService.open<ApasAlertComponent>(ApasAlertComponent,
      { context: { options: myOptions } }).onClose.toPromise();
  }

  close() {
    this.close$.next(true);
  }
  update(options: ApasAlertOptions) {
    this.update$.next(options);
  }

  showValidationMessage(message: string) {
    console.log(this.tag, 'message:', message);
    this.showValidationMessage$.next(message);
  }



}
