import { ViewChild } from '@angular/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogService, NbDialogRef, NbTabsetComponent } from '@nebular/theme';
import { SantiyeModel } from '../../../../models/santiye_model';
import { SantiyeImgListComponent } from '../santiye-img-list/santiye-img-list.component';
export enum SantiyePhotoType {
  'List' = 'list',
  'Add' = 'add',
}
@Component({
  selector: 'santiye-photo',
  templateUrl: './santiye-photo.component.html',
  styleUrls: ['./santiye-photo.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SantiyePhotoComponent implements OnInit {
  @ViewChild(NbTabsetComponent)
  myTab!: NbTabsetComponent;
  @ViewChild(SantiyeImgListComponent)
  santiyeImgListComponent!: SantiyeImgListComponent;


  santiyeId: number;
  santiye: SantiyeModel;

  santiyeName: string;
  tapTaype = SantiyePhotoType;
  constructor(private sanitizer: DomSanitizer,
    private ref: NbDialogRef<SantiyePhotoComponent>) { }

  ngOnInit() {
  }

  closed() {
    this.ref.close();
  }
  cahangeTab(tab: SantiyePhotoType) {
    this.myTab.selectTab(this.myTab.tabs.find(f => f.tabId === tab));
  }
  successUploads() {
    this.cahangeTab(SantiyePhotoType.List);
    this.santiyeImgListComponent.refresh();
  }
}
