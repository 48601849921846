import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../pages/global-functions';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { PersonelEkleFirmaComponent } from '../../personel/personel-ekle-firma/personel-ekle-firma.component';
import { PersonelGrubuOlusturComponent } from '../../personel/personel-grubu-olustur/personel-grubu-olustur.component';
import { PersonelSelectService } from './personel-select.service';

@Component({
  selector: 'personel-select',
  template: `<ng-select
              #select
              [items]="personelList$ | async"
              [loading]="isLoading"
              appendTo="body"
              [typeahead]="personelInput$"
              typeToSearchText="Arama yapmak için yazınız."
              bindLabel="name"
              [bindValue]="bindValue"
              [addTag]="false"
              [groupBy]="multiple ? 'group' : null"
              [selectableGroup]="true"
              [selectableGroupAsModel]="false"
              [multiple]="multiple"
              [selectOnTab]="true"
              [formControl]="control"
              [placeholder]="'Personel Seç'"
              [closeOnSelect]="!multiple"
              class="uzun-select"
              ngDefaultControl
              (change)="personelSelected($event)"
          >

      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
          <button nbButton size="small" status="primary" hero (click)="addNewPersonel(select)"> Personel Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="grupOlsutur()" *ngIf="multiple"> Personel Grubu Ekle  </button>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>`
  ,
  styleUrls: ['./personel-select.component.scss'],
  providers: [
    PersonelSelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonelSelectComponent),
      multi: true,
    },
  ],
})
export class PersonelSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  isLoading: boolean = false;
  @Input() bindValue: string = 'id';
  @Input() is_active: boolean = true;
  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() showAddButtons: boolean = true;
  @Input() personelTypes = 'is_active'; // is_active, yetkili, yektisiz, pasif
  @Input() url: string = 'organization/newpersonellist/';
  @Input() santiyeId: number; // iş güvenliği için eklendi. alternatif bir çözüm üretmekte fayda olabilir.
  constructor(
    private personelSelectService: PersonelSelectService,
    injector: Injector,
    private nbDialogService: NbDialogService,
  ) {
    super(injector);
    this.firstList();
  }

  @Input() forceRefresh: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.firstList();
    if (changes?.forceRefresh) {
      this.getOrFindPersonel(this.control.value);
    }

    if (changes?.santiyeId) {
      this.getPersonel();
    }
  }

  ngOnInit(): void {
    this.getPersonel();
  }

  personelSelected(event) {
    this.change.emit(event);
  }

  personelList$: Observable<any[]>;
  personelInput$ = new Subject<string>();
  getPersonel() {
    // console.log(this.tag, 'GETTIN NEW LIST ');
    const filtre = {};
    filtre[this.personelTypes] = true;
    
    this.personelList$ = concat(
      of([]), // default items
      this.personelInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.personelSelectService.personelList(
            removeAllNull({ search: term, filter: filtre, group: this.multiple, santiye_id: this.santiyeId }), this.url,
          ).pipe(map(data => {
            // Grouplu şekilde listeleniyorsa, en üstte grubu olanlar getirilir.
            if (this.multiple) {
              data.sort((a, b) => {
                return b.group ? 1 : -1;
              });
            }
            return data;
          }),
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getPersonel();
    setTimeout(() => {
      this.personelInput$.next('');
    }, 300);
  }

  addNewPersonel(select: NgSelectComponent) {
    select.close();
    this.nbDialogService.open(PersonelEkleFirmaComponent, { closeOnBackdropClick: false });
  }

  grupOlsutur() {
    this.nbDialogService.open(PersonelGrubuOlusturComponent, { closeOnBackdropClick: false });
  }

  async getOrFindPersonel(instanceId) {
    // Ahmet Abi yazacağın fonksiyon isimlerini sevsinler.
    // Eğer Id'ye kayıtlı bir ürün elimdeki listede mevcutsa, sıkıntı yok,
    // Eğer mevcut değilse, bi zahmet request atıp elimdeki listeye pushlayayım.
    const found = await this.personelList$.map(async (data) => {
      console.log('Found : ');
      // tslint:disable-next-line: triple-equals
      return await data.find(x => x[this.bindValue] == instanceId);
    });
    console.log('devam');
    if (!found) {
      this.personelSelectService.getPersonelDetail(instanceId).subscribe(data => {
        this.personelList$ = of([data]);
      });
    }
  }

}
