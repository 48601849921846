import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal from 'sweetalert2';
import { ApasImageUploaderComponent } from '../../../Apas-Modules/apas-image-uploader/apas-image-uploader.component';

export enum NoteType {
  'Liste' = 'list',
  'Item' = 'item',
}

@Component({
  selector: 'notes-modal',
  templateUrl: './yapilan-isler-add-photo.component.html',
  styleUrls: ['./yapilan-isler-add-photo.component.scss'],
  providers: [ApasImageUploaderComponent],
})


export class YapilanIslerAddPhotoComponent implements OnInit {
  contentId: number;
  noteTypes = NoteType;
  modul: string;
  tip = NoteType.Item;
  showPublic: boolean = false;
  form: FormGroup;

  @Input() santiyeId: number;
  @Output() onSuccessUploads: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ApasImageUploaderComponent) files!: QueryList<ApasImageUploaderComponent>;
  tag: string = 'santiye-img-upload --->';
  currentFiles: File[] = [];
  targetUrl = 'santiye/imageupload/';

  constructor(private fb: FormBuilder,
    private ref: NbDialogRef<YapilanIslerAddPhotoComponent>) {
    this.initForm();
  }

  ngOnInit() {
    if (this.modul) {
      this.targetUrl = `${this.modul}/${this.contentId}/note/create/`;
    } else {
      this.targetUrl = `${this.contentId}/note/create/`;
    }
  }

  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
  */
  openFile(fileInput) {
    fileInput.click();
  }
  /**
   * Resimler seçildikden sonra çalışan fonksiyon.
   * Seçilen resimleri daha önceden eklenen fotograf dizisine ekler.
   * başlangıcda liste de 0 tane eleman vardırç
   * @param currentFiles currentFiles: File[] = [] olarak tanımlanır.
   * @param event Seçilen dosyaların listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  openFileDialog(event) {
    // uyumsus dosyaların ismleri burda toplanacak.
    let ignores: string = '';
    this.currentFiles = [...this.currentFiles, ...(Array.from((event.target as HTMLInputElement).files)
      .filter((file: File) => { // Seçilen tüm dosyalar arasdından uyumsuz olanalrını ayıklar
        // eğer bu dosya resim ise onu kabulet
        if (file.type.indexOf('image') >= 0) {
          return true;
        } else { // eğer resim değilse onu kabul etmez. ve ignores string listesine ekler.
          ignores += '<br><div style="padding: 5px;border-bottom: 1px solid #ebebeb;">' + file.name + '</div>';
          return false;
        }
      }))];
    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (ignores.length > 0) {
      Swal.fire({
        icon: 'error', title: 'Lütfen sadece uyumlu (jpeg, png) resim dosyalarını seçiniz.',
        html: '<div style="color: #f27474;text-align: left !important;">'
          + ignores + '</div>', showConfirmButton: true,
      });
    }
  }
  /**
   * Dosya sistemden başarılı birşekilde gösterildiğinde tetiklenir.
   */
  successLoad() {
    console.log(this.tag, 'loaded');
  }
  /**
   * Resim sunucuya başarılı bir şekilde yüklendiğinde çalışır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  success(event: ApasImageUploaderComponent, i) {
    // console.log(this.tag, 'Resim başarılı birşekilde yüklendi', event);
    if (!this.files.some(f => f.isSuccess === false)) {
      this.ref.close();
      Swal.fire({
        icon: 'success',
        title: 'İşlem başarılı',
        html: 'Tüm resimler başarılı birşekilde yüklendi',
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.onSuccessUploads.emit(null);
        this.currentFiles = [];
      });
    }
  }
  /**
   * Seçilen resmi listeden kaldırır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  remove(event, i) {
    // console.log(this.tag, 'Resim başarılı bir şekilde silindi:', event, 'index:', i);
    this.currentFiles = this.currentFiles.filter((item, index) => index !== i);
  }
  /**
   * Tümresimleri zaten yüklenmemiş olan tüm resimleri sunucuya yükler.
   */
  uploadAll() {
    if (this.files) {
      this.files.forEach(f => {
        if (!f.isSuccess) { f.upload(); }
      });

    } else {
      throw new Error('@ViewChild files bulunamadı.');
    }
  }
  close() {
    this.ref.close();
  }
  get liste() { if (this.tip === NoteType.Liste) return this.form.get('liste') as FormArray; }
  initForm() {
    if (this.tip === NoteType.Item) {
      this.form = this.fb.group({
        title: [],
        note: [],
        is_public: [false],
        image: [],
      });
    } else {
      this.form = this.fb.group({
        liste: this.fb.array([]),
      });
      this.addItem();
    }
  }

  addItem() {
    const formItem = this.fb.group({
      title: [],
      note: [],
      is_public: [false],
      image: [],
    });

    this.liste.push(formItem);
  }


}
