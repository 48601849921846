<nb-card [class]="modal ? 'modalType animated fadeInRight':'h-100 border'" accent="primary"
    [ngStyle]="{'min-width': (modal ? '734px' : 'auto'), 'min-height' : (modal ? '500px' : 'auto')}">
    <nb-card-header>
        <div class="row d-flex align-items-center">
            <div class="col-xxl col-md">

                {{'SOZLESMELIST.DOSYALAR' | translate}}
            </div>
            <div class="col-xxl col-md d-flex justify-content-end align-items-center">
                <input nbInput [placeholder]="'MAP.ARA'|translate" (keyup)="search($event)">
                <button nbButton (click)="newFile()" status="warning" size="medium" style="float: right;">Yeni
                    Ekle</button>
                <button nbButton (click)="close()" *ngIf="modal" size="medium" class="heryerdeClose"
                    style="float: right;">X</button>
            </div>

        </div>
    </nb-card-header>
    <nb-card-body class="p-0 m-0 w-100" [nbSpinner]="isLoading">
        <!--Dosya Listele-->

        <nb-card style="border:unset;">
            <nb-card-header style="position: relative !important; top: 0 !important;" class="p-0 py-2">
                <div class="row m-0 p-0">
                    <div class="col-md-3">
                        {{'PUANTAJDETAY.TARIH'|translate}}
                    </div>
                    <div class="col-md">
                        {{'HAKEDISCUSTOMIDARIHAKEDIS.DOSYAADI'|translate}}
                    </div>
                    <!-- <div class="col-md">
                  {{'APASIMAGELIGHTBOX.ACIKLAMA'|translate}}
                </div> -->
                    <div class="col-md-3 justify-content-end d-flex">
                        İşlemler
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body class="p-0">

                <nb-list *ngIf="rows && rows?.length > 0" class="row p-0 m-0 w-100">
                    <nb-list-item class="aps-hover p-0" *ngFor="let row of rows;let i = index;"
                    nbInfiniteList listenWindowScroll [threshold]="100" (bottomThreshold)="goToNextPage()">

                        <div class="col-md-3">
            <tarih-col class="w-100" [index]="i" [tarih]="row?.created_time"></tarih-col>

                        </div>
                        <div class="col-md">
                            <div class="d-flex">
                                <div *ngIf="row.extension" style="float: left; margin-right: 8px; font-size: large;">
                                    <div [ngSwitch]="row.extension">
                                        <i style="color: #035798;" class="far fa-file-word" *ngSwitchCase="'doc'"></i>
                                        <i style="color: #035798;" class="far fa-file-word" *ngSwitchCase="'docx'"></i>
                                        <i style="color: #43A047;" class="far fa-file-excel" *ngSwitchCase="'xls'"></i>
                                        <i style="color: #43A047;" class="far fa-file-excel" *ngSwitchCase="'xlsx'"></i>
                                        <i style="color: #03A9F4;" class="far fa-file-image" *ngSwitchCase="'jpg'"></i>
                                        <i style="color: #03A9F4;" class="far fa-file-image" *ngSwitchCase="'jpeg'"></i>
                                        <i style="color: #03A9F4;" class="far fa-file-image" *ngSwitchCase="'png'"></i>
                                        <i class="far fa-file-alt" *ngSwitchCase="'txt'"></i>
                                        <i style="color: #e57373;" class="far fa-file-pdf" *ngSwitchCase="'pdf'"></i>
                                        <img src="../../../../assets/images/dwg_icon.png" alt="dwg"
                                            style="height: 18px; width: 13.5px;" *ngSwitchCase="'dwg'">
                                        <img src="../../../../assets/images/dwg_icon.png"
                                            style="height: 18px; width: 13.5px;" *ngSwitchCase="'dxf'">
                                        <img src="../../../../assets/images/dwg_icon.png"
                                            style="height: 18px; width: 13.5px;" *ngSwitchCase="'dwf'">

                                    </div>
                                </div>
                                <span style="margin-top: auto; margin-bottom: auto;" [nbTooltip]="row?.note">
                                    {{row?.title}} </span>
                            </div>
                        </div>

                        <!-- <div class="col-md" [nbTooltip]="row?.note">
                          {{ row?.note | slice:0:60 }} {{row?.note?.length > 60 ? '...':''}}
                        </div> -->
                        <div class="col-md-3 justify-content-end d-flex">
                            <button [disabled]="row?.isDownloading" (click)="downLoadFile(row?.id,row)" size="tiny"
                                nbButton [nbTooltip]="'Görüntüle'" status="info">
                                <nb-icon icon="file-outline"></nb-icon>
                                <b *ngIf="row?.isDownloading && row?.percentDone">
                                   {{row?.isDownloading?'%'+row?.percentDone: (row.size ? (row?.size | filesize) : null)}} 
                                </b>
                            </button>
                            <button [disabled]="row?.isDownloading" *ngIf="row?.can_delete" (click)="upDateFile(row)"
                                size="tiny" nbButton [nbTooltip]="'Düzenle'" status="warning" >
                                <nb-icon icon="edit-2-outline"></nb-icon>
                            </button>
                            <button [disabled]="row?.isDownloading" *ngIf="row?.can_delete" (click)="deleteFile(row)"
                                size="tiny" nbButton [nbTooltip]="'Sil'" status="danger" class="silButon">
                                <nb-icon icon="trash-outline"></nb-icon>
                            </button>
                        </div>


                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
        <!--// Dosya Listele-->
    </nb-card-body>
</nb-card>