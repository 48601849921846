<nb-card class="modalType animated fadeInRight" size="giant" style="width: 600px;">
  <nb-card-header>

    <button nbButton status="basic" class="m-close no-focus linked-div" (click)="closed()">X</button>
  </nb-card-header>
  <nb-card-body [formGroup]="urunCikisForm" [nbSpinner]="isSubmitting">
    <!---->
    <div class="form-group">
      <label><b>{{ 'DEPO.URUNADI' | translate }}</b></label>
      <div [nbTooltip]="this.urun.name" nbTooltipPlacement="top" nbTooltipStatus="success"><input type="text" nbInput
          fullWidth status="basic" disabled [value]="this.urun.name" class="m-input"></div>
    </div>
    <div>
      <div class="form-group">
        <label><b>{{ 'DEPO.MARKA' | translate }}</b></label>
        <ng-select class="ng-select-without-bottom-margin" [items]="allUrunMarka" bindLabel="brand_name" bindValue="id"
          [placeholder]="'DEPO.MARKA' | translate" appendTo="body" [selectOnTab]="true" [clearable]="false"
          (change)="selectMarka($event)" formControlName="link_to_own_model">
        </ng-select>
      </div>
      <div
        *ngIf="urunCikisForm.get('link_to_own_model').touched && urunCikisForm.get('link_to_own_model').hasError('required')"
        class="uyari" style="position: relative; top: -18px;">
        {{ 'DEPO.UYARI' | translate }}
      </div>
    </div>
    <!--Teslim Eden-->
    <div class="form-group">
      <label><b>{{ 'DEPO.TESLIMEDEN' | translate }}</b></label>
      <input type="text" nbInput fullWidth status="basic" disabled value="{{name}}" class="m-input">
    </div>
    <!--//Teslim Eden-->

    <!--Teslim alan-->
    <div>
      <div class="form-group">
        <label><b>{{ 'DEPO.TESLIMALAN' | translate }}</b></label>
        <ng-select class="ng-select-without-bottom-margin" (change)="selectedDeliveryPersonel($event)" [addTag]="true"
          formControlName="delivery_by_apas_id" bindLabel="name" bindValue="apas_id"
          [placeholder]="'DEPO.TESLIMALAN' | translate" [items]="allPersonel" appendTo="body">
        </ng-select>
      </div>
      <div
        *ngIf="urunCikisForm.get('delivery_by_name').touched && urunCikisForm.get('delivery_by_name').hasError('required')"
        class="uyari" style="position: relative; top: -18px;">
        {{ 'DEPO.UYARI' | translate }}
      </div>
    </div>
    <!--//Teslim Alan-->
    
    <!--Miktar -->
    <div class="form-group">
      <label><b>{{ 'DEPO.MIKTAR' | translate }}</b></label>
      <input type="text" nbInput paraDirective [encrypt]="false" [decrypt]="false" [plcholder]="'DEPO.MIKTAR'|translate"
        fullWidth status="basic" formControlName="amount" apasInvalid>
    </div>
    <!--Miktar -->

    <!--Açıklama-->
    <div class="form-group">
      <label><b>{{ 'DEPO.ACIKLAMA' | translate }}</b></label>
      <textarea nbInput fullWidth formControlName="note"></textarea>
    </div>
    <div class="text-center">
      <button nbButton status="success" size="medium" (click)="urunCikisYap()">{{ 'DEPO.CIKIS' | translate }}</button>
    </div>
  </nb-card-body>
</nb-card>