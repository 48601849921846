import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { EntegrasyonService } from '../../../services/entegrasyon.service';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { SetOrgInfo } from '../../../store/organization/organization.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { sehirAndIlce } from '../../../pages/resources/il-ilce';

@Component({
  selector: 'ngx-firma-bilgi-guncelle',
  templateUrl: './firma-bilgi-guncelle.component.html',
  styleUrls: ['./firma-bilgi-guncelle.component.scss'],
  providers: [EntegrasyonService],
})
export class FirmaBilgiGuncelleComponent implements OnInit {
  form: FormGroup;
  sehirAndIlce = sehirAndIlce;
  ilceler;
  vergiDaireleri;
  constructor(
    private organizationService: OrganizasyonService,
    private fb: FormBuilder,
    private entegrasyonService: EntegrasyonService,
    private ref: NbDialogRef<FirmaBilgiGuncelleComponent>,
    private store: Store<{ OrgInfo, Lang, Redirect }>,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.firmaInfo();
    this.vergiDaireList();

  }

  initForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      resmi_unvan: [null, Validators.required],
      tax_office: [null, Validators.required],
      tax_no: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, Validators.required],
      address: [null, Validators.required],
      city: [null, Validators.required],
      district: [null, Validators.required],
    });
  }

  firmaInfo() {
    this.organizationService.getCurrentOrganizationInformation().subscribe(data => {

      if (!data.resmi_unvan) {
        setTimeout(() => {
          this.form.get('resmi_unvan').setValue(data.name);
        }, 500);
      }
      if (data.phone) {
        data.phone = data.phone.slice(2);

      }
      if (data.city) {
        this.ilceSec(data.city);
      }
      this.form.patchValue(data);

    });
  }

  ilceSec(event) {
    const sehirData = this.sehirAndIlce;
    this.ilceler = sehirData.find(x => x.city === event);
    this.ilceler = this.ilceler.ilceler;
  }
  selectSehir(event) {
    this.form.get('district').setValue(null);
    this.ilceler = event.ilceler;
  }
  unvanBul(event) {
    this.entegrasyonService.getGibUser(event.target.value).subscribe(resp => {
      this.form.get('resmi_unvan').setValue(resp.unvan);
    });
  }


  vergiDaireList() {
    this.entegrasyonService.getVergiDairesi().subscribe(data => {
      this.vergiDaireleri = data;
    });
  }



  close(success?) {
    this.ref.close(success);
  }
  saveLoad = false;
  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.saveLoad = true;
    const firmaData = this.form.value;
    this.organizationService.orgUpdate(firmaData).subscribe((data) => {
      localStorage.setItem('org', JSON.stringify(data));
      const orgInfoGuncelle = JSON.parse(localStorage.getItem('org'));
      this.store.dispatch(SetOrgInfo({ ORG: orgInfoGuncelle }));
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => {
        this.saveLoad = false;
        this.close(true);
      });

    }, (err: HttpErrorResponse) => { 
      this.saveLoad = false;

      if(err.error.email){ 
        Swal.fire({ ...responseKodlari.unSuccessProgress, ...{ text: 'E-mail Hatalı' } } as SweetAlertOptions); 
        return;
      }
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

    });
  }

}


