import { Pipe, PipeTransform } from '@angular/core';
import { encrypte, inputMaskTemizle } from '../pages/global-functions';
@Pipe({
  name: 'apasencrypte',
})

export class ApasEncryptePipe implements PipeTransform {
  transform(raw_text: any, temizle?: boolean) {
    /**
     * @params: raw_text şifrelenmesi istenilen metin, 
     * temizle ? : zorunlu değil ve eğer true olarak belitrilitse, gelen raw_text'i para mask temizle işleminden geçirir.
     */

    if (temizle) {
      raw_text = inputMaskTemizle(raw_text);

      if (!raw_text) {
        return null;
      }
    }
    raw_text = raw_text.toString();
    const encrypted = encrypte(raw_text);
    return encrypted;
  }
}
