import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromCari from '../muhasebe-store/cari/cari.connector';
import * as fromAlisSatis from '../muhasebe-store/alis-satis/alis-satis.connector';
import * as cariActions from '../muhasebe-store/cari/cari.actions';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CariModel } from '../models/cari.model';
import { CariHareketModel } from '../models/cari_hakeret.model';
import { ExchangesModel } from '../models/exchanges.model';
import { getOrgInfo } from '../../../store/organization/organization.connector';
import { ornekFaturaSablon } from '../../constants';
import { removeAllNull } from '../../global-functions';
import { MuhasebeTabType } from '../muhasebe-main/apasx-main.component';
import { HesapGroupModel, HesapModel } from '../muhasebe-main/muhasebe-hesaplar/hesap.model';


@Injectable()
export class MuhasebeService {

    private readonly API_URL = `${environment.backendurl}api/muhasebe/`;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private store: Store<{ Cari }>) { }

    navigate(tab: MuhasebeTabType) {
        this.router.navigate(['pages/muhasebe/main'], {
            state: {
                tab: tab,
            },
        });
    }

    getAccountList(data?): Observable<HesapGroupModel> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.get<HesapGroupModel>(url);
    }

    searchAccount(data): Observable<HesapModel[]> {
        const url: string = `${this.API_URL}searchaccount/`;
        return this.httpClient.post<HesapModel[]>(url, data);
    }

    createAccount(data): Observable<HesapModel> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.post<HesapModel>(url, data);
    }

    updateAccount(instanceID, data): Observable<HesapModel> {
        const url: string = `${this.API_URL}update/${instanceID}/`;
        return this.httpClient.patch<HesapModel>(url, data);
    }

    getAccountDetails(kasaId: number, filtre?: any): Observable<HesapModel> {
        const url: string = `${this.API_URL}detail/${kasaId}/`;

        let data;
        if (filtre) {
            data = filtre;
        } else {
            data = '';
        }
        return this.httpClient.post<HesapModel>(url, data);
    }
    getYetkiList(): Observable<any> {
        const url: string = `${this.API_URL}auth/`;
        return this.httpClient.get<any>(url);
    }

    getCariList(filtre): Observable<CariModel[]> {
        filtre = removeAllNull(filtre);
        const url: string = `${this.API_URL}carilist/`;
        return this.httpClient.post<CariModel[]>(url, filtre);
    }

    getCariDetay(cariId): Observable<CariModel> {
        console.log('Get Cari Detay : ', cariId);
        const url: string = `${this.API_URL}caridetay/${cariId}/`;
        return this.httpClient.get<CariModel>(url);
    }

    updateCari(cari_id, data): Observable<CariModel> {
        const url: string = `${this.API_URL}cariupdate/${cari_id}/`;
        return this.httpClient.patch<CariModel>(url, data);
    }

    setCariActive(cari_id, data): Observable<CariModel> {
        const url: string = `${this.API_URL}setcariactive/${cari_id}/`;
        return this.httpClient.patch<CariModel>(url, data);
    }

    getCariHareketList(data): Observable<CariHareketModel[]> {
        const url: string = `${this.API_URL}carihareketlist/`;
        return this.httpClient.post<CariHareketModel[]>(
            url,
            data,
        );
    }

    getCariHareketReport(data): Observable<CariHareketModel[]> {
        const url: string = `${this.API_URL}carireport/`;
        return this.httpClient.post<CariHareketModel[]>(
            url,
            data,
        );
    }

    addCariHareket(data): Observable<CariHareketModel> {
        const url: string = `${this.API_URL}addcarihareket/`;
        return this.httpClient.post<CariHareketModel>(
            url,
            data,
        );
    }
    getCrediDetail(data): Observable<any> {
        const url: string = `${this.API_URL}credidetay/`;
        return this.httpClient.post<any>(
            url,
            data,
        );
    }
    payCredi(instanceId, data): Observable<CariHareketModel> {
        const url: string = `${this.API_URL}paycredi/${instanceId}/`;
        return this.httpClient.patch<CariHareketModel>(
            url,
            data,
        );
    }

    updateCariHareket(instanceId, data): Observable<CariHareketModel> {
        const url: string = `${this.API_URL}carihareketetupdate/${instanceId}/`;
        return this.httpClient.patch<CariHareketModel>(
            url,
            data,
        );
    }

    deleteCariHareket(instanceId): Observable<CariHareketModel> {
        const url: string = `${this.API_URL}carihareketetdelete/${instanceId}/`;
        return this.httpClient.delete<CariHareketModel>(url);
    }

    getCariHareketDetailForCari(cariId, hareketId): Observable<CariHareketModel> {
        // Bir Carinin Bakışından, cari hareketin detayını taşır.
        const url: string = `${this.API_URL}carihareketcaridetail/${cariId}/${hareketId}/`;
        return this.httpClient.get<CariHareketModel>(url);
    }

    getCariHareketDetailForHesap(
        hesapId,
        hareketId,
    ): Observable<CariHareketModel> {
        // Bir Hesabın Bakışından, cari hareketin detayını taşır.
        const url: string = `${this.API_URL}carihareketcasedetail/${hesapId}/${hareketId}/`;
        return this.httpClient.get<CariHareketModel>(url);
    }


    getCariHareketDetail(id): Observable<CariHareketModel> {
        const url: string = `${this.API_URL}carihareketdetail/${id}/`;
        return this.httpClient.get<CariHareketModel>(url);
    }

    getCariHareketFile(id) {
        const url: string = `${this.API_URL}carihareketdetail/${id}/`;
        const req = new HttpRequest('GET', url);
        return this.httpClient.request(req);
    }

    getCekList(data): Observable<any> {
        const url: string = `${this.API_URL}newceklist/`;
        return this.httpClient.post<any>(url, data);
    }

    getCekFile(id) {
        const url: string = `${this.API_URL}getcekfile/${id}/`;
        const req = new HttpRequest('GET', url);
        return this.httpClient.request(req);
    }

    getCekHistory(instanceId) {
        const url: string = `${this.API_URL}getcekhistory/${instanceId}/`;
        return this.httpClient.get<any[]>(url);
    }

    addCekSenet(data): Observable<any> {
        const url: string = `${this.API_URL}addcek/`;
        return this.httpClient.post<any[]>(url, data);
    }

    updateCekSenet(cekId: number, data: any): Observable<any> {
        const url: string = `${this.API_URL}updatecek/${cekId}/`;
        return this.httpClient.patch<any>(url, data);
    }

    deleteCekSenet(cekId: number): Observable<any> {
        const url: string = `${this.API_URL}deletecek/${cekId}/`;
        return this.httpClient.delete<any>(url);
    }

    transferCek(data: any): Observable<any> {
        const url: string = `${this.API_URL}transfercek/`;
        return this.httpClient.post<any[]>(url, data);
    }

    giveCekTo(data: any): Observable<any> {
        const url: string = `${this.API_URL}cek/giveto/`;

        return this.httpClient.post<any>(url, data);
    }

    payCek(data: any): Observable<any> {
        const url: string = `${this.API_URL}paycek/`;
        return this.httpClient.post<any>(url, data);
    }
    collectCek(data: any) {
        const url: string = `${this.API_URL}collectcek/`;
        return this.httpClient.post<any>(url, data);
    }

    getCurrentExchageRates() {
        const url = 'https://canlidoviz.com/doviz-kurlari.jsonld';
        return this.httpClient.post(url, '');
    }

    postSatisData(data) {
        const url: string = `${this.API_URL}addsatis/`;
        // https://api.apasplustest.com/api/muhasebe/addsatis/
        return this.httpClient.post(url, data);
    }

    getMasrafList(filtre): Observable<CariHareketModel[]> {
        const url: string = `${this.API_URL}listmasraf/`;
        return this.httpClient.post<CariHareketModel[]>(
            url,
            filtre,
        );
    }

    getTaxList(): Observable<any[]> {
        const url: string = `${this.API_URL}listtax/`;
        return this.httpClient.get<any[]>(url);
    }

    addTax(data): Observable<any[]> {
        const url: string = `${this.API_URL}addtax/`;
        return this.httpClient.post<any[]>(url, data);
    }
    updateTax(instanceId, data): Observable<any[]> {
        const url: string = `${this.API_URL}updatetax/${instanceId}/`;
        return this.httpClient.patch<any[]>(url, data);
    }
    deleteTax(instanceId) {
        const url: string = `${this.API_URL}deletetax/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getExchageList(): Observable<ExchangesModel> {
        const url: string = `${this.API_URL}exchagelist/`;
        return this.httpClient.get<ExchangesModel>(url);
    }

    updateExchageList(data): Observable<ExchangesModel> {
        const url: string = `${this.API_URL}exchagelist/`;
        return this.httpClient.patch<ExchangesModel>(
            url,
            data,
        );
    }

    getAlisList(data): Observable<any> {
        const url: string = `${this.API_URL}listalis/`;
        return this.httpClient.post<ExchangesModel>(url, data);
    }

    addAlis(data): Observable<any> {
        const url: string = `${this.API_URL}addalis/`;
        return this.httpClient.post<ExchangesModel>(url, data);
    }

    updateAlis(instanceID, data): Observable<any> {
        const url: string = `${this.API_URL}updatealis/${instanceID}/`;
        return this.httpClient.patch<ExchangesModel>(
            url,
            data,
        );
    }
    deleteAlis(instanceID): Observable<any[]> {
        const url: string = `${this.API_URL}deletealis/${instanceID}/`;
        return this.httpClient.delete<any[]>(url);
    }

    getSatisList(data): Observable<any[]> {
        const url: string = `${this.API_URL}listsatis/`;
        return this.httpClient.post<any[]>(url, data);
    }

    addSatis(data): Observable<any> {
        const url: string = `${this.API_URL}addsatis/`;
        return this.httpClient.post<ExchangesModel>(url, data);
    }

    updateSatis(instanceID, data): Observable<any> {
        const url: string = `${this.API_URL}updatesatis/${instanceID}/`;
        return this.httpClient.patch<ExchangesModel>(
            url,
            data,
        );
    }

    deleteSatis(instanceID): Observable<any> {
        const url: string = `${this.API_URL}deletesatis/${instanceID}/`;
        return this.httpClient.delete<ExchangesModel>(url);
    }

    addAlisSatis(data): Observable<any> {
        const url: string = `${this.API_URL}addalissatis/`;
        return this.httpClient.post<ExchangesModel>(url, data);
    }

    getAlisDetail(id) {
        const url: string = `${this.API_URL}alisdetail/${id}/`;
        // Henüz Bir endpointi olmadığı için request atılamaz.
        return {};
    }

    getAlisFile(id) {
        const url: string = `${this.API_URL}getalisfile/${id}/`;
        const req = new HttpRequest('GET', url);
        return this.httpClient.request(req);
    }

    getIslemList(data): Observable<any[]> {
        const url: string = `${this.API_URL}islemlist/`;
        return this.httpClient.post<any[]>(url, data);
    }

    updateIslemHareket(data): Observable<any[]> {
        const url: string = `${this.API_URL}updateislem/`;
        return this.httpClient.patch<any[]>(url, data);
    }

    deleteIslemList(data): Observable<any> {
        const url: string = `${this.API_URL}deleteislem/`;
        return this.httpClient.post<any>(url, data);
    }

    getVergiReport(data): Observable<any[]> {
        const url: string = `${this.API_URL}getvergireport/`;
        return this.httpClient.post<any[]>(url, data);
    }

    deleteBankAccount(id: number): Observable<any> {
        const url: string = `${this.API_URL}deletebankacc/${id}/`;
        return this.httpClient.delete<any>(url);
    }

    deleteAccount(id: number): Observable<any> {
        const url: string = `${this.API_URL}account/delete/${id}/`;
        return this.httpClient.delete<any>(url);
    }

    deleteFatura(id: number): Observable<any> {
        const url: string = `${this.API_URL}/delete/${id}/`;
        return this.httpClient.delete<any>(url);
    }


    getCarisizList(data: any): Observable<any[]> {
        const url: string = `${this.API_URL}getcarisizlist/`;
        return this.httpClient.post<any[]>(url, data);
    }

    addNewCari(data: any): Observable<any> {
        const url: string = `${this.API_URL}addcari/`;
        return this.httpClient.post<any>(url, data);
    }

    cariFileList(data?: any): Observable<any> {
        const url: string = `${this.API_URL}/cari/file/list/`;
        return this.httpClient.post<any[]>(url, data);
    }

    cariFileDetail(instancId): Observable<any> {
        const url: string = `${this.API_URL}cari/file/detail/${instancId}/`;
        return this.httpClient.get<any>(url);
    }

    cariFileUpload(data): Observable<any> {
        const url: string = `${this.API_URL}cari/file/upload/`;
        return this.httpClient.post<any>(url, data);
    }

    cariFileUpdate(instanceId, data): Observable<any> {
        const url: string = `${this.API_URL}cari/file/update/${instanceId}/`;
        return this.httpClient.patch<any>(url, data);
    }

    cariFileDelete(instanceId): Observable<any> {
        const url: string = `${this.API_URL}cari/file/delete/${instanceId}/`;
        return this.httpClient.delete<any>(url);
    }

    factoreCek(data) {
        const url: string = `${this.API_URL}factorcek/`;
        return this.httpClient.post(url, data);
    }

    cancelCekPayment(data) {
        const url: string = `${this.API_URL}cancelcekpayment/`;
        return this.httpClient.post(url, data);
    }

    getSablonList(instance_id?) {
        const url: string = `${this.API_URL}faturasablon/${instance_id ? instance_id + '/' : ''}`;
        return this.httpClient.get(url);
    }

    addSablonItem() {
        const url: string = `${this.API_URL}faturasablon/`;
        return this.httpClient.post(url, { title: 'Fatura Şablon', content: ornekFaturaSablon });
    }

    updateSablonItem(instanceId, data) {
        const url: string = `${this.API_URL}updatesablon/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteSablonItem(instanceId) {
        const url: string = `${this.API_URL}deletesablon/${instanceId}/`;
        return this.httpClient.patch(url, '');
    }

    kasaGraph(kasaId) {
        const url: string = `${this.API_URL}thegraph/${kasaId}/`;
        return this.httpClient.get(url);
    }

    addMaasTahakkuk(data): Observable<CariHareketModel[]> {
        const url: string = `${this.API_URL}cari/maastahakkuk/add/`;
        return this.httpClient.post<CariHareketModel[]>(url, data);
    }

    updateMaasTahakkuk(data, instanceId) {
        const url: string = `${this.API_URL}cari/maastahakkuk/update/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    unpaidSalary(): Observable<any[]> {
        const url: string = `${this.API_URL}cari/unpaidsalary/`;
        return this.httpClient.get<any[]>(url);
    }

    getTahakkukData(): Observable<any> {
        const url: string = `${this.API_URL}cari/tahakkukdata/`;
        return this.httpClient.post<any[]>(url, '');
    }

    deleteCariHareketFile(instanceId): Observable<any> {
        const url: string = `${this.API_URL}deletehareketfile/${instanceId}/`;
        return this.httpClient.delete<any>(url);
    }

    deleteCredi(instanceId): Observable<any> {
        const url: string = `${this.API_URL}deletecredi/${instanceId}/`;

        return this.httpClient.delete<any>(url);
    }

    addCariList(data: any[]): Observable<any[]> {
        const url: string = `${this.API_URL}addacarilist/`;
        return this.httpClient.post<any[]>(url, data);
    }

    topluMaasOde(data) {
        const url: string = `${this.API_URL}cari/toplumaasode/`;
        return this.httpClient.post<any[]>(url, data);
    }
    mainGraph(dates?) {
        const url: string = `${this.API_URL}maingraph/`;
        return this.httpClient.post<any[]>(url, dates);
    }
    mainList(dates?) {
        const url: string = `${this.API_URL}mainlist/`;
        return this.httpClient.post<any[]>(url, dates);
    }

    comingList(dates?) {
        const url: string = `${this.API_URL}cominglist/`;
        return this.httpClient.post<any[]>(url, dates);
    }


    hideFromComingList(instanceId): Observable<any> {
        const url: string = `${this.API_URL}cominglist/`;
        const data = {
            instance_id: instanceId,
            show_status: false,
        };
        return this.httpClient.patch<any>(url, data);
    }

    getDetailForDay(day) {
        const url: string = `${this.API_URL}daydetail/`;
        return this.httpClient.post<any[]>(url, day);
    }

    getDetailForDayData(day) {
        const url: string = `${this.API_URL}islemgecmisi/`;
        return this.httpClient.post<any[]>(url, day);
    }

    calendarData(data?) {
        const url: string = `${this.API_URL}calendar/`;
        return this.httpClient.post(url, data);
    }

    setLog() {
        const url: string = `${this.API_URL}setlog/`;
        return this.httpClient.get(url);
    }
    giveCekToBank(data: any): Observable<any> {
        const url: string = `${this.API_URL}cek/givetobank/`;

        return this.httpClient.post<any>(url, data);
    }

    getEFatura(data: any): Observable<any> {
        const url: string = `${this.API_URL}entekrasyon/list/fatura/`;
        return this.httpClient.post<any>(url, data);
    }

    allEntegrasyonControl(id) {
        const url: string = `${this.API_URL}carifaturainformation/${id}/`;
        return this.httpClient.get<any>(url);

    }


    getExstreLink(cariIdentifier) {
        const url: string = `${this.API_URL}ekstrelink/${cariIdentifier}/`;
        return this.httpClient.get(url);
    }

    kurGetir() {
        const url = 'https://canlidoviz.com/doviz-kurlari.jsonld';
        return this.httpClient.post(url, {});
    }


    addOdemePlani(formVal) {
        const url = `${this.API_URL}odemeplan/add/`;
        return this.httpClient.post(url, formVal);
    }

    getOdemePlani(formVal) {
        const url = `${this.API_URL}odemeplan/list/`;
        return this.httpClient.post(url, formVal);
    }

    deleteOdemePlani(instanceId) {
        const url = `${this.API_URL}odemeplan/delete/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getOdemePlaniDetail(instanceId) {
        const url = `${this.API_URL}odemeplan/detail/${instanceId}/`;
        return this.httpClient.get(url);
    }

    payOdemePlaniTaksit(instanceId, formVal) {
        const url = `${this.API_URL}odemeplan/pay/${instanceId}/`;
        return this.httpClient.patch(url, formVal);
    }

    getUnpaidOdemeTaksits(cariId: number) {
        const url = `${this.API_URL}odemeplan/unpaid/${cariId}/`;
        return this.httpClient.get(url);
    }

    odenmisOlarakIsaretle(instanceId: number) {
        const data = { instance_id: instanceId };
        const url = `${this.API_URL}odemeplan/odendiisratle/`;
        return this.httpClient.patch(url, data);
    }

    odenmediOlarakIsaretle(instanceId: number) {
        const data = { instance_id: instanceId };
        const url = `${this.API_URL}odemeplan/odenmediisaretle/`;
        return this.httpClient.patch(url, data);
    }

    public destroy$ = new Subject();

    public cariList$ = this.store.select(fromCari.filterCari).pipe(
        tap(caris => {
            /**
             * state üzerindeki filtrelenmiş carileri (filterCari) listeler.
             * cari state'e search filter vb bir etki uygulanırsa
             * ve listesinde 20'den az veri varsa yeni liste getirme işlemini tetikler.
             * Eğer elindeki veri bitmişse tekrar aktive olmaz.
             */
            let filtre;
            let isLoading: boolean = false;
            let end: boolean = false;
            let orgInfo;
            this.store.select(fromCari.selectCariLoading).subscribe(loading => isLoading = loading);
            this.store.select(fromCari.selectCariEnd).subscribe(isEnd => end = isEnd);
            this.store.select(fromCari.getFilter).subscribe(currentFilter => filtre = currentFilter);
            this.store.select(getOrgInfo).subscribe(org => orgInfo = org);
            if (orgInfo?.apas_id) {
                if (caris && caris.length !== 0 && caris.length < 20) {
                    if (filtre && !end && !isLoading) {
                        filtre = removeAllNull(filtre);
                        this.store.dispatch(cariActions.loadCari(filtre));
                    }
                } else if (!isLoading && (!caris || caris.length === 0) && !end) {
                    this.store.dispatch(cariActions.loadCari(filtre));
                }
            }

        }),
        filter(caris => caris !== null),
    );

    public alisList$ = this.store.select(fromAlisSatis.selectAlis);
    public satisList$ = this.store.select(fromAlisSatis.selectSatis);

}
