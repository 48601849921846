import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NoteModel } from '../../../models/note.model';
import {
  addOrUpdate,
  removeAllNull,
  resizeImage,
  timeStampToClock,
} from '../../../pages/global-functions';
import { NoteService } from '../../../services/note.service';
import * as moment from 'moment-timezone';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ApasImageUploaderComponent } from '../../Apas-Modules/apas-image-uploader/apas-image-uploader.component';
import { DomSanitizer } from '@angular/platform-browser';
import { responseKodlari } from '../../../pages/constants';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
@Component({
  selector: 'aps-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
  providers: [NoteService],
})
export class NotesListComponent implements OnInit, AfterViewInit {
  readonly message = { value: 'İşlem başarılı.', error: '' };

  @Input() instance: any;
  @Input() module: string;
  @Input() title: string;
  rows: NoteModel[] = [];
  noteForm: FormGroup;
  showNewForm: boolean = false;
  currentFile: File;

  tag: string = 'note-list.component --->';
  ImageUrl;
  loading: boolean;
  uploading: boolean = false;
  isSuccess: boolean = false;
  isAnimationComplate: boolean = false;
  isSubmited: boolean = false;
  respons: NoteModel;
  editMode: boolean = false;
  reveal: boolean = false;
  isLoading: boolean = false;
  nextPage: string;
  isEnd: boolean = false;
  choices = [
    {
      label: 'Herkes Görebilir',
      detail: 'Yüklenici ve işveren personllerinden bu şantiyede yetkili herkes tarafından görüntülenebilir.',
      value: 1,
    },
    {
      label: 'Firma Personeli',
      detail: 'Sadece sizin firmanızın personllerinden bu şantiyede yetkili kişiler tarafından görüntülenebilir.',
      value: 2,
    },
    {
      label: 'Sadece ben',
      detail: 'Sadece sizin tarafınızdan görüntülenir.',
      value: 3,
    },
  ];
  pinColors = [
    '#FBC02D',
    '#03A9F4',
    '#4CAF50',
    '#9C27B0',
    '#FF5722',
    '#795548',
  ];
  constructor(
    private noteService: NoteService,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    private ref: NbDialogRef<NotesListComponent>,
  ) {
    this.initForm();
  }
  ngAfterViewInit(): void {
  }
  ngOnInit() {
    this.getNoteList();
  }

  findChoice(value) {
    const dt = this.choices.find(x => x.value == value);
    return dt;
  }
  selectedPinColor = '#FBC02D';
  selectedStatus = 2;
  setColor(event) {
    this.noteForm.get('pin').setValue(event);
  }
  setStatus(event){
    this.noteForm.get('show_status').setValue(event);
  }
  initForm() {
    this.noteForm = this.fb.group({
      note: [null, [Validators.required, Validators.maxLength(2048)]],
      image: [null],
      pin: ['#FBC02D'],
      show_status: [2],
    });
  }
  getNoteList() {
    this.isLoading = true;
    this.noteService
      .getNoteList(this.module, this.instance.id, this.nextPage)
      .subscribe((resp) => {
        this.nextPage = resp.next;
        resp.results.forEach((elm) => {
          elm['tarih'] = moment(elm.created_time, 'X')
            .tz('Europe/Istanbul')
            .format('DD/MM/YYYY');
          elm['note_status'] = this.findChoice(elm.show_status);
          this.rows = addOrUpdate(this.rows, elm);
        });
        this.isLoading = false;
        this.rows = [...this.rows];
        if (this.rows.length === resp.count) this.isEnd = true;
      }, (err) => this.isLoading = false);
  }
  loadNext() {
    if (!this.isLoading && !this.isEnd) {

      this.getNoteList();
    }
  }
  addNewNote() {
    this.selectedStatus = 2;
    this.selectedPinColor = '#FBC02D';
    this.ImageUrl = null;
    this.currentFile = null;
    this.noteForm.reset();
    this.noteForm.get('pin').setValue('#FBC02D');
    this.showNewForm = true; 
    document.getElementsByClassName('notBodyy')[0].scrollTo(0, 0);
  }

  //  Fotoğraf Select /

  openFile(fileInput) {
    fileInput.click();
  }
  // /
  //   Resimler seçildikden sonra çalışan fonksiyon.
  //   Seçilen resimleri daha önceden eklenen fotograf dizisine ekler.
  //   başlangıcda liste de 0 tane eleman vardırç
  //   @param currentFiles currentFiles: File[] = [] olarak tanımlanır.
  //   @param event Seçilen dosyaların listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
  //  /
  openFileDialog(event) {
    // uyumsus dosyaların ismleri burda toplanacak.
    let ignores: string = '';
    this.currentFile = (event.target as HTMLInputElement).files[0];
    // eğer bu dosya resim ise onu kabulet
    if (this.currentFile.type.indexOf('image') !== 0) {
      // eğer resim değilse onu kabul etmez. ve ignores string listesine ekler.
      ignores +=
        '<br><div style="padding: 5px;border-bottom: 1px solid #ebebeb;">' +
        this.currentFile.name +
        '</div>';
    }

    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (ignores.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen sadece uyumlu resim dosyalarını seçiniz.',
        html:
          '<div style="color: #f27474;text-align: left !important;">' +
          ignores +
          '</div>',
        showConfirmButton: true,
      });
    } else {
      this.showImage(this.currentFile);
    }
  }

  successLoad() {
  }
  //
   // Resim sunucuya başarılı bir şekilde yüklendiğinde çalışır.
   // @param event fonksiyonu cağıran component
   // @param i componentin id  si
   //
  success(event: ApasImageUploaderComponent, i) {
  }
  remove() {
    // console.log(this.tag, 'Resim başarılı bir şekilde silindi:', event, 'index:', i);
    this.currentFile = null;
    this.noteForm.reset();
    this.showNewForm = false;
    this.editMode = false;
    this.selectedStatus = 2;
    this.ImageUrl = null;
    this.selectedPinColor = '#FBC02D';
  }

  showImage(file: File | String) {
    if (file instanceof File) {
      this.showFile(file);
    } else if (typeof file === 'string') {
      this.showBase64(file);
    }
  }
  showBase64(file: String) {
    this.loading = false;
    this.ImageUrl = this.domSanitizer.bypassSecurityTrustUrl('' + file);
  }
  showFile(file: File) {
    this.loading = true;

    // Dosya okumamızı sağlar.
    const reader = new FileReader();
    // Dosyayı okur ve ImgaUrl'e yükler.
    reader.onload = () => {
      this.ImageUrl = reader.result as string;
      this.loading = false;
    };
    // resimin Gözükmesi için onu güvenilir olarak işaretler.
    // aksi taktirde resim gösterilmiyecektir.
    reader.readAsDataURL(file);
  }
  detayNote: any;
  openDeatil(not) {
    this.showNewForm = false;
    this.detayNote = not;
    this.reveal = true;

      this.noteService.noteDetail(this.module, this.instance.id, not.id).subscribe(resp => {
      resp.tarih = moment(resp.created_time, 'X').tz('Europe/Istanbul').format('DD/MM/YYYY');
      this.detayNote = resp;
    });
  }

  // Resimleri suncuya yükleyn fonksiyon. /
  upload() {
    if (!this.noteForm.valid) {
      this.noteForm.markAllAsTouched();
      return;
    }

    // kullanıcının girdiği metin
    const note = this.noteForm.get('note').value;
    const pin = this.noteForm.get('pin').value;
    const showStatus = this.noteForm.get('show_status').value;
    // zaten bir yükleme yapıyorsak tekrar yükleme yapmasını engelliyor.
    if (this.loading && this.uploading) {
      return;
    }
    // yükleme işleminin başladığını belirtiyor.
    this.uploading = true;
    let formVal = {  note: note , pin: pin, show_status: showStatus};
    formVal = removeAllNull(formVal);
    if (this.ImageUrl && this.currentFile ) {
      // sunucuya yüklenmden once resmin yeniden boyutlandırlması icin gerekli
      // resizing işlemini yapan fonksiyon

      resizeImage(this.ImageUrl).then(
        (resizedImage: string) => {
          formVal['image'] =  resizedImage;
          this.postOrPatchForm(formVal);

        },
        () => {
          this.message.error = 'Resim boyutlandırılamadı.';
          throw new Error('Resim boyutlandırılamadı.');
        },
      );
    } else {
      this.postOrPatchForm(formVal);
    }

  }

  postOrPatchForm(formVal) {
    if (!this.editMode) {
      this.isSubmited = true;
      this.noteService
        .createNote(this.module, this.instance.id, formVal)
        .subscribe(
          (resp) => {
            resp.tarih = moment(resp.created_time, 'X').tz('Europe/Istanbul').format('DD/MM/YYYY');
            resp['note_status'] = this.findChoice(resp.show_status);

            this.uploading = false;
            this.currentFile = null;
            this.ImageUrl = null;
            this.showNewForm = false;
            this.rows.unshift(resp);
            this.noteForm.reset();
            // Resim yüklendiğinde text areayı kapatır.
          },
          (err) => {
            this.message.value = 'fail';
            this.uploading = false;

            throw new Error(
              'Resim sunucuya yüklenemedi. error:' + err.message,
            );
          },
        );
    }

    // patch  resim sunucuya gönderiliyor.
    if (this.editMode) {
      this.isSubmited = true;
      this.noteService.updateNote(this.module, this.instance.id, this.detayNote.id, formVal).subscribe(resp => {
        resp.tarih = moment(resp.created_time, 'X').tz('Europe/Istanbul').format('DD/MM/YYYY');
        resp['note_status'] = this.findChoice(resp.show_status);

        this.uploading = false;
        this.currentFile = null;
        this.ImageUrl = null;
        this.showNewForm = false;
        this.rows = addOrUpdate(this.rows, resp);
        this.detayNote = resp;
        this.editMode = false;
      }, (err) => {
        this.editMode = false;
      });
    }
  }

  closeRev() {
    this.reveal = false;
    this.detayNote = null;
    this.editMode = false;
    this.showNewForm = false;
  }


  editNote() {
    this.editMode = true;
    this.showNewForm  = true;
    this.selectedPinColor = this.detayNote.pin;
    this.selectedStatus = this.detayNote.show_status;
    this.showImage(this.detayNote.image);
    this.noteForm.patchValue(this.detayNote);
  }

  deleteNote() {
    this.showNewForm = false;
    Swal.fire(
      {
        ...responseKodlari.shureToDelete,
        ...{text: 'Bu Notu Silmek istediğinize Emin Misiniz?'}
      } as SweetAlertOptions).then(({value}) => {
      if (value) {
        const data = {is_deleted: true}
        this.noteService.updateNote(this.module, this.instance.id, this.detayNote.id, data).subscribe(resp => {
          this.rows = this.rows.filter(x => x.id !== resp.id);
          this.closeRev();
        });
      }
    });

  }

  closeNotes(){
    this.ref.close(NotesListComponent);
  }
}
