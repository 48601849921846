<nb-card size="giant" class="modalType animated fadeInRight" style="border-top: unset; width: 80%;"  >
  <nb-card-header>

    <div class="d-flex align-items-center justify-content-between">
      <span> Çalışma Raporu </span>
      <button nbButton (click)="close()" size="medium" class="heryerdeClose">x</button>
    </div>
  </nb-card-header>
  <nb-card-body [nbSpinner]="isLoading" [formGroup]="form" >

    <div class="row form-padding" style="padding-left: 13px;">
      
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label label">Makina</label>
          <is-makinasi-select formControlName="machine_id" [multiple]="true"></is-makinasi-select>
        </div>
      </div>

      <div class="col-md-1">
        <div class="form-group">
          <label class="form-label label">{{'ISMAKINASIPUANTAJRAPORU.TIP'|translate}}</label>
          <makina-type-select formControlName="machine_type_id"></makina-type-select>
        </div>
      </div>

      <!--Başlangıç Tarihi-->
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label label">{{'ISMAKINASIPUANTAJRAPORU.BASLANGICTARIHI'|translate}}</label>
          <input nbInput [placeholder]="'ISMAKINASIPUANTAJRAPORU.BASLANGICTARIHI'|translate" [nbDatepicker]="baslangic" dateOnly
            formControlName="start_time" fullWidth>
          <nb-datepicker #baslangic></nb-datepicker>
        </div>
      </div>
      <!--// Başlangıç Tarihi-->

      <!--Bitiş Tarihi-->
      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label label">{{'ISMAKINASIPUANTAJRAPORU.BITISTARIHI'|translate}}</label>
          <input nbInput [placeholder]="'ISMAKINASIPUANTAJRAPORU.BITISTARIHI'|translate" formControlName="finish_time" dateOnly
            [nbDatepicker]="bitis" fullWidth>
          <nb-datepicker #bitis></nb-datepicker>
        </div>
      </div>
      <!--// Biriş Tarihi-->

      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label label">Şantiye</label>
          <santiye-select [mine]="true" [is_active]="form.get('santiye_is_active').value" formControlName="santiye_id"></santiye-select>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label class="form-label label">Taşeron</label>
          <organization-select formControlName="taseron_id" apasInvalid>
          </organization-select>
        </div>
      </div>

      
      <!--Filtre-->
      <div class="col-md pt-2 d-flex align-items-center">
        <button nbButton status="success" (click)="getReport()">{{'ISMAKINA.FILTRE' | translate }}</button>


      </div>
      <!--// Filtre-->
    </div>
    <div class="row form-padding" style="padding-left: 13px;">


      <div class="col-md-2 mb-2">
        <input #searchInput id="searchInput" [nbTooltip]="'Açıklama Metinlerinde Arama Yapar.'" fullWidth nbInput
          placeholder="Ara" formControlName="search">
        <nb-icon icon="search-outline" class="aps-src"></nb-icon>
      </div>
      <div class="col d-flex justify-content-end" style="float: right;">
        <button nbButton class="excel_button" (click)="listeExcel()" [nbSpinner]="excelReporting"> Excel Olarak Kaydet</button>
        <nb-toggle formControlName="santiye_is_active"> Biten Şantiyeleri {{form.get('santiye_is_active').value ?
          'Gizle' : 'Göster'}} </nb-toggle>
      </div>
    </div>
    <div class="aps-splt" style="background-color: #fff;">
      <div class="row w-100 p-0 m-0 apsHeaderTable">

        <div class="col-md-2">
          <span>{{ 'ISMAKINA.SANTIYE' | translate }}</span>
        </div>
        <div class="col-md-2">
          <span>{{ 'ISMAKINA.MAKINE_TIPI' | translate}}</span>
        </div>
        <div class="col-md-2">
          <span>{{ 'TASERON.SUBCONTRACTOR' | translate }}</span>
        </div>
       
        <div class="col-md-2">
          <span>{{ 'ISMAKINA.BASLAMATARIHI' | translate }}</span>
        </div>
        <div class="col-md-2">
          <span>{{ 'ISMAKINA.BITISTARIHI' | translate }}</span>
        </div>
        <div class="col-md">
          <span>{{ 'ISMAKINA.SURE2' | translate }} / Çalışma Şekli </span>
        </div>
     

      </div>



      <nb-list *ngIf="rows && rows.length > 0" style="overflow-x: hidden;">

        <nb-list-item class="aps-hover p-0 listCustom linked-div" *ngFor="let row of rows;let i = index;">
          <div class="col-md-2">
            <span [nbTooltip]="row.note">{{row?.santiye_name}} </span>
          </div>
          <div class="col-md-2" [nbTooltip]="row.note">
            <span style="font-size: 13px;">{{row?.machine_type_title}} ({{row?.plate}}) </span>
          </div>
          <div class="col-md-2">
            <span>{{row?.taseron_name}} </span>
          </div>

         
          <div class="col-md-2">
            <div *ngIf="row?.start_time " style="font-weight: normal !important;">
               <!-- {{ row?.baslangic[0] }} -->
              <b>{{ row?.start_time | date : tarihFullFormat }}</b>
            </div>
            <div *ngIf="!row?.start_time "> --/--/---- </div>
          </div>
          <div class="col-md-2">
            <span *ngIf="row?.finish_time "> 
              <!-- {{ row?.bitis }}  -->
              <b>{{row?.finish_time | date : tarihFullFormat}}</b>
            </span>
            <span *ngIf="!row?.finish_time "> --/--/---- </span>
          </div>
          <div class="col-md" [nbTooltip]="row?.note" style="font-size:13px">
            <strong>{{row.total_work_time}} <b *ngIf="row.work_type === 'saat'"> / <span class="text-success">Saat</span> </b></strong>
            <strong *ngIf="row.work_type === 'sefer'"> / <span class="text-success">{{row?.miktar + 'sefer'| translate}}</span> </strong>
          </div>

          <div class="text-right">
            <button nbButton status="info" size="tiny" (click)="editCalisma(row)">
              <nb-icon icon="edit-outline" ></nb-icon>
            </button>
            <button nbButton status="danger" size="tiny" (click)="deleteInfo(row)">
              <nb-icon icon="trash-outline"></nb-icon>
            </button>
          </div>



        </nb-list-item>


      </nb-list>
      <div class="row" style="background: #f8fafb; padding: 12px 4px;" *ngIf="rows && rows.length > 0">
        <div class="col-md-9 text-right"><b >Toplam:</b></div>
        <div class="col-md text-center pr-5">
          <strong style="font-size:13px">
           <span *ngIf="totalTime"> <b class="text-success">{{'saat' | translate}}:</b> {{totalTime}} </span>
            <br>
            <span *ngIf="totalMiktar > 0"><b class="text-success">{{'sefer' | translate}}:</b> {{totalMiktar}}</span>
          </strong>
        </div>
      </div>
      <aps-emptylist *ngIf="!isLoading && !(rows && rows.length > 0)">

      </aps-emptylist>
    </div>

   

  </nb-card-body>

</nb-card>



