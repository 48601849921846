import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { getBirim } from '../../../pages/constants';
import { DepoService } from '../../../services/depo.service';

@Component({
  selector: 'depo-miktar-modal',
  templateUrl: './depo-miktar-modal.component.html',
  styleUrls: ['./depo-miktar-modal.component.css']
})
export class DepoMiktarModalComponent implements OnInit {

  rows = [];
  isLoading: boolean = false;

  getBirim = getBirim;

  constructor(
    private depoService: DepoService,
    private ref: NbDialogRef<DepoMiktarModalComponent>,
  ) { }
  product_id: any;
  ngOnInit() {

    if (this.product_id) {
      this.isLoading = true;
      this.depoService.getProductForTalep(this.product_id).subscribe(resp => {
        this.isLoading = false;
        this.rows = resp;
        console.log('RESP : ', resp);
      }, (err) => {
        this.isLoading = false;
      });
    }
  }
  closed(value?) {
    this.ref.close(value);
  }
}
