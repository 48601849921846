import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import { tarih_format } from '../../../pages/constants';
import { convertDateWithTZ, dateFormSetter, removeAllNull } from '../../../pages/global-functions';
import { TakvimService } from '../../../services/takvim.service';
import { GorevDetayComponent } from '../../gorev/gorev-detay/gorev-detay.component';
import { GorevListComponent } from '../../gorev/gorev-list/gorev-list.component';
import { HatirlatmaDetayComponent } from '../../hatirlatmalar/hatirlatma-detay/hatirlatma-detay.component';
import { HatirlatmaListComponent } from '../../hatirlatmalar/hatirlatma-list/hatirlatma-list.component';
import { YapilanIslerDetayComponent } from '../../santiye/yapilanIsler/yapilan-isler-detay/yapilan-isler-detay.component';
import { TakvimIsProgramiDetayComponent } from '../takvim-is-programi-detay/takvim-is-programi-detay.component';

@Component({
  selector: 'mini-takvim',
  templateUrl: './mini-takvim.component.html',
  styleUrls: ['./mini-takvim.component.scss'],
  providers: [TakvimService],
})
export class MiniTakvimComponent implements OnInit {
tarih_format = tarih_format;
takvimGoster = false;
isLoading = false;
detayDate = new Date();
takvimList = [];
tarihFilterDurum = 'month';
form: FormGroup;
  constructor(private fb: FormBuilder,
              private takvimService: TakvimService,
              private nbDialogService: NbDialogService,
              private router: Router) { }

  ngOnInit(): void {
    this.initForm();
    this.getTakvim();
  }

  getDetailForDay() {
    let day = this.detayDate;
    day = dateFormSetter(day, 'start_date');
    this.form.get('tip').setValue('');
    this.form.get('start_date').setValue(day);
    this.getTakvim();
   }
  initForm() {
    this.form = this.fb.group({
      start_date: [],
      tip: [this.tarihFilterDurum],
    });
    this.form.get('tip').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getTakvim();
      }, 150);
    });
  }

  tarihFilter(event) {
    this.tarihFilterDurum = event;
    this.form.get('start_date').setValue(null);
    this.form.get('tip').setValue(this.tarihFilterDurum);
  }

  getTakvim() {
    this.takvimList = [];
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.takvimService.getTakvimDashboard(formVal).subscribe(data => {
      console.log('Takvim Gelen Data: ', data);
      data.forEach(element => {
        element.value = this.takvimType(element.type);
        element.date  = convertDateWithTZ(element, 'date');
      });
      this.takvimList = data;
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }


  takvimType(type) {
      switch (type) {
        case 1 : {
          return 'gorev';
        }
        case 2 : {
          return 'hatirlatma';
        }
        case 3 : {
          return 'toplanti';
        }
        case 4 : {
          return 'isprogrami';
        }

      }
  }

  islemYap(item) {
    switch (item.type) {
      case 1 : {
        this.nbDialogService.open(GorevDetayComponent, {
          context: {
            gorevId: item.item_id,
          },
        });
        break;
      }
      case 2 : {
        this.nbDialogService.open(HatirlatmaDetayComponent, {
          context: {
            hatirlatmaID: item.item_id,
          },
        });
        break;
      }
      case 3 : {
        this.router.navigate([`pages/toplanti/toplanti-detay/${item.item_id}`]);
        break;
      }
      case 4 : {
        this.nbDialogService.open(TakvimIsProgramiDetayComponent, {
          context: {
            item: item,
          },
        });
        break;
      }
    }
  }

  gorevModal() {
    const context: {} = {
      'buyukGetir': true,
    };
    this.nbDialogService.open(GorevListComponent, { context });
  }

  hatirlatmaModal() {
    const context: {} = {
      'buyukGetir': true,
    };
    this.nbDialogService.open(HatirlatmaListComponent, { context });
  }



}

