import { Component, OnInit } from '@angular/core';
import { FirmaBilgiGuncelleComponent } from '../../../../profil/firma-profil-main/firma-bilgi-guncelle/firma-bilgi-guncelle.component';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { responseKodlari } from '../../../constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { EFaturaGirisComponent } from '../../e-fatura-sureci/e-fatura-giris/e-fatura-giris.component';
import { EFaturaBasvuruYapComponent } from '../../e-fatura-sureci/e-fatura-basvuru-yap/e-fatura-basvuru-yap.component';

@Component({
  selector: 'basvuru-secenekleri',
  templateUrl: './basvuru-secenekleri.component.html',
  styleUrls: ['./basvuru-secenekleri.component.scss'],
})
export class BasvuruSecenekleriComponent implements OnInit {
  basvuruSecim = false;
  basvuruTip = '';
  organization_info;
  constructor(
    private ref: NbDialogRef<BasvuruSecenekleriComponent>,
    private nbDialogService: NbDialogService,
    private organizationService: OrganizasyonService,
    ) {
    this.organizationService.org$.subscribe(org => {
      this.organization_info = org;
    });
  }

  ngOnInit(): void {
  }

  basvuruSec(tip) {
    if (tip === 'ilkBasvuru') {
      if (!this.organization_info.name ||
        !this.organization_info.tax_no ||
        !this.organization_info.tax_office ||
        !this.organization_info.phone ||
        !this.organization_info.email ||
        !this.organization_info.address ||
        !this.organization_info.resmi_unvan) {
        Swal.fire(responseKodlari.firmaBilgiEksik as SweetAlertOptions);
        this.nbDialogService.open(FirmaBilgiGuncelleComponent);
      } else {
        this.nbDialogService.open(EFaturaBasvuruYapComponent).onClose.subscribe((resp) => {
          if(resp){
            this.ref.close();  
          }
        });

      }
    } else if (tip === 'giris') {
      if (!this.organization_info.name ||
        !this.organization_info.tax_no ||
        !this.organization_info.tax_office ||
        !this.organization_info.phone ||
        !this.organization_info.email ||
        !this.organization_info.address ||
        !this.organization_info.resmi_unvan) {
        Swal.fire(responseKodlari.firmaBilgiEksik as SweetAlertOptions);
        this.nbDialogService.open(FirmaBilgiGuncelleComponent);
      } else {
        this.nbDialogService.open(EFaturaGirisComponent).onClose.subscribe(resp => { 
          if(resp){
            this.ref.close();
          }
        });
      }
    }
  }

  close() {
    this.ref.close();
  }
}
