import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, telefonKodu, Ulkeler } from '../../../../pages/constants';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { ApasdisiFirmaEkleType } from '../organizationTypeEnums';

@Component({
  selector: 'iliskili-firma-edit',
  templateUrl: './iliskili-firma-edit.component.html',
  styleUrls: ['./iliskili-firma-edit.component.css'],
  providers: [OrganizasyonService],
})
export class IliskiliFirmaEditComponent implements OnInit {
  addType: ApasdisiFirmaEkleType = ApasdisiFirmaEkleType.Taseron;
  isSubmitting: boolean = false;
  firma;
  telefon_kodu = telefonKodu;
  ulkem: FormControl = new FormControl(telefonKodu[0]);
  sehirler: any[] = Ulkeler[0].sehir;

  form: FormGroup;
  tag: string = 'APAS FİRMA EKLE COMPONENT ----> ';
  constructor(
    private ref: NbDialogRef<IliskiliFirmaEditComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private organizasyonService: OrganizasyonService,
  ) {
    this.initForm();
  }
  ngOnInit() {

    console.log('Firma : ', this.firma);
    this.form.patchValue(this.firma);
    if (!this.firma.demo) {
      this.disableForm();
    }
    this.form.get('instance_type').setValue(this.addType);
  }
  initForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.email],
      city: [null, Validators.required],
      instance_type: [this.addType],
    });
  }
  formDisabled = false;
  disableForm() {
    this.formDisabled = true;
    this.form.disable();
  }

  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.formDisabled) {
      // Firma Demo Değil, bilgileri düzenlenemez.
      const swalOption: SweetAlertOptions = {
        icon: 'info',
        title: 'Düzenlenemez',
        text: 'Düzenlemeye çalıştığınız firma Apas+ üyesi bir firma olduğu için bilgilerini düzenleyemezsiniz.',
        showCancelButton: true,
        cancelButtonText: 'Kapat',
      };

      Swal.fire(swalOption);
    }
    this.isSubmitting = true;

    this.organizasyonService.updateRelatedOrganization(this.firma.company_id.id, this.form.value).subscribe(val => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(val);
      this.isSubmitting = false;

    }, (err) => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      this.isSubmitting = false;
    });

  }

  close(value?) {
    this.ref.close(value);
  }

  taseronPasif() {
    Swal.fire(
      {
        ...responseKodlari.shureToDelete, ...{
          text: `${this.firma.name} Firmayı ${this.firma.is_active ? 'Pasif' : 'Aktif'} Yapmak Emin Misiniz?`,
          confirmButtonText: `${this.firma.is_active ? 'Pasif' : 'Aktif'} Yap`,
        },
      } as SweetAlertOptions).then(({ value }) => {
        if (value) {
          this.isSubmitting = true;
          this.organizasyonService.updateRelatedOrganization(
            this.firma.company_id.id, { is_active: !this.firma.is_active, instance_type: this.addType },
          ).subscribe(resp => {
            this.firma = resp;
            this.close(true);
            Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
            this.isSubmitting = false;
          }, (err) => {
            this.isSubmitting = false;
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          });
        }
      });
  }

  taseronDelete() {
    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: `${this.firma.name} Firmayı Silmek İstediğinizden Emin Misiniz?` } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.isSubmitting = true;
        this.organizasyonService.updateRelatedOrganization(
          this.firma.company_id.id, { is_deleted: true, instance_type: this.addType },
        ).subscribe(resp => {
          this.close();
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.router.navigate(['pages/taseron/taseron-list']);
          this.isSubmitting = false;
        }, (err) => {
          this.isSubmitting = false;
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
      }
    });
  }


}
