<nb-card class="modalType animated fadeInRight" style="width: 50% !important;">
    <nb-card-header class="d-flex justify-content-between">
        <span *ngIf="!detay"> {{employee?.name}} Puantaj Detay </span>
        <div  class="linked-div" (click)="hideDetail()" *ngIf="detay">
            <nb-icon icon="arrow-back-outline" style="transform: scale(1.5);margin-right: 16px;"></nb-icon>
            <b *ngIf="detayRows && detayRows.length > 0">{{employee?.name}} {{detayRows[0].santiye_name}}</b>
        </div>
        <div>
        <button size="small" class="excel_button aps-m-2px" nbButton (click)="listeExcel()">
            <i class="fas fa-file-excel pr-2"></i>Excel Çıktısı Al
        </button>
        <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>

        </div>
    </nb-card-header>

    <nb-card-body [formGroup]="form" [nbSpinner]="isLoading">
        <!-- Filtre -->
        <div class="d-flex justify-content-between">
            <nb-toggle style="margin-top: 5px;" formControlName="santiye_active">Biten Şantiyeleri
                {{form.get('santiye_active').value ? 'Gizle' : 'Göster'}}</nb-toggle>
            <span style="float: right;" class="aps-radio">
                <nb-radio-group [formControl]="dateType" name="reactive-forms-group">
                    <nb-radio value="bugun">Bugün</nb-radio>
                    <nb-radio value="buhafta">Bu Hafta</nb-radio>
                    <nb-radio value="buay">Bu Ay</nb-radio>
                    <nb-radio value="tumu">Tümü</nb-radio>
                </nb-radio-group>
            </span>
        </div>
        <!-- // Filtre -->


        <!-- Ana Blok -->

        <table class="table table-bordered" *ngIf="!detay" >
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col" style="width: 30%;">Şantiye</th>
                    <th scope="col" class="text-center">Toplam</th>
                    <th scope="col" class="text-center">İşlemler</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of rows; let i = index;">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.santiye_name}}</td>
                    <td class="text-center">
                        <span class="apasBadgeSuccess">{{item?.total_count}}</span>
                        <label class="ml-2 form-label label">{{item?.max_date | date:tarih_format}}</label>
                    </td>
                    <td class="text-center">
                        <button nbButton size="tiny" status="primary" (click)="puantajDetay(item)">Detay</button>
                    </td>
                </tr>

            </tbody>
        </table>

        <!-- // Ana Blok -->
        <!-- Detay Blok -->
        <div *ngIf="detay" class="animated fadeInRight">

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col" width="15">#</th>
                        <th scope="col">Tarih</th>
                        <th scope="col" class="text-center">Meslek</th>
                        <th scope="col" class="text-center">Tam Gün</th>
                        <th scope="col" class="text-center">Mesai</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of detayRows; let i = index;">
                        <th>{{i+1}}</th>
                        <td >{{item?.date | date : tarih_format }}</td>
                        <td class="text-center">{{item?.job_name}}</td>
                        <td class="text-center">
                            <span class="text-success" *ngIf="!item?.yarim_gun">Tam Gün</span>
                            <span class="text-warning" *ngIf="item?.yarim_gun">Yarım Gün</span>
                        </td>
                        <td class="text-center">{{item?.overtime}}</td>
                    </tr>
    
                </tbody>
            </table>
        </div>
        <!-- // Detay Blok -->

    </nb-card-body>

</nb-card>