import {
  Component,
  HostListener, OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NbDialogService, NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { OnerComponent } from '../../../components/apas-components/arkadasina-oner/oner/oner.component';
import { DuyuruEkleComponent } from '../../../components/duyuru/duyuru-ekle/duyuru-ekle.component';
import { GorevListComponent } from '../../../components/gorev/gorev-list/gorev-list.component';
import { KullaniciModel } from '../../../models/kullanici_model';
import OrgInfoModel from '../../../models/orgInfo.model';
import { ApasInfo, urlChangeKeyCode } from '../../../pages/constants';
import { AuthService } from '../../../services/auth.service';
import { KullaniciService } from '../../../services/kullanici.service';
import * as bildirimActions from '../../../store/bildirim/bildirim.actions';
import * as fromUser from '../../../store/user/user.connector';
import * as fromDuyuru from '../../../store/duyuru/duyuru.connector';
import { DuyuruService } from '../../../services/duyuru.service';
import { YardimListComponent } from '../../../components/yardim/yardim-list/yardim-list.component';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { ClearOrgInfo } from '../../../store/organization/organization.actions';
import { selectUnShowedBildirim } from '../../../store/bildirim/bildirim.connector';
import { SetUrl } from '../../../store/aplikasyon/aplikasyon.actions';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [
    KullaniciService,
    AuthService,
    DuyuruService,
    OrganizasyonService,
  ],
})


export class HeaderComponent implements OnInit, OnDestroy {

  isCanApiChange: number = 0;
  // @ViewChild('bildirimPopOver', { static: false }) popover: NbPopoverDirective;
  @ViewChildren(NbPopoverDirective) menuPopOver: QueryList<NbPopoverDirective>;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: KullaniciModel;
  currentTheme = 'default';
  pencere: any;
  images: string;
  org$: Observable<OrgInfoModel>;
  org_info: OrgInfoModel;
  auth: string;
  _org: string;
  org_name: string;
  org_id: string;
  apasInfo = ApasInfo;
  lang$: Observable<string>;
  userMenu = [
    {
      title: 'ApasID ',
    },
    {
      title: 'Profil',
    },
    {
      title: 'Firma Profili',
    },
    {
      title: 'Firma Değiştir',
    },
    {
      title: 'Çıkış Yap',
    },

  ];
  date = Date.now();
  /**
   * Bildirimleri BildirimModele göre tutan değişken
   */
  // bildirimData: BildirimModel[] = [];
  unShowedBildrimCount: number = 0;
  /**
   * Okunmamış Mesaj Sayısını tutan değişken
   */
  unReadedCount = 0;

  unReadedDuyuruCount: number = 5;

  tag: string = 'HeaderComponent --->';
  showMuhasebeIcon: boolean = false;
  showEvrakIcon: boolean = false;


  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private store: Store<{}>,
    private nbDialogService: NbDialogService,
    private organizasyonService: OrganizasyonService,
  ) {

    this.store.pipe(select(fromUser.selectUser)).subscribe(user => this.user = user );
    this.store.pipe(select(selectUnShowedBildirim)).subscribe(count => this.unShowedBildrimCount = count);
    this.store.pipe(select(fromDuyuru.selectUnReadedDuyuru)).subscribe(count => this.unReadedDuyuruCount = count);
    this.pencere = window;

    this.subscribeOrg();
  }

  subscribeOrg() {
    this.org$ = this.organizasyonService.org$;
    this.org$.subscribe(data => {
      this.org_info = data;
      if (data && data.moduls) {
        const muhasebe = data.moduls.find(x => x.slug === 'muhasebe');
        this.showMuhasebeIcon = muhasebe ? muhasebe.value : false;
        const evrak = data.moduls.find(x => x.slug === 'evraklar');
        this.showEvrakIcon = evrak ? evrak.value : false;
      }
      if (data['id'] === undefined) {
        const local_org = localStorage.getItem('org');
        if (local_org === null || local_org === undefined) {
          this.router.navigateByUrl('/profil');
        } else {
          return;
        }
      } else {
        this.org_info = data;
        this.userMenu[0].title = 'ApasID : ';
      }
    });

  }

  menuUser(git: string) {
    this.menuPopOver.toArray().forEach(el => {
      el.hide();
    });
    if (git === 'Çıkış Yap') {
      this.org$.subscribe().unsubscribe();
      this.router.navigateByUrl('/logout');

    } else if (git === 'Profil') {
      this.router.navigateByUrl('/profil/kullanici-profil');
    } else if (git === 'Firma Profili') {
      this.router.navigateByUrl('/profil/firma-profil');
    } else if (git === 'Hesabim') {
      this.router.navigateByUrl('/profil/firma-profil');
    } else if (git === 'Firma Değiştir') {
      this.org$.subscribe().unsubscribe();
      this.store.dispatch(ClearOrgInfo());
      this.router.navigateByUrl('/karsilama');
    } else if (git === 'userMenu') {
    }
  }

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();

    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  goHome() {
    this.router.navigateByUrl('pages/dashboard');
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  gorevModal() {
    const context: {} = {
      'buyukGetir': true,
    };
    this.nbDialogService.open(GorevListComponent, { context });
  }

  duyuruEkle() {
    this.nbDialogService.open(DuyuruEkleComponent);
  }
  arkadasinaOner() {
    this.nbDialogService.open(OnerComponent);
  }

  openYardim() {
    this.nbDialogService.open(YardimListComponent);
  }
  openMuhasebe() {
    this.router.navigate(['/pages/muhasebe']);
  }

  openBirimFiyat() {
    this.router.navigate(['pages/birimfiyat']);
  }

  setAllNotificationAsShowed() {
    this.store.dispatch(bildirimActions.setAllBildirimShowed());
  }

}
