import { NbMenuItem } from '@nebular/theme';

export interface ApasImalatUygunsuzlukItem extends NbMenuItem {
    click?: void | any;
    type?: ApasImalatUygunsuzlukMenuType;
}

export interface ApasImalatUygunsuzlukItemOptinos {
    canEdit?: boolean;
    canDelete?: boolean;
    canDetails?: boolean;
    menuItems?: ApasImalatUygunsuzlukItem[];

}
export enum ApasImalatUygunsuzlukMenuType {
    Edit,
    Detail,
    Delete,
}


export interface ApasImalatUygunsuzlukContext {
    userName?: string;
    userImage?: string;
    date?: number;
    title?: string;
    image?: string;
    content?: string;
}



