import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SantiyeService } from '../../../../services/santiye.service';
import { ImageModel } from '../../../../models/image.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ApasInfiniteScrollComponent } from '../../../Apas-Modules/infinite-scroll/infinite-scroll.component';
import { responseKodlari, tarih_format } from '../../../../pages/constants';
import { NbDialogService } from '@nebular/theme';
import { ApasImageLightboxComponent } from '../../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { last, reduce } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import * as moment from 'moment';
import { group } from '@angular/animations';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'santiye-img-list',
  templateUrl: './santiye-img-list.component.html',
  styleUrls: ['./santiye-img-list.component.css'],
  providers: [SantiyeService],
})
export class SantiyeImgListComponent implements OnInit {
  @ViewChild(ApasInfiniteScrollComponent)
  apasInfiniteScrollComponent!: ApasInfiniteScrollComponent;
  @Input() santiyeId: number;
  tarih_format = tarih_format;
  pageItemCount: number = 10;
  santiyeImages: any[] = [];
  imageUrl;
  tag: string = 'SantiyeImgListComponent --->';
  serializer = this.domSanitizer;
  constructor(
    private santiyeService: SantiyeService,
    private domSanitizer: DomSanitizer,
    private nbDialogService: NbDialogService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    console.log(this.tag, 'id:', this.santiyeId);
    this.getFirstData(this.santiyeImages, () => {
      this.apasInfiniteScrollComponent.setReset();
      this.apasInfiniteScrollComponent.options.message = 'Resim Bulunamadı!';
    });

    this.initEditForm();
  }

  /** Uyarı:Sunucudan scrolla düşecek kadar veri gelmez ise alan scrola düşmediği için scroll çalışmamaktadır.
   *
   * sunucudan en az 6 adet veri gelmelidir.
   *
   * Sayfa scroll olduğunda çalışır.
   *
   * @param event ApasInfinitscroll instance
   */
  onScrolled(event: ApasInfiniteScrollComponent) {
    console.log(this.tag, 'scroolled:', event);
    let lastTime: number = this.getLastDate(this.santiyeImages);

    console.log(this.tag, 'lastTime', lastTime);

    this.santiyeService.getImagesByTime(this.santiyeId, lastTime).subscribe(
      (data) => {
        data.forEach(elm => {
          if (elm.images.lenght > 0 && elm.images[0].gmt) elm['gmt'] = elm.images[0].gmt;
          elm['tarih'] = convertDateWithTZ(elm, 'created_time');
        });
        console.log(this.tag, 'Santiyes :', data);
        this.santiyeImages = [...this.santiyeImages, ...data];
        // event.isLoaiding = false;
        event.isLoaiding = true;

      },
      (err: HttpErrorResponse) => {
        event.isLoaiding = false;
      },
    );
  }
  imgClick(
    image: string,
    imageId: number,
    imageIndex: number,
    groupIndex: number,
  ) {

    const context: {} = {
      image: image,
      url: 'santiye/imagedetay/',
      // 'url': 'santiye/imagedetay/' + this.santiyeId + '/' + imageId + '/',
      santiyeId: this.santiyeId,
      imageIndex: imageIndex,
      groupIndex: groupIndex,
      rows: this.santiyeImages,
    };

    this.nbDialogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }

  refresh() {
    this.santiyeImages = [];
    this.apasInfiniteScrollComponent.setReset();
  }

  calculateAdet(rows: any[]): number {
    let adet: number = 0;
    adet = rows.reduce((a, b) => a + b.adet, 0);
    console.log(this.tag, 'Toplam Adet:', adet);
    return adet;
  }
  getLastDate(rows: any[]): number {
    let last: number;
    if (rows.length > 0) {
      const lastGroup: any = rows[rows.length - 1];
      const lastImage: any = lastGroup.images[lastGroup.images.length - 1];
      last = lastImage.created_time;
    }
    return last;
  }
  getFirstData(rows: any[], callback?) {
    console.log(this.tag, 'getFirstData --> rows:', rows);
    const lastTime: number = this.getLastDate(rows);
    console.log(this.tag, 'lastTime', lastTime);
    this.santiyeService.getImagesByTime(this.santiyeId, lastTime).subscribe(
      (data) => {

        data.forEach(elm => {
          if (elm.images.lenght > 0 && elm.images[0].gmt) elm['gmt'] = elm.images[0].gmt;
          elm['tarih'] = convertDateWithTZ(elm, 'created_time');
          const found = rows.find(x => x.tarih === elm['tarih']);

          if (found) {
            elm.images.forEach(img => {
              found.images = addOrUpdate(found.images, img);
            });
          } else {
            rows = [...rows, ...[elm]];
          }
        });
        rows = [...rows];
        const adet: number = this.calculateAdet(rows);

        if (adet < 10) {
          this.getFirstData(rows);
        } else {
          this.santiyeImages = [...rows];
          if (callback) {
            callback();
          }
        }
      },
      () => {
        this.santiyeImages = [...rows];
        if (callback) {
          callback();
        }
      },
    );
  }

  fotografSil(image, images, allList) {
    console.log('image', images);
    Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(
      ({ value }) => {
        if (value) {
          this.santiyeService.deleteImage(image.id).subscribe((resp) => {
            console.log('resp', resp);
            images.images = images.images.filter((img) => img.id !== image.id);
            if (images.images.length === 0) {
              console.log('İFE GİRDİ');
              this.santiyeImages = this.santiyeImages.filter((crt) => crt.created_time !== images.created_time);
            }
            console.log('Images 2', images, 'LENGT : ', images.length);
          });
        }
      },
    );
  }

  editForm: FormGroup;

  initEditForm() {
    this.editForm = this.fb.group({
      title: [],
      created_time: [],
      is_public: [],
    });
  }

  fotografEdit(image) {
    this.santiyeImages.forEach(grp => {
      grp.images.forEach(rsm => {
        rsm['editMod'] = false;
      });
    });
    image['editMod'] = true;

    this.editForm.get('title').setValue(image.title);

    const newDate = new Date(image.created_time * 1000);
    this.editForm.get('created_time').setValue(newDate);
    this.editForm.get('is_public').setValue(image.is_public);
  }

  updateImage(image, images) {
    const formVal = removeAllNull(this.editForm.value);

    this.santiyeService.updateImage(image.id, formVal).subscribe(resp => {
      image = resp;
      image['editMod'] = false;
      images.images = images.images.filter(x => x.id !== resp.id);
      const formatTarih = convertDateWithTZ(resp, 'created_time');
      const to_add = this.santiyeImages.find(x => x.tarih === formatTarih);
      if (to_add) {
        to_add.images = [...to_add.images, ...[image]];
      } else {
        const newImageArray = {
          adet: 1,
          created_time: image.created_time,
          tarih: formatTarih,
          images: [image],
        };
        this.santiyeImages = [...this.santiyeImages, ...[newImageArray]];
      }
      this.santiyeImages.forEach((gun, i) => {
        console.log('ELM', gun.tarih , gun.images.length);
        if (gun.images.length === 0) {
          console.log('TO REMOVE : ', gun);
          this.santiyeImages = this.santiyeImages.filter(x => x !== gun);
        }
      });

      this.santiyeImages.sort((x, y) => y.created_time - x.created_time);
      this.santiyeImages = [...this.santiyeImages];
      console.log('ALL IMAGES : ', this.santiyeImages);

      this.editForm.reset();
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);

    });
  }
}
