import { Component, OnInit, ViewChildren, QueryList, Input, EventEmitter, Output } from '@angular/core';
import { ApasImageUploaderComponent } from '../../../Apas-Modules/apas-image-uploader/apas-image-uploader.component';
import Swal from 'sweetalert2';
import { SantiyeModel } from '../../../../models/santiye_model';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'santiye-img-upload',
  templateUrl: './santiye-img-upload.component.html',
  providers: [ApasImageUploaderComponent],
  styleUrls: ['./santiye-img-upload.component.css'],
})
export class SantiyeImgUploadComponent implements OnInit {
  @Input() santiyeId: number;
  @Input() santiye: SantiyeModel;
  @Output() onSuccessUploads: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ApasImageUploaderComponent) files!: QueryList<ApasImageUploaderComponent>;
  tag: string = 'santiye-img-upload --->';
  currentFiles: File[] = [];
  targetUrl = 'santiye/imageupload/';
  constructor(private ref: NbDialogRef<SantiyeImgUploadComponent>) {
  }

  closed(resp?) {
    this.ref.close(resp);
  }

  ngOnInit() {
    this.targetUrl = 'santiye/imageupload/' + this.santiyeId + '/';
  }

  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.click();
  }
  /**
   * Resimler seçildikden sonra çalışan fonksiyon.
   * Seçilen resimleri daha önceden eklenen fotograf dizisine ekler.
   * başlangıcda liste de 0 tane eleman vardırç
   * @param currentFiles currentFiles: File[] = [] olarak tanımlanır.
   * @param event Seçilen dosyaların listesinin bulunduğu event openFile dialoğun geri dönüşüdür. 
   */
  openFileDialog(event) {
    // uyumsus dosyaların ismleri burda toplanacak.
    let ignores: string = '';
    this.currentFiles = [...this.currentFiles, ...(Array.from((event.target as HTMLInputElement).files)
      .filter((file: File) => { // Seçilen tüm dosyalar arasdından uyumsuz olanalrını ayıklar
        // eğer bu dosya resim ise onu kabulet
        if (file.type.indexOf('image') >= 0) {
          return true;
        } else { // eğer resim değilse onu kabul etmez. ve ignores string listesine ekler.
          ignores += '<br><div style="padding: 5px;border-bottom: 1px solid #ebebeb;">' + file.name + '</div>';
          return false;
        }
      }))];
    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (ignores.length > 0) {
      Swal.fire({
        icon: 'error', title: 'Lütfen sadece uyumlu resim dosyalarını seçiniz.',
        html: '<div style="color: #f27474;text-align: left !important;">'
          + ignores + '</div>', showConfirmButton: true,
      });
    }
  }
  /**
   * Dosya sistemden başarılı birşekilde gösterildiğinde tetiklenir.
   */
  successLoad() {
    console.log(this.tag, 'loaded');
  }
  /**
   * Resim sunucuya başarılı bir şekilde yüklendiğinde çalışır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  success(event: ApasImageUploaderComponent, i) {
    // console.log(this.tag, 'Resim başarılı birşekilde yüklendi', event);
    if (!this.files.some(f => f.isSuccess === false)) {
      Swal.fire({
        icon: 'success',
        title: 'İşlem başarılı',
        html: 'Tüm resimler başarılı birşekilde yüklendi',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        this.onSuccessUploads.emit(null);
        this.currentFiles = [];
      });
      this.closed(true);
    }
  }
  /**
   * Seçilen resmi listeden kaldırır.
   * @param event fonksiyonu cağıran component
   * @param i componentin id  si
   */
  remove(event, i) {
    // console.log(this.tag, 'Resim başarılı bir şekilde silindi:', event, 'index:', i);
    this.currentFiles = this.currentFiles.filter((item, index) => index !== i);
  }
  /**
   * Tümresimleri zaten yüklenmemiş olan tüm resimleri sunucuya yükler.
   */
  uploadAll() {
    if (this.files) {
      this.files.forEach(f => {
        if (!f.isSuccess) { f.upload(); }
      });

    } else {
      throw new Error('@ViewChild files bulunamadı.');
    }
  }


}
