import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, switchMapTo } from 'rxjs/operators';
import { convertDateWithTZ } from '../../../../pages/global-functions';
import { ApasCrypto } from '../../../../shared/ApasEncryptor/apas-encryptor';
import { MusteriService } from '../../crm-main/musteri/musteri.service';
import * as musterilerAction from './musteriler.actions';


@Injectable()
export class MusteriEffects {

  @Effect()

  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(musterilerAction.loadData),
      mergeMap((props) => this.musteriService.getMusteriList(props)
        .pipe(
          map((data: any) => {
            return (musterilerAction.loadDataSuccess({ data: data }));
          }),
          catchError(() => of(musterilerAction.loadDataFail({ error: 'Müşteriler Fail', isEnd: false }))),
        )),
    );
  });


  addNewItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(musterilerAction.addNewItem),
      mergeMap((props) => this.musteriService.addMusteri(props)
        .pipe(
          map((data: any) => {
            return (musterilerAction.addNewItemSuccess({ data: data }));
          }),
          catchError(() => of(musterilerAction.addNewItemFail({ error: 'Müşteriler Add Fail' }))),
        )),
    );
  });

  patchItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(musterilerAction.patchItem),
      mergeMap((props: any) => this.musteriService.updateMusteri(props.id, props.data)
        .pipe(
          map((data: any) => {
            return (musterilerAction.addNewItemSuccess({ data: data }));
          }),
          catchError(() => of(musterilerAction.addNewItemFail({ error: 'Müşteriler Patch Fail' }))),
        )),
    );
  });

  getDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(musterilerAction.getDetail),
      switchMap((props) => this.musteriService.getMusteriDetail(props.selectedId).pipe(
        // tslint:disable-next-line: max-line-length
        catchError((err: HttpErrorResponse) => of(musterilerAction.addNewItemFail({ error: 'Müşteriler Patch Fail' }))),
      )),
      switchMap(async (data: any) => {
        const decryptor = new ApasCrypto();
        data = await this.clearData(data, decryptor);
        return (musterilerAction.addNewItemSuccess({ data: data }));
      },
      ),
    );
  });

  clearData = async (data, decryptor: ApasCrypto) => {
    console.log('data?.sonraki_taksit?.price', data?.sonraki_taksit?.price, data);
    if (data.sonraki_taksit) {
      data.sonraki_taksit.price = await decryptor.decrypt(data?.sonraki_taksit?.price);
      data.sonraki_taksit.date = moment(data.sonraki_taksit.date, 'X').tz('Europe/Istanbul').toDate();
      let dateDiff: string | number = moment(data.sonraki_taksit.date).diff(moment(), 'days');
      if (dateDiff > 0) {
        dateDiff = `${dateDiff} Gün Sonra.`;
        data.sonraki_taksit.date_color = '#27ae60';
      } else if (dateDiff === 0) {
        dateDiff = 'Bugün';
        data.sonraki_taksit.date_color = '#073b4c';
      } else {
        dateDiff = `${dateDiff * -1} Gün Gecikti.`;
        data.sonraki_taksit.date_color = '#e62452';
      }
  
      data.sonraki_taksit.date_diff = dateDiff;
      // data.kalan_taksit.forEach(async (taksit) => {
      //   taksit.kalan_taksit = await decryptor.decrypt(taksit.kalan_taksit);
      // });
  
      // data.toplam_taksit.forEach(async (taksit) => {
      //   taksit.toplam_taksit = await decryptor.decrypt(taksit.toplam_taksit);
      // });
    }

    return data;
  }

  constructor(
    private actions$: Actions,
    private musteriService: MusteriService,
  ) { }
}
