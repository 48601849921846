import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SantiyeModel } from '../../../models/santiye_model';
import {
  acceptFileFormatsList,
  responseKodlari,
  tarih_format,
} from '../../../pages/constants';
import { FormBuild } from '../../../pages/formInterfaces';
import { downLoadPdf, findExtantion, removeAllNull } from '../../../pages/global-functions';
import { SantiyeService } from '../../../services/santiye.service';


@Component({
  selector: 'santiye-dosyalar',
  templateUrl: './santiye-dosyalar.component.html',
  styleUrls: ['./santiye-dosyalar.component.css'],
  providers: [SantiyeService],
  encapsulation: ViewEncapsulation.None,
})
export class SantiyeDosyalarComponent implements OnInit, FormBuild {
  tarih_format = tarih_format;
  santiye: SantiyeModel;
  santiyeTitle: string;
  @ViewChild('myTable')
  table: any;

  expanded: any = {};
  timeout: any;
  duration: number;
  rows = [];
  temp = [];
  SortType = SortType;

  ColumnMode = ColumnMode;

  currentFile: File;
  acceptFileFormats: string[] = acceptFileFormatsList;

  percentDone: number = 100;

  isLoading: boolean = false;
  isSubmitting: boolean = false;
  form: FormGroup;
  tag: string = 'SantiyeDosyalarComponent ----->';

  constructor(
    private ref: NbDialogRef<SantiyeDosyalarComponent>,
    private fb: FormBuilder,
    private santiyeService: SantiyeService,
  ) {
    this.initForm();
  }

  /**
   * Servisi henüz yazılmadı çünkü back çalışmıyor.
   */

  ngOnInit() {
    if (this.santiye.id === undefined || this.santiye.id === null) {
      throw new Error(
        this.tag + ' Santiye.id is dont gived in parent component!',
      );
    } else {
      console.log(this.tag, 'santiye.id:', this.santiye.id);
      this.form.get('santiye_id').setValue(this.santiye.id);
      this.isLoading = true;
      this.santiyeService.getFiles(this.santiye.id).subscribe(
        (response) => {
          this.rows = response;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      santiye_id: [null, Validators.required],
      title: [],
      note: [],
      _file: [null, Validators.required],
    });
  }
  closed() {
    this.ref.close();
  }

  // Arama inputu gizleyip gösterme
  sGizleGoster: boolean = false;
  mIcon: string = 'search';
  searchGizleGoster() {
    if (this.sGizleGoster === false) {
      this.sGizleGoster = true;
      this.mIcon = 'close';
    } else {
      this.sGizleGoster = false;
      this.mIcon = 'search';
    }
  }

  downLoadFile(id: number, row?: any) {
    if (!row._file) {
      this.santiyeService.getFilesPercent(this.santiye.id, row.id).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              row['percentDone'] = 0;

              row['isDownloading'] = true;
              break;
            case HttpEventType.DownloadProgress:
              row['percentDone'] = Math.round(
                (100 * event.loaded) / event.total,
              );

              break;
            case HttpEventType.Response:
              row['_file'] = event.body;
              row['isDownloading'] = false;
              row['_file'] = event.body._file;
              downLoadPdf(row._file, row.title);
              console.log('response:', event);
              break;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          }
        },
      );
    } else {
      downLoadPdf(row._file, row.title);
    }
  }

  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.value = '';
    fileInput.click();
  }
  /**
   * Dosya(lar) seçildikden sonra çalışan fonksiyon.
   * @param event Seçilen dosya(lar)ın listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  openFileDialog(event) {
    // yeni bir dosya seçildiği icin eski dosyanın adını siler.
    // this.clearSelection();
    this.isLoading = true;

    // tek bir dosya alıcağım icin ilk dosyayı seçiyorum.
    const tmpFile: File = (event.target as HTMLInputElement).files[0];

    // this.currentFile = (event.target as HTMLInputElement).files[0];
    // const fileName = tmpFile.name.split('.');
    const fileName = tmpFile.name.split(new RegExp('.[a-z]*$', 'gmi'))[0];

    console.log('tmpFile:', tmpFile);

    console.log('fileName:', fileName);

    // Seçilen elemanon dosya uzantısının doğru olup olmadığına bakılır.
    let isFileCurrect;
    const format = findExtantion(tmpFile.type);
    isFileCurrect = acceptFileFormatsList.find(x => x === format);
    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (!isFileCurrect) {
      this.isLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Lütfen sadece uyumlu bir dosya seçiniz.',
        html:
          '<div style="color: #f27474;text-align: left !important;">' +
          fileName +
          '</div>',
        showConfirmButton: true,
      });
    } else {
      this.currentFile = tmpFile;
      const reader = new FileReader();
      reader.onload = () => {
        this.isLoading = false;
        const fileBase64 = reader.result as string;
        this.form.get('_file').patchValue(fileBase64);
      };
      reader.readAsDataURL(tmpFile);
      this.form.get('title').patchValue(fileName);
    }
  }

  uploadFile() {
    const formData = this.valueToForm();
    if (this.form.valid) {
      this.santiyeService.uploadFile(formData).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.percentDone = 0;
              this.isSubmitting = true;
              break;
            case HttpEventType.UploadProgress:
              this.percentDone = Math.round((100 * event.loaded) / event.total);
              break;
            case HttpEventType.Response:
              this.isSubmitting = false;
              console.log('response:', event);
              this.succesUploadFile(event.body);

              Swal.fire({
                ...responseKodlari.successProgress,
                timer: 3000,
                showConfirmButton: true,
              } as SweetAlertOptions).then(() => {
                this.clearSelection();
              });

              break;
          }
        },
        (err: HttpErrorResponse) => {
          this.isSubmitting = false;
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        },
      );
    } else {
      if (this.santiye.id === undefined || this.santiye.id === null) {
        Swal.fire({
          ...responseKodlari.unValidForm,
          text:
            'Şantiye bilgisi alınamadı! Lütfen daha sonra tekrar deneyiniz.',
        } as SweetAlertOptions);
      } else {
        Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      }
    }

    // setTimeout(() => this.succesUploadFile(), 100);
  }
  valueToForm(): any {
    // console.log(this.tag, 'form:', this.form);this.form.value

    console.log(this.tag, ' form data:', this.form.value);
    const data = removeAllNull(this.form.value);
    console.log(this.tag, 'cleared form data:', data);

    return data;
  }
  succesUploadFile(data) {
    this.rows = [...this.rows, ...[data]];
    // console.log(this.tag, 'rows:', this.rows);
    this.clearSelection();
  }
  dosyaTableArea(event) {
    console.log('test:', event);
  }
  clearSelection() {
    this.form.reset({ santiye_id: this.santiye.id });
  }
  deleteFile(row) {
    Swal.fire(
      {...responseKodlari.shureToDelete, ...{text: `${row.title} başlıklı dosyayı silmek istedinizden emin misiniz? Bu işlem geri alınamaz.`} } as SweetAlertOptions,
    ).then(({ value }) => {

      if (value) {
        this.santiyeService.deleteFile(row.id).subscribe(resp => {
          this.rows = this.rows.filter(x => x.id !== resp.id);
          this.rows = [...this.rows];
        });
      }
    });
  }
}
