import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { cariHareketTypes, responseKodlari } from '../../../../../../constants';
import { MuhasebeService } from '../../../../../muhasebe-service/muhasebe.service';
import { formatNumberWithCurrency, removeAllNull } from '../../../../../../global-functions';
import { ApasCrypto } from '../../../../../../../shared/ApasEncryptor/apas-encryptor';

@Component({
  selector: 'apx-odeme-plani-taksit-ode',
  templateUrl: './odeme-plani-taksit-ode.component.html',
  styleUrls: ['./odeme-plani-taksit-ode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class OdemePlaniTaksitOdeComponent implements OnInit {

  @Input() data: any;
  @Input() cariId: any;
  form: FormGroup;
  isLoading: boolean = false;
  selectedTaksit;
  @Input() editMod: boolean = false;


  constructor(
    private fb: FormBuilder,
    private muhasebeService: MuhasebeService,
    private ref: NbDialogRef<OdemePlaniTaksitOdeComponent>,
  ) {
    this.initForm();
  }

  onTaksitSelect(event) {
    this.data = event;
    this.setValueToForm();
  }

  ngOnInit() {
    if (this.selectedTaksit) {
      this.onTaksitSelect(this.selectedTaksit);
      this.cariId = this.selectedTaksit.sender_cari_id;
    } else {
      this.setValueToForm();
    }
  }
  forceRefresh = 0;
  setValueToForm() {
    console.log('DATA : ', this.data);
    if (this.data?.reciver_cari_id) {
      this.cariId = this.data?.reciver_cari_id;
    }
    this.form.get('currency').setValue(this.data?.currency);
    this.form.get('total_price').setValue(this.data?.must_pay_price);
    this.form.get('must_pay_price').setValue(this.data?.must_pay_price);
    this.form.get('total_price').setValidators([Validators.required, Validators.min(0.1), Validators.max(this.data?.must_pay_price)]);

    this.form.get('taksit_id').setValue(this.data?.id);
    if (this.data?.date) {
      const mt = moment(this.data.date, 'X').tz(this.data.gmt).toDate();
      this.form.get('date').setValue(mt);
    }
    this.form.get('note').setValue(this.data?.note);
    this.forceRefresh += 1;
  }

  initForm() {
    this.form = this.fb.group({
      taksit_id: [null, Validators.required],
      reciver_account: [null, Validators.required],
      currency: [null, Validators.required],
      must_pay_price: [null, Validators.required],
      total_price: [null, Validators.required],
      date: [new Date(), Validators.required],
      note: [],
      hareket_type: [cariHareketTypes.odemeTaksitTahsilat],
    });
  }

  close() {
    this.ref.close();
  }

  account_type = ['BankAccount', 'PosAccount', 'CaseAccount'];
  selectedHesapTitle: string;

  hesapSelect(event) {
    this.selectedHesapTitle = event?.title;
  }

  async postForm() {

    if (!this.form.valid) {
      console.log('FORM ERROS : ', this.form);
      this.form.markAllAsTouched();
      return;
    }

    const must_pay: number = Number(this.form.get('must_pay_price').value);
    const total_price: number = Number(this.form.get('total_price').value);
    const diff = must_pay - total_price;

    console.log('DIFF : ', diff);
    let swalAnswer = true;
    if (diff > 0) {

      const sweetOptions: SweetAlertOptions = {
        icon: 'warning',
        title: 'Emin Misiniz?',
        html: `Yaptığınız bu ödeme sonucunda, taksit tutarının <strong><u> tümü ödenmediği için; </u></strong>
        Mevcut taksit tutarı <strong>${formatNumberWithCurrency(total_price, this.form.get('currency').value)}</strong>
        olarak güncellenecek ve
        <strong>${formatNumberWithCurrency(diff, this.form.get('currency').value)} </strong> tutarında <strong><u>bir taksit daha oluşturulacaktır</u>.</strong> </br>
        <div style="display:flex; justify-content: center; font-size: larger; color: #01579b;"> <strong> Onaylıyor Musunuz? </strong> </div>`,
        showCancelButton: true,
        cancelButtonText: 'İptal',
        showConfirmButton: true,
        confirmButtonText: 'Evet',
      };
      swalAnswer = await Swal.fire(sweetOptions).then(({ value }) => {

        console.log('Click Value : ', value);

        if (value === true) {
          console.log('evet clicked');
          return true;
        }
        return false;
      });
    }

    console.log('SWAL ONAYLANDI : ', swalAnswer);
    if (!swalAnswer) return;

    this.isLoading = true;

    const formVal = removeAllNull(this.form.value);

    const crypto = new ApasCrypto();
    formVal['must_pay_price'] = (await crypto.encrypte(must_pay));
    formVal['total_price'] = (await crypto.encrypte(total_price));


    formVal.instance_type = 'cari';
    formVal.instance_id = this.cariId;

    this.muhasebeService.payOdemePlaniTaksit(this.data.id, formVal).subscribe(
      (resp) => {
        this.isLoading = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.ref.close(resp);
      },
      (err) => {
        this.isLoading = false;
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      },
    );
  }
}
