import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApasInfo } from '../../../pages/constants';
import { isDevMode } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { SetUrl } from '../../../store/aplikasyon/aplikasyon.actions';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.html',

})
export class FooterComponent implements OnInit, OnDestroy {
  apasInfo = ApasInfo;
  wpGoster = false;
  mesaj;

  devMode: boolean = false;
  constructor(private store: Store<{}>) {

    this.devMode = isDevMode();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }


  wpMesajGonder() {
    const url = 'https://api.whatsapp.com/send?text=' + this.mesaj + '&phone=905547231927';
    window.open(url, '_blank');
  }

  backendUrl: string = environment.backendurl;
  changeBackendUrl(event) {

    if (event) {
      this.backendUrl = environment.testendurl;
    } else {
      this.backendUrl = environment.backendurl;
    }

    this.store.dispatch(SetUrl({ URL: this.backendUrl }));

  }
}

