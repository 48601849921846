

<nb-card class="modalType" size="giant" style="width: 60%;border: unset;">

    <nb-card-header>
        <div class="d-flex d-flex justify-content-between align-items-center">
            <div>{{item?.title}}</div>
            <div>
                <button nbButton status="basic" (click)="close()">
                    <nb-icon icon="close-outline"></nb-icon>
                </button>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body #frame *ngIf="item?.file_url && previewUrl && docOrImage == 'doc'" style="overflow-y: hidden;">
        <iframe width='100%' height='100%' [src]="previewUrl | safe" frameborder="0"></iframe>
    </nb-card-body>

    <nb-card-body *ngIf="docOrImage === 'image'" [nbSpinner]="isLoading">
        <img [src]="item?._file | safe" alt="image" style="width: 100%; height: auto;">
    </nb-card-body>

    <nb-card-body *ngIf="docOrImage == 'pdf'" style="overflow: hidden;">
        <!-- bu alan pdf -->
        <div id='pdf-div' class='pdf-view'></div>
    </nb-card-body>
    <nb-card-footer>

        <table>

            <tr *ngIf="item?.note" class="pb-1" [nbPopover]="notePop" [nbPopoverContext]="item?.note"
                nbPopoverTrigger="hover" nbPopoverPlacement="bottom">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Açıklama
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.note | slice : 0: 300}} <b *ngIf="item?.note?.length > 300"> ... </b>
                </td>
            </tr>


            <tr *ngIf="item?.created_by_name" class="pb-1">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Ekleyen
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.created_by_name}}
                </td>
            </tr>

            <tr *ngIf="item?.created_time">
                <td class="d-flex">
                    <label class="label form-label alignVertical">
                        Eklenme Tarihi
                    </label>
                </td>
                <td class="pl-4">
                    {{item?.created_time | date : tarih_format_long}}
                </td>
            </tr>
        </table>
        <div class="row d-flex justify-content-center">
            <button nbButton (click)="indir()" status="info" #hero>
                <nb-icon icon="download-outline"></nb-icon> İndir
            </button>

        </div>

    </nb-card-footer>
</nb-card>


<ng-template #notePop let-note>
    <div style="padding: 8px; border-radius: 4px; background-color: #151a30; color: white; width: 600px; height: auto;">
        {{note}}</div>
</ng-template>