<nb-card class="card" *ngIf="isCard" [style]="santiyeMap ? 'border: none;width:100%;height:300px;':''">
    <nb-card-body [class]="santiyeMap ? 'p-1':''">
        <agm-map class="google-map" [latitude]="lat" [longitude]="lng" [zoom]="15" [mapTypeControl]="true">
            <agm-marker [markerDraggable]="draggable" [latitude]="lat" [longitude]="lng" ></agm-marker>

        </agm-map>
    </nb-card-body>
</nb-card>

<div *ngIf="!isCard">


    <div class="row mr-3 mr-2">
        <div class="col-md-11 pr-0">
            <div class="form-group">
                <label for="search"><b>{{'MAP.ARA'|translate}}</b></label>
                <input nbInput id="search" [(ngModel)]="searchInput" fullWidth required [placeholder]="'MAP.ARA'|translate" [ngModelOptions]="{standalone: true}" name="search" style="background:#607d8b42;">
            </div>

        </div>

        <div class="col-md-1 p-0  mt-4"> <button (click)="showLocation()" nbButton>{{'MAP.ARA'|translate}}</button> </div>
    </div>
</div>

<agm-map *ngIf="!isCard" class="google-map" [latitude]="location?.lat" [longitude]="location?.lng" (mapClick)="mapClicked($event)" [zoom]="15" [disableDefaultUI]="false" [mapTypeControl]="true">
    <agm-marker #m [latitude]="location?.lat" [longitude]="location?.lng" [markerDraggable]="true"   (drag)="markerDragged($event)" (dragEnd)="DragEnd()">
    </agm-marker>
</agm-map>




<!--

            <agm-marker
        (drag)="markerDragEnd($event)"
        [markerDraggable]="draggable"
        [latitude]="lat" [longitude]="lot">
    </agm-marker>

-->