
  <ng-select
      [items]="santiyeList$ | async"
      [loading]="isLoading"
      [loadingText]="'Yükleniyor...'"
      appendTo="body"
      [typeahead]="santiyeInput$"
      typeToSearchText="Arama yapmak için en az 2 karakter giriniz."
      [minTermLength]="2"
      bindLabel="title"
      bindValue="id"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'ISMAKINASIPUANTAJRAPORU.SANTIYESEC'|translate"
      class="uzun-select"
      ngDefaultControl
      (change)="santiyeSelected($event)"
  >

  </ng-select>