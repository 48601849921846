import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SantiyeModel } from '../../../../../models/santiye_model';
import { calismaSekli, responseKodlari } from '../../../../../pages/constants';
import { calculateTimeBetweenStartEnd, convertDateWithTZ, removeAllNull } from '../../../../../pages/global-functions';
import { timeValidator } from '../../../../../pages/validators';
import { IsMakinalariService } from '../../../../../services/is_makinalari.service';

@Component({
  selector: 'ngx-calisma-guncelle',
  templateUrl: './calisma-guncelle.component.html',
  styleUrls: ['./calisma-guncelle.component.scss'],
  providers: [IsMakinalariService],
})
export class CalismaGuncelleComponent implements OnInit {
  editData;
  form: FormGroup;
  calismaSekli = calismaSekli;
  isLoading: boolean = false;
  constructor(
    private ref: NbDialogRef<CalismaGuncelleComponent>,
    private fb: FormBuilder,
    private isMakinalariService: IsMakinalariService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.editData) {
      this.editData.start_time = convertDateWithTZ(this.editData, 'start_time');
      this.editData.finish_time = convertDateWithTZ(this.editData, 'finish_time');
      this.form.patchValue(this.editData);
      this.calcdatediff();
    }
  }
  initForm() {
    this.form = this.fb.group({
      id: [],
      santiye_id: [],
      machine_type_id: [null, Validators.required],
      start_time: [null, Validators.required],
      finish_time: [null, Validators.required],
      sefer: [],
      work_type: [null, Validators.required],
      note: [null],
      diff: [null],
    },
      {
        validator: timeValidator, // custom validation 
      },
    );
    this.form.get('work_type').valueChanges.subscribe(val => {
      if (val === 'saat') {
        this.form.get('sefer').setValidators(null);
        this.form.get('sefer').updateValueAndValidity();
      } else {
        this.form.get('sefer').setValidators(Validators.required);
        this.form.get('sefer').updateValueAndValidity();

      }
    });
  }

  santiyes: SantiyeModel[] = null;

  getSantiyes(id?: number) {
    const machineId = id ? id : this.editData.id; 
    this.isMakinalariService.getSantiyeListForMachine(machineId).subscribe(data => {
      this.santiyes = data;
    });
  }
 
  closed(success?) {
    this.ref.close(success);
  }

  calcdatediff() {
    const startTime = this.form.get('start_time').value;
    if (!startTime) {
      this.form.get('diff').setValue(null);
      return;

    }

    const finishTime = this.form.get('finish_time').value;
    if (!finishTime) {
      this.form.get('diff').setValue(null);
      return;

    }
    const calculated = calculateTimeBetweenStartEnd(startTime, finishTime);

    this.form.get('diff').setValue(calculated);

  }

  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = removeAllNull(this.form.getRawValue(), null, false);

    this.isMakinalariService.updateMotorInfo(this.editData.id, formData).subscribe(val => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.closed(val);

    }, (err) => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

}
