import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'birimfiyat-add',
  templateUrl: './birimfiyat-add.component.html',
  styleUrls: ['./birimfiyat-add.component.css']
})
export class BirimfiyatAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
