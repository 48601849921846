
  <ng-select
      [items]="makinaTypeList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="makinaInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindValue="id"
      bindLabel="title"
      [addTag]="addTagPromise"
      [addTagText]="'Ekle'"
      [multiple]="multiple"
      [selectOnTab]="true"
      [placeholder]="'Makina Tipi'"
      class="uzun-select"
      ngDefaultControl
      (change)="makinaSelect($event)"
      [formControl]="control"
  >
  </ng-select>