import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { JobModel } from '../../shared/selects/job-select/job.model';
import { JobService } from '../../shared/selects/job-select/job.service';
import * as jobActions from './jobs.actions';

@Injectable()
export class JobEffects {

    loaHesap$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jobActions.loadJobs),
            mergeMap((props) => this.jobService.getJobList()
                .pipe(
                    map((jobs: JobModel[]) => {

                        return (jobActions.loadJobsSuccess({ jobItems: jobs}));
                    }),
                    catchError(() => of(jobActions.loadJobsFail('Meslekler yüklenemedi.'))),
                )),
        );
    });

    constructor(
        private actions$: Actions,
        private jobService: JobService,
    ) {

    }

}
