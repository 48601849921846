import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { EFaturaGirisComponent } from '../../e-fatura-sureci/e-fatura-giris/e-fatura-giris.component';

@Component({
  selector: 'ngx-basvuru-bilgisi',
  templateUrl: './basvuru-bilgisi.component.html',
  styleUrls: ['./basvuru-bilgisi.component.scss'],
})
export class BasvuruBilgisiComponent implements OnInit {

  constructor(
    private nbDialogService: NbDialogService,
    private ref: NbDialogRef<BasvuruBilgisiComponent>,
  ) { }

  ngOnInit(): void {
  }

  entegrasyonYap() {
    this.nbDialogService.open(EFaturaGirisComponent).onClose.subscribe(resp => {
      if (resp) {
        this.ref.close(resp);
      }
    });
  }

  close(resp?) {
    this.ref.close(resp);
  }
}
