import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TalepService } from '../../../services/talep.service';
import { Constants, getMoneyCurrency, tarihFullFormat, telefonKodu } from '../../../pages/constants';
import { tarih_format } from '../../../pages/constants';
import { TalepModalComponent } from '../talep-modal/talep-modal.component';
import { TeklifService } from '../../../services/teklifler.service';
import { convertDateWithTZ, downLoadPdf } from '../../../pages/global-functions';


@Component({
  selector: 'talep-detay',
  templateUrl: './talep-detay.component.html',
  styleUrls: ['./talep-detay.component.css'],
  providers: [TalepService, TeklifService],
})
export class TalepDetayComponent implements OnInit {
  telefonKodu = telefonKodu[0].mask;
  talepDetay: any;
  convert = Constants.prototype;
  tarihFullFormat = tarihFullFormat;
  tarih_format = tarih_format;
  isLoading: boolean = true;
  getMoneyCurrency = getMoneyCurrency;


  urun_list: any[] = [];
  firmalar: any[] = [];
  teklifler: any[] = [];
  @Input() talepId: number;
  constructor(private ref: NbDialogRef<TalepDetayComponent>,
    private nbDialogService: NbDialogService,
    private talepService: TalepService,
    private teklifService: TeklifService) {
  }

  ngOnInit() {

    this.talepService.getTalepDetails(this.talepId).subscribe(data => {
      this.talepDetay = data;
      // Çok Şey Yapar. Gelen Veriden firma - ürün eşleşmelerini ayrı listere atayarak, 2 dizi oluşturur.

      this.talepDetay?.teklifs?.firmalar.forEach(elm => {
        elm['date'] = convertDateWithTZ(elm, 'date');
      });
      this.isLoading = false;

    }, err => {
      this.isLoading = false;
    });
  }

  toplamTutarHesapla(firma) {
    const TL: number[] = [];
    const USD: number[] = [];
    const EUR: number[] = [];
    firma.teklifler.forEach((el) => {
      if (el.amount_currency === 1) {
        TL.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else if (el.amount_currency === 2) {
        USD.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else if (el.amount_currency === 3) {
        EUR.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else {
        TL.push(Number(el.unit_price) * Number(el.teklif_amount));
      }
    });
    // TL dizisinin icini toplar.
    // tslint:disable-next-line: max-line-length
    return { TL: TL.reduce((toplam, f) => toplam + f, 0), USD: USD.reduce((toplam, f) => toplam + f, 0), EUR: EUR.reduce((toplam, f) => toplam + f, 0) };
  }

  historyList = [];
  isHistoryLoading: boolean = false;
  getHistory(itemId) {
    this.historyList = [];
    this.isHistoryLoading = true;
    setTimeout(() => {
      this.teklifService.getItemHistory(itemId).subscribe(resp => {
        this.historyList = resp;
        this.isHistoryLoading = false;
      }, (err) => {
        this.isHistoryLoading = false;
      });
    }, 150);

  }

  closed() {
    this.ref.close();
  }

  openBox(row) {
    this.convert.setTalep(row.id);
    this.nbDialogService.open(TalepModalComponent, {
      context: {
        talep: row,
      },
    });
  }

  dosyaSpinner: boolean = false;
  teklifDownload(id) {
    this.dosyaSpinner = true;
    if (id) {
      this.teklifService.getTeklifFile(id).subscribe(data => {
        downLoadPdf(data.teklif_file, data.title);
        this.dosyaSpinner = false;
      }, (err) => {
        this.dosyaSpinner = false;
      });
    }
  }


}
