<nb-card style="width: 500px;" size="giant" class="modalType animated fadeInRight">
  <nb-card-header >
    {{ 'HATIRLATMALAR.TARIHI' | translate }}
    {{ hatirlatmaDetay.reminder_time * 1000 | date: 'dd/MM/yyyy - ' }} <b
      style="color:#ff7043">{{ hatirlatmaDetay.reminder_time * 1000 | date: 'HH:mm ' }}</b>

    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
    color: #000 !important;
    float: right;
    font-size: 18px;">X</button>

    <button nbButton status="warning" style="float:right;" size="tiny" *ngIf="!hatirlatmaDuzenle"
      (click)="duzenle()">{{ 'HATIRLATMALAR.DUZENLE' | translate }}</button>
    <button nbButton status="danger" style="float:right;" size="tiny" *ngIf="hatirlatmaDuzenle"
      (click)="duzenle()">{{ 'HATIRLATMALAR.IPTAL' | translate }}</button>


  </nb-card-header>
  <nb-card-body  [nbSpinner]="isSubmitting"
  nbSpinnerSize="tiny">

    <p *ngIf="!hatirlatmaDuzenle">
      {{hatirlatmaDetay.note}}
    </p>

    <form *ngIf="hatirlatmaDuzenle" [formGroup]="hatirlatmaForm">
      <div class="form-group">
        <label><b>{{ 'HATIRLATMALAR.ACIKLAMA' | translate }}</b></label>
        <textarea type="text" [placeholder]="'HATIRLATMALAR.ACIKLAMA' | translate" formControlName="note" nbInput fullWidth></textarea>
        <div class="uyari"
          *ngIf="hatirlatmaForm.get('note').touched && hatirlatmaForm.get('note').hasError('required')">
          {{ 'HATIRLATMALAR.UYARI' | translate }} 
        </div>
      </div>

      <div class="form-group">|
        <label><b>{{ 'HATIRLATMALAR.TARIHI' | translate }}{{'HATIRLATMADETAY.HATIRLATMATARIHI'|translate}}</b></label>
        <input nbInput fullWidth [nbDatepicker]="formpicker" formControlName="gun" [placeholder]="'HATIRLATMADETAY.HATIRLATMATARIHI'|translate">
        <nb-datepicker #formpicker [min]="min"></nb-datepicker>

        <div class="uyari" *ngIf="hatirlatmaForm.get('gun').touched && hatirlatmaForm.get('gun').hasError('required')">
          {{ 'HATIRLATMALAR.UYARI' | translate }}
        </div>
      </div>


      <div class="form-group">
        <label><b>{{ 'HATIRLATMALAR.SAATI' | translate }}</b></label>
        <div class="24hr-example" style="margin-top: 5px;">
          <input [placeholder]="'HATIRLATMALAR.SAATI' | translate" formControlName="saat" nbInput fullWidth aria-label="24hr format"
            [ngxTimepicker]="fullTime" [format]="24" readonly>
          <div class="uyari"
            *ngIf="hatirlatmaForm.get('saat').touched && hatirlatmaForm.get('saat').hasError('required')">
            {{ 'HATIRLATMALARIM.UYARI' | translate }} 
          </div>
          <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
          </ngx-material-timepicker>
          <ng-template #cancelBtn>
            <button style="margin-right: 10px;" nbButton status="warning" size="small">{{ 'HATIRLATMALAR.IPTAL' | translate }}</button>
          </ng-template>
          <ng-template #confirmBtn>
            <button nbButton status="primary" size="small">{{ 'HATIRLATMALAR.TAMAMLA' | translate }}</button>
          </ng-template>
        </div>
      </div>
      <div class="text-center">
        <button nbButton (click)="hatirlatmaGuncelle()">{{ 'HATIRLATMALAR.GUNCELLE' | translate }}</button>
      </div>
    </form>



  </nb-card-body>
  <nb-card-footer style="opacity: 0.5;">{{ 'HATIRLATMALAR.EKLEME' | translate }}
    <b>{{ hatirlatmaDetay.created_time * 1000 | date: 'dd/MM/yyyy - HH:mm' }} </b></nb-card-footer>


</nb-card>