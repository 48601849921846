import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { TaseronService } from '../../../../services/taseron.service';
import { JobService } from '../../../../shared/selects/job-select/job.service';

@Component({
  selector: 'personel-excel-import',
  templateUrl: './personel-excel-import.component.html',
  styleUrls: ['./personel-excel-import.component.scss'],
})
export class PersonelExcelImportComponent implements OnInit {
  meslekler = [];
  firma;
  constructor(
    private ref: NbDialogRef<PersonelExcelImportComponent>,
    private jobService: JobService,
    private taseronService: TaseronService,
  ) { }

  ngOnInit(): void {
    this.getMeslekList();
  }

  getMeslekList() {
    this.jobService.getJobList().subscribe(data => {
      this.meslekler = data;
      this.generateMeslekStr();
    });
  }

  meslekStr: string;
  generateMeslekStr() {
    this.meslekStr = '';
    this.meslekler.forEach((elm, i) => {
      elm.name = elm.name.trim();
      this.meslekStr = this.meslekStr + elm.name + ',';
    });
    this.meslekStr = this.meslekStr.slice(0, -1);
    // this.meslekStr = `"${this.meslekStr}"`

  }
  close(resp?) {
    this.ref.close(resp);
  }
  exampleExcelLoading: boolean = false;

  getExampleExcel() {
    this.meslekStr = this.meslekStr.toLowerCase();
    this.exampleExcelLoading = true;
    const workBook = new Workbook();

    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 9,
      bold: true,
    };


    const tumPersonellerSheet = workBook.addWorksheet('Personeller');
    const personelBaslikRow = tumPersonellerSheet.getRow(1);


    /*Meslekler İçin */
    const mesleklerSheet = workBook.addWorksheet('Meslekler');
    let meslekTitleRow = mesleklerSheet.getRow(1);
    let meslekTitleCell = meslekTitleRow.getCell('A');
    this.meslekler.forEach((data, i = 2) => {
      meslekTitleRow = mesleklerSheet.getRow(i + 1);
      meslekTitleCell = meslekTitleRow.getCell('A');
      meslekTitleCell.value = data.name;
    });
    /*Meslekler İçin */

    personelBaslikRow.height = 20;

    const personelAdiColumn = tumPersonellerSheet.getColumn('A');
    personelAdiColumn.width = 50;

    const personelSoyadiColumn = tumPersonellerSheet.getColumn('B');
    personelSoyadiColumn.width = 50;

    const kimlikNoColumn = tumPersonellerSheet.getColumn('C');
    kimlikNoColumn.width = 50;

    const phoneColumn = tumPersonellerSheet.getColumn('D');
    phoneColumn.width = 50;

    const meslekColumn = tumPersonellerSheet.getColumn('E');
    meslekColumn.width = 50;

    const numFmtStr = '90##########;(###)#######';
    phoneColumn.numFmt = numFmtStr;

    const personelAdi = personelBaslikRow.getCell('A');
    personelAdi.value = 'Adı';
    personelAdi.font = font;

    const personelSoyadi = personelBaslikRow.getCell('B');
    personelSoyadi.value = 'Soyadı';
    personelSoyadi.font = font;

    const kimlikNo = personelBaslikRow.getCell('C');
    kimlikNo.value = 'Kimlik No';
    kimlikNo.font = font;

    const phone = personelBaslikRow.getCell('D');
    phone.value = 'Telefon';
    phone.font = font;


    const meslek = personelBaslikRow.getCell('E');
    meslek.value = 'Meslek';
    meslek.font = font;





    let i = 2;
    while (i < 100) {
      const currentRow = tumPersonellerSheet.getRow(i);
      const meslek = currentRow.getCell(5);
      meslek.dataValidation = {
        type: 'list',
        allowBlank: false,
        showErrorMessage: true,
        formulae: ['Meslekler!A1:A514'],
      };
      meslek.value = 'Ağaç İşleri Endüstri Mühendisi';
      i++;
    }


    workBook.xlsx
      .writeBuffer()
      .then((exp) => {
        const dosyaAdi = 'Personeller Excell';
        const blob = new Blob([exp], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = dosyaAdi + '.xlsx';
        fs.saveAs(blob, filename);
        Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
        this.exampleExcelLoading = false;
      })
      .catch((err) => this.exampleExcelLoading = false);
  }


  isLoading: boolean = false;
  //#region Dosya işlemleri
  currentFile: File;
  acceptFileFormats: string = 'xls, xlsx';
  isFileCurrect: boolean = false;
  isFileUploading: boolean = false;
  dosya_adi;
  percentDone: number = 100;
  isSubmitting: boolean = false;
  file: FormControl = new FormControl();
  //#endregion
  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type=file accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.value = '';
    fileInput.click();
  }
  /**
   * Dosya(lar) seçildikden sonra çalışan fonksiyon.
   * @param event Seçilen dosya(lar)ın listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  async openFileDialog(event) {
    this.isLoading = true;
    // tek bir dosya alıcağım icin ilk dosyayı seçiyorum.
    const tmpFile: File = (event.target as HTMLInputElement).files[0];
    const fileName = tmpFile.name.split('.');
    const currentType = fileName.pop();

    // Seçilen elemanon dosya uzantısının doğru olup olmadığına bakılır.
    this.isFileCurrect = this.acceptFileFormats
      .split(',')
      .some((s) => s.toLowerCase().includes(currentType.toLowerCase()));

    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (!this.isFileCurrect) {
      this.isLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Dosya Uzantısı Hatalı.',
        html:
          '<div style="color: #f27474;text-align: left !important;">' +
          tmpFile.name +
          '</div>',
        showConfirmButton: true,
      });

      return;
    }
    const workbook = new Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {

      const fList = [];
      workbook.xlsx.load(data).then(() => {
        const worksheet = workbook.getWorksheet(1);
        worksheet.eachRow(function (row, rowNumber) {
          if (rowNumber > 1) {
            const personelAdi = row.getCell(1).value;
            const soyadi = row.getCell(2).value;
            const kimlikNo = row.getCell(3).value;
            const meslek = 591;
            const telefon = row.getCell(4).value;
            // const taseron_id = this.taseron.id;
            // console.log("taşeron_id",taseron_id);
            if (personelAdi && meslek) {
              const firma = {
                first_name: personelAdi,
                last_name: soyadi,
                kimlik_no: kimlikNo,
                job_id: meslek,
                phone: telefon,
              };

              fList.push(firma);
            }
          }
        });
        return fList;
      },
      ).then((liste) => {
        liste.forEach((kisi: any) => {
          kisi['firma_id'] = this.firma.id;
          kisi['phone'] = Number('90' + kisi?.phone);
        });
        return liste;
      }).then(liste => {
        liste = removeAllNull(liste);
        this.taseronService.addTaseronPersonel(liste, this.firma.id).subscribe(resp => {
          return resp;
        }, (err) => {
          this.isLoading = false;
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
      }).then((resp) => {
        this.isLoading = false;
        this.close(true);
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      });
    });
  }


}
