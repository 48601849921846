import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { katNumberToName } from 'src/app/pages/constants';
import { convertDateWithTZ } from 'src/app/pages/global-functions';
import { CrmTeklif } from '../../crm-model/projeler-model';
import { CrmService } from '../../crm-service';
import * as teklifActions from './teklifler.actions';

@Injectable()
export class CrmTeklifEffects {

    @Effect()

    loadData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teklifActions.loadData),
            mergeMap((props) => this.crmService.getTeklifList(props)
                .pipe(
                    map((data: any) => {
                        data.results.forEach(teklif => {
                            teklif = this.clearData(teklif);
                        });

                        return (teklifActions.loadDataSuccess({ data: data, isEnd: false }));
                    }),
                    catchError(() => of(teklifActions.loadDataFail({ error: 'Teklif Fail', isEnd: false }))),
                )),
        );
    });

    addNewTeklif$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teklifActions.addNewTeklif),
            mergeMap((props) => this.crmService.addTeklif(props)
                .pipe(
                    map((data: any) => {
                        data = this.clearData(data);
                        return (teklifActions.addNewTeklifSuccess({ data: data }));
                    }),
                    catchError(() => of(teklifActions.addNewTeklifFail({ error: 'Teklif Add Fail' }))),
                )),
        );
    });
    updateTeklif$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teklifActions.updateTeklif),
            mergeMap((props) => this.crmService.updateTeklif(props.data,props.teklifId)
                .pipe(
                    map((data: any) => {
                        data = this.clearData(data);
                        return (teklifActions.addNewTeklifSuccess({ data: data }));
                    }),
                    catchError(() => of(teklifActions.addNewTeklifFail({ error: 'Teklif Add Fail' }))),
                )),
        );
    });

    getTeklifDetail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teklifActions.getTeklifDetail),
            mergeMap((props) => this.crmService.getProjeDetail(props.teklifId)
                .pipe(
                    map((data: any) => {
                        return (teklifActions.setTeklifDetail({ teklifId: props.teklifId, data: data }));
                    }),
                    catchError(() => of(teklifActions.addNewTeklifFail({ error: 'Teklif Add Fail' }))),
                ),
            ),
        );
    });

    clearData(teklif: CrmTeklif) {
        // if (teklif.satis_tarihi) teklif.satis_tarihi = convertDateWithTZ(teklif, 'satis_tarihi');
        if (teklif.teklif_tarihi) teklif.teklif_tarihi = convertDateWithTZ(teklif, 'teklif_tarihi');
        console.log('clearData',teklif);
        teklif['kat_adi'] = katNumberToName(Number(teklif.kat_adi));

        if (teklif.teklif_gecerlilik_tarihi) teklif.teklif_gecerlilik_tarihi = convertDateWithTZ(teklif, 'teklif_gecerlilik_tarihi');


        return teklif;
    }
    constructor(
        private actions$: Actions,
        private crmService: CrmService,
    ) { }
}
