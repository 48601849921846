import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { TalepDetayComponent } from '../talep-detay/talep-detay.component';
import { TalepService } from '../../../services/talep.service';
import { tarih_format } from '../../../pages/constants';
import { ApasInfiniteScrollComponent } from '../../Apas-Modules/infinite-scroll/infinite-scroll.component';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { Router } from '@angular/router';
@Component({
  selector: 'talep-list-dashboard',
  templateUrl: './talep-list-dashboard.component.html',
  styleUrls: ['./talep-list-dashboard.component.css'],
  providers: [TalepService, OrganizasyonService],
})
export class TalepListDashboardComponent implements OnInit, OnDestroy {
  rows: any[] = [];
  pageRowCount: number = 20;
  tarih_format = tarih_format;
  isLoading: boolean = false;
  @Input() santiye_id: number;
  datas: any;
  hasAuthorization: boolean = false;
  constructor(private nbDiaologService: NbDialogService,
    private talepService: TalepService,
    private organizasyonService: OrganizasyonService,
    private router: Router,
  ) {
    this.orgSubscription();
  }

  ngOnInit() { }

  organizationSubscription;
  orgSubscription() {
    this.organizationSubscription = this.organizasyonService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const talep = modules.find(x => x.slug === 'satinalma');
        if (talep.value) {
          this.hasAuthorization = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
  }

  talepDetay(id: number) {
    this.nbDiaologService.open(TalepDetayComponent, {
      context: {
        talepId: id,
      },
    });
  }
  onScrool(event: ApasInfiniteScrollComponent) {
    if (this.hasAuthorization) {
      this.isLoading = true;
      this.talepService.getTalepsForSantiye(this.santiye_id, event.target.page).subscribe((data) => {
        console.log('Dash board --> rows', data);
        this.rows = [...this.rows, ...data];
        if (data.length < this.pageRowCount) {
          event.isLoaiding = false;
        }
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
        event.isLoaiding = false;
      });
    }
  }

  addNew() {
    this.router.navigate(['pages/talep/talep-ekle']);
  }
}
