import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Subject, Observable } from 'rxjs';

import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BaseModel } from '../../../models/base.model';
import { ProductModel } from '../../../models/product.model';
import { birim, Constants, responseKodlari, tarih_format } from '../../../pages/constants';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { DepoService } from '../../../services/depo.service';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { FilePreviewerComponent } from '../../apas-components/file-preview-component/file-previewer.component';
import { UrunHareketEditComponent } from './urun-hareket-edit/urun-hareket-edit.component';

@Component({
  selector: 'urun-hareketler',
  templateUrl: './urun-hareketler.component.html',
  styleUrls: ['./urun-hareketler.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DepoService, OrganizasyonService],
})
export class UrunHareketlerComponent implements OnInit {
  urun: ProductModel;
  tarih_format = tarih_format;
  editMod: boolean = false;
  selectedMarka;
  allUrunMarka: ProductModel[];
  tag: string = 'UrunHareketlerComponent --->';
  isLoading: boolean;
  c = Constants.prototype;
  @Output() toEdit: EventEmitter<any> = new EventEmitter();

  constructor(
    private ref: NbDialogRef<UrunHareketlerComponent>,
    private _depoService: DepoService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();


  }
  hareketler: any;
  globalConstants = Constants.prototype;

  ngOnInit() {
    console.log(this.tag, 'urun:', this.urun);
    this.getUrunDetay();
  }
  getUrunDetay() {
    this.isLoading = true;
    this._depoService
      .getUrunHareket(this.urun.warehouse_id, this.urun.product_id)
      .subscribe(
        (data) => {
          this.isLoading = false;

          this.allUrunMarka = data;
        },
        () => {
          this.isLoading = false;
        },
      );
  }
  closed() {
    this.ref.close();
  }
  selectMarka(selectedMarka: ProductModel) {
    this.hareketler = [];
    this.selectedMarka = selectedMarka;
    this.isLoading = true;

    this._depoService
      .getUrunTumHareket(
        this.urun.warehouse_id,
        this.urun.product_id,
        this.selectedMarka.id,
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          data.forEach(element => {
            element['created_time'] = convertDateWithTZ(element, 'created_time');
          });
          this.hareketler = data;

          console.log(data);
        },
        (err) => {
          this.isLoading = false;
          if (err.status === 404) {
            this.selectedMarka = undefined;
          }
        },
      );
  }
  eraseMarka() {
    this.selectedMarka = undefined;
  }



  openIrsaliyeImage(event) {
    this.isLoading = true;
    this._depoService.getIrsaliyeImage(event.identifier).subscribe((resp) => {

      const fileContext: {} = {
        _file: resp['irsaliye_image'],
        extension: 'png',
      };
      this.nbDialogService.open(FilePreviewerComponent,
        {
          context: { item: fileContext },
          closeOnBackdropClick: false,
        });
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
    });
  }

  editStatus = false;
  toEditHareket;
  birim = birim;
  form: FormGroup;
  tedarikci$: Observable<BaseModel[]>;
  organizationLoading = false;
  orgListeInput$ = new Subject<string>();
  personelList$: Observable<BaseModel[]>;
  minos: boolean = false; // işlem başlangıcında miktar verisi + veya - mi ?

  isFileCleared: boolean = false;
  initForm() {
    this.form = this.fb.group({
      id: [],
      created_time: [],
      delivery_by_org_apas_id: [],
      delivery_by_name: [],
      delivery_by_apas_id: [],
      amount: [],
      unit: [],
      irsaliye_image: [],
      note: [],
    });
  }

  backToEdit(hareket) {
    this.nbDialogService.open(
      UrunHareketEditComponent, { context: { editData: hareket } },
    ).onClose.subscribe((resp) => {
      if (resp) {
        this.selectMarka(this.selectedMarka);
      }
    });

  }  
  saveLoad: boolean = false;
  patchForm() {
    this.saveLoad = true;
    const formVal = removeAllNull(this.form.value);
    if (this.minos) {
      formVal.amount = formVal.amount * -1;
    }
    formVal['file_change'] = this.isFileCleared;
    this._depoService
      .editHareket(this.toEditHareket.id, formVal)
      .subscribe((resp) => {
        this.saveLoad = false;
        console.log('RESP : ', resp);
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.ref.close({ refresh: true });
      }, err => {
        this.saveLoad = false;
      });
  }

  deleteHareket(hareket) {
    console.log('HAREKET : ', hareket);
    Swal.fire({
      ...responseKodlari.shureToDelete,
      ...{
        text: `${hareket.product_name} ürününün işlemini silmek istediğinizden emin misiniz?`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this._depoService.deleteHareket(hareket.id).subscribe(
          (resp) => {
            console.log('SUCCESS TO DELETE', resp);
            Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
            this.ref.close({ refresh: true });
          },
          (err) => {
            console.log('ERROR', err);
          },
        );
      }
    });
  }






}
