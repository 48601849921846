import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[apasRoundPipe]',
  providers: [DecimalPipe],
  
})
export class ApasRoundPipeDirective {
  tag: string = 'ApasRoundPipeDirective --->';
  constructor(public ngControl: NgControl, private decimalPipe: DecimalPipe) {

  }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(event: number) {
    if (!event) { return true; }
    console.log(this.tag, event);
    if (event >= 100) {
      event = 100;
    }
    let calcNumber: number = Number(this.decimalPipe.transform(event, '3.6-6').replace(',', '.'));
    if (calcNumber >= 100) {
      calcNumber = 100;
    }

    console.log(this.tag, calcNumber)
    this.ngControl.valueAccessor.writeValue(calcNumber);
  }

}
