
import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import {
    NbCalendarCell,
    NbCalendarRangeMonthCellComponent,
    NbCalendarRangeYearCellComponent,
    NbCalendarSize,
    NbCalendarSizeValues,
    NbCalendarViewMode,
    NbCalendarViewModeValues,
    NbDateService,
} from '@nebular/theme';

export interface ApasNbCalendarRange<D> {
    start: D;
    end?: D;
}

@Component({
    selector: 'apas-month-calendar-range',
    template: `
    <apas-base-calendar
      [date]="range"
      (dateChange)="onChange($any($event))"
      [min]="min"
      [max]="max"
      [filter]="filter"
      [startView]="startView"
      [boundingMonth]="boundingMonth"
      [monthCellComponent]="monthCellComponent"
      [yearCellComponent]="yearCellComponent"
      [visibleDate]="visibleDate"
      [showNavigation]="showNavigation"
      [size]="size"
      [weekNumberSymbol]="weekNumberSymbol"
    ></apas-base-calendar>
  `,
})
export class ApasMonthCalendarRangeComponent<D> {
    /**
     * Defines if we should render previous and next months
     * in the current month view.
     * */
    @Input() boundingMonth: boolean = true;

    /**
     * Defines starting view for the calendar.
     * */
    @Input() startView: NbCalendarViewMode = NbCalendarViewMode.MONTH;
    static ngAcceptInputType_startView: NbCalendarViewModeValues;

    /**
     * A minimum available date for selection.
     * */
    @Input() min: D;

    /**
     * A maximum available date for selection.
     * */
    @Input() max: D;

    /**
     * A predicate that decides which cells will be disabled.
     * */
    @Input() filter: (D) => boolean;


    /**
     * Custom month cell component. Have to implement `NbCalendarCell` interface.
     * */
    @Input('monthCellComponent')
    set _monthCellComponent(cellComponent: Type<NbCalendarCell<D, ApasNbCalendarRange<D>>>) {
        if (cellComponent) {
            this.monthCellComponent = cellComponent;
        }
    }
    @Input() monthCellComponent: Type<NbCalendarCell<D, ApasNbCalendarRange<D>>> = NbCalendarRangeMonthCellComponent;

    /**
     * Custom year cell component. Have to implement `NbCalendarCell` interface.
     * */
    @Input('yearCellComponent')
    set _yearCellComponent(cellComponent: Type<NbCalendarCell<D, ApasNbCalendarRange<D>>>) {
        if (cellComponent) {
            this.yearCellComponent = cellComponent;
        }
    }
    yearCellComponent: Type<NbCalendarCell<D, ApasNbCalendarRange<D>>> = NbCalendarRangeYearCellComponent;

    /**
     * Size of the calendar and entire components.
     * Can be 'medium' which is default or 'large'.
     * */
    @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;
    static ngAcceptInputType_size: NbCalendarSizeValues;

    @Input() visibleDate: D;

    /**
     * Determines should we show calendars navigation or not.
     * */
    @Input() showNavigation: boolean = true;

    /**
     * Range which will be rendered as selected.
     * */
    @Input() range: ApasNbCalendarRange<D>;

    /**
     * Sets symbol used as a header for week numbers column
     * */
    @Input() weekNumberSymbol: string = '#';

    /**
     * Emits range when start selected and emits again when end selected.
     * */
    @Output() rangeChange: EventEmitter<ApasNbCalendarRange<D>> = new EventEmitter();

    constructor(protected dateService: NbDateService<D>) {
    }

    onChange(date: D) {
        console.log('RANGE CHANGE : ', date);
        this.initDateIfNull();
        this.handleSelected(date);
    }

    private initDateIfNull() {
        if (!this.range) {
            this.range = { start: null, end: null };
        }
    }

    private handleSelected(date: D) {
        if (this.selectionStarted()) {
            this.selectEnd(date);
        } else {
            this.selectStart(date);
        }
    }

    private selectionStarted(): boolean {
        const { start, end } = this.range;
        return start && !end;
    }

    private selectStart(start: D) {
        this.selectRange({ start });
    }

    private selectEnd(date: D) {
        const { start } = this.range;

        if (this.dateService.compareDates(date, start) > 0) {
            this.selectRange({ start, end: date });
        } else {
            this.selectRange({ start: date, end: start });
        }
    }

    private selectRange(range: ApasNbCalendarRange<D>) {
        this.range = range;
        this.rangeChange.emit(range);
    }
}
