import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Constants, tarih_format } from '../../../pages/constants';
import { convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { PuantajService } from '../../../services/puantaj.service';
import { PuantajEkleModalComponent } from '../puantaj-ekle/Puantaj-ekle-modal/puantaj-ekle-modal.component';
import { TopluPuantajEkleModalComponent } from '../puantaj-ekle/toplu-puantaj-ekle-modal/toplu-puantaj-ekle-modal.component';
import { PuantajGunListComponent } from '../puantaj-main/puantaj-gun-list/puantaj-gun-list.component';

@Component({
  selector: 'santiye-puantaj-detay',
  templateUrl: './santiye-puantaj-detay.component.html',
  styleUrls: ['./santiye-puantaj-detay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [OrganizasyonService],
})
export class SantiyePuantajDetayComponent implements OnInit, OnDestroy {
  @Input() santiye;
  @Input() taseron;
  tarih_format = tarih_format;
  rows;
  isLoading: boolean = false;
  ColumnMode = ColumnMode;
  tag: string = 'SantiyePuantajDetayComponent --->';
  hasAuthorization: boolean = true;
  form: FormGroup;

  width = 'col-md-7';
  c = Constants.prototype;


  constructor(
    private puantajService: PuantajService,
    private el: ElementRef,
    private nbDialogService: NbDialogService,
    private fb: FormBuilder,
    private organizasyonService: OrganizasyonService,
  ) {
    this.initForm();
    this.orgSubscription();
  }

  organizationSubscription;
  orgSubscription() {
    this.organizationSubscription = this.organizasyonService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const module = modules.find(x => x.slug === 'puantajlar');
        if (module.value) {
          this.hasAuthorization = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
  }

  ngOnInit() {

    if (this.santiye && this.santiye.id) {
      this.form.get('santiye_id').setValue(this.santiye.id);
    }
    if (this.taseron) {
      this.form.get('taseron_id').setValue(this.taseron.company_id.id);
    }


    if (this.hasAuthorization) {
      this.getPuantajData();
    }
    const viewRect = this.el.nativeElement.getBoundingClientRect();
  }



  initForm() {
    this.form = this.fb.group({
      santiye_id: [],
      taseron_id: [],
      santiye_active: [true],
      end_date: [],
      start_date: [],
    });
  }


  getPuantajData() {
    this.rows = null;
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.puantajService.getNewList(formVal).subscribe(resp => {
      this.isLoading = false;
      this.rows = resp;
      this.rows = this.getDateConvert(this.rows);

    }, (err) => {
      this.isLoading = false;
    });
  }


  getDateConvert(rowss) {
    rowss.taseron.forEach(element => {
      element['min_date'] = convertDateWithTZ(element, 'min_date');
      element['max_date'] = convertDateWithTZ(element, 'max_date');
      element['collepseOrderBy'] = false;
      element.santiye_list.forEach(elm => {
        elm['max_stamp'] = convertDateWithTZ(elm, 'max_stamp');
      });
    });
    rowss.santiye.forEach(element => {
      element['min_date'] = convertDateWithTZ(element, 'min_date');
      element['max_date'] = convertDateWithTZ(element, 'max_date');
      element['collepseOrderBy'] = false;
      element.taseron_list.forEach(elm => {
        elm['max_stamp'] = convertDateWithTZ(elm, 'max_stamp');
      });
    });
    return rowss;
  }

  puantajDetay(row) {
    this.nbDialogService.open(PuantajGunListComponent, { context: { data: row } });
  }

  yeniPuantajEKleSantiye() {
    let context: {};
    if (!this.taseron) {
      context = {
        'santiye_id': this.santiye.id,
        'disableControl': true,
      };
    } else {
      context = {
        'taseron_id': this.taseron.company_id.id,
        'disableControl': false,
        'taseron_name': this.taseron.company_id.name,
      };
    }
    this.nbDialogService.open(
      PuantajEkleModalComponent, { context, closeOnBackdropClick: false })
      .onClose.subscribe(() => {
        this.getPuantajData();
      });
  }

  topluPuantajEkele() {
    this.nbDialogService.open(TopluPuantajEkleModalComponent).onClose.subscribe(() => {
      this.getPuantajData();
    });
  }


  collepseOrderBySet(row) {
    row.collepseOrderBy = !row.collepseOrderBy;
    if (row.taseron_list) {
      if (row.collepseOrderBy) {
        row.taseron_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.taseron_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    } else {
      if (row.collepseOrderBy) {
        row.santiye_list.sort((a, b) => a.max_stamp - b.max_stamp);
      } else {
        row.santiye_list.sort((a, b) => b.max_stamp - a.max_stamp);
      }
    }
  }

}

