import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { addOrUpdate, removeAllNull } from '../../../pages/global-functions';
import { MutabakatService } from '../../../services/mutabakat.service';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { Constants, responseKodlari, tarih_format } from '../../../pages/constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'taseron-mutabakat-list',
  templateUrl: './taseron-mutabakat-list.component.html',
  styleUrls: ['./taseron-mutabakat-list.component.scss'],
  providers: [MutabakatService],
})
export class TaseronMutabakatListComponent implements OnInit {
  @ViewChild('myTable') table!: any;

  @Input() taseron_id;
  form: FormGroup;
  rows: any[] = [];
  isLoading: boolean = false;
  ColumnMode = ColumnMode;
  SortType = SortType;
  convert = Constants.prototype;
  tarih_format = tarih_format;
  constructor(
    private mutabakatService: MutabakatService,
    private fb: FormBuilder,
    ) {
        this.initForm();
      }

  ngOnInit() {

    if (this.taseron_id) {
      this.form.get('taseron_id').setValue(this.taseron_id);
    }

    this.getMutabakatList();
  }

  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      search: [],
      taseron_id: [],
    });
  }
  aproveMutabakat(row: any) {
    const metin = `${row.mutabakat_taraf_org_name} ile ${row.mutabakat_title} 
    başlıklı Mutabakatı onaylamak istiyor musunuz? 
    Bu işlem sonrasında ${row.mutabakat_taraf_org_name} firması bu mutabakatı değiştiremez.`
    Swal.fire({ ...responseKodlari.userCount, text: metin } as SweetAlertOptions).then((value) => {
      if (value.value) {
        this.mutabakatService.aproveMutabakat(row.id).subscribe(response => {
          row.is_approved = response['is_approved'];
        });
      }
    });
  }
  
  getMutabakatList() {
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.mutabakatService.getMutabakatForTaseron(formVal).subscribe(resp => {
      resp.forEach(elm => {
        this.rows = addOrUpdate(this.rows, elm);
      });

      this.rows = [...this.rows];
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  search(event) {
    const len = event.target.value.length;
    const arama = event.target.value.toUpperCase();


    if (len > 2) {
      this.rows = [];
      this.form.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.form.get('search').setValue(arama);
    } else if (len === 0) {
      this.form.get('page').setValue(0, { emitEvent: false, onlySelf: true });
      this.form.get('search').setValue(null);
    } else {
      return;
    }
    this.getMutabakatList();
  }
}
