import { createAction, props } from '@ngrx/store';
import { DuyuruModel } from '../../models/duyuru.model';
import { Update } from '@ngrx/entity';
// Customers
// Load Actions
export const loadDuyuru = createAction('[Duyuru] Load Duyuru', props<{ page?: number }>());
export const loadDuyuruSuccess =
    createAction('[Duyuru] Load Duyuru Success', props<{ duyuruItems: DuyuruModel[], isEnd?: boolean }>());
export const loadDuyuruFail = createAction('[Duyuru] Load Duyuru Fail', props<String>());


export const addItem = createAction('[Duyuru] Add DuyuruItem', props<{ duyuruItem: DuyuruModel }>());




//#region  SetReaded
export const setDuyuruReaded = createAction('[Duyuru] Set Duyuru Readed',
    props<{ id: number }>());

export const setDuyuruReadedSuccess = createAction('[Duyuru] Set Duyuru Readed Success',
    props<{ duyuru: Update<DuyuruModel> }>());

export const setDuyuruReadedFail = createAction('[Duyuru] Set Duyuru Readed Fail',
    props<{ err: string }>());
//#endregion
