<nb-card size="giant" style="min-width:750px !important;" class="aps-splt modalType animated fadeInRight">
        <nb-card-header>
          
           {{data[0]?.santiye_name}} (<b style="color:#ff7043;">{{data[0].taseron_name}}</b>)
              <button nbButton ghost (click)="close()" size="tiny" style="float:right;font-size: 18px;">X</button> 
        
        </nb-card-header>


        <nb-card-body style="overflow-x: hidden;">
                <table class="table table-bordered " *ngIf="!detayDate">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" style="width: 30%;">Tarih</th>
                            <th scope="col" class="text-center">Puantaj</th>
                            <th scope="col" class="text-center">İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of data;let i = index;">
                            <th scope="row">{{i+1}}</th>
                            <td>{{item.date * 1000 | date:tarih_format}}</td>
                            <td class="text-center">
                                    <span class="apasBadgeSuccess">
                                        {{item?.day_count}}
                                    </span>
                                </td>
                            <td class="text-center">
                                    <button nbButton size="tiny" status="primary" (click)="calisanDetay(item,item.day_count)">
                                      Personel Bilgisi
                                      <nb-icon icon="arrow-right"></nb-icon>
                                    </button>
                            </td>
                          </tr>
                         
                        </tbody>
                      </table>

                      <div class="animated fadeInRight" *ngIf="detayDate" [nbSpinner]="isLoading">
                        <button nbButton size="tiny" status="warning" (click)="calisanDetay()"><nb-icon icon="arrow-left"></nb-icon> Geri Dön</button>
                        <!-- <button class="excel_button" nbButton size="tiny" style="float:right;" (click)="excelToPuantaj()" [disabled]="exampleExcelLoading">
                          <i class="fas fa-file-excel pr-2"></i>Excel Çıktısı Al
                      </button> -->

                      <span style="float:right;">
                          <b>Toplam Puantaj: </b>
                          <span  class="apasBadgeSuccess">{{dayCount}}</span>
                        </span>

                        <div class="row w-100 p-0 m-0 apsHeaderTable">
        
                          <div class="col-md">
                                  <span>Meslek </span>
                          </div> 
                          <div class="col-md-3 text-center">
                                  <span>Tam Gün</span>
                          </div>
                          <div class="col-md-3 text-center">
                            <span>Yarım Gün</span>
                          </div> 
  
                       </div>

                       <nb-accordion>
                         <nb-accordion-item *ngFor="let item of rows.job_groups;">
                           <nb-accordion-item-header>
                             
                          <div class="col-md">
                            <span>{{item.job_name}} </span>
                          </div> 
                          <div class="col-md-3 text-center">
                               <span class="apasBadgeSuccess">{{item.tam_gun_adet}}</span>
                          </div>
                          <div class="col-md-3 text-center">
                            <span class="apasBadgeWarn">{{item.yarim_gun_adet}}</span>
                          </div> 
                           </nb-accordion-item-header>
                           <nb-accordion-item-body>
                            <table class="table table-bordered animated flipInY">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col" style="width: 30%;">Adı</th>
                                  <th scope="col" class="text-center">Durum</th>
                                  <th scope="col" class="text-center">Mesai(Saat)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of item.employees;let i = index">
                                  <th scope="row">{{i + 1}}</th>
                                  <td>{{row.employee_name}}</td>
                                  
                                  <td class="text-center">
                                    <span class="text-success" *ngIf="!row.yarim_gun">Tam Gün</span>
                                    <span class="text-warning" *ngIf="row.yarim_gun">Yarım Gün</span>
                                 </td>
                                 <td class="text-center">
                                  <span class="apasBadgeSuccess">{{row.overtime}}</span>
                               </td>
                                </tr>
                               
                              </tbody>
                            </table>
      
                           </nb-accordion-item-body>
                         </nb-accordion-item>
                       </nb-accordion>
                       
                      </div>
                      

        </nb-card-body>
</nb-card>