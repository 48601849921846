import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DuyuruModel } from '../../../models/duyuru.model';
import { prefixBase64, tarih_format } from '../../../pages/constants';

@Component({
  selector: 'duyuru-detay',
  templateUrl: './duyuru-detay.component.html',
  styleUrls: ['./duyuru-detay.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DuyuruDetayComponent implements OnInit {
tarih_format = tarih_format;
  duyuru: DuyuruModel;
  prefixBase64 = prefixBase64;
  constructor (private ref: NbDialogRef<DuyuruDetayComponent>) { }

  ngOnInit() {
  }

  closed() {
    this.ref.close();
  }

}
