import { ActionReducer, combineReducers, compose, createFeatureSelector } from '@ngrx/store';
import { CrmReducer, CrmState, defaultCrmStates } from '../../pages/crm/crm-store/crm-state';
import { defaultMuhasebeStates, MuhasebeReducer, MuhasebeState } from '../../pages/muhasebe/muhasebe-store/muhasebe-state';


const moduleReducers = {
        Muhasebe: MuhasebeReducer,
        Crm: CrmReducer,
};


const defaultModuleStates = {
        Muhasebe: defaultMuhasebeStates,
        Crm: defaultCrmStates,


};

interface ModuleState {
        Muhasebe: MuhasebeState;
        Crm: CrmState;
}

const moduleReducer: ActionReducer<ModuleState> = combineReducers(moduleReducers);

function ModuleReducer(state: any, action: any) {

        return moduleReducer(state, action);
}

const getMuhasebeSelector = createFeatureSelector<ModuleState>('module');

export {
        getMuhasebeSelector,
        ModuleReducer,
        moduleReducer,
        defaultModuleStates,
        ModuleState,
};
