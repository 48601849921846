<nb-card class="modalType animated fadeInRight" style="max-width: 450px;">
  <nb-card-header class="d-flex justify-content-between">
      <span>
        {{'POZEKLEMODAL.YENIPOZEKLE' |translate}}
      </span>

      <button 
        nbButton 
        (click)="closed()" 
        size="tiny" 
        class="m-close" 
        style="background: none !important; float: right; font-size: 18px;"
      >X</button>
  </nb-card-header>

  <nb-card-body [formGroup]="form" [nbSpinner]="isLoading">
    <div class="row">
      <div class="col-12 form-group">
        <label class="label">{{'POZEKLEMODAL.POZNO' |translate}}</label>
        <input nbInput fullWidth [placeholder]="'POZEKLEMODAL.POZNO' |translate" formControlName="new_poz_no" apasInvalid>
      </div>


      <div class="col-12 form-group">
        <label class="label">{{'POZEKLEMODAL.IMALATADI' |translate}}</label>
        <input nbInput fullWidth id="imalat" [placeholder]="'POZEKLEMODAL.IMALATADI' |translate" formControlName="title" apasInvalid />
      </div>


      <div class="col-12 form-group">
        <div class="form-group">
          <label class="label">{{'POZEKLEMODAL.BIRIM' |translate}}</label>
          <div >
            <ng-select id="birim" [items]="birim" appendTo="body" [addTag]="false" fullWidth
              [placeholder]="'POZEKLEMODAL.BIRIM' |translate" bindLabel="label" bindValue="value" formControlName="unit"
              [searchable]="false" apasInvalid>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="col-12 form-group">
        <label class="label">{{'POZEKLEMODAL.BIRIMFIYATI' |translate}}</label>

        <input nbInput [placeholder]="'POZEKLEMODAL.BIRIMFIYATI' |translate" formControlName="new_price" apasInvalid
           paraDirective fullWidth [negative]="false" [encrypt]="false" [decrypt]="false" />

      </div>
    </div>
    <div class="justify-content-center d-flex">
      <button nbButton status="primary" (click)="pozEkle()">{{'POZEKLEMODAL.KAYDET' |translate}}</button>
    </div>
  </nb-card-body>
</nb-card>