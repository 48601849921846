import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductModel } from '../models/product.model';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ProductService {
    private readonly API_URL = `${environment.backendurl}api/depo/product/`;
    private readonly API_URL_INFO = `${environment.backendurl}api/depo/taleplist/`;
    private readonly BRAND_URL = `${environment.backendurl}api/depo/brand/`;
    constructor(private httpClient: HttpClient) { }
    getProduct(instanceId?: number): Observable<ProductModel[]> {
        const url: string = `${this.API_URL}${instanceId ? instanceId + '/' : ''}`;
        return this.httpClient.get<ProductModel[]>(url);
    }
    getUrunDepoInfo(product_id: number): Observable<any[]> {
        const url: string = `${this.API_URL_INFO}${product_id}/`;
        return this.httpClient.get<any[]>(url);
    }
    addProduct(data: any): Observable<any> {
        return this.httpClient.post<any>(this.API_URL, data);
    }
    patchProduct(data: ProductModel): Observable<ProductModel> {
        return this.httpClient.patch<ProductModel>(this.API_URL, data);
    }
    searchProduct(search?: string, page?: number, id?, mine?: boolean): Observable<ProductModel[]> {
        const filter = {
            search: search,
            page: page,
            id: id,
            mine: mine,
        };
        const url: string = `${this.API_URL}search/`;
        return this.httpClient.post<ProductModel[]>(url, filter);
    }

  

    productList(filter): Observable<any> {
        const url: string = `${this.API_URL}productList/`;
        return this.httpClient.post<any>(url, filter);
    }

    productDelete(instanceId): Observable<any> {
        const url: string = `${this.API_URL}delete/${instanceId}/`;
        return this.httpClient.delete<any>(url);

    }
}

