import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class MetrajService {
    private readonly API_URL = `${environment.backendurl}api/metraj/`;
    private readonly API_URL_EK = `${environment.backendurl}api/ek/`;
    constructor(private httpClient: HttpClient) { }

    /**
     *   Şantiyeye ait mahal listesini cekecek
     */
    getPozHistory(santiye_id: number, work_group: number, poz_no: number): Observable<any[]> {
        const url: string = this.API_URL + 'poz/' + santiye_id + '/' + work_group + '/' + poz_no + '/';
        return this.httpClient.get<any[]>(url);
    }

    /**
     * Verilen bilgilere göre metraj bilgisi çekmeye yarar.
     * @param hakedis_id hakedisin id'si
     * @param work_group iş gurubu
     * @param poz_no poz no
    *  https://test.apspls.com/api/metraj/poz/49/1/123/
    *   https://test.apspls.com/api/metraj/poz/hakediş id/ iş grubu id/poz id/
    * hakediş_no / work_group / birim_fiyat_poz_no
    * poz
    */
    getMetrajForHakedis(hakedis_id: number, work_group: number, poz_no: number): Observable<any[]> {
        const url: string = this.API_URL + 'poz/' + hakedis_id + '/' + work_group + '/' + poz_no + '/';
        return this.httpClient.get<any[]>(url);

    }

    getOrCreateNewMetrajGroup(hakedis_id: number, work_group: number, poz_no: number): Observable<any> {
        const url: string = this.API_URL + hakedis_id + '/' + work_group + '/' + poz_no + '/';
        return this.httpClient.get<any[]>(url);
    }
    /**
     * Verilen bilgilere göre metraj başlıklarını çekmeye yarar.
     * @param hakedis_id hakedisin id'si
    */
    getMetrajHeaderForHakedis(hakedis_id: number): Observable<any[]> {
        const url: string = this.API_URL + hakedis_id + '/';
        return this.httpClient.get<any[]>(url);

    }


    /**
     * Verilen bilgilere göre metraj başlıklarını çekmeye yarar.
     * @param id hakedisin id'si
    */
    patchMetrajItems(hakedisId: number, data) {
        const url: string = this.API_URL + 'item/update/' + hakedisId + '/';
        return this.httpClient.patch<any>(url, data);
    }

    /**
    * Verilen bilgilere göre metraj başlıklarını çekmeye yarar.
    * @param hakedisId hakedisin id'si
   */
    postMetrajItems(hakedisId: number, data): Observable<any> {
        const url: string = this.API_URL + 'item/create/' + hakedisId + '/';

        return this.httpClient.post<any>(url, data);
    }
    /** Metrajı siler.
    * @param hakedisId hakedisin id'si
    * @param metrajId metraj id'si
   */
    deleteMetrajItem(hakedisId: number, metrajId: number): Observable<any> {
        const url: string = this.API_URL + 'item/delete/' + hakedisId + '/' + metrajId + '/';
        console.log('deleteMetraj -->url:', url);
        return this.httpClient.delete(url);
    }

    addNewPoz(data: any) {
        const url: string = `${this.API_URL_EK}birimfiyat/add/`;
        return this.httpClient.post(url, data);
    }

}



