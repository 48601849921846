
		<div
			class="info_product"
			*ngIf="productInfo"
			[nbPopover]="list"
			nbPopoverPlacement="bottom"
		>
			<nb-icon
				icon="alert-circle-outline"
				class="text-primary "
			></nb-icon>
			<label class="label"> Ürün Bilgisi </label>
		</div>
		<ng-template #list>
			<nb-card size="medium" style="min-width:400px">
				<nb-list>
					<nb-list-item *ngFor="let item of productInfo">
						<div class="col-md-8">
							<b class="ml-2"> {{ item.warehouse_name }}</b>
						</div>
						<div class="col-md">
							<span>Miktar: </span>
							<b class="ml-2">
								<apas-para-item
									[value]="item.total_amount"
									[alacak]="false"
									[negative]="true"
									[decrypt]="false"
									[setColor]="false"
									[currency]="false"
									[animate]="false"
								></apas-para-item>
								{{ c.getBirim(item?.unit) }}</b
							>
							<div>
								<button
									nbButton
									status="info"
									(click)="urunHareket(item)"
									size="tiny"
								>
									Ürün Hareketleri
								</button>
							</div>
						</div>
					</nb-list-item>
				</nb-list>
			</nb-card>
		</ng-template>
		<ng-select
			#productSelect
			class="m-tablo-baslik uzun-select ng-select-without-bottom-margin"
			[items]="productTmp"
			[loading]="productLoading"
			[typeahead]="productInput$"
			bindLabel="name"
			[bindValue]="bindValue"
			appendTo="body"
			[addTag]="false"
			[placeholder]="placeText"
			[searchable]="true"
			[virtualScroll]="true"
			(scrollToEnd)="onScrollToEnd()"
			[formControl]="control"
			(change)="urunChange($event)"
		>
			<ng-template ng-label-tmp let-item="item">
				{{ item.name }}
			</ng-template>

			<ng-template
				ng-option-tmp
				let-item="item"
				let-index="index"
				let-search="searchTerm"
			>
				<div [class]="item?.blue ? 'used' : 'unused'" class="card">
					<div
						class="d-flex alig-items-center justify-content-between"
					>
						<div>
							{{ item?.name }}
						</div>
						<div
							class="linked-div"
							(click)="urunEdit(productSelect, item)"
							*ngIf="!item?.is_public"
						>
							<nb-icon icon="edit-outline"></nb-icon>
						</div>
					</div>
				</div>
			</ng-template>

			<ng-template ng-footer-tmp>
				<div>
					<button
						*ngIf="showHizmetButton"
						fullWidth
						nbButton
						status="warning"
						(click)="hizmetSelect()"
					>
						Hizmet Ekle
					</button>
				</div>
				<div>
					<button
						fullWidth
						nbButton
						status="primary"
						(click)="yeniUrun(productSelect)"
					>
						{{ "DEPO.URUNEKLE" | translate }}
					</button>
				</div>
			</ng-template>
		</ng-select>
	