<nb-card class="modalType animated fadeInRight" size="giant" style="width: 50%;">

    <nb-card-header class="d-flex justify-content-between">
        <span>{{ 'GOREVEKLE.TITLE' | translate }}</span>
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>
    <nb-card-body [nbSpinner]="isSubmiting" [formGroup]="form">

        <div class="row">
            <!-- Baslık -->
            <div class="col-md-12 form-group">
                <label class="label">{{'GOREVLER.BASLIK'|translate}}</label>
                <input formControlName="title" type="text" nbInput novalidate fullWidth apasInvalid
                    [placeholder]="'GOREVLER.BASLIK'|translate" />
            </div>
            <!--// Baslık -->

            <!-- İçerik -->
            <div class="col-md-12 form-group">
                <label class="label">{{'GOREVLER.ICERIK' | translate}}</label>
                <textarea [placeholder]="'GOREVLER.ICERIK' | translate" nbInput fullWidth formControlName="content"
                    apasInvalid></textarea>
            </div>
            <!-- //İçerik -->


            <!-- Önem Seviyesi -->
            <div class="col-md-6">
                <label class="label">{{'GOREVLER.ONEMSEVIYE' | translate}}</label>
                <div>
                    <ng-select fullWidth [items]="importance_level" bindValue="value" appendTo="body"
                        [placeholder]="'GOREVLER.ONEMSEVIYE' | translate" formControlName="importance_level"
                        apasInvalid>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div [class]="item.bicim+' onem-bicim'">{{item.label}}</div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <!-- //Önem Seviyesi -->
            <!-- Tarih -->
            <div class="col-md-6">
                <label class="label">{{'GOREVEKLE.TAMAMLANMATARIHI'|translate}}</label>
                <div>
                    <input dateOnly [nbDatepicker]="endDate" [placeholder]="'GOREVEKLE.TAMAMLANMATARIHI'|translate"
                        formControlName="end_time" nbInput fullWidth apasInvalid dateOnly />
                    <nb-datepicker #endDate></nb-datepicker>
                </div>
            </div>
            <!-- // Tarih -->

            <!-- Görevlendirilenler  -->
            <div class="form-group col-md-6">
                <label class="label">Görevli Personeller</label>

                <personel-select [multiple]="true" [bindValue]="'apas_id'" style="display: block;"
                    formControlName="assign_to_ids" [showAddButtons]="true" apasInvalid>
                </personel-select>
            </div>
            <!-- // Görevlendirilenler -->
            <!-- Görüntüleme -->
            <div class="form-group col-md-6">
                <label class="label">{{'GOREVEKLE.GOREVIGOREBILECEKPERSONELLER' | translate}}</label>
                <personel-select [multiple]="true" [bindValue]="'apas_id'" style="display: block;"
                    formControlName="observers" [showAddButtons]="true" apasInvalid>
                </personel-select>
            </div>
            <!-- //Görüntüleme -->
        </div>


        <div class="text-center">
            <button nbButton status="primary" type="submit" (click)="postForm()">
                {{ 'GOREVEKLE.BUTTON' | translate}}
            </button>
        </div>
    </nb-card-body>
</nb-card>