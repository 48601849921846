import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GorevService } from '../../../services/gorev.service';
import { GorevModel } from '../../../models/gorev_model';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { GorevEkleComponent } from '../gorev-ekle/gorev-ekle.component';
import { GorevDetayComponent } from '../gorev-detay/gorev-detay.component';
import { LangTranslateService } from '../../../services/lang.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { HttpErrorResponse } from '@angular/common/http';
import { tarih_format } from '../../../pages/constants';
import { colorSet } from '../../apas-components/apas-filter/apas-filter.component';


@Component({
  selector: 'gorev-list',
  templateUrl: './gorev-list.component.html',
  styleUrls: ['./gorev-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [GorevService],
})
export class GorevListComponent implements OnInit, AfterViewInit {
  colorSet = colorSet;
  tarih_format = tarih_format;
  rows = [];
  isLoading: boolean = false;
  buyukGetir;
  tag: string = 'GorevListComponent --->';
  form: FormGroup;
  get searchControl(): FormControl { return this.form.get('search') as FormControl; }
  constructor(private gorevService: GorevService,
    private fb: FormBuilder,
    private ref: NbDialogRef<GorevListComponent>,
    private nbDialogService: NbDialogService,

  ) {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      tip: [0],
      search: [null],
    });



    this.searchControl.valueChanges.subscribe(x => {
      setTimeout(() => {
        this.getFilteredData();
      },200);
    });
    this.form.get('tip').valueChanges.subscribe(val => this.getFilteredData());
    // this.form.get('page').valueChanges.subscribe(x => {
    //   this.getGorvList();
    // });
  }
  async getFilteredData() {
    this.isEnd = await false;
    this.isLoading = await false;
    this.rows = await [];
    await this.form.get('page').setValue(0);
    this.getGorvList();
  }

  request;
  isEnd: boolean = false;
  getGorvList() {

    if (this.isEnd) return;
    const formVal = removeAllNull(this.form.value);
    if (this.request) this.request.unsubscribe();

    this.isLoading = true;
    this.gorevService.gorevList(formVal).subscribe(resp => {
      resp.forEach(elm => {
        this.kalanGun(elm);
        this.setImportanceColors(elm);
        this.rows = addOrUpdate(this.rows, elm);
      });

      this.isLoading = false;

      if (resp.length < formVal.count) {
        this.isEnd = true;
      }
    }, (err: HttpErrorResponse) => {
      console.log('err : ', err);
      if (err.status === 404) this.isEnd = true;
      this.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    this.getGorvList();
  }

  yenigorev: GorevModel[];

  tabState: boolean = true;

  ngOnInit() {
    // this.gorevListDegistir({ id: 0});

  }

  setImportanceColors(item) {
    if (item.importance_level === 1) {
      item.importance_level = 'cokonemli';
      item.char1 = '#edacb2';
      item.char2 = '#ffcdd291';
    } else if (item.importance_level === 2) {
      item.importance_level = 'onemli';
      item.char1 = '#b3e5fc';
      item.char2 = '#b3e5fc70';
    } else if (item.importance_level === 3) {
      item.importance_level = 'normal';
      item.char1 = '#c8e6c9';
      item.char2 = '#c8e6c970';
    }
  }

  kalanGun(item) {
    item.created_time = convertDateWithTZ(item, 'created_time');
    item.end_time = convertDateWithTZ(item, 'end_time');
    const one_day = 1000 * 60 * 60 * 24;

    const sure = (item.end_time.getTime() - item.created_time.getTime()) / one_day;
    const kalanSure = Math.round((item.end_time.getTime() - new Date().getTime()) / one_day);
    item.kalanSure = sure.toFixed(0);
    // this.puantajCalc(item);
    const oran = ((kalanSure / item.end_time) * 100);

    item.kalanSureOran = oran;
  }



  tamamla(id: number, percentage) {

    const context = { 'percentage': percentage };
    this.gorevService.patchGorev(id, context).subscribe(data => {
      const tamGorev: GorevModel = data;
      let listItem = this.rows.find(gorev => gorev.id === tamGorev.id);
      listItem = tamGorev;
    });
  }

  openYeniGorev() {
    this.nbDialogService.open(GorevEkleComponent, { closeOnBackdropClick: false }).onClose.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 300);
    });
  }


  openDetail(_id: number) {
    const context: {} = {
      'gorevId': _id,
    };

    this.nbDialogService.open(GorevDetayComponent, { context });

  }
  onScrool() {
    this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
    setTimeout(() => {
      this.getGorvList();
    },200);
  }

  closed() {
    this.ref.close();
  }

  gorevType = [
    {
      id: 0,
      name: 'ALLGOREVS',
    },
    {
      id: 1,
      name: 'BITENGOREVLER',
    },
    {
      id: 2,
      name: 'GOREVLERIM',
    },
    {
      id: 3,
      name: 'GOREVVERDIKLERIM',
    }, {
      id: 4,
      name: 'TAKIPETTIKLERIM',
    },
  ];
}


