import { PuantajMiniModel } from './puantaj_model';
import { WeatherModel , WeatherListItem } from './weather.model';
import { SozlesmeModel } from './sozlesme_model';
export class SantiyeModel {
    id: number;
    created_by_id: number;
    created_time: number;
    update_time: number;
    organization_id: any;
    sozlesme_id: SozlesmeModel;
    manager_id: any;
    manager: string;
    title: string;
    santiye_title: string;
    address: string;
    start_date: number;
    current_weather:any;
    end_time: number;
    total_area: number;
    kontrol_ids: number;
    puantaj_santiye: PuantajMiniModel[];
    end_date: number;
    kalanSure: number;
    kalanSureOran: number;
    sonPuantaj: number;
    sonPuantajTarihi: number;
    longitude: number;
    latitude: number;
    fiziksel_ilerleme: number;
    gecen_gun: number;
    kalan_gun: number;
    parasal_ilerleme: number;
    santiye_org_hakedis: SantiyeHakedis[];
    havaDurumu: WeatherModel;

    grafik: GrafikItemModel[];

    santiye_pursantaj: SantiyePursantaj[];
    santiye_works: YapilanIsModel;
    is_active: boolean;
    taseronlar?: any;
    can_edit?: boolean;
    is_employer_allowed: boolean;
    temp?: any;
    weathericon?: any;
    weatherdesc?: any;
    weathers:any;
    total_puantaj_count?: number;
    puantaj_baslangic?: any;
    last_puantaj_count?: number;
    son_puantaj_tarihi?: any;
}

export class SantiyePursantaj {
    work_type: string;
    work_percentage: number;
}
export class GrafikItemModel {
    name: string;
    value: number;
}
export class SantiyeHakedis {
    created_time: number;
    hakedis_item: SantiyeHakedisItem[];
}
export class SantiyeHakedisItem {
    yapilan_is: string;
    hakedis_miktar: number;
}

export interface YapilanIsModel {
    created_by_id: number;
    date: number;
    santiye_id: number;
    desc: string;
    work_done_percentage: number;
}
// veritabanından veri geldiginde düzenlenmesi gerekiyor.
export interface YapilanIsTamamlanModel {
    mahal: string;
    ayrinti: string;
    durum: string;
}




