<nb-card class="modalType animated fadeInRight" size="giant" style="width: 600px;">

    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>
            {{instanceTitle ? instanceTitle : ''}} {{'checklist' | translate}}
        </span>
        <span>
            <button nbButton status="primary" size="medium" (click)="checkListEkle()">
                {{rows.length > 0 ? 'Listeyi Düzenle':'Liste Ekle'}}
            </button>
            <button nbButton (click)="close()" size="medium" class="heryerdeClose" style="float: right;">X</button>
        </span>

    </nb-card-header>
    <nb-card-body  class="m-0 p-0"   [nbSpinner]="isLoading">
        <table class="table table-striped">
            <tbody>
                <tr *ngFor="let item of rows; let i=index;" [nbPopover]="tooltip" [nbPopoverContext]="item"
                    nbPopoverPlacement="bottom" nbPopoverTrigger="hover">
                    <th scope="row">{{i+1}}</th>
                    <td [class]="item.status ? 'tamamlanan' : ''">{{item.title | slice : 0:40 }} <span *ngIf="item.title.length > 40">...</span></td>
                    <td>
                        <nb-checkbox [value]="item.status" (checkedChange)="setChecked(item)"></nb-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </nb-card-body>
    <nb-card-body *ngIf="!isLoading && rows.length == 0">

        <div class="text-center mb-3">
            <h6>
                <nb-icon icon="alert-circle-outline" status="warning"></nb-icon>
                {{'checklist' | translate}} Bulunamadı.
            </h6>
            <br>
            Aşağıdaki Listeden Daha önce tanımladığınız bir şablonu atayabilir, yeni şablon oluştrabilirsiniz....

        </div>

        <div class="form-group">
            <label class="label"> Şablon Seç (Yeni Şablon Oluştur) </label>
            <ng-select [items]="sablonListesi" [formControl]="sablonFormControl" placeholder="Şablonlar"
                bindLabel="title" bindValue="id" appendTo="body" #ngSelect apasInvalid>
                <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex justify-content-between align-items-center">
                        <b>
                            {{item.title}}
                        </b>

                        <span>
                            <button nbButton status="warning" size="tiny" (click)="sablonEkle(item)">
                                <nb-icon icon="edit-outline"></nb-icon>
                            </button>
                            <button nbButton status="danger" size="tiny" (click)="deleteSablon(item)">
                                <nb-icon icon="trash-outline"></nb-icon>
                            </button>
                        </span>
                    </div>
                </ng-template>
                <ng-template ng-footer-tmp>
                    <div class="linked-div">
                        <button nbButton status="warning" (click)="sablonEkle()" [nbSpinner]="isLoading"
                            [disabled]="isLoading">Yeni {{'checklist' | translate}} Grubu Oluştur</button>
                    </div>

                </ng-template>
            </ng-select>
        </div>

        <div  *ngIf="selectedSablon">
            <ul>
                <li *ngFor="let sablonItem of selectedSablon?.templateitem_set; let i = index;">
                    <b> {{ sablonItem?.title }} </b>
                </li>
            </ul>
        </div>
        <div class="text-center">
            <button nbButton status="primary" (click)="sablonSetEt()" >Şablonu
                Uygula</button>
        </div>

    </nb-card-body>

</nb-card>

<ng-template #tooltip let-item>
    <nb-card class="m-0 p-0" style="max-width: 450px; background-color: #ffdc7169;">
        <nb-card-body>
            <div>
                <label class="label" style="color: #000;">
                    Başlık :
                </label>
                <span>
                    {{item.title}}
                </span>
            </div>
            <div *ngIf="item.checked_by_name">
                <label class="label" style="color: #000;"> Onaylayan : </label>
                <span>
                    {{item.checked_by_name}}
                </span>
            </div>
            <div *ngIf="item.checked_time">
                <label class="label" style="color: #000;"> Onaylanma Zamanı : </label>
                <span>
                    {{item.checked_time | date : tarih_format}}
                </span>
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>