import { createAction, props } from '@ngrx/store';

export const loadData = createAction('[CRM Load Teklif List]', props<{ filter?: any[] }>());
export const loadDataSuccess = createAction('[CRM Load Success Teklif List]', props<{ data: any[], isEnd?: boolean }>());
export const loadDataFail = createAction('[CRM Load Teklif Fail]', props<{ error: string, isEnd?: boolean }>());

export const resetData = createAction('[CRM Filter Data Teklif]');

export const selectTeklif = createAction('[CRM Selected Teklif]', props<{ selectedId: number }>());

export const addNewTeklif = createAction('[CRM Add New Teklif]', props<{ data: any }>());
export const updateTeklif = createAction('[CRM Add New Teklif]', props<{ data: any,teklifId: number }>());
export const addNewTeklifSuccess = createAction('[CRM Add New Teklif Success]', props<{ data: any }>());
export const addNewTeklifFail = createAction('[CRM Add New Teklif Fail]', props<{ error: any }>());

export const getTeklifDetail = createAction('[Get] CRM Teklif Detay', props<{ teklifId: number }>());
export const setTeklifDetail = createAction('[Set] CRM Teklif Detay', props<{ teklifId: number, data: any }>());
