<infinite-scroll #infinit (scrolled)="onScrolled($event)" [pagerowCount]="pageItemCount" [rows]="santiyeImages"
  [options]="{message:'Yükleniyor...'}" [isLoaiding]="false">

  <div *ngFor="let images of santiyeImages;let groupIndex=index" class="row m-images">
    <div class="col-md-12 tarih">
      {{'SANTIYEIMGLIST.TARIH'|translate}} <b>{{images.tarih |date:tarih_format}}</b>
    </div>
    <div *ngFor="let image of images.images;let imageIndex=index" class="col-md-2 p-2 d-flex flex-column" >
      <div>
        <img 
          (click)="imgClick(image.image,image.id,imageIndex,groupIndex)" 
          alt="picture"
          [src]="serializer.bypassSecurityTrustUrl(image.image)" 
          style="object-fit: cover;"
          class="img-fluid linked-div" />

      </div>
      <div class="mt-2" style="max-height: 20px; overflow: hidden;">
        <p class="text-center" *ngIf="!image?.editMod" [nbTooltip]="image?.title"> {{image?.title}} </p>
      </div>
      <div *ngIf="image.editMod" [formGroup]="editForm">
        <textarea fullWidth  nbInput placeholder="Açıklama" formControlName="title"></textarea>
        <nb-checkbox formControlName="is_public">{{'APASIMAGELIGHTBOX.ISVERENGORUNTULEYEBILIR' | translate }} </nb-checkbox>
        <input fullWidth nbInput [nbDatepicker]="tarih" placeholder="tarih" formControlName="created_time">
  
      </div>
      <nb-datepicker #tarih></nb-datepicker>
      <div class="d-flex justify-content-end" style="margin-top: auto">
        <b>
          <button nbButton *ngIf="image?.can_edit && !image?.editMod" (click)="fotografEdit(image)" status="warning" size="tiny"><nb-icon icon="edit-outline"></nb-icon></button>
        </b>
        <b>
          <button nbButton *ngIf="image?.can_edit && !image?.editMod" (click)="fotografSil(image, images, santiyeImages)" status="warning" size="tiny"><nb-icon icon="trash-outline"></nb-icon></button>
        </b>
        <b>
          <button nbButton *ngIf="image?.can_edit && image?.editMod" (click)="updateImage(image, images)" status="success" size="tiny">Kaydet</button>
        </b>
      </div>
      
    </div>
  </div>
</infinite-scroll>