<div class="row">
        <label class="col-md-12" *ngIf="labelShow"><b>Telefon</b></label>
        <!--Ülke Kodu Seçme Alanı--> 
          <div class="col pr-0 selectSel" style="width: 70px; min-width: 70px; max-width: 70px;">
                
                  <ng-select 
                  bindLabel="ulke"
                  [items]="telefon_kodu" 
                  appendTo="body" 
                  class="uzun-select ng-select-without-bottom-margin custom"
                  [clearable]="false" 
                  [formControl]="ulkem"
                  [disabled]="disabled"
                  >
                          <!--Label Template-->
                          <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm" class="text-center">
                                  <img  [src]="bayrakPath+item.bayrak"/>
                              </ng-template>
                          <!--//Label Template-->
  
                          <!--Option Template-->
                          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                  <img  [src]="bayrakPath+item.bayrak"/>
                                  {{item.ulke}}
                              </ng-template>
                          <!--//Option Template-->
                           
                  </ng-select>
          </div>
        <!--Ülke Kodu Seçme Alanı--> 
  
        <!--Telefon Numarası-->
          <div class="col pl-0">
                  <input [(ngModel)]="value"  
                  nbInput type="text" 
                  class="selectInput" 
                  fullWidth  
                  placeholder="Telefon" 
                  [prefix]="ulkem.value.ulkeKodu" 
                  [mask]="ulkem?.value?.mask"
                  [disabled]="disabled"
                  /> 
                  <!-- [imask]="ulkem.value.mask" [unmask]="true"  -->
          </div>
        <!--Telefon Numarası-->
        
  </div>