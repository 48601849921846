import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { tarih_format } from '../../../pages/constants';
import { isAvailable } from '../../../pages/global-functions';
import {
  ApasYazdirBody,
  ApasYazdirHead,
  ApasYazdirOptions,
  ApasYazdirTableHeader,
} from '../model/apas-yazdir.model';

@Injectable({
  providedIn: 'root',
})
export class ApasYazdirService {
  styles: string = `
            .head {
              width: 100%;
          }

          .head h2,h3 {
              text-align: center;
          }

          .head p {
              text-align: left;
              font-weight: bold;
          }

          .body {
              width: 100%;
          }
          @page {
            size:letter;

            top-left{
              content:'oliyor';
            }
          }
          `;

  constructor(private datePipe: DatePipe) {}
  /*
    /** Yazdırma  işlemlerinin gerçekleştirmek için kullanılır.
     * @param options Yazdırılacak içerik
     * @returns void
     */
  Yazdir(options: ApasYazdirOptions): void {
    const printContent: string = this.renderPage(
      this.renderHead(options.head),
      this.renderBody(options.body),
    );
    // console.log('Html:', printContent);
    const newWindow = window.open();
    newWindow.document.write(printContent);
    newWindow.document.close();
    newWindow.focus();
  }

  renderHead(head: ApasYazdirHead | string): string {
    console.log('head type', typeof head === 'string');
    if (typeof head === 'string') {
      return head;
    }
    let page: string = '';
    if (head.date) {
      page += `<p>Rapor Tarih: ${head.date}</p>`;
    }

    page += ` <h2>${head.mainTitle ? head.mainTitle : ''}</h2>
              <h3>${head.title ? head.title : ''}</h3>`;
    return page;
  }

  renderBody(body: ApasYazdirBody | string): string {
    const page: string = this.arrayToTable(body);

    return page;
  }

  renderPage(head: string, body: string): string {
    const page: string = `
                         <!DOCTYPE html>
                           <html lang="en">

                        <head>
                         <meta charset="UTF-8">
                         <meta name="viewport" content="width=device-width, initial-scale=1.0">
                         <link rel="stylesheet"
                          href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
                         <style>
                            ${this.styles}
                         </style>
                      </head>

                      <body>
                      <div class="head">
                         ${head}
                         </div>
                         <hr>
                         <div class="body">
                         ${body}
                         </div>
                         <script>
                         window.print();
                         setTimeout(() => {
                           window.close();
                           }, 300);
                       </script>
                      </body>
                      </html>`;
    return page;
  }

  arrayToTable(body: ApasYazdirBody | string): string {
    if (typeof body === 'string') {
      return body;
    }

    let printContents: string = `<table class="table table-bordered">
                                <thead>`;
    printContents += '<tr>';
    printContents += `<th style="text-align:center;width:100px">Sıra No</th>`;

    body.tableHeader.forEach((element: ApasYazdirTableHeader) => {
      printContents += `<th style="text-align:center"> ${element.name}</th>`;
    });
    printContents += '</tr>';

    printContents += `</thead>
                                    <tbody>`;
    body.tableBody.forEach((element, index) => {
      printContents += '<tr>';
      printContents += `<td style="text-align:center"> ${index + 1}</td>`;
      body.tableHeader.forEach((el: ApasYazdirTableHeader) => {
        /** Undefined veya null görmememk için bir önlem */
        let value = isAvailable(element[el.prop]) ? element[el.prop] : '';
        /**Eğer bu field tarih ise number'dan tarihe çevirir. */
        value = el.isDate ? moment(value, 'X').format('ll HH:mm') : value;

        printContents += `<td style="text-align:center"> ${value}</td>`;
      });
      printContents += '</tr>';
    });
    printContents += `  </tbody>
                             </table>`;

    return printContents;
  }
}
