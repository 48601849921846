import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { BothEmptyValidator } from '../../../../kullanici/components/validators';
import { responseKodlari } from '../../../../pages/constants';
import { convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import { PursantajService } from '../../../../services/pursantaj.service';
import { YapilanIslerService } from '../../../../services/yapilan-isler.service';
import { YapilanIslerAddPhotoComponent, NoteType } from '../yapilan-isler-add-photo/yapilan-isler-add-photo.component';

@Component({
  selector: 'ngx-edit-pursantaj-modal',
  templateUrl: './edit-pursantaj-modal.component.html',
  styleUrls: ['./edit-pursantaj-modal.component.scss'],
  providers: [PursantajService, YapilanIslerService],
})
export class EditPursantajModalComponent implements OnInit {
  @Input() data;
  @Input() santiye_id;
  form: FormGroup;
  isLoading: boolean = false;
  pursantajList = [];
  isPursantajLoading: boolean = false;
  isNew: boolean = false;
  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<EditPursantajModalComponent>,
    private pursantajService: PursantajService,
    private yapilanIslerService: YapilanIslerService,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    console.log('gelen data : ', this.data);
    this.clearData();
    this.getYapilanIs();
    this.form.patchValue(this.data);
  }

  close(resp?) {
    this.ref.close(resp);
  }
  forceRefresh = 0;

  clearData() {
    if (this.data?.start_day) {
      this.data.start_day = convertDateWithTZ(this.data, 'start_day');

      setTimeout(() => {
        this.form.get('start_day').patchValue(this.data.start_day);

      }, 150);
    }

    if (this.data?.end_day) {
      console.log('before convert end day : ', this.data.end_day);
      this.data.end_day = convertDateWithTZ(this.data, 'end_day');
      console.log('clear end day : ', this.data.end_day);

      setTimeout(() => {
        this.form.get('end_day').patchValue(this.data.end_day);

      }, 150);
    }
    this.forceRefresh += 1;
  }

  getYapilanIs() {
    this.isPursantajLoading = true;
    this.pursantajService.getPursantaj(this.santiye_id).subscribe((data) => {
      this.pursantajList = data;
      this.removeSelf();
      this.form.get('ust_pursantaj_id').setValue(this.data.ust_pursantaj_id);
      this.isPursantajLoading = false;
    }, (err) => this.isPursantajLoading = false);
  }

  removeSelf() {
    // tslint:disable-next-line: triple-equals
    this.pursantajList = this.pursantajList.filter(x => x.id != this.data.id);
  }


  initForm() {
    this.form = this.fb.group({
      ust_pursantaj_id: [],
      pursantaj_name: [],
      pursantaj_rate: [null, [BothEmptyValidator('metraj')]],
      metraj: [null, BothEmptyValidator('pursantaj_rate')],
      start_day: [],
      end_day: [],
      desc: [],
      work_done_percentage: [null, BothEmptyValidator('tamamlanan_metraj')],
      tamamlanan_metraj: [null, BothEmptyValidator('work_done_percentage')],
      applied_rate: [],
      date: [new Date()],
    },
    );

    this.form.get('tamamlanan_metraj').valueChanges.subscribe(val => {
      this.calculateMetraj();
    });

    this.form.get('metraj').valueChanges.subscribe(val => {
      this.calculateMetraj();
    });


    this.form.get('work_done_percentage').valueChanges.subscribe(val => {
      this.pursantajHesapla();
    });

    this.form.get('pursantaj_rate').valueChanges.subscribe(val => {
      this.pursantajHesapla();
    });

  }

  updatePursantaj() {
    const formVal = removeAllNull(this.form.value);

    this.pursantajService.pursantajPatch(this.data.id, formVal).subscribe(val => {
      console.log('updated pursantaj', val);
    });
  }

  pursantajHesapla() {
    const pursantaj = Number(this.form.get('pursantaj_rate').value);
    const totalPursantaj = Number(this.form.get('work_done_percentage').value);
    let hesap = (pursantaj * totalPursantaj) / 100;
    if (!isFinite(hesap)) hesap = 0;
    this.form.get('applied_rate').setValue(hesap, { emitEvent: false });
    this.updateValidity();
  }

  calculateMetraj() {
    const formVal = this.form.getRawValue();

    const toplam = formVal.metraj ? formVal.metraj : 0;
    const tamamlanan_metraj = formVal.tamamlanan_metraj ? formVal.tamamlanan_metraj : 0;

    // 1500 300 100 X = 100 * tamamlanan_metraj / toplam

    let calculated_percentage = 100 * tamamlanan_metraj / toplam;
    calculated_percentage = Number(calculated_percentage.toFixed(2));
    if (!(isFinite(calculated_percentage))) calculated_percentage = 0;
    this.form.get('applied_rate').setValue(calculated_percentage, { emitEvent: false });
    this.form.get('work_done_percentage').setValue(calculated_percentage, { emitEvent: false });
    this.updateValidity();
  }

  updateValidity() {
    this.form.controls.metraj.updateValueAndValidity({ emitEvent: false });
    this.form.controls.pursantaj_rate.updateValueAndValidity({ emitEvent: false });
    this.form.controls.work_done_percentage.updateValueAndValidity({ emitEvent: false });
    this.form.controls.tamamlanan_metraj.updateValueAndValidity({ emitEvent: false });
  }

  postForm() {
    if (!this.form.valid) {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
      this.form.markAllAsTouched();
      console.log('form errors ', this.form);
      return;
    }

    const formData = removeAllNull(this.form.value);
    formData['santiye_id'] = this.santiye_id;
    formData['pursantaj_id'] = this.data.id;

    console.log('Yapılan İşler Form Data', formData);
    this.isLoading = true;
    this.yapilanIslerService.postYapilanIs(formData).subscribe(
      (data) => {
        this.isLoading = false;

        Swal.fire({
          ...responseKodlari.wantToAddPhoto,
        } as SweetAlertOptions).then(({ value }) => {
          // Fotgraf ekeleme alanı
          if (value) {
            this.nbDialogService.open(YapilanIslerAddPhotoComponent,
              {
                context: {
                  contentId: data.id,
                  tip: NoteType.Liste,
                  modul: 'yapilanisler',
                },
              });
          }
        });
        this.close(data);
      },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
      },
    );

  }
}
