
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, Type } from '@angular/core';
import {
    NbCalendarCell,
    NbCalendarSize,
    NbCalendarSizeValues,
    NbCalendarViewMode,
    NbCalendarViewModeValues,
    NbCalendarYearModelService,
    NbDateService,
} from '@nebular/theme';
import * as moment from 'moment';

/**
 * The basis for calendar and range calendar components.
 * Encapsulates common behavior - store calendar state and perform navigation
 * between pickers.
 * */
@Component({
    selector: 'apas-base-calendar',
    templateUrl: './apas-base-calander.component.html',
})
export class ApasBaseCalendarComponent<D, T> implements OnInit {

    /**
     * Defines if we should render previous and next months
     * in the current month view.
     * */
    @Input() boundingMonth: boolean = true;

    /**
     * Defines active view for calendar.
     * */
    @Input('startView') activeViewMode: NbCalendarViewMode = NbCalendarViewMode.MONTH;
    static ngAcceptInputType_activeViewMode: NbCalendarViewModeValues;

    /**
     * Minimum available date for selection.
     * */
    @Input() min: D;

    /**
     * Maximum available date for selection.
     * */
    @Input() max: D;

    /**
     * Predicate that decides which cells will be disabled.
     * */
    @Input() filter: (D) => boolean;

    /**
     * Custom month cell component. Have to implement `NbCalendarCell` interface.
     * */
    @Input() monthCellComponent: Type<NbCalendarCell<D, T>>;

    /**
     * Custom year cell component. Have to implement `NbCalendarCell` interface.
     * */
    @Input() yearCellComponent: Type<NbCalendarCell<D, T>>;

    /**
     * Size of the calendar and entire components.
     * Can be 'medium' which is default or 'large'.
     * */
    @Input() size: NbCalendarSize = NbCalendarSize.MEDIUM;
    static ngAcceptInputType_size: NbCalendarSizeValues;

    @Input() visibleDate: any;

    /**
     * Determines whether we should show calendar navigation or not.
     * */
    @Input()
    @HostBinding('class.has-navigation')
    showNavigation: boolean = true;

    /**
     * Value which will be rendered as selected.
     * */
    @Input() date: T;

    /**
     * Sets symbol used as a header for week numbers column
     * */
    @Input() weekNumberSymbol: string;

    /**
     * Emits date when selected.
     * */
    @Output() dateChange: EventEmitter<T> = new EventEmitter();

    constructor(
        protected dateService: NbDateService<D>,
        protected yearModelService: NbCalendarYearModelService<D>,
    ) { }

    ngOnInit() {
        if (!this.visibleDate) {
            this.visibleDate = this.dateService.today();
        }
    }

    @HostBinding('class.size-large')
    get large() {
        return this.size === NbCalendarSize.LARGE;
    }

    ViewMode = NbCalendarViewMode;

    setViewMode(viewMode: NbCalendarViewMode) {
        this.activeViewMode = viewMode;
    }

    setVisibleDate(visibleDate: any) {
        this.visibleDate = visibleDate;
        // this.visibleDate = moment(this.visibleDate).date(1);
        if (this.activeViewMode === NbCalendarViewMode.MONTH) {
            this.dateChange.emit(visibleDate);
        }
    }

    prevMonth() {
        this.changeVisibleMonth(-1);
    }

    nextMonth() {
        this.changeVisibleMonth(1);
    }

    prevYear() {
        this.changeVisibleYear(-1);
    }

    nextYear() {
        this.changeVisibleYear(1);
    }

    prevYears() {
        this.changeVisibleYears(-1);
    }

    nextYears() {
        this.changeVisibleYears(1);
    }

    navigatePrev() {
        switch (this.activeViewMode) {

            case NbCalendarViewMode.MONTH:
                return this.prevYear();
            case NbCalendarViewMode.YEAR:
                return this.prevYears();
        }
    }

    navigateNext() {
        switch (this.activeViewMode) {

            case NbCalendarViewMode.MONTH:
                return this.nextYear();
            case NbCalendarViewMode.YEAR:
                return this.nextYears();
        }
    }

    onChangeViewMode() {
        if (this.activeViewMode === NbCalendarViewMode.MONTH) {
            return this.setViewMode(NbCalendarViewMode.YEAR);
        }

        this.setViewMode(NbCalendarViewMode.MONTH);
    }

    private changeVisibleMonth(direction: number) {
        this.visibleDate = this.dateService.addMonth(this.visibleDate, direction);
    }

    private changeVisibleYear(direction: number) {
        this.visibleDate = this.dateService.addYear(this.visibleDate, direction);
    }

    private changeVisibleYears(direction: number) {
        // tslint:disable-next-line: max-line-length
        this.visibleDate = this.dateService.addYear(this.visibleDate, direction * this.yearModelService.getYearsInView());
    }
}
