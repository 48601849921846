import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../../pages/global-functions';
import { SantiyeService } from '../../../../services/santiye.service';
import { NbDialogRef } from '@nebular/theme';
import { NbDialogService } from '@nebular/theme';
import { ApasImageLightboxComponent } from '../../../Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { tarih_format } from '../../../../pages/constants';


@Component({
  selector: 'ngx-santiye-album-modal',
  templateUrl: './santiye-album-modal.component.html',
  styleUrls: ['./santiye-album-modal.component.scss']
})
export class SantiyeAlbumModalComponent implements OnInit {
  modalData;
  isLoading = false;
  serializer = this.domSanitizer;
  dayData = [];
  form: FormGroup;
  next_start_date;
  totalCount;
  tarih_format = tarih_format;
  galleryDate;
  constructor(private fb: FormBuilder,
    private santiyeService: SantiyeService,
    private domSanitizer: DomSanitizer,
    private ref: NbDialogRef<SantiyeAlbumModalComponent>,
    private nbDalogService: NbDialogService) { }

  ngOnInit(): void {
    this.initForm();
    this.getAlbumDetayData();
  }

  initForm() {
    this.form = this.fb.group({
      santiye_id: [this.modalData.santiye_id],
      start_date: [this.modalData.start_date],
      show_yapilan_isler: [this.modalData.showImalatPhotos],
      show_santiye_photos: [this.modalData.showSantiyePhotos],
    });
  }

  getAlbumDetayData() {
    this.isLoading = true;
    let filter = removeAllNull(this.form.value);
    this.santiyeService.getImageForDay(filter).subscribe(data => {
      this.next_start_date = data.next_start_date;
      this.totalCount = data.total_count;
      data.data.forEach(elm => {
        elm['tarih'] = convertDateWithTZ(elm, 'created_time');
        this.dayData = addOrUpdate(this.dayData, elm);
        this.isLoading = false;

      });
      console.log('Modal Forodata:', this.dayData);


      this.dayData = [...this.dayData];

    }, err => {
      this.isLoading = false;
    });

  }

  close(){
    this.ref.close();
  }

  imgClick(  image: string, imageIndex: number) {
    const context: {} = {
      image: image,
      url: 'santiye/imagedetay/',
      santiyeId: this.modalData.santiye_id,
      imageIndex: imageIndex,
      groupIndex: 0,
      rows: [{ images: this.dayData }],

    };


    this.nbDalogService.open(ApasImageLightboxComponent, {
      context,
      closeOnBackdropClick: true,
    });
  }
}
